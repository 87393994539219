(function (angular) {
    angular
        .module('one.admin')
        .controller('EditEventController', EditEventController);

    EditEventController.$inject = ['$scope', '$state', 'EventService', 'event', 'sites'];

    function EditEventController($scope, $state, EventService, event, sites) {
        $scope.event = event;

        $scope.sites = sites;

        $scope.editEvent = editEvent;

        function editEvent(data) {
            EventService.editEvent(data).then(function (data) {
                $state.transitionTo('events.events.event', { eventId: data.event.id });
            });
        }
    }
})(angular);
