(function (angular) {
    angular
        .module('one.admin')
        .directive('sliderModuleForm', sliderModuleFormDirective);

    sliderModuleFormDirective.$inject = ['config'];

    function sliderModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: ['$scope', function ($scope) {
                $scope.ratios = [
                    [1, 1],
                    [4, 3],
                    [16, 9],
                    [2, 1],
                    [10, 3],
                    [5, 1]
                ];

                $scope.setAspectRatio = setAspectRatio;
                $scope.formatAspectRatio = formatAspectRatio;
                $scope.getPaddingBottomFromRatio = getPaddingBottomFromRatio;

                function setAspectRatio(ratio) {
                    $scope.module.module_instance.width = ratio[0];
                    $scope.module.module_instance.height = ratio[1];
                }

                function formatAspectRatio(ratio) {
                    return ratio[0] + ':' + ratio[1];
                }

                function getPaddingBottomFromRatio(ratio) {
                    return ratio[1] / ratio[0] * 100;
                }
            }],
            templateUrl: config.path.template('modules.slider.form')
        };
    }
}(angular));
