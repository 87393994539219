(function (angular) {
    angular
        .module('one.admin')
        .filter('capitalize', capitalizeFilter);

    capitalizeFilter.$inject = [];

    function capitalizeFilter() {
        return function (value) {
            if (!angular.isString(value)) {
                return value;
            }

            return (!!value) ? value.charAt(0).toUpperCase() + value.slice(1) : '';
        };
    }
})(angular);
