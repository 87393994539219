(function (angular) {
    angular
        .module('one.admin')
        .controller('AddTransactionalMailController', AddTransactionalMailController);

    AddTransactionalMailController.$inject = ['$scope', '$state', 'TransactionalMailService', 'pdfTemplates', 'senders', 'templates'];

    function AddTransactionalMailController($scope, $state, TransactionalMailService, pdfTemplates, senders, templates) {
        $scope.pdfTemplates = pdfTemplates;
        $scope.senders = senders;
        $scope.templates = templates;

        $scope.transactionalMail = {
            bcc: [],
            personalized_sender: false,
            sender: $scope.senders[0],
            template: $scope.templates[0],
            subject: '',
            body: '',
            sections: [],
            file_attachments: [],
            pdf_template_attachments: []
        };

        $scope.submitting = false;

        $scope.addTransactionalMail = addTransactionalMail;

        function addTransactionalMail(data) {
            if ($scope.submitting) {
                return;
            }

            $scope.submitting = true;

            TransactionalMailService.addTransactionalMail(data).then(function (response) {
                $state.transitionTo('mail.transactionalMails.transactionalMail', { transactionalMailId: response.transactionalMail.id });
            }, function () {
                $scope.submitting = false;
            });
        }
    }
})(angular);
