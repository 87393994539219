(function (angular) {
    angular
        .module('one.admin')
        .controller('AddCampaignModalController', AddCampaignModalController);

    AddCampaignModalController.$inject = ['$scope', '$uibModalInstance', 'CampaignService', 'translateFilter', 'sites', 'params'];

    function AddCampaignModalController($scope, $uibModalInstance, CampaignService, translateFilter, sites, params) {
        $scope.campaign = {};
        $scope.sites = sites;

        angular.extend($scope, params);

        $scope.campaign.site = $scope.sites[0];

        $scope.add = add;
        $scope.cancel = cancel;

        function add(data) {
            CampaignService.addCampaign(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
