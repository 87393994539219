(function (angular) {
    angular
        .module('one.admin')
        .filter('trim', trimFilter);

    trimFilter.$inject = [];

    function trimFilter() {
        return function (value) {
            if (!angular.isString(value)) {
                return value;
            }

            return value.replace(/^\s+|\s+$/g, '');
        };
    }
})(angular);
