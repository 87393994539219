(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountSettingsController', AccountSettingsController);

    AccountSettingsController.$inject = ['$scope', '$state', 'AuthService', 'notification', 'user'];

    function AccountSettingsController($scope, $state, AuthService, notification, user) {
        $scope.user = user;

        $scope.editUser = editUser;

        function editUser(data) {
            AuthService.user.editUser(data).then(function (data) {
                $state.reload($state.current);
            });
        }
    }
})(angular);
