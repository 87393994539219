(function (angular) {
    angular
        .module('one.admin')
        .service('VariableService', VariableService);

    VariableService.$inject = ['http'];

    function VariableService(http) {
        var dataTypes = [
            {label: 'String', value: 'string'},
            {label: 'Integer', value: 'integer'},
            {label: 'Money', value: 'money'}
        ];

        function variable(id) {
            return http.get('variables/' + id);
        }

        function variables(params) {
            return http.get('variables', params);
        }

        function keys(modelType, params) {
            return http.get('variables/keys/' + modelType, params);
        }

        function addVariable(data) {
            return http.post('variables/add', data);
        }

        function deleteVariable(id, data) {
            return http.post('variables/' + id + '/delete', data);
        }

        function editVariable(data) {
            return http.post('variables/' + data.id + '/edit', data);
        }

        return {
            variable: variable,
            variables: variables,
            keys: keys,
            addVariable: addVariable,
            deleteVariable: deleteVariable,
            editVariable: editVariable,
            dataTypes: dataTypes
        };
    }
})(angular);
