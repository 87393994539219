(function (angular) {
    angular
        .module('one.admin')
        .controller('SliderModuleIndexController', SliderModuleIndexController);

    SliderModuleIndexController.$inject = ['$scope', '$state', 'FileService', 'ModuleService', 'modal', 'module'];

    function SliderModuleIndexController($scope, $state, FileService, ModuleService, modal, module) {
        $scope.module = module;
        $scope.site = $scope.module.type.site;

        $scope.selectedSlideIndex = 0;
        $scope.selectedSlide = $scope.module.module_instance.slides[$scope.selectedSlideIndex];

        $scope.selectSlide = selectSlide;
        $scope.addSlide = addSlide;
        $scope.moveSlide = moveSlide;
        $scope.deleteSlide = deleteSlide;
        $scope.getAspectRatio = getAspectRatio;
        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;
        $scope.getFiles = FileService.files;
        $scope.formatFilename = FileService.formatFilename;

        function selectSlide(index) {
            $scope.selectedSlideIndex = index;
            $scope.selectedSlide = $scope.module.module_instance.slides[$scope.selectedSlideIndex];
        }

        function addSlide() {
            var newSlideIndex = this.module.module_instance.slides.length;

            var newSlide = {
                content: '',
                image_id: null,
                image: null,
                position: newSlideIndex + 1,
                alt_text: ''
            };

            this.module.module_instance.slides.push(newSlide);
            this.selectSlide(newSlideIndex);
        }

        function moveSlide(newIndex, movedSlide) {
            if ($scope.selectedSlide.id == movedSlide.id) {
                this.selectSlide(newIndex);
            }
        }

        function deleteSlide(index) {
            $scope.module.module_instance.slides.splice(index, 1);

            var newSlideIndex = Math.min($scope.selectedSlideIndex, this.module.module_instance.slides.length - 1);

            this.selectSlide(newSlideIndex);
        }

        function getAspectRatio() {
            if ($scope.module.module_instance.width) {
                return ($scope.module.module_instance.height / $scope.module.module_instance.width) * 100;
            }

            return 100;
        }

        function editModule(data) {
            ModuleService.editModule(data).then(function (data) {
                var state = ModuleService.getReturnState($scope.module);

                $state.transitionTo(state.name, state.params);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);
