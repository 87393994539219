(function (angular) {
    angular
        .module('one.admin')
        .service('SalesDashboardService', SalesDashboardService);

    SalesDashboardService.$inject = ['http'];

    function SalesDashboardService(http) {
        function exportTotals(params) {
            return http.get('sales/export_totals', params);
        }

        function totals(params) {
            return http.get('sales/totals', params);
        }

        return {
            exportTotals: exportTotals,
            totals: totals
        };
    }
})(angular);
