(function (angular) {
    angular
        .module('one.admin')
        .service('SiteService', SiteService);

    SiteService.$inject = ['$filter', 'http'];

    function SiteService($filter, http) {
        function site(id, params, config) {
            return http.get('sites/' + id, params, null, config);
        }

        function sites(params) {
            return http.get('sites', params);
        }

        function editSite(data) {
            return http.post('sites/' + data.id + '/edit', data);
        }

        function editStyles(data) {
            return http.post('sites/' + data.id + '/edit_styles', data);
        }

        function updateConfig(data) {
            return http.post('sites/' + data.id + '/update_config', data);
        }

        function languages(params) {
            return http.get('sites/languages', params);
        }

        function formatSiteTitle(site) {
            return '<i class="md-icon">desktop_mac</i> ' + site.title + ' <span class="text-muted">• ' + $filter('simplifyUrl')(site.url) + '</span>';
        }

        function formatLanguageTitle(language) {
            return $filter('translate')('languages.' + language.name);
        }

        return {
            site: site,
            sites: sites,
            editSite: editSite,
            editStyles: editStyles,
            updateConfig: updateConfig,
            languages: languages,
            formatSiteTitle: formatSiteTitle,
            formatLanguageTitle: formatLanguageTitle
        };
    }
})(angular);
