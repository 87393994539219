(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['config'];

    function config(config) {
        config.auth = {
            checkInterval: 10000
        };
    }
})(angular);
