(function (angular) {
    angular
        .module('one.admin')
        .controller('DownloadOrderPdfModalController', DownloadOrderPdfModalController);

    DownloadOrderPdfModalController.$inject = ['$scope', '$uibModalInstance', 'notification', 'translateFilter', 'config', 'templates', 'params'];

    function DownloadOrderPdfModalController($scope, $uibModalInstance, notification, translateFilter, config, templates, params) {
        angular.merge($scope, params);

        angular.forEach(templates, function (template) {
            template.download_url = config.path.api('orders/' + $scope.order.id + '/download_pdf/' + template.id + '/' + template.client_filename);
        });

        $scope.templates = templates;
        $scope.template = null;

        $scope.close = $uibModalInstance.close;
    }
})(angular);
