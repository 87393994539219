(function (angular) {
    angular
        .module('one.admin')
        .directive('giftsDataframe', giftsDataframeDirective);

    giftsDataframeDirective.$inject = ['config'];

    function giftsDataframeDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                gifts: '=',
                name: '=',
                externalData: '&',
                hideColumns: '=',
                hideFilters: '=',
            },
            controller: ['$scope', 'AuthService', 'AccountService', 'ActionCampaignService', 'ActionProjectService', 'GroupService', 'ProductService', 'TagService', 'dataframe', 'session', function ($scope, AuthService, AccountService, ActionCampaignService, ActionProjectService, GroupService, ProductService, TagService, dataframe, session) {
                var dataframeName = ($scope.name || 'gifts');
                var sessionName = 'data.dataframes.' + dataframeName;

                $scope.gifts = dataframe.init({
                    name: dataframeName,
                    externalData: function (params) {
                        return $scope.externalData()(params);
                    },
                    cols: [
                        {
                            name: 'gift_amount',
                            title: 'Amount',
                            display: function (row) {
                                return '<span class="h4">{{ row.amount | money: \'&euro; \' }}</span> <span order-status-icon statuses="row.purchase.statuses"></span>';
                            },
                            weight: 2
                        },
                        {
                            name: 'from',
                            title: 'From',
                            display: function (row) {
                                return '<a ui-sref="crm.accounts.account.index({accountId: row.from.id})" ng-click="$event.stopPropagation()" class="text-limiter" ng-class="{archived: row.active && !row.from.active}"><i account-icon account="row.from"></i> {{ row.from.title }}</a>';
                            },
                            weight: 3
                        },
                        {
                            name: 'to',
                            title: 'To',
                            display: function (row) {
                                row.hide = $scope.hideColumns;

                                return '<gift-destination gift="row" hide="row.hide"></gift-destination>';
                            },
                            weight: 5
                        },
                        {
                            name: 'created_at',
                            title: 'Gifted at',
                            display: function (row) {
                                return '<small>{{ row.purchase.created_at | formatDate }}</small>';
                            },
                            weight: 1
                        }
                    ],
                    rowSref: function (row) {
                        if (AuthService.userCanAccess('gifts.view')) {
                            return 'crowdfunding.gifts.gift({giftId: row.id})';
                        }

                        return false;
                    },
                    rowOptions: [
                        {
                            title: 'Edit',
                            icon: 'edit',
                            sref: function (row) {
                                return 'crowdfunding.gifts.editGift({giftId: row.id})';
                            },
                            visible: function (row) {
                                return AuthService.userCanAccess('gifts.edit');
                            }
                        }
                    ],
                    state: session.get(sessionName, {
                        sort: {
                            id: 'desc'
                        }
                    }),
                    batchLimits: {
                        export: 50000
                    }
                });

                session.sync(sessionName, $scope.gifts.state);

                $scope.productCategoryFilters = [];

                ProductService.giftProductCategories().then(function (productCategories) {
                    $scope.productCategoryFilters = dataframe.initFilterList(productCategories || [], 'id', 'title');
                });

                $scope.getAccounts = function (params) {
                    params = params || {};
                    params.perPage = 100;

                    return AccountService.accounts(params).then(function (result) {
                        return dataframe.initFilterList(result.data || [], 'id', 'title');
                    });
                };

                $scope.getActionProjects = function (params) {
                    return ActionProjectService.actionProjects(params).then(function (actionProjects) {
                        return dataframe.initFilterList(actionProjects || [], 'id', 'title');
                    });
                };

                $scope.getActionCampaigns = function (params) {
                    return ActionCampaignService.actionCampaigns(params).then(function (actionProjects) {
                        return dataframe.initFilterList(actionProjects || [], 'id', 'title');
                    });
                };

                $scope.getGroups = function (params) {
                    return GroupService.groups(params).then(function (groups) {
                        return dataframe.initFilterList(groups || [], 'id', 'full_title');
                    });
                };

                $scope.formatAccountFilter = function (filter) {
                    return '<i class="md-icon">' + AccountService.accountIcon(filter.object.account_type_name) + '</i> {{ filter.object.title }}';
                };

                $scope.formatActionProjectFilter = function (filter) {
                    return ActionProjectService.formatActionProjectTitle(filter.object);
                };

                $scope.formatActionCampaignFilter = function (filter) {
                    return ActionCampaignService.formatActionCampaignTitle(filter.object);
                };

                $scope.formatGroupFilter = function (filter) {
                    return GroupService.formatGroupTitle(filter.object);
                };

                $scope.formatProductCategoryFilter = function (filter) {
                    return ProductService.formatProductCategoryTitle(filter.object);
                };
            }],
            templateUrl: config.path.template('gifts.gifts_dataframe')
        };
    }
}(angular));
