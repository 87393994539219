(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountDuplicatesController', AccountDuplicatesController);

    AccountDuplicatesController.$inject = ['$scope', 'AccountService', 'AuthService', 'DuplicateService', 'GroupService', 'dataframe', 'modal', 'translateFilter', 'account'];

    function AccountDuplicatesController($scope, AccountService, AuthService, DuplicateService, GroupService, dataframe, modal, translateFilter, account) {
        $scope.account = account;
        $scope.groups = GroupService.getLeafGroups($scope.account.groups);

        $scope.duplicates = dataframe.init({
            name: 'duplicates',
            externalData: function (params) {
                return getDuplicates($scope.account)(params).then(function (result) {
                    for (var i = 0; i < result.data.length; i++) {
                        result.data[i].duplicate_account.leaf_groups = GroupService.getLeafGroups(result.data[i].duplicate_account.groups);
                    }

                    return result;
                });
            },
            cols: [
                {
                    name: 'title',
                    title: 'Name',
                    display: function (row) {
                        return '<span class="h4"><i account-icon account="row.duplicate_account"></i> {{ row.duplicate_account.title }}</span> <span ng-repeat="group in row.duplicate_account.leaf_groups"><span group-label group="::group" shorten="10"></span> </span>';
                    },
                    weight: 3
                },
                {
                    title: 'Match based on',
                    name: 'match_type',
                    display: function (row) {
                        return row.match_types.map(function (matchType) {
                            return translateFilter('duplicate_match_types.' + matchType);
                        }).join(', ');
                    },
                    weight: 1
                },
                {
                    title: 'Added',
                    name: 'created_at',
                    display: function (row) {
                        return '{{ row.duplicate_account.created_at | formatDate }}';
                    },
                    weight: 1
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('accounts.view')) {
                    return 'crm.accounts.account.index({accountId: ' + row.duplicate_account.id + '})';
                }

                return false;
            },
            rowOptions: [
                {
                    title: 'Merge into contact',
                    icon: 'merge_type',
                    click: function (row) {
                        openMergeAccountsModal(row.duplicate_account, 'lg');
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('accounts.edit');
                    }
                },
                {
                    title: 'Hide',
                    icon: 'visibility_off',
                    click: function (row) {
                        DuplicateService.deactivateDuplicate($scope.account.id, row.duplicate_account.id).then(function () {
                            $scope.duplicates.getData();
                        });
                    },
                    visible: function (row) {
                        return row.active && AuthService.userCanAccess('duplicates.edit');
                    }
                },
                {
                    title: 'Show',
                    icon: 'visibility',
                    click: function (row) {
                        DuplicateService.reactivateDuplicate($scope.account.id, row.duplicate_account.id).then(function () {
                            $scope.duplicates.getData();
                        });
                    },
                    visible: function (row) {
                        return !row.active && AuthService.userCanAccess('duplicates.edit');
                    }
                }
            ],
            state: {
                sort: {
                    created_at: 'desc'
                },
                filters: {
                    active: [true]
                }
            }
        });

        $scope.activeState = function () {
            var active = null;

            if ($scope.duplicates.state.filters.active) {
                active = $scope.duplicates.state.filters.active[0];
            }

            return (active === true ? 'visible' : (active === false ? 'hidden' : 'all'));
        };

        $scope.openMergeAccountsModal = openMergeAccountsModal;

        function getDuplicates(account) {
            return function (params) {
                return AccountService.account.duplicates(account.id, params);
            };
        }

        function openMergeAccountsModal(duplicate) {
            modal.open('mergeAccounts', 'lg', { merge: { master: $scope.account, duplicate: duplicate } }).result.then(function (data) {
                $scope.duplicates.getData();
            });
        }
    }
})(angular);
