(function (angular) {
    angular
        .module('one.admin')
        .controller('AddIpLocationController', AddIpLocationController);

    AddIpLocationController.$inject = ['$scope', '$state', 'IpLocationService'];

    function AddIpLocationController($scope, $state, IpLocationService) {
        $scope.ipLocation = {
            title: '',
            ip_address: ''
        };

        $scope.add = add;

        function add(data) {
            IpLocationService.addIpLocation(data).then(function (data) {
                $state.transitionTo('ipLocations.index');
            });
        }
    }
})(angular);
