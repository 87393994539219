(function (angular) {
    angular
        .module('one.admin')
        .controller('AddNewsModulePostController', AddNewsModulePostController);

    AddNewsModulePostController.$inject = ['$scope', '$state', 'NewsModuleService', 'module'];

    function AddNewsModulePostController($scope, $state, NewsModuleService, module) {
        $scope.post = {
            image: null,
            pinned: false,
            status: 'after_publication',
            created_at: moment().format('YYYY-MM-DD'),
            alt_text: ''
        };

        $scope.module = module;

        $scope.addPost = addPost;

        function addPost(data) {
            NewsModuleService.addPost(module.module_instance.id, data).then(function () {
                $state.transitionTo('content.modules.newsModule.index', {
                    moduleId: module.id
                });
            });
        }
    }
})(angular);
