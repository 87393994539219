(function (angular) {
    angular
        .module('one.admin')
        .controller('AddHtmlModuleModalController', AddHtmlModuleModalController);

    AddHtmlModuleModalController.$inject = ['$scope', '$uibModalInstance','ModuleService', 'params'];

    function AddHtmlModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                content: '',
                placeholders: [],
            }
        };

        angular.merge($scope, params);

        $scope.addHtmlModule = addHtmlModule;
        $scope.cancel = cancel;

        function addHtmlModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
