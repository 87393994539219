(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['RollbarProvider'];

    function config(RollbarProvider) {
        RollbarProvider.init({
            accessToken: '2940ac0688254f8c9478c804cc44a1e6',
            captureUncaught: true,
            rollbarJsUrl: 'scripts/rollbar.js',
            payload: {
                environment: 'production'
            },
            ignoredMessages: [
                "Ongeldig argument.",
                "Onvoldoende geheugen",
                "Onvoldoende opslagruimte beschikbaar om deze bewerking te voltooien."
            ],
            itemsPerMinute: 1,
            captureIp: 'anonymize'
        });
    }
})(angular);