(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPageBlockController', EditPageBlockController);

    EditPageBlockController.$inject = ['$scope', '$state', 'modal', 'PageService', 'pageBlock', 'languages'];

    function EditPageBlockController($scope, $state, modal, PageService, pageBlock, languages) {
        $scope.pageBlock = pageBlock;
        $scope.languages = languages;

        $scope.editPageBlock = editPageBlock;
        $scope.openAuditorModal = openAuditorModal;

        function editPageBlock(data) {
            PageService.editBlock(data).then(function (data) {
                $state.transitionTo('content.sites.pageBlocks');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: pageBlock.id, modelType: 'page_block' });
        }
    }
})(angular);
