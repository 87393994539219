(function (angular) {
    angular
        .module('one.admin')
        .controller('PagesController', PagesController);

    PagesController.$inject = ['$scope', 'session', 'string', 'AuthService', 'PageService', 'SiteService', 'dataframe', 'translateFilter', 'languages'];

    function PagesController($scope, session, string, AuthService, PageService, SiteService, dataframe, translateFilter, languages) {
        var currentSite = session.get('data.current_site');
        var sessionName = 'data.dataframes.pages.' + currentSite.name;

        $scope.pages = dataframe.init({
            externalData: function (params) {
                params = params || {};

                params.filters = angular.merge({}, params.filters, {
                    sites: [currentSite.id]
                });

                return PageService.pages(params);
            },
            cols: [
                {
                    title: 'Page',
                    name: 'title',
                    display: function (row) {
                        row.flag_labels = [];

                        angular.forEach(row.flags, function (flag) {
                            row.flag_labels.push('<span class="label label-default">' + translateFilter('page_flags.' + flag.name) + '</span>');
                        });

                        var output = '<h4><i class="md-icon">description</i> {{ row.title }}</h4>';
                        output += '<small list-limiter list="row.flag_labels" delimiter="\' \'" ng-class="{ \'m-r-1_2\': row.flag_labels.length > 0 }"></small>'
                        output += '<small class="text-muted" ng-if="row.visitable.url_title">/{{ row.visitable.url_title }}</small>';

                        return output;
                    },
                    sortable: true,
                    weight: 5
                },
                {
                    title: 'Access',
                    name: 'access_level',
                    display: function (row) {
                        if (row.access_segment && row.access_level == PageService.constants.access_levels.login) {
                            return '<a ui-sref="crm.segments.segment({ segmentId: row.access_segment.id })" ng-click="$event.stopPropagation()" class="text-limiter"><i class="md-icon">filter_list</i> {{ row.access_segment.title }}</a>';
                        }

                        return '<span page-access-level-icon access-level="' + row.access_level + '"></span> ' + string.ucfirst(row.access_level);
                    },
                    sortable: true,
                    weight: 3
                },
                {
                    title: 'Redirect',
                    name: 'redirect',
                    display: function (row) {
                        if (row.redirect_visitable) {
                            return '<a ui-sref="content.pages.pageContent({pageId: row.redirect_visitable.visitable_instance.id })" ng-click="$event.stopPropagation()"><i class="md-icon">description</i> {{ row.redirect_visitable.visitable_instance.title }}</a>';
                        }

                        return '';
                    },
                    weight: 3
                },
                {
                    title: 'Language',
                    name: 'language_name',
                    display: function (row) {
                        return '{{ \'languages.\' + row.language_name | translate }}';
                    },
                    weight: 2
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('pages.edit')) {
                    return 'content.pages.pageContent({pageId: row.id})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        if (AuthService.userCanAccess('pages.edit')) {
                            return 'content.pages.pageContent({pageId: ' + row.id + '})';
                        }
                        return false;
                    },
                    visible: function (row) {
                        return row.editable && AuthService.userCanAccess('pages.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.pages.loading = true;

                        PageService.duplicatePage(row).then(function () {
                            $scope.pages.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('pages.add');
                    }
                },
                {
                    title: 'Visit',
                    icon: 'launch',
                    href: function (row) {
                        return row.full_url;
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeletePageModal(row.id);
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('pages.delete');
                    }
                }
            ],
            state: session.get(sessionName, {
                sort: { title: 'asc' }
            })
        });

        session.sync(sessionName, $scope.pages.state);

        $scope.languageFilters = [];

        SiteService.languages().then(function (languages) {
            $scope.languageFilters = dataframe.initFilterList(languages || [], 'name', SiteService.formatLanguageTitle);
        });

        $scope.togglePageVisibility = togglePageVisibility;

        function openDeletePageModal(id) {
            PageService.openDeletePageModal(id, function () {
                $scope.pages.getData();
            });
        }

        function togglePageVisibility(id) {
            return id;
        }
    }
})(angular);
