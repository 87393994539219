(function (angular) {
    angular
        .module('one.admin')
        .service('FileService', FileService);

    FileService.$inject = ['http', 'config'];

    function FileService(http, config) {
        function file(id) {
            return http.get('files/' + id);
        }

        function files(params) {
            return http.get('files', params);
        }

        function images(params) {
            params = params || {};
            params.filters = angular.extend({}, params.filters, { file_types: ['image'] });

            return files(params);
        }

        function addFiles(data) {
            return http.post('files/add', data);
        }

        function deleteFile(id) {
            return http.post('files/' + id + '/delete');
        }

        function editFile(data) {
            return http.post('files/' + data.id + '/edit', data);
        }

        function downloadUrl(file) {
            return config.path.api('files/' + file.id + '/download/' + file.client_filename);
        }

        function filenameToFaIcon(filename) {
            var inArray = function (key, array) {
                return array.indexOf(key) > -1;
            };

            var extension = (filename || '').split('.').pop().toLowerCase();

            if (inArray(extension, ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg'])) {
                return 'file-image';
            } else if (inArray(extension, ['pdf'])) {
                return 'file-pdf';
            } else if (inArray(extension, ['7zip', 'bz', 'gz', 'iso', 'rar', 'tar', 'zip', 'dmg', 'tgz'])) {
                return 'file-archive';
            } else if (inArray(extension, ['doc', 'docx'])) {
                return 'file-word';
            } else if (inArray(extension, ['xml', 'html', 'php', 'js', 'css', 'java', 'py', 'c', 'cpp'])) {
                return 'file-code';
            } else if (inArray(extension, ['mp4', 'avi', 'mkv', 'flv', 'vob', 'wmv', 'rm', 'asf', 'm2v', 'm4v', '3gp', '3g2', 'mpg', 'mpeg', 'mpv', 'mov', 'qt', 'webm'])) {
                return 'file-video';
            } else if (inArray(extension, ['ppt', 'pptx'])) {
                return 'file-powerpoint';
            } else if (inArray(extension, ['txt', 'pages'])) {
                return 'file-alt';
            } else if (inArray(extension, ['mp3', 'flac', 'm4a', 'ogg', 'wav', 'wma'])) {
                return 'file-audio';
            } else if (inArray(extension, ['xls', 'xlsx'])) {
                return 'file-excel';
            }

            return 'file';
        }

        function formatFilename(file) {
            var output = '';

            if (file.thumbnail) {
                output += '<img src="' + file.thumbnail.url + '" alt="' + file.client_filename + '" class="thumb-icon" />';
            } else {
                output += '<i class="fas fa-' + filenameToFaIcon(file.client_filename) + '"></i>';
            }

            output += ' ' + file.client_filename;

            if (file.width && file.height) {
                output += ' <span class="text-muted">(' + file.width + ' &times; ' + file.height + ')</span>';
            }

            return output;
        }

        return {
            file: file,
            files: files,
            images: images,
            addFiles: addFiles,
            editFile: editFile,
            deleteFile: deleteFile,
            downloadUrl: downloadUrl,
            filenameToFaIcon: filenameToFaIcon,
            formatFilename: formatFilename
        };
    }
})(angular);
