(function (angular) {
    angular
        .module('one.admin')
        .controller('TransactionsController', TransactionController);

    TransactionController.$inject = ['$scope', '$filter', 'AuthService', 'TransactionService', 'dataframe', 'modal', 'notification', 'session', 'string'];

    function TransactionController($scope, $filter, AuthService, TransactionService, dataframe, modal, notification, session, string) {
        $scope.transactions = dataframe.init({
            name: 'transactions',
            externalData: TransactionService.transactions,
            cols: [
                {
                    title: 'Transaction',
                    name: 'id',
                    display: function (row) {
                        return '<span transaction-label transaction="row"></span>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Amount',
                    name: 'amount',
                    weight: 2,
                    display: function (row) {
                        return '<h4>{{ row.failed ? row.cancelled_gross_amount : (row.refunded ? row.refunded_gross_amount : row.gross_amount) | money: \'&euro; \' }} <span tag-indicator tags="row.tags"></span></h4>';
                    },
                    sortable: true
                },
                {
                    title: 'Contact',
                    name: 'account',
                    display: function (row) {
                        return '<a ui-sref="crm.accounts.account.index({accountId: row.order.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.order.account.active}"><i account-icon account="row.order.account"></i> {{ row.order.account.title }}</a>';
                    },
                    weight: 3
                },
                {
                    title: 'Payment method',
                    name: 'order.payment_method.title',
                    weight: 3
                },
                {
                    title: 'IBAN',
                    name: 'bank_account',
                    weight: 3,
                    display: function (row) {
                        var bankAccount = null;

                        if (row.order.payment) {
                            bankAccount = row.order.payment.bank_account;
                        }

                        if (!bankAccount && row.transactionable) {
                            bankAccount = row.transactionable.bank_account;
                        }

                        if (bankAccount) {
                            var output = '<div>' + bankAccount.iban + '</div>';

                            if (bankAccount.account_holder) {
                                output += '<div>' + bankAccount.account_holder + '</div>';
                            }

                            return output;
                        }

                        return '';
                    },
                    visible: false
                },
                {
                    title: 'Contact details',
                    name: 'contact_details',
                    weight: 3,
                    display: function (row) {
                        row.order.account.phone_numbers = [];

                        if (row.order.account.phone_private) {
                            row.order.account.phone_numbers.push(string.escape(row.order.account.phone_private) + ' <span class="light">(private)</span>');
                        }

                        if (row.order.account.phone_work) {
                            row.order.account.phone_numbers.push(string.escape(row.order.account.phone_work) + ' <span class="light">(work)</span>');
                        }

                        var output = '<div><a ui-sref="crm.accounts.account.index({accountId: row.order.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.order.account.active}"><i account-icon account="row.order.account"></i> {{ row.order.account.title }}</a></div>';

                        if (row.order.account.email) {
                            output += '<div>{{ row.order.account.email }}</div>';
                        }

                        if (row.order.account.phone_numbers) {
                            row.delimiter = ' <span class="text-muted">/</span> ';

                            output += '<div list-limiter limit="3" list="row.order.account.phone_numbers" delimiter="row.delimiter"></div>';
                        }

                        return output;
                    },
                    visible: false
                },
                {
                    title: 'Note',
                    name: 'note',
                    weight: 3,
                    display: function (row) {
                        return $filter('nl2br')(row.note || '');
                    },
                    visible: false
                },
                {
                    title: 'Collect date',
                    name: 'collect_date',
                    weight: 2,
                    display: function (row) {
                        return $filter('formatDate')(row.collect_date);
                    },
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('transactions.view')) {
                    return 'finance.transactions.transaction({transactionId: ' + row.id + '})';
                }
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'finance.transactions.editTransaction({transactionId:' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('transactions.edit');
                    }
                }
            ],
            filterables: [
                'id', 'account_holder', 'iban', 'reference', 'collect_date', 'created_at'
            ],
            filterableLabels: {
                id: 'transaction_id',
                reference: 'mandate_reference'
            },
            state: session.get('data.dataframes.transactions', {
                sort: { id: 'desc' }
            }),
            batchLimits: {
                export: 50000
            }
        });

        session.sync('data.dataframes.transactions', $scope.transactions.state);

        $scope.colPresets = [
            {
                name: 'general',
                cols: ['id', 'amount', 'status', 'account', 'payment_method', 'collect_date']
            },
            {
                name: 'iban',
                cols: ['id', 'amount', 'status', 'account', 'bank_account', 'collect_date']
            },
            {
                name: 'contact_details',
                cols: ['id', 'amount', 'status', 'contact_details', 'note', 'collect_date']
            }
        ];

        if (!$scope.transactions.state.activeColPreset) {
            $scope.transactions.state.activeColPreset = $scope.colPresets[0].name;
        }
    }
})(angular);
