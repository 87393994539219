(function (angular) {
    angular
        .module('one.admin')
        .service('CampaignService', CampaignService);

    CampaignService.$inject = ['http', 'modal'];

    function CampaignService(http, modal) {
        function campaign(id) {
            return http.get('campaigns/' + id);
        }

        function campaigns(params) {
            return http.get('campaigns', params);
        }

        function mailings(campaignId, params) {
            return http.get('campaigns/' + campaignId + '/mailings', params);
        }

        function addCampaign(data) {
            return http.post('campaigns/add', data);
        }

        function deleteCampaign(id) {
            return http.post('campaigns/' + id + '/delete');
        }

        function editCampaign(data) {
            return http.post('campaigns/' + data.id + '/edit', data);
        }

        function duplicateCampaign(data) {
            return http.post('campaigns/' + data.id + '/duplicate', data);
        }

        function openDeleteCampaignModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this campaign?'
            }).result.then(function (data) {
                return deleteCampaign(id).then(callback);
            });
        }

        return {
            campaign: campaign,
            campaigns: campaigns,
            mailings: mailings,
            addCampaign: addCampaign,
            deleteCampaign: deleteCampaign,
            duplicateCampaign: duplicateCampaign,
            editCampaign: editCampaign,
            openDeleteCampaignModal: openDeleteCampaignModal,
        };
    }
})(angular);
