(function (angular) {
    angular
        .module('one.admin')
        .controller('FilesController', FilesController);

    FilesController.$inject = ['$scope', '$filter', '$timeout', 'session', 'AuthService', 'FileService', 'dataframe', 'modal', 'notification'];

    function FilesController($scope, $filter, $timeout, session, AuthService, FileService, dataframe, modal, notification) {
        var currentSite = session.get('data.current_site');
        var sessionName = 'data.dataframes.files.' + currentSite.name;
        var refreshTimer;
        var uploadsCount = 0;

        $scope.site = currentSite;

        $scope.files = dataframe.init({
            externalData: function (params) {
                params.filters = {
                    sites: [currentSite.id]
                };

                return FileService.files(params);
            },
            cols: [
                {
                    title: 'Filename',
                    name: 'filename',
                    weight: 2,
                    display: function (row) {
                        var output = '<h4>';

                        if (row.thumbnail) {
                            output += '<img src="' + row.thumbnail.url + '" alt="' + row.client_filename + '" class="thumb-icon" /> ';
                        } else {
                            output += '<i class="fas fa-' + FileService.filenameToFaIcon(row.client_filename) + '"></i>';
                        }

                        output += ' {{ row.filename }}</h4>';

                        return output;
                    },
                    sortable: true
                },
                {
                    title: 'Size',
                    name: 'size',
                    weight: 1,
                    display: function (row) {
                        return '{{ row.size | bytes }}';
                    },
                    sortable: true
                },
                {
                    title: 'Dimensions',
                    name: 'dimensions',
                    weight: 1,
                    display: function (row) {
                        return row.width ? '{{ row.width }} &times; {{ row.height }}' : '';
                    },
                    sortable: true
                },
                {
                    title: 'Uploaded at',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'short');
                    },
                    sortable: true
                }
            ],
            rowOptions: [
                {
                    title: 'Visit',
                    icon: 'launch',
                    href: function (row) {
                        return row.url;
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteFileModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('files.delete');
                    }
                }
            ],
            rowHref: function (row) {
                return row.url;
            },
            state: session.get(sessionName, {
                sort: { created_at: 'desc' }
            })
        });

        session.sync(sessionName, $scope.files.state);

        $scope.filesUploaded = filesUploaded;
        $scope.openDeleteFileModal = openDeleteFileModal;

        function filesUploaded() {
            $timeout.cancel(refreshTimer);
            uploadsCount++;

            refreshTimer = $timeout(function () {
                notification.success($filter('translate')('general.n_files_uploaded', { n: uploadsCount }));

                $scope.files.getData();

                uploadsCount = 0;
            }, 500);
        }

        function openDeleteFileModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this file?' }).result.then(function (data) {
                deleteFile(id);
            });
        }

        function deleteFile(id) {
            FileService.deleteFile(id).then(function (data) {
                $scope.files.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }
    }
})(angular);
