(function (angular) {
    angular
        .module('one.admin')
        .service('TransactionService', TransactionService);

    TransactionService.$inject = ['http'];

    function TransactionService(http) {
        var statuses = [
            'authorised',
            'in_progress',
            'paid',
            'paused',
            'reversed',
            'refunded',
            'cancelled',
            'expired',
            'error',
            'refused'
        ];

        var failedStatuses = ['reversed', 'refunded', 'cancelled', 'expired', 'error', 'refused'];
        var openStatuses = ['authorised', 'in_progress', 'paused'];
        var paidStatuses = ['paid'];

        var actions = {
            authorise: 'authorise',
            finalize: 'finalize',
            pause: 'pause',
            cancel: 'cancel',
            reverse: 'reverse',
            refund: 'refund'
        };

        function transaction(id) {
            return http.get('transactions/' + id);
        }

        transaction.applyAction = function (id, action, params) {
            params = params || {};
            params.action = action;

            return http.post('transactions/' + id + '/apply_action', params);
        };

        function transactions(params) {
            return http.get('transactions', params);
        }

        function addTransaction(data) {
            return http.post('transactions/add', data);
        }

        function deleteTransaction(id) {
            return http.post('transactions/' + id + '/delete');
        }

        function editTransaction(data) {
            return http.post('transactions/' + data.id + '/edit', data);
        }

        return {
            transaction: transaction,
            transactions: transactions,
            addTransaction: addTransaction,
            deleteTransaction: deleteTransaction,
            editTransaction: editTransaction,
            statuses: statuses,
            failedStatuses: failedStatuses,
            openStatuses: openStatuses,
            paidStatuses: paidStatuses,
            actions: actions
        };
    }
})(angular);
