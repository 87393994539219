(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFormFieldModalController', AddFormFieldModalController);

    AddFormFieldModalController.$inject = ['$scope', '$uibModalInstance', '$filter', '$timeout', 'FormService', 'params'];

    function AddFormFieldModalController($scope, $uibModalInstance, $filter, $timeout, FormService, params) {
        var fieldTypeScopes = {
            'generic_string': 'basic',
            'generic_text': 'basic',
            'generic_date': 'basic',
            'generic_integer': 'basic',
            'generic_email': 'basic',
            'generic_money': 'basic',
            'account.email_username': 'account',
            'account.first_name': 'account',
            'account.gender': 'account',
            'account.house_number': 'account',
            'account.house_number_addition': 'account',
            'account.image': 'account',
            'account.infix': 'account',
            'account.initials': 'account',
            'account.last_name': 'account',
            'account.note': 'account',
            'account.password': 'account',
            'account.phone_emergency': 'account',
            'account.phone_home': 'account',
            'account.phone_private': 'account',
            'account.phone_work': 'account',
            'account.postal_city': 'account',
            'account.postal_country': 'account',
            'account.postal_house_number': 'account',
            'account.postal_house_number_addition': 'account',
            'account.postal_street': 'account',
            'account.postal_zip_code': 'account',
            'account.secondary_email': 'account',
            'account.street': 'account',
            'account.subscribe_to_mails': 'account',
            'account.title': 'account',
            'account.unsubscribe_from_mails': 'account',
            'account.variables.tshirt_size': 'account',
            'account.zip_code': 'account',
            'account': 'account',
            'account.city': 'account',
            'account.email': 'account',
            'account.date_of_birth': 'account',
            'account.country': 'account',
            'account_type': 'account',
            'group': 'account',
            'sub_group': 'account',
            'file': 'account',
            'language': 'account',
            'order.reference': 'order',
            'purchase': 'order',
            'payment_method': 'order',
            'bank_account': 'order',
            'ideal_bank': 'order',
            'idin_bank': 'order',
            'discount_code': 'order',
            'gift.destination': 'order',
            'gift.message': 'order',
            'gift.privacy': 'order',
            'action_campaign.target': 'action_campaign',
            'action_campaign.title': 'action_campaign',
            'action_campaign.type': 'action_campaign',
            'action_campaign.end_date': 'action_campaign',
            'action_campaign.description': 'action_campaign',
            'action_campaign.active': 'action_campaign',
            'action_campaign.image': 'action_campaign',
            'action_campaign.cause_title': 'action_campaign',
            'action_campaign.cause_iban': 'action_campaign',
            'action_campaign': 'action_campaign',
            'action_campaign.child.target': 'action_campaign',
            'action_campaign.child.image': 'action_campaign',
            'action_campaign.child.active': 'action_campaign',
            'action_campaign.child.description': 'action_campaign',
            'action_project': 'action_campaign',
        };

        $scope.fieldTypes = {
            basic: [],
            account: [],
            order: [],
            action_campaign: [],
            other: []
        };

        $scope.focus = {
            advanced: 0,
            title: 1
        };

        $scope.fieldTypeIcons = {
            open: 'short_text',
            multiple_choice: 'radio_button_checked',
            selection: 'check_box'
        };

        $scope.state = {
            fieldType: params.page ? 'open' : 'hidden',
            scope: 'basic'
        };

        FormService.fieldTypes().then(function (fieldTypes) {
            angular.forEach(fieldTypes, function (fieldType) {
                fieldType.title = FormService.formatFieldTypeTitle(fieldType);

                var fieldTypeScope = fieldTypeScopes[fieldType.name] || 'other';

                $scope.fieldTypes[fieldTypeScope].push(fieldType);
            });

            $scope.field = {
                type: $scope.fieldTypes.basic[0],
                form: params.form,
                page: params.page,
                rules: angular.merge($scope.fieldTypes.basic[0].rules, { choices: {} })
            };
        });

        $scope.fieldTypeIcon = FormService.fieldTypeIcon;

        $scope.advanced = {};

        $scope.$watch('field.type', function (newType) {
            if (newType) {
                $scope.field.default_value = newType.default_value;
                $scope.field.rules = angular.merge(newType.rules, { choices: $scope.field.rules.choices });

                if (!params.page) {
                    $scope.setHiddenField();
                } else if (!newType['allow_' + $scope.state.fieldType]) {
                    if (newType.allow_open) {
                        $scope.setOpenField();
                    } else if (newType.allow_multiple_choice) {
                        $scope.setMultipleChoiceField();
                    } else if (newType.allow_selection) {
                        $scope.setSelectionField();
                    }
                }
            }
        });

        $scope.titlePlaceholder = titlePlaceholder;
        $scope.add = add;
        $scope.cancel = cancel;

        $scope.setOpenField = function () {
            $scope.state.fieldType = 'open';

            $scope.field.rules.choices.min = null;
            $scope.field.rules.choices.max = null;

            if (!$scope.field.type.allow_open) {
                $scope.field.type = $scope.fieldTypes.basic[0];
            }

            $scope.focus.title += 1;
        };

        $scope.setMultipleChoiceField = function () {
            $scope.state.fieldType = 'multiple_choice';

            $scope.field.rules.choices.min = 1;
            $scope.field.rules.choices.max = 1;

            if (!$scope.field.type.allow_multiple_choice) {
                $scope.field.type = $scope.fieldTypes.basic.multiple_choice[0];
            }

            $scope.focus.title += 1;
        };

        $scope.setSelectionField = function () {
            $scope.state.fieldType = 'selection';

            $scope.field.rules.choices.min = 0;
            $scope.field.rules.choices.max = null;

            if (!$scope.field.type.allow_selection) {
                $scope.field.type = $scope.fieldTypes.basic.selection[0];
            }

            $scope.focus.title += 1;
        };

        $scope.setHiddenField = function () {
            $scope.state.fieldType = 'hidden';

            $scope.field.page = null;
            $scope.field.enabled = true;
            $scope.field.rules.choices.min = null;
            $scope.field.rules.choices.max = null;

            $scope.focus.title += 1;
        };

        function titlePlaceholder() {
            if ($scope.field.type) {
                return $filter('translate')('form_field_types.' + $scope.field.type.name).split(' › ').slice(-1)[0];
            }

            return null;
        }

        function add() {
            if (!$scope.field.title) {
                $scope.field.title = titlePlaceholder();
            }

            FormService.addFormField($scope.field).then(function (data) {
                $uibModalInstance.close(data);
            });
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
