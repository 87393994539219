(function (angular) {
    angular
        .module('one.admin')
        .controller('SendTestMailModalController', SendTestMailModalController);

    SendTestMailModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'MailingService', 'config', 'params'];

    function SendTestMailModalController($scope, $uibModalInstance, AccountService, MailingService, config, params) {
        angular.extend($scope, params);

        $scope.previewPath = config.path.api('mailings/' + $scope.mailing.id + '/preview');
        $scope.previewCompiledPath = config.path.api('mailings/' + $scope.mailing.id + '/preview_mail');

        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        $scope.account = null;

        $scope.send = send;
        $scope.cancel = cancel;

        function send() {
            if ($scope.loading) {
                return;
            }

            MailingService.sendTestMail($scope.mailing.id, $scope.account.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
