(function (angular) {
    angular
        .module('one.admin')
        .service('PdfTemplateService', PdfTemplateService);

    PdfTemplateService.$inject = ['http'];

    function PdfTemplateService(http) {
        function pdfTemplate(id) {
            return http.get('pdf_templates/' + id);
        }

        function pdfTemplates(params) {
            return http.get('pdf_templates', params);
        }

        function addPdfTemplate(data) {
            return http.post('pdf_templates/add', data);
        }

        function deletePdfTemplate(id) {
            return http.post('pdf_templates/' + id + '/delete');
        }

        function editPdfTemplate(data) {
            return http.post('pdf_templates/' + data.id + '/edit', data);
        }

        function duplicatePdfTemplate(data) {
            return http.post('pdf_templates/' + data.id + '/duplicate', data);
        }

        return {
            pdfTemplate: pdfTemplate,
            pdfTemplates: pdfTemplates,
            addPdfTemplate: addPdfTemplate,
            deletePdfTemplate: deletePdfTemplate,
            duplicatePdfTemplate: duplicatePdfTemplate,
            editPdfTemplate: editPdfTemplate,
        };
    }
})(angular);
