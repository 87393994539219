(function (angular) {
    angular
        .module('one.admin')
        .controller('AddEventController', AddEventController);

    AddEventController.$inject = ['$scope', '$state', 'EventService', 'sites'];

    function AddEventController($scope, $state, EventService, sites) {
        $scope.sites = sites;

        $scope.event = {
            site: $scope.sites[0],
            tickets: [],
            products: [],
            payment_methods: [],
        };

        $scope.addEvent = addEvent;

        function addEvent(data) {
            EventService.addEvent(data).then(function (data) {
                $state.transitionTo('events.events.event', { eventId: data.event.id });
            });
        }
    }
})(angular);
