(function (angular) {
    angular
        .module('one.admin')
        .controller('EditModuleController', EditModuleController);

    EditModuleController.$inject = ['$scope', '$state', 'modal', 'ModuleService', 'module'];

    function EditModuleController($scope, $state, modal, ModuleService, module) {
        $scope.module = module;

        $scope.loading = false;

        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;

        function editModule(data, state) {
            $scope.loading = true;

            ModuleService.editModule(data).then(function (data) {
                $scope.loading = false;

                if (!state) {
                    state = ModuleService.getReturnState($scope.module);
                }

                $state.transitionTo(state.name, state.params);
            }, function (data) {
                $scope.errors = data.errors;
                $scope.loading = false;
            });
        };

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);
