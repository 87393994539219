(function (angular) {
    angular
        .module('one.admin')
        .controller('IpLocationController', IpLocationController);

    IpLocationController.$inject = ['$scope', 'AuthService', 'IpLocationService', 'dataframe', 'string', 'session', 'ipLocation'];

    function IpLocationController($scope, AuthService, IpLocationService, dataframe, string, session, ipLocation) {
        $scope.ipLocation = ipLocation;

        $scope.users = dataframe.init({
            externalData: function (params) {
                return IpLocationService.ipLocation.users($scope.ipLocation.id, params);
            },
            cols: [
                {
                    title: 'E-mail address',
                    name: 'email',
                    display: function (row) {
                        var color, icon;

                        if (!row.active) {
                            color = 'text-muted';
                            icon = 'lock_outline';
                        } else if (row.two_factor_enabled || row.ip_locations.length > 0) {
                            color = 'text-success';
                            icon = 'lock_outline';
                        } else {
                            color = 'text-danger';
                            icon = 'lock_open';
                        }

                        return '<span class="text-limiter"><i class="md-icon ' + color + '">' + icon + '</i> {{ row.email }}</span>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Name',
                    name: 'first_name',
                    weight: 2,
                    display: function (row) {
                        return '{{ row.full_name }}';
                    },
                    sortable: true
                },
                {
                    title: 'Two-factor',
                    name: 'two_factor_enabled',
                    display: function (row) {
                        return '<i class="md-icon" ng-if="row.two_factor_enabled">check</i>';
                    },
                    weight: 1,
                    sortable: false
                },
                {
                    title: 'IP-check',
                    name: 'ip_whitelisted',
                    display: function (row) {
                        return '<i class="md-icon" ng-if="row.ip_locations.length > 0">check</i>';
                    },
                    weight: 1,
                    sortable: false
                },
                {
                    title: 'Roles',
                    name: 'auth_roles',
                    display: function (row) {
                        row.role_labels = [];

                        angular.forEach(row.auth_roles, function (authRole) {
                            row.role_labels.push('<span class="label label-default label-list-item">' + string.escape(authRole.title) + '</span>');
                        });

                        return '<span class="label-list" list-limiter list="row.role_labels" delimiter="\' \'" shorten="true" limit="1"></span>';
                    },
                    weight: 2
                },
                {
                    title: 'Last activity',
                    name: 'last_activity',
                    display: function (row) {
                        if (row.active) {
                            return '<div class="text-muted" am-time-ago="row.last_activity"></div>';
                        }
                        
                        return '';
                    },
                    weight: 2,
                    sortable: true
                }
            ],
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'users.editUser({userId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('users.edit');
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('users.view')) {
                    return 'users.user({userId: ' + row.id + '})';
                }
                return false;
            },
            state: session.get('data.dataframes.users', { sort: { first_name: 'asc' } })
        });
    }
})(angular);
