(function (angular) {
    angular
        .module('one.admin')
        .service('BankAccountService', BankAccountService);

    BankAccountService.$inject = ['http'];

    function BankAccountService(http) {
        function bankAccount(id) {
            return http.get('bank_accounts/' + id);
        }

        function bankAccounts(params) {
            return http.get('bank_accounts', params);
        }

        function addBankAccount(data) {
            return http.post('bank_accounts/add', data);
        }

        function deleteBankAccount(id) {
            return http.post('bank_accounts/' + id + '/delete');
        }

        function editBankAccount(data) {
            return http.post('bank_accounts/' + data.id + '/edit', data);
        }

        return {
            bankAccount: bankAccount,
            bankAccounts: bankAccounts,
            addBankAccount: addBankAccount,
            editBankAccount: editBankAccount,
            deleteBankAccount: deleteBankAccount
        };
    }
})(angular);
