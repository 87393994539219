(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$state', '$window'];

    function run($rootScope, $state, $window) {
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.state = toState.name;
            $rootScope.stateParams = toParams;

            $rootScope.previousState = fromState;
            $rootScope.previousStateParams = fromParams;
        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $window.scrollTo(0, 0);
        });
    }
})(angular);
