(function (angular) {
    angular
        .module('one.admin')
        .directive('auditLogEntries', auditLogEntriesDirective);

    auditLogEntriesDirective.$inject = ['config'];

    function auditLogEntriesDirective(config) {
        controller.$inject = ['$scope', 'AuditService', 'dataframe'];

        function controller($scope, AuditService, dataframe) {
            $scope.logEntries = dataframe.init({
                externalData: function (params) {
                    return AuditService.logEntries($scope.modelType, $scope.modelId, params);
                },
                cols: [
                    {
                        name: 'timestamp',
                        display: function (row) {
                            return '<div class="text-muted">{{ row.timestamp | formatDate: \'medium\' }}</div>';
                        },
                        weight: 1
                    },
                    {
                        name: 'event',
                        display: function (row) {
                            return '<div audit-log-entry-description log-entry="row" model-type="' + $scope.modelType + '" model-id="' + $scope.modelId + '"></div>';
                        },
                        weight: 4
                    }

                ],
                showHeader: false,
                state: {
                    perPage: $scope.perPage || 8
                }
            });
        }

        return {
            restrict: 'AE',
            scope: {
                modelType: '@',
                modelId: '=',
                perPage: '='
            },
            controller: controller,
            templateUrl: config.path.template('auditor.log_entries')
        };
    }
})(angular);
