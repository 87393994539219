(function (angular) {
    angular
        .module('one.admin')
        .controller('EditSegmentController', EditSegmentController);

    EditSegmentController.$inject = ['$scope', '$state', 'modal', 'segment'];

    function EditSegmentController($scope, $state, modal, segment) {
        $scope.segment = segment;

        $scope.openDiffSegmentModal = openDiffSegmentModal;

        function openDiffSegmentModal(segment) {
            modal.open('diffSegment', 'lg', { segment: segment }).result.then(function (data) {
                $state.transitionTo('crm.segments.segment', { segmentId: segment.id });
            });
        }
    }
})(angular);
