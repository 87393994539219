(function (angular) {
    angular
        .module('one.admin')
        .provider('modal', modalProvider);

    modalProvider.$inject = [];

    /**
     * Register and open modals using ui-bootstrap.
     */
    function modalProvider() {
        var modals = {};

        this.$get = $get;
        this.modal = modal;

        $get.$inject = ['$uibModal'];

        function $get($uibModal) {
            this.open = openModal;

            function openModal(modalName, size, params) {
                if (size) {
                    modals[modalName].size = size;
                }

                if (!('resolve' in modals[modalName])) {
                    modals[modalName].resolve = {};
                }

                modals[modalName].resolve.params = function () {
                    return params;
                };

                return $uibModal.open(modals[modalName]);
            }

            return this;
        }

        function modal(name, options) {
            modals[name] = options;
        }

        return this;
    }
})(angular);
