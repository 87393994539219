(function (angular) {
    angular
        .module('one.admin')
        .directive('formEntryValueEditor', formEntryValueEditor);

    formEntryValueEditor.$inject = ['config'];

    function formEntryValueEditor(config) {
        return {
            restrict: 'EA',
            scope: {
                value: '=',
                field: '=',
                rules: '=',
                formEntry: '=',
                nullable: '=',
                disabled: '=',
                change: '&'
            },
            controller: ['$scope', '$filter', 'services', function ($scope, $filter, services) {
                angular.merge($scope, services);

                $scope.model = {
                    value: $scope.value
                };

                $scope.$watch('model.value', function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        $scope.value = newValue;
                    }
                });

                $scope.$watch('value', function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        $scope.model.value = newValue;
                    }
                });

                $scope.viewPath = function () {
                    var type = $scope.field.type ? $scope.field.type.name : $scope.field.form_field_type_name;

                    return config.path.template('forms.entries.editor.values.' + type.replace(/\-/g, '_'));
                };

                $scope.getOptions = function (scope, filters) {
                    return function (params) {
                        params = angular.merge({}, params, { scope: scope }, filters || {});

                        return services.FormService.fieldOptions($scope.field.id || $scope.field.type.name, params);
                    }
                };

                $scope.typeLabel = $filter('translate')('form_field_types.' + $scope.field.type.name).split(' › ').slice(-1)[0];
            }],
            template: function () {
                return '<span ng-include="viewPath()"></span>';
            }
        };
    }
}(angular));
