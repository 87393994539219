(function (angular) {
    angular
        .module('one.admin')
        .service('SddBatchService', SddBatchService);

    SddBatchService.$inject = ['http'];

    function SddBatchService(http) {
        function sddBatch(id) {
            return http.get('sdd_batches/' + id);
        }

        sddBatch.sddTransactions = function(id, params) {
            return http.get('sdd_batches/' + id + '/sdd_transactions', params);
        };

        sddBatch.setStatus = function(id, status, data) {
            data = data || {};
            data.status = status;

            return http.post('sdd_batches/' + id + '/set_status', data);
        };

        function sddBatches(params) {
            return http.get('sdd_batches', params);
        }

        function addSddBatch(data) {
            return http.post('sdd_batches/add', data);
        }

        return {
            sddBatch: sddBatch,
            sddBatches: sddBatches,
            addSddBatch: addSddBatch
        };
    }
})(angular);
