(function (angular) {
    angular
        .module('one.admin')
        .controller('TransactionalMailClicksModalController', TransactionalMailClicksModalController);

    TransactionalMailClicksModalController.$inject = ['$scope', '$filter', '$uibModalInstance', '$state', 'dataframe', 'session', 'TransactionalMailService', 'params'];

    function TransactionalMailClicksModalController($scope, $filter, $uibModalInstance, $state, dataframe, session, TransactionalMailService, params) {
        angular.extend($scope, params);

        $scope.clicks = dataframe.init({
            externalData: function (params) {
                params = params || {};
                params.url = $scope.url;

                return TransactionalMailService.clicks($scope.transactionalMail.id, params);
            },
            cols: [
                {
                    name: 'title',
                    title: 'Name',
                    display: function (row) {
                        var icon = 'person';

                        if (row.sent_mail.account.account_type_name == 'organisation') {
                            icon = 'business';
                        };

                        return '<i class="md-icon">' + icon + '</i> <strong>{{ row.sent_mail.account.title }}</strong>';
                    },
                    weight: 1
                },
                {
                    name: 'email',
                    title: 'E-mail',
                    display: function (row) {
                        return row.sent_mail.recipient
                    },
                    weight: 1
                },
                {
                    title: 'Timestamp',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'short');
                    },
                    sortable: true
                }
            ],
            perPage: 5,
            rowClick: function (row) {
                $uibModalInstance.close();

                $state.transitionTo('crm.accounts.account.index', {accountId: row.sent_mail.account.id});
            },
            state: session.get('data.dataframes.sent_mail_per_status', {
                sort: {
                    created_at: 'desc'
                }
            })
        });

        $scope.close = $uibModalInstance.close;
    }
})(angular);
