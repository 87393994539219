(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeSearch', oneDataframeSearchDirective);

    oneDataframeSearchDirective.$inject = ['config'];

    function oneDataframeSearchDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '=',
                focus: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-search');
            },
            controller: ['$scope', function ($scope) {
                if ($scope.dataframe.filterables && $scope.dataframe.filterables.length > 0) {
                    var filterableAttributes = [];
                    var filterableLabels = {};

                    angular.forEach($scope.dataframe.filterables, function (value) {
                        filterableAttributes.push(value);

                        filterableLabels[value] = $scope.dataframe.filterableLabels[value] || value;
                    });

                    $scope.search = {
                        searchTerm: null,
                        filterAttribute: filterableAttributes[0],
                        filtered: isFiltered()
                    };

                    $scope.dropdown = {};

                    $scope.filterableAttributes = filterableAttributes;
                    $scope.filterableLabels = filterableLabels;

                    $scope.enableFilters = enableFilters;
                    $scope.disableFilters = disableFilters;
                    $scope.addFilter = addFilter;
                    $scope.removeFilter = removeFilter;
                    $scope.isFiltered = isFiltered;

                    function enableFilters() {
                        if (!isFiltered()) {
                            $scope.search.filtered = true;
                            $scope.dataframe.state.search = {};
                        }

                        $scope.search.searchTerm = null;
                    }

                    function disableFilters() {
                        $scope.search.filtered = false;
                        $scope.dataframe.state.search = null;
                    }

                    function addFilter() {
                        if (!$scope.search.filtered) {
                            return;
                        }

                        var searchTerm = $scope.search.searchTerm;
                        var filterAttribute = $scope.search.filterAttribute;

                        if (searchTerm && filterAttribute) {
                            if ($scope.dataframe.state.search[filterAttribute]) {
                                if ($scope.dataframe.state.search[filterAttribute].indexOf(searchTerm) == -1) {
                                    $scope.dataframe.state.search[filterAttribute].push(searchTerm);
                                }
                            } else {
                                $scope.dataframe.state.search[filterAttribute] = [searchTerm];
                            }
                        }

                        $scope.search.searchTerm = null;
                    }

                    function removeFilter(index, attribute) {
                        $scope.dataframe.state.search[attribute].splice(index, 1);

                        if ($scope.dataframe.state.search[attribute].length == 0) {
                            delete $scope.dataframe.state.search[attribute];
                        }
                    }

                    function isFiltered() {
                        return angular.isObject($scope.dataframe.state.search);
                    }
                }
            }],
            templateUrl: config.path.template('common.dataframe.search')
        };
    }
})(angular);
