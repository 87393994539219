(function (angular) {
    angular
        .module('one.admin')
        .controller('ScheduleMailingModalController', ScheduleMailingModalController);

    ScheduleMailingModalController.$inject = ['$scope', '$uibModalInstance', 'MailingService', 'params'];

    function ScheduleMailingModalController($scope, $uibModalInstance, MailingService, params) {
        angular.extend($scope, params);

        $scope.loading = false;

        $scope.mailing.scheduled_date = $scope.mailing.scheduled_date || moment().format('YYYY-MM-DD');
        $scope.mailing.scheduled_time = $scope.mailing.scheduled_time || moment().format('HH:mm:ss');

        $scope.offsetScheduledDate = offsetScheduledDate;
        $scope.save = save;
        $scope.cancel = cancel;

        function offsetScheduledDate(days) {
            $scope.mailing.scheduled_date = moment().add(days, 'days').format('YYYY-MM-DD');
        }

        function save() {
            $scope.loading = true;

            MailingService.scheduleMailing({
                id: $scope.mailing.id,
                automatic: $scope.mailing.automatic,
                scheduled_frequency: $scope.mailing.scheduled_frequency,
                scheduled_date: $scope.mailing.scheduled_date,
                scheduled_time: $scope.mailing.scheduled_time,
                end_date: $scope.mailing.scheduled_frequency != 'once' ? $scope.mailing.end_date : null
            }).then(function (data) {
                $uibModalInstance.close(data);
            }, function () {
                $scope.loading = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
