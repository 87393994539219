(function (angular) {
    angular
        .module('one.admin')
        .filter('middleShorten', middleShortenFilter);

    middleShortenFilter.$inject = ['trimFilter'];

    function middleShortenFilter(trimFilter) {
        return function (value, length) {
            if (!angular.isString(value)) {
                return value;
            }

            if (value.length <= length) {
              return value;
            }

            var halfLength = Math.floor((length - 1) / 2);
            var leftValue = value.slice(0, halfLength);
            var rightValue = value.slice(value.length - halfLength);

            return trimFilter(leftValue) + '...' + trimFilter(rightValue);
        };
    }
})(angular);
