(function (angular) {
    angular
        .module('one.admin')
        .controller('EditFormPageController', EditFormPageController);

    EditFormPageController.$inject = ['$scope', '$state', 'FormService', 'form', 'page'];

    function EditFormPageController($scope, $state, FormService, form, page) {
        $scope.form = form;
        $scope.page = page;

        $scope.editPage = editPage;

        function editPage() {
            FormService.editFormPage($scope.page).then(function (response) {
                $state.transitionTo('crm.forms.formStructure', { formId: $scope.form.id });
            });
        }
    }
})(angular);
