(function (angular) {
    angular
        .module('one.admin')
        .service('BankStatementService', BankStatementService);

    BankStatementService.$inject = ['http'];

    function BankStatementService(http) {
        function bankStatement(id) {
            return http.get('bank_statements/' + id);
        }

        bankStatement.reversalEntries = function (id, params) {
            return http.get('bank_statements/' + id + '/reversal_entries', params);
        }

        function bankStatements(params) {
            return http.get('bank_statements', params);
        }

        function bankStatementEntry(id, params) {
            return http.get('bank_statement_entries/' + id, params);
        }

        function bankStatementEntries(params) {
            return http.get('bank_statement_entries', params);
        }

        function unmatchedPayments(params) {
            return http.get('bank_statements/unmatched_payments', params);
        }

        function processingCount(params) {
            return http.get('bank_statements/processing_count', params);
        }

        function unmatchedPaymentCount(params) {
            return http.get('bank_statements/unmatched_payment_count', params);
        }

        function processMatch(bankStatementEntryId, data) {
            return http.post('bank_statement_entries/' + bankStatementEntryId + '/process_match', data);
        }

        function undoIgnore(bankStatementEntryId, data) {
            return http.post('bank_statement_entries/' + bankStatementEntryId + '/undo_ignore', data);
        }

        function matchSuggestions(bankStatementEntryId) {
            return http.get('bank_statement_entries/' + bankStatementEntryId + '/match_suggestions');
        }

        function reversalActionStats(params) {
            return http.get('bank_statements/reversal_action_stats', params);
        }

        function reversalEntryStats(params) {
            return http.get('bank_statements/reversal_entry_stats', params);
        }

        var matchTypes = {
            IGNORED_PAYMENT: 'ignored_payment',
            MATCHED_PAYMENT: 'matched_payment',
            SDD_BATCH_PAYMENT: 'sdd_batch_payment',
            SDD_BATCH_PAYMENT_UNTRACKED: 'sdd_batch_payment_untracked',
            SDD_REVERSAL: 'sdd_reversal',
            SDD_REVERSAL_UNTRACKED: 'sdd_reversal_untracked',
            SKIP_NEGATIVE_AMOUNT: 'skip_negative_amount',
            SKIP_NO_IBAN: 'skip_no_iban',
            UNKNOWN_ENTRY: 'unknown_entry',
            UNMATCHED_PAYMENT: 'unmatched_payment'
        };

        return {
            bankStatement: bankStatement,
            bankStatements: bankStatements,
            bankStatementEntry: bankStatementEntry,
            bankStatementEntries: bankStatementEntries,
            unmatchedPayments: unmatchedPayments,
            processingCount: processingCount,
            unmatchedPaymentCount: unmatchedPaymentCount,
            processMatch: processMatch,
            undoIgnore: undoIgnore,
            matchSuggestions: matchSuggestions,
            reversalActionStats: reversalActionStats,
            reversalEntryStats: reversalEntryStats,
            matchTypes: matchTypes
        };
    }
})(angular);
