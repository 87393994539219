(function (angular) {
    angular
        .module('one.admin')
        .controller('SddBatchController', SddBatchController);

    SddBatchController.$inject = ['$scope', '$state', '$filter', 'AuthService', 'SddBatchService', 'config', 'dataframe', 'modal', 'notification', 'sddBatch'];

    function SddBatchController($scope, $state, $filter, AuthService, SddBatchService, config, dataframe, modal, notification, sddBatch) {
        $scope.sddBatch = sddBatch;

        $scope.sddBatchPath = config.path.api('sdd_batches/' + sddBatch.id + '/download');

        var rowOptions = [];

        if (sddBatch.status == 'processed') {
            rowOptions = [
                {
                    title: 'Reversed',
                    icon: 'error',
                    click: function (row) {
                        openReverseSddTransactionModal(row);
                    }
                }
            ];
        }

        $scope.sddTransactions = dataframe.init({
            externalData: function (params) {
                return SddBatchService.sddBatch.sddTransactions(sddBatch.id, params);
            },
            cols: [
                {
                    title: 'Transaction',
                    name: 'transaction.remote_id',
                    display: function (row) {
                        return '<span transaction-label transaction="row.transaction"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Amount',
                    name: 'amount',
                    display: function (row) {
                        return '<h4>{{ row.amount | money:\'&euro; \' }} <span tag-indicator tags="row.tags"></span></h4>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Status',
                    name: 'status',
                    weight: 1,
                    display: function (row) {
                        return $filter('translate')('transaction_statuses.' + row.transaction.status);
                    }
                },
                {
                    title: 'IBAN',
                    name: 'iban',
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Sequence type',
                    name: 'sequence_type',
                    display: function (row) {
                        return '{{ \'sequence_types.\' + row.sequence_type | translate }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Mandate',
                    name: 'mandate_reference',
                    display: function (row) {
                        return '<em>{{ row.sdd_mandate.reference }}</em>';
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('transactions.view')) {
                    return 'finance.transactions.transaction({transactionId: ' + row.transaction.id + '})';
                }
            },
            rowOptions: rowOptions,
            state: { sort: { created_at: 'desc' } }
        });

        $scope.openSetStatusModal = openSetStatusModal;
        $scope.openAuditorModal = openAuditorModal;

        function openReverseSddTransactionModal(sddTransaction) {
            modal.open('reverseSddTransaction', null, {
                sddTransaction: sddTransaction,
                transaction: sddTransaction.transaction
            }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openSetStatusModal(status, size) {
            var modalName = status == 'processed' ? 'processSddBatch' : 'confirm';

            modal.open(modalName, size, { message: 'Change status to <strong>' + $filter('translate')('sdd_batch_statuses.' + status) + '</strong>? This will affect <strong>' + sddBatch.transaction_count + ' transaction' + (sddBatch.transaction_count != 1 ? 's' : '') + '</strong> and cannot be undone.' }).result.then(function (data) {
                SddBatchService.sddBatch.setStatus($scope.sddBatch.id, status, data).then(function (data) {
                    if (status == 'cancelled') {
                        $state.go('finance.sddBatches.index');
                    } else {
                        $state.reload($state.current);
                    }
                }, function (response) {
                    notification.error(Object.values(response.errors)[0][0]);
                });
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: sddBatch.id, modelType: 'sdd_batch' });
        }
    }
})(angular);
