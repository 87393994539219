(function (angular) {
    angular
        .module('one.admin')
        .controller('EditProductCategoryController', EditProductCategoryController);

    EditProductCategoryController.$inject = ['$scope', '$state', 'ProductService', 'category'];

    function EditProductCategoryController($scope, $state, ProductService, category) {
        $scope.category = category;

        $scope.editCategory = editCategory;

        function editCategory() {
            ProductService.editProductCategory($scope.category).then(function (data) {
                $state.transitionTo('sales.products.productCategories.productCategory', { productCategoryId: $scope.category.id });
            });
        }
    }
})(angular);
