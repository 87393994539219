(function (angular) {
    angular
        .module('one.admin')
        .controller('EditTransactionalMailController', EditTransactionalMailController);

    EditTransactionalMailController.$inject = ['$scope', '$state', 'TransactionalMailService', 'notification', 'transactionalMail', 'pdfTemplates', 'senders', 'templates'];

    function EditTransactionalMailController($scope, $state, TransactionalMailService, notification, transactionalMail, pdfTemplates, senders, templates) {
        transactionalMail.template = transactionalMail.template || templates[0];

        $scope.pdfTemplates = pdfTemplates;
        $scope.senders = senders;
        $scope.templates = templates;
        $scope.transactionalMail = transactionalMail;

        $scope.submitting = false;

        $scope.editTransactionalMail = editTransactionalMail;

        function editTransactionalMail(data) {
            if ($scope.submitting) {
                return;
            }

            $scope.submitting = true;

            TransactionalMailService.editTransactionalMail(data).then(function (data) {
                $scope.setChanged(false);
                $state.transitionTo('mail.transactionalMails.transactionalMail', { transactionalMailId: transactionalMail.id });
            }, function () {
                $scope.submitting = false;
            });
        }
    }
})(angular);
