(function (angular) {
    angular
        .module('one.admin')
        .controller('AddShareModuleModalController', AddShareModuleModalController);

    AddShareModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddShareModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                enable_brand: false,
                brand: 'default'
            }
        };

        angular.merge($scope, params);

        $scope.addShareModule = addShareModule;

        $scope.cancel = cancel;

        function addShareModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
