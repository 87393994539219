(function (angular) {
    angular
        .module('one.admin')
        .directive('navBar', navBarDirective);

    navBarDirective.$inject = ['config'];

    function navBarDirective(config) {
        controller.$inject = ['$rootScope', '$scope', '$state', 'AuthService', 'translateFilter', 'session'];

        function controller($rootScope, $scope, $state, AuthService, translateFilter, session) {
            var project = session.get('project');

            $scope.navState = {
                activeSection: $state.params ? $state.params.section : false,
                currentSite: session.get('data.current_site')
            };

            $scope.items = {};

            addItem('content', 'default', {
                state: 'content.pages.index',
                title: translateFilter('navbar.pages'),
                authRole: 'pages.overview',
                if: function () {
                    return $scope.navState.currentSite;
                }
            });

            addItem('content', 'default', {
                state: 'content.pageNavs.index',
                title: translateFilter('navbar.page_navs'),
                authRole: 'page_navs.overview',
                if: function () {
                    return $scope.navState.currentSite;
                }
            });

            addItem('content', 'default', {
                state: 'content.modules.index',
                title: translateFilter('navbar.modules'),
                authRole: 'modules.overview',
                if: function () {
                    return $scope.navState.currentSite;
                }
            });

            addItem('content', 'default', {
                state: 'content.files.index',
                title: translateFilter('navbar.files'),
                authRole: 'files.overview'
            });

            addItem('content', 'extra', {
                state: 'content.sites.site',
                title: translateFilter('navbar.site_settings'),
                authRole: 'sites.edit'
            })

            addItem('crm', 'default', {
                state: 'crm.accounts.index',
                title: translateFilter('navbar.contacts'),
                authRole: 'accounts.overview'
            });

            addItem('crm', 'default', {
                state: 'crm.groups.index',
                title: translateFilter('navbar.groups'),
                authRole: 'groups.overview'
            });

            addItem('crm', 'default', {
                state: 'crm.segments.index',
                title: translateFilter('navbar.segments'),
                authRole: 'segments.overview'
            });

            addItem('crm', 'default', {
                state: 'crm.interactions.index',
                title: translateFilter('navbar.interactions'),
                badge: function () {
                    return session.get('user.due_tasks_count');
                },
                authRole: 'interactions.overview'
            });

            if (project.deprecations.indexOf('events') === -1) {
                addItem('events', 'default', {
                    state: 'events.events.index',
                    title: translateFilter('navbar.events'),
                    authRole: 'events.overview'
                });
            }

            addItem('crowdfunding', 'default', {
                state: 'crowdfunding.actionProjects.index',
                title: translateFilter('navbar.projects'),
                authRole: 'action_projects.overview'
            });

            addItem('crowdfunding', 'default', {
                state: 'crowdfunding.actionCampaigns.index',
                title: translateFilter('navbar.campaigns'),
                authRole: 'action_campaigns.overview'
            });

            addItem('crowdfunding', 'default', {
                state: 'crowdfunding.gifts.index',
                title: translateFilter('navbar.gifts'),
                authRole: 'action_projects.overview'
            });

            addItem('crm', 'default', {
                state: 'crm.forms.index',
                title: translateFilter('navbar.forms'),
                authRole: 'forms.overview'
            });

            addItem('mail', 'default', {
                state: 'mail.campaigns.index',
                title: translateFilter('navbar.campaigns'),
                authRole: 'campaigns.overview'
            });

            addItem('mail', 'default', {
                state: 'mail.transactionalMails.index',
                title: translateFilter('navbar.transactional_mail'),
                authRole: 'transactional_mails.overview'
            });

            addItem('mail', 'extra', {
                state: 'mail.templates.index',
                title: translateFilter('navbar.templates'),
                authRole: 'mail_templates.overview'
            });

            addItem('mail', 'extra', {
                state: 'mail.senders.index',
                title: translateFilter('navbar.senders'),
                authRole: 'mail_senders.overview'
            });

            addItem('sales', 'default', {
                state: 'sales.dashboard.index',
                title: translateFilter('navbar.dashboard'),
                authRole: 'sales_dashboard.view'
            });

            addItem('sales', 'default', {
                state: 'sales.orders.index',
                title: translateFilter('navbar.orders'),
                authRole: 'orders.overview'
            });

            addItem('sales', 'extra', {
                state: 'sales.products.index',
                title: translateFilter('navbar.products'),
                authRole: 'products.overview'
            });

            addItem('sales', 'extra', {
                state: 'sales.discountCodes.index',
                title: translateFilter('navbar.discount_codes'),
                authRole: 'discount_codes.overview'
            });

            addItem('finance', 'default', {
                state: 'finance.dashboard.index',
                title: translateFilter('navbar.dashboard'),
                authRole: 'finance_dashboard.view'
            });

            addItem('finance', 'default', {
                state: 'finance.transactions.index',
                title: translateFilter('navbar.transactions'),
                authRole: 'transactions.overview'
            });

            addItem('finance', 'default', {
                state: 'finance.sddBatches.index',
                title: translateFilter('navbar.direct_debit'),
                authRole: 'sdd_batches.overview'
            });

            if (project.deprecations.indexOf('bank_statements') === -1) {
                addItem('finance', 'extra', {
                    state: 'finance.bankStatements.index',
                    title: translateFilter('navbar.bank_statements'),
                    authRole: 'bank_statements.overview'
                });
            }

            if (project.deprecations.indexOf('payment_references') === -1) {
                addItem('finance', 'extra', {
                    state: 'finance.paymentReferences.index',
                    title: translateFilter('navbar.bank_transfer'),
                    authRole: 'payment_reference_configs.overview'
                });
            }

            $scope.sites = session.get('data.sites');

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                if ($state.params && $state.params.section) {
                    setActiveSection($state.params.section);
                }
            });

            $scope.setSite = setSite;
            $scope.setActiveSection = setActiveSection;

            function addItem(section, type, item) {
                if (!AuthService.userCanAccess(item.authRole)) {
                    return;
                }

                if (!$scope.items[section]) {
                    $scope.items[section] = {
                        default: [],
                        extra: []
                    };
                }

                $scope.items[section][type].push({
                    state: item.state,
                    title: item.title,
                    badge: item.badge,
                    if: item.if
                });
            }

            function setSite(site) {
                session.set('data.current_site', site);
                $state.reload();
            }

            function setActiveSection(name) {
                $scope.navState.activeSection = name;
            }
        }
        return {
            restrict: 'EA',
            controller: controller,
            templateUrl: config.path.template('common.navbar.navbar')
        };
    }
}(angular));
