(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('crowdfunding', {
            parent: 'main',
            abstract: true,
            url: '/crowdfunding',
            template: '<div ui-view></div>',
            params: {
                section: 'crowdfunding'
            }
        });

        $stateProvider.state('crowdfunding.actionProjects', {
            parent: 'crowdfunding',
            abstract: true,
            url: '/projects',
            template: '<div ui-view></div>',
            params: {
                section: 'crowdfunding'
            }
        });

        $stateProvider.state('crowdfunding.actionProjects.addActionProject', {
            url: '/add',
            controller: 'AddActionProjectController',
            templateUrl: config.path.template('action_projects.add'),
            resolve: {
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }],
                types: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }],
                fields: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.fields();
                }]
            },
            requirePermission: 'action_projects.add'
        });

        $stateProvider.state('crowdfunding.actionProjects.editActionProject', {
            url: '/:actionProjectId/edit',
            controller: 'EditActionProjectController',
            templateUrl: config.path.template('action_projects.edit'),
            resolve: {
                actionProject: ['ActionProjectService', '$stateParams', function (ActionProjectService, $stateParams) {
                    return ActionProjectService.actionProject($stateParams.actionProjectId);
                }],
                types: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }],
                fields: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.fields();
                }]
            },
            requirePermission: 'action_projects.edit'
        });

        $stateProvider.state('crowdfunding.actionProjects.actionProject', {
            url: '/:actionProjectId/campaigns',
            controller: 'ActionProjectController',
            templateUrl: config.path.template('action_projects.action_project.action_campaigns'),
            resolve: {
                actionProject: ['ActionProjectService', '$stateParams', function (ActionProjectService, $stateParams) {
                    return ActionProjectService.actionProject($stateParams.actionProjectId);
                }]
            },
            requirePermission: 'action_projects.view'
        });

        $stateProvider.state('crowdfunding.actionProjects.gifts', {
            url: '/:actionProjectId/gifts',
            controller: 'ActionProjectController',
            templateUrl: config.path.template('action_projects.action_project.gifts'),
            resolve: {
                actionProject: ['ActionProjectService', '$stateParams', function (ActionProjectService, $stateParams) {
                    return ActionProjectService.actionProject($stateParams.actionProjectId);
                }]
            },
            requirePermission: 'action_projects.view'
        });

        $stateProvider.state('crowdfunding.actionProjects.giftRewards', {
            url: '/:actionProjectId/gift-rewards',
            controller: 'ActionProjectController',
            templateUrl: config.path.template('action_projects.action_project.gift_rewards'),
            resolve: {
                actionProject: ['ActionProjectService', '$stateParams', function (ActionProjectService, $stateParams) {
                    return ActionProjectService.actionProject($stateParams.actionProjectId);
                }]
            },
            requirePermission: 'action_projects.view'
        });

        $stateProvider.state('crowdfunding.actionProjects.index', {
            url: '',
            controller: 'ActionProjectsController',
            templateUrl: config.path.template('action_projects.index'),
            resolve: {
                groups: ['ActionProjectService', function (ActionProjectService) {
                    return ActionProjectService.groups();
                }]
            },
            requirePermission: 'action_projects.overview'
        });

        $stateProvider.state('crowdfunding.actionCampaigns', {
            parent: 'crowdfunding',
            abstract: true,
            url: '/campaigns',
            template: '<div ui-view></div>',
            params: {
                section: 'crowdfunding'
            }
        });

        $stateProvider.state('crowdfunding.actionCampaigns.addActionCampaign', {
            url: '/add',
            controller: 'AddActionCampaignController',
            templateUrl: config.path.template('action_campaigns.add'),
            resolve: {
                types: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }],
                actionProjects: ['ActionProjectService', function (ActionProjectService) {
                    return ActionProjectService.actionProjects();
                }]
            },
            params: {
                actionProject: null,
                parentCampaign: null
            },
            requirePermission: 'action_campaigns.add'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.editActionCampaign', {
            url: '/:actionCampaignId/edit',
            controller: 'EditActionCampaignController',
            templateUrl: config.path.template('action_campaigns.edit'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }],
                types: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.members', {
            url: '/:actionCampaignId/members',
            controller: 'ActionCampaignController',
            templateUrl: config.path.template('action_campaigns.action_campaign.members'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.posts', {
            url: '/:actionCampaignId/updates',
            controller: 'ActionCampaignController',
            templateUrl: config.path.template('action_campaigns.action_campaign.posts'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.giftRewards', {
            url: '/:actionCampaignId/gift-rewards',
            controller: 'ActionCampaignController',
            templateUrl: config.path.template('action_campaigns.action_campaign.gift_rewards'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.gifts', {
            url: '/:actionCampaignId/gifts',
            controller: 'ActionCampaignController',
            templateUrl: config.path.template('action_campaigns.action_campaign.gifts'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        $stateProvider.state('crowdfunding.actionCampaigns.actionCampaign', {
            url: '/:actionCampaignId',
            controller: 'ActionCampaignController',
            templateUrl: config.path.template('action_campaigns.action_campaign.about'),
            resolve: {
                actionCampaign: ['ActionCampaignService', '$stateParams', function (ActionCampaignService, $stateParams) {
                    return ActionCampaignService.actionCampaign($stateParams.actionCampaignId);
                }]
            },
            requirePermission: 'action_campaigns.view'
        });

        modalProvider.modal('mergeActionCampaigns', {
            templateUrl: config.path.template('action_campaigns.action_campaign.merge_modal'),
            controller: 'MergeActionCampaignsModalController',
            backdrop: 'static',
            keyboard: false
        });

        $stateProvider.state('crowdfunding.actionCampaigns.index', {
            url: '',
            controller: 'ActionProjectsController',
            templateUrl: config.path.template('action_campaigns.index'),
            resolve: {
                groups: ['ActionProjectService', function (ActionProjectService) {
                    return ActionProjectService.groups();
                }]
            },
            requirePermission: 'action_campaigns.overview'
        });

        $stateProvider.state('crowdfunding.gifts', {
            parent: 'crowdfunding',
            abstract: true,
            url: '/gifts',
            template: '<div ui-view></div>',
            params: {
                section: 'crowdfunding'
            }
        });

        $stateProvider.state('crowdfunding.gifts.index', {
            url: '',
            controller: 'ActionProjectsController',
            templateUrl: config.path.template('action_projects.gifts'),
            resolve: {
                groups: ['ActionProjectService', function (ActionProjectService) {
                    return ActionProjectService.groups();
                }]
            },
            requirePermission: 'gifts.overview'
        });

        $stateProvider.state('crowdfunding.gifts.editGift', {
            url: '/:giftId/edit',
            controller: 'EditGiftController',
            templateUrl: config.path.template('gifts.edit'),
            resolve: {
                gift: ['GiftService', '$stateParams', function (GiftService, $stateParams) {
                    return GiftService.gift($stateParams.giftId);
                }]
            },
            requirePermission: 'gifts.edit'
        });

        $stateProvider.state('crowdfunding.gifts.gift', {
            url: '/:giftId',
            controller: 'GiftController',
            templateUrl: config.path.template('gifts.gift'),
            resolve: {
                gift: ['GiftService', '$stateParams', function (GiftService, $stateParams) {
                    return GiftService.gift($stateParams.giftId);
                }]
            },
            requirePermission: 'gifts.view'
        });

        $stateProvider.state('crowdfunding.giftRewards', {
            parent: 'crowdfunding',
            abstract: true,
            url: '/gift-rewards',
            template: '<div ui-view></div>',
            params: {
                section: 'gifts'
            }
        });

        modalProvider.modal('addGiftReward', {
            templateUrl: config.path.template('gift_rewards.add'),
            controller: 'AddGiftRewardModalController',
            backdrop: 'static',
            keyboard: false
        })

        $stateProvider.state('crowdfunding.giftRewards.editGiftReward', {
            url: '/:giftRewardId/edit',
            controller: 'EditGiftRewardController',
            templateUrl: config.path.template('gift_rewards.edit'),
            resolve: {
                giftReward: ['GiftService', '$stateParams', function (GiftService, $stateParams) {
                    return GiftService.giftReward($stateParams.giftRewardId);
                }]
            },
            requirePermission: 'gift_rewards.edit'
        });
    }
})(angular);
