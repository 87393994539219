(function (angular) {
    angular
        .module('one.admin')
        .controller('ExistingModuleModalController', ExistingModuleModalController);

    ExistingModuleModalController.$inject = ['$scope', '$uibModalInstance', '$timeout', 'modal', 'session', 'string', 'ModuleService', 'moduleTypes', 'params'];

    function ExistingModuleModalController($scope, $uibModalInstance, $timeout, modal, session, string, ModuleService, moduleTypes, params) {
        var timeout;

        $scope.moduleTypes = moduleTypes;
        $scope.search = { term: null };
        $scope.focus = false;

        angular.extend($scope, params);

        $timeout(function () {
            $scope.focus = true;
        }, 500);

        $scope.getModules = getModules;
        $scope.select = select;
        $scope.cancel = cancel;

        function getModules(search) {
            if (!search) {
                return;
            }

            $timeout.cancel(timeout);

            $scope.loading = true;

            timeout = $timeout(function () {
                ModuleService.modules({
                    search: search,
                    site: {
                        id: session.get('data.current_site.id')
                    }
                }).then(function (modules) {
                    $scope.modules = modules;

                    $scope.loading = false;
                });
            }, 250);
        }

        function select(module) {
            $uibModalInstance.close({ module: module });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
