(function (angular) {
    angular
        .module('one.admin')
        .service('DuplicateService', DuplicateService);

    DuplicateService.$inject = ['$q', '$httpParamSerializer', 'http'];

    function DuplicateService($q, $httpParamSerializer, http) {
        var matchTypes = [
            'title',
            'email',
            'address',
            'iban',
            'personal',
        ];

        function duplicates(params) {
            return http.get('duplicates', params);
        }

        function deactivateDuplicate(master, duplicate) {
            return http.post('duplicates/' + master + '/' + duplicate + '/deactivate');
        }

        function reactivateDuplicate(master, duplicate) {
            return http.post('duplicates/' + master + '/' + duplicate + '/reactivate');
        }

        function findDuplicatesForAccount(account) {
            return http.post('duplicates/find_duplicates_for_account', account, null, {idempotent: true});
        }

        return {
            matchTypes: matchTypes,
            duplicates: duplicates,
            deactivateDuplicate: deactivateDuplicate,
            reactivateDuplicate: reactivateDuplicate,
            findDuplicatesForAccount: findDuplicatesForAccount
        };
    }
})(angular);
