(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderAnnualPaymentFilterBlock', segmentBuilderAnnualPaymentFilterBlock);

    segmentBuilderAnnualPaymentFilterBlock.$inject = ['config'];

    function segmentBuilderAnnualPaymentFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                filterBlock: '=',
                segment: '='
            },
            controller: ['$scope', 'segmentBuilder', function SegmentBuilderAnnualPaymentFilterBlockController($scope, segmentBuilder) {
                $scope.filterables = null;

                getFilterables();

                $scope.deleteFilterBlock = deleteFilterBlock;
                $scope.addFilter = addFilter;

                $scope.annualPaymentFilterBlock = $scope.filterBlock.filters[0];

                /**
                 * Resets the filter block and filterables.
                 * @return {undefined}
                 */
                function deleteFilterBlock() {
                    $scope.filterBlock = null;
                    $scope.filterables = null;
                }

                /**
                 * Adds a comparison filter to the filter block.
                 * @return {undefined}
                 */
                function addFilter() {
                    $scope.annualPaymentFilterBlock.filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Fetches the fields that can be filtered on.
                 * @return {undefined}
                 */
                function getFilterables() {
                    segmentBuilder.filterables.annualPayment().then(function (filterables) {
                        $scope.filterables = filterables;
                    });
                }
            }],
            templateUrl: config.path.template('segments.builder.annual_payment_filter_block')
        };
    }
}(angular));
