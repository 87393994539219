(function (angular) {
    angular
        .module('one.admin')
        .directive('formRulesEditor', formRulesEditor);

    formRulesEditor.$inject = ['FormService'];

    function formRulesEditor(FormService) {
        return {
            restrict: 'EA',
            scope: {
                rules: '=',
                field: '=',
                value: '=',
                customizable: '='
            },
            controller: ['$scope', 'services', function ($scope, services) {
                $scope.templatePath = FormService.rulesTemplatePath($scope.field);

                var controllers = {
                    'sub_group': function () {
                        var GroupService = services.GroupService;

                        $scope.selected = {};

                        var groupId = ($scope.field.rules.filters.groups.parent_groups || [])[0]

                        if (groupId) {
                            GroupService.group(groupId).then(function (group) {
                                $scope.selected.group = group;
                            });
                        }

                        $scope.getGroups = GroupService.groups;
                        $scope.formatGroupTitle = GroupService.formatGroupTitle;

                        $scope.setGroup = function () {
                            $scope.field.rules.filters.groups.parent_groups = [$scope.selected.group.id];

                            if ($scope.value) {
                                var defaultValueIsSubGroup = false;

                                for (var i = 0; i < $scope.value.parents.length; i++) {
                                    if ($scope.value.parents[i].id == $scope.selected.group.id) {
                                        defaultValueIsSubGroup = true;
                                    }
                                }

                                if (!defaultValueIsSubGroup) {
                                    $scope.value = null;
                                }
                            }
                        };
                    },
                    'action_campaign': function () {
                        var ActionCampaignService = services.ActionCampaignService;
                        var ActionProjectService = services.ActionProjectService;

                        ActionProjectService.actionProjects().then(function (actionProjects) {
                            $scope.actionProjects = actionProjects;
                        });

                        ActionCampaignService.types().then(function (types) {
                            $scope.actionCampaignTypes = types;
                        });

                        $scope.formatActionCampaignType = ActionCampaignService.formatActionCampaignType;
                        $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;
                    },
                    'gift.destination': function () {
                        var ActionCampaignService = services.ActionCampaignService;
                        var ActionProjectService = services.ActionProjectService;

                        ActionProjectService.actionProjects().then(function (actionProjects) {
                            $scope.actionProjects = actionProjects;
                        });

                        ActionCampaignService.types().then(function (types) {
                            $scope.actionCampaignTypes = types;
                        });

                        $scope.formatActionCampaignType = ActionCampaignService.formatActionCampaignType;
                        $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;
                    }
                };

                var controller = controllers[$scope.field.type.name];

                if (controller) {
                    controller();
                }
            }],
            template: function () {
                return '<span ng-include="templatePath" ng-if="templatePath"></span>';
            }
        };
    }
}(angular));
