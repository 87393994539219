(function (angular) {
    angular
        .module('one.admin')
        .directive('navBarItems', navBarItemsDirective);

    navBarItemsDirective.$inject = ['config'];

    function navBarItemsDirective(config) {
        controller.$inject = ['$scope', '$state'];

        function controller($scope, $state) {
            $scope.extra = {
                show: $state.params && $scope.navState.activeSection == $state.params.section
            };

            $scope.isActiveState = isActiveState;

            function isActiveState(name) {
                name = name.split('.');
                name.pop();
                name = name.join('.');

                return $state.includes(name);
            }
        }

        return {
            restrict: 'EA',
            scope: {
                navState: '=',
                defaultItems: '=',
                extraItems: '='
            },
            controller: controller,
            templateUrl: config.path.template('common.navbar.items')
        };
    }
}(angular));
