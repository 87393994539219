(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountActionCampaignsController', AccountActionCampaignsController);

    AccountActionCampaignsController.$inject = ['$scope', 'ActionCampaignService', 'account'];

    function AccountActionCampaignsController($scope, ActionCampaignService, account) {
        $scope.account = account;

        $scope.actionCampaignTypes = [];

        ActionCampaignService.types().then(function (actionCampaignTypes) {
            $scope.actionCampaignTypes = actionCampaignTypes;
        });

        $scope.getActionCampaigns = getActionCampaigns;

        function getActionCampaigns(params) {
            params = params || {};
            params.filters = angular.extend({}, params.filters, { campaigners: [$scope.account.id] });

            return ActionCampaignService.actionCampaigns(params);
        }
    }
})(angular);
