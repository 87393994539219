(function (angular) {
    angular
        .module('one.admin')
        .service('RelationService', RelationService);

    RelationService.$inject = ['http'];

    function RelationService(http) {
        function addRelation(data) {
            return http.post('relations/add', data);
        }

        function deleteRelation(id) {
            return http.post('relations/' + id + '/delete');
        }

        function makeRelationPrimaryOrganisation(id) {
            return http.post('relations/' + id + '/make_primary_organisation');
        }

        return {
            addRelation: addRelation,
            deleteRelation: deleteRelation,
            makeRelationPrimaryOrganisation: makeRelationPrimaryOrganisation
        };
    }
})(angular);
