(function (angular) {
    angular
        .module('one.admin')
        .service('AuditService', AuditService);

    AuditService.$inject = ['http'];

    function AuditService(http) {
        var self = this;

        this.logEntries = function (modelType, modelId, params) {
            return http.get('audits/logs/' + modelType + '/' + modelId, params);
        };

        this.EVENTS = {
            ATTACHED: 'attached',
            CREATED: 'created',
            DELETED: 'deleted',
            DETACHED: 'detached',
            INITIALIZED: 'initialized',
            UPDATED: 'updated'
        };
    }
})(angular);
