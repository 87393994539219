(function (angular) {
    angular
        .module('one.admin')
        .directive('formModuleForm', formModuleFormDirective);

    formModuleFormDirective.$inject = ['config'];

    function formModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: ['$scope', 'translateFilter', 'FormService', 'VisitableService', function ($scope, translateFilter, FormService, VisitableService) {
                $scope.getForms = FormService.forms;
                $scope.getVisitables = VisitableService.pageVisitables;
                $scope.formatVisitableTitle = VisitableService.formatVisitableTitle;

                angular.forEach($scope.visitables, function (visitable) {
                    visitable.label = visitable.site.title + ' / ' + visitable.visitable_instance.title;
                });

                var visitable = $scope.module.module_instance.success_visitable;

                if (visitable) {
                    visitable.label = visitable.site.title + ' / ' + visitable.visitable_instance.title;
                }
            }],
            templateUrl: config.path.template('modules.form.form')
        };
    }
}(angular));
