(function (angular) {
    angular
        .module('one.admin')
        .service('GroupService', GroupService);

    GroupService.$inject = ['http', 'modal', 'string'];

    function GroupService(http, modal, string) {
        function accounts(id, params) {
            return http.get('groups/' + id + '/accounts', params);
        }

        function group(id) {
            return http.get('groups/' + id);
        }

        function groups(params) {
            return http.get('groups', params);
        }

        function addGroup(data) {
            return http.post('groups/add', data);
        }

        function addGroupMembership(data) {
            return http.post('groups/memberships/add', data);
        }

        function deleteGroupMembership(accountId, groupId) {
            return http.post('groups/memberships/' + accountId + '/' + groupId + '/delete');
        }

        function deleteGroup(id) {
            return http.post('groups/' + id + '/delete');
        }

        function editGroup(data) {
            return http.post('groups/' + data.id + '/edit', data);
        }

        function duplicateGroup(data) {
            return http.post('groups/' + data.id + '/duplicate', data);
        }

        function formatGroupTitle(group, compact) {
            var output = '<span class="group-label">';

            if (group.parents && !compact) {
                angular.forEach(group.parents, function (parentGroup, i) {
                    output += '<span class="label-part">' + string.escape(parentGroup.title) + '</span><span class="text-muted label-part"> / </span>';
                });
            }

            output += '<span class="label-part' + (!compact ? ' text-strong' : '') + '">';

            if (group.is_atleta_group) {
                output += '<img src="images/atleta-symbol.svg" alt="Atleta" class="atleta-icon" />';
            } else if (group.events && group.events.length > 0) {
                output += '<i class="md-icon">emoji_events</i> ';
            } else if (group.action_projects && group.action_projects.length > 0) {
                output += '<i class="md-icon">favorite_outline</i> ';
            } else if (group.name === 'email_optins') {
                output += '<i class="md-icon">email</i> ';
            }

            output += string.escape(group.title);
            output += '</span>';

            if (group.tags) {
                output += '<span class="tag-indicators">';

                angular.forEach(group.tags, function (tag) {
                    output += ' <i class="tag-indicator" style="background: ' + tag.color + '; border-color: ' + tag.color + '"></i>';
                });

                output += '</span>';
            }

            output += '</span>';

            return output
        }

        function getTitleParts(group) {
            return group.title.split(/ \/ | - /);
        }

        function getLeafGroups(groups) {
            var parentGroupIds = [];

            for (var i = 0; i < groups.length; i++) {
                if (groups[i].parent_group_id) {
                    parentGroupIds.push(groups[i].parent_group_id)
                }
            }

            var leafGroups = [];

            for (var i = 0; i < groups.length; i++) {
                if (parentGroupIds.indexOf(groups[i].id) == -1) {
                    // Group is leaf node
                    leafGroups.push(groups[i]);
                }
            }

            return leafGroups;
        }

        function getRootGroups(groups) {
            var rootGroups = [];

            for (var i = 0; i < groups.length; i++) {
                if (!groups[i].parent_group_id) {
                    rootGroups.push(groups[i]);
                }
            }

            return rootGroups;
        }

        function openDeleteGroupModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this group <strong>and all of its subgroups</strong>?',
                message: 'The group\'s contacts will not be deleted.'
            }).result.then(function (data) {
                return deleteGroup(id).then(callback);
            });
        }

        return {
            accounts: accounts,
            group: group,
            groups: groups,
            addGroup: addGroup,
            addGroupMembership: addGroupMembership,
            editGroup: editGroup,
            deleteGroup: deleteGroup,
            duplicateGroup: duplicateGroup,
            deleteGroupMembership: deleteGroupMembership,
            formatGroupTitle: formatGroupTitle,
            getTitleParts: getTitleParts,
            getLeafGroups: getLeafGroups,
            getRootGroups: getRootGroups,
            openDeleteGroupModal: openDeleteGroupModal
        };
    }
})(angular);
