(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderAccountFilterBlock', segmentBuilderAccountFilterBlock);

    segmentBuilderAccountFilterBlock.$inject = ['config'];

    function segmentBuilderAccountFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                filterBlock: '=',
                segment: '='
            },
            controller: ['$scope', 'segmentBuilder', function SegmentBuilderAccountFilterBlockController($scope, segmentBuilder) {
                $scope.filterables = null;

                getFilterables();

                $scope.deleteFilterBlock = deleteFilterBlock;
                $scope.addFilter = addFilter;

                /**
                 * Resets the filter block and filterables.
                 * @return {undefined}
                 */
                function deleteFilterBlock() {
                    $scope.filterBlock = null;
                    $scope.filterables = null;
                }

                /**
                 * Adds a comparison filter to the filter block.
                 * @return {undefined}
                 */
                function addFilter() {
                    $scope.filterBlock.filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Fetches the fields that can be filtered on.
                 * @return {undefined}
                 */
                function getFilterables() {
                    segmentBuilder.filterables.account().then(function (filterables) {
                        $scope.filterables = filterables;
                    });
                }
            }],
            templateUrl: config.path.template('segments.builder.account_filter_block')
        };
    }
}(angular));
