(function (angular) {
    angular
        .module('one.admin')
        .controller('RefundPurchaseModalController', RefundPurchaseModalController);

    RefundPurchaseModalController.$inject = ['$scope', '$uibModalInstance', 'PurchaseService', 'notification', 'params'];

    function RefundPurchaseModalController($scope, $uibModalInstance, PurchaseService, notification, params) {
        $scope.purchase = params.purchase;

        $scope.refund = {
            payment_terms: []
        };

        angular.forEach($scope.purchase.payment_terms, function (paymentTerm) {
            paymentTerm.refundable = isRefundable(paymentTerm);

            if (paymentTerm.refundable) {
                $scope.refund.payment_terms.push(paymentTerm);
            }
        });

        $scope.close = $uibModalInstance.close;
        $scope.refundPurchase = refundPurchase;

        function refundPurchase(purchase, refund) {
            PurchaseService.refundPurchase(purchase.id, refund).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function isRefundable(paymentTerm) {
            return paymentTerm.actions.indexOf('refund') > -1;
        }
    }
})(angular);
