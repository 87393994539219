(function (angular) {
    angular
        .module('one.admin')
        .directive('mailBodyEditor', mailBodyEditorDirective);

    mailBodyEditorDirective.$inject = ['config'];

    function mailBodyEditorDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                mail: '=',
                type: '@',
                variables: '='
            },
            controller: ['$scope', '$rootScope', '$timeout', 'MailService', function ($scope, $rootScope, $timeout, MailService) {
                var sectionMap = {};
                var timer;

                angular.forEach($scope.mail.sections, function (section) {
                    sectionMap[section.name] = section;
                });

                $scope.forms = {};
                $scope.files = {};

                var placeholders = $scope.mail.template.placeholders;

                if ($scope.mail.template && placeholders.length > 0) {
                    for (var i = 0; i < placeholders.length; i++) {
                        if (placeholders[i].type == 'text' && !$scope.forms.showPlaceholderForm) {
                            $scope.forms.showPlaceholderForm = placeholders[i].name;
                        }

                        $scope.files[placeholders[i].name] = null;
                    }

                    if (!$scope.forms.showPlaceholderForm) {
                        $scope.forms.showPlaceholderForm = placeholders[0].name;
                    }
                }

                $scope.placeholderIcons = MailService.getPlaceholderIcons();

                $scope.mail.type = $scope.type;
                $scope.site = $scope.mail.campaign ? { id: $scope.mail.campaign.site_id } : null;

                $scope.preview = null;

                $scope.$watch('mail.template.id', getEditorTheme);
                $scope.$watch('mail.template.placeholders', updateSections);
                $scope.$watch('mail.sections', getLivePreview, true);

                // If there are changes, prompt the user with a confirmation window when leaving the page
                var originalValue = null;

                $scope.$watch(
                    // Watch for changes
                    function () {
                        return JSON.stringify({
                            template: $scope.mail.template.id,
                            sections: $scope.mail.sections.map(function (section) {
                                var keys = ['value', 'width', 'height', 'url','link'];

                                return keys.map(function (key) {
                                    return section.config[key] || null;
                                });
                            }),
                        });
                    },
                    // Set the 'changed' flag that activates the confirmation window
                    function (value) {
                        if (!originalValue) {
                            originalValue = value;
                        } else {
                            $rootScope.setChanged(value !== originalValue);
                        }
                    }
                );

                $scope.togglePlaceholderForm = togglePlaceholderForm;
                $scope.setImage = setImage;

                function updateSections(placeholders) {
                    placeholders = placeholders || [];

                    var sections = [];

                    angular.forEach(placeholders, function (placeholder) {
                        if (!sectionMap[placeholder.name]) {
                            sectionMap[placeholder.name] = MailService.createSection(placeholder);
                        }

                        if (sectionMap[placeholder.name].placeholder && angular.equals(sectionMap[placeholder.name].config, sectionMap[placeholder.name].placeholder.config)) {
                            // Section config is default value. Empty it so that the placeholder can inherit the new placeholder's config.
                            sectionMap[placeholder.name].config = null;
                        }

                        sectionMap[placeholder.name].config = angular.extend({}, placeholder.config, sectionMap[placeholder.name].config);
                        sectionMap[placeholder.name].placeholder = placeholder;

                        sections.push(sectionMap[placeholder.name]);

                        if (sectionMap[placeholder.name].config.url) {
                            $scope.files[placeholder.name] = {
                                url: sectionMap[placeholder.name].config.url
                            };
                        } else {
                            $scope.files[placeholder.name] = null;
                        }
                    });

                    $scope.mail.sections = sections;
                }

                function togglePlaceholderForm(name) {
                    $scope.forms.showPlaceholderForm = ($scope.forms.showPlaceholderForm == name ? null : name)
                }

                function getLivePreview() {
                    var delay = $scope.preview ? 500 : 0;

                    $timeout.cancel(timer);

                    timer = $timeout(function () {
                        MailService.livePreviewMail({
                            site: $scope.site,
                            template: $scope.mail.template,
                            sections: $scope.mail.sections,
                            sender: $scope.mail.sender,
                            type: $scope.type
                        }, { ignoreLoadingBar: true }).then(function (preview) {
                            $scope.preview = preview;
                        });
                    }, delay);
                }

                function getEditorTheme() {
                    if ($scope.mail.template) {
                        $scope.theme = MailService.getEditorTheme($scope.mail.template);
                    } else {
                        $scope.theme = {};
                    }
                }

                function setImage(section) {
                    var image = $scope.files[section.name] || section.placeholder.config || {};

                    section.config = angular.merge(section.config, {
                        url: image.url
                    });

                    if (!$scope.files[section.name] || $scope.files[section.name].url != image.url) {
                        $scope.files[section.name] = image.url ? {
                            url: image.url
                        } : null;
                    }
                }
            }],
            templateUrl: config.path.template('mails.body.editor')
        };
    }
})(angular);
