(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['config'];

    function config(config) {
        var isActive = function (cmd) {
            var tag = 'N';
            var blocks = this.selection.blocks();

            if (blocks.length) {
                var blk = blocks[0];
                var tag = 'N';
                var default_tag = this.html.defaultTag();
                if (blk.tagName.toLowerCase() != default_tag && blk != this.el) {
                    tag = blk.tagName;
                }
            }

            if (['LI', 'TD', 'TH'].indexOf(tag) >= 0) {
                tag = 'N';
            }

            return tag.toLowerCase() == cmd;
        }

        var headings = ['h1', 'h2', 'h3'];

        for (var i = 0; i < headings.length; i++) {
            $.FroalaEditor.DefineIcon(headings[i], { NAME: '<strong>' + headings[i].toUpperCase() + '</strong>', template: 'text' });

            $.FroalaEditor.RegisterCommand(headings[i], {
                title: 'Heading 1',
                callback: function (cmd, val, params) {
                    if (isActive.apply(this, [cmd])) {
                        this.paragraphFormat.apply('N');
                    }
                    else {
                        this.paragraphFormat.apply(cmd);
                    }
                },
                refresh: function ($btn) {
                    $btn.toggleClass('fr-active', isActive.apply(this, [$btn.data('cmd')]));
                }
            });
        }

        $.FroalaEditor.DefineIcon('variables', { NAME: 'plus-circle' });

        $.FroalaEditor.RegisterCommand('variables', {
            title: 'Insert variable',
            type: 'dropdown',
            options: {},
            callback: function (cmd, val) {
                this.html.insert('{{ ' + val + ' }}');

                this.events.focus();
            },
            refreshOnShow: function ($btn, $dropdown) {
                $dropdown.find('.fr-dropdown-list').html('');

                angular.forEach(this.opts.variables, function (variable) {
                    $dropdown.find('.fr-dropdown-list').append('<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="variables" data-param1="' + variable + '" title="' + variable + '" aria-selected="false">' + config.templateCode.output(variable.split('|')[0]) + '</a></li>');
                });
            }
        });

        var codeMirrorOptions = {
            lineNumbers: true,
            lineWrapping: true,
            matchTags: { bothTags: true },
            autoCloseTags: true,
            mode: 'htmltwig',
            htmlMode: true,
            tabSize: 2,
        };

        var froalaOptions = {
            toolbarButtons: ['h1', 'h2', 'h3', 'bold', 'italic', 'underline', 'clearFormatting', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'insertLink', 'ocImage', 'insertTable', 'insertHR', 'html'],
            placeholderText: '',
            listAdvancedTypes: false,
            enter: $.FroalaEditor.ENTER_BR,
            imageUpload: true,
            imageUploadURL: '../../api/public/files/upload',
            imageUploadMethod: 'POST',
            imageUploadParams: {
                user_file: true
            },
            imageManagerDeleteURL: null,
            pasteImage: true,
            pastePlain: true,
            wordPasteModal: false,
            linkList: false,
            linkInsertButtons: [],
            imageCORSProxy: '', // https://github.com/froala/wysiwyg-editor/issues/2308
            imageEditButtons: ['ocImage', 'imageAlign', 'imageAlt', 'imageRemove', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', 'imageSize'],
            tableEditButtons: ['tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
            codeBeautifierOptions: {
                end_with_newline: true,
                indent_inner_html: true,
                brace_style: 'expand',
                indent_char: ' ',
                indent_size: 2,
                wrap_line_length: 0
            },
            codeMirrorOptions: codeMirrorOptions,
            key: 'nD5D1C1C1wG1G1B1B3B1C7A5E1F4B3jvhiF3trxA-7pmlwmmqD-8D3fe==',
            htmlUntouched: true,
            htmlAllowedTags: ['h1', 'h2', 'h3', 'br', 'hr', 'table', 'tr', 'td', 'span', 'b', 'i', 'u', 'strong', 'em', 'a', 'img', 'ul', 'ol', 'li', 'div', 'p', 'span'],
            htmlAllowedAttrs: ['class', 'id', 'style', 'src', 'href', 'target', 'width', 'height', 'alt', 'border', 'colspan', 'rowspan', 'cellspacing', 'cellpadding', 'align'],
            htmlAllowedEmptyTags: $.FroalaEditor.DEFAULTS.htmlAllowedEmptyTags.concat(['br', 'span', 'div']),
            linkStyles: {
                '': 'No style',
                'btn btn-default': 'Default button',
                'btn btn-primary': 'Primary button',
                'btn btn-secondary': 'Secondary button',
                'btn btn-tertiary': 'Tertiary button'
            },
            linkMultipleStyles: false,
            paragraphStyles: {
                'text-primary': 'Primary',
                'text-secondary': 'Secondary',
                'text-tertiary': 'Tertiary',
                'text-success': 'Success',
                'text-danger': 'Danger',
                'text-muted': 'Muted'
            },
            paragraphMultipleStyles: false,
            spellcheck: false
        };

        config.codeMirrorOptions = codeMirrorOptions;
        config.froalaOptions = froalaOptions;

        config.simpleFroalaOptions = angular.extend(angular.copy(froalaOptions), {
            toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'clearFormatting']
        });

        config.textFroalaOptions = angular.extend(angular.copy(froalaOptions), {
            toolbarButtons: ['h1', 'h2', 'h3', 'bold', 'italic', 'underline', 'insertLink', 'clearFormatting', 'paragraphStyle', 'align']
        });
    }
})(angular);
