(function (angular) {
    angular
        .module('one.admin')
        .directive('tagForm', tagFormDirective);

    tagFormDirective.$inject = ['config'];

    function tagFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                tag: '=',
                tags: '=',
                colors: '='
            },
            controller: ['$scope', function ($scope) {
                $scope.usedColors = {};

                angular.forEach($scope.tags, function (tag) {
                    $scope.usedColors[tag.color] = $scope.usedColors[tag.color] || [];

                    $scope.usedColors[tag.color].push(tag.title);
                });

                angular.forEach($scope.colors, function (color) {
                    $scope.usedColors[color] = ($scope.usedColors[color] || []).join(' / ');
                });
            }],
            templateUrl: config.path.template('tags.form')
        };
    }
}(angular));
