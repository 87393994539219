(function(angular) {
    angular
        .module('one.admin')
        .directive('listModuleForm', listModuleFormDirective);

    listModuleFormDirective.$inject = ['config', 'VisitableService'];

    function listModuleFormDirective(config, VisitableService) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.site = $scope.module.type.site;
            $scope.getIcons = config.faIcons;
            $scope.selectedItemIndex = 0;
            $scope.iconAlignments = ['left', 'top', 'right'];
            $scope.layouts = ['columns', 'rows'];
            $scope.selectedItem = $scope.module.module_instance.items[$scope.selectedItemIndex];

            $scope.stringToIconMap = {
                columns: 'view_column',
                rows: 'view_list'
            };

            $scope.alignmentsMap = {
                left: 'arrow_back',
                top: 'arrow_upward',
                right: 'arrow_forward'
            };

            $scope.module.module_instance.items.map(function (item) {
                if (item.custom_url) {
                    item.has_custom_url = true;
                }
            });

            $scope.selectItem = selectItem;
            $scope.addItem = addItem;
            $scope.moveItem = moveItem;
            $scope.deleteItem = deleteItem;
            $scope.formatVisitable = VisitableService.formatVisitableTitle;
            $scope.getVisitables = VisitableService.pageVisitables;
            $scope.getBrandColor = getBrandColor;
            $scope.formatIcon = formatIcon;
            $scope.formatVisitable = VisitableService.formatVisitableTitle;
            $scope.getVisitables = VisitableService.pageVisitables;
            $scope.setItemLink = setItemLink;

            function selectItem(index) {
                $scope.selectedItemIndex = index;
                $scope.selectedItem = $scope.module.module_instance.items[$scope.selectedItemIndex];
            }

            function formatIcon(string) {
                if (string) {
                    return $scope.stringToIconMap[string];
                }

                return '';
            }

            function addItem() {
                var newItemIndex = this.module.module_instance.items.length;

                var newItem = {
                    content: '',
                    alignment: this.module.module_instance.items[newItemIndex - 1].alignment,
                    position: newItemIndex + 1,
                    new_window: false
                };

                this.module.module_instance.items.push(newItem);
                this.selectItem(newItemIndex);
            }

            function setItemLink(item, hasCustomUrl) {
                if (item.has_custom_url != hasCustomUrl) {
                    item.has_custom_url = hasCustomUrl;
                    item.visitable = null;
                    item.custom_url = null;
                }
            }

            function moveItem(newIndex, movedItem) {
                if ($scope.selectedItem.id == movedItem.id) {
                    this.selectItem(newIndex);
                }
            }

            function getBrandColor(brand) {
                return $scope.module.type.site.config.brands[brand];
            }

            function deleteItem(index) {
                $scope.module.module_instance.items.splice(index, 1);

                var newItemIndex = Math.min(
                    $scope.selectedItemIndex,
                    this.module.module_instance.items.length - 1
                );

                this.selectItem(newItemIndex);
            }

            $scope.formatIcon = function(icon) {
                if (icon) {
                    return '<i class="' + icon.prefix + ' fa-fw fa-' + icon.name + ' m-r-1_2"></i>' + icon.name;
                }

                return '';
            };
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.list.form')
        };
    }
})(angular);
