(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountCommunicationController', AccountCommunicationController);

    AccountCommunicationController.$inject = ['$scope', 'AccountService', 'AuthService', 'InteractionService', 'modal', 'dataframe', 'account'];

    function AccountCommunicationController($scope, AccountService, AuthService, InteractionService, modal, dataframe, account) {
        $scope.account = account;

        $scope.interactions = dataframe.init({
            externalData: function (params) {
                return AccountService.account.interactions($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Finished',
                    name: 'finished',
                    display: function (row) {
                        row.setFinished = function (status) {
                            if (row.loading) {
                                return;
                            }

                            row.loading = true;
                            row.finished = status;

                            InteractionService.setFinished(row.id, status).then(function (data) {
                                InteractionService.updateBadgeCount();
                                row.loading = false;
                                $scope.interactions.getData();
                                $scope.interactions.loading = false;
                            });
                        };

                        var result = '';

                        result += '<span ng-if="!row.finished" class="interaction-toggle" ng-click="row.setFinished(true); $event.stopPropagation()"><i class="md-icon">check_box_outline_blank</i></span>';
                        result += '<span ng-if="row.finished" class="interaction-toggle" ng-click="row.setFinished(false); $event.stopPropagation()" class="text-muted"><i class="md-icon">check_box</i></span>';

                        return result;
                    },
                    weight: 1
                },
                {
                    title: 'Summary',
                    name: 'description',
                    display: function (row) {
                        return '<h4 class="text-limiter w80"><i class="md-icon text-muted" ng-if="row.incoming">call_received</i> {{ row.description }}</h4>';
                    },
                    weight: 5
                },
                {
                    title: 'Subject',
                    name: 'subject',
                    display: function (row) {
                        return '<div class="text-limiter w80">{{ row.subject.title }}</div>';
                    },
                    weight: 3
                },
                {
                    title: 'User',
                    name: 'assigned_user.full_name',
                    display: function (row) {
                        if (row.assigned_user) {
                            return '<span class="one-user"><i class="md-icon">account_circle</i> {{ row.assigned_user.first_name }}</span>';
                        }
                        return '';
                    },
                    weight: 2
                },
                {
                    title: 'Date',
                    name: 'contact_date',
                    display: InteractionService.displayContactDate,
                    weight: 2
                }
            ],
            rowClick: function (row) {
                openInteractionModal(row, 'lg');
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.interactions.editInteraction({interactionId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        InteractionService.openDeleteInteractionModal(row.id, function () {
                            InteractionService.updateBadgeCount();
                            $scope.interactions.getData();
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.delete');
                    }
                }
            ],
            state: {
                perPage: 5,
                sort: { id: 'desc' }
            }
        });

        $scope.sentMails = dataframe.init({
            externalData: function (params) {
                return AccountService.account.sentMails($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Subject',
                    name: 'subject',
                    display: function (row) {
                        return '<h4 class="text-limiter w80">{{ row.subject }}</h4>';
                    },
                    weight: 3
                },
                {
                    title: 'Status',
                    name: 'status',
                    display: function (row) {
                        return '<span uib-tooltip="{{ row.status_reason }}" class="' + (row.status == 'failed' ? 'text-danger' : (row.status == 'delivered' ? 'text-success' : '')) + '" ng-if="row.status">' +
                            '<i class="md-icon">' + (row.status == 'failed' ? 'error' : (row.status == 'delivered' ? 'check' : 'more_horiz')) + '</i> ' +
                            '{{ \'general.' + row.status + '\' | translate }}' +
                            '</span>';
                    },
                    weight: 2
                },
                {
                    title: 'To',
                    name: 'recipient',
                    weight: 3
                },
                {
                    title: 'Date',
                    name: 'date_sent',
                    display: function (row) {
                        return '{{ row.created_at | formatDate }}';
                    },
                    weight: 2
                }
            ],
            rowClick: function (row) {
                openSentMailModal(row, 'lg');
            },
            state: {
                perPage: 5,
                sort: { created_at: 'desc' }
            }
        });

        $scope.openAddInteractionModal = openAddInteractionModal;
        $scope.openSendPersonalMailModal = openSendPersonalMailModal;

        function openInteractionModal(interaction, size) {
            interaction.account = account;

            modal.open('interaction', size, { interaction: interaction });
        }

        function openSentMailModal(sentMail, size) {
            sentMail.account = account;
            modal.open('sentMail', size, { sentMail: sentMail });
        }

        function openAddInteractionModal(size) {
            modal.open('addInteraction', size, { interaction: { account: account } }).result.then(function (data) {
                $scope.interactions.getData();
            });
        }

        function openSendPersonalMailModal(size) {
            modal.open('sendPersonalMail', size, { mail: { account: account } }).result.then(function (data) {
                $scope.sentMails.getData();
            });
        }
    }
})(angular);
