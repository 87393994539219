(function (angular) {
    angular
        .module('one.admin')
        .controller('AddGiftRewardModalController', AddGiftRewardModalController);

    AddGiftRewardModalController.$inject = ['$scope', '$uibModalInstance', '$state', 'GiftService', 'params'];

    function AddGiftRewardModalController($scope, $uibModalInstance, $state, GiftService, params) {
        $scope.giftReward = {
            active: true
        };

        angular.extend($scope.giftReward, params.giftReward);

        $scope.addGiftReward = addGiftReward;
        $scope.cancel = cancel;

        function addGiftReward(data) {
            GiftService.addGiftReward(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
