(function (angular) {
    angular
        .module('one.admin')
        .service('ImportService', ImportService);

    ImportService.$inject = ['http', 'modal'];

    function ImportService(http, modal) {
        function getImport(id, params, ignoreLoadingBar) {
            return http.get('imports/' + id, params, null, { ignoreLoadingBar: ignoreLoadingBar });
        }

        function imports(params) {
            return http.get('imports', params);
        }

        function importRecords(id, params, ignoreLoadingBar) {
            return http.get('imports/' + id + '/records', params, null, { ignoreLoadingBar: ignoreLoadingBar });
        }

        function invalidImportRecords(id, params, ignoreLoadingBar) {
            return http.get('imports/' + id + '/invalid_records', params, null, { ignoreLoadingBar: ignoreLoadingBar });
        }

        function deleteImport(id) {
            return http.post('imports/' + id + '/delete');
        }

        function finishImport(id, data) {
            return http.post('imports/' + id + '/finish', data);
        }

        function openDeleteImportModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Are you sure?',
                message: 'This will not delete the data imported by this import, but only the information about this import.'
            }).result.then(function (data) {
                return deleteImport(id).then(callback);
            });
        }

        return {
            getImport: getImport, // Use this name because "import" is a reserved keyword.
            imports: imports,
            importRecords: importRecords,
            invalidImportRecords: invalidImportRecords,
            deleteImport: deleteImport,
            finishImport: finishImport,
            openDeleteImportModal: openDeleteImportModal
        };
    }
})(angular);
