(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('content', {
            parent: 'main',
            abstract: true,
            url: '/content',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.pdfTemplates', {
            parent: 'content',
            abstract: true,
            url: '/pdf-templates',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.pdfTemplates.index', {
            url: '',
            controller: 'PdfTemplatesController',
            templateUrl: config.path.template('pdf_templates.index'),
            requirePermission: 'pdf_templates.overview'
        });

        $stateProvider.state('content.pdfTemplates.addPdfTemplate', {
            url: '/add',
            controller: 'AddPdfTemplateController',
            templateUrl: config.path.template('pdf_templates.add'),
            requirePermission: 'pdf_templates.add'
        });

        $stateProvider.state('content.pdfTemplates.editPdfTemplate', {
            url: '/:pdfTemplateId/edit',
            controller: 'EditPdfTemplateController',
            templateUrl: config.path.template('pdf_templates.edit'),
            resolve: {
                pdfTemplate: ['PdfTemplateService', '$stateParams', function (PdfTemplateService, $stateParams) {
                    return PdfTemplateService.pdfTemplate($stateParams.pdfTemplateId);
                }]
            },
            requirePermission: 'pdf_templates.edit'
        });

        $stateProvider.state('content.sites', {
            parent: 'content',
            abstract: true,
            url: '/sites',
            template: '<div ui-view></div>',
            params: {
                section: 'content',
                dependsOnCurrentSite: true
            }
        });

        $stateProvider.state('content.sites.site', {
            url: '/site',
            controller: 'SiteController',
            templateUrl: config.path.template('sites.site'),
            requirePermission: 'sites.edit',
            resolve: {
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            }
        });

        $stateProvider.state('content.sites.pageBlocks', {
            url: '/page-blocks',
            controller: 'PageBlocksController',
            templateUrl: config.path.template('sites.page_blocks'),
            params: {
                dependsOnCurrentSite: true
            },
            requirePermission: 'sites.edit'
        });

        $stateProvider.state('content.sites.styles', {
            url: '/styles',
            controller: 'SiteStylesController',
            templateUrl: config.path.template('sites.styles'),
            requirePermission: 'sites.edit'
        });

        $stateProvider.state('content.sites.socialMedia', {
            url: '/social-media',
            controller: 'SiteSocialMediaController',
            templateUrl: config.path.template('sites.social_media'),
            requirePermission: 'sites.edit'
        });

        $stateProvider.state('content.sites.trackingCodes', {
            url: '/tracking-codes',
            controller: 'SiteTrackingCodesController',
            templateUrl: config.path.template('sites.tracking_codes'),
            requirePermission: 'sites.edit'
        });

        $stateProvider.state('content.sites.scripts', {
            url: '/scripts',
            controller: 'SiteScriptsController',
            templateUrl: config.path.template('sites.scripts'),
            requirePermission: 'sites.edit'
        });

        $stateProvider.state('content.files', {
            parent: 'content',
            abstract: true,
            url: '/files',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.files.index', {
            url: '',
            controller: 'FilesController',
            templateUrl: config.path.template('files.index'),
            params: {
                dependsOnCurrentSite: true
            },
            requirePermission: 'files.overview'
        });

        $stateProvider.state('content.pages', {
            parent: 'content',
            abstract: true,
            url: '/pages',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.pages.index', {
            url: '',
            controller: 'PagesController',
            templateUrl: config.path.template('pages.index'),
            resolve: {
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }],
            },
            params: {
                dependsOnCurrentSite: true
            },
            requirePermission: 'pages.overview'
        });

        $stateProvider.state('content.pages.addPage', {
            url: '/add',
            controller: 'AddPageController',
            templateUrl: config.path.template('pages.add'),
            resolve: {
                blocks: ['PageService', function (PageService) {
                    return PageService.blocks();
                }],
                flags: ['PageService', function (PageService) {
                    return PageService.flags();
                }],
                languages: ['PageService', function (PageService) {
                    return PageService.languages();
                }]
            },
            requirePermission: 'pages.add'
        });

        $stateProvider.state('content.pages.pageSettings', {
            url: '/:pageId/settings',
            controller: 'PageSettingsController',
            templateUrl: config.path.template('pages.manage.settings'),
            resolve: {
                blocks: ['PageService', function (PageService) {
                    return PageService.blocks();
                }],
                flags: ['PageService', function (PageService) {
                    return PageService.flags();
                }],
                languages: ['PageService', function (PageService) {
                    return PageService.languages();
                }],
                page: ['PageService', '$stateParams', function (PageService, $stateParams) {
                    return PageService.page($stateParams.pageId);
                }]
            },
            requirePermission: 'pages.edit'
        });

        $stateProvider.state('content.pages.pageContent', {
            url: '/:pageId/content',
            controller: 'PageContentController',
            templateUrl: config.path.template('pages.manage.content'),
            resolve: {
                moduleTypes: ['ModuleService', function (ModuleService) {
                    return ModuleService.types();
                }],
                page: ['PageService', '$stateParams', function (PageService, $stateParams) {
                    return PageService.page($stateParams.pageId);
                }],
                rowOptions: ['PageService', function (PageService) {
                    return PageService.rowOptions();
                }]
            },
            requirePermission: 'pages.edit'
        });

        $stateProvider.state('content.pageBlocks', {
            parent: 'content',
            abstract: true,
            url: '/page-blocks',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.pageBlocks.addPageBlock', {
            url: '/add',
            controller: 'AddPageBlockController',
            templateUrl: config.path.template('page_blocks.add'),
            resolve: {
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            },
            requirePermission: 'page_blocks.add'
        });

        $stateProvider.state('content.pageBlocks.editPageBlock', {
            url: '/:pageBlockId/edit',
            controller: 'EditPageBlockController',
            templateUrl: config.path.template('page_blocks.edit'),
            resolve: {
                pageBlock: ['PageService', '$stateParams', function (PageService, $stateParams) {
                    return PageService.block($stateParams.pageBlockId);
                }],
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            },
            requirePermission: 'page_blocks.edit'
        });

        $stateProvider.state('content.pageNavs', {
            parent: 'content',
            abstract: true,
            url: '/navs',
            template: '<div ui-view></div>',
            params: {
                section: 'content'
            }
        });

        $stateProvider.state('content.pageNavs.index', {
            url: '',
            controller: 'PageNavsController',
            templateUrl: config.path.template('page_navs.index'),
            resolve: {
                pageNavs: ['PageService', function (PageService) {
                    return PageService.navs();
                }],
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            },
            params: {
                dependsOnCurrentSite: true
            },
            requirePermission: 'page_navs.overview'
        });

        $stateProvider.state('content.pageNavs.editPageNav', {
            url: '/:pageNavId/edit',
            controller: 'EditPageNavController',
            templateUrl: config.path.template('page_navs.edit'),
            resolve: {
                pageNav: ['PageService', '$stateParams', function (PageService, $stateParams) {
                    return PageService.nav($stateParams.pageNavId);
                }],
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            },
            requirePermission: 'page_navs.edit'
        });

        $stateProvider.state('content.modules', {
            parent: 'content',
            abstract: true,
            url: '/modules',
            template: '<div ui-view></div>',
            params: {
                section: 'content',
                pageId: null
            }
        });

        $stateProvider.state('content.modules.index', {
            url: '',
            controller: 'ModulesController',
            templateUrl: config.path.template('modules.index'),
            params: {
                dependsOnCurrentSite: true
            },
            resolve: {
                moduleTypes: ['ModuleService', function (ModuleService) {
                    return ModuleService.types();
                }]
            },
            requirePermission: 'modules.overview'
        });

        $stateProvider.state('content.modules.contentModule', {
            url: '/content/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.contentModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.content.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.formModule', {
            url: '/form/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.formModule.index', {
            url: '/index',
            controller: 'EditFormModuleController',
            templateUrl: config.path.template('modules.form.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.htmlModule', {
            url: '/html/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true,
        });

        $stateProvider.state('content.modules.htmlModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.html.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.actionCampaignOverviewModule', {
            url: '/action-campaign-overview/:moduleId',
            template: '<div ui-view></div>',
            abstract: true
        });

        $stateProvider.state('content.modules.actionCampaignOverviewModule.index', {
            url: '/index',
            controller: 'EditActionCampaignOverviewModuleController',
            templateUrl: config.path.template('modules.action_campaign_overview.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }],
                actionProjects: ['ActionProjectService', 'session', function (ActionProjectService, session) {
                    return ActionProjectService.actionProjects({ filters: { active: true, sites: session.get('data.current_site.id') } });
                }],
                overviewTypes: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.overviewTypes();
                }],
                displayOptions: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.displayOptions();
                }],
                layoutOptions: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.layoutOptions();
                }],
                sortOptions: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.sortOptions();
                }],
                actionCampaignTypes: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.faqModule', {
            url: '/faq/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.faqModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.faq.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.sliderModule', {
            url: '/slider/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.sliderModule.index', {
            url: '/index',
            controller: 'SliderModuleIndexController',
            templateUrl: config.path.template('modules.slider.index'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            }
        });

        $stateProvider.state('content.modules.sliderModule.edit', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.slider.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            }
        });

        $stateProvider.state('content.modules.newsModule', {
            url: '/news/:moduleId',
            template: '<div ui-view> </div>',
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            abstract: true
        });

        $stateProvider.state('content.modules.newsModule.index', {
            url: '/index',
            controller: 'NewsModuleIndexController',
            templateUrl: config.path.template('modules.news.index'),
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.newsModule.edit', {
            url: '/edit',
            controller: 'EditNewsModuleController',
            templateUrl: config.path.template('modules.news.edit'),
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.newsModule.posts', {
            url: '/posts',
            template: '<div ui-view></div>',
            abstract: true
        });

        $stateProvider.state('content.modules.newsModule.posts.addPost', {
            url: '/add',
            controller: 'AddNewsModulePostController',
            templateUrl: config.path.template('modules.news.post.add'),
            requirePermission: 'modules.add'
        });

        $stateProvider.state('content.modules.newsFeedModule', {
            url: '/news-feed/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.newsFeedModule.index', {
            url: '/index',
            controller: 'NewsFeedModuleIndexController',
            templateUrl: config.path.template('modules.news-feed.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }],
                newsModules: ['NewsModuleService', function (NewsModuleService) {
                    return NewsModuleService.newsModules();
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.videoModule', {
            url: '/video/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.videoModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.video.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.posts', {
            parent: 'content',
            abstract: true,
            url: '/posts',
            template: '<div ui-view></div>',
            params: {
                section: 'posts'
            }
        });

        $stateProvider.state('content.posts.editPost', {
            url: '/:postId/edit',
            controller: 'EditPostController',
            templateUrl: config.path.template('posts.edit'),
            resolve: {
                post: ['PostService', '$stateParams', function (PostService, $stateParams) {
                    return PostService.post($stateParams.postId);
                }]
            },
            requirePermission: 'posts.edit'
        });

        $stateProvider.state('content.modules.listModule', {
            url: '/list/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.cardModule', {
            url: '/card/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.cardModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.card.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.carouselModule', {
            url: '/carousel/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.carouselModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.carousel.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.countdownModule', {
            url: '/countdown/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.listModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.list.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.countdownModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.countdown.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });


        $stateProvider.state('content.modules.imageModule', {
            url: '/image/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.imageModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.image.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.lineModule', {
            url: '/line/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.lineModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.line.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.buttonModule', {
            url: '/button/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.buttonModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.button.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.galleryModule', {
            url: '/gallery/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.galleryModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.gallery.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        $stateProvider.state('content.modules.shareModule', {
            url: '/share/:moduleId',
            template: '<div ui-view> </div>',
            abstract: true
        });

        $stateProvider.state('content.modules.shareModule.index', {
            url: '/edit',
            controller: 'EditModuleController',
            templateUrl: config.path.template('modules.share.edit'),
            resolve: {
                module: ['ModuleService', '$stateParams', function (ModuleService, $stateParams) {
                    return ModuleService.module($stateParams.moduleId);
                }]
            },
            requirePermission: 'modules.edit'
        });

        modalProvider.modal('addFile', {
            templateUrl: config.path.template('files.add'),
            controller: 'AddFileModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }]
            }
        });

        modalProvider.modal('selectFile', {
            templateUrl: config.path.template('files.select_modal'),
            controller: 'SelectFileModalController',
            resolve: {}
        });

        modalProvider.modal('confirmLeavePage', {
            templateUrl: config.path.template('pages.manage.content.confirm_leave_page'),
            controller: 'ConfirmLeavePageModalController'
        });

        modalProvider.modal('addPageNav', {
            templateUrl: config.path.template('page_navs.add'),
            controller: 'AddPageNavModalController',
            backdrop: 'static',
            keyboard: false,
            requirePermission: 'page_navs.add',
            resolve: {
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            }
        });

        modalProvider.modal('addActionCampaignOverviewModule', {
            templateUrl: config.path.template('modules.action_campaign_overview.add'),
            controller: 'AddActionCampaignOverviewModuleModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                actionProjects: ['ActionProjectService', 'session', function (ActionProjectService, session) {
                    return ActionProjectService.actionProjects({ filters: { active: true, sites: session.get('data.current_site.id') } });
                }],
                displayOptions: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.displayOptions();
                }],
                layoutOptions: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.layoutOptions();
                }],
                overviewTypes: ['ActionCampaignOverviewModuleService', function (ActionCampaignOverviewModuleService) {
                    return ActionCampaignOverviewModuleService.overviewTypes();
                }],
                sortOptions: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.sortOptions();
                }],
                actionCampaignTypes: ['ActionCampaignService', function (ActionCampaignService) {
                    return ActionCampaignService.types();
                }]
            }
        });

        modalProvider.modal('addContentModule', {
            templateUrl: config.path.template('modules.content.add'),
            controller: 'AddContentModuleModalController',
            backdrop: 'static',
            keyboard: false,
            requirePermission: 'modules.add'
        });

        modalProvider.modal('addFormModule', {
            templateUrl: config.path.template('modules.form.add'),
            controller: 'AddFormModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addHtmlModule', {
            templateUrl: config.path.template('modules.html.add'),
            controller: 'AddHtmlModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addNewsModule', {
            templateUrl: config.path.template('modules.news.add'),
            controller: 'AddNewsModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addNewsFeedModule', {
            templateUrl: config.path.template('modules.news-feed.add'),
            controller: 'AddNewsFeedModuleModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                newsModules: ['NewsModuleService', function (NewsModuleService) {
                    return NewsModuleService.newsModules();
                }]
            }
        });

        modalProvider.modal('addVideoModule', {
            templateUrl: config.path.template('modules.video.add'),
            controller: 'AddVideoModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addFaqModule', {
            templateUrl: config.path.template('modules.faq.add'),
            controller: 'AddFaqModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addSliderModule', {
            templateUrl: config.path.template('modules.slider.add'),
            controller: 'AddSliderModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addListModule', {
            templateUrl: config.path.template('modules.list.add'),
            controller: 'AddListModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addCardModule', {
            templateUrl: config.path.template('modules.card.add'),
            controller: 'AddCardModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addCarouselModule', {
            templateUrl: config.path.template('modules.carousel.add'),
            controller: 'AddCarouselModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addImageModule', {
            templateUrl: config.path.template('modules.image.add'),
            controller: 'AddImageModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addButtonModule', {
            templateUrl: config.path.template('modules.button.add'),
            controller: 'AddButtonModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addCountdownModule', {
            templateUrl: config.path.template('modules.countdown.add'),
            controller: 'AddCountdownModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addLineModule', {
            templateUrl: config.path.template('modules.line.add'),
            controller: 'AddLineModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addGalleryModule', {
            templateUrl: config.path.template('modules.gallery.add'),
            controller: 'AddGalleryModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addShareModule', {
            templateUrl: config.path.template('modules.share.add'),
            controller: 'AddShareModuleModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('insertModule', {
            templateUrl: config.path.template('modules.insert_module'),
            controller: 'InsertModuleModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                moduleTypes: ['ModuleService', 'session', function (ModuleService, session) {
                    var currentSite = session.get('data.current_site');

                    var params = {
                        filters: { sites: [currentSite.id] }
                    };

                    return ModuleService.types(params);
                }]
            }
        });

        modalProvider.modal('existingModule', {
            templateUrl: config.path.template('modules.existing_module'),
            controller: 'ExistingModuleModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                moduleTypes: ['ModuleService', function (ModuleService) {
                    return ModuleService.types();
                }]
            }
        });

        modalProvider.modal('selectProject', {
            templateUrl: config.path.template('auth.gateway'),
            controller: 'GatewayController',
            backdrop: 'static',
            keyboard: false
        });
    }
})(angular);
