(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['$compileProvider'];

    function config($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    }
})(angular);
