(function (angular) {
    angular
        .module('one.admin')
        .directive('postForm', postFormDirective);

    postFormDirective.$inject = ['config'];

    function postFormDirective(config) {
        controller.$inject = ['$scope', 'PageService'];

        function controller($scope, PageService) {
            $scope.showPageSelection = !! $scope.post.page;

            $scope.togglePageSelect = togglePageSelect;
            $scope.getPages = getPages;
            $scope.formatPageTitle = PageService.formatPageTitle;

            function getPages(params) {
                params = params || {};
                params.filters = params.filters || {};
                params.filters.sites = [$scope.site.id];

                return PageService.pages(params);
            }

            function togglePageSelect(show) {
                $scope.showPageSelection = show;

                if (!show) {
                    $scope.post.page = null;
                }
            }
        }

        return {
            restrict: 'EA',
            scope: {
                post: '=',
                site: '='
            },
            controller: controller,
            templateUrl: config.path.template('posts.form')
        };
    }
}(angular));
