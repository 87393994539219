(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['httpProvider'];

    function config(httpProvider) {
        httpProvider.setGlobalHeader('X-Requested-With', 'XMLHttpRequest');
    }
})(angular);
