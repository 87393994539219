(function (angular) {
    angular
        .module('one.admin')
        .filter('recurringInterval', recurringIntervalFilter);

    recurringIntervalFilter.$inject = ['$filter'];

    function recurringIntervalFilter($filter) {
        return function (objectWithInterval) {
            var months = objectWithInterval.interval_months;
            var years = objectWithInterval.interval_years;
            var days = objectWithInterval.interval_days;

            return $filter('translate')('recurring_interval', {years: years, months: months, days: days});
        };
    }
})(angular);
