(function (angular) {
    angular
        .module('one.admin')
        .controller('SendOrderMailModalController', SendOrderMailModalController);

    SendOrderMailModalController.$inject = ['$scope', '$uibModalInstance', 'OrderService', 'TransactionalMailService', 'config', 'mails', 'params'];

    function SendOrderMailModalController($scope, $uibModalInstance, OrderService, TransactionalMailService, config, mails, params) {
        angular.merge($scope, params);

        var constraints;

        $scope.batchMode = !!$scope.ordersDataframe;

        if ($scope.batchMode) {
            $scope.batchSize = $scope.ordersDataframe.total;
            $scope.order = $scope.ordersDataframe.rows[0] || null;
            constraints = $scope.ordersDataframe.state;
        } else {
            constraints = { search: { id: $scope.order.id } };
        }

        $scope.previewPath = config.path.api('orders/' + $scope.order.id + '/preview_mail');

        $scope.mails = mails;

        if ($scope.order && $scope.order.form_entry && $scope.order.form_entry.form.confirmation_mail) {
            $scope.mail = $scope.order.form_entry.form.confirmation_mail;
        } else {
            $scope.mail = null;
        }

        $scope.close = $uibModalInstance.close;
        $scope.sendMail = sendMail;
        $scope.formatTransactionalMailTitle = TransactionalMailService.formatTransactionalMailTitle;

        function sendMail(mail) {
            if ($scope.loading) {
                return;
            }

            OrderService.sendMail(mail.id, constraints).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
