(function (angular) {
    angular
        .module('one.admin')
        .controller('FinalizeSddTransactionModalController', FinalizeSddTransactionModalController);

    FinalizeSddTransactionModalController.$inject = ['$scope', '$locale', '$uibModalInstance', 'TransactionService', 'notification', 'translateFilter', 'params'];

    function FinalizeSddTransactionModalController($scope, $locale, $uibModalInstance, TransactionService, notification, translateFilter, params) {
        angular.merge($scope, params);

        $scope.paymentDate = $scope.transaction.payment_date || moment().format($locale.DATETIME_FORMATS.parsedDate);

        $scope.close = $uibModalInstance.close;
        $scope.finalizeSddTransaction = finalizeSddTransaction;

        function finalizeSddTransaction() {
            TransactionService.transaction.applyAction($scope.transaction.id, TransactionService.actions.finalize, {payment_date: $scope.paymentDate}).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
