(function (angular) {
    angular
        .module('one.admin')
        .controller('TwoFactorAuthenticationController', TwoFactorAuthenticationController);

    TwoFactorAuthenticationController.$inject = ['$scope', '$state', 'AuthService', 'user'];

    function TwoFactorAuthenticationController($scope, $state, AuthService, user) {
        $scope.user = user;

        $scope.verifyToken = verifyToken;

        function verifyToken(token) {
            AuthService.verifyTwoFactorAuthenticationToken({token: token}).then(function (data) {
                $state.transitionTo('welcome');
            });
        }
    }
})(angular);
