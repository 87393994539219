(function (angular) {
    angular
        .module('one.admin')
        .controller('AddSegmentController', AddSegmentController);

    AddSegmentController.$inject = ['$scope', '$state', 'SegmentService'];

    function AddSegmentController($scope, $state, SegmentService) {
        $scope.segment = {
            filters: {},
            filtered_models: {}
        };

        $scope.addSegment = addSegment;

        function addSegment(data) {
            SegmentService.addSegment(data).then(function (data) {
                $state.transitionTo('crm.segments.segment', { segmentId: data.segment.id });
            });
        }
    }
})(angular);
