(function (angular) {
    angular
        .module('one.admin')
        .controller('SentMailModalController', SentMailModalController);

    SentMailModalController.$inject = ['$scope', '$uibModalInstance', 'config', 'MailService', 'params'];

    function SentMailModalController($scope, $uibModalInstance, config, MailService, params) {
        angular.extend($scope, params);

        $scope.viewSentMailPath = config.path.api('mail/sent_mails/' + $scope.sentMail.id + '/view');

        $scope.clicks = {};

        angular.forEach($scope.sentMail.clicks, function (click) {
            if (!(click.url in $scope.clicks)) {
                $scope.clicks[click.url] = 0;
            }

            $scope.clicks[click.url]++;
        });

        $scope.close = $uibModalInstance.close;
        $scope.downloadAttachmentPath = downloadAttachmentPath;
        $scope.toggleResend = toggleResend;
        $scope.resend = resend;
        $scope.resending = false;
        $scope.confirming = false;

        function downloadAttachmentPath(attachment) {
            return config.path.api('mail/sent_mail_attachments/' + attachment.id + '/download/' + attachment.file.client_filename);
        }

        function toggleResend() {
            $scope.confirming = !$scope.confirming;
        }

        function resend() {
            $scope.resending = true;

            MailService.resendSentMail($scope.sentMail.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
