(function (angular) {
    angular
        .module('one.admin')
        .controller('DiscountCodeFormController', DiscountCodeFormController);

    DiscountCodeFormController.$inject = ['$scope', 'DiscountCodeService', 'ProductService'];

    function DiscountCodeFormController($scope, DiscountCodeService, ProductService) {
        $scope.types = DiscountCodeService.types;
        $scope.selectedProduct = {};

        setStatus();

        $scope.$watch('status', function (newStatus) {
            if (newStatus == 'enabled' || newStatus == 'scheduled') {
                $scope.discountCode.enabled = true;

                if (newStatus == 'enabled') {
                    $scope.discountCode.start_date = null;
                    $scope.discountCode.end_date = null;
                }
            } else {
                $scope.discountCode.enabled = false;
            }
        });

        $scope.offsetDate = function (attribute, days) {
            $scope.discountCode[attribute] = moment().add(days, 'days').format('YYYY-MM-DD');
        }

        $scope.addProduct = addProduct;
        $scope.removeProduct = removeProduct;
        $scope.formatProductTitle = ProductService.formatProductTitle;

        function setStatus() {
            if (!$scope.discountCode.enabled) {
                $scope.status = 'disabled';
            } else if ($scope.discountCode.enabled && !$scope.discountCode.start_date && !$scope.discountCode.end_date) {
                $scope.status = 'enabled';
            } else {
                $scope.status = 'scheduled';
            }
        }

        function addProduct(product) {
            if (product && !hasProduct(product)) {
                $scope.discountCode.products.push(product);
            }

            $scope.selectedProduct.product = null;
        }

        function removeProduct(index) {
            $scope.discountCode.products.splice(index, 1);
        }

        function hasProduct(product) {
            var result = false;

            angular.forEach($scope.discountCode.products, function (discountCodeProduct) {
                if (product.id == discountCodeProduct.id) {
                    result = true;
                }
            });

            return result;
        }
    }
})(angular);
