(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderGroupFilterBlock', segmentBuilderGroupFilterBlock);

    segmentBuilderGroupFilterBlock.$inject = ['config'];

    function segmentBuilderGroupFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                segment: '='
            },
            controller: ['$scope', 'GroupService', 'segmentBuilder', function ($scope, GroupService, segmentBuilder) {
                $scope.filterables = null;

                $scope.status = { loading: false };

                $scope.selected = {};
                $scope.groups = $scope.segment.filtered_models.group || {};

                $scope.compareValues = compareValues;
                $scope.includeGroup = includeGroup;
                $scope.excludeGroup = excludeGroup;
                $scope.getGroups = getGroups;
                $scope.formatGroupTitle = GroupService.formatGroupTitle;
                $scope.deleteFilterBlock = deleteFilterBlock;

                function compareValues(item, value) {
                    return item.filters[0].value == value;
                }

                function includeGroup(group) {
                    if (group) {
                        $scope.groups[group.id] = group;

                        $scope.segment.filters.group.filters.push(segmentBuilder.hasFilter({
                            relation: 'groups',
                            boolean: 'or',
                            filters: [
                                segmentBuilder.comparisonFilter({
                                    field: 'id',
                                    operator: '=',
                                    value: group.id
                                })
                            ]
                        }));
                    }

                    $scope.selected.includeGroup = null;
                }

                function excludeGroup(group) {
                    if (group) {
                        $scope.groups[group.id] = group;

                        $scope.segment.filters.not_group.filters.push(segmentBuilder.hasFilter({
                            relation: 'groups',
                            boolean: 'or',
                            operator: '=',
                            count: 0,
                            filters: [
                                segmentBuilder.comparisonFilter({
                                    field: 'id',
                                    operator: '=',
                                    value: group.id
                                })
                            ]
                        }));
                    }

                    $scope.selected.excludeGroup = null;
                }

                function getGroups(params) {
                    return segmentBuilder.filterables.group(params).then(function (filterables) {
                        return filterables.groups;
                    });
                }

                /**
                 * Resets the filter block and filterables.
                 */
                function deleteFilterBlock() {
                    $scope.segment.filters.group = null;
                    $scope.segment.filters.not_group = null;
                    $scope.filterables = null;
                }
            }],
            templateUrl: config.path.template('segments.builder.group_filter_block')
        };
    }
}(angular));
