(function (angular) {
    angular
        .module('one.admin')
        .controller('CancelRecurringPurchaseModalController', CancelRecurringPurchaseModalController);

    CancelRecurringPurchaseModalController.$inject = ['$scope', '$uibModalInstance', 'PurchaseService', 'notification', 'moment', 'params'];

    function CancelRecurringPurchaseModalController($scope, $uibModalInstance, PurchaseService, notification, moment, params) {
        $scope.purchase = params.purchase;

        $scope.cancellation = {
            end_date: $scope.purchase.end_date || moment().format('YYYY-MM-DD')
        };

        $scope.close = $uibModalInstance.close;
        $scope.cancelRecurringPurchase = cancelRecurringPurchase;

        function cancelRecurringPurchase() {
            PurchaseService.cancelRecurringPurchase($scope.purchase.id, $scope.cancellation).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
