(function (angular) {
    angular
        .module('one.admin')
        .directive('contentModuleForm', contentModuleFormDirective);

    contentModuleFormDirective.$inject = ['config'];

    function contentModuleFormDirective(config) {
        controller.$inject = ['$scope', 'ModuleService'];

        function controller($scope, ModuleService) {
            $scope.variables = ModuleService.getSiteVariables();
            $scope.site = $scope.module.type.site;
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.content.form')
        };
    }
}(angular));
