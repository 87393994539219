(function (angular) {
    angular
        .module('one.admin', [
            'one.admin.config',
            'one.admin.dependencies',
            'one.admin.http'
        ])
        .config(config);

    config.$inject = ['$stateProvider', '$urlRouterProvider', 'config'];

    function config($stateProvider, $urlRouterProvider, config) {
        $stateProvider.state('main', {
            templateUrl: config.path.template('main'),
            resolve: {
                sites: ['SiteService', 'session', function (SiteService, session) {
                    return SiteService.sites().then(function (sites) {
                        session.set('data.sites', sites);

                        if (sites.length > 0) {
                            var currentSite = sites[0];

                            for (var i = 0; i < sites.length; i++) {
                                if (session.get('data.current_site') && session.get('data.current_site.id') == sites[i].id) {
                                    currentSite = sites[i];
                                    break;
                                }
                            }

                            session.set('data.current_site', currentSite);
                        } else {
                            session.unset('data.current_site');
                        }
                    });
                }]
            }
        });

        $stateProvider.state('welcome', {
            parent: 'main',
            url: '/',
            templateUrl: config.path.template('welcome'),
            controller: 'GlobalDashboardController'
        });

        $stateProvider.state('unauthorised', {
            parent: 'main',
            url: '/unauthorised',
            templateUrl: config.path.template('unauthorised')
        });

        $stateProvider.state('notFound', {
            url: '/not-found',
            templateUrl: config.path.template('not_found')
        });

        $urlRouterProvider.when('', '/');

        $urlRouterProvider.otherwise('/not-found');
    }
})(angular);
