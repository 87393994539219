(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPageNavController', EditPageNavController);

    EditPageNavController.$inject = ['$scope', '$state', 'modal', 'PageService', 'pageNav', 'languages'];

    function EditPageNavController($scope, $state, modal, PageService, pageNav, languages) {
        $scope.pageNav = pageNav;
        $scope.pageNav.links = pageNav.root_links;

        $scope.languages = languages;

        $scope.state = {};

        $scope.editPageNav = editPageNav;
        $scope.openAuditorModal = openAuditorModal;

        function editPageNav(data) {
            angular.forEach(data.links, function (link) {
                if (link.toggleCustomUrl) {
                    link.page = null;
                    return;
                }

                link.custom_url = null;
            });

            PageService.editNav(data).then(function (data) {
                $state.transitionTo('content.pageNavs.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: pageNav.id, modelType: 'page_nav' });
        }
    }
})(angular);
