(function (angular) {
    angular
        .module('one.admin')
        .controller('AddAccountModalController', AddAccountModalController);

    AddAccountModalController.$inject = ['$scope', '$state', '$uibModalInstance', '$timeout', 'notification', 'AccountService', 'DuplicateService', 'genders', 'params'];

    function AddAccountModalController($scope, $state, $uibModalInstance, $timeout, notification, AccountService, DuplicateService, genders, params) {
        var dedupTimer;

        $scope.account = {
            type: {
                name: params.name
            }
        };

        $scope.duplicates = [];
        $scope.genders = genders;

        $scope.$watch('account', function (account) {
            $timeout.cancel(dedupTimer);
            
            dedupTimer = $timeout(function () {
                DuplicateService.findDuplicatesForAccount(account).then(function (result) {
                    $scope.duplicates = result;
                });
            }, 250);
        }, true);

        $scope.addAccount = addAccount;
        $scope.cancel = cancel;

        function addAccount(data) {
            AccountService.addAccount(data).then(function (data) {
                $uibModalInstance.close();
                $state.transitionTo('crm.accounts.account.index', { accountId: data.account.id });
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
