(function (angular) {
    angular
        .module('one.admin')
        .directive('mailSenderEditor', mailSenderEditorDirective);

    mailSenderEditorDirective.$inject = ['config'];

    function mailSenderEditorDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                mail: '='
            },
            controller: ['$scope', 'MailService', function ($scope, MailService) {
                $scope.getSenders = MailService.senders;
                $scope.formatMailSenderTitle = MailService.formatMailSenderTitle;
            }],
            templateUrl: config.path.template('mails.sender.editor')
        };
    }
})(angular);
