(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPaymentReferenceConfigController', EditPaymentReferenceConfigController);

    EditPaymentReferenceConfigController.$inject = ['$scope', '$state', 'PaymentReferenceService', 'products', 'purchaseSources', 'purchaseDestinations', 'paymentReferenceConfig'];

    function EditPaymentReferenceConfigController ($scope, $state, PaymentReferenceService, products, purchaseSources, purchaseDestinations, paymentReferenceConfig) {
        $scope.paymentReferenceConfig = paymentReferenceConfig;

        $scope.products = products;
        $scope.purchaseSources = purchaseSources;
        $scope.purchaseDestinations = purchaseDestinations;

        $scope.edit = edit;

        function edit(paymentReferenceConfig) {
            PaymentReferenceService.editPaymentReferenceConfig(paymentReferenceConfig).then(function (data) {
                $state.transitionTo('finance.paymentReferences.index');
            });
        }
    }
})(angular);
