(function (angular) {
    angular
        .module('one.admin')
        .directive('errorMessages', errorMessagesDirective);

    errorMessagesDirective.$inject = ['config'];

    /**
     * Display a list of errors.
     */
    function errorMessagesDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                errors: '=',
                title: '@'
            },
            templateUrl: config.path.template('common.messages.errors')
        };
    }
}(angular));
