(function (angular) {
    angular
        .module('one.admin')
        .controller('GroupsController', GroupsController);

    GroupsController.$inject = ['$scope', 'AuthService', 'GroupService', 'TagService', 'dataframe', 'modal', 'session'];

    function GroupsController($scope, AuthService, GroupService, TagService, dataframe, modal, session) {
        $scope.groups = dataframe.init({
            externalData: GroupService.groups,
            cols: [
                {
                    name: 'full_title',
                    title: 'Title',
                    display: function (row) {
                        return '<h4><span group-label group="row" hide-tags="true"></span></h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    name: 'child_group_count',
                    title: 'Subgroups',
                    display: function (row) {
                        return '{{ row.child_group_count | number }}';
                    },
                    weight: 1
                },
                {
                    name: 'account_count',
                    title: 'Contacts',
                    display: function (row) {
                        return '{{ row.account_count | number }}';
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('groups.view')) {
                    return 'crm.groups.group({groupId: ' + row.id + '})';
                }
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.groups.editGroup({groupId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('groups.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.groups.loading = true;

                        GroupService.duplicateGroup(row).then(function () {
                            $scope.groups.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('groups.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        GroupService.openDeleteGroupModal(row.id, function () {
                            $scope.groups.getData()
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('groups.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.groups', {
                sort: {
                    full_title: 'asc'
                },
                filters: {
                    root_groups: true,
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        $scope.$watch('groups.state.search', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            $scope.groups.state.filters.root_groups = newValue ? null : true;
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.groups, 'group', session.get('data.tags'));
        }

        session.sync('data.dataframes.groups', $scope.groups.state);

        $scope.openAddGroupModal = openAddGroupModal;

        function openAddGroupModal(size) {
            modal.open('addGroup', size).result.then(function (data) {
                $scope.groups.getData();
            });
        }
    }
})(angular);
