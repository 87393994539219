(function (angular) {
    angular
        .module('one.admin')
        .directive('integerInput', integerInputDirective);

    integerInputDirective.$inject = ['$parse'];

    function integerInputDirective($parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                minValue: '@',
                maxValue: '@',
                required: '@',
                defaultValue: '=',
                focusWhen: '='
            },
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(parseValue);

                ngModel.$validators.integer = function (modelValue, viewValue) {
                    return (!scope.required && modelValue === null) || modelValue === parseInt(modelValue, 10);
                };

                ngModel.$validators.minValue = function (modelValue, viewValue) {
                    return typeof scope.minValue == 'undefined' || scope.minValue === '' || modelValue >= parseInt(scope.minValue, 10);
                };

                ngModel.$validators.maxValue = function (modelValue, viewValue) {
                    return typeof scope.minValue == 'undefined' || scope.maxValue ===  ''|| modelValue <= parseInt(scope.maxValue, 10);
                };

                scope.ngModel = parseValue(scope.ngModel);

                element.on('blur', function () {
                    if (ngModel.$valid) {
                        ngModel.$setViewValue(formatValue(ngModel.$modelValue));
                        ngModel.$render();
                    } else {
                        ngModel.$setViewValue(formatValue(scope.defaultValue));
                        ngModel.$render();
                    }
                });

                scope.$watch('focusWhen', function (newValue, oldValue) {
                    if (newValue) {
                        element[0].focus();
                    }
                }, true);

                function formatValue(value) {
                    return value;
                }

                function parseValue(value) {
                    var result = parseInt('' + value, 10);

                    return isNaN(result) ? null : result;
                }
            }
        };
    }
})(angular);
