(function (angular) {
    angular
        .module('one.admin')
        .directive('paymentReferenceConfigForm', paymentReferenceConfigFormDirective);

    paymentReferenceConfigFormDirective.$inject = ['config'];

    function paymentReferenceConfigFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                paymentReferenceConfig: '=',
                products: '=',
                purchaseSources: '=',
                purchaseDestinations: '='
            },
            controller: 'PaymentReferenceConfigFormController',
            templateUrl: config.path.template('payment_reference_configs.form')
        };
    }
}(angular));
