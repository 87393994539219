(function (angular) {
    angular
        .module('one.admin')
        .directive('transactionLabel', directive);

    directive.$inject = ['config'];

    function directive(config) {
        return {
            restrict: 'EA',
            scope: {
                transaction: '='
            },
            templateUrl: config.path.template('transactions.label')
        };
    }
}(angular));
