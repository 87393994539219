(function (angular) {
    angular
        .module('one.admin')
        .service('ProductService', ProductService);

    ProductService.$inject = ['$filter', 'http', 'modal'];

    function ProductService($filter, http, modal) {
        var paymentTypes = [
            { label: 'One-off', value: 'oneoff' },
            { label: 'Terms', value: 'terms' },
            { label: 'Recurring', value: 'recurring' },
            { label: 'Manual', value: 'manual' }
        ];

        var vatPercentages = [
            { label: '0%', value: 0 },
            { label: '6%', value: 6 },
            { label: '9%', value: 9 },
            { label: '21%', value: 21 }
        ];

        function product(id, params) {
            return http.get('products/' + id, params);
        }

        product.purchases = function (productId, params) {
            return http.get('products/' + productId + '/purchases', params);
        };

        function products(params) {
            return http.get('products', params);
        }

        function giftProducts(params) {
            params = params || {};
            params.filters = params.filters || {};
            params.filters.gift = [true];
            params.filters.allow_custom_amount = [true];

            return products(params);
        }

        function productCategory(id, params) {
            return http.get('products/categories/' + id, params);
        }

        productCategory.purchases = function (productCategoryId, params) {
            return http.get('products/categories/' + productCategoryId + '/purchases', params);
        };

        function productCategories(params) {
            return http.get('products/categories', params);
        }

        function giftProductCategories(params) {
            return http.get('products/gift_categories', params);
        }

        function orders(productId, params) {
            return http.get('products/' + productId + '/orders', params);
        }

        function addProduct(data) {
            return http.post('products/add', data);
        }

        function deleteProduct(id) {
            return http.post('products/' + id + '/delete');
        }

        function editProduct(data) {
            return http.post('products/' + data.id + '/edit', data);
        }

        function duplicateProduct(data) {
            return http.post('products/' + data.id + '/duplicate', data);
        }

        function addProductCategory(data) {
            return http.post('products/categories/add', data);
        }

        function deleteProductCategory(id) {
            return http.post('products/categories/' + id + '/delete');
        }

        function editProductCategory(data) {
            return http.post('products/categories/' + data.id + '/edit', data);
        }

        function duplicateProductCategory(data) {
            return http.post('products/categories/' + data.id + '/duplicate', data);
        }

        function formatProductTitle(product, hideProductCategory) {
            var output = '<i class="md-icon">loyalty</i> ' + product.title;

            if (product.gross_amount) {
                output += ' <span class="text-muted">• ' + $filter('money')(product.gross_amount) + '</span>';
            }

            if (!hideProductCategory && product.category) {
                output += ' <span class="text-muted">• ' + formatProductCategoryTitle(product.category) + '</span>';
            }

            return output;
        }

        function formatProductCategoryTitle(category) {
            var output = '<i class="md-icon">style</i> ' + category.title;

            if (category.tags) {
                output += '<span class="tag-indicators">';

                angular.forEach(category.tags, function (tag) {
                    output += ' <i class="tag-indicator" style="background: ' + tag.color + '"></i>';
                });

                output += '</span>';
            }

            output += '</span>';

            return output
        }

        function openDeleteProductModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this product?'
            }).result.then(function (data) {
                return deleteProduct(id).then(callback);
            });
        }

        function openDeleteProductCategoryModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this product category?'
            }).result.then(function (data) {
                return deleteProductCategory(id).then(callback);
            });
        }

        return {
            paymentTypes: paymentTypes,
            vatPercentages: vatPercentages,
            product: product,
            products: products,
            giftProducts: giftProducts,
            productCategory: productCategory,
            productCategories: productCategories,
            giftProductCategories: giftProductCategories,
            orders: orders,
            addProduct: addProduct,
            deleteProduct: deleteProduct,
            duplicateProduct: duplicateProduct,
            editProduct: editProduct,
            addProductCategory: addProductCategory,
            deleteProductCategory: deleteProductCategory,
            editProductCategory: editProductCategory,
            duplicateProductCategory: duplicateProductCategory,
            formatProductTitle: formatProductTitle,
            formatProductCategoryTitle: formatProductCategoryTitle,
            openDeleteProductModal: openDeleteProductModal,
            openDeleteProductCategoryModal: openDeleteProductCategoryModal
        };
    }
})(angular);
