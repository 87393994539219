(function (angular) {
    angular
        .module('one.admin')
        .directive('optionsDropdown', optionsDropdownDirective);

    optionsDropdownDirective.$inject = [];

    function optionsDropdownDirective() {
        return {
            restrict: 'EA',
            transclude: true,
            replace: true,
            scope: {
                dropdownMenuRight: '='
            },
            templateUrl: 'one.ui.options-dropdown'
        };
    }
}(angular));
