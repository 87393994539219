(function (angular) {
    angular
        .module('one.admin')
        .controller('PdfTemplatesController', PdfTemplatesController);

    PdfTemplatesController.$inject = ['$scope', 'AuthService', 'PdfTemplateService', 'TagService', 'dataframe', 'modal', 'notification', 'session'];

    function PdfTemplatesController($scope, AuthService, PdfTemplateService, TagService, dataframe, modal, notification, session) {
        $scope.pdfTemplates = dataframe.init({
            externalData: PdfTemplateService.pdfTemplates,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">code</i> {{ row.title }} <span tag-indicator tags="row.tags"></span></h4>';
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('pdf_templates.edit')) {
                    return 'content.pdfTemplates.editPdfTemplate({pdfTemplateId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'content.pdfTemplates.editPdfTemplate({pdfTemplateId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('pdf_templates.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.pdfTemplates.loading = true;

                        PdfTemplateService.duplicatePdfTemplate(row).then(function () {
                            $scope.pdfTemplates.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('pdf_templates.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeletePdfTemplateModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('pdf_templates.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.pdfTemplates', { sort: { title: 'asc' } })
        });

        session.sync('data.dataframes.pdfTemplates', $scope.pdfTemplates.state);

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.pdfTemplates, 'pdf_template', session.get('data.tags'));
        }

        $scope.openDeletePdfTemplateModal = openDeletePdfTemplateModal;

        function openDeletePdfTemplateModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this PDF template?' }).result.then(function (data) {
                deletePdfTemplate(id);
            });
        }

        function deletePdfTemplate(id) {
            PdfTemplateService.deletePdfTemplate(id).then(function (data) {
                $scope.pdfTemplates.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }
    }
})(angular);
