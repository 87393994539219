(function (angular) {
    angular
        .module('one.admin')
        .filter('absolute', absoluteFilter);

    absoluteFilter.$inject = [];

    function absoluteFilter() {
        return function (value) {
            if (value !== void 0) {
                return Math.abs(value);
            }
        };
    }
})(angular);
