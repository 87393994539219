(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['config', 'httpProvider'];

    function config(config, httpProvider) {
        var templatePathPrefix = 'one';

        config.path = {
            api: function (path) {
                return '../../api/public/' + (path || '');
            },
            template: function (path) {
                path = (templatePathPrefix ? templatePathPrefix + '.' : '') + (path || '');
                path = path.replace(/-/g, '_');
                path = path.replace(/\//g, '.');
                return path;
            }
        };

        httpProvider.setPath(config.path.api());
    }
})(angular);
