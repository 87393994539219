(function (angular) {
    angular
        .module('one.admin')
        .controller('TransactionalMailController', TransactionalMailController);

    TransactionalMailController.$inject = ['$scope', '$state', 'TransactionalMailService', 'config', 'dataframe', 'modal', 'formatDateFilter', 'transactionalMail'];

    function TransactionalMailController($scope, $state, TransactionalMailService, config, dataframe, modal, formatDateFilter, transactionalMail) {
        $scope.previewPath = config.path.api('transactional_mails/' + transactionalMail.id + '/preview');

        $scope.transactionalMail = transactionalMail;

        var clickCounts = [];

        angular.forEach($scope.transactionalMail.click_counts, function (counts, url) {
            clickCounts.push(angular.merge(counts, {
                url: url
            }));
        });

        $scope.clickCounts = dataframe.init({
            data: clickCounts,
            cols: [
                {
                    title: 'URL',
                    name: 'url',
                    display: function (row) {
                        return '<a ng-href="{{ row.url }}" target="_blank" rel="noopener">{{ row.url }}</a>';
                    },
                    weight: 2
                },
                {
                    title: 'Clicks (unique)',
                    name: 'row.all',
                    display: function (row) {
                        row.openClicksModal = function () {
                            openTransactionalMailClicksModal(row.url);
                        };

                        return '<a ng-click="row.openClicksModal()">{{ row.all }} ({{ row.unique }})</a>';
                    },
                    weight: 1
                }
            ],
            state: {
                perPage: 5
            }
        });

        TransactionalMailService.history($scope.transactionalMail.id).then(function (history) {
            $scope.history = dataframe.init({
                data: history,
                cols: [
                    {
                        title: 'Date',
                        name: 'created_at',
                        display: function (row) {
                            return formatDateFilter(row.created_at);
                        },
                        weight: 1
                    },
                    {
                        title: 'Deliveries',
                        name: 'delivery_count',
                        weight: 1
                    },
                    {
                        title: 'Failures',
                        name: 'failed_count',
                        weight: 1
                    },
                    {
                        title: 'Opens',
                        name: 'open_count',
                        weight: 1
                    },
                    {
                        title: 'Delivery rate',
                        name: 'delivery_count',
                        display: function (row) {
                            var rate = Math.round(row.delivery_rate * 1000) / 10;

                            if (rate < 100) {
                                rate = rate.toFixed(1);
                            }

                            return rate + '%';
                        },
                        weight: 1
                    },
                    {
                        title: 'Open rate',
                        name: 'open_count',
                        display: function (row) {
                            var rate = Math.round(row.open_rate * 1000) / 10;

                            if (rate < 100) {
                                rate = rate.toFixed(1);
                            }

                            return rate + '%';
                        },
                        weight: 1
                    }
                ],
                state: {
                    perPage: 5
                }
            });
        });

        $scope.recached = false;

        TransactionalMailService.transactionalMail(transactionalMail.id, { recache: true }).then(function (transactionalMail) {
            $scope.transactionalMail = transactionalMail;
            $scope.recached = true;
        });

        $scope.openTransactionalMailClicksModal = openTransactionalMailClicksModal;
        $scope.openTransactionalMailDeliveriesModal = openTransactionalMailDeliveriesModal;
        $scope.openTransactionalMailPendingModal = openTransactionalMailPendingModal;
        $scope.openTransactionalMailFailuresModal = openTransactionalMailFailuresModal;
        $scope.openTransactionalMailOpensModal = openTransactionalMailOpensModal;
        $scope.openDeleteTransactionalMailModal = openDeleteTransactionalMailModal;
        $scope.openAuditorModal = openAuditorModal;

        function openTransactionalMailClicksModal(url) {
            modal.open('transactionalMailClicks', 'lg', { transactionalMail: $scope.transactionalMail, url: url });
        }

        function openTransactionalMailDeliveriesModal() {
            modal.open('transactionalMailDeliveries', 'lg', { transactionalMail: $scope.transactionalMail });
        }

        function openTransactionalMailPendingModal() {
            modal.open('transactionalMailPending', 'lg', { transactionalMail: $scope.transactionalMail });
        }

        function openTransactionalMailFailuresModal() {
            modal.open('transactionalMailFailures', 'lg', { transactionalMail: $scope.transactionalMail });
        }

        function openTransactionalMailOpensModal() {
            modal.open('transactionalMailOpens', 'lg', { transactionalMail: $scope.transactionalMail });
        }

        function openDeleteTransactionalMailModal() {
            TransactionalMailService.openDeleteTransactionalMailModal($scope.transactionalMail.id, function () {
                $state.transitionTo('mail.transactionalMails.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: transactionalMail.id, modelType: 'transactional_mail' });
        }
    }
})(angular);
