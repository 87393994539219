(function (angular) {
    angular
        .module('one.admin')
        .directive('htmlModuleForm', htmlModuleFormDirective);

    htmlModuleFormDirective.$inject = ['config'];

    function htmlModuleFormDirective(config) {
        controller.$inject = ['$scope', 'ModuleService', '$timeout', 'HtmlService', 'string'];

        function controller($scope, ModuleService, $timeout, HtmlService, string) {
            var getPlaceholdersTimer;

            $scope.hasPlaceholders = false;
            $scope.placeholderMap = {};
            $scope.files = {};
            $scope.forms = {};
            $scope.tab = { enabled: false, active: 'html' };

            $scope.variables = ModuleService.getSiteVariables();
            $scope.placeholderTypes = HtmlService.getPlaceholderTypes();
            $scope.placeholderIcons = HtmlService.getPlaceholderIcons();

            $scope.setPlaceholderImage = setPlaceholderImage;
            $scope.getPlaceholders = getPlaceholders;

            // Initialize: If placeholders can be found, enable the tab, and activate first form.
            if ($scope.module.module_instance.placeholders.length > 0) {
                $scope.tab = { enabled: true, active: 'content' };
                $scope.hasPlaceholders = true;
                $scope.forms.showPlaceholderForm = $scope.module.module_instance.placeholders[0].name;
            }

            // Map each placeholder
            for (var i = 0; i < $scope.module.module_instance.placeholders.length; i++) {
                var placeholder = $scope.module.module_instance.placeholders[i];

                $scope.placeholderMap[placeholder.name] = placeholder;

                if (placeholder.config.url) {
                    $scope.files[placeholder.name] = { url: placeholder.config.url };
                } else {
                    $scope.files[placeholder.name] = null;
                }
            }

            function setPlaceholderImage(placeholder) {
                var image = $scope.files[placeholder.name] || {};

                placeholder.config = angular.merge(placeholder.config, {
                    url: image.url
                });
            }

            // Watch loop
            function getPlaceholders() {
                $timeout.cancel(getPlaceholdersTimer);

                getPlaceholdersTimer = $timeout(function () {
                    var placeholderNames = HtmlService.matchPlaceholders($scope.module.module_instance.content);
                    var placeholders = [];

                    // If placeholders have been placed, but the tabs are not enabled, enable it
                    if (placeholderNames.length > 0 && !$scope.tab.enabled) {
                        $scope.tab = { enabled: true, active: 'html' };
                        $scope.hasPlaceholders = true;
                    }

                    // If tabs are enabled, but no placeholders can be found, disable it
                    if (placeholderNames.length === 0 && $scope.tab.enabled) {
                        $scope.tab = { enabled: false, active: 'html' };
                        $scope.hasPlaceholders = false;
                    }

                    angular.forEach(placeholderNames, function (name) {
                        if (!$scope.placeholderMap[name]) {
                            $scope.placeholderMap[name] = {
                                name: name,
                                type: $scope.placeholderTypes[0],
                                title: string.snakeToTitle(name),
                                config: {}
                            };
                        }

                        if (angular.isArray($scope.placeholderMap[name].config)) {
                            $scope.placeholderMap[name].config = {};
                        }

                        placeholders.push($scope.placeholderMap[name]);
                    });

                    $scope.module.module_instance.placeholders = placeholders;
                }, 500);
            }

            // Activate watch loop
            $scope.getPlaceholders();
        }

        return {
            restrict: 'EA',
            scope: {
                module: '=',
                showPlaceholders: '=',
            },
            controller: controller,
            templateUrl: config.path.template('modules.html.form')
        };
    }
}(angular));
