(function (angular) {
    angular.module('one.admin').service('notification', ['$timeout', function ($timeout) {
        var notifications = [];
        var queue = [];
        var maxQueueLength = 1;
        var timeout;

        var all = function () {
            return notifications;
        };

        var addNotification = function (notification) {
            if (notifications.length < maxQueueLength) {
                notifications.push(notification);

                $timeout.cancel(timeout);

                // Shift notifications after a while
                timeout = $timeout(function () {
                    notifications.shift();

                    // If there are qeueud notifications, shift the first to notifications
                    if (queue.length > 0) {
                        addNotification(queue.shift());
                    }
                }, notification.timeout);
            } else {
                // Too many notificiations, queue it
                queue.push(notification);
            }
        };

        var add = function (message, type, timeout) {
            notifications.shift();

            var notification = {
                message: message,
                type: type || 'info',
                timeout: timeout || 2000
            };

            addNotification(notification);
        };

        var success = function (message, timeout) {
            return add(message, 'success', timeout);
        };

        var info = function (message, timeout) {
            return add(message, 'info', timeout);
        };

        var error = function (message, timeout) {
            return add(message, 'danger', timeout);
        };

        return {
            all: all,
            error: error,
            info: info,
            success: success
        };
    }]);
})(angular);
