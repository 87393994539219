(function (angular) {
    angular
        .module('one.admin')
        .controller('SendFormEntryConfirmationMailModalController', SendFormEntryConfirmationMailModalController);

    SendFormEntryConfirmationMailModalController.$inject = ['$scope', '$uibModalInstance', 'FormEntryService', 'config', 'params'];

    function SendFormEntryConfirmationMailModalController($scope, $uibModalInstance, FormEntryService, config, params) {
        angular.extend($scope, params);

        $scope.recipients = null;

        FormEntryService.confirmationMailRecipients($scope.formEntry.id).then(function (recipients) {
            $scope.recipients = recipients;
        });

        $scope.previewPath = config.path.api('forms/entries/' + $scope.formEntry.id + '/preview_confirmation_mail');

        $scope.send = send;
        $scope.cancel = cancel;

        function send() {
            FormEntryService.sendConfirmationMail($scope.formEntry.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
