(function (angular) {
    angular
        .module('one.admin')
        .controller('SendMailingModalController', SendMailingModalController);

    SendMailingModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'MailingService', 'params'];

    function SendMailingModalController($scope, $uibModalInstance, AccountService, MailingService, params) {
        angular.extend($scope, params);

        $scope.formState = {
            recipientsType: params.mailing.recipient_count > 0 ? 'all' : 'single',
            account: null,
        };

        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.send = send;
        $scope.cancel = cancel;

        $scope.isValid = function () {
            return $scope.formState.recipientsType === 'all'
                || ($scope.formState.recipientsType === 'single' && $scope.formState.account);
        };

        function send() {
            if ($scope.loading) {
                return;
            }

            MailingService.sendMailing({
                mailing: { id: $scope.mailing.id },
                account: $scope.formState.recipientsType === 'single' ? $scope.formState.account : null
            }).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
