(function (angular) {
    angular
        .module('one.admin')
        .directive('brandField', brandFieldDirective);

    brandFieldDirective.$inject = ['config'];

    function brandFieldDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.brands = $scope.module.type.site.config_keys.brands;

            if (!$scope.module.module_instance.brand) {
                $scope.module.module_instance.brand = $scope.module.type.site.config_keys.brands[0];
            }

            if ($scope.disableTransparent) {
                $scope.brands = $scope.brands.filter(function (brand) { return brand !== 'transparent'; });

                if ($scope.module.module_instance.brand == 'transparent') {
                    $scope.module.module_instance.brand = $scope.module.type.site.config_keys.brands[0];
                }
            }
        }

        return {
            restrict: 'EA',
            scope: {
                module: '=',
                disableTransparent: '='
            },
            templateUrl: config.path.template('modules.brand_field'),
            controller: controller
        };
    }
}(angular));
