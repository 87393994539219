(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderActionCampaignFilterBlock', segmentBuilderActionCampaignFilterBlock);

    segmentBuilderActionCampaignFilterBlock.$inject = ['config'];

    function segmentBuilderActionCampaignFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                filterBlock: '=',
                segment: '='
            },
            controller: ['$scope', '$q', 'segmentBuilder', function ($scope, $q, segmentBuilder) {
                $scope.actionCampaignFilterables = null;

                $scope.status = { loading: true };

                // The main filter block is a has filter, and its first subfilter is for the action campaign
                $scope.actionCampaignFilterBlock = $scope.filterBlock.filters[0];

                getFilterables();

                $scope.deleteFilterBlock = deleteFilterBlock;
                $scope.addAllFilter = addAllFilter;
                $scope.addAnyFilter = addAnyFilter;

                /**
                 * Resets the filter block and filterables.
                 */
                function deleteFilterBlock() {
                    $scope.filterBlock = null;
                    $scope.actionCampaignFilterables = null;
                }

                /**
                 * Adds a comparison filter to the filter block.
                 */
                function addAllFilter() {
                    $scope.actionCampaignFilterBlock.filters[0].filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Adds a comparison filter to the filter block.
                 */
                function addAnyFilter() {
                    $scope.actionCampaignFilterBlock.filters[1].filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Fetches the fields that can be filtered on.
                 */
                function getFilterables() {
                    segmentBuilder.filterables.actionCampaign().then(function (data) {
                        $scope.actionCampaignFilterables = data;
                        $scope.status.loading = false;
                    })
                }
            }],
            templateUrl: config.path.template('segments.builder.action_campaign_filter_block')
        };
    }
}(angular));
