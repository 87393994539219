(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeFiltersReset', oneDataframeFiltersResetDirective);

    oneDataframeFiltersResetDirective.$inject = ['config',];

    function oneDataframeFiltersResetDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.hasFilters = hasFilters;

            function hasFilters() {
                var hasFilters = false;

                var ignoredKeys = ['tags', 'search'];

                angular.forEach($scope.dataframe.state.filters, function (values, key) {
                    if (ignoredKeys.indexOf(key) == -1 && values && values.length > 0) {
                        hasFilters = true;
                    }
                });

                return hasFilters;
            }
        }

        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-filters-reset');
            },
            controller: controller,
            templateUrl: config.path.template('common.dataframe.filters_reset')
        };
    }
})(angular);
