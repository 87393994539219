(function (angular) {
    angular
        .module('one.admin')
        .controller('ConfirmModalController', ConfirmModalController);

    ConfirmModalController.$inject = ['$scope', '$uibModalInstance', 'params'];

    function ConfirmModalController($scope, $uibModalInstance, params) {
        angular.extend($scope, params);

        $scope.confirm = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
