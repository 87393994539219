(function (angular) {
    angular
        .module('one.admin')
        .service('TagService', TagService);

    TagService.$inject = ['$filter', 'AuthService', 'http', 'modal'];

    function TagService($filter, AuthService, http, modal) {
        var maxTags = 10;

        function tag(id, params) {
            return http.get('tags/' + id, params);
        }

        function tags(params) {
            return http.get('tags', params);
        }

        function colors(params) {
            return http.get('tags/colors', params);
        }

        function toggleTag(tag, modelType, modelId) {
            return http.post('tags/' + tag.id + '/toggle', {
                model_type: modelType,
                model_id: modelId
            });
        }

        function toggleTagOptimistically(tag, modelType, model) {
            var index = -1;

            for (var i = 0; i < model.tags.length; i++) {
                if (model.tags[i].id == tag.id) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                // Remove tag
                model.tags.splice(index, 1);
            } else {
                // Add tag
                if (model.tags.length == 0) {
                    model.tags.push(tag);
                } else if (model.tags[0].position > tag.position) {
                    model.tags.unshift(tag);
                } else {
                    for (var i = 0; i < model.tags.length; i++) {
                        if (tag.position > model.tags[i].position) {
                            model.tags.splice(i, 0, tag);
                            break;
                        }
                    }
                }
            }

            return toggleTag(tag, modelType, model.id);
        }

        function addTag(data) {
            return http.post('tags/add', data);
        }

        function deleteTag(id) {
            return http.post('tags/' + id + '/delete');
        }

        function editTag(data) {
            return http.post('tags/' + data.id + '/edit', data);
        }

        function insertDataframeTags(dataframe, modelType) {
            var index = 0;

            while (index < dataframe.rowOptions.length) {
                var option = dataframe.rowOptions[index];

                if (option.tagOption) {
                    dataframe.rowOptions.splice(index, 1);
                } else {
                    index++;
                }
            }

            tags().then(function (tags) {
                dataframe.rowOptions.push({
                    heading: function (row) {
                        return $filter('translate')('general.tags');
                    },
                    tagOption: true
                });

                angular.forEach(tags, function (tag) {
                    dataframe.rowOptions.push({
                        title: function (row) {
                            return tagIndicator(tag, !containsTag(row.tags, tag)) + ' ' + tag.title;
                        },
                        click: function (row) {
                            dataframe.loading = true;

                            toggleTagOptimistically(tag, modelType, row).then(function (model) {
                                dataframe.getData();
                            });
                        },
                        tagOption: true
                    });
                });

                if (tags.length < maxTags) {
                    dataframe.rowOptions.push({
                        icon: 'add',
                        title: function (row) {
                            return $filter('translate')('general.add_tag');
                        },
                        click: function (row) {
                            modal.open('addTag', null, { models: [{ type: modelType, id: row.id }] }).result.then(function (data) {
                                insertDataframeTags(dataframe, modelType)

                                dataframe.getData(true);
                            });
                        },
                        visible: function (row) {
                            return AuthService.userCanAccess('tags.add');
                        },
                        tagOption: true
                    });
                }
            });
        }

        function containsTag(tags, tag) {
            for (var i = 0; i < tags.length; i++) {
                if (tags[i].id == tag.id) {
                    return true;
                }
            }

            return false;
        }

        function tagIndicator(tag, blank) {
            if (blank) {
                return '<span class="tag-indicators"><i class="tag-indicator" style="border-color: ' + tag.color + ';"></i></span>';
            }

            return '<span class="tag-indicators"><i class="tag-indicator" style="background: ' + tag.color + '; border-color: ' + tag.color + ';"></i></span>';
        }

        function formatTags(tags) {
            var output = '<span class="tag-indicators">';

            angular.forEach(tags, function (tag) {
                output += '<i class="tag-indicator" style="background: ' + tag.color + '; border-color: ' + tag.color + ';"></i>';
            });

            output += '</span>';

            return output;
        }

        return {
            tag: tag,
            tags: tags,
            colors: colors,
            toggleTag: toggleTag,
            toggleTagOptimistically: toggleTagOptimistically,
            addTag: addTag,
            deleteTag: deleteTag,
            editTag: editTag,
            insertDataframeTags: insertDataframeTags,
            containsTag: containsTag,
            maxTags: maxTags,
            formatTags: formatTags
        };
    }
})(angular);
