(function (angular) {
    angular
        .module('one.admin')
        .directive('addRelationToAccountForm', addRelationToAccountFormDirective);

    addRelationToAccountFormDirective.$inject = ['config'];

    function addRelationToAccountFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                relation: '=',
                accountTypes: '='
            },
            controller: ['$scope', 'AccountService', function ($scope, AccountService) {
                $scope.state = {};

                $scope.getAccounts = AccountService.accounts;
                $scope.formatAccountTitle = AccountService.formatAccountTitle;
            }],
            templateUrl: config.path.template('accounts.account.relations.add_relation_form')
        };
    }
}(angular));
