(function (angular) {
    angular
        .module('one.admin')
        .directive('inputFocusWhen', inputFocusWhenDirective);

    inputFocusWhenDirective.$inject = [];

    function inputFocusWhenDirective() {
        return {
            restrict: 'AE',
            scope: {
                inputFocusWhen: '='
            },
            link: function (scope, element, attributes) {
                scope.$watch('inputFocusWhen', function (newValue, oldValue) {
                    if (newValue) {
                        element[0].focus();
                    }
                }, true);
            }
        };
    }
})(angular);
