(function (angular) {
    angular
        .module('one.admin')
        .service('MailingService', MailingService);

    MailingService.$inject = ['http', 'modal'];

    function MailingService(http, modal) {
        function mailing(id, params) {
            return http.get('mailings/' + id, params, null, { ignoreLoadingBar: params && params.recache });
        }

        function mailings(params) {
            return http.get('mailings', params);
        }

        function permissions(params) {
            return http.get('mailings/permissions', params);
        }

        function batches(id, params) {
            return http.get('mailings/' + id + '/batches', params);
        }

        function clicks(id, params) {
            return http.get('mailings/' + id + '/clicks', params);
        }

        function recipients(id, params) {
            return http.get('mailings/' + id + '/recipients', params);
        }

        function deliveries(id, params) {
            return http.get('mailings/' + id + '/deliveries', params);
        }

        function pending(id, params) {
            return http.get('mailings/' + id + '/pending', params);
        }

        function failures(id, params) {
            return http.get('mailings/' + id + '/failures', params);
        }

        function opens(id, params) {
            return http.get('mailings/' + id + '/opens', params);
        }

        function addMailing(data) {
            return http.post('mailings/add', data);
        }

        function deleteMailing(id) {
            return http.post('mailings/' + id + '/delete');
        }

        function editMailing(data) {
            return http.post('mailings/' + data.id + '/edit', data);
        }

        function createSegment(id) {
            return http.post('mailings/' + id + '/create_segment');
        }

        function duplicateMailing(data) {
            return http.post('mailings/' + data.id + '/duplicate', data);
        }

        function scheduleMailing(data) {
            return http.post('mailings/' + data.id + '/schedule', data);
        }

        function sendMailing(data) {
            return http.post('mailings/' + data.mailing.id + '/send', data);
        }

        function sendTestMail(mailingId, accountId) {
            return http.post('mailings/' + mailingId + '/send_test/' + accountId);
        }

        function getVariables() {
            return [
                'account.first_name',
                'account.full_name',
                'sender.signature',
                'unsubscribe_url'
            ];
        }

        function openDeleteMailingModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this mailing?'
            }).result.then(function (data) {
                return deleteMailing(id).then(callback);
            });
        }

        function formatMailingTitle(mailing) {
            var icon = mailing.draft ? 'drafts' : 'mail';

            return '<i class="md-icon">' + icon + '</i> ' + mailing.title;
        }

        return {
            mailing: mailing,
            mailings: mailings,
            permissions: permissions,
            batches: batches,
            recipients: recipients,
            clicks: clicks,
            deliveries: deliveries,
            pending: pending,
            failures: failures,
            opens: opens,
            addMailing: addMailing,
            deleteMailing: deleteMailing,
            duplicateMailing: duplicateMailing,
            editMailing: editMailing,
            createSegment: createSegment,
            scheduleMailing: scheduleMailing,
            sendMailing: sendMailing,
            sendTestMail: sendTestMail,
            getVariables: getVariables,
            openDeleteMailingModal: openDeleteMailingModal,
            formatMailingTitle: formatMailingTitle
        };
    }
})(angular);
