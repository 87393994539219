(function (angular) {
    angular
        .module('one.admin')
        .controller('ActionCampaignController', ActionCampaignController);

    ActionCampaignController.$inject = ['$scope', '$state', 'ActionCampaignService', 'PostService', 'GiftService', 'modal', 'actionCampaign'];

    function ActionCampaignController($scope, $state, ActionCampaignService, PostService, GiftService, modal, actionCampaign) {
        $scope.actionProject = actionCampaign.action_project;
        $scope.actionCampaign = actionCampaign;

        $scope.getChildCampaigns = getChildCampaigns;
        $scope.getPosts = getPosts;
        $scope.getGiftRewards = getGiftRewards;
        $scope.getGifts = getGifts;
        $scope.openAuditorModal = openAuditorModal;
        $scope.openAddGiftRewardModal = openAddGiftRewardModal;
        $scope.openDeactivateActionCampaignModal = openDeactivateActionCampaignModal;
        $scope.openReactivateActionCampaignModal = openReactivateActionCampaignModal;
        $scope.openMergeActionCampaignsModal = openMergeActionCampaignsModal;
        $scope.openDeleteActionCampaignModal = openDeleteActionCampaignModal;

        function getChildCampaigns(params) {
            params.filters = angular.merge({}, params.filters, { parent_campaigns: [$scope.actionCampaign.id] });

            return ActionCampaignService.actionCampaigns(params);
        }

        function getPosts(params) {
            params.filters = angular.merge({}, params.filters, { action_campaigns: [$scope.actionCampaign.id] });

            return PostService.posts(params);
        }

        function getGiftRewards(params) {
            params.filters = angular.merge({}, params.filters, { action_campaigns: [$scope.actionCampaign.id] });

            return GiftService.giftRewards(params);
        }

        function getGifts(params) {
            params.filters = angular.merge({}, params.filters, { action_campaigns: [$scope.actionCampaign.id] });

            return GiftService.gifts(params);
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: actionCampaign.id, modelType: 'action_campaign' });
        }

        function openAddGiftRewardModal(size) {
            modal.open('addGiftReward', size, { giftReward: { action_campaign: { id: actionCampaign.id } } }).result.then(function (data) {
                $state.reload();
            });
        }

        function openDeactivateActionCampaignModal() {
            ActionCampaignService.openDeactivateActionCampaignModal($scope.actionCampaign.id, function () {
                $state.reload($state.current);
            });
        }

        function openReactivateActionCampaignModal() {
            ActionCampaignService.openReactivateActionCampaignModal($scope.actionCampaign.id, function () {
                $state.reload($state.current);
            });
        }

        function openMergeActionCampaignsModal() {
            modal.open('mergeActionCampaigns', 'lg', { merge: { master: $scope.actionCampaign } }).result.then(function (data) {
                if (data) {
                    $state.reload($state.current);
                }
            });
        }

        function openDeleteActionCampaignModal() {
            return ActionCampaignService.openDeleteActionCampaignModal($scope.actionCampaign.id, function () {
                $state.go('crowdfunding.actionProjects.actionProject', { actionProjectId: $scope.actionProject.id });
            });
        }
    }
})(angular);
