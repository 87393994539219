(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$locale'];

    function run($locale) {
        $locale.DATETIME_FORMATS.short = 'd MMM yyyy H:mm';
        $locale.DATETIME_FORMATS.mediumDate = 'd MMM yyyy';
        $locale.DATETIME_FORMATS.medium = 'd MMM yyyy HH:mm:ss';
        $locale.DATETIME_FORMATS.longDate = 'EEE d MMM yyyy';
        $locale.DATETIME_FORMATS.parsedDate = 'YYYY-MM-DD';
        $locale.DATETIME_FORMATS.userInputDate = 'DD-MM-YYYY';
        $locale.DATETIME_FORMATS.parsedDateTime = 'YYYY-MM-DD HH:mm:ss';
        $locale.DATETIME_FORMATS.userInputDateTime = 'DD-MM-YYYY HH:mm:ss';
        $locale.DATETIME_FORMATS.parsedTime = 'HH:mm:ss';
        $locale.DATETIME_FORMATS.userInputTime = 'HH:mm';
        $locale.DATETIME_FORMATS.shortTime = 'HH:mm';
        $locale.DATETIME_FORMATS.time = 'HH:mm:ss';
        $locale.DATETIME_FORMATS.yearMonth = 'MMM yyyy';
        $locale.DATETIME_FORMATS.withoutYear = 'DD-MM';
    }
})(angular);
