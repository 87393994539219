(function (angular) {
    angular
        .module('one.admin')
        .controller('MailingRecipientsModalController', MailingRecipientsModalController);

    MailingRecipientsModalController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$state', 'dataframe', 'MailingService', 'params'];

    function MailingRecipientsModalController($scope, $rootScope, $uibModalInstance, $state, dataframe, MailingService, params) {
        angular.extend($scope, params);

        $scope.recipients = dataframe.init({
            externalData: function (params) {
                return MailingService.recipients($scope.mailing.id, params);
            },
            cols: [
                {
                    name: 'title',
                    title: 'Name',
                    display: function (row) {
                        var icon = 'person';

                        if (row.account_type_name == 'organisation') {
                            icon = 'business';
                        };

                        return '<i class="md-icon">' + icon + '</i> <strong>{{ row.title }}</strong>';
                    },
                    weight: 1
                },
                {
                    name: 'email',
                    title: 'E-mail',
                    weight: 1
                }
            ],
            perPage: 5,
            rowSref: function (row) {
                return 'crm.accounts.account.index({accountId: ' + row.id + '})';
            }
        });

        $scope.close = $uibModalInstance.close;

        $rootScope.$on('$stateChangeStart', function () {
            $uibModalInstance.close();
        });
    }
})(angular);
