(function (angular) {
    angular
        .module('one.admin')
        .directive('tagIndicator', tagIndicatorDirective);

    tagIndicatorDirective.$inject = [];

    function tagIndicatorDirective() {
        return {
            restrict: 'AE',
            scope: {
                tags: '=',
                hideTooltip: '=',
                blank: '='
            },
            controller: ['$scope', 'translateFilter', function ($scope, translateFilter) {
                var titles = [];

                angular.forEach($scope.tags, function (tag) {
                    titles.push(tag.title);
                });

                $scope.tooltip = titles.join(' / ');
            }],
            templateUrl: 'one.common.tags.tag_indicator'
        };
    }
})(angular);
