(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPurchaseController', EditPurchaseController);

    EditPurchaseController.$inject = ['$scope', '$state', 'AccountService', 'AuthService', 'DiscountCodeService', 'PurchaseService', 'ProductService', 'translateFilter', 'notification', 'purchase', 'purchaseSources', 'purchaseDestinations'];

    function EditPurchaseController($scope, $state, AccountService, AuthService, DiscountCodeService, PurchaseService, ProductService, translateFilter, notification, purchase, purchaseSources, purchaseDestinations) {
        $scope.toggles = {
            showPurchaseSourceAndDestination: (purchaseSources.length + purchaseDestinations.length) > 0
        };

        angular.forEach(purchaseSources, function (purchaseSource) {
            purchaseSource.label = purchaseSource.title;
        });

        angular.forEach(purchaseDestinations, function (purchaseDestination) {
            purchaseDestination.label = purchaseDestination.title;
        });

        if (AuthService.userCanAccess('purchases.add_purchase_source')) {
            purchaseSources.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        if (AuthService.userCanAccess('purchases.add_purchase_destination')) {
            purchaseDestinations.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        if (purchase.purchase_source) {
            purchase.purchase_source.label = purchase.purchase_source.title;
        }

        if (purchase.purchase_destination) {
            purchase.purchase_destination.label = purchase.purchase_destination.title;
        }

        $scope.purchase = purchase;
        $scope.purchaseSources = purchaseSources;
        $scope.purchaseDestinations = purchaseDestinations;
        $scope.vatPercentages = ProductService.vatPercentages;
        $scope.edit = edit;
        $scope.applyDiscountCode = applyDiscountCode;
        $scope.updateDiscountedAmount = updateDiscountedAmount;

        $scope.addTerm = addTerm;
        $scope.deleteTerm = deleteTerm;
        $scope.updateAmounts = updateAmounts;
        $scope.updateAllAmounts = updateAllAmounts;
        $scope.updateBaseAmounts = updateBaseAmounts;
        $scope.getAccounts = AccountService.accounts;
        $scope.getDiscountCodes = DiscountCodeService.discountCodes;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.getProducts = getProducts;
        $scope.formatProductTitle = ProductService.formatProductTitle;
        $scope.setPaymentTermTitles = setPaymentTermTitles;

        function applyDiscountCode(discountCode) {
            var empty = true;
            var paymentTermBaseAmounts = {};

            angular.forEach(purchase.payment_terms, function (paymentTerm) {
                if (paymentTerm.transaction && paymentTerm.transaction.status != 'in_progress') {
                    paymentTermBaseAmounts[paymentTerm.id] = $scope.purchase.vat_inclusive ? paymentTerm.gross_base_amount : paymentTerm.base_amount;
                    empty = false;
                }
            });

            if (empty) {
                notification.error('There are no transactions that are not in progress to apply discount to.');
            } else {
                DiscountCodeService.discountCode.apply(discountCode.id, { amount: paymentTermBaseAmounts }).then(function (discountedAmounts) {
                    angular.forEach(purchase.payment_terms, function (paymentTerm) {
                        if (paymentTerm.id in paymentTermBaseAmounts) {
                            if ($scope.purchase.vat_inclusive) {
                                paymentTerm.discount_amount = paymentTerm.gross_base_amount - discountedAmounts[paymentTerm.id];
                            } else {
                                paymentTerm.discount_amount = paymentTerm.base_amount - discountedAmounts[paymentTerm.id];
                            }

                            updateDiscountedAmount(paymentTerm);
                        }
                    });
                });
            }
        }

        function addTerm() {
            $scope.purchase.payment_terms.push({});
        }

        function deleteTerm(index) {
            $scope.purchase.payment_terms.splice(index, 1);
        }

        function updateAmounts(obj) {
            if ($scope.purchase.vat_inclusive) {
                obj.vat_amount = calculateVatAmount(obj.gross_amount, $scope.purchase.vat_percentage, true);
                obj.amount = obj.gross_amount - obj.vat_amount;
            } else {
                obj.vat_amount = calculateVatAmount(obj.amount, $scope.purchase.vat_percentage, false);
                obj.gross_amount = obj.amount + obj.vat_amount;
            }
        }

        function updateAllAmounts() {
            updateAmounts($scope.purchase);

            angular.forEach($scope.purchase.payment_terms, function (paymentTerm) {
                updateAmounts(paymentTerm);
                updateBaseAmounts(paymentTerm);
            });
        }

        function updateBaseAmounts(paymentTerm) {
            if (paymentTerm.discount_amount === 0 || !$scope.purchase.discount_code || !$scope.purchase.discount_code.value) {
                paymentTerm.base_amount = paymentTerm.amount;
                paymentTerm.base_vat_amount = paymentTerm.vat_amount;
                paymentTerm.gross_base_amount = paymentTerm.gross_amount;
            }
        }

        function updateDiscountedAmount(paymentTerm) {
            if ($scope.purchase.vat_inclusive) {
                paymentTerm.gross_amount = Math.max(paymentTerm.gross_base_amount - paymentTerm.discount_amount, 0);
                paymentTerm.base_vat_amount = calculateVatAmount(paymentTerm.gross_base_amount, $scope.purchase.vat_percentage, true);
                paymentTerm.base_amount = paymentTerm.gross_base_amount - paymentTerm.base_vat_amount;
            } else {
                paymentTerm.amount = Math.max(paymentTerm.base_amount - paymentTerm.discount_amount, 0);
                paymentTerm.base_vat_amount = calculateVatAmount(paymentTerm.base_amount, $scope.purchase.vat_percentage, false);
                paymentTerm.gross_base_amount = paymentTerm.base_amount + paymentTerm.base_vat_amount;
            }

            updateAmounts(paymentTerm);
        }

        function calculateVatAmount(amount, percentage, inclusive) {
            if (inclusive) {
                return Math.round(amount / (100 + percentage) * percentage);
            }

            return Math.round(amount / 100 * percentage);
        }

        function getProducts(params) {
            return PurchaseService.purchase.alternativeProducts($scope.purchase.id, params);
        }

        function setPaymentTermTitles() {
            var product = $scope.purchase.product;

            angular.forEach($scope.purchase.payment_terms, function (paymentTerm, i) {
                paymentTerm.title = product.payment_terms.length > 0 ? product.payment_terms[i].title : product.title;
            });
        }

        function edit(data) {
            PurchaseService.editPurchase(data).then(function (data) {
                $state.transitionTo('sales.orders.order', { orderId: data.purchase.order_id });
            });
        }
    }
})(angular);
