(function (angular) {
    angular
        .module('one.admin')
        .directive('stringInput', stringInputDirective);

    stringInputDirective.$inject = ['$parse', '$timeout'];

    function stringInputDirective($parse, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                minLength: '@',
                maxLength: '@',
                focusWhen: '='
            },
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(parseValue);

                ngModel.$validators.string = function (modelValue, viewValue) {
                    return ngModel.$isEmpty(viewValue) || modelValue === '' + modelValue;
                };

                scope.ngModel = parseValue(scope.ngModel);

                element.on('blur', function () {
                    if (ngModel.$valid) {
                        ngModel.$setViewValue(ngModel.$modelValue);
                        ngModel.$render();
                    }
                });

                scope.$watch('focusWhen', function (newValue, oldValue) {
                    if (newValue) {
                        $timeout(function () {
                            element[0].focus();
                        }, 0);
                    }
                }, true);

                function parseValue(value) {
                    return value ? '' + value : '';
                }
            }
        };
    }
})(angular);
