(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config'];

    function config($stateProvider, config) {
        $stateProvider.state('events', {
            parent: 'main',
            abstract: true,
            url: '/events',
            template: '<div ui-view></div>',
            params: {
                section: 'events'
            }
        });

        $stateProvider.state('events.events', {
            parent: 'events',
            abstract: true,
            url: '/events',
            template: '<div ui-view></div>',
            params: {
                section: 'events'
            }
        });

        $stateProvider.state('events.events.index', {
            url: '',
            controller: 'EventsController',
            templateUrl: config.path.template('events.index'),
            requirePermission: 'events.overview'
        });

        $stateProvider.state('events.events.addEvent', {
            url: '/add',
            controller: 'AddEventController',
            templateUrl: config.path.template('events.add'),
            resolve: {
                sites: ['SiteService', function (SiteService, $stateParams) {
                    return SiteService.sites();
                }]
            },
            requirePermission: 'events.add'
        });

        $stateProvider.state('events.events.editEvent', {
            url: '/:eventId/edit',
            controller: 'EditEventController',
            templateUrl: config.path.template('events.edit'),
            resolve: {
                event: ['EventService', '$stateParams', function (EventService, $stateParams) {
                    return EventService.event($stateParams.eventId);
                }],
                sites: ['SiteService', function (SiteService, $stateParams) {
                    return SiteService.sites();
                }]
            },
            requirePermission: 'events.edit'
        });

        $stateProvider.state('events.events.event', {
            url: '/:eventId',
            controller: 'EventController',
            templateUrl: config.path.template('events.event'),
            resolve: {
                event: ['EventService', '$stateParams', function (EventService, $stateParams) {
                    return EventService.event($stateParams.eventId);
                }]
            },
            requirePermission: 'events.view'
        });
    }
})(angular);
