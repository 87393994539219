(function (angular) {
    angular
        .module('one.admin')
        .controller('UserFormController', UserFormController);

    UserFormController.$inject = ['$scope', 'session'];

    function UserFormController($scope, session) {
        $scope.project = session.get('project');
        $scope.organisation = $scope.project.organisation;
    }
})(angular);
