(function (angular) {
    angular
        .module('one.admin')
        .directive('postsDataframe', postsDataframeDirective);

    postsDataframeDirective.$inject = ['config'];

    function postsDataframeDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                posts: '=',
                name: '=',
                externalData: '&',
                hideColumns: '=',
                hideExport: '=',
            },
            controller: ['$scope', 'AuthService', 'PostService', 'dataframe', 'modal', 'session', function ($scope, AuthService, PostService, dataframe, modal, session) {
                var dataframeName = ($scope.name || 'posts');
                var sessionName = 'data.dataframes.' + ($scope.name || 'posts');

                $scope.posts = dataframe.init({
                    name: dataframeName,
                    externalData: function (params) {
                        return $scope.externalData()(params);
                    },
                    cols: [
                        {
                            name: 'title',
                            title: 'Title',
                            display: function (row) {
                                return '<span class="h4"><i class="md-icon">message</i> {{ row.title }}</span>';
                            },
                            weight: 3
                        },
                        {
                            name: 'owner',
                            title: 'By',
                            display: function (row) {
                                if (row.owner_type = 'action_campaign') {
                                    return '<a ui-sref="crowdfunding.actionCampaigns.actionCampaign({actionProjectId: row.owner.action_project_id, actionCampaignId: row.owner.id})" ng-click="$event.stopPropagation()"><i class="md-icon">group_work</i> {{ row.owner.title }}</a>';
                                }

                                return '';
                            },
                            visible: !$scope.hideColumns || $scope.hideColumns.indexOf('owner') == -1,
                            weight: 2
                        },
                        {
                            name: 'pinned',
                            title: 'Pinned',
                            display: function (row) {
                                return '{{ (row.pinned ? \'general.yes\' : \'general.no\') | translate }}';
                            },
                            weight: 1
                        },
                        {
                            name: 'status',
                            title: 'Status',
                            display: function (row) {
                                return '{{ \'post_statuses.\' + row.status | translate }}';
                            },
                            weight: 2
                        },
                        {
                            name: 'created_at',
                            title: 'Publication date',
                            display: function (row) {
                                return '{{ row.created_at | formatDate }}';
                            },
                            weight: 1
                        }
                    ],
                    rowSref: function (row) {
                        if (AuthService.userCanAccess('posts.edit')) {
                            return 'content.posts.editPost({postId: row.id})';
                        }

                        return false;
                    },
                    rowOptions: [
                        {
                            title: 'Edit',
                            icon: 'edit',
                            sref: function (row) {
                                return 'content.posts.editPost({postId: row.id})';
                            },
                            visible: function (row) {
                                return AuthService.userCanAccess('posts.edit');
                            }
                        },
                        {
                            title: 'Delete',
                            icon: 'delete',
                            click: function (row) {
                                openDeletePostModal(row.id);
                            },
                            visible: function (row) {
                                return AuthService.userCanAccess('posts.delete');
                            }
                        }
                    ],
                    state: session.get(sessionName, {
                        sort: {
                            created_at: 'desc'
                        }
                    })
                });

                session.sync(sessionName, $scope.posts.state);

                function openDeletePostModal(id) {
                    modal.open('confirm', null, { title: 'Delete this post?', message: 'This cannot be undone.' }).result.then(function (data) {
                        PostService.deletePost(id).then(function (data) {
                            $scope.posts.getData();
                        }, function (errors) {
                            notification.error(errors['all'][0]);
                        });
                    });
                }
            }],
            templateUrl: config.path.template('posts.posts_dataframe')
        };
    }
}(angular));
