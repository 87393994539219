(function (angular) {
    angular
        .module('one.admin')
        .directive('galleryModuleForm', galleryModuleFormDirective);

    galleryModuleFormDirective.$inject = ['config'];

    function galleryModuleFormDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.layouts = ['single', 'squares', 'highlight'];
            $scope.data = $scope.module.module_instance.items;
            $scope.site = $scope.module.type.site;

            $scope.addImage = addImage;
            $scope.deleteItem = deleteItem;
            $scope.toggleVisibility = toggleVisibility;
            $scope.setLayout = setLayout;

            function setLayout(layout) {
                if (layout) {
                    $scope.module.module_instance.layout = layout;
                }
            }

            function addImage(image) {
                $scope.module.module_instance.items.push({
                    image: image,
                    alt_text: '',
                    show: true
                });
            }

            function toggleVisibility(index) {
                $scope.module.module_instance.items[index].show = !$scope.module
                    .module_instance.items[index].show;
            }

            function deleteItem(index) {
                $scope.module.module_instance.items.splice(index, 1);
            }
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.gallery.form')
        };
    }
})(angular);
