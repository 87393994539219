(function (angular) {
    angular
        .module('one.admin')
        .directive('giftDestination', giftDestinationDirective);

    giftDestinationDirective.$inject = ['config'];

    function giftDestinationDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                gift: '=',
                hide: '=',
                noLink: '='
            },
            controller: ['$scope', function ($scope) {
                $scope.showActionProject = $scope.gift.action_project && (!$scope.hide || $scope.hide.indexOf('action_project') == -1);
                $scope.showActionCampaign = $scope.gift.action_campaign && (!$scope.hide || $scope.hide.indexOf('action_campaign') == -1);
                $scope.showAccount = $scope.gift.account && (!$scope.hide || $scope.hide.indexOf('account') == -1);
                $scope.showGiftReward = $scope.gift.gift_reward && (!$scope.hide || $scope.hide.indexOf('gift_reward') == -1);
            }],
            templateUrl: config.path.template('gifts.destination')
        };
    }
}(angular));
