(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['config'];

    function config(config) {
        config.templateCode = {
            output: function (output) {
                return '<span class="twig-output fr-deletable" contenteditable="false">' + output + '</span>';
            },
            statement: function (statement) {
                return '<span class="twig-statement fr-deletable" contenteditable="false">' + statement + '</span>';
            }
        }
    }
})(angular);
