(function (angular) {
    angular
        .module('one.admin')
        .controller('SegmentController', SegmentController);

    SegmentController.$inject = ['$scope', '$state', 'SegmentService', 'modal', 'segment'];

    function SegmentController($scope, $state, SegmentService, modal, segment) {
        $scope.segment = segment;

        $scope.state = {};

        $scope.dependentModels = [];

        if (segment.has_dependent_mailings) {
            $scope.dependentModels.push('mailings');
        }

        if (segment.has_dependent_pages) {
            $scope.dependentModels.push('pages');
        }

        if (segment.has_dependent_segments) {
            $scope.dependentModels.push('other_segments');
        }

        $scope.getAccounts = getAccounts(SegmentService, segment);
        $scope.openAuditorModal = openAuditorModal;
        $scope.openDeleteSegmentModal = openDeleteSegmentModal;

        function getAccounts(SegmentService, segment) {
            return function (params) {
                return SegmentService.accounts(segment.id, params);
            };
        }

        function openDeleteSegmentModal() {
            SegmentService.openDeleteSegmentModal($scope.segment.id, function () {
                $state.transitionTo('crm.segments.index');
            })
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: segment.id, modelType: 'segment' });
        }
    }
})(angular);
