(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('finance', {
            parent: 'main',
            abstract: true,
            url: '/finance',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        // Dashboard

        $stateProvider.state('finance.dashboard', {
            parent: 'finance',
            abstract: true,
            url: '/dashboard',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('finance.dashboard.index', {
            url: '',
            controller: 'FinanceDashboardController',
            templateUrl: config.path.template('dashboards.finance.dashboard'),
            resolve: {
                purchaseSources: ['PurchaseService', function (PurchaseService) {
                    return PurchaseService.purchaseSources({ sort: { title: 'asc' }});
                }],
                purchaseDestinations: ['PurchaseService', function (PurchaseService) {
                    return PurchaseService.purchaseDestinations({ sort: { title: 'asc' }});
                }],
                paymentMethods: ['PaymentMethodService', function (PaymentMethodService) {
                    return PaymentMethodService.paymentMethods({ sort: { title: 'asc' }});
                }],
                productCategories: ['ProductService', function (ProductService) {
                    return ProductService.productCategories({ with: 'products', sort: { title: 'asc' }});
                }],
                vatPercentages: ['ProductService', function (ProductService) {
                    return ProductService.vatPercentages;
                }],
                miscProducts: ['ProductService', function (ProductService) {
                    return ProductService.products({ filters: { product_categories: ['null']}, sort: { title: 'asc' }});
                }]
            }
        });

        // Bank accounts

        $stateProvider.state('finance.bankAccounts', {
            parent: 'finance',
            abstract: true,
            url: '/bank-accounts',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('finance.bankAccounts.editBankAccount', {
            url: '/:bankAccountId/edit',
            controller: 'EditBankAccountController',
            templateUrl: config.path.template('bank_accounts.edit'),
            resolve: {
                bankAccount: ['BankAccountService', '$stateParams', function (BankAccountService, $stateParams) {
                    return BankAccountService.bankAccount($stateParams.bankAccountId);
                }]
            },
            requirePermission: 'bank_accounts.edit'
        });

        modalProvider.modal('addBankAccount', {
            templateUrl: config.path.template('bank_accounts.add'),
            controller: 'AddBankAccountModalController',
            backdrop: 'static',
            keyboard: false
        });

        // Payment references

        $stateProvider.state('finance.paymentReferences', {
            parent: 'finance',
            abstract: true,
            url: '/payment-references',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('finance.paymentReferences.index', {
            url: '',
            controller: 'PaymentReferenceConfigsController',
            templateUrl: config.path.template('payment_reference_configs.index'),
            requirePermission: 'payment_reference_configs.overview'
        });

        $stateProvider.state('finance.paymentReferences.addPaymentReferenceConfig', {
            url: '/add',
            controller: 'AddPaymentReferenceConfigController',
            templateUrl: config.path.template('payment_reference_configs.add'),
            resolve: {
                products: ['ProductService', function (ProductService) {
                    return ProductService.products();
                }],
                purchaseSources: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseSources();
                }],
                purchaseDestinations: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseDestinations();
                }]
            },
            requirePermission: 'payment_reference_configs.add'
        });

        $stateProvider.state('finance.paymentReferences.editPaymentReferenceConfig', {
            url: '/:paymentReferenceConfigId/edit',
            controller: 'EditPaymentReferenceConfigController',
            templateUrl: config.path.template('payment_reference_configs.edit'),
            resolve: {
                products: ['ProductService', function (ProductService) {
                    return ProductService.products();
                }],
                purchaseSources: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseSources();
                }],
                purchaseDestinations: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseDestinations();
                }],
                paymentReferenceConfig: ['PaymentReferenceService', '$stateParams', function (PaymentReferenceService, $stateParams) {
                    return PaymentReferenceService.paymentReferenceConfig($stateParams.paymentReferenceConfigId);
                }]
            },
            requirePermission: 'payment_reference_configs.edit'
        });

        modalProvider.modal('exportPaymentReferences', {
            templateUrl: config.path.template('payment_reference_configs.export_modal'),
            controller: 'ExportPaymentReferencesModalController',
            backdrop: 'static',
            keyboard: false
        });


        // SDD Batches

        $stateProvider.state('finance.sddBatches', {
            parent: 'finance',
            abstract: true,
            url: '/sdd-batches',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('finance.sddBatches.index', {
            url: '',
            controller: 'SddBatchesController',
            templateUrl: config.path.template('sdd_batches.index'),
            requirePermission: 'sdd_batches.overview'
        });

        $stateProvider.state('finance.sddBatches.addSddBatch', {
            url: '/add',
            controller: 'AddSddBatchController',
            templateUrl: config.path.template('sdd_batches.add'),
            resolve: {
                paymentMethods: ['PaymentMethodService', function (PaymentMethodService) {
                    return PaymentMethodService.sddPaymentMethods().then(function (methods) {
                        return methods;
                    });
                }]
            },
            requirePermission: 'sdd_batches.add'
        });

        $stateProvider.state('finance.sddBatches.sddBatch', {
            url: '/:sddBatchId',
            controller: 'SddBatchController',
            templateUrl: config.path.template('sdd_batches.sdd_batch'),
            resolve: {
                sddBatch: ['SddBatchService', '$stateParams', function (SddBatchService, $stateParams) {
                    return SddBatchService.sddBatch($stateParams.sddBatchId);
                }]
            },
            requirePermission: 'sdd_batches.view'
        });

        modalProvider.modal('processSddBatch', {
            templateUrl: config.path.template('sdd_batches.process_sdd_batch_modal'),
            controller: 'ProcessSddBatchModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('finalizeSddTransaction', {
            templateUrl: config.path.template('sdd_batches.finalize_sdd_transaction_modal'),
            controller: 'FinalizeSddTransactionModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('refundTransaction', {
            templateUrl: config.path.template('transactions.refund_transaction_modal'),
            controller: 'RefundTransactionModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('reverseSddTransaction', {
            templateUrl: config.path.template('sdd_batches.reverse_sdd_transaction_modal'),
            controller: 'ReverseSddTransactionModalController',
            backdrop: 'static',
            keyboard: false
        });

        $stateProvider.state('finance.transactions', {
            parent: 'finance',
            abstract: true,
            url: '/transactions',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('finance.transactions.index', {
            url: '',
            controller: 'TransactionsController',
            templateUrl: config.path.template('transactions.index'),
            requirePermission: 'transactions.overview'
        });

        $stateProvider.state('finance.transactions.editTransaction', {
            url: '/:transactionId/edit',
            controller: 'EditTransactionController',
            templateUrl: config.path.template('transactions.edit'),
            resolve: {
                transaction: ['TransactionService', '$stateParams', function (TransactionService, $stateParams) {
                    return TransactionService.transaction($stateParams.transactionId);
                }]
            },
            requirePermission: 'transactions.edit'
        });

        $stateProvider.state('finance.transactions.transaction', {
            url: '/:transactionId',
            controller: 'TransactionController',
            templateUrl: config.path.template('transactions.transaction'),
            resolve: {
                transaction: ['TransactionService', '$stateParams', function (TransactionService, $stateParams) {
                    return TransactionService.transaction($stateParams.transactionId);
                }]
            },
            requirePermission: 'transactions.view'
        });

        // SDD Reversal Batches

        $stateProvider.state('finance.bankStatements', {
            parent: 'finance',
            abstract: true,
            url: '/bank-statements',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('finance.bankStatements.index', {
            url: '',
            controller: 'BankStatementsController',
            templateUrl: config.path.template('bank_statements.index'),
            resolve: {
                unmatchedPaymentCount: ['BankStatementService', function (BankStatementService) {
                    return BankStatementService.unmatchedPaymentCount();
                }]
            },
            requirePermission: 'bank_statements.overview'
        });

        $stateProvider.state('finance.bankStatements.addBankStatementBatch', {
            url: '/add',
            controller: 'AddBankStatementBatchController',
            templateUrl: config.path.template('bank_statements.add'),
            requirePermission: 'bank_statements.add'
        });

        $stateProvider.state('finance.bankStatements.processUnmatchedPayments', {
            url: '/process-unmatched-payments',
            controller: 'ProcessUnmatchedPaymentsController',
            templateUrl: config.path.template('bank_statements.process_unmatched_payments'),
            params: {
                bankStatementEntryId: null
            },
            requirePermission: 'bank_statements.add'
        });

        $stateProvider.state('finance.bankStatements.bankStatement', {
            url: '/:bankStatementId',
            controller: 'BankStatementController',
            templateUrl: config.path.template('bank_statements.bank_statement'),
            resolve: {
                bankStatement: ['BankStatementService', '$stateParams', function (BankStatementService, $stateParams) {
                    return BankStatementService.bankStatement($stateParams.bankStatementId);
                }]
            },
            requirePermission: 'bank_statements.view'
        });

        // Annual payments
        modalProvider.modal('saveAnnualPayment', {
            templateUrl: config.path.template('annual_payments.save'),
            controller: 'SaveAnnualPaymentModalController',
            backdrop: 'static',
            keyboard: false
        });
    }
})(angular);
