(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$locale'];

    function run($locale) {
        $locale.NUMBER_FORMATS.CURRENCY_SYM = '&euro;';
        $locale.NUMBER_FORMATS.GROUP_SEP = '.';
        $locale.NUMBER_FORMATS.DECIMAL_SEP = ',';
    }
})(angular);
