(function (angular) {
    angular
        .module('one.admin')
        .component('purchasePaymentTerm', {
            controller: controller,
            bindings: {
                purchase: '=',
                paymentTerm: '=',
                indent: '<'
            },
            templateUrl: ['config', function (config) {
                return config.path.template('orders.purchases.payment_terms.payment_term');
            }]
        });

    controller.$inject = ['$filter', '$state', 'modal', 'PurchaseService'];

    function controller($filter, $state, modal, PurchaseService) {
        var $ctrl = this;

        $ctrl.openCancelPaymentTermModal = function (size) {
            modal.open('confirm', size, {title: 'Are you sure?', message: 'Cancel this payment term?'}).result.then(function (data) {
                PurchaseService.cancelPurchasePaymentTerm($ctrl.paymentTerm.id).then(function (data) {
                    $state.reload($state.current);
                });
            });
        };

        $ctrl.openRefundPaymentTermModal = function (size) {
            modal.open('confirm', size, {title: 'Are you sure?', message: $filter('translate')('general.refund_transaction_description')}).result.then(function (data) {
                PurchaseService.refundPurchasePaymentTerm($ctrl.paymentTerm.id).then(function (data) {
                    $state.reload($state.current);
                });
            });
        };

        $ctrl.$onInit = function () {
            $ctrl.paymentTerm.reference_strings = [];

            angular.forEach($ctrl.paymentTerm.references, function (reference) {
                $ctrl.paymentTerm.reference_strings.push(reference.reference);
            });
        
            $ctrl.subPaymentTerms = [];

            angular.forEach($ctrl.purchase.payment_terms, function (paymentTerm) {
                if (paymentTerm.parent_transaction_id && paymentTerm.parent_transaction_id == $ctrl.paymentTerm.transaction_id) {
                    $ctrl.subPaymentTerms.push(paymentTerm);
                }
            });
        };
    }
})(angular);
