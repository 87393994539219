(function(angular) {
    angular
        .module('one.admin')
        .directive('buttonModuleForm', buttonModuleFormDirective);

    buttonModuleFormDirective.$inject = ['config'];

    function buttonModuleFormDirective(config) {
        controller.$inject = ['$scope', 'ModuleService', 'VisitableService'];

        function controller($scope, ModuleService, VisitableService) {
            $scope.variables = ModuleService.getSiteVariables();
            $scope.sizes = ['sm', 'md', 'lg'];
            $scope.alignments = ['left', 'center', 'right'];
            $scope.toggleCustomUrl = angular.isString($scope.module.module_instance.custom_url);

            $scope.formatVisitable = VisitableService.formatVisitableTitle;
            $scope.getVisitables = VisitableService.pageVisitables;

            $scope.$watch('toggleCustomUrl', function (newVal) {
                if (newVal) {
                    $scope.module.module_instance.visitable = null;
                    return;
                }

                $scope.module.module_instance.custom_url = null;
            });
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.button.form')
        };
    }
})(angular);
