(function (angular) {
    angular
        .module('one.admin')
        .factory('dataframe', dataframe);

    dataframe.$inject = ['$rootScope', '$state', '$timeout', 'config', 'modal', 'notification'];

    function dataframe($rootScope, $state, $timeout, config, modal, notification) {
        this.init = init;
        this.initFilterList = initFilterList;

        function Dataframe(options) {
            // Initialization methods
            this.initOptions = initOptions;
            this.initCols = initCols;
            this.initStateWatcher = initStateWatcher;
            this.initData = initData;

            // Data methods
            this.getData = getData;
            this.getExternalData = getExternalData;
            this.getInternalData = getInternalData;
            this.getFilteredRows = getFilteredRows;

            // Helper methods
            this.showCols = showCols;
            this.openRequestExportModal = openRequestExportModal;
            this.requestExport = requestExport;
            this.resetFilters = resetFilters;
            this.hasFilters = hasFilters;
            this.getBatchSize = getBatchSize;
            this.isEmptyBatch = isEmptyBatch;
            this.allowBatchAction = allowBatchAction;

            // Initialization
            this.initOptions();
            this.initCols();
            this.initStateWatcher();
            this.initData();

            function initOptions() {
                var defaultOptions = {
                    name: '',
                    externalData: null,
                    exportData: null,
                    data: [],
                    cols: [],
                    rowSref: null,
                    rowClick: null,
                    rowOptions: [],
                    rowHasVisibleOptions: function (row) {
                        var visible = false;

                        angular.forEach(this.rowOptions, function (rowOption) {
                            if (!rowOption.visible || rowOption.visible(row)) {
                                // Option is visible when no visibility function is defined or when the function evaluates true.
                                visible = true;
                            }
                        });

                        return visible;
                    },
                    showHeader: true,
                    showStats: true,
                    showPagination: true,
                    loading: false,
                    total: null,
                    filterables: [],
                    filterableLabels: {},
                    state: {
                        maxPagerSize: 5,
                        search: '',
                        perPage: 10,
                        page: 1,
                        sort: {},
                        filters: {},
                        visible: []
                    },
                    batchLimits: {}
                };

                angular.merge(this, defaultOptions, options);

                this.exportData = this.exportData || this.externalData;
            }

            function initCols() {
                var dataframe = this;

                var defaultCol = {
                    name: '',
                    title: '',
                    width: '',
                    weight: 1,
                    visible: true,
                    stickRight: false,
                    sortable: false,
                    sort: function (row) {
                        if (!this.sortable) {
                            return;
                        }

                        if (!dataframe.state.sort[this.name]) {
                            dataframe.state.sort = {};
                        }

                        dataframe.state.sort[this.name] = dataframe.state.sort[this.name] == 'asc' ? 'desc' : 'asc';
                    },
                    display: function (row) {
                        return this.value(row);
                    },
                    contains: function (value, row) {
                        value = value.toLowerCase();

                        return value === '' || value === null || value === undefined || ('' + this.value(row).toLowerCase()).indexOf(value) > -1;
                    },
                    value: function (row) {
                        return '{{ row.' + this.name + ' }}';
                    },
                    computeWidth: function (col) {
                        var weightSum = 0;

                        for (var i = 0; i < dataframe.cols.length; i++) {
                            if (dataframe.cols[i].visible) {
                                weightSum = weightSum + dataframe.cols[i].weight;
                            }
                        }

                        return (col.weight / weightSum * 100) + '%';
                    }
                };

                angular.forEach(dataframe.cols, function (col, i) {
                    dataframe.cols[i] = angular.merge({}, defaultCol, col);

                    if (dataframe.state.visible.length > 0) {
                        dataframe.cols[i].visible = dataframe.state.visible.indexOf(col.name) != -1;
                    }
                });
            }

            function initStateWatcher() {
                var timeout;
                var dataframe = this;

                var deregister = $rootScope.$watch(function () {
                    return [dataframe.state.search, dataframe.state.sort, dataframe.state.filters, dataframe.state.page];
                }, function (newValue, oldValue) {
                    if (angular.equals(newValue, oldValue)) {
                        return;
                    }

                    if (newValue[0] !== oldValue[0] && typeof newValue[0] == 'string' && newValue[0].length > 0 && newValue[0].length < config.minSearchLength)  {
                        // Only start searching after the user has typed at least 2 characters
                        return;
                    }

                    $timeout.cancel(timeout);

                    timeout = $timeout(function () {
                        dataframe.getData();
                    }, 250);
                }, true);

                $rootScope.$on('$stateChangeSuccess', deregister);
            }

            function initData() {
                this.rows = [];

                this.getData();
            }

            function getData(inBackground) {
                if (this.externalData) {
                    return this.getExternalData(inBackground);
                } else {
                    this.getInternalData();
                }
            }

            function getExternalData(inBackground) {
                this.loading = !inBackground;

                // Genereate search ID to match search requests and responses.
                this.searchId = "";

                var alphabet = "0123456789abcdef";

                for (var i = 0; i < 16; i++) {
                    this.searchId += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                }

                return this.externalData({
                    page: this.state.page,
                    perPage: this.state.perPage,
                    search: this.state.search,
                    filters: this.state.filters,
                    sort: this.state.sort,
                    searchId: this.searchId
                }).then(setExternalDataResult.bind(this));
            }

            function getInternalData() {
                var rows = this.getFilteredRows();

                if (this.state.perPage > 0) {
                    this.rows = rows.slice((this.state.page - 1) * this.state.perPage, this.state.page * this.state.perPage);
                } else {
                    this.rows = rows;
                }

                this.total = rows.length;
            }

            // Takes each row that contains the search value in any column and leaves out the other rows
            function getFilteredRows() {
                var result = [];

                for (var i = 0; i < this.data.length; i++) {
                    for (var j = 0; j < this.cols.length; j++) {
                        if (this.cols[j].contains(this.state.search, this.data[i])) {
                            result.push(this.data[i]);
                            break;
                        }
                    }
                }

                return result;
            }

            function setExternalDataResult(result) {
                // Ignore old search responses.
                if (!result.searchId || this.searchId == result.searchId) {
                    this.rows = this.state.perPage ? result.data : result;
                    this.total = this.state.perPage ? result.total : this.rows.length;
                    this.loading = false;
                }
            }

            function showCols(colsToShow) {
                this.state.visible = colsToShow;

                for (var i = 0; i < this.cols.length; i++) {
                    this.cols[i].visible = colsToShow.indexOf(this.cols[i].name) != -1;
                }
            }

            function openRequestExportModal(data) {
                modal.open('requestExport', null, { dataframe: this, export: data }).result.then(function (data) {
                    if (data.scheduled_at) {
                        notification.success('Export(s) scheduled.');

                        $state.transitionTo('exports.scheduled');
                    } else {
                        notification.success('Your export will be ready soon.');

                        $state.transitionTo('exports.index');
                    }
                });
            }

            function requestExport(data) {
                this.exportData(angular.merge(this.state, {
                    requestExport: true
                }, { export: data })).then(function (result) {
                    notification.success('Your export will be ready soon.');

                    $state.transitionTo('exports.index');
                });
            }

            function resetFilters(filters) {
                this.state.search = '';
                this.state.filters = filters || {};
            }

            function hasFilters() {
                var hasFilters = !!this.state.search;

                angular.forEach(this.state.filters, function (values) {
                    if (values && values.length > 0) {
                        hasFilters = true;
                    }
                });

                return hasFilters;
            }

            function getBatchSize() {
                return this.total;
            }

            function isEmptyBatch() {
                return this.getBatchSize() <= 0;
            }

            function allowBatchAction(action) {
                if (action != 'export' && this.isEmptyBatch()) {
                    return false;
                }

                return !this.batchLimits[action] || this.getBatchSize() <= this.batchLimits[action];
            }

            return this;
        }

        /**
         * Create a new instance of the Dataframe class.
         */
        function init(options) {
            return new Dataframe(options);
        }

        /**
         * Convert a list of objects to a list of value/label pairs used by the
         * one-dataframe-filter directive.
         */
        function initFilterList(objects, valueKey, label) {
            var filters = [];

            angular.forEach(objects, function (object) {
                filters.push({
                    value: object[valueKey],
                    label: angular.isFunction(label) ? label(object) : object[label],
                    object: object
                });
            });

            return filters;
        }

        return this;
    }
})(angular);
