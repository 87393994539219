(function (angular) {
    angular
        .module('one.admin')
        .factory('segmentBuilder', segmentBuilder);

    segmentBuilder.$inject = ['$q', 'services', 'translateFilter', 'capitalizeFilter'];

    function segmentBuilder($q, services, translateFilter, capitalizeFilter) {
        var booleanOptions = [
            {
                label: translateFilter('general.yes'),
                value: true
            },
            {
                label: translateFilter('general.no'),
                value: false
            }
        ];

        var filterables = {
            account: function () {
                var promises = {
                    accountTypes: services.AccountService.types(),
                    genders: services.AccountService.genders(),
                    languages: services.SiteService.languages()
                };

                return $q.all(promises).then(function (data) {
                    return {
                        fields: [
                            {
                                name: 'account_type_name',
                                label: translateFilter('general.account_type'),
                                options: data.accountTypes,
                                optionLabelFn: services.AccountService.formatAccountTypeName,
                                optionLabel: 'name',
                                optionValue: 'name',
                                type: 'options'
                            },
                            {
                                name: 'id',
                                label: translateFilter('general.id'),
                                type: 'integer'
                            },
                            {
                                name: 'title',
                                label: translateFilter('general.title'),
                                type: 'string'
                            },
                            {
                                name: 'initials',
                                label: translateFilter('general.initials'),
                                type: 'string'
                            },
                            {
                                name: 'first_name',
                                label: translateFilter('general.first_name'),
                                type: 'string'
                            },
                            {
                                name: 'infix',
                                label: translateFilter('general.infix'),
                                type: 'string'
                            },
                            {
                                name: 'last_name',
                                label: translateFilter('general.last_name'),
                                type: 'string'
                            },
                            {
                                name: 'gender',
                                label: translateFilter('general.gender'),
                                options: data.genders,
                                optionLabelFn: services.AccountService.formatGender,
                                type: 'options'
                            },
                            {
                                name: 'date_of_birth',
                                label: translateFilter('general.date_of_birth'),
                                type: 'date'
                            },
                            {
                                name: 'street',
                                label: translateFilter('general.street'),
                                type: 'string'
                            },
                            {
                                name: 'house_number',
                                label: translateFilter('general.house_number'),
                                type: 'string'
                            },
                            {
                                name: 'house_number_addition',
                                label: translateFilter('general.house_number_addition'),
                                type: 'string'
                            },
                            {
                                name: 'zip_code',
                                label: translateFilter('general.zip_code'),
                                type: 'integer'
                            },
                            {
                                name: 'city',
                                label: translateFilter('general.city'),
                                type: 'string'
                            },
                            {
                                name: 'country',
                                label: translateFilter('general.country'),
                                type: 'string'
                            },
                            {
                                name: 'postal_street',
                                label: translateFilter('general.postal_street'),
                                type: 'string'
                            },
                            {
                                name: 'postal_house_number',
                                label: translateFilter('general.postal_house_number'),
                                type: 'string'
                            },
                            {
                                name: 'postal_house_number_addition',
                                label: translateFilter('general.postal_house_number_addition'),
                                type: 'string'
                            },
                            {
                                name: 'postal_zip_code',
                                label: translateFilter('general.postal_zip_code'),
                                type: 'integer'
                            },
                            {
                                name: 'postal_city',
                                label: translateFilter('general.postal_city'),
                                type: 'string'
                            },
                            {
                                name: 'postal_country',
                                label: translateFilter('general.postal_country'),
                                type: 'string'
                            },
                            {
                                name: 'phone_home',
                                label: translateFilter('general.phone_home'),
                                type: 'string'
                            },
                            {
                                name: 'phone_private',
                                label: translateFilter('general.phone_private'),
                                type: 'string'
                            },
                            {
                                name: 'phone_work',
                                label: translateFilter('general.phone_work'),
                                type: 'string'
                            },
                            {
                                name: 'phone_emergency',
                                label: translateFilter('general.phone_emergency'),
                                type: 'string'
                            },
                            {
                                name: 'email',
                                label: translateFilter('general.email'),
                                type: 'email'
                            },
                            {
                                name: 'secondary_email',
                                label: translateFilter('general.secondary_email'),
                                type: 'email'
                            },
                            {
                                name: 'image_id',
                                label: translateFilter('general.image'),
                                type: 'nullable'
                            },
                            {
                                name: 'contact_user_id',
                                label: translateFilter('general.contact_user'),
                                externalOptions: services.AuthService.users,
                                optionLabelFn: services.AuthService.formatUserName,
                                optionValue: 'id',
                                model: 'user',
                                type: 'options'
                            },
                            {
                                name: 'language_name',
                                label: translateFilter('general.language'),
                                options: data.languages,
                                optionLabelFn: services.SiteService.formatLanguageTitle,
                                optionLabel: 'name',
                                optionValue: 'name',
                                type: 'options',
                                nullable: true,
                            },
                            {
                                name: 'date_unsubscribed_from_mails',
                                label: translateFilter('general.date_unsubscribed_from_mails'),
                                type: 'date'
                            },
                            {
                                name: 'last_activity',
                                label: translateFilter('general.last_activity'),
                                type: 'datetime'
                            },
                            {
                                name: 'created_at',
                                label: translateFilter('general.date_created'),
                                type: 'datetime'
                            },
                        ],
                        getField: getField
                    };
                });
            },

            annualPayment: function () {
                return $q(function (resolve) {
                    resolve({
                        fields: [
                            {
                                name: 'year',
                                label: capitalizeFilter(translateFilter('general.year')),
                                type: 'integer'
                            },
                            {
                                name: 'actual',
                                label: translateFilter('general.actual'),
                                type: 'money'
                            },
                            {
                                name: 'expected',
                                label: translateFilter('general.expected'),
                                type: 'money'
                            },
                            {
                                name: 'ratio',
                                label: translateFilter('general.ratio'),
                                type: 'integer'
                            }
                        ],
                        getField: getField
                    });
                });
            },

            group: function (params) {
                return services.GroupService.groups(params).then(function (groups) {
                    return {
                        groups: groups
                    };
                });
            },

            segment: function (params) {
                return services.SegmentService.segments(params).then(function (segments) {
                    return {
                        segments: segments
                    };
                });
            },

            actionCampaign: function (params) {
                var promises = {
                    actionCampaignTypes: services.ActionCampaignService.types(),
                    actionProjects: services.ActionProjectService.actionProjects(),
                };

                return $q.all(promises).then(function (data) {
                    return {
                        fields: [
                            {
                                name: 'action_project_id',
                                label: translateFilter('general.action_project'),
                                options: data.actionProjects,
                                optionLabelFn: services.ActionProjectService.formatActionProjectTitle,
                                optionLabel: 'title',
                                optionValue: 'id',
                                model: 'action_project',
                                type: 'selection'
                            },
                            {
                                name: 'image_id',
                                label: translateFilter('general.image'),
                                type: 'nullable'
                            },
                            {
                                name: 'title',
                                label: translateFilter('general.title'),
                                type: 'string'
                            },
                            {
                                name: 'description',
                                label: translateFilter('general.description'),
                                type: 'string'
                            },
                            {
                                name: 'gift_amount',
                                label: translateFilter('general.gift_amount'),
                                type: 'money'
                            },
                            {
                                name: 'target',
                                label: translateFilter('general.target'),
                                type: 'money'
                            },
                            {
                                name: 'type',
                                label: translateFilter('general.type'),
                                options: data.actionCampaignTypes,
                                optionLabelFn: function (type) {
                                    return translateFilter('general.' + type);
                                },
                                type: 'selection'
                            },
                            {
                                name: 'end_date',
                                label: translateFilter('general.end_date'),
                                type: 'datetime'
                            },
                            {
                                name: 'created_at',
                                label: translateFilter('general.date_created'),
                                type: 'datetime'
                            },
                            {
                                name: 'parent_campaign_id',
                                label: translateFilter('general.parent_campaign'),
                                type: 'nullable'
                            },
                            {
                                name: 'parent_campaigns.image_id',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.image'),
                                type: 'nullable'
                            },
                            {
                                name: 'parent_campaigns.title',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.title'),
                                type: 'string'
                            },
                            {
                                name: 'parent_campaigns.description',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.description'),
                                type: 'string'
                            },
                            {
                                name: 'parent_campaigns.gift_amount',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.gift_amount'),
                                type: 'money'
                            },
                            {
                                name: 'parent_campaigns.target',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.target'),
                                type: 'money'
                            },
                            {
                                name: 'parent_campaigns.type',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.type'),
                                options: data.actionCampaignTypes.filter(function (type) { return type != 'individual'; }),
                                optionLabelFn: function (type) {
                                    return translateFilter('general.' + type);
                                },
                                type: 'selection'
                            },
                            {
                                name: 'parent_campaigns.end_date',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.end_date'),
                                type: 'datetime'
                            },
                            {
                                name: 'parent_campaigns.created_at',
                                label: translateFilter('general.parent_campaign') + ' › ' + translateFilter('general.date_created'),
                                type: 'datetime'
                            }
                        ],
                        getField: getField
                    };
                });
            },

            formEntry: function (formId) {
                return services.FormService.form(formId).then(function (form) {
                    var fields = [];

                    angular.forEach(form.fields, function (field) {
                        if (field.type.data_type != 'array' || field.choices.length > 0) {
                            var types = {
                                generic_date: 'date',
                            };

                            fields.push({
                                label: field.title,
                                field: field,
                                type: types[field.type.name] || field.type.data_type,
                            });
                        } else {
                            // TODO: Implement filters for purchases, discount codes, etc.
                        }
                    });

                    fields.push({
                        name: 'created_at',
                        label: translateFilter('general.created_at'),
                        type: 'datetime'
                    });

                    return {
                        fields: fields,
                        getField: function (filter) {
                            var filterableField;

                            if (filter.type == 'comparison') {
                                angular.forEach(this.fields, function (field) {
                                    if (field.name == filter.field) {
                                        filterableField = field;
                                        return;
                                    }
                                });
                            } else if (filter.type == 'has') {
                                angular.forEach(this.fields, function (field) {
                                    if (field.field && field.field.id == filter.filters[0].value) {
                                        filterableField = field;
                                        return;
                                    }
                                });
                            }

                            return filterableField;
                        }
                    };
                });
            },

            sentMail: function (params) {
                var promises = {
                    sentMailStatusOptions: services.MailService.sentMailStatuses(params)
                };

                return $q.all(promises).then(function (data) {
                    return {
                        fields: [
                            {
                                name: 'transactional_mail_id',
                                label: translateFilter('general.transactional_mail'),
                                externalOptions: services.TransactionalMailService.transactionalMails,
                                optionLabelFn: services.TransactionalMailService.formatTransactionalMailTitle,
                                optionLabel: 'title',
                                optionValue: 'id',
                                model: 'transactional_mail',
                                type: 'options'
                            },
                            {
                                name: 'mailing_id',
                                label: translateFilter('general.mailing'),
                                externalOptions: services.MailingService.mailings,
                                optionLabelFn: services.MailingService.formatMailingTitle,
                                optionLabel: 'title',
                                optionValue: 'id',
                                model: 'mailing',
                                type: 'options'
                            },
                            {
                                name: 'status',
                                label: translateFilter('general.status'),
                                options: data.sentMailStatusOptions,
                                optionLabelFn: function (status) {
                                    return translateFilter('general.' + status);
                                },
                                type: 'options'
                            },
                            {
                                name: 'opened',
                                label: translateFilter('general.opened'),
                                options: booleanOptions,
                                optionLabel: 'label',
                                optionValue: 'value',
                                type: 'options'
                            },
                            {
                                name: 'created_at',
                                label: translateFilter('general.date_created'),
                                type: 'datetime'
                            }
                        ],
                        getField: getField
                    };
                });
            },

            sentMailClick: function (params) {
                return $q(function (resolve) {
                    resolve({
                        fields: [
                            {
                                name: 'url',
                                label: translateFilter('general.url'),
                                type: 'string'
                            },
                            {
                                name: 'created_at',
                                label: translateFilter('general.date_created'),
                                type: 'datetime'
                            }
                        ],
                        getField: getField
                    });
                });
            }
        };

        function getField(filter) {
            var filterableField;

            angular.forEach(this.fields, function (field) {
                if (field.name == filter.field) {
                    filterableField = field;
                }
            });

            return filterableField;
        }

        var intervalDirections = [
            { label: translateFilter('interval_directions.past'), value: 'past' },
            { label: translateFilter('interval_directions.future'), value: 'future' }
        ];

        var intervalTypes = [
            { label: translateFilter('interval_types.days'), value: 'days' },
            { label: translateFilter('interval_types.weeks'), value: 'weeks' },
            { label: translateFilter('interval_types.months'), value: 'months' },
            { label: translateFilter('interval_types.years'), value: 'years' }
        ];

        var comparisonOperatorMap = {
            '=': { label: translateFilter('operators.='), value: '=' },
            '!=': { label: translateFilter('operators.!='), value: '!=' },
            '>': { label: translateFilter('operators.>'), value: '>' },
            '>=': { label: translateFilter('operators.>='), value: '>=' },
            '<': { label: translateFilter('operators.<'), value: '<' },
            '<=': { label: translateFilter('operators.<='), value: '<=' },
            'eq_interval': { label: translateFilter('operators.eq_interval'), value: 'eq_interval' },
            'neq_interval': { label: translateFilter('operators.neq_interval'), value: 'neq_interval' },
            'gt_interval': { label: translateFilter('operators.gt_interval'), value: 'gt_interval' },
            'gte_interval': { label: translateFilter('operators.gte_interval'), value: 'gte_interval' },
            'lt_interval': { label: translateFilter('operators.lt_interval'), value: 'lt_interval' },
            'lte_interval': { label: translateFilter('operators.lte_interval'), value: 'lte_interval' },
            'today': { label: translateFilter('operators.today'), value: 'today' },
            'today_without_year': { label: translateFilter('operators.today_without_year'), value: 'today_without_year' },
            'empty': { label: translateFilter('operators.empty'), value: 'empty' },
            'not_empty': { label: translateFilter('operators.not_empty'), value: 'not_empty' },
            'in': { label: translateFilter('operators.in'), value: 'in' },
            'not_in': { label: translateFilter('operators.not_in'), value: 'not_in' },
            'starts_with': { label: translateFilter('operators.starts_with'), value: 'starts_with' },
        };

        var comparisonOperators = {
            boolean: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!=']
            ],
            string: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty'],
                comparisonOperatorMap['starts_with']
            ],
            options: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            selection: [
                comparisonOperatorMap['in'],
                comparisonOperatorMap['not_in'],
            ],
            text: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            email: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            integer: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['>'],
                comparisonOperatorMap['>='],
                comparisonOperatorMap['<'],
                comparisonOperatorMap['<='],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            date: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['>'],
                comparisonOperatorMap['>='],
                comparisonOperatorMap['<'],
                comparisonOperatorMap['<='],
                comparisonOperatorMap['eq_interval'],
                comparisonOperatorMap['neq_interval'],
                comparisonOperatorMap['gt_interval'],
                comparisonOperatorMap['gte_interval'],
                comparisonOperatorMap['lt_interval'],
                comparisonOperatorMap['lte_interval'],
                comparisonOperatorMap['today'],
                comparisonOperatorMap['today_without_year'],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            datetime: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['>'],
                comparisonOperatorMap['>='],
                comparisonOperatorMap['<'],
                comparisonOperatorMap['<='],
                comparisonOperatorMap['eq_interval'],
                comparisonOperatorMap['neq_interval'],
                comparisonOperatorMap['gt_interval'],
                comparisonOperatorMap['gte_interval'],
                comparisonOperatorMap['lt_interval'],
                comparisonOperatorMap['lte_interval'],
                comparisonOperatorMap['today'],
                comparisonOperatorMap['today_without_year'],
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            nullable: [
                comparisonOperatorMap['empty'],
                comparisonOperatorMap['not_empty']
            ],
            money: [
                comparisonOperatorMap['='],
                comparisonOperatorMap['!='],
                comparisonOperatorMap['>'],
                comparisonOperatorMap['>='],
                comparisonOperatorMap['<'],
                comparisonOperatorMap['<=']
            ]
        };

        var booleanOperators = [
            { label: translateFilter('operators.and'), value: 'and' },
            { label: translateFilter('operators.any'), value: 'or' }
        ];

        function isIntervalOperator(operator) {
            if (typeof operator !== 'undefined') {
                return operator.indexOf('_interval') !== -1;
            }

            return false;
        }

        function isOperatorWithoutValue(operator) {
            if (typeof operator !== 'undefined') {
                return ['today', 'today_without_year', 'empty', 'not_empty'].indexOf(operator) > -1;
            }

            return false;
        }

        function booleanFilter(options) {
            return angular.extend({
                type: 'boolean',
                operator: 'and',
                filters: []
            }, options);
        }

        function comparisonFilter(options) {
            return angular.extend({
                type: 'comparison',
                value: null
            }, options);
        }

        function hasFilter(options) {
            return angular.extend({
                type: 'has',
                operator: '>=',
                count: 1,
                boolean: 'and',
                filters: []
            }, options);
        }

        function clearFilter(filter) {
            for (var key in filter) {
                if (key.indexOf('$$') != 0) { // don't delete Angular properties
                    delete filter[key];
                }
            }
        }

        function deleteFilter(filter, i) {
            filter.filters.splice(i, 1);
        }

        function moveUpFilter(filter, i) {
            if (i > 0) {
                var filterCopy = filter.filters[i];

                filter.filters[i] = filter.filters[i - 1];
                filter.filters[i - 1] = filterCopy;
            }
        }

        function moveDownFilter(filter, i) {
            if (i < filter.filters.length - 1) {
                var filterCopy = filter.filters[i];

                filter.filters[i] = filter.filters[i + 1];
                filter.filters[i + 1] = filterCopy;
            }
        }

        return {
            intervalDirections: intervalDirections,
            intervalTypes: intervalTypes,
            comparisonOperators: comparisonOperators,
            booleanOperators: booleanOperators,
            filterables: filterables,
            booleanFilter: booleanFilter,
            comparisonFilter: comparisonFilter,
            hasFilter: hasFilter,
            clearFilter: clearFilter,
            deleteFilter: deleteFilter,
            moveUpFilter: moveUpFilter,
            moveDownFilter: moveDownFilter,
            isIntervalOperator: isIntervalOperator,
            isOperatorWithoutValue: isOperatorWithoutValue
        };
    }
})(angular);
