(function (angular) {
    angular
        .module('one.admin')
        .controller('HiddenFormStructureController', HiddenFormStructureController);

    HiddenFormStructureController.$inject = ['$scope', 'FormService', 'modal', 'form'];

    function HiddenFormStructureController($scope, FormService, modal, form) {
        init(form);

        $scope.loading = false;

        $scope.disableRow = disableRow;
        $scope.addFieldInNewRow = addFieldInNewRow;
        $scope.addField = addField;
        $scope.disableField = disableField;
        $scope.dragging = dragging;
        $scope.fieldIcon = FormService.fieldIcon;
        $scope.fieldTypeIcon = FormService.fieldTypeIcon;

        function disableRow($index) {
            if ($scope.form.hidden_fields.length == 0) {
                return true;
            }

            if ($scope.dnd.dragging) {
                var dragging = $scope.dnd.dragging;

                if ($index == dragging.rowIndex - 1 || $index == dragging.rowIndex) {
                    return true;
                }
            }

            return false;
        }

        function addFieldInNewRow(index, field) {
            $scope.form.hidden_fields.splice(index, 0, field);

            var hiddenFields = $scope.form.hidden_fields;

            for (var i = 0; i < hiddenFields.length; i++) {
                if (hiddenFields[i].id == hiddenFields[index].id && i != index) {
                    hiddenFields.splice(i, 1);
                    break;
                }
            }

            editFormStructure();

            return true;
        }

        function addField() {
            modal.open('addFormField', 'lg', { form: $scope.form }).result.then(function (data) {
                $scope.form.hidden_fields.push(data.field);
            });
        }

        function disableField(field, index) {
            modal.open('confirm', null, { title: 'Disable this field?', message: 'You will be able to recover it later.' }).result.then(function (data) {
                $scope.form.hidden_fields.splice(index, 1);
                $scope.form.disabled_fields.push(field);

                FormService.disableFormField(field.id);
            });
        }

        function dragging(index) {
            $scope.dnd.dragging = {
                rowIndex: index
            };
        }

        function editFormStructure() {
            $scope.loading = true;

            FormService.editFormStructure($scope.form).then(function (data) {
                init(data.form);

                $scope.loading = false;
            });
        }

        function init(form) {
            $scope.form = form;

            $scope.newRow = [];

            $scope.dnd = {
                dragging: {}
            };
        }
    }
})(angular);
