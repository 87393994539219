(function (angular) {
    angular.module('one.admin').controller('AddGalleryModuleModalController', AddGalleryModuleModalController);

    AddGalleryModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddGalleryModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                items: [],
                layout: 'squares',
                enable_search: false,
                enable_pagination: false,
            }
        };

        angular.merge($scope, params);

        $scope.addGalleryModule = addGalleryModule;

        $scope.cancel = cancel;

        function addGalleryModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
