(function (angular) {
    angular
        .module('one.admin')
        .directive('fileUploader', fileUploaderDirective);

    fileUploaderDirective.$inject = ['config'];

    function fileUploaderDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                model: '=',
                data: '=',
                site: '=',
                accept: '@',
                change: '&',
                callback: '=',
                allowMultiple: '=',
                hidePreview: '=',
                hideImagePreview: '=',
                hideError: '=',
                errors: '='
            },
            controller: ['$scope', '$timeout', 'FileService', 'Upload', 'http', function ($scope, $timeout, FileService, Upload, http) {
                $scope.loading = false;

                $scope.uploads = [];

                $scope.hasModel = $scope.model !== undefined;

                $scope.formatFilename = FileService.formatFilename;

                $scope.isImage = function (file) {
                    var filename = file.name || file.filename;

                    return ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg'].indexOf(filename.split('.').pop().toLowerCase()) > -1;
                };

                $scope.upload = function (files) {
                    $scope.errors = {};

                    if (files && files.length > 0) {
                        $scope.loading = true;

                        var progress = function (evt) {
                            $scope.uploads[this.id].progress = parseInt(100.0 * evt.loaded / evt.total, 10);
                        };

                        var success = function (data, status, headers, config) {
                            $scope.uploads[this.id].file = data;

                            if ($scope.hasModel) {
                                $scope.model = data;
                            }

                            change();

                            $scope.loading = false;
                        };

                        var error = function (data, status, headers, config) {
                            $scope.uploads[this.id].errors = data.errors;

                            $scope.errors[$scope.uploads[this.id].binary.name] = data.errors;

                            $scope.loading = false;
                        };

                        for (var i = 0; i < files.length; i++) {
                            var file = files[i];

                            var id = $scope.uploads.length;

                            $scope.uploads[id] = { binary: file, progress: 0 };

                            var data = angular.merge({
                                user_file: $scope.hasModel ? 0 : 1
                            }, $scope.data);

                            if ($scope.site) {
                                data.site = { id: $scope.site.id };
                            }

                            Upload.upload({
                                url: http.getPath('files/upload'),
                                data: data,
                                file: file
                            })
                                .progress(progress.bind({ id: id }))
                                .success(success.bind({ id: id }))
                                .error(error.bind({ id: id }));
                        }
                    }
                };

                $scope.reset = function () {
                    $scope.uploads = [];

                    if ($scope.hasModel) {
                        $scope.model = null;

                        change();
                    }
                };

                function change() {
                    if ($scope.change) {
                        $timeout(function () {
                            $scope.change();
                        });
                    }

                    if ($scope.callback) {
                        $scope.callback($scope.model);
                    }
                }
            }],
            templateUrl: config.path.template('files.uploader')
        };
    }
}(angular));
