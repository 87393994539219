(function (angular) {
    angular
        .module('one.admin')
        .directive('formButtons', formButtonsDirective);

    formButtonsDirective.$inject = ['config'];

    function formButtonsDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {

        }
        return {
            restrict: 'EA',
            scope: {
                form: '='
            },
            controller: controller,
            templateUrl: config.path.template('common.forms.buttons')
        };
    }
}(angular));
