(function (angular) {
    angular
        .module('one.admin')
        .controller('AddMailTemplateModalController', AddMailTemplateModalController);

    AddMailTemplateModalController.$inject = ['$scope', '$uibModalInstance', 'MailService'];

    function AddMailTemplateModalController($scope, $uibModalInstance, MailService) {
        $scope.template = {
            default: true
        };

        $scope.add = add;
        $scope.cancel = cancel;

        function add() {
            MailService.addMailTemplate($scope.template).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
