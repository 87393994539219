(function (angular) {
    angular
        .module('one.admin')
        .controller('InteractionModalController', InteractionModalController);

    InteractionModalController.$inject = ['$scope', '$uibModalInstance', '$timeout', 'InteractionService', 'config', 'modal', 'params'];

    function InteractionModalController($scope, $uibModalInstance, $timeout, InteractionService, config, modal, params) {
        angular.extend($scope, params);

        $scope.loading = false;

        $scope.displayContactDate = InteractionService.displayContactDate;
        $scope.openAddInteractionModal = openAddInteractionModal;
        $scope.setFinished = setFinished;
        $scope.close = $uibModalInstance.close;

        $scope.attachmentDownloadUrl = function (attachment) {
            return config.path.api('files/' + attachment.id + '/download/' + attachment.client_filename);
        };

        function openAddInteractionModal(size) {
            $scope.close();

            modal.open('addInteraction', size, { interaction: { account: $scope.interaction.account, internal: false } }).result.then(function (data) {
                $state.reload();
            });
        }

        function setFinished(status) {
            if ($scope.loading) {
                return;
            }

            $scope.loading = true;
            $scope.interaction.finished = status;

            InteractionService.setFinished($scope.interaction.id, status).then(function (data) {
                InteractionService.updateBadgeCount();
                $scope.loading = false;
            });
        }
    }
})(angular);
