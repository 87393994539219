(function (angular) {
    angular
        .module('one.admin')
        .controller('EditInteractionSubjectController', EditInteractionSubjectController);

    EditInteractionSubjectController.$inject = ['$scope', '$state', 'InteractionService', 'subject'];

    function EditInteractionSubjectController($scope, $state, InteractionService, subject) {
        $scope.subject = subject;

        $scope.editSubject = editSubject;

        function editSubject() {
            InteractionService.editInteractionSubject($scope.subject).then(function (data) {
                $state.transitionTo('crm.interactions.interactionSubjects.index');
            });
        }
    }
})(angular);
