(function (angular) {
    angular
        .module('one.admin')
        .controller('EditGiftController', EditGiftController);

    EditGiftController.$inject = ['$scope', '$state', 'ActionProjectService', 'ActionCampaignService', 'AccountService', 'GiftService', 'gift'];

    function EditGiftController($scope, $state, ActionProjectService, ActionCampaignService, AccountService, GiftService, gift) {
        $scope.gift = gift;
        $scope.getActionProjects = GiftService.destinationForm.getActionProjects($scope.gift);
        $scope.getActionCampaigns = GiftService.destinationForm.getActionCampaigns($scope.gift);
        $scope.getAccounts = GiftService.destinationForm.getAccounts($scope.gift);
        $scope.getGiftRewards = GiftService.destinationForm.getGiftRewards($scope.gift);
        $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;
        $scope.formatActionCampaignTitle = ActionCampaignService.formatActionCampaignTitle;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.formatGiftRewardTitle = GiftService.formatGiftRewardTitle;
        $scope.handleActionProjectChange = GiftService.destinationForm.handleActionProjectChange;
        $scope.handleActionCampaignChange = GiftService.destinationForm.handleActionCampaignChange;
        $scope.handleAccountChange = GiftService.destinationForm.handleAccountChange;
        $scope.handleGiftRewardChange = GiftService.destinationForm.handleGiftRewardChange;
        $scope.edit = edit;

        function edit(data) {
            GiftService.editGift(data).then(function (data) {
                $state.transitionTo('crowdfunding.gifts.gift', { giftId: gift.id });
            });
        }
    }
})(angular);
