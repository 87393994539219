(function (angular) {
    angular
        .module('one.admin')
        .directive('passwordInput', passwordInputDirective);

    passwordInputDirective.$inject = [];

    function passwordInputDirective() {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                minLength: '@',
                maxLength: '@',
                focusWhen: '='
            },
            link: function (scope, element, attrs, ngModel) {
                scope.$watch('focusWhen', function (newValue, oldValue) {
                    if (newValue) {
                        element[0].focus();
                    }
                }, true);
            }
        };
    }
})(angular);
