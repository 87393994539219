(function (angular) {
    angular
        .module('one.admin')
        .controller('CampaignsController', CampaignsController);

    CampaignsController.$inject = ['$scope', '$state', 'AuthService', 'CampaignService', 'TagService', 'dataframe', 'modal', 'session'];

    function CampaignsController($scope, $state, AuthService, CampaignService, TagService, dataframe, modal, session) {
        $scope.campaigns = dataframe.init({
            externalData: CampaignService.campaigns,
            cols: [
                {
                    title: 'Campaign',
                    name: 'label',
                    display: function (row) {
                        return '<h4><i class="md-icon">email</i> {{ row.label }}</h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Mailings',
                    name: 'mailing_count',
                    display: function (row) {
                        return '{{ row.mailing_count | number }}';
                    },
                    weight: 1
                },
                {
                    title: 'Scheduled',
                    name: 'automatic_mailing_count',
                    display: function (row) {
                        return '{{ row.automatic_mailing_count | number }}';
                    },
                    weight: 1
                },
                {
                    title: 'Deliveries',
                    name: 'delivery_count',
                    display: function (row) {
                        return '{{ row.delivery_count | number }}';
                    },
                    weight: 1
                },
                {
                    title: 'Delivery rate',
                    name: 'delivery_count',
                    display: function (row) {
                        var rate = Math.round(row.delivery_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        var className = rate == 0 ? 'text-muted' : (rate < 98 ? 'text-danger' : (rate < 100 ? 'text-warning' : 'text-success'));

                        return '<span class="' + className + '" uib-tooltip="{{ \'general.deliveries\' | translate }}: ' + row.delivery_count + ', {{ \'general.bounces\' | translate }}: ' + row.failed_count + '">' +
                            '{{ ' + rate + ' | number }}' + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 1,
                    sortable: false
                },
                {
                    title: 'Open rate',
                    name: 'open_count',
                    display: function (row) {
                        var rate = Math.round(row.open_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        var className = rate == 0 ? 'text-muted' : (rate < 50 ? 'text-danger' : (rate < 75 ? 'text-warning' : 'text-success'));

                        return '<span class="' + className + '" uib-tooltip="{{ \'general.opens\' | translate }}: ' + row.open_count + '">' +
                            '{{ ' + rate + ' | number }}' + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 1,
                    sortable: false
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('campaigns.view')) {
                    return 'mail.campaigns.campaign({campaignId: row.id})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'mail.campaigns.editCampaign({campaignId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('campaigns.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.campaigns.loading = true;

                        CampaignService.duplicateCampaign(row).then(function () {
                            $scope.campaigns.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('pages.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        CampaignService.openDeleteCampaignModal(row.id, function () {
                            $scope.campaigns.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('campaigns.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.campaigns', {
                sort: { title: 'asc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.campaigns, 'campaign', session.get('data.tags'));
        }

        session.sync('data.dataframes.campaigns', $scope.campaigns.state);

        $scope.openAddCampaignModal = openAddCampaignModal;

        function openAddCampaignModal(size) {
            modal.open('addCampaign', size).result.then(function (data) {
                $state.transitionTo('mail.campaigns.campaign', { campaignId: data.campaign.id });
            });
        }
    }
})(angular);
