(function (angular) {
    angular
        .module('one.admin')
        .controller('FormEntryController', FormEntryController);

    FormEntryController.$inject = ['$scope', 'FormService', 'modal', 'formEntry'];

    function FormEntryController($scope, FormService, modal, formEntry) {
        $scope.formEntry = formEntry;

        $scope.openSendConfirmationMailModal = openSendConfirmationMailModal;
        $scope.fieldIcon = FormService.fieldIcon;

        function openSendConfirmationMailModal() {
            modal.open('sendFormEntryConfirmationMail', null, { formEntry: $scope.formEntry });
        }
    }
})(angular);
