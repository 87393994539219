(function (angular) {
    angular
        .module('one.admin')
        .service('ActionProjectService', ActionProjectService);

    ActionProjectService.$inject = ['http', 'modal', 'string'];

    function ActionProjectService(http, modal, string) {
        function actionProject(id, params) {
            return http.get('action_projects/' + id, params);
        }

        function actionProjects(params) {
            return http.get('action_projects', params);
        }

        function addActionProject(data) {
            return http.post('action_projects/add', data);
        }

        function deactivateActionProject(id) {
            return http.post('action_projects/' + id + '/deactivate');
        }

        function reactivateActionProject(id) {
            return http.post('action_projects/' + id + '/reactivate');
        }

        function deleteActionProject(id) {
            return http.post('action_projects/' + id + '/delete');
        }

        function editActionProject(data) {
            return http.post('action_projects/' + data.id + '/edit', data);
        }

        function duplicateActionProject(data) {
            return http.post('action_projects/' + data.id + '/duplicate', data);
        }

        function stats(params) {
            return http.get('action_projects/stats', params);
        }

        function gifts(params) {
            return http.get('action_projects/gifts', params);
        }

        function groups(params) {
            return http.get('action_projects/groups', params);
        }

        function openDeactivateActionProjectModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to deactivate this project?', message: 'Deactivating this project will hide it from overviews and the website. You will be able to reactivate it at any time.' }).result.then(function (data) {
                deactivateActionProject(id).then(function (data) {
                    callback(data);
                });
            });
        }

        function openReactivateActionProjectModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to reactivate this project?', message: 'This will cause the project to show up in overviews and on the website again.' }).result.then(function (data) {
                reactivateActionProject(id).then(function (data) {
                    callback(data);
                });
            });
        }

        function openDeleteActionProjectModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to permantenly delete this project?', message: 'Don\'t worry, it does not contain any campaigns or gifts so you can safely delete it.' }).result.then(function (data) {
                deleteActionProject(id).then(function (data) {
                    callback(data);
                });
            });
        }

        function formatActionProjectTitle(actionProject) {
            var output = '';

            if (!actionProject.active) {
                output = output + '<span class="archived">';
            }

            output = output + '<i class="md-icon">favorite_outline</i> ' + string.escape(actionProject.title);

            if (actionProject.tags) {
                output += '<span class="tag-indicators">';

                angular.forEach(actionProject.tags, function (tag) {
                    output += ' <i class="tag-indicator" style="background: ' + tag.color + '"></i>';
                });

                output += '</span>';
            }

            if (!actionProject.active) {
                output = output + '</span>';
            }

            return output;
        }

        return {
            actionProject: actionProject,
            actionProjects: actionProjects,
            addActionProject: addActionProject,
            deactivateActionProject: deactivateActionProject,
            reactivateActionProject: reactivateActionProject,
            deleteActionProject: deleteActionProject,
            duplicateActionProject: duplicateActionProject,
            editActionProject: editActionProject,
            stats: stats,
            gifts: gifts,
            groups: groups,
            openDeactivateActionProjectModal: openDeactivateActionProjectModal,
            openReactivateActionProjectModal: openReactivateActionProjectModal,
            openDeleteActionProjectModal: openDeleteActionProjectModal,
            formatActionProjectTitle: formatActionProjectTitle
        };
    }
})(angular);
