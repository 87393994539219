(function (angular) {
    angular
        .module('one.admin')
        .controller('EditAccountContactUserModalController', EditAccountContactUserModalController);

    EditAccountContactUserModalController.$inject = ['$scope', '$uibModalInstance', 'translateFilter', 'users', 'AccountService', 'AuthService', 'params'];

    function EditAccountContactUserModalController($scope, $uibModalInstance, translateFilter, users, AccountService, AuthService, params) {
        angular.extend($scope, params);

        $scope.users = users;

        $scope.editedAccount = {
            id: $scope.account.id,
            contact_user: $scope.account.contact_user
        };

        $scope.edit = edit;
        $scope.cancel = cancel;

        function edit(data) {
            AccountService.editAccount(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
