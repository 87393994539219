(function (angular) {
    angular
        .module('one.admin')
        .controller('TagsController', TagsController);

    TagsController.$inject = ['$scope', 'AuthService', 'TagService', 'dataframe', 'modal', 'notification', 'session'];

    function TagsController($scope, AuthService, TagService, dataframe, modal, notification, session) {
        $scope.maxTags = TagService.maxTags;

        $scope.tags = dataframe.init({
            externalData: TagService.tags,
            cols: [
                {
                    title: 'Tag',
                    name: 'email',
                    display: function (row) {
                        return '<span tag-indicator tags="[row]"></span> {{ row.title }}';
                    },
                    weight: 1
                },
                {
                    title: 'Times used',
                    name: 'taggable_count',
                    display: function (row) {
                        return '{{ row.taggable_count | number }}';
                    },
                    weight: 1
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('tags.edit')) {
                    return 'tags.editTag({tagId: ' + row.id + '})';
                }
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'tags.editTag({tagId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('tags.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteTagModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('tags.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.tags', {
                sort: { position: 'asc' }
            })
        });

        session.sync('data.dataframes.tags', $scope.tags.state);

        $scope.openAddTagModal = openAddTagModal;
        $scope.openDeleteTagModal = openDeleteTagModal;

        function openAddTagModal(size) {
            modal.open('addTag', size).result.then(function (data) {
                $scope.tags.getData();
            });
        }

        function openDeleteTagModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this tag?' }).result.then(function (data) {
                deleteTag(id);
            });
        }

        function deleteTag(id) {
            TagService.deleteTag(id).then(function (data) {
                $scope.tags.getData();
            }, function (errors) {
                notification.error(errors['tag'][0]);
            });
        }
    }
})(angular);
