(function (angular) {
    angular
        .module('one.admin')
        .directive('auditLogEntryDescription', auditLogEntryDescriptionDirective);

    auditLogEntryDescriptionDirective.$inject = ['config'];

    function auditLogEntryDescriptionDirective(config) {
        controller.$inject = ['$scope', 'AuditService', 'translateFilter'];

        function controller($scope, AuditService, translateFilter) {
            $scope.change = { show: false };

            $scope.EVENTS = AuditService.EVENTS;

            $scope.parseValue = function (value) {
                if (value === true) {
                    return translateFilter('general.yes');
                }

                if (value === false) {
                    return translateFilter('general.no');
                }

                if (angular.isObject(value)) {
                    return "test";
                }

                if (angular.isArray(value)) {
                    return value.map(this.parseValue).join(', ');
                }

                return value;
            };

            $scope.toggle = function () {
                this.change.show = !this.change.show;
            };

            $scope.isEmpty = function (value) {
                return (angular.isArray(value) && value.length == 0) || (!angular.isArray(value) && value === null);
            };
        }

        return {
            restrict: 'AE',
            scope: {
                logEntry: '=',
                modelId: '@',
                modelType: '@'
            },
            controller: controller,
            templateUrl: config.path.template('auditor.log_entry_description')
        };
    }
})(angular);
