(function (angular) {
    angular
        .module('one.admin')
        .controller('GatewayController', GatewayController);

    GatewayController.$inject = ['$scope', '$state', 'AuthService', 'user'];

    function GatewayController($scope, $state, AuthService, user) {
        $scope.user = user;

        $scope.setProject = setProject;

        function setProject(project) {
            AuthService.setProject(project).then(function(data) {
                $state.transitionTo('welcome');
            });
        }
    }
})(angular);
