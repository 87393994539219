(function (angular) {
    angular
        .module('one.admin')
        .service('AccountService', AccountService);

    AccountService.$inject = ['http', 'string', 'translateFilter', 'GroupService'];

    function AccountService(http, string, translateFilter, GroupService) {
        function account(id, params, config) {
            return http.get('accounts/' + id, params, null, config);
        }

        account.activity = function (id, params, config) {
            return http.get('accounts/' + id + '/activity', params, null, config);
        };

        account.attachments = function (id, params) {
            return http.get('accounts/' + id + '/attachments', params);
        };

        account.groups = function (id, params) {
            return http.get('accounts/' + id + '/groups', params);
        };

        account.bankAccounts = function (id, params) {
            return http.get('accounts/' + id + '/bank_accounts', params);
        };

        account.gifts = function (accountId, params) {
            return http.get('accounts/' + accountId + '/gifts', params);
        };

        account.orders = function (id, params) {
            return http.get('accounts/' + id + '/orders', params);
        };

        account.annualPayments = function (id, params) {
            return http.get('accounts/' + id + '/annual_payments', params);
        };

        account.interactions = function (id, params) {
            return http.get('accounts/' + id + '/interactions', params);
        };

        account.duplicates = function (id, params) {
            return http.get('accounts/' + id + '/duplicates', params);
        };

        account.formEntries = function (id, params) {
            return http.get('accounts/' + id + '/form_entries', params);
        };

        account.relations = function (id, params) {
            return http.get('accounts/' + id + '/relations', params);
        };

        account.sentMails = function (id, params) {
            return http.get('accounts/' + id + '/sent_mails', params);
        };

        account.transactions = function (id, params) {
            return http.get('accounts/' + id + '/transactions', params);
        };

        account.transactionStatusDistribution = function (id, params) {
            return http.get('accounts/' + id + '/transaction_status_distribution', params);
        };

        account.variables = function (id, params) {
            return http.get('accounts/' + id + '/variables', params);
        };

        function accounts(params) {
            return http.get('accounts', params);
        }

        function contacts(params) {
            params = params || {};
            params.filters = angular.extend({}, params.filters, { account_types: ['contact'] });

            return accounts(params);
        }

        function teams(params) {
            params = params || {};
            params.filters = angular.extend({}, params.filters, { account_types: ['team'] });

            return accounts(params);
        }

        function organisations(params) {
            params = params || {};
            params.filters = angular.extend({}, params.filters, { account_types: ['organisation'] });

            return accounts(params);
        }

        function genders(params) {
            return http.get('accounts/genders', params);
        }

        function getGeoLocation(params) {
            return http.get('accounts/get_geo_location', params);
        }

        function types(params) {
            return http.get('accounts/types', params);
        }

        function addAccount(data) {
            return http.post('accounts/add', data);
        }

        function deleteAccount(id) {
            return http.post('accounts/' + id + '/delete');
        }

        function editAccount(data) {
            return http.post('accounts/' + data.id + '/edit', data);
        }

        function mergeAccounts(data) {
            return http.post('accounts/merge', data);
        }

        function formatAccountTitle(account) {
            var output = '<i class="md-icon">' + accountIcon(account.account_type_name) + '</i> ';
            output += string.escape(account.title);

            var groups = GroupService.getLeafGroups(account.groups || []);

            angular.forEach(groups, function (group) {
                output += ' <small>' + GroupService.formatGroupTitle(group, true) + '</small>';
            });

            return output;
        }

        function formatName(account) {
            // When initials are known, format as "J. Doe (John)", otherwise "John Doe".
            var formattedName = '';

            var lastName = (account.infix ? account.infix + ' ' : '') + (account.last_name || '');

            if (account.initials) {
                formattedName = account.initials + (lastName ? ' ' + lastName : '');

                if (account.first_name) {
                    formattedName += ' ' + '(' + account.first_name + ')';
                }
            } else if (account.first_name) {
                formattedName = account.first_name + (lastName ? ' ' + lastName : '');
            }

            return formattedName;
        }

        function formatAccountTypeName(type) {
            return '<i class="md-icon">' + accountIcon(type.name) + '</i> ' + translateFilter('account_types.' + type.name);
        }

        function formatGender(gender) {
            return translateFilter('genders.' + gender);
        }

        function accountIcon(type) {
            if (type == 'organisation') {
                return 'business';
            } else if (type == 'team') {
                return 'group';
            }

            return 'person';
        }

        return {
            account: account,
            accounts: accounts,
            contacts: contacts,
            teams: teams,
            organisations: organisations,
            addAccount: addAccount,
            editAccount: editAccount,
            deleteAccount: deleteAccount,
            mergeAccounts: mergeAccounts,
            genders: genders,
            types: types,
            formatAccountTitle: formatAccountTitle,
            formatName: formatName,
            formatGender: formatGender,
            formatAccountTypeName: formatAccountTypeName,
            accountIcon: accountIcon,
            getGeoLocation: getGeoLocation
        };
    }
})(angular);
