(function (angular) {
    angular
        .module('one.admin')
        .service('FinanceDashboardService', FinanceDashboardService);

    FinanceDashboardService.$inject = ['http'];

    function FinanceDashboardService(http) {
        function totals(params) {
            return http.get('finance/totals', params);
        }

        return {
            totals: totals
        };
    }
})(angular);
