(function (angular) {
    angular
        .module('one.admin')
        .filter('nl2br', nl2brFilter);

    nl2brFilter.$inject = [];

    function nl2brFilter() {
        return function (value) {
            value = value + '';

            if (value !== void 0) {
                return value.replace(/\n/g, '<br>');
            }
        };
    }
})(angular);
