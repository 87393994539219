(function (angular) {
    angular
        .module('one.admin')
        .controller('GiftController', GiftController);

    GiftController.$inject = ['$scope', 'AuthService', 'modal', 'gift'];

    function GiftController($scope, AuthService, modal, gift) {
        $scope.gift = gift;

        $scope.shareContactInfo = gift.privacy.indexOf('no_contact') == -1;
        $scope.showAmount = gift.privacy.indexOf('hide_amount') == -1;
        $scope.showName = gift.privacy.indexOf('hide_name') == -1;

        $scope.openAuditorModal = openAuditorModal;

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: gift.id, modelType: 'gift' });
        }
    }
})(angular);
