(function (angular) {
    angular
        .module('one.admin')
        .controller('AddPageBlockController', AddPageBlockController);

    AddPageBlockController.$inject = ['$scope', '$state', 'session', 'PageService', 'languages'];

    function AddPageBlockController($scope, $state, session, PageService, languages) {
        $scope.pageBlock = {
            site: session.get('data.current_site'),
            language: session.get('data.current_site.language')
        };

        $scope.languages = languages;

        $scope.addPageBlock = addPageBlock;

        function addPageBlock(data) {
            PageService.addBlock(data).then(function (data) {
                $state.transitionTo('content.sites.pageBlocks');
            });
        }
    }
})(angular);
