(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountFinancialController', AccountFinancialController);

    AccountFinancialController.$inject = ['$scope', '$filter', 'AccountService', 'AuthService', 'BankAccountService', 'notification', 'modal', 'dataframe', 'account'];

    function AccountFinancialController($scope, $filter, AccountService, AuthService, BankAccountService, notification, modal, dataframe, account) {
        $scope.account = account;

        $scope.bankAccounts = dataframe.init({
            externalData: function (params) {
                return AccountService.account.bankAccounts($scope.account.id, params);
            },
            cols: [
                {
                    name: 'iban',
                    title: 'IBAN',
                    display: function (row) {
                        return '<h4>' + (row.default ? '<i class="md-icon">check</i>' : '') + ' {{ row.iban }}</h4>';
                    },
                    weight: 1
                },
                {
                    name: 'bic',
                    title: 'BIC',
                    weight: 1
                },
                {
                    name: 'account_holder',
                    title: 'Account holder',
                    weight: 1
                }
            ],
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'finance.bankAccounts.editBankAccount({bankAccountId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('bank_accounts.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteBankAccountModal(row.id);
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('bank_accounts.delete');
                    }
                }
            ],
            state: {
                perPage: 5,
                sort: { id: 'desc' }
            }
        });

        $scope.transactions = dataframe.init({
            externalData: function getTransactions(params) {
                return AccountService.account.transactions($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Transaction',
                    name: 'id',
                    display: function (row) {
                        return '<span transaction-label transaction="row"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Amount',
                    name: 'amount',
                    weight: 1,
                    display: function (row) {
                        return '<h4>' + $filter('money')(row.failed ? row.cancelled_gross_amount : (row.refunded ? row.refunded_gross_amount : row.gross_amount), '&euro; ', 2) + '</h4>';
                    }
                },
                {
                    title: 'Status',
                    name: 'status',
                    weight: 1,
                    display: function (row) {
                        return $filter('translate')('transaction_statuses.' + row.status);
                    }
                },
                {
                    title: 'Payment method',
                    name: 'order.payment_method.title',
                    weight: 1
                },
                {
                    title: 'Collect date',
                    name: 'collect_date',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.collect_date);
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('transactions.view')) {
                    return 'finance.transactions.transaction({transactionId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'finance.transactions.editTransaction({transactionId:' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('transactions.edit');
                    }
                }
            ],
            state: {
                perPage: 5,
                sort: { created_at: 'desc' }
            }
        });

        AccountService.account.transactionStatusDistribution(account.id).then(function (distribution) {
            $scope.transactionStatusDistribution = distribution;
        });

        $scope.openAddBankAccountModal = openAddBankAccountModal;

        function openAddBankAccountModal(size) {
            modal.open('addBankAccount', size, { bankAccount: { account: account } }).result.then(function (data) {
                $scope.bankAccounts.getData();
            });
        }

        function openDeleteBankAccountModal(id, size) {
            modal.open('confirm', size, {
                title: 'Are you sure?',
                message: 'Delete this bank account?'
            }).result.then(function (data) {
                BankAccountService.deleteBankAccount(id).then(function (data) {
                    $scope.bankAccounts.getData();
                }, function (errors) {
                    notification.error(errors['bank_account'][0]);
                });
            });
        }
    }
})(angular);
