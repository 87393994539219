(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeCell', oneDataframeCellDirective);

    oneDataframeCellDirective.$inject = ['$compile', 'config'];

    function oneDataframeCellDirective($compile, config) {
        return {
            restrict: 'AE',
            scope: {
                value: '=',
                row: '=',
                options: '='
            },
            link: function (scope, element) {
                scope.options = scope.options || {};

                element.addClass('one-dataframe-cell');

                if (scope.options.stickRight) {
                    element.addClass('one-dataframe-cell-right');
                }

                element.append($compile('<div class="one-dataframe-cell-inner">' + (scope.value !== null ? scope.value : '&nbsp;') + '</div>')(scope));
            },
            templateUrl: config.path.template('common.dataframe.cell')
        };
    }
})(angular);
