(function (angular) {
    angular
        .module('one.admin')
        .filter('formatDate', formatDateFilter);

    formatDateFilter.$inject = ['dateFilter'];

    function formatDateFilter(dateFilter) {
        return function (value, scope) {
            value = value || '';

            if (value.length > 10) {
                // String is probably in yyyy-mm-dd hh:ii:ss format, so put a 'T' between date and
                // time to prevent invalid dates.
                value = value.substr(0, 10) + 'T' + value.substr(11);
            }

            return dateFilter(value, scope);
        };
    }
})(angular);
