(function (angular) {
    angular
        .module('one.admin')
        .directive('personalMailForm', personalMailFormDirective);

    personalMailFormDirective.$inject = ['config'];

    function personalMailFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                attachments: '=',
                mail: '=',
                senders: '=',
                templates: '='
            },
            controller: ['$scope', 'MailService', 'PersonalMailService', function ($scope, MailService, PersonalMailService) {
                $scope.showAttachments = false;
                $scope.to = {};
                $scope.variables = PersonalMailService.getVariables();

                $scope.addAttachment = addAttachment;
                $scope.hasAttachment = hasAttachment;
                $scope.formatMailSenderTitle = MailService.formatMailSenderTitle;
                $scope.formatMailTemplateTitle = MailService.formatMailTemplateTitle;

                function addAttachment(file) {
                    if (file && !hasAttachment(file)) {
                        $scope.mail.attachments.push({ attachment: file });
                    }
                }

                function hasAttachment(file) {
                    var result = false;

                    angular.forEach($scope.mail.attachments, function (personalMailFile) {
                        if (file.id == personalMailFile.attachment.id) {
                            result = true;
                        }
                    });

                    return result;
                }

            }],
            templateUrl: config.path.template('personal_mails.form')
        };
    }
}(angular));
