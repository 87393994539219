(function (angular) {
    angular
        .module('one.admin')
        .service('ActionCampaignService', ActionCampaignService);

    ActionCampaignService.$inject = ['$filter', 'http', 'modal', 'string', 'translateFilter'];

    function ActionCampaignService($filter, http, modal, string, translateFilter) {
        function actionCampaign(id, params) {
            return http.get('action_campaigns/' + id, params);
        }

        function actionCampaigns(params) {
            return http.get('action_campaigns', params);
        }

        function fields(params) {
            return http.get('action_campaigns/fields', params);
        }

        function sortOptions() {
            return http.get('action_campaigns/sort_options');
        }

        function types(params) {
            return http.get('action_campaigns/types', params);
        }

        function addActionCampaign(data) {
            return http.post('action_campaigns/add', data);
        }

        function deactivateActionCampaign(id) {
            return http.post('action_campaigns/' + id + '/deactivate');
        }

        function reactivateActionCampaign(id) {
            return http.post('action_campaigns/' + id + '/reactivate');
        }

        function deleteActionCampaign(id) {
            return http.post('action_campaigns/' + id + '/delete');
        }

        function editActionCampaign(data) {
            return http.post('action_campaigns/' + data.id + '/edit', data);
        }

        function mergeActionCampaigns(data) {
            return http.post('action_campaigns/merge', data);
        }

        function openDeactivateActionCampaignModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to deactivate this campaign?', message: 'Deactivating this campaign will hide it from website. You will be able to reactivate it at any time.' }).result.then(function (data) {
                deactivateActionCampaign(id).then(callback);
            });
        }

        function openReactivateActionCampaignModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to reactivate this campaign?', message: 'Reactivating this campaign will show it on website.' }).result.then(function (data) {
                reactivateActionCampaign(id).then(callback);
            });
        }

        function openDeleteActionCampaignModal(id, callback) {
            modal.open('confirm', null, { title: 'Do you want to permanently delete this campaign?', message: 'This action will permanently delete the campaign. Gifts to this campaign will be moved to the campaign\'s project. <strong class="text-danger">This action cannot be undone.</strong> If you wish to hide the campaign from overviews and the website, but keep the data, you should deactivate it instead.' }).result.then(function (data) {
                deleteActionCampaign(id).then(callback);
            });
        }

        function formatActionCampaignTitle(actionCampaign) {
            var output = '';

            if (!actionCampaign.active) {
                output += '<span class="archived">';
            }

            output += '<i class="md-icon">group_work</i> ' + string.escape(actionCampaign.prefilled_title || actionCampaign.title);
            output += ' <span class="text-muted">('
            output += translateFilter('general.' + actionCampaign.type);

            if (actionCampaign.action_project) {
                output += ' • <i class="md-icon">favorite_outline</i> ' + string.escape(actionCampaign.action_project.title);
            }

            output += ')</span>';

            if (!actionCampaign.active) {
                output += '</span>';
            }

            return output;
        }

        function formatActionCampaignType(type) {
            return '<i class="md-icon">' + typeIcons[type] + '</i> ' + $filter('translate')('action_campaign_types.' + type);
        }

        var typeIcons = {
            individual: 'person',
            team: 'people',
            open: 'public'
        };

        return {
            actionCampaign: actionCampaign,
            actionCampaigns: actionCampaigns,
            fields: fields,
            sortOptions: sortOptions,
            types: types,
            addActionCampaign: addActionCampaign,
            deactivateActionCampaign: deactivateActionCampaign,
            reactivateActionCampaign: reactivateActionCampaign,
            deleteActionCampaign: deleteActionCampaign,
            editActionCampaign: editActionCampaign,
            mergeActionCampaigns: mergeActionCampaigns,
            openDeactivateActionCampaignModal: openDeactivateActionCampaignModal,
            openReactivateActionCampaignModal: openReactivateActionCampaignModal,
            openDeleteActionCampaignModal: openDeleteActionCampaignModal,
            formatActionCampaignTitle: formatActionCampaignTitle,
            formatActionCampaignType: formatActionCampaignType,
            typeIcons: typeIcons,
        };
    }
})(angular);
