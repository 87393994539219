(function (angular) {
    angular
        .module('one.admin.dependencies', [
            'ui.router',
            'ui.codemirror',
            'ui.bootstrap',
            'pascalprecht.translate',
            'angular-loading-bar',
            'angularMoment',
            'ngFileUpload',
            'ngSanitize',
            'monospaced.elastic',
            'checklist-model',
            'chart.js',
            'froala',
            'duScroll',
            'tandibar/ng-rollbar',
            'dndLists',
            'ngCookies',
            'colorpicker.module'
        ]);
})(angular);
