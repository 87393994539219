(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountGiftsController', AccountGiftsController);

    AccountGiftsController.$inject = ['$scope', 'AccountService', 'account'];

    function AccountGiftsController($scope, AccountService, account) {
        $scope.account = account;

        $scope.getGifts = getGifts;

        function getGifts(params) {
            return AccountService.account.gifts($scope.account.id, params);
        }
    }
})(angular);
