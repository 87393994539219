(function (angular) {
    angular
        .module('one.admin')
        .controller('ExportsController', ExportsController);

    ExportsController.$inject = ['$scope', '$timeout', 'session', 'AuthService', 'ExportService', 'config', 'dataframe', 'modal', 'notification'];

    function ExportsController($scope, $timeout, session, AuthService, ExportService, config, dataframe, modal, notification) {
        var currentTimeout = 1000;
        var maxTimeout = 10000;
        var timer;

        refresh();

        $scope.$on('$destroy', function () {
            $timeout.cancel(timer);
        });

        $scope.exports = dataframe.init({
            externalData: function (params) {
                params = params || {};
                params.filters = params.filters || {};
                params.filters.scheduled = [false];

                return ExportService.exports(params);
            },
            cols: [
                {
                    title: 'Export',
                    name: 'title',
                    display: function (row) {
                        if (row.status != 'ready') {
                            row.active = false;
                        }

                        return '<h4><span class="loader loader-sm" ng-if="row.status != \'ready\'"></span> {{ row.title }} <small class="text-muted" ng-if="(\'export_scopes.\' + row.type | translate) != row.title">({{ \'export_scopes.\' + row.type | translate }})</small></h4> <small>' + ExportService.formatFilters(row) + '</small>';
                    },
                    sortable: true,
                    weight: 3
                },
                {
                    title: 'Size',
                    name: 'row_count',
                    display: function (row) {
                        return '{{ row.row_count | number }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Generated',
                    name: 'generated_at',
                    display: function (row) {
                        return '<small>{{ row.generated_at | formatDate: \'medium\' }}</small>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Requested by',
                    name: 'user.full_name',
                    display: function (row) {
                        return '<small class="one-user" ng-if="row.user"><i class="md-icon">account_circle</i> {{ row.user.first_name }}</small>'
                    },
                    weight: 1
                }
            ],
            rowHref: function (row) {
                if (AuthService.userCanAccess('exports.download') && row.downloadable) {
                    return config.path.api('exports/' + row.id + '/download');
                }

                return false;
            },
            rowOptions: [
                {
                    title: 'Download',
                    icon: 'file_download',
                    href: function (row) {
                        return config.path.api('exports/' + row.id + '/download');
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('exports.download') && row.downloadable;
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteExportModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('exports.delete') && row.deletable;
                    }
                }
            ],
            state: session.get('data.dataframes.exports', {
                sort: { id: 'desc' }
            })
        });

        session.sync('data.dataframes.exports', $scope.exports.state);

        $scope.openDeleteExportModal = openDeleteExportModal;

        function openDeleteExportModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this export?' }).result.then(function (data) {
                deleteExport(id);
            });
        }

        function deleteExport(id) {
            ExportService.deleteExport(id).then(function (data) {
                $scope.exports.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }

        function refresh() {
            timer = $timeout(function () {
                $scope.exports.getData();
                $scope.exports.loading = false;

                currentTimeout += 2000;

                refresh();
            }, Math.min(currentTimeout, maxTimeout));
        }
    }
})(angular);
