(function (angular) {
    angular
        .module('one.admin')
        .controller('AddMailingModalController', AddMailingModalController);

    AddMailingModalController.$inject = ['$scope', '$uibModalInstance', 'MailingService', 'params'];

    function AddMailingModalController($scope, $uibModalInstance, MailingService, params) {
        $scope.mailing = {
            campaign: params.campaign
        };

        $scope.add = add;
        $scope.cancel = cancel;

        function add(data) {
            MailingService.addMailing(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
