(function (angular) {
    angular
        .module('one.admin')
        .directive('dateInput', dateInputDirective);

    dateInputDirective.$inject = ['$locale', 'dateFilter', 'moment'];

    function dateInputDirective($locale, dateFilter, moment) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                var parsedDateFormat = $locale.DATETIME_FORMATS.parsedDate;
                var formattedDateFormat = $locale.DATETIME_FORMATS.userInputDate;

                scope.datepickerFormat = formattedDateFormat;

                ngModel.$parsers = [parseValue];
                ngModel.$formatters = [formatValue];

                ngModel.$validators.date = validateDate;

                element.on('blur', updateViewValue);

                function parseValue(value) {
                    return value ? moment(value, formattedDateFormat).format(parsedDateFormat) : null;
                }

                function formatValue(value) {
                    return value ? moment(value, parsedDateFormat).format(formattedDateFormat) : null;
                }

                function validateDate(modelValue, viewValue) {
                    return ngModel.$isEmpty(viewValue) || moment(modelValue, parsedDateFormat).isValid();
                }

                function updateViewValue() {
                    if (ngModel.$valid) {
                        ngModel.$setViewValue(formatValue(ngModel.$modelValue));
                        ngModel.$render();
                    }
                }
            }
        };
    }
})(angular);
