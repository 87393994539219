(function (angular) {
    angular
        .module('one.admin')
        .factory('FormHelper', FormHelper);

    FormHelper.$inject = [];

    function FormHelper() {
        function getFormState(options) {
            return {
                data: options.data,
                initialData: angular.copy(options.data),
                errors: null,
                loading: false,
                submit: function () {
                    var self = this;
                    var currentData = this.data;

                    self.errors = null
                    self.loading = true;

                    options.submit(this.data).then(function (data) {
                        self.initialData = angular.copy(currentData);
                        self.loading = false;

                        if (options.success) {
                            options.success(data);
                        }

                        return data;
                    }, function (data) {
                        self.errors = data.errors;
                        self.loading = false;

                        if (options.error) {
                            options.error(data);
                        }
                    });
                },
                hasChanges: function () {
                    return !angular.equals(this.data, this.initialData);
                },
                cancel: function () {
                    this.data = angular.copy(this.initialData);
                    this.errors = null;
                }
            }
        }

        return {
            getFormState: getFormState
        };
    }
})(angular);
