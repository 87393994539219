(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderSentMailFilterBlock', segmentBuilderSentMailFilterBlock);

    segmentBuilderSentMailFilterBlock.$inject = ['config'];

    function segmentBuilderSentMailFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                filterBlock: '=',
                segment: '='
            },
            controller: ['$scope', 'segmentBuilder', function controller($scope, segmentBuilder) {
                $scope.mailFilterables = null;
                $scope.clicksFilterables = null;
                $scope.selected = {};

                $scope.mailFilterBlock = $scope.filterBlock.filters[0];
                $scope.clicksFilterBlock = $scope.filterBlock.filters[1];

                $scope.clicks = {
                    active: !!$scope.clicksFilterBlock
                };

                $scope.recoveredClicksFilterBlock = null;

                getFilterables();

                $scope.deleteFilterBlock = deleteFilterBlock;
                $scope.addMailFilter = addMailFilter;
                $scope.addClicksFilter = addClicksFilter;
                $scope.toggleClicksFilterBlock = toggleClicksFilterBlock;

                /**
                 * Resets the filter block and filterables.
                 */
                function deleteFilterBlock() {
                    $scope.filterBlock = null;
                    $scope.mailFilterables = null;
                    $scope.clicksFilterables = null;
                }

                /**
                 * Adds a comparison filter to the mail filter block.
                 */
                function addMailFilter() {
                    $scope.mailFilterBlock.filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Adds a comparison filter to the clicks filter block.
                 */
                function addClicksFilter() {
                    $scope.clicksFilterBlock.filters.push(segmentBuilder.comparisonFilter());
                }

                /**
                 * Fetches the fields that can be filtered on.
                 */
                function getFilterables() {
                    segmentBuilder.filterables.sentMail().then(function (filterables) {
                        $scope.mailFilterables = filterables;
                    });

                    segmentBuilder.filterables.sentMailClick().then(function (filterables) {
                        $scope.clicksFilterables = filterables;
                    });
                }

                function toggleClicksFilterBlock() {
                    if ($scope.filterBlock.filters.length == 1) {
                        $scope.filterBlock.filters.push($scope.recoveredClicksFilterBlock || segmentBuilder.hasFilter({
                            relation: 'clicks',
                            boolean: 'and',
                        }));

                        $scope.clicksFilterBlock = $scope.filterBlock.filters[1];
                    } else if ($scope.filterBlock.filters.length == 2) {
                        $scope.recoveredClicksFilterBlock = $scope.filterBlock.filters.splice(-1)[0];

                        $scope.clicksFilterBlock = null;
                    }
                }
            }],
            templateUrl: config.path.template('segments.builder.sent_mail_filter_block')
        };
    }
}(angular));
