(function (angular) {
    angular
        .module('one.admin')
        .controller('AddActionProjectController', AddActionProjectController);

    AddActionProjectController.$inject = ['$scope', '$state', 'ActionProjectService', 'sites', 'types', 'fields'];

    function AddActionProjectController($scope, $state, ActionProjectService, sites, types, fields) {
        $scope.sites = sites;
        $scope.types = types;
        $scope.fields = fields;

        $scope.actionProject = {
            image: null,
            groups: [],
            gift_product: null,
            gift_mail: null,
            sponsor_group: null,
            payment_method: null,
            quick_donation_amount: 2500,
            site: sites.length == 1 ? sites[0] : null,
            types: [],
            fields: {
                individual: [],
                team: [],
                open: [],
            }
        };

        $scope.add = add;

        function add(data) {
            ActionProjectService.addActionProject(data).then(function (data) {
                $state.transitionTo('crowdfunding.actionProjects.actionProject', { actionProjectId: data.actionProject.id });
            });
        }
    }
})(angular);
