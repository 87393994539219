(function (angular) {
    angular
        .module('one.admin')
        .controller('AddGroupMembershipModalController', AddGroupMembershipModalController);

    AddGroupMembershipModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'GroupService', 'params'];

    function AddGroupMembershipModalController($scope, $uibModalInstance, AccountService, GroupService, params) {
        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.membership = {};

        angular.extend($scope, params);

        $scope.showGroupField = !$scope.membership.group;
        $scope.showAccountField = !$scope.membership.account;
        $scope.getGroups = GroupService.groups;
        $scope.formatGroupTitle = GroupService.formatGroupTitle;

        $scope.formState = {
            addGroup: false
        };

        $scope.add = add;
        $scope.cancel = cancel;

        function add(data) {
            GroupService.addGroupMembership(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
