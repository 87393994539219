(function (angular) {
    angular
        .module('one.admin')
        .controller('CancelPurchasePaymentTermModalController', CancelPurchasePaymentTermModalController);

    CancelPurchasePaymentTermModalController.$inject = ['$scope', '$uibModalInstance', 'PurchaseService', 'notification', 'params'];

    function CancelPurchasePaymentTermModalController($scope, $uibModalInstance, PurchaseService, notification, params) {
        $scope.paymentTerm = params.paymentTerm;

        $scope.close = $uibModalInstance.close;
        $scope.cancelPaymentTerm = cancelPaymentTerm;

        function cancelPaymentTerm() {
            PurchaseService.cancelPaymentTerm($scope.paymentTerm.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
