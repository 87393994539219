(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['cfpLoadingBarProvider'];

    function config(cfpLoadingBarProvider) {
        cfpLoadingBarProvider.latencyThreshold = 100;
        cfpLoadingBarProvider.includeSpinner = false;
    }
})(angular);
