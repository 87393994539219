(function (angular) {
    angular
        .module('one.admin')
        .controller('EditTransactionController', EditTransactionController);

    EditTransactionController.$inject = ['$scope', '$state','TransactionService', 'transaction'];

    function EditTransactionController($scope, $state,TransactionService, transaction) {
        $scope.transaction = transaction;

        $scope.edit = edit;

        function edit(data) {
            TransactionService.editTransaction(data).then(function (data) {
                if ($scope.previousState.name) {
                    $state.transitionTo($scope.previousState, $scope.previousStateParams);
                } else {
                    $state.transitionTo('finance.transactions.transaction', {transactionId: data.transaction.id});
                }
            });
        }
    }
})(angular);
