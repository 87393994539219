(function (angular) {
    angular
        .module('one.admin')
        .service('AuthRoleService', AuthRoleService);

    AuthRoleService.$inject = ['http', 'modal', 'translateFilter'];

    function AuthRoleService(http, modal, translateFilter) {
        function authRoles(params) {
            return http.get('auth_roles', params);
        }

        return {
            authRoles: authRoles
        };
    }
})(angular);
