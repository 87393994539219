(function (angular) {
    angular
        .module('one.admin')
        .directive('moneyInput', moneyInputDirective);

    moneyInputDirective.$inject = [];

    function moneyInputDirective() {
        return {
            restrict: 'AE',
            scope: {
                ngModel: '=',
                config: '=',
                focusWhen: '='
            },
            require: 'ngModel',
            link: function (scope, element, attributes, ngModelController) {
                var config = angular.merge(
                    {
                        delimiter: ','
                    },
                    scope.config
                );

                ngModelController.$parsers.push(parseValue);
                ngModelController.$formatters.push(formatValue);

                element.on('blur', function () {
                    if (ngModelController.$valid) {
                        ngModelController.$setViewValue(formatValue(ngModelController.$modelValue));
                        ngModelController.$render();
                    }
                });

                scope.$watch('focusWhen', function (newValue, oldValue) {
                    if (newValue) {
                        element[0].focus();
                    }
                }, true);

                function formatValue(value) {
                    if (ngModelController.$isEmpty(value)) {
                        return '';
                    }

                    value = value || 0;

                    var numerals = Math.floor(value / 100);
                    var decimals = value % 100;

                    return numerals + config.delimiter + (decimals === 0 ? '00' : (decimals < 10 ? '0' + decimals : decimals));
                }

                function parseValue(value) {
                    if (ngModelController.$isEmpty(value) || isNaN(parseInt(value, 10))) {
                        return null;
                    }

                    value = value + '';

                    value = parseFloat(value.replace(',', '.'));

                    value = parseInt(Math.round(value * 100)) || 0;

                    if ('min' in config) {
                        value = Math.max(config.min, value);
                    }

                    if ('max' in config) {
                        value = Math.min(config.max, value);
                    }

                    return value;
                }
            }
        };
    }
})(angular);
