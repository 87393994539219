(function (angular) {
    angular
        .module('one.admin')
        .controller('DeleteAccountModalController', DeleteAccountModalController);

    DeleteAccountModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'notification', 'translateFilter', 'params'];

    function DeleteAccountModalController($scope, $uibModalInstance, AccountService, notification, translateFilter, params) {
        angular.merge($scope, params);

        $scope.confirmationString = translateFilter('general.delete_account_confirmation_string');

        $scope.close = $uibModalInstance.close;
        $scope.deleteAccount = deleteAccount;

        function deleteAccount(account) {
            AccountService.deleteAccount(account.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
