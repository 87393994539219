(function (angular) {
    angular
        .module('one.admin')
        .service('EventService', EventService);

    EventService.$inject = ['$filter', 'http'];

    function EventService($filter, http) {
        function event(id) {
            return http.get('events/' + id);
        }

        function events(params) {
            return http.get('events', params);
        }

        function addEvent(data) {
            return http.post('events/add', data);
        }

        function deleteEvent(id) {
            return http.post('events/' + id + '/delete');
        }

        function editEvent(data) {
            return http.post('events/' + data.id + '/edit', data);
        }

        function duplicateEvent(data) {
            return http.post('events/' + data.id + '/duplicate', data);
        }

        function formatTicketPrice(ticket) {
            var amount, min, max;

            for (var i = 0; i < ticket.products.length; i++) {
                amount = ticket && (ticket.products[i].gross_amount || ticket.products[i].amount) || 0;

                if (typeof min === 'undefined' || amount < min) {
                    min = amount;
                }

                if (typeof max === 'undefined' || amount > max) {
                    max = amount;
                }
            }

            if (min === max) {
                return $filter('money')(min);
            }

            return $filter('money')(min) + ' - ' + $filter('money')(max, ' ');
        }

        return {
            event: event,
            events: events,
            addEvent: addEvent,
            deleteEvent: deleteEvent,
            duplicateEvent: duplicateEvent,
            editEvent: editEvent,
            formatTicketPrice: formatTicketPrice,
        };
    }
})(angular);
