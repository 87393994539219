(function (angular) {
    angular
        .module('one.admin')
        .directive('modulePages', modulePagesDirective);

    modulePagesDirective.$inject = ['config'];

    function modulePagesDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '=',
            },
            templateUrl: config.path.template('modules.pages')
        };
    }
}(angular));
