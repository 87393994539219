(function (angular) {
    angular
        .module('one.admin')
        .controller('MailSendersController', MailSendersController);

    MailSendersController.$inject = ['$scope', 'AuthService', 'MailService', 'TagService', 'dataframe', 'modal', 'session'];

    function MailSendersController($scope, AuthService, MailService, TagService, dataframe, modal, session) {
        $scope.senders = dataframe.init({
            externalData: MailService.senders,
            cols: [
                {
                    title: 'Name',
                    name: 'name',
                    display: function (row) {
                        return '<h4><i class="md-icon">contact_mail</i> {{ row.name }} <i class="md-icon text-muted" uib-tooltip="{{ \'general.default\' | translate }}" ng-if="row.primary">check</i></h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'E-mail',
                    name: 'email',
                    weight: 3
                },
                {
                    title: 'Description',
                    name: 'description',
                    display: function (row) {
                        return '<span class="text-limiter">{{ row.description }}</span>';
                    },
                    weight: 2
                },
                {
                    title: 'Internal user',
                    name: 'contact_user.full_name',
                    display: function (row) {
                        return '<span class="one-user" ng-if="row.contact_user"><i class="md-icon">account_circle</i> {{ row.contact_user.first_name }}</span>';
                    },
                    weight: 2
                },
                {
                    title: 'Mails',
                    name: 'mail_count',
                    display: function (row) {
                        return '{{ row.mail_count | number }}';
                    },
                    weight: 1
                },
            ],
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'mail.senders.editMailSender({mailSenderId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('mail_senders.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.senders.loading = true;

                        MailService.duplicateMailSender(row).then(function () {
                            $scope.senders.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('mail_senders.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteMailSenderModal(row.id);
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('mail_senders.delete');
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('mail_senders.edit')) {
                    return 'mail.senders.editMailSender({mailSenderId: ' + row.id + '})';
                }
                return false;
            },
            state: session.get('data.dataframes.senders', {
                sort: { name: 'asc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.senders, 'mail_sender', session.get('data.tags'));
        }

        session.sync('data.dataframes.senders', $scope.senders.state);

        $scope.openAddMailSenderModal = openAddMailSenderModal;
        $scope.openDeleteMailSenderModal = openDeleteMailSenderModal;

        function openAddMailSenderModal(size) {
            modal.open('addMailSender', size).result.then(function (data) {
                $scope.senders.getData();
            });
        }

        function openDeleteMailSenderModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this mail sender?' }).result.then(function (data) {
                deleteMailSender(id);
            });
        }

        function deleteMailSender(id) {
            MailService.deleteMailSender(id).then(function (data) {
                $scope.senders.getData();
            });
        }
    }
})(angular);
