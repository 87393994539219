(function (angular) {
    angular
        .module('one.admin')
        .service('PaymentMethodService', PaymentMethodService);

    PaymentMethodService.$inject = ['http'];

    function PaymentMethodService(http) {
        function paymentMethods(params) {
            return http.get('payment_methods', params);
        }

        function idealPaymentMethods(params) {
            return http.get('payment_methods/ideal', params);
        }

        function invoicePaymentMethods(params) {
            return http.get('payment_methods/invoice', params);
        }

        function sddPaymentMethods(params) {
            return http.get('payment_methods/sdd', params);
        }

        return {
            paymentMethods: paymentMethods,
            idealPaymentMethods: idealPaymentMethods,
            invoicePaymentMethods: invoicePaymentMethods,
            sddPaymentMethods: sddPaymentMethods
        };
    }
})(angular);
