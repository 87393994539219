(function (angular) {
    angular
        .module('one.admin')
        .controller('LogoutController', LogoutController);

    LogoutController.$inject = ['$scope', '$state', 'AuthService'];

    function LogoutController($scope, $state, AuthService) {
        AuthService.logout().then(function() {
            $state.transitionTo('login');
        });
    }
})(angular);
