(function (angular) {
    angular
        .module('one.admin')
        .directive('ipLocationForm', ipLocationFormDirective);

    ipLocationFormDirective.$inject = ['config'];

    function ipLocationFormDirective(config) {
        controller.$inject = ['$scope', 'AuthService'];

        function controller($scope, AuthService) {
            $scope.currentIp = null;

            AuthService.clientInfo().then(function (data) {
                $scope.currentIp = data.ip_address;
            });
        }

        return {
            restrict: 'EA',
            controller: controller,
            scope: {
                ipLocation: '='
            },
            templateUrl: config.path.template('ip_locations.form')
        };
    }
}(angular));
