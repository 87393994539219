(function (angular) {
    angular
        .module('one.admin')
        .controller('SendPersonalMailModalController', SendPersonalMailModalController);

    SendPersonalMailModalController.$inject = ['$scope', '$state', '$uibModalInstance', 'translateFilter', 'session', 'PersonalMailService', 'attachments', 'senders', 'templates', 'params'];

    function SendPersonalMailModalController($scope, $state, $uibModalInstance, translateFilter, session, PersonalMailService, attachments, senders, templates, params) {
        $scope.attachments = attachments;
        $scope.senders = senders;
        $scope.templates = templates;

        $scope.mail = {
            account: {},
            sender: null,
            template: templates[0],
            subject: '',
            body: '',
            attachments: []
        };

        angular.extend($scope.mail, params.mail);

        // By default, select the account's contact person as sender.
        // If not set, select the logged in user as sender.
        // If none of above, use the first sender.
        var contactUser = $scope.mail.account.contact_user;

        if (contactUser) {
            angular.forEach(senders, function (sender) {
                if (sender.contact_user_id == contactUser.id) {
                    sender.title = sender.title + " — " + translateFilter('general.contact_person');

                    $scope.mail.sender = sender;
                }
            });
        }

        if (!$scope.mail.sender) {
            var user = session.get('user');

            angular.forEach(senders, function (sender) {
                if (sender.contact_user_id == user.id) {
                    $scope.mail.sender = sender;
                }
            });
        }

        if (!$scope.mail.sender) {
            $scope.mail.sender = senders[0];
        }

        $scope.continueEnterEmail = continueEnterEmail;
        $scope.sendPersonalMail = sendPersonalMail;
        $scope.cancel = cancel;

        function continueEnterEmail() {
            $state.transitionTo("crm.accounts.account.index", {accountId: $scope.mail.account.id});

            $uibModalInstance.close();
        }

        function sendPersonalMail(data) {
            PersonalMailService.sendPersonalMail(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
