(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountsController', AccountsController);

    AccountsController.$inject = ['$scope', 'AccountService', 'modal', 'types'];

    function AccountsController($scope, AccountService, modal, types) {
        $scope.types = types;

        $scope.getAccounts = AccountService.accounts;
        $scope.openAddAccountModal = openAddAccountModal;

        function openAddAccountModal(type, size) {
            modal.open('addAccount', size, { name: type.name });
        }
    }
})(angular);
