(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFormPageModalController', AddFormPageModalController);

    AddFormPageModalController.$inject = ['$scope', '$uibModalInstance', 'FormService', 'params'];

    function AddFormPageModalController($scope, $uibModalInstance, FormService, params) {
        $scope.form = params.form;

        $scope.page = {
            form: $scope.form
        };

        $scope.add = function () {
            FormService.addFormPage($scope.form.id, $scope.page).then(function (data) {
                $uibModalInstance.close(data);
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
