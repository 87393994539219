(function (angular) {
    angular
        .module('one.admin')
        .controller('MailingSegmentController', MailingSegmentController);

    MailingSegmentController.$inject = ['$scope', '$state', 'SegmentService', 'mailing', 'segment'];

    function MailingSegmentController($scope, $state, SegmentService, mailing, segment) {
        $scope.mailing = mailing;
        $scope.segment = segment;

        $scope.getAccounts = getAccounts;
        $scope.editSegment = editSegment;

        function getAccounts(params) {
            return SegmentService.accounts($scope.segment.id, params);
        }

        function editSegment() {
            SegmentService.editSegment($scope.segment).then(function () {
                $state.transitionTo('mail.campaigns.mailings.mailing', {
                    campaignId: $scope.mailing.campaign.id,
                    mailingId: $scope.mailing.id,
                    editing: 'recipients',
                });
            });
        }
    }
})(angular);
