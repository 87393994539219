(function (angular) {
    angular
        .module('one.admin')
        .service('HtmlService', HtmlService);

    HtmlService.$inject = ['config'];

    function HtmlService(http, config, TransactionalHtmlService, MailingService) {
        function matchPlaceholders(string) {
            var regExp = /{{ *placeholders\.([a-zA-Z0-9_]+?) *}}/g;

            var match;
            var placeholders = [];

            while (match = regExp.exec(string)) {
                var placeholder = match[1];

                if (placeholders.indexOf(placeholder) === -1) {
                    placeholders.push(placeholder);
                }
            }

            return placeholders;
        }

        function getPlaceholderIcons() {
            return {
                text: 'text_format',
                plain: 'short_text',
                image: 'image',
            };
        }

        function getPlaceholderTypes() {
            return [
                'text',
                'plain',
                'image',
            ];
        }

        return {
            matchPlaceholders: matchPlaceholders,
            getPlaceholderIcons: getPlaceholderIcons,
            getPlaceholderTypes: getPlaceholderTypes,
        };
    }
})(angular);
