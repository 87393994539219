(function (angular) {
    angular
        .module('one.admin')
        .service('FormEntryService', FormEntryService);

    FormEntryService.$inject = ['http', 'AccountService', 'ActionCampaignService', 'GiftService', 'OrderService', 'PostService'];

    function FormEntryService(http, AccountService, ActionCampaignService, GiftService, OrderService, PostService) {
        function distribution(formId, params) {
            return http.get('forms/' + formId + '/distribution', params);
        }

        function entry(id) {
            return http.get('forms/entries/' + id);
        }

        function entries(formId, params) {
            return http.get('forms/' + formId + '/entries', params);
        }

        function confirmationMailRecipients(id) {
            return http.get('forms/entries/' + id + '/confirmation_mail_recipients');
        }

        function saveFormEntry(data) {
            return http.post('forms/' + data.form.id + '/entries/save', data);
        }

        function deleteFormEntry(id) {
            return http.post('forms/entries/' + id + '/delete');
        }

        function initFormEntry(data) {
            return http.post('forms/' + data.form.id + '/entries/init', data);
        }

        function updateFormEntryState(data) {
            return http.post('forms/' + data.form.id + '/entries/state', data);
        }

        function sendConfirmationMail(id) {
            return http.post('forms/entries/' + id + '/send_confirmation_mail');
        }

        function formatModelTitle(modelType, model) {
            if (modelType == 'account') {
                return AccountService.formatAccountTitle(model);
            } else if (modelType == 'action_campaign') {
                return ActionCampaignService.formatActionCampaignTitle(model);
            } else if (modelType == 'gift') {
                return GiftService.formatGiftTitle(model);
            } else if (modelType == 'order') {
                return OrderService.formatOrderTitle(model);
            }

            return modelType;
        }

        var models = ['account', 'action_campaign', 'gift', 'order'];

        return {
            distribution: distribution,
            entry: entry,
            entries: entries,
            confirmationMailRecipients: confirmationMailRecipients,
            saveFormEntry: saveFormEntry,
            deleteFormEntry: deleteFormEntry,
            initFormEntry: initFormEntry,
            updateFormEntryState: updateFormEntryState,
            sendConfirmationMail: sendConfirmationMail,
            models: models,
            formatModelTitle: formatModelTitle
        };
    }
})(angular);
