(function (angular) {
    angular
        .module('one.admin')
        .directive('oneFroalaInput', froalaInputDirective);

    froalaInputDirective.$inject = ['config'];

    function froalaInputDirective(config) {
        controller.$inject = ['$cookies', '$scope', 'modal'];

        function controller($cookies, $scope, modal) {
            $.FroalaEditor.DefineIcon('ocImage', { NAME: 'image' });

            $.FroalaEditor.RegisterCommand('ocImage', {
                title: 'Insert image',
                callback: function (cmd, val) {
                    var editor = this;

                    editor.selection.save();

                    var selectedImage = editor.image.get();

                    editor.events.disableBlur();

                    modal.open('selectFile', null, {
                        file: selectedImage ? {
                            url: selectedImage.attr('src'),
                            is_image: true
                        } : null,
                        type: 'image',
                        site: $scope.site,
                    }).result.then(function (file) {
                        if (file) {
                            editor.image.insert(file.url, false, null, selectedImage);
                        }

                        editor.events.enableBlur();
                    });
                }
            });

            if ($scope.model) {
                $scope.froalaModel = $scope.model;
            }

            $scope.$watch('froalaModel', function (newModel) {
                $scope.model = $scope.froalaModel;
            });

            if ($scope.simple) {
                $scope.options = config.simpleFroalaOptions;
            } else if($scope.text) {
                $scope.options = config.textFroalaOptions;
            } else {
                $scope.options = config.froalaOptions;
            }

            if ($scope.variables) {
                var index = $scope.options.toolbarButtons.indexOf('insertLink');

                if (index == -1) {
                    index = 0;
                }

                $scope.options.toolbarButtons.splice(index, 0, 'variables');
                $scope.options.variables = $scope.variables;
            }

            $scope.options.events = $scope.options.events || {};

            $scope.options.events['froalaEditor.blur'] = function (e, editor) {
                $scope.blur();

                if ($scope.froalaModel == '<br>') {
                    $scope.froalaModel = '';
                }

                $scope.$apply();
            };

            $scope.options.requestHeaders = {
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
            };

            $scope.options.events['froalaEditor.image.uploaded'] = function (event, editor, response) {
                file = JSON.parse(response);

                editor.image.insert(file.url, false, null, editor.image.get(), file);

                return false;
            };
        }

        function link(scope, element) {
            scope.options.events['froalaEditor.initialized'] = function (e, editor) {
                // The CodeMirror field does not update the model real-time, so we have to
                // trigger an event to update the model when the CodeMirror field is blurred.
                $('body').on('blur', '.CodeMirror textarea', $(element), function () {
                    scope.options.froalaEditor(
                        'events.trigger',
                        'form.submit'
                    );
                });
            };

            scope.$watch('model', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    scope.froalaModel = newValue;
                }
            });
        }

        return {
            restrict: 'AE',
            scope: {
                model: '=',
                simple: '=',
                text: '=',
                variables: '=',
                theme: '=',
                site: '=',
                blur: '&'
            },
            link: link,
            controller: controller,
            templateUrl: config.path.template('common.inputs.froala')
        };
    }
})(angular);
