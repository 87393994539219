(function (angular) {
    angular
        .module('one.admin')
        .directive('accountVariableForm', accountVariableFormDirective);

    accountVariableFormDirective.$inject = ['config'];

    function accountVariableFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                variable: '='
            },
            controller: ['$scope', '$timeout', 'VariableService', function ($scope, $timeout, VariableService) {
                $scope.state = {};
                $scope.focus = false;

                $scope.variables = [];

                $scope.search = {};

                $timeout(function () {
                    $scope.focus = true;
                }, 0);
            }],
            templateUrl: config.path.template('accounts.account.variables.account_variable_form')
        };
    }
}(angular));
