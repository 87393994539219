(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountOrdersController', AccountOrdersController);

    AccountOrdersController.$inject = ['$scope', '$filter', 'AccountService', 'AuthService', 'modal', 'dataframe', 'string', 'account'];

    function AccountOrdersController($scope, $filter, AccountService, AuthService, modal, dataframe, string, account) {
        $scope.account = account;

        $scope.orders = dataframe.init({
            externalData: function (params) {
                return AccountService.account.orders($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Order',
                    name: 'id',
                    display: function (row) {
                        return '<h4><i class="md-icon">shopping_basket</i> {{ row.remote_id }}</h4>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Status',
                    name: 'statuses',
                    display: function (row) {
                        return '<span order-status-icon statuses="row.statuses"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Purchases',
                    name: 'purchases',
                    display: function (row) {
                        row.purchase_titles = [];

                        angular.forEach(row.purchases, function (purchase) {
                            var title = '<a ui-sref="sales.products.product({ productId: ' + purchase.product.id + '})" ng-click="$event.stopPropagation()">';
                            title = title + '<i class="md-icon">loyalty</i>';
                            title = title + ' ' + string.escape($filter('shorten')(purchase.product.title, 20));
                            title = title + '</a>';

                            if (purchase.is_recurring) {
                                title += ' <span class="text-muted">• <i class="md-icon text-muted">repeat</i> ';

                                if (purchase.end_date) {
                                    title += '<span class="text-line-through">';
                                }

                                title += $filter('money')(purchase.amount * purchase.quantity, "&euro; ") + " ";

                                if (purchase.interval_months == 1) {
                                    title += $filter('translate')('general.monthly').toLowerCase();
                                } else if (purchase.interval_months == 12) {
                                    title += $filter('translate')('general.yearly').toLowerCase();
                                } else {
                                    title += ($filter('translate')('general.every') + " " + purchase.interval_months + " " + $filter('translate')('general.months')).toLowerCase();
                                }

                                if (purchase.end_date) {
                                    title += '</span>';
                                }
                            }

                            row.purchase_titles.push(title);
                        });

                        return '<span list-limiter list="row.purchase_titles" delimiter="\' / \'" limit="1" shorten="true"></span>';
                    },
                    weight: 3
                },
                {
                    title: 'Payment method',
                    name: 'payment_method.title',
                    weight: 2
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    },
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('orders.view')) {
                    return 'sales.orders.order({orderId: ' + row.id + '})';
                }
                return false;
            },
            state: {
                perPage: 5,
                sort: { created_at: 'desc' }
            }
        });

        $scope.annualPayments = dataframe.init({
            externalData: function (params) {
                return AccountService.account.annualPayments($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Year',
                    name: 'year',
                    weight: 1
                },
                {
                    title: 'Actual',
                    name: 'actual',
                    weight: 1,
                    display: function (row) {
                        return $filter('money')(row.actual, '&euro; ', 2);
                    }
                },
                {
                    title: 'Expected',
                    name: 'expected',
                    weight: 1,
                    display: function (row) {
                        var expected = $filter('money')(row.expected, '&euro; ', 2);

                        if (row.modified_expected) {
                            expected += ' <i class="md-icon text-muted">edit</i>';
                        }

                        return expected;
                    }
                },
                {
                    title: 'Ratio',
                    name: 'ratio',
                    weight: 1,
                    display: function (row) {
                        var ratio = row.ratio

                        if (ratio < 100) {
                            ratio = ratio.toFixed(1);
                        }

                        return '{{ ' + ratio + ' | number }}' + '% ';
                    }
                }
            ],
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    click: function (row) {
                        openSaveAnnualPaymentModal({
                            year: row.year,
                            expected: row.expected
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('purchases.edit');
                    }
                }
            ],
            showHeader: true,
            state: {
                perPage: 5,
                sort: { year: 'asc' }
            }
        });

        $scope.openSaveAnnualPaymentModal = openSaveAnnualPaymentModal;

        function openSaveAnnualPaymentModal(data) {
            modal.open('saveAnnualPayment', null, { account: account, annualPayment: data || {} }).result.then(function (data) {
                $scope.annualPayments.getData();
            });
        }
    }
})(angular);
