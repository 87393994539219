(function (angular) {
    angular
        .module('one.admin')
        .controller('PageContentController', PageContentController);

    PageContentController.$inject = ['$scope', '$state', 'modal', 'PageService', 'ModuleService', 'moduleTypes', 'page', 'rowOptions'];

    function PageContentController($scope, $state, modal, PageService, ModuleService, moduleTypes, page, rowOptions) {
        var minColumnWidth = 1 / 6;

        $scope.errors = null;

        $scope.moduleTypes = moduleTypes;
        $scope.page = angular.copy(page);

        $scope.config = $scope.page.site.config;

        $scope.accessLevels = PageService.constants.access_levels;

        $scope.pageBuilder = {
            device: 'desktop',
            row_options: rowOptions
        };

        $scope.editPage = editPage;
        $scope.addRow = addRow;
        $scope.moveUpRow = moveUpRow;
        $scope.moveDownRow = moveDownRow;
        $scope.deleteRow = deleteRow;
        $scope.addColumn = addColumn;
        $scope.changeColumnWidth = changeColumnWidth;
        $scope.isMinWidth = isMinWidth;
        $scope.isMaxWidth = isMaxWidth;
        $scope.rowIsFull = rowIsFull;
        $scope.deleteColumn = deleteColumn;
        $scope.openInsertModuleModal = openInsertModuleModal;
        $scope.movedModule = movedModule;
        $scope.duplicateModule = duplicateModule;
        $scope.invisibleOnDevice = invisibleOnDevice;
        $scope.deleteModule = deleteModule;
        $scope.editModule = editModule;
        $scope.containsChanges = containsChanges;
        $scope.getBrandColor = getBrandColor;
        $scope.openAuditorModal = openAuditorModal;
        $scope.openDeletePageModal = openDeletePageModal;

        function editPage() {
            PageService.editPage($scope.page).then(function (data) {
                $state.reload($state.current);
            });
        }

        function invisibleOnDevice(module) {
            if ($scope.pageBuilder.device == 'desktop' && module.pivot.hide_desktop) {
                return true;
            }

            if ($scope.pageBuilder.device == 'mobile' && module.pivot.hide_mobile) {
                return true;
            }

            return false;
        }

        function getBrandColor(name) {
            if (name == 'transparent') {
                return name;
            }

            return $scope.config.brands[name];
        }

        function addRow(position) {
            $scope.page.rows.splice(position, 0, {
                columns: [
                    {
                        modules: [],
                        width: 1
                    }
                ],
                width: 1,
                options: {
                    theme: 'default',
                    classes: [],
                    mobile: {}
                }
            });
        }

        function moveUpRow(i) {
            if (i === 0) return;

            var pageRow = $scope.page.rows[i];

            $scope.page.rows[i] = $scope.page.rows[i - 1];
            $scope.page.rows[i - 1] = pageRow;
        }

        function moveDownRow(i) {
            if (i === $scope.page.rows.length - 1) return;

            var pageRow = $scope.page.rows[i];

            $scope.page.rows[i] = $scope.page.rows[i + 1];
            $scope.page.rows[i + 1] = pageRow;
        }

        function deleteRow(i) {
            $scope.page.rows.splice(i, 1);
        }

        function addColumn(row) {
            row.columns.push({
                modules: [],
                width: 1
            });

            row.width = row.width + 1;
        }

        function changeColumnWidth(row, index, change) {
            row.width += change;
            row.columns[index].width += change;
        }

        function isMinWidth(row, index) {
            return row.columns[index].width <= 1 || (row.columns[index].width - 1) / (row.width - 1) <= minColumnWidth;
        }

        function isMaxWidth(row, index) {
            if (row.columns.length <= 1 || row.width >= 12) {
                return true;
            }

            for (var i = 0; i < row.columns.length; i++) {
                if (row.columns[i].width / (row.width + 1) < minColumnWidth && i != index) {
                    return true;
                }
            }

            return false;
        }

        function rowIsFull(row) {
            for (var i = 0; i < row.columns.length; i++) {
                if (row.columns[i].width / row.width <= minColumnWidth) {
                    return true;
                }
            }

            return false;
        }

        function deleteColumn(row, i) {
            var column = row.columns[i];

            row.columns.splice(i, 1);
            row.width = row.width - column.width;
        }

        function openInsertModuleModal(column) {
            modal.open('insertModule', 'xl').result.then(function (data) {
                data.module.pivot = {
                    hide_mobile: false,
                    hide_desktop: false
                };

                column.modules.push(data.module);
            });
        }

        function movedModule(column, index) {
            column.modules.splice(index, 1);
        }

        function duplicateModule(pageRowColumn, module, index) {
            ModuleService.duplicateModule(module).then(function (data) {
                data.module.pivot = {
                    hide_mobile: false,
                    hide_desktop: false
                };

                pageRowColumn.modules.splice(index + 1, 0, data.module);
            });
        }

        function deleteModule(pageRowColumn, i) {
            pageRowColumn.modules.splice(i, 1);
        }

        function editModule(module) {
            if (containsChanges()) {
                openConfirmLeavePageModal(module);
            } else {
                goToEditModuleState(module);
            }
        }

        function containsChanges() {
            return !angular.equals($scope.page, page);
        }

        function openConfirmLeavePageModal(module) {
            modal.open('confirmLeavePage').result.then(function (result) {
                if (result) {
                    if (result.save) {
                        PageService.editPage($scope.page).then(function () {
                            goToEditModuleState(module);
                        });
                    } else if (result.discard) {
                        goToEditModuleState(module);
                    }
                }
            });
        }

        function goToEditModuleState(module) {
            $state.transitionTo(ModuleService.getState(module), {
                moduleId: module.id,
                pageId: $scope.page.id
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', {
                modelId: page.id,
                modelType: 'page'
            });
        }

        function openDeletePageModal() {
            PageService.openDeletePageModal($scope.page.id, function () {
                $state.transitionTo('content.pages.index');
            });
        }
    }
})(angular);
