(function (angular) {
    angular
        .module('one.admin')
        .controller('UpdatesModalController', UpdatesModalController);

    UpdatesModalController.$inject = ['$scope', '$uibModalInstance', 'AuthService', 'session', 'params'];

    function UpdatesModalController($scope, $uibModalInstance, AuthService, session, params) {
        angular.extend($scope, params);

        var user = session.get('user');

        $scope.confirm = function () {
            // Remove unread updates from session optimistically
            angular.forEach($scope.updates, function(update) {
                angular.forEach(user.unread_updates, function (unreadUpdate, index) {
                    if (update.id == unreadUpdate.id) {
                        // We can splice because each update occurs at most once
                        user.unread_updates.splice(index, 1);
                    }
                });
            });

            session.set('user.unread_updates', user.unread_updates);

            AuthService.confirmUpdates({
                updates: $scope.updates
            });

            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
