(function (angular) {
    angular
        .module('one.admin')
        .controller('PaymentReferenceConfigFormController', PaymentReferenceConfigFormController);

    PaymentReferenceConfigFormController.$inject = ['$scope', 'AuthService', 'ProductService', 'translateFilter'];

    function PaymentReferenceConfigFormController($scope, AuthService, ProductService, translateFilter) {
        $scope.formatProductTitle = ProductService.formatProductTitle;

        angular.forEach($scope.purchaseSources, function (purchaseSource) {
            purchaseSource.label = purchaseSource.title;
        });

        angular.forEach($scope.purchaseDestinations, function (purchaseDestination) {
            purchaseDestination.label = purchaseDestination.title;
        });

        if (AuthService.userCanAccess('purchases.add_purchase_source')) {
            $scope.purchaseSources.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        if (AuthService.userCanAccess('purchases.add_purchase_destination')) {
            $scope.purchaseDestinations.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        if ($scope.paymentReferenceConfig.purchase_source) {
            $scope.paymentReferenceConfig.purchase_source.label = $scope.paymentReferenceConfig.purchase_source.title;
        }

        if ($scope.paymentReferenceConfig.purchase_destination) {
            $scope.paymentReferenceConfig.purchase_destination.label = $scope.paymentReferenceConfig.purchase_destination.title;
        }
    }
})(angular);
