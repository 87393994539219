(function (angular) {
    angular
        .module('one.admin')
        .controller('EditMailingController', EditMailingController);

    EditMailingController.$inject = ['$scope', '$state', 'MailingService', 'CampaignService', 'campaign', 'mailing'];

    function EditMailingController($scope, $state, MailingService, CampaignService, campaign, mailing) {
        $scope.campaign = campaign;

        $scope.mailing = {
            id: mailing.id,
            title: mailing.title,
            campaign: mailing.campaign
        };

        $scope.getCampaigns = CampaignService.campaigns;
        $scope.editMailing = editMailing;

        function editMailing(data) {
            MailingService.editMailing(data).then(function (data) {
                $state.transitionTo('mail.campaigns.mailings.mailing', { campaignId: data.mailing.campaign.id, mailingId: mailing.id });
            });
        }
    }
})(angular);
