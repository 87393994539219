(function (angular) {
    angular
        .module('one.admin')
        .directive('sddPaymentForm', sddPaymentForm);

    sddPaymentForm.$inject = ['config'];

    function sddPaymentForm(config) {
        return {
            restrict: 'EA',
            scope: {
                order: '='
            },
            controller: 'SddPaymentFormController',
            templateUrl: config.path.template('orders.payments.form.sdd')
        };
    }
}(angular));
