(function (angular) {
    angular
        .module('one.admin')
        .controller('DiscountCodesController', DiscountCodesController);

    DiscountCodesController.$inject = ['$scope', 'AuthService', 'DiscountCodeService', 'dataframe', '$filter', 'session'];

    function DiscountCodesController($scope, AuthService, DiscountCodeService, dataframe, $filter, session) {
        $scope.discountCodes = dataframe.init({
            externalData: DiscountCodeService.discountCodes,
            cols: [
                {
                    title: 'Code',
                    name: 'code',
                    display: function (row) {
                        return '<h4>{{ row.code }} <span tag-indicator tags="row.tags"></span></h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Active',
                    name: 'active',
                    display: function (row) {
                        if (row.active) {
                            return '<i class="md-icon">check</i>';
                        }

                        if (row.scheduled) {
                            return '<i class="md-icon">schedule</i>';
                        }

                        return '';
                    },
                    weight: 1,
                    sortable: false
                },
                {
                    title: 'Description',
                    name: 'description',
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Discount',
                    name: 'value',
                    display: getDiscountValueForRow,
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Used',
                    display: function (row) {
                        return row.usage + (row.max_usage ? ' / ' + row.max_usage : '');
                    },
                    weight: 2
                },
                {
                    title: 'Added',
                    name: 'created_at',
                    display: function (row) {
                        return '{{ row.created_at | formatDate }}';
                    },
                    weight: 2,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('discount_codes.view')) {
                    return 'sales.discountCodes.discountCode({discountCodeId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'sales.discountCodes.editDiscountCode({discountCodeId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('discount_codes.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.discountCodes.loading = true;

                        DiscountCodeService.duplicateDiscountCode(row).then(function () {
                            $scope.discountCodes.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('discount_codes.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        DiscountCodeService.openDeleteDiscountCodeModal(row.id, function () {
                            $scope.discountCodes.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('discount_codes.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.discountCodes', {
                sort: { created_at: 'desc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        session.sync('data.dataframes.discountCodes', $scope.discountCodes.state);

        function getDiscountValueForRow(row) {
            switch (row.type) {
                case 'absolute':
                    return $filter('money')(row.value, '&euro; ', 2);
                case 'percentage':
                    return '{{ row.value + \'%\' }}';
            }
        }
    }
})(angular);
