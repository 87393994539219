(function (angular) {
    angular
        .module('one.admin')
        .controller('EditNewsModuleController', EditNewsModuleController);

    EditNewsModuleController.$inject = ['$scope', '$state', 'module', 'ModuleService', 'modal'];

    function EditNewsModuleController($scope, $state, module, ModuleService, modal) {
        $scope.module = module;

        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;

        function editModule(module) {
            ModuleService.editModule(module).then(function () {
                $state.transitionTo('content.modules.newsModule.index', {
                    moduleId: module.id
                });
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);
