(function (angular) {
    angular
        .module('one.admin')
        .controller('SiteTrackingCodesController', SiteTrackingCodesController);

    SiteTrackingCodesController.$inject = ['$scope', 'session', 'modal', 'SiteService', 'FormHelper'];

    function SiteTrackingCodesController($scope, session, modal, SiteService, FormHelper) {
        $scope.site = angular.copy(session.get('data.current_site'));

        $scope.form = FormHelper.getFormState({
            data: $scope.site.config.tracking_codes,
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, {
                    config: {
                        tracking_codes: data
                    }
                });

                return SiteService.updateConfig(data);
            },
            success: function (data) {
                $scope.site = data.site;
                session.set('data.current_site', angular.copy(data.site));
            }
        });

        $scope.openAuditorModal = openAuditorModal;

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.site.id, modelType: 'site' });
        }
    }
})(angular);
