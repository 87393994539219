(function (angular) {
    angular
        .module('one.admin')
        .directive('shareModuleForm', shareModuleFormDirective);

    shareModuleFormDirective.$inject = ['config'];

    function shareModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.share.form')
        };
    }
}(angular));
