(function (angular) {
    angular
        .module('one.admin')
        .controller('AddPaymentReferenceConfigController', AddPaymentReferenceConfigController);

    AddPaymentReferenceConfigController.$inject = ['$scope', '$state', 'PaymentReferenceService', 'products', 'purchaseSources', 'purchaseDestinations'];

    function AddPaymentReferenceConfigController ($scope, $state, PaymentReferenceService, products, purchaseSources, purchaseDestinations) {
        $scope.paymentReferenceConfig = {};

        $scope.products = products;
        $scope.purchaseSources = purchaseSources;
        $scope.purchaseDestinations = purchaseDestinations;

        $scope.add = add;

        function add(paymentReferenceConfig) {
            PaymentReferenceService.addPaymentReferenceConfig(paymentReferenceConfig).then(function (data) {
                $state.transitionTo('finance.paymentReferences.index');
            });
        }
    }
})(angular);
