(function (angular) {
    angular
        .module('one.admin')
        .controller('ConvertToInvoiceModalController', ConvertToInvoiceModalController);

    ConvertToInvoiceModalController.$inject = ['$scope', '$uibModalInstance', 'OrderService', 'notification', 'invoicePaymentMethods', 'params'];

    function ConvertToInvoiceModalController($scope, $uibModalInstance, OrderService, notification, invoicePaymentMethods, params) {
        angular.merge($scope, params);

        $scope.conversion = {
            payment_method: invoicePaymentMethods[0]
        };

        $scope.invoicePaymentMethods = invoicePaymentMethods;

        $scope.close = $uibModalInstance.close;
        $scope.convertToInvoice = convertToInvoice;

        function convertToInvoice() {
            OrderService.convertToInvoice($scope.order.id, $scope.conversion).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
