(function (angular) {
    angular
        .module('one.admin')
        .controller('RefundTransactionModalController', RefundTransactionModalController);

    RefundTransactionModalController.$inject = ['$scope', '$locale', '$uibModalInstance', 'TransactionService', 'params'];

    function RefundTransactionModalController($scope, $locale, $uibModalInstance, TransactionService, params) {
        angular.merge($scope, params);

        $scope.close = $uibModalInstance.close;
        $scope.refundTransaction = refundTransaction;

        function refundTransaction() {
            TransactionService.transaction.applyAction($scope.transaction.id, TransactionService.actions.refund).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
