(function (angular) {
    angular
        .module('one.admin')
        .controller('EventController', EventController);

    EventController.$inject = ['$scope', 'EventService', 'modal', 'event'];

    function EventController($scope, EventService, modal, event) {
        $scope.event = event;

        $scope.openAuditorModal = openAuditorModal;

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: event.id, modelType: 'event' });
        }
    }
})(angular);
