(function (angular) {
    angular
        .module('one.admin')
        .directive('newsModuleForm', newsModuleFormDirective);

    newsModuleFormDirective.$inject = ['config'];

    function newsModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.news.form')
        };
    }
}(angular));
