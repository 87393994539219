(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$interval', '$rootScope', '$state', 'AuthService', 'config', 'notification', 'session', 'translateFilter'];

    function run($interval, $rootScope, $state, AuthService, config, notification, session, translateFilter) {
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (session.get('project') && 'requirePermission' in toState) {
                if (!AuthService.userCanAccess(toState.requirePermission)) {
                    if ($state.current.abstract) {
                        // When loading the page for the first time.
                        $state.transitionTo('unauthorised');
                    } else {
                        notification.error('Sorry, you are not authorised to do this.');
                    }

                    event.preventDefault();
                }
            }
        });
    }
})(angular);
