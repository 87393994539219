(function (angular) {
    angular
        .module('one.admin')
        .controller('BillingController', BillingController);

    BillingController.$inject = ['$scope', 'AuthService'];

    function BillingController($scope, AuthService) {
        $scope.init = function () {
            $scope.billing = [];

            $scope.status = {
                loading: true
            };

            AuthService.projectBilling().then(function (billing) {
                $scope.billing = billing;

                $scope.status.loading = false;
            });

            $scope.types = ['accounts', 'transactions'];
        };

        $scope.init();
    }
})(angular);
