(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountController', AccountController);

    AccountController.$inject = ['$scope', '$interval', '$state', 'AccountService', 'GroupService', 'modal', 'account'];

    function AccountController($scope, $interval, $state, AccountService, GroupService, modal, account) {
        $scope.account = account;

        $scope.groups = GroupService.getLeafGroups($scope.account.groups);

        var timer = $interval(getAccount, 10000);

        $scope.$on('$destroy', function () {
            $interval.cancel(timer);
        });

        $scope.openAddAttachmentModal = openAddAttachmentModal;
        $scope.openAddBankAccountModal = openAddBankAccountModal;
        $scope.openAddInteractionModal = openAddInteractionModal;
        $scope.openAddFormEntryModal = openAddFormEntryModal;
        $scope.openAddGroupMembershipModal = openAddGroupMembershipModal;
        $scope.openDeleteAccountModal = openDeleteAccountModal;
        $scope.openDeleteGroupMembershipModal = openDeleteGroupMembershipModal;
        $scope.openEditContactUserModal = openEditContactUserModal;
        $scope.openSendPersonalMailModal = openSendPersonalMailModal;
        $scope.openAuditorModal = openAuditorModal;

        function openAddAttachmentModal(size) {
            modal.open('addFile', size, { data: { account: { id: account.id } } }).result.then(function (data) {
                $state.reload();
            });
        }

        function openAddBankAccountModal(size) {
            modal.open('addBankAccount', size, { bankAccount: { account: account } });
        }

        function openAddInteractionModal(size) {
            modal.open('addInteraction', size, { interaction: { account: account } }).result.then(function (data) {
                $state.reload();
            });
        }

        function openAddFormEntryModal(size) {
            modal.open('selectFormEntry', size, { formEntry: { account: account } });
        }

        function openAddGroupMembershipModal(size) {
            modal.open('addGroupMembership', size, { membership: { account: account } }).result.then(function (data) {
                getGroups();
            });
        }

        function openDeleteAccountModal(size) {
            modal.open('deleteAccount', size, { account: account }).result.then(function (data) {
                $state.go('crm.accounts.account.index', { accountId: account.id }, { reload: true });
            });
        }

        function openSendPersonalMailModal(size) {
            modal.open('sendPersonalMail', size, { mail: { account: account } }).result.then(function (data) {
                $state.reload();
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: account.id, modelType: 'account' });
        }

        function openDeleteGroupMembershipModal(group) {
            modal.open('confirm', null, {
                title: 'Are you sure?',
                message: 'Delete this group membership?'
            }).result.then(function (data) {
                GroupService.deleteGroupMembership(account.id, group.id).then(function (data) {
                    getGroups();
                });
            });
        }

        function openEditContactUserModal(size) {
            modal.open('editAccountContactUser', size, { account: $scope.account }).result.then(function (data) {
                $scope.account.contact_user = data.account.contact_user;
            });
        }

        function getAccount() {
            AccountService.account(account.id, null, { ignoreLoadingBar: true }).then(function (account) {
                $scope.account = account;
            });
        }

        function getGroups() {
            AccountService.account.groups($scope.account.id).then(function (groups) {
                $scope.groups = GroupService.getLeafGroups(groups);
            });
        }
    }
})(angular);
