(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFileModalController', AddFileModalController);

    AddFileModalController.$inject = ['$scope', '$uibModalInstance', 'session', 'params'];

    function AddFileModalController($scope, $uibModalInstance, session, params) {
        angular.extend($scope, params);

        $scope.site = session.get('data.current_site');

        $scope.close = function () {
            $uibModalInstance.close();
        };
    }
})(angular);
