(function (angular) {
    angular
        .module('one.admin')
        .filter('bytes', bytesFilter);

    bytesFilter.$inject = [];

    function bytesFilter() {
        return function (value, precision) {
            if (!value) return '0 B';

            if (typeof precision === 'undefined') precision = 1;

            var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
                number = Math.floor(Math.log(value) / Math.log(1024));

            return (value / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
        };
    }
})(angular);
