(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFormModuleModalController', AddFormModuleModalController);

    AddFormModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'translateFilter', 'params'];

    function AddFormModuleModalController($scope, $uibModalInstance, ModuleService, translateFilter, params) {
        $scope.module = {
            module_instance: {}
        };

        angular.merge($scope, params);

        $scope.addFormModule = addFormModule;
        $scope.cancel = cancel;

        function addFormModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
