(function (angular) {
    angular
        .module('one.admin')
        .controller('AddTagModalController', AddTagModalController);

    AddTagModalController.$inject = ['$scope', '$uibModalInstance', 'TagService', 'tags', 'colors', 'params'];

    function AddTagModalController($scope, $uibModalInstance, TagService, tags, colors, params) {
        $scope.tags = tags;
        $scope.colors = colors;

        $scope.tag = {
            position: 1,
            models: params ? params.models : null
        };

        for ($scope.tag.position = 1; $scope.tag.position <= $scope.tags.length; $scope.tag.position++) {
            if ($scope.tag.position != tags[$scope.tag.position - 1].position) {
                break;
            }
        }

        $scope.add = add;
        $scope.cancel = cancel;

        function add() {
            TagService.addTag($scope.tag).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
