(function (angular) {
    angular
        .module('one.admin')
        .service('SegmentService', SegmentService);

    SegmentService.$inject = ['$httpParamSerializer', '$q', 'http', 'AccountService', 'modal', 'notification', 'string', 'translateFilter'];

    function SegmentService($httpParamSerializer, $q, http, AccountService, modal, notification, string, translateFilter) {
        function accounts(id, params) {
            return http.get('segments/' + id + '/accounts', params);
        }

        /**
         * The result of filterables should look like:
         * {
         *     account: {
         *         fieldOptions: [
         *             {label: 'id', value: 'id'},
         *             {label: 'gender', value: 'gender'},
         *             ...
         *         ],
         *         fields: {
         *             id: {
         *                 type: 'integer',
         *                 operators: [{label: '=', value: 'equals'}, ...]
         *             },
         *             gender: {
         *                 options: [{label: 'm', value: 'm'}, {label: 'Male', value: 'Female'}],
         *                 operators: [{label: '=', value: 'equals'}, ...]
         *             },
         *             ...
         *         }
         *     }
         * }
         */
        function filterables() {
            return http.get('segments/filterables').then(function (filterables) {
                angular.forEach(filterables, function (filterable, scope) {
                    filterable.fieldOptions = [];

                    angular.forEach(filterable.fields, function (field, fieldName) {
                        var label = fieldName;

                        if (scope == 'group' && fieldName == 'id') {
                            label = 'group';
                        }

                        filterable.fieldOptions.push({
                            label: translateFilter('general.' + label),
                            value: fieldName
                        });

                        var operators = [];

                        angular.forEach(field.operators, function (operator) {
                            operators.push({
                                value: operator,
                                label: translateFilter('operators.' + operator)
                            });
                        });

                        var options = [];
                        var translateNamespace;

                        if (fieldName == 'account_type_name') {
                            translateNamespace = 'account_types';
                        } else if (fieldName == 'gender') {
                            translateNamespace = 'genders';
                        }

                        angular.forEach(field.options, function (label, value) {
                            options.push({
                                value: value,
                                label: translateNamespace ? translateFilter(translateNamespace + '.' + label) : label
                            });
                        });

                        field.operators = operators;
                        field.options = options;
                    });
                });

                return $q.resolve(filterables);
            });
        }

        function stats(params) {
            return http.get('segments/stats', params);
        }

        function segment(id) {
            return http.get('segments/' + id);
        }

        function segments(params) {
            return http.get('segments', params);
        }

        function addSegment(data) {
            return http.post('segments/add', data);
        }

        function deleteSegment(id) {
            return http.post('segments/' + id + '/delete');
        }

        function diffSegment(data) {
            return http.post('segments/' + data.id + '/diff', data);
        }

        function editSegment(data) {
            return http.post('segments/' + data.id + '/edit', data);
        }

        function duplicateSegment(data) {
            return http.post('segments/' + data.id + '/duplicate', data);
        }

        function formatSegmentTitle(segment) {
            var output = '<i class="md-icon">filter_list</i> ' + segment.title;

            if (segment.tags) {
                output += '<span class="tag-indicators">';

                angular.forEach(segment.tags, function (tag) {
                    output += ' <i class="tag-indicator" style="background: ' + tag.color + '"></i>';
                });

                output += '</span>';
            }

            return output;
        }

        function openDeleteSegmentModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Delete this segment?',
            }).result.then(function (data) {
                return deleteSegment(id).then(callback);
            });
        }

        return {
            accounts: accounts,
            filterables: filterables,
            stats: stats,
            segment: segment,
            segments: segments,
            addSegment: addSegment,
            editSegment: editSegment,
            deleteSegment: deleteSegment,
            diffSegment: diffSegment,
            duplicateSegment: duplicateSegment,
            formatSegmentTitle: formatSegmentTitle,
            openDeleteSegmentModal: openDeleteSegmentModal
        };
    }
})(angular);
