(function (angular) {
    angular
        .module('one.admin')
        .directive('auditLogEntryValue', auditLogEntryValueDirective);

    auditLogEntryValueDirective.$inject = ['$compile', 'config'];

    function auditLogEntryValueDirective($compile, config) {
        controller.$inject = ['$scope', 'AuditService', 'translateFilter'];

        function controller($scope, AuditService, translateFilter) {
            if ($scope.value === true || $scope.value === false) {
                $scope.type = 'boolean';
            } else if (angular.isArray($scope.value)) {
                $scope.type = 'array';
            } else if (angular.isObject($scope.value)) {
                $scope.type = 'object';
            } else {
                $scope.type = 'string';
            }
        }

        function compile(element, link) {
            // Normalize the link parameter
            if (angular.isFunction(link)) {
                link = { post: link };
            }

            // Break the recursion loop by removing the contents
            var contents = element.contents().remove();
            var compiledContents;

            return {
                pre: (link && link.pre) ? link.pre : null,
                /**
                 * Compiles and re-adds the contents
                 */
                post: function (scope, element) {
                    // Compile the contents
                    if (!compiledContents) {
                        compiledContents = $compile(contents);
                    }
                    // Re-add the compiled contents to the element
                    compiledContents(scope, function (clone) {
                        element.append(clone);
                    });

                    // Call the post-linking function, if any
                    if (link && link.post) {
                        link.post.apply(null, arguments);
                    }
                }
            };
        }

        return {
            restrict: 'AE',
            scope: {
                value: '=',
                depth: '='
            },
            controller: controller,
            compile: function (element) {
                return compile(element);
            },
            templateUrl: config.path.template('auditor.log_entry_value')
        };
    }
})(angular);
