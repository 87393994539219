(function (angular) {
    angular
        .module('one.admin')
        .directive('addTeamForm', addTeamFormDirective);

    addTeamFormDirective.$inject = ['config'];

    function addTeamFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                account: '='
            },
            controller: ['$scope', function ($scope) {
                $scope.account = $scope.account || {};
                $scope.account.type = {name: 'team'};

                $scope.state = {};

                $scope.toggleExtraFields = toggleExtraFields;

                function toggleExtraFields() {
                    $scope.state.expand = !$scope.state.expand;

                    if (!$scope.state.expand) {
                        var keys = ['email', 'phone_work'];

                        angular.forEach(keys, function (key) {
                            delete $scope.account[key];
                        });
                    }
                }
            }],
            templateUrl: config.path.template('accounts.add_team_form')
        };
    }
}(angular));
