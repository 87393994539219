(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$state'];

    function run($rootScope, $state) {
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, response) {
            $state.go('notFound');
        });
    }
})(angular);
