(function (angular) {
    angular
        .module('one.admin')
        .service('GiftService', GiftService);

    GiftService.$inject = ['$filter', 'http', 'string', 'ActionProjectService', 'ActionCampaignService', 'AccountService'];

    function GiftService($filter, http, string, ActionProjectService, ActionCampaignService, AccountService) {
        function gift(id, params) {
            return http.get('gifts/' + id, params);
        }

        function gifts(params) {
            return http.get('gifts', params);
        }

        function editGift(data) {
            return http.post('gifts/' + data.id + '/edit', data);
        }

        function giftReward(id, params) {
            return http.get('gift_rewards/' + id, params);
        }

        function giftRewards(params) {
            return http.get('gift_rewards', params);
        }

        function addGiftReward(data) {
            return http.post('gift_rewards/add', data);
        }

        function editGiftReward(data) {
            return http.post('gift_rewards/' + data.id + '/edit', data);
        }

        function deleteGiftReward(giftRewardId) {
            return http.post('gift_rewards/' + giftRewardId + '/delete');
        }

        function formatGiftTitle(gift) {
            return '<i class="md-icon">card_giftcard</i> {{ gift.amount | money: \'&euro; \' }}</span> <span order-status-icon statuses="gift.purchase.statuses"></span> (<i account-icon account="gift.from"></i> {{ gift.from.title }} › <gift-destination gift="gift" no-link="true"></gift-destination>)'
        }

        function formatGiftRewardTitle(giftReward) {
            output = '';

            if (!giftReward.active) {
                output = output + '<span class="archived">';
            }

            output = output + '<i class="md-icon">card_giftcard</i> ' + string.escape(giftReward.title) + ' (' + $filter('money')(giftReward.amount) + ')';

            if (!giftReward.active) {
                output = output + '</span>';
            }

            return output;
        }

        var destinationForm = {
            getActionProjects: function (gift) {
                return function (params) {
                    return ActionProjectService.actionProjects(params);
                }
            },
            getActionCampaigns: function (gift) {
                return function (params) {
                    if (gift.action_project) {
                        params = params || {};
                        params.filters = angular.extend({}, params.filters, { action_projects: [gift.action_project.id] })
                    }

                    return ActionCampaignService.actionCampaigns(params);
                }
            },
            getAccounts: function (gift) {
                return function (params) {
                    if (gift.action_campaign) {
                        params = params || {};
                        params.filters = angular.extend({}, params.filters, { action_campaigns: [gift.action_campaign.id] })
                    }

                    return AccountService.accounts(params);
                }
            },
            getGiftRewards: function (gift) {
                return function (params) {
                    params = params || {};
                    params.filters = angular.extend({}, params.filters);

                    if (gift.account && gift.action_campaign) {
                        params.filters = angular.extend({}, params.filters, {
                            action_campaigns: [gift.action_campaign.id],
                            accounts: [gift.account.id]
                        })
                    } else if (gift.action_campaign) {
                        params.filters = angular.extend({}, params.filters, { action_campaigns: [gift.action_campaign.id] })
                    } else if (gift.action_project) {
                        params.filters = angular.extend({}, params.filters, { action_projects: [gift.action_project.id] })
                    }

                    return giftRewards(params);
                }
            },
            handleActionProjectChange: function (gift) {
                if (!gift.action_project || (gift.action_campaign && gift.action_project.id != gift.action_campaign.action_project_id)) {
                    gift.account = null;
                    gift.action_campaign = null;
                    gift.gift_reward = null;
                }
            },
            handleActionCampaignChange: function (gift) {
                if (gift.action_campaign && gift.action_campaign.action_project) {
                    gift.action_project = gift.action_campaign.action_project;
                }

                if (!gift.action_campaign || (gift.gift_reward && gift.action_campaign.id != gift.gift_reward.action_campaign_id)) {
                    gift.gift_reward = null;
                }

                gift.account = null;
            },
            handleAccountChange: function (gift) {
                gift.gift_reward = null;

                destinationForm.getActionCampaigns(gift)({ perPage: 20 }).then(function(result) {
                    if (result.data.length == 1) {
                        gift.action_campaign = result.data[0];
                    }
                });
            }
        };

        return {
            gift: gift,
            gifts: gifts,
            giftReward: giftReward,
            giftRewards: giftRewards,
            editGift: editGift,
            editGiftReward: editGiftReward,
            addGiftReward: addGiftReward,
            deleteGiftReward: deleteGiftReward,
            formatGiftTitle: formatGiftTitle,
            formatGiftRewardTitle: formatGiftRewardTitle,
            destinationForm: destinationForm
        };
    }
})(angular);
