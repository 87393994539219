(function (angular) {
    angular
        .module('one.admin')
        .controller('AddPageController', AddPageController);

    AddPageController.$inject = ['$scope', '$state', 'session', 'PageService', 'blocks', 'flags', 'languages'];

    function AddPageController($scope, $state, session, PageService, blocks, flags, languages) {
        $scope.blocks = blocks;
        $scope.flags = flags;
        $scope.languages = languages;

        $scope.page = {
            custom_url_title: false,
            site: session.get('data.current_site'),
            access_level: PageService.constants.access_levels.public,
            noindex: false,
            rows: [
                {
                    columns: [
                        {
                            modules: [],
                            width: 1
                        }
                    ],
                    width: 1,
                    options: {
                        theme: 'default',
                        classes: [],
                        mobile: {}
                    }
                }
            ],
            language: session.get('data.current_site.language')
        };

        $scope.addPage = addPage;

        function addPage(data) {
            PageService.addPage(data).then(function (data) {
                $state.transitionTo('content.pages.pageContent', { pageId: data.page.id });
            });
        }
    }
})(angular);
