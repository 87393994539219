(function (angular) {
    angular
        .module('one.admin')
        .controller('EditBankAccountController', EditBankAccountController);

    EditBankAccountController.$inject = ['$scope', '$state', 'AccountService', 'BankAccountService', 'modal', 'notification', 'bankAccount'];

    function EditBankAccountController($scope, $state, AccountService, BankAccountService, modal, notification, bankAccount) {
        $scope.bankAccount = bankAccount;

        $scope.editBankAccount = editBankAccount;

        function editBankAccount(data) {
            BankAccountService.editBankAccount(data).then(function (data) {
                $state.transitionTo('crm.accounts.account.financial', {accountId: $scope.bankAccount.account.id});
            });
        }
    }
})(angular);
