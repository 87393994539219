(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$http', '$interval', 'Rollbar'];

    function run($rootScope, $http, $interval, Rollbar) {
        $rootScope.updateAvailable = false;

        var loadedVersion = null;
        var latestVersion = null;

        var checkInterval = 30000;

        var checkVersion = function () {
            $http.get('version.html').then(function (response) {
                var version = response.data;

                latestVersion = version;

                if (!loadedVersion) {
                    loadedVersion = version;
                } else if (loadedVersion != latestVersion) {
                    $rootScope.updateAvailable = true;

                    // Do not log errors while the client is working with an outdated version.
                    Rollbar.disable();
                }
            });
        };

        checkVersion();

        $interval(checkVersion, checkInterval);
    }
})(angular);
