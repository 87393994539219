(function (angular) {
    angular
        .module('one.admin')
        .controller('AddOrderController', AddOrderController);

    AddOrderController.$inject = ['$scope', '$state', '$stateParams', 'AccountService', 'ActionProjectService', 'ActionCampaignService', 'AuthService', 'DiscountCodeService', 'GiftService', 'OrderService', 'ProductService', 'translateFilter', 'paymentMethods', 'products', 'purchaseSources', 'purchaseDestinations'];

    function AddOrderController($scope, $state, $stateParams, AccountService, ActionProjectService, ActionCampaignService, AuthService, DiscountCodeService, GiftService, OrderService, ProductService, translateFilter, paymentMethods, products, purchaseSources, purchaseDestinations) {
        $scope.paymentMethods = [];

        angular.forEach(paymentMethods, function (paymentMethod) {
            if (paymentMethod.payment_method_type_name == 'sdd'
                || (paymentMethod.payment_method_type_name == 'manual' && paymentMethod.title !== 'Atleta') // Only used for orders that are managed through Atleta.
                || paymentMethod.payment_method_type_name == 'mollie_direct_debit'
                || paymentMethod.payment_method_type_name == 'mollie_ideal'
                || paymentMethod.payment_method_type_name == 'invoice') {
                $scope.paymentMethods.push(paymentMethod);
            }
        });

        $scope.order = {
            account: $stateParams.account,
            payment_method: $scope.paymentMethods[0],
            issuer: '',
            purchases: []
        };

        $scope.toggles = {
            showPurchaseSourceAndDestination: (purchaseSources.length + purchaseDestinations.length) > 0
        };

        angular.forEach(purchaseSources, function (purchaseSource) {
            purchaseSource.label = purchaseSource.title;
        });

        angular.forEach(purchaseDestinations, function (purchaseDestination) {
            purchaseDestination.label = purchaseDestination.title;
        });

        if (AuthService.userCanAccess('purchases.add_purchase_source')) {
            purchaseSources.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        if (AuthService.userCanAccess('purchases.add_purchase_destination')) {
            purchaseDestinations.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        $scope.products = products;
        $scope.purchaseSources = purchaseSources;
        $scope.purchaseDestinations = purchaseDestinations;

        $scope.today = (new Date()).toISOString().slice(0, 10);

        addPurchase();

        $scope.getAccounts = AccountService.accounts;
        $scope.getDiscountCodes = DiscountCodeService.discountCodes;
        $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;
        $scope.formatActionCampaignTitle = ActionCampaignService.formatActionCampaignTitle;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.getProducts = ProductService.products;
        $scope.addOrder = addOrder;
        $scope.addPurchase = addPurchase;
        $scope.initPurchase = initPurchase;
        $scope.removePurchase = removePurchase;
        $scope.updateAmounts = updateAmounts;
        $scope.updateDiscountCode = updateDiscountCode;
        $scope.formatProductTitle = ProductService.formatProductTitle;
        $scope.giftDestinationForm = GiftService.destinationForm;
        $scope.hasEndDate = hasEndDate;

        function hasEndDate(purchase) {
            return purchase.product && (purchase.product.payment_type == 'recurring' || purchase.product.payment_type == 'manual');
        }

        function updateDiscountCode() {
            angular.forEach($scope.order.purchases, function (purchase) {
                purchase.discount_code = $scope.order.discount_code;
            });
        }

        function addOrder(data) {
            OrderService.addOrder(data).then(function (data) {
                $state.transitionTo('sales.orders.order', { orderId: data.order.id });
            });
        }

        function addPurchase() {
            $scope.order.purchases.push({
                discount_code: $scope.order.discount_code,
                quantity: 1,
                payment_terms: [],
                start_date: $scope.today,
                gift: {}
            });
        }

        function initPurchase(purchase) {
            if (!purchase.product) {
                return;
            }

            purchase.amount = purchase.product.amount * purchase.quantity;

            purchase.payment_terms = [];

            angular.forEach(purchase.product.payment_terms, function (paymentTerm) {
                purchase.payment_terms.push({
                    amount: paymentTerm.amount * purchase.quantity,
                    title: paymentTerm.title
                });
            });
        }

        function removePurchase(index) {
            $scope.order.purchases.splice(index, 1);
        }

        function updateAmounts(purchase) {
            if (purchase.product.payment_type == 'terms') {
                angular.forEach(purchase.product.payment_terms, function (paymentTerm, i) {
                    purchase.payment_terms[i].amount = paymentTerm.amount * purchase.quantity;
                });
            } else if (!purchase.product.allow_custom_amount) {
                purchase.amount = purchase.product.amount * purchase.quantity;
            }
        }
    }
})(angular);
