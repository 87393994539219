(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['$qProvider'];

    function config($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }
})(angular);
