(function (angular) {
    angular
        .module('one.admin')
        .controller('MailingController', MailingController);

    MailingController.$inject = ['$scope', '$state', '$timeout', '$locale', 'MailingService', 'DashboardService', 'chartBuilder', 'config', 'dataframe', 'modal', 'mailing'];

    function MailingController($scope, $state, $timeout, $locale, MailingService, DashboardService, chartBuilder, config, dataframe, modal, mailing) {
        $scope.previewPath = config.path.api('mailings/' + mailing.id + '/preview');

        $scope.mailing = mailing;

        $scope.editor = initialEditorState();

        $scope.hasRecipients = mailing.groups.length > 0 || mailing.segment;

        $scope.scheduledInFuture = mailing.scheduled_frequency != 'once' && moment(mailing.scheduled_date).isAfter();
        $scope.scheduledToday = moment(mailing.scheduled_date).isSame(moment().startOf('day'), 'day');

        DashboardService.sentMailStats({ days: 30, filters: { mailings: [$scope.mailing.id] } }).then(function (result) {
            var green = 'rgba(113, 192, 37, .7)';
            var red = 'rgba(245, 77, 71, .7)';

            var data = chartBuilder.prefillData(result.start_date, result.end_date, { bounces: 0, deliveries: 0 })

            angular.forEach(result.data, function (stats) {
                var index = moment(stats.iso_date).format($locale.DATETIME_FORMATS.parsedDate);

                if (stats.status == 'delivered') {
                    data[index].deliveries += stats.count;
                } else if (stats.status == 'failed') {
                    data[index].bounces += stats.count;
                }
            });

            $scope.sentMailsChart = chartBuilder.createChart({
                series: ['deliveries', 'bounces'],
                colors: [green, red]
            }, data);
        });

        var clickCounts = [];

        angular.forEach($scope.mailing.click_counts, function (counts, url) {
            clickCounts.push(angular.merge(counts, {
                url: url
            }));
        });

        $scope.clickCounts = dataframe.init({
            data: clickCounts,
            cols: [
                {
                    title: 'URL',
                    name: 'url',
                    display: function (row) {
                        return '<a ng-href="{{ row.url }}" target="_blank" rel="noopener">{{ row.url }}</a>';
                    },
                    weight: 2
                },
                {
                    title: 'Clicks (unique)',
                    name: 'row.all',
                    display: function (row) {
                        row.openClicksModal = function () {
                            openMailingClicksModal(row.url);
                        };

                        return '<a ng-click="row.openClicksModal()">{{ row.all | number }} ({{ row.unique | number }})</a>';
                    },
                    weight: 1
                }
            ],
            state: {
                perPage: 5
            }
        });

        $scope.batches = dataframe.init({
            externalData: function (params) {
                return MailingService.batches($scope.mailing.id, params);
            },
            cols: [
                {
                    title: 'Sent by',
                    name: 'user',
                    display: function (row) {
                        if (row.user) {
                            return '<span class="one-user"><i class="md-icon">account_circle</i> {{ row.user.full_name }}</span>';
                        }
                        return '';
                    },
                    weight: 2
                },
                {
                    title: 'Recipients',
                    name: 'recipient_count',
                    display: function (row) {
                        return '{{ row.recipient_count | number }}';
                    },
                    weight: 1
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    display: function (row) {
                        return '{{ row.created_at | formatDate: \'short\' }}';
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            state: {
                sort: { created_at: 'desc' },
                perPage: 5
            }
        });

        var timer;
        $scope.$on('$destroy', function () {
            $timeout.cancel(timer);
            timer = null;
        });

        $scope.recached = false;
        refreshMailing(0);

        function refreshMailing(timeout) {
            if (!$scope.recached || $scope.mailing.sending) {
                timer = $timeout(function () {
                    MailingService.mailing(mailing.id, { recache: true }).then(function (mailing) {
                        $scope.mailing = mailing;
                        $scope.recached = true;

                        if (mailing.sending) {
                            if (timer) {
                                refreshMailing(2000);
                            }
                        }
                    });
                }, timeout);
            }
        }

        $scope.toggleEditor = toggleEditor;
        $scope.saveRecipients = saveRecipients;
        $scope.saveSender = saveSender;
        $scope.saveSubject = saveSubject;
        $scope.stopSchedule = stopSchedule;
        $scope.openSendMailingModal = openSendMailingModal;
        $scope.openScheduleMailingModal = openScheduleMailingModal;
        $scope.openSendTestMailModal = openSendTestMailModal;
        $scope.openMailingClicksModal = openMailingClicksModal;
        $scope.openMailingRecipientsModal = openMailingRecipientsModal;
        $scope.openMailingDeliveriesModal = openMailingDeliveriesModal;
        $scope.openMailingPendingModal = openMailingPendingModal;
        $scope.openMailingFailuresModal = openMailingFailuresModal;
        $scope.openMailingOpensModal = openMailingOpensModal;
        $scope.openDeleteMailingModal = openDeleteMailingModal;
        $scope.openAuditorModal = openAuditorModal;

        function toggleEditor(type) {
            $scope.editor = initialEditorState();
            $scope.errors = false;

            $scope.editor.editing = type;
        }

        function initialEditorState() {
            return {
                editing: $state.params.editing,
                mailing: angular.copy(mailing),
                loading: false
            };
        }

        function saveRecipients() {
            $state.transitionTo('mail.campaigns.mailings.mailing', {
                campaignId: $scope.mailing.campaign.id,
                mailingId: $scope.mailing.id
            });

            save({
                id: $scope.editor.mailing.id,
                accounts: $scope.editor.mailing.accounts,
                groups: $scope.editor.mailing.groups,
                segments: $scope.editor.mailing.segments,
                once_per_email: $scope.editor.mailing.once_per_email,
                important: $scope.editor.mailing.important,
                delete_segment: $scope.editor.mailing.delete_segment,
                include_atleta_summary: $scope.editor.mailing.include_atleta_summary,
            });
        }

        function saveSender() {
            save({
                id: $scope.editor.mailing.id,
                sender: $scope.editor.mailing.sender,
                personalized_sender: $scope.editor.mailing.personalized_sender
            });
        }

        function saveSubject() {
            save({
                id: $scope.editor.mailing.id,
                subject: $scope.editor.mailing.subject
            });
        }

        function save(data) {
            $scope.editor.loading = true;

            MailingService.editMailing(data).then(function (data) {
                $state.reload($state.current);
            }, function (data) {
                $scope.errors = data.errors;
                $scope.editor.loading = false;
            });
        }

        function stopSchedule() {
            MailingService.editMailing({
                id: $scope.mailing.id,
                automatic: false
            }).then(function (data) {
                $state.reload($state.current);
            });
        }

        function openSendMailingModal() {
            modal.open('sendMailing', null, { mailing: $scope.mailing }).result.then(function (data) {
                $scope.mailing.sending = true;

                $state.reload($state.current);
            });
        }

        function openScheduleMailingModal() {
            modal.open('scheduleMailing', null, { mailing: angular.copy($scope.mailing) }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openSendTestMailModal() {
            modal.open('sendTestMail', null, { mailing: $scope.mailing }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openMailingClicksModal(url) {
            modal.open('mailingClicks', 'lg', { mailing: $scope.mailing, url: url });
        }

        function openMailingRecipientsModal() {
            modal.open('mailingRecipients', 'lg', { mailing: $scope.mailing });
        }

        function openMailingPendingModal() {
            modal.open('mailingPending', 'lg', { mailing: $scope.mailing });
        }

        function openMailingDeliveriesModal() {
            modal.open('mailingDeliveries', 'lg', { mailing: $scope.mailing });
        }

        function openMailingFailuresModal() {
            modal.open('mailingFailures', 'lg', { mailing: $scope.mailing });
        }

        function openMailingOpensModal() {
            modal.open('mailingOpens', 'lg', { mailing: $scope.mailing });
        }

        function openDeleteMailingModal() {
            MailingService.openDeleteMailingModal($scope.mailing.id, function () {
                $state.transitionTo('mail.campaigns.campaign', { campaignId: $scope.mailing.campaign_id });
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: mailing.id, modelType: 'mailing' });
        }
    }
})(angular);
