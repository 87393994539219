(function (angular) {
    angular
        .module('one.admin')
        .controller('FormEntriesController', FormEntriesController);

    FormEntriesController.$inject = ['$scope', 'FormService', 'FormEntryService', 'dataframe', 'modal', 'session', 'formatDateFilter', 'form', 'visitablePages'];

    function FormEntriesController($scope, FormService, FormEntryService, dataframe, modal, session, formatDateFilter, form, visitablePages) {
        $scope.form = form;
        $scope.visitablePages = visitablePages;

        $scope.openPublishFormModal = FormService.openPublishFormModal;

        var name = 'form_' + form.id + '.entries';

        $scope.entries = dataframe.init({
            name: name,
            externalData: getEntries,
            cols: [
                {
                    name: 'accounts.title',
                    title: 'Contact',
                    display: function (row) {
                        if (row.account) {
                            var icon = 'person';

                            if (row.account.account_type_name == 'organisation') {
                                icon = 'business';
                            }

                            if (row.account.account_type_name == 'team') {
                                icon = 'group';
                            }

                            return '<h4><i class="md-icon">' + icon + '</i>{{ row.account.title }}</h4>';
                        }

                        return '<span class="text-muted">{{ \'general.anonymous\' | translate }}</span>';
                    },
                    weight: 1
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    display: function (row) {
                        return formatDateFilter(row.created_at);
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                return 'crm.forms.formEntry({formEntryId: ' + row.id + '})';
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.forms.editFormEntry({formId: ' + row.form.id + ', formEntryId: ' + row.id + '})';
                    },
                    visible: function () {
                        return form.editable_entries;
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteFormEntryModal(row.id);
                    },
                    visible: function () {
                        return form.editable_entries;
                    }
                }
            ],
            filterables: [
                'created_at'
            ],
            state: session.get('data.dataframes.' + name, { sort: { created_at: 'desc' } }),
            batchLimits: {
                export: 5000
            }
        });

        session.sync('data.dataframes.' + name, $scope.entries.state);

        $scope.openDeleteFormEntryModal = openDeleteFormEntryModal;

        function getEntries(params) {
            return FormEntryService.entries($scope.form.id, params);
        }

        function openDeleteFormEntryModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this form entry?' }).result.then(function (data) {
                deleteFormEntry(id);
            });
        }

        function deleteFormEntry(id) {
            FormEntryService.deleteFormEntry(id).then(function (data) {
                $scope.entries.getData();
            });
        }
    }
})(angular);
