(function (angular) {
    angular
        .module('one.admin')
        .directive('actionCampaignTypeIcon', actionCampaignTypeIconDirective);

    actionCampaignTypeIconDirective.$inject = [];

    function actionCampaignTypeIconDirective() {
        return {
            restrict: 'EA',
            scope: {
                actionCampaign: '=',
                type: '@',
            },
            replace: true,
            controller: ['$scope', 'ActionCampaignService', function ($scope, ActionCampaignService) {
                $scope.icons = ActionCampaignService.typeIcons;
            }],
            template: '<i class="md-icon">{{ icons[actionCampaign.type || type] }}</i>'
        };
    }
}(angular));
