(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountAttachmentsController', AccountAttachmentsController);

    AccountAttachmentsController.$inject = ['$scope', '$rootScope', '$filter', '$timeout', '$state', 'AccountService', 'ActionCampaignService', 'AuthService', 'BankAccountService', 'InteractionService', 'FileService', 'FormEntryService', 'GroupService', 'RelationService', 'MailService', 'VariableService', 'config', 'notification', 'modal', 'moment', 'dataframe', 'string', 'account'];

    function AccountAttachmentsController($scope, $rootScope, $filter, $timeout, $state, AccountService, ActionCampaignService, AuthService, BankAccountService, InteractionService, FileService, FormEntryService, GroupService, RelationService, MailService, VariableService, config, notification, modal, moment, dataframe, string, account) {
        $scope.account = account;

        $scope.attachments = dataframe.init({
            externalData: function (params) {
                return AccountService.account.attachments($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Filename',
                    name: 'filename',
                    weight: 2,
                    display: function (row) {
                        return '<h4><i class="fas fa-' + FileService.filenameToFaIcon(row.filename) + '"></i> {{ row.client_filename }}</h4>';
                    },
                    sortable: true
                },
                {
                    title: 'Uploaded at',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'short');
                    },
                    sortable: true
                }
            ],
            showHeader: true,
            showStats: false,
            rowOptions: [
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteAttachmentModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('files.delete');
                    }
                }
            ],
            rowHref: function (row) {
                if (AuthService.userCanAccess('files.download')) {
                    return config.path.api('files/' + row.id + '/download/' + row.client_filename);
                }
                return false;
            },
            state: {
                sort: { created_at: 'desc' }
            }
        });

        $scope.openAddAttachmentModal = openAddAttachmentModal;

        function openAddAttachmentModal(size) {
            modal.open('addFile', size, { data: { account: { id: account.id } } }).result.then(function (data) {
                $scope.attachments.getData();
            });
        }

        function openDeleteAttachmentModal(id, size) {
            modal.open('confirm', size, {
                title: 'Are you sure?',
                message: 'Delete this attachment?'
            }).result.then(function (data) {
                FileService.deleteFile(id).then(function (data) {
                    $scope.attachments.getData();
                }, function (errors) {
                    notification.error(errors['all'][0]);
                });
            });
        }
    }
})(angular);
