(function (angular) {
    angular
        .module('one.admin')
        .controller('CampaignController', CampaignController);

    CampaignController.$inject = ['$scope', '$state', 'AuthService', 'CampaignService', 'MailingService', 'config', 'dataframe', 'modal', 'notification', 'session', 'campaign'];

    function CampaignController($scope, $state, AuthService, CampaignService, MailingService, config, dataframe, modal, notification, session, campaign) {
        $scope.campaign = campaign;

        $scope.mailings = dataframe.init({
            externalData: function (params) {
                return CampaignService.mailings(campaign.id, params);
            },
            cols: [
                {
                    title: 'Mailing',
                    name: 'title',
                    display: function (row) {
                        return '<h4 class="text-limiter"><i class="md-icon">{{ row.draft ? \'drafts\' : \'mail\' }}</i> {{ row.title }}</h4>';
                    },
                    weight: 6,
                    sortable: true
                },
                {
                    title: 'Recipients',
                    name: 'recipient_count',
                    display: function (row) {
                        return '{{ row.recipient_count | number }}';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Deliveries',
                    name: 'delivery_count',
                    display: function (row) {
                        return '{{ row.delivery_count | number }}';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Delivery rate',
                    name: 'delivery_count',
                    display: function (row) {
                        var rate = Math.round(row.delivery_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        var className = rate == 0 ? 'text-muted' : (rate < 98 ? 'text-danger' : (rate < 100 ? 'text-warning' : 'text-success'));

                        return '<span class="' + className + '" uib-tooltip="{{ \'general.deliveries\' | translate }}: ' + row.delivery_count + ', {{ \'general.bounces\' | translate }}: ' + row.failed_count + '">' +
                            '{{ ' + rate + ' | number }}' + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 2,
                    sortable: false
                },
                {
                    title: 'Open rate',
                    name: 'open_count',
                    display: function (row) {
                        var rate = Math.round(row.open_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        var className = rate == 0 ? 'text-muted' : (rate < 50 ? 'text-danger' : (rate < 75 ? 'text-warning' : 'text-success'));

                        return '<span class="' + className + '" uib-tooltip="{{ \'general.opens\' | translate }}: ' + row.open_count + '">' +
                            '{{ ' + rate + ' | number }}' + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 2,
                    sortable: false
                },
                {
                    title: 'Scheduled',
                    name: 'automatic',
                    display: function (row) {
                        var indicator = '';

                        if (row.automatic) {
                            var indicator = '<small class="mail-scheduled-label"><i class="md-icon">schedule</i> ';

                            if (row.scheduled_frequency == 'once') {
                                indicator += "{{ row.scheduled_date | formatDate }} {{ 'general.at' | translate }} {{ row.formatted_scheduled_time }}";
                            } else if (row.scheduled_frequency == 'daily') {
                                indicator += "{{ 'general.daily' | translate }} {{ 'general.at' | translate }} {{ row.formatted_scheduled_time }}";
                            } else if (row.scheduled_frequency == 'always') {
                                indicator += "{{ 'general.continuous' | translate }}";
                            }

                            indicator += '</small>';

                            if (row.scheduled_frequency != 'once' && moment(row.scheduled_date).isAfter()) {
                                return '<span class="text-muted">' + indicator + '</span>';
                            }
                        }

                        return indicator;
                    },
                    weight: 3,
                    sortable: false
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('mailings.view')) {
                    return 'mail.campaigns.mailings.mailing({campaignId: ' + $scope.campaign.id + ', mailingId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'mail.campaigns.mailings.editMailing({campaignId: ' + $scope.campaign.id + ', mailingId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('mailings.edit');
                    }
                },
                {
                    title: 'Preview',
                    icon: 'pageview',
                    href: function (row) {
                        return config.path.api('mailings/' + row.id + '/preview');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.mailings.loading = true;

                        MailingService.duplicateMailing(row).then(function () {
                            $scope.mailings.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('campaigns.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        MailingService.openDeleteMailingModal(row.id, function () {
                            $scope.mailings.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('mailings.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.campaign', {
                sort: { title: 'asc' }
            })
        });

        session.sync('data.dataframes.campaign', $scope.mailings.state);

        $scope.openAddMailingModal = openAddMailingModal;
        $scope.openDeleteCampaignModal = openDeleteCampaignModal;
        $scope.openAuditorModal = openAuditorModal;

        function openAddMailingModal() {
            modal.open('addMailing', null, { campaign: $scope.campaign }).result.then(function (data) {
                $state.transitionTo('mail.campaigns.mailings.mailing', { campaignId: $scope.campaign.id, mailingId: data.mailing.id });
            });
        }

        function openDeleteCampaignModal() {
            CampaignService.openDeleteCampaignModal($scope.campaign.id, function () {
                $state.transitionTo('mail.campaigns.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: campaign.id, modelType: 'campaign' });
        }
    }
})(angular);
