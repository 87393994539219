(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$window'];

    function run($rootScope, $window) {
        $rootScope.changed = false;

        $rootScope.setChanged = function (changed) {
            cleanup();

            $rootScope.changed = changed;

            if (changed) {
                // When refreshing/closing the window, show alert
                $window.addEventListener('beforeunload', handleUnload);
            }
        };

        $rootScope.$on('$stateChangeStart', function (event) {
            // When navigating away within the app, check for changes
            if ($rootScope.changed) {
                if (!$window.confirm('Discard unsaved changes?')) {
                    event.preventDefault();
                } else {
                    cleanup();
                }
            }
        });

        function cleanup() {
            $window.removeEventListener('beforeunload', handleUnload);
            $rootScope.changed = false;
        }

        function handleUnload(event) {
            event.preventDefault();
            event.returnValue = 'You have unsaved changes';
        }
    }
})(angular);
