(function (angular) {
    angular
        .module('one.admin')
        .controller('AddPdfTemplateController', AddPdfTemplateController);

    AddPdfTemplateController.$inject = ['$scope', '$state', 'PdfTemplateService', 'notification'];

    function AddPdfTemplateController($scope, $state, PdfTemplateService, notification) {
        $scope.pdfTemplate = {
            title: '',
            content: ''
        };

        $scope.addPdfTemplate = addPdfTemplate;

        function addPdfTemplate(data) {
            PdfTemplateService.addPdfTemplate(data).then(function (data) {
                $state.transitionTo('content.pdfTemplates.index');
            });
        }
    }
})(angular);
