(function (angular) {
    angular
        .module('one.admin')
        .directive('addContactForm', addContactFormDirective);

    addContactFormDirective.$inject = ['config'];

    function addContactFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                account: '='
            },
            controller: ['$scope', 'AccountService', function ($scope, AccountService) {
                $scope.account = $scope.account || {};
                $scope.account.type = { name: 'contact' };
                $scope.genders = [];

                AccountService.genders().then(function (genders) {
                    $scope.genders = genders;
                });

                $scope.state = {};

                $scope.toggleExtraFields = toggleExtraFields;
                $scope.formatGender = AccountService.formatGender;

                function toggleExtraFields() {
                    $scope.state.expand = !$scope.state.expand;

                    if (!$scope.state.expand) {
                        var keys = ['email', 'phone_private', 'phone_work', 'phone_home', 'phone_emergency', 'gender', 'date_of_birth'];

                        angular.forEach(keys, function (key) {
                            delete $scope.account[key];
                        });
                    }
                }
            }],
            templateUrl: config.path.template('accounts.add_contact_form')
        };
    }
}(angular));
