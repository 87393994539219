(function (angular) {
    angular
        .module('one.admin')
        .controller('PageBlocksController', PageBlocksController);

    PageBlocksController.$inject = ['$scope', 'session', 'modal', 'AuthService', 'PageService', 'dataframe', 'modal', 'notification', 'shortenFilter'];

    function PageBlocksController($scope, session, modal, AuthService, PageService, dataframe, modal, notification, shortenFilter) {
        var currentSite = session.get('data.current_site');
        var sessionName = 'data.dataframes.pageBlocks.' + currentSite.name;

        $scope.site = angular.copy(currentSite);

        $scope.pageBlocks = dataframe.init({
            externalData: function (params) {
                params.filters = {
                    sites: [currentSite.id]
                };

                return PageService.blocks(params);
            },
            cols: [
                {
                    title: 'Page block',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">code</i> {{ row.title }}</h4>';
                    },
                    sortable: true,
                    weight: 2
                },
                {
                    title: 'Header',
                    name: 'header',
                    display: function (row) {
                        return '<i class="md-icon" ng-if="row.header">check</i>'
                    },
                    sortable: true,
                    weight: 1
                },
                {
                    title: 'Footer',
                    name: 'footer',
                    display: function (row) {
                        return '<i class="md-icon" ng-if="row.footer">check</i>'
                    },
                    sortable: true,
                    weight: 1
                },
                {
                    title: 'Language',
                    name: 'language',
                    display: function (row) {
                        if (row.language) {
                            return '{{ \'languages.' + row.language.name + '\' | translate }}';
                        }

                        return '<span class="text-muted">{{ \'languages.' + row.site.language_name + '\' | translate }} ({{ \'general.default\' | translate }})</span>';
                    },
                    weight: 1
                },
                {
                    title: 'Pages',
                    name: 'pages',
                    display: function (row) {
                        return '{{ row.pages.length | number }}';
                    },
                    weight: 1
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('page_blocks.edit')) {
                    return 'content.pageBlocks.editPageBlock({pageBlockId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'content.pageBlocks.editPageBlock({pageBlockId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('page_blocks.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.pageBlocks.loading = true;

                        PageService.duplicateBlock(row).then(function () {
                            $scope.pageBlocks.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('page_blocks.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeletePageBlockModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('page_blocks.delete');
                    }
                }
            ],
            state: session.get(sessionName, {
                sort: { title: 'asc' }
            })
        });

        session.sync(sessionName, $scope.pageBlocks.state);

        $scope.togglePageBlockVisibility = togglePageBlockVisibility;
        $scope.openAuditorModal = openAuditorModal;

        function openDeletePageBlockModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this page block?' }).result.then(function (data) {
                deletePageBlock(id);
            });
        }

        function deletePageBlock(id) {
            PageService.deleteBlock(id).then(function (data) {
                $scope.pageBlocks.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }

        function togglePageBlockVisibility(id) {
            return id;
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.site.id, modelType: 'site' });
        }
    }
})(angular);
