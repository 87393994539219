(function (angular) {
    angular
        .module('one.admin')
        .controller('PdfTemplateFormController', PdfTemplateFormController);

    PdfTemplateFormController.$inject = ['$scope', 'PdfTemplateService'];

    function PdfTemplateFormController($scope, PdfTemplateService) {

    }
})(angular);
