(function (angular) {
    angular
        .module('one.admin')
        .controller('AddProductCategoryModalController', AddProductCategoryModalController);

    AddProductCategoryModalController.$inject = ['$scope', '$uibModalInstance', 'ProductService', 'params'];

    function AddProductCategoryModalController($scope, $uibModalInstance, ProductService, params) {
        angular.extend($scope, params);

        if (!$scope.category) {
            $scope.category = {};
        }

        $scope.add = add;
        $scope.cancel = cancel;
        $scope.getProductCategorys = ProductService.productCategories;
        $scope.formatProductCategoryTitle = ProductService.formatProductCategoryTitle;

        function add() {
            ProductService.addProductCategory($scope.category).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
