(function (angular) {
    angular
        .module('one.admin')
        .controller('SddPaymentFormController', SddPaymentFormController);

    SddPaymentFormController.$inject = ['$scope','PaymentMethodService','AccountService'];

    function SddPaymentFormController($scope, PaymentMethodService, AccountService) {
        $scope.getPaymentMethods = PaymentMethodService.paymentMethods;
        $scope.getBankAccounts = getBankAccounts;

        function getBankAccounts() {
            if ($scope.order.account) {
                AccountService.account.bankAccounts($scope.order.account.id).then(function (data) {
                    angular.forEach(data, function (bankAccount) {
                        bankAccount.title = bankAccount.iban;

                        if (bankAccount.account_holder) {
                            bankAccount.title += ", " + bankAccount.account_holder;
                        }
                        
                        if (bankAccount.default) {
                            bankAccount.title += " (default)";
                            $scope.order.bank_account = bankAccount;
                        }
                    });
                    $scope.bankAccounts = data;
                });
            }
        }

        $scope.$watch(
            function () {
                if ($scope.order.account) {
                    return $scope.order.account.id;
                } else {
                    return null;
                }
            },
            function (newValue, oldValue) {
                getBankAccounts();
            });

    }
})(angular);
