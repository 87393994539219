(function (angular) {
    angular
        .module('one.admin')
        .controller('ActionProjectController', ActionProjectController);

    ActionProjectController.$inject = ['$scope', '$state', 'ActionProjectService', 'ActionCampaignService', 'GiftService', 'modal', 'actionProject'];

    function ActionProjectController($scope, $state, ActionProjectService, ActionCampaignService, GiftService, modal, actionProject) {
        $scope.actionProject = actionProject;

        var chunkSize = 6;
        var i = 0;
        var chunk;

        $scope.groups = [];

        while (i < actionProject.groups.length) {
            chunk = [];

            for (var j = 0; j < chunkSize && i < actionProject.groups.length; j++) {
                chunk.push(actionProject.groups[i]);
                i++;
            }

            $scope.groups.push(chunk);
        }

        ActionProjectService.stats({
            filters: {
                action_projects: [$scope.actionProject.id]
            }
        }).then(function (stats) {
            $scope.stats = stats;
        });

        $scope.getActionCampaigns = function (params) {
            params.filters = angular.merge({}, params.filters, { action_projects: [$scope.actionProject.id] });

            return ActionCampaignService.actionCampaigns(params);
        };

        $scope.getGifts = function (params) {
            params.filters = angular.merge({}, params.filters, { action_projects: [$scope.actionProject.id] });

            return GiftService.gifts(params);
        };

        $scope.getGiftRewards = function (params) {
            params.filters = angular.merge({}, params.filters, { action_projects: [$scope.actionProject.id] });

            return GiftService.giftRewards(params);
        };

        $scope.openAuditorModal = openAuditorModal;
        $scope.openAddGiftRewardModal = openAddGiftRewardModal;
        $scope.openDeactivateActionProjectModal = openDeactivateActionProjectModal;
        $scope.openReactivateActionProjectModal = openReactivateActionProjectModal;
        $scope.openDeleteActionProjectModal = openDeleteActionProjectModal;

        function openAddGiftRewardModal(size) {
            modal.open('addGiftReward', size, { giftReward: { action_project: { id: actionProject.id } } }).result.then(function (data) {
                $state.reload();
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: actionProject.id, modelType: 'action_project' });
        }

        function openDeactivateActionProjectModal() {
            return ActionProjectService.openDeactivateActionProjectModal($scope.actionProject.id, function () {
                $state.reload($state.current);
            });
        }

        function openReactivateActionProjectModal() {
            return ActionProjectService.openReactivateActionProjectModal($scope.actionProject.id, function () {
                $state.reload($state.current);
            });
        }

        function openDeleteActionProjectModal() {
            return ActionProjectService.openDeleteActionProjectModal($scope.actionProject.id, function () {
                $state.go('crowdfunding.actionProjects.index');
            });
        }
    }
})(angular);
