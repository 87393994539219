(function (angular) {
    angular
        .module('one.admin')
        .directive('newsFeedModuleForm', newsFeedModuleFormDirective);

    newsFeedModuleFormDirective.$inject = ['config'];

    function newsFeedModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                newsModules: '=',
                module: '='
            },
            templateUrl: config.path.template('modules.news-feed.form'),
            controller: ['$scope', 'NewsModuleService', function ($scope, NewsModuleService) {
                $scope.stagedNewsModule = null;

                $scope.layouts = {
                    'columns': 'columns',
                    'list': 'list'
                };

                $scope.pages = {};

                angular.forEach($scope.newsModules, function (newsModule) {
                    $scope.pages[newsModule.id] = newsModule.module.pages.map(function (page) { return page.title; }).join(', ');
                });

                $scope.addNewsModule = function (stagedNewsModule) {
                    if (!stagedNewsModule) {
                        return
                    }

                    var existingNewsModules = $scope.module.module_instance.news_modules.filter(function(newsModule) {
                        return newsModule.id == stagedNewsModule.id
                    });

                    if (!existingNewsModules.length) {
                        $scope.module.module_instance.news_modules.push(stagedNewsModule);
                    }

                    $scope.stagedNewsModule = null;
                };

                $scope.formatNewsModuleTitle = NewsModuleService.formatNewsModuleTitle;
            }]
        };
    }
}(angular));
