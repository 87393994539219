(function (angular) {
    angular
        .module('one.admin')
        .service('NewsModuleService', NewsModuleService);

    NewsModuleService.$inject = ['http', 'PostService'];

    function NewsModuleService(http, PostService) {
        function newsModules() {
            return http.get('modules/news');
        }

        function post(newsModuleId, postId, params) {
            return http.get('modules/news/' + newsModuleId + '/posts/' + postId, params);
        }

        function posts(newsModuleId, params) {
            return http.get('modules/news/' + newsModuleId + '/posts', params);
        }

        function addPost(newsModuleId, data, params) {
            data.owner_id = newsModuleId;
            data.owner_type = 'news_module';

            return http.post('modules/news/' + newsModuleId + '/posts/add', data, params);
        }

        function formatNewsModuleTitle(newsModule) {
            var title = newsModule.title;

            var module = newsModule.module;

            if (module) {
                var pages = module.pages;

                if (pages) {
                    title += ' <span class="text-muted">(' + pages.map(function (page) { return page.title; }).join(', ') + ')</span>';
                }
            }

            return title;
        }

        return {
            newsModules: newsModules,
            post: post,
            posts: posts,
            addPost: addPost,
            formatNewsModuleTitle: formatNewsModuleTitle,
        };
    }
})(angular);
