(function (angular) {
    angular
        .module('one.admin')
        .controller('AddInteractionSubjectModalController', AddInteractionSubjectModalController);

    AddInteractionSubjectModalController.$inject = ['$scope', '$uibModalInstance', 'InteractionService', 'params'];

    function AddInteractionSubjectModalController($scope, $uibModalInstance, InteractionService, params) {
        $scope.subject = {
            title: '',
            template: ''
        };

        $scope.add = add;
        $scope.cancel = cancel;

        function add() {
            InteractionService.addInteractionSubject($scope.subject).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
