(function (angular) {
    angular
        .module('one.admin')
        .controller('PublishFormModalController', PublishFormModalController);

    PublishFormModalController.$inject = ['$scope', '$uibModalInstance', 'FormService', 'translateFilter', 'sites', 'params'];

    function PublishFormModalController($scope, $uibModalInstance, FormService, translateFilter, sites, params) {
        $scope.config = {site : null};
        $scope.sites = sites;

        angular.extend($scope, params);

        $scope.publish = publish;
        $scope.cancel = cancel;

        function publish(data) {
            FormService.publishForm($scope.form.id, $scope.config).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
