(function (angular) {
    angular
        .module('one.admin')
        .service('PersonalMailService', PersonalMailService);

    PersonalMailService.$inject = ['http'];

    function PersonalMailService(http) {
        function sendPersonalMail(data) {
            return http.post('mailings/send_personal_mail', data);
        }

        function getVariables() {
            return [
                'account.first_name',
                'account.full_name',
                'sender.signature | raw'
            ];
        }

        return {
            sendPersonalMail: sendPersonalMail,
            getVariables: getVariables
        };
    }
})(angular);
