(function (angular) {
    angular
        .module('one.admin')
        .service('PostService', PostService);

    PostService.$inject = ['http', 'shortenFilter'];

    function PostService(http, shortenFilter) {
        function post(id) {
            return http.get('posts/' + id);
        }

        function posts(params) {
            return http.get('posts', params);
        }

        function addPost(data) {
            return http.post('posts/add', data);
        }

        function editPost(data) {
            return http.post('posts/' + data.id + '/edit', data);
        }

        function deletePost(id) {
            return http.post('posts/' + id + '/delete');
        }

        function formatPostTitle(post) {
            return shortenFilter(post.title, 30);
        }

        return {
            post: post,
            posts: posts,
            addPost: addPost,
            editPost: editPost,
            deletePost: deletePost,
            formatPostTitle: formatPostTitle
        };
    }
})(angular);
