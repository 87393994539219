(function (angular) {
    angular
        .module('one.admin')
        .controller('ModulesController', ModulesController);

    ModulesController.$inject = ['$scope', 'ModuleService', 'PageService', 'AuthService', 'dataframe', 'modal', 'notification', 'session', 'string', 'shortenFilter', 'moduleTypes'];

    function ModulesController($scope, ModuleService, PageService, AuthService, dataframe, modal, notification, session, string, shortenFilter, moduleTypes) {
        var currentSite = session.get('data.current_site');
        var sessionName = 'data.dataframes.modules.' + currentSite.name;

        $scope.modules = dataframe.init({
            externalData: function (params) {
                params = params || {};

                params.filters = angular.merge({}, params.filters, {
                    sites: [currentSite.id]
                });

                return ModuleService.modules(params);
            },
            cols: [
                {
                    title: 'Module',
                    name: 'label',
                    display: function (row) {
                        return '<h4><i module-icon name="row.type.name"></i> {{ row.label }}</h4>';
                    },
                    sortable: true,
                    weight: 4
                },
                {
                    title: 'Type',
                    name: 'type',
                    value: function (row) {
                        return ' {{ \'module_types.\' + row.type.name | translate }}';
                    },
                    weight: 2
                },
                {
                    title: 'Used on',
                    weight: 4,
                    display: function (row) {
                        row.page_titles = [];

                        angular.forEach(row.pages, function (page) {
                            row.page_titles.push('<a ui-sref="content.pages.pageContent({pageId: ' + page.id + '})" ng-click="$event.stopPropagation()">' + string.escape(shortenFilter(page.title, 20)) + '</a>');
                        });

                        row.pages_delimiter = ' <span class="text-muted">/</span> ';

                        return '<span list-limiter list="row.page_titles" delimiter="row.pages_delimiter"></span>';
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('modules.edit')) {
                    return 'content.modules.' + string.snakeToCamel(row.type.name) + 'Module.' + 'index({moduleId: row.id, moduleName: row.type.name})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return ModuleService.getState(row) + '({moduleId: row.id})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('modules.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.modules.loading = true;

                        ModuleService.duplicateModule(row).then(function () {
                            $scope.modules.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('modules.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteModuleModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('modules.delete');
                    }
                }
            ],
            state: session.get(sessionName, {
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                },
                sort: { label: 'asc' }
            })
        });

        $scope.moduleTypes = moduleTypes;

        session.sync(sessionName, $scope.modules.state);

        var params = {
            filters: { sites: [currentSite.id] }
        };

        $scope.moduleTypeFilters = [];

        ModuleService.types(params).then(function (moduleTypes) {
            $scope.moduleTypeFilters = dataframe.initFilterList(moduleTypes || [], 'id', 'name');
        });

        $scope.pageFilters = [];

        PageService.pages(params).then(function (pages) {
            $scope.pageFilters = dataframe.initFilterList(pages || [], 'id', 'title');
        });

        $scope.formatModuleTypeTitle = formatModuleTypeTitle;

        function formatModuleTypeTitle(filter) {
            return ModuleService.formatModuleTypeTitle({ name: filter.label });
        }

        function openDeleteModuleModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this module?' }).result.then(function (data) {
                deleteModule(id);
            });
        }

        function deleteModule(id) {
            ModuleService.deleteModule(id).then(function (data) {
                $scope.modules.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }
    }
})(angular);
