(function (angular) {
    angular
        .module('one.admin')
        .controller('FinanceDashboardController', FinanceDashboardController);

    FinanceDashboardController.$inject = ['$scope', '$filter', '$locale', 'FinanceDashboardService', 'chartBuilder', 'moment', 'paymentMethods', 'productCategories', 'vatPercentages', 'miscProducts', 'purchaseSources', 'purchaseDestinations'];

    function FinanceDashboardController($scope, $filter, $locale, FinanceDashboardService, chartBuilder, moment, paymentMethods, productCategories, vatPercentages, miscProducts, purchaseSources, purchaseDestinations) {
        $scope.purchaseSources = purchaseSources;
        $scope.purchaseDestinations = purchaseDestinations;
        $scope.paymentMethods = paymentMethods;
        $scope.productCategories = productCategories;
        $scope.vatPercentages = vatPercentages;

        $scope.purchaseSources.push({id: '', title: 'No source'});
        $scope.purchaseDestinations.push({id: '', title: 'No destination'});

        var emptyProductCategory = {
            id: '',
            title: 'No product category',
            products: miscProducts
        };

        $scope.productCategories.push(emptyProductCategory);

        $scope.totals = {
            purchase_sources: {},
            purchase_destinations: {},
            payment_methods: {},
            product_categories: {},
            vat_percentages: {}
        };

        var endDate = moment().endOf('month');
        var startDate = endDate.clone().subtract(11, 'months').startOf('day').startOf('month')

        $scope.params = {
            start_date: startDate.format($locale.DATETIME_FORMATS.parsedDate),
            end_date: endDate.format($locale.DATETIME_FORMATS.parsedDate)
        };

        $scope.state = {
            loading: false,
            initializing: true
        };

        $scope.filters = {
            purchase_sources: [],
            purchase_destinations: [],
            payment_methods: [],
            product_categories: [],
            vat_percentages: [],
            dates: [],
        };

        $scope.activeFilters = angular.copy($scope.filters);
        $scope.activeParams = angular.copy($scope.params);

        $scope.displayType = 'gross';

        $scope.data = {};

        getTotals();

        $scope.hideFilters = {
            purchase_sources: true,
            purchase_destinations: true,
            product_categories: true,
            payment_methods: true,
            vat_percentages: true
        };

        $scope.hideCategories = {
            timeline: true
        };

        $scope.showProducts = {};

        $scope.applyParams = applyParams;
        $scope.updateStartDate = updateStartDate;
        $scope.updateEndDate = updateEndDate;
        $scope.paramsHaveChanges = paramsHaveChanges;
        $scope.setDisplayType = setDisplayType;
        $scope.displayAmount = displayAmount;
        $scope.applyFilters = applyFilters;
        $scope.resetFilters = resetFilters;
        $scope.filtersHaveChanges = filtersHaveChanges;
        $scope.hiddenFiltersCount = hiddenFiltersCount;
        $scope.toggleFilter = toggleFilter;
        $scope.filterIsActive = filterIsActive;
        $scope.filterIsSelected = filterIsSelected;
        $scope.filterIsVisible = filterIsVisible;
        $scope.toggleCategory = toggleCategory;

        function applyParams() {
            if (!$scope.params.start_date || !$scope.params.end_date) {
                return;
            }

            getTotals(function (totals) {
                $scope.params = angular.copy(totals.params);
                $scope.activeParams = angular.copy(totals.params);
            });
        }

        function updateStartDate(change) {
            var date = moment($scope.params['start_date']).startOf('day');

            if (date.clone().startOf('month').format('D') == date.format('D') || change > 0) {
                date = date.add(change, 'months');
            }

            date = date.startOf('month');

            $scope.params['start_date'] = date.format($locale.DATETIME_FORMATS.parsedDate);
        }

        function updateEndDate(change) {
            var date = moment($scope.params['end_date']).startOf('day');

            if (date.clone().endOf('month').format('D') == date.format('D') || change < 0) {
                date = date.add(change, 'months');
            }

            date = date.endOf('month');

            $scope.params['end_date'] = date.format($locale.DATETIME_FORMATS.parsedDate);
        }

        function paramsHaveChanges() {
            return !angular.equals($scope.activeParams, $scope.params);
        }

        function setDisplayType(type) {
            $scope.displayType = type;

            buildChart();
        }

        function displayAmount(item, key) {
            if (!item) {
                return 0;
            }

            if ($scope.displayType == 'gross') {
                return item[key + '_amount'] + item[key + '_vat_amount'];
            }

            if ($scope.displayType == 'net') {
                return item[key + '_amount'];
            }

            if ($scope.displayType == 'vat') {
                return item[key + '_vat_amount'];
            }
        }

        function applyFilters(category) {
            getTotals(function () {
                $scope.hideFilters[category] = true;
                $scope.activeFilters = angular.copy($scope.filters);
            });
        }

        function resetFilters(category) {
            $scope.filters[category] = [];

            applyFilters(category);
        }

        function filtersHaveChanges(category) {
            return !angular.equals($scope.activeFilters[category], $scope.filters[category]);
        }

        function hiddenFiltersCount(category, items) {
            if ($scope.activeFilters[category].length > 0) {
                var filters = $scope.activeFilters[category].concat($scope.filters[category]);

                filters = filters.filter(function (item, index) {
                    return filters.indexOf(item) === index;
                });

                return items.length - filters.length;
            }

            var count = items.length - Object.keys($scope.totals[category]).length;

            angular.forEach($scope.totals[category], function (item, value) {
                if (totalAmount(item) == 0 && !filterIsSelected(category, value)) {
                    count++;
                }
            });

            return count;
        }

        function getTotals(callback) {
            if ($scope.state.loading) {
                return;
            }

            $scope.state.loading = true;

            FinanceDashboardService.totals(angular.merge({}, $scope.params, { filters: $scope.filters })).then(function (totals) {
                $scope.totals = angular.merge({
                    payment_methods: {},
                    product_categories: {},
                    vat_percentages: {},
                    dates: {}
                }, totals);

                if (callback) {
                    callback(totals);
                }

                buildChart();

                $scope.state.loading = false;
                $scope.state.initializing = false;
            });
        }

        function toggleFilter(category, value) {
            var index = $scope.filters[category].indexOf(value);

            if (index > -1) {
                $scope.filters[category].splice(index, 1);
            } else {
                $scope.filters[category].push(value);
            }
        }

        function filterIsActive(category, value) {
            return $scope.activeFilters[category].indexOf(value) > -1;
        }

        function filterIsSelected(category, value) {
            return $scope.filters[category].indexOf(value) > -1;
        }

        function filterIsVisible(category, value) {
            if (filterIsActive(category, value) || filterIsSelected(category, value)) {
                return true;
            }

            if ($scope.hideFilters[category]) {
                return totalAmount($scope.totals[category][value]) > 0;
            }

            return true;
        }

        function totalAmount(item) {
            return displayAmount(item, 'refunded') + displayAmount(item, 'paused') + displayAmount(item, 'open') + displayAmount(item, 'paid');
        }

        function buildChart() {
            var start = moment($scope.activeParams.start_date);
            var end = moment($scope.activeParams.end_date);
            var diff = end.diff(start, 'months');

            var indexDateFormat = 'YYYY-MM-DD';
            var labelDateFormat = 'D MMM YYYY';
            $scope.timeScale = 'daily';

            if (diff >= 24) {
                indexDateFormat = 'YYYY-Q'
                labelDateFormat = 'YYYY ([Q]Q)';
                $scope.timeScale = 'quarterly';
            } else if (diff >= 6) {
                indexDateFormat = 'YYYY-MM'
                labelDateFormat = 'MMM YYYY';
                $scope.timeScale = 'monthly';
            } else if (diff >= 2) {
                // GGGG = ISO-8601 week-numbering year. This has the same value as YYYY, except that if
                // the ISO week number (WW) belongs to the previous or next year, that year is used instead
                indexDateFormat = 'GGGG-WW'
                labelDateFormat = 'GGGG ([W]W)';
                $scope.timeScale = 'weekly';
            }

            var defaultItem = { open: 0, paid: 0, paused: 0, authorised: 0, refunded: 0 }

            var data = chartBuilder.prefillData($scope.activeParams.start_date, $scope.activeParams.end_date, defaultItem, indexDateFormat, labelDateFormat);

            angular.forEach($scope.totals.dates, function (item, date) {
                var index = moment(date).format(indexDateFormat);

                data[index].open += displayAmount(item, 'open');
                data[index].paid += displayAmount(item, 'paid');
                data[index].paused += displayAmount(item, 'paused');
                data[index].refunded += displayAmount(item, 'refunded');
                data[index].authorised += displayAmount(item, 'authorised');
            });

            $scope.data = data;

            $scope.chart = chartBuilder.createChart({
                series: ['authorised'],
                colors: ['rgba(117, 182, 54, .7)'],
                options: {
                    scales: {
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                callback: function (value) {
                                    return $filter('money')(value, '€ ');
                                }
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                callback: function (value, a) {
                                    return value;
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function () {
                                return [];
                            },
                            footer: function (tooltipItems, data) {
                                var total = 0;

                                angular.forEach(tooltipItems, function (tooltipItem) {
                                    total = total + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                });

                                return ['Total: ' + $filter('money')(total, '€ ')];
                            }
                        }
                    }
                }
            }, data);
        }

        function toggleCategory(category) {
            $scope.hideCategories[category] = !$scope.hideCategories[category];
        }
    }
})(angular);
