(function (angular) {
    angular
        .module('one.admin')
        .directive('productForm', productFormDirective);

    productFormDirective.$inject = ['config'];

    function productFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                product: '=',
                paymentMethods: '=',
                productCategories: '='
            },
            controller: ['$scope', 'ProductService', 'TransactionalMailService', function ($scope, ProductService, TransactionalMailService) {
                var originalCategory = $scope.product.category;

                enableSelectCategory();

                $scope.uploadedImage = null;

                $scope.vatPercentages = ProductService.vatPercentages;
                $scope.paymentTypes = ProductService.paymentTypes;

                $scope.addTerm = addTerm;
                $scope.deleteTerm = deleteTerm;
                $scope.updateAmounts = updateAmounts;
                $scope.updateAllAmounts = updateAllAmounts;
                $scope.enableSelectCategory = enableSelectCategory;
                $scope.enableNewCategory = enableNewCategory;
                $scope.addImage = addImage;
                $scope.deleteImage = deleteImage;
                $scope.formatProductCategoryTitle = ProductService.formatProductCategoryTitle;
                $scope.getTransactionalMails = TransactionalMailService.transactionalMails;
                $scope.formatTransactionalMailTitle = TransactionalMailService.formatTransactionalMailTitle;

                function enableSelectCategory() {
                    $scope.newCategory = false;
                    $scope.product.category = originalCategory;
                }

                function enableNewCategory() {
                    $scope.newCategory = true;
                    $scope.product.category = { title: '', new: true };
                }

                function addImage(image) {
                    $scope.product.images.push(image);
                }

                function deleteImage(index) {
                    $scope.product.images.splice(index, 1);
                }

                function addTerm() {
                    $scope.product.payment_terms.push({});
                }

                function deleteTerm(index) {
                    $scope.product.payment_terms.splice(index, 1);
                }

                function updateAmounts(obj) {
                    if ($scope.product.vat_inclusive) {
                        obj.vat_amount = calculateVatAmount(obj.gross_amount, $scope.product.vat_percentage, true);
                        obj.amount = obj.gross_amount - obj.vat_amount;
                    } else {
                        obj.vat_amount = calculateVatAmount(obj.amount, $scope.product.vat_percentage, false);
                        obj.gross_amount = obj.amount + obj.vat_amount;
                    }
                }

                function updateAllAmounts() {
                    updateAmounts($scope.product);

                    angular.forEach($scope.product.payment_terms, function (paymentTerm) {
                        updateAmounts(paymentTerm);
                    });
                }

                function calculateVatAmount(amount, percentage, inclusive) {
                    if (inclusive) {
                        return Math.round(amount / (100 + percentage) * percentage);
                    }

                    return Math.round(amount / 100 * percentage);
                }
            }],
            templateUrl: config.path.template('products.form')
        };
    }
}(angular));
