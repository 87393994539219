(function (angular) {
    angular
        .module('one.admin')
        .controller('InsertModuleModalController', InsertModuleModalController);

    InsertModuleModalController.$inject = ['$scope', '$uibModalInstance', '$timeout', 'modal', 'session', 'string', 'ModuleService', 'moduleTypes', 'params'];

    function InsertModuleModalController($scope, $uibModalInstance, $timeout, modal, session, string, ModuleService, moduleTypes, params) {
        var timeout;

        $scope.moduleTypes = moduleTypes;

        angular.extend($scope, params);

        $scope.focus = false;

        $timeout(function () {
            $scope.focus = true;
        }, 500);

        getModules();

        $scope.getModules = getModules;
        $scope.select = select;
        $scope.openAddModuleModal = openAddModuleModal;
        $scope.openExistingModuleModal = openExistingModuleModal;
        $scope.cancel = cancel;
        $scope.selectModuleType = selectModuleType;
        $scope.focusOnSelectedModuleType = focusOnSelectedModuleType;
        $scope.focusOnModuleType = focusOnModuleType;

        if ($scope.moduleTypes) {
            $scope.selectedModuleType = $scope.moduleTypes[0]
        }

        $scope.focusedModuleType = $scope.selectedModuleType;

        function focusOnModuleType(moduleType) {
            $scope.focusedModuleType = moduleType;
        }

        function focusOnSelectedModuleType() {
            $scope.focusedModuleType = $scope.selectedModuleType;
        }

        function selectModuleType(moduleType) {
            $scope.selectedModuleType = moduleType;
            $scope.focusedModuleType = moduleType;
        }

        function getModules(search) {
            var perPage = 50;

            if (!search) {
                perPage = 5;
            }

            $timeout.cancel(timeout);

            $scope.loading = true;

            timeout = $timeout(function () {
                ModuleService.modules({
                    search: search,
                    perPage: perPage,
                    sort: { updated_at: 'desc' },
                    site: {
                        id: session.get('data.current_site.id')
                    }
                }).then(function (response) {
                    $scope.modules = response.data;

                    $scope.loading = false;
                });
            }, 250);
        }

        function select(module) {
            $uibModalInstance.close({ module: module });
        }

        function openExistingModuleModal() {
            var promise = modal.open('existingModule').result;

            $uibModalInstance.close(promise);
        }

        function openAddModuleModal(type) {
            var promise = modal.open('add' + string.snakeToUpperCamel(type.name) + 'Module', 'lg', { module: { type: type } }).result;

            $uibModalInstance.close(promise);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
