(function (angular) {
    angular
        .module('one.admin')
        .directive('modelComparator', modelComparatorDirective);

    modelComparatorDirective.$inject = ['$parse'];

    function modelComparatorDirective($parse) {
        return {
            restrict: 'A',
            scope: {
                ngModel: "=",
                ngValue: "=",
                modelComparator: "@"
            },
            link: function (scope, element, attrs, ngModel) {
                if (scope.ngModel && scope.ngValue[scope.modelComparator] === scope.ngModel[scope.modelComparator]) {
                    scope.ngModel = scope.ngValue;
                }
            }
        };
    }
})(angular);
