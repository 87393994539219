(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'modalProvider', 'config'];

    function config($stateProvider, modalProvider, config) {
        $stateProvider.state('imports', {
            parent: 'finance',
            abstract: true,
            url: '/imports',
            template: '<div ui-view></div>',
            params: {
                section: 'finance'
            }
        });

        $stateProvider.state('imports.index', {
            url: '',
            controller: 'ImportsController',
            templateUrl: config.path.template('imports.index'),
            requirePermission: 'imports.overview'
        });

        $stateProvider.state('imports.addImport', {
            url: '/add',
            controller: 'AddImportController',
            templateUrl: config.path.template('imports.add'),
            requirePermission: 'imports.add'
        });

        $stateProvider.state('imports.import', {
            url: '/:importId',
            controller: 'ImportController',
            templateUrl: config.path.template('imports.import'),
            resolve: {
                importInstance: ['ImportService', '$stateParams', function (ImportService, $stateParams) {
                    return ImportService.getImport($stateParams.importId);
                }]
            },
            requirePermission: 'imports.view'
        });

        $stateProvider.state('exports', {
            parent: 'main',
            abstract: true,
            url: '/exports',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('exports.index', {
            url: '',
            controller: 'ExportsController',
            templateUrl: config.path.template('exports.index'),
            requirePermission: 'exports.overview'
        });

        $stateProvider.state('exports.scheduled', {
            url: '/scheduled',
            controller: 'ScheduledExportsController',
            templateUrl: config.path.template('exports.scheduled'),
            requirePermission: 'exports.overview'
        });

        $stateProvider.state('billing', {
            parent: 'main',
            abstract: true,
            url: '/billing',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('billing.index', {
            url: '',
            controller: 'BillingController',
            templateUrl: config.path.template('billing.index'),
            requirePermission: 'billing.view'
        });

        $stateProvider.state('ipLocations', {
            parent: 'main',
            abstract: true,
            url: '/ip-locations',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('ipLocations.index', {
            url: '',
            controller: 'IpLocationsController',
            templateUrl: config.path.template('ip_locations.index'),
            requirePermission: 'ip_locations.overview'
        });

        $stateProvider.state('ipLocations.addIpLocation', {
            url: '/add',
            controller: 'AddIpLocationController',
            templateUrl: config.path.template('ip_locations.add'),
            requirePermission: 'ip_locations.add'
        });

        $stateProvider.state('ipLocations.editIpLocation', {
            url: '/:ipLocationId/edit',
            controller: 'EditIpLocationController',
            templateUrl: config.path.template('ip_locations.edit'),
            resolve: {
                ipLocation: ['IpLocationService', '$stateParams', function (IpLocationService, $stateParams) {
                    return IpLocationService.ipLocation($stateParams.ipLocationId);
                }]
            },
            requirePermission: 'ip_locations.edit'
        });

        $stateProvider.state('ipLocations.ipLocation', {
            url: '/:ipLocationId',
            controller: 'IpLocationController',
            templateUrl: config.path.template('ip_locations.ip_location'),
            resolve: {
                ipLocation: ['IpLocationService', '$stateParams', function (IpLocationService, $stateParams) {
                    return IpLocationService.ipLocation($stateParams.ipLocationId);
                }]
            },
            requirePermission: 'ip_locations.view'
        });

        $stateProvider.state('users', {
            parent: 'main',
            abstract: true,
            url: '/users',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('users.index', {
            url: '',
            controller: 'UsersController',
            templateUrl: config.path.template('users.index'),
            requirePermission: 'users.overview'
        });

        $stateProvider.state('tags', {
            parent: 'main',
            abstract: true,
            url: '/tags',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('tags.index', {
            url: '',
            controller: 'TagsController',
            templateUrl: config.path.template('tags.index'),
            requirePermission: 'tags.overview'
        });

        $stateProvider.state('tags.editTag', {
            url: '/:tagId/edit',
            controller: 'EditTagController',
            templateUrl: config.path.template('tags.edit'),
            resolve: {
                tag: ['TagService', '$stateParams', function (TagService, $stateParams) {
                    return TagService.tag($stateParams.tagId);
                }],
                tags: ['TagService', function (TagService) {
                    return TagService.tags();
                }],
                colors: ['TagService', function (TagService) {
                    return TagService.colors();
                }]
            },
            requirePermission: 'tags.edit'
        });

        modalProvider.modal('addTag', {
            templateUrl: config.path.template('tags.add'),
            controller: 'AddTagModalController',
            resolve: {
                tags: ['TagService', function (TagService) {
                    return TagService.tags();
                }],
                colors: ['TagService', function (TagService) {
                    return TagService.colors();
                }]
            },
            backdrop: 'static',
            keyboard: false
        });

        $stateProvider.state('users.addUser', {
            url: '/add',
            controller: 'AddUserController',
            templateUrl: config.path.template('users.add'),
            resolve: {
                authRoles: ['AuthRoleService', function (AuthRoleService) {
                    return AuthRoleService.authRoles();
                }],
                ipLocations: ['IpLocationService', function (IpLocationService) {
                    return IpLocationService.ipLocations();
                }]
            },
            requirePermission: 'users.add'
        });

        $stateProvider.state('users.editUser', {
            url: '/:userId/edit',
            controller: 'EditUserController',
            templateUrl: config.path.template('users.edit'),
            resolve: {
                user: ['AuthService', '$stateParams', function (AuthService, $stateParams) {
                    return AuthService.projectUser($stateParams.userId);
                }],
                authRoles: ['AuthRoleService', function (AuthRoleService) {
                    return AuthRoleService.authRoles();
                }],
                ipLocations: ['IpLocationService', function (IpLocationService) {
                    return IpLocationService.ipLocations();
                }]
            },
            requirePermission: 'users.edit'
        });

        $stateProvider.state('users.accountSettings', {
            url: '/account-settings',
            controller: 'AccountSettingsController',
            templateUrl: config.path.template('users.account_settings'),
            resolve: {
                user: ['AuthService', function (AuthService) {
                    return AuthService.user();
                }]
            }
        });

        $stateProvider.state('users.user', {
            url: '/:userId',
            controller: 'UserController',
            templateUrl: config.path.template('users.user'),
            resolve: {
                user: ['AuthService', '$stateParams', function (AuthService, $stateParams) {
                    return AuthService.projectUser($stateParams.userId).then(function (user) {
                        return user;
                    });
                }]
            },
            requirePermission: 'users.view'
        });

        $stateProvider.state('activateUser', {
            url: '/activate-user/:userId/:token',
            controller: 'ActivateUserController',
            templateUrl: config.path.template('auth.activate_user')
        });

        $stateProvider.state('forgotPassword', {
            url: '/forgot-password',
            controller: 'ForgotPasswordController',
            templateUrl: config.path.template('auth.forgot_password')
        });


        $stateProvider.state('resetPassword', {
            url: '/reset-password/:token',
            controller: 'ResetPasswordController',
            templateUrl: config.path.template('auth.reset_password')
        });

        $stateProvider.state('login', {
            url: '/login',
            controller: 'LoginController',
            templateUrl: config.path.template('auth.login')
        });

        $stateProvider.state('logout', {
            url: '/logout',
            controller: 'LogoutController'
        });

        $stateProvider.state('gateway', {
            url: '/gateway',
            controller: 'GatewayController',
            resolve: {
                user: ['AuthService', function (AuthService) {
                    return AuthService.user();
                }]
            },
            templateUrl: config.path.template('auth.gateway')
        });

        $stateProvider.state('twoFactorAuthentication', {
            url: '/two-factor-authentication',
            controller: 'TwoFactorAuthenticationController',
            resolve: {
                user: ['AuthService', function (AuthService) {
                    return AuthService.user();
                }]
            },
            templateUrl: config.path.template('auth.two_factor_authentication')
        });

        $stateProvider.state('settings', {
            parent: 'main',
            abstract: true,
            template: '<div ui-view></div>'
        });

        modalProvider.modal('confirm', {
            templateUrl: config.path.template('common.modals.confirm'),
            controller: 'ConfirmModalController'
        });

        modalProvider.modal('auditor', {
            templateUrl: config.path.template('auditor.auditor_modal'),
            controller: 'AuditorModalController'
        });

        modalProvider.modal('requestExport', {
            templateUrl: config.path.template('exports.request'),
            controller: 'RequestExportModalController'
        });

        modalProvider.modal('updates', {
            templateUrl: config.path.template('common.modals.updates'),
            controller: 'UpdatesModalController',
            backdrop: 'static',
            keyboard: false
        });
    }
})(angular);
