(function (angular) {
    angular
        .module('one.admin')
        .controller('ProductCategoryController', ProductCategoryController);

    ProductCategoryController.$inject = ['$scope', '$filter', 'AuthService', 'ProductService', 'dataframe', 'modal', 'session', 'category'];

    function ProductCategoryController($scope, $filter, AuthService, ProductService, dataframe, modal, session, category) {
        $scope.category = category;

        $scope.products = dataframe.init({
            name: 'products',
            externalData: function (params) {
                params.filters = params.filter || {};
                params.filters.product_categories = [$scope.category.id];

                return ProductService.products(params);
            },
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4 class="text-limiter"><i class="md-icon outline">loyalty</i> {{ row.title }}</h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Amount',
                    name: 'amount',
                    display: function (row) {
                        if (row.allow_custom_amount) {
                            return '<em>Variable</em>';
                        }
                        return getPriceValueForRow(row);
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'VAT',
                    name: 'vat',
                    display: function (row) {
                        if (row.payment_type == 'oneoff' || row.payment_type == 'recurring') {
                            return $filter('money')(row.vat_amount, '&euro; ', 2) + ' (' + row.vat_percentage + '%)';
                        }

                        var amount = 0;

                        angular.forEach(row.payment_terms, function (paymentTerm) {
                            amount += paymentTerm.vat_amount;
                        });

                        return $filter('money')(amount, '&euro; ', 2) + ' (' + row.vat_percentage + '%)';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Payment type',
                    name: 'payment_type',
                    display: getPaymentTypeValueForRow,
                    weight: 2,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('products.view')) {
                    return 'sales.products.product({productId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'sales.products.editProduct({productId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('products.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.products.loading = true;

                        ProductService.duplicateProduct(row).then(function () {
                            $scope.products.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('products.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        ProductService.openDeleteProductModal(row.id, function () {
                            $scope.products.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('products.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.product_category.products', {
                sort: { title: 'asc' }
            })
        });

        session.sync('data.dataframes.product_category.products', $scope.products.state);

        $scope.openDeleteProductCategoryModal = openDeleteProductCategoryModal;
        $scope.openAuditorModal = openAuditorModal;

        function openDeleteProductCategoryModal() {
            ProductService.openDeleteProductCategoryModal($scope.category.id, function () {
                $state.transitionTo('sales.products.productCategories');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: category.id, modelType: 'product_category' });
        }

        function getPriceValueForRow(row) {
            switch (row.payment_type) {
                case 'manual':
                    return '<em>Variable</em>';
                case 'oneoff':
                    return $filter('money')(row.gross_amount, '&euro; ', 2);
                case 'terms':
                    var totalPrice = 0;
                    angular.forEach(row.payment_terms, function (term) {
                        totalPrice += term.gross_amount;
                    });
                    return $filter('money')(totalPrice, '&euro; ', 2);
                case 'recurring':
                    return $filter('money')(row.gross_amount, '&euro; ', 2);
            }
        }

        function getPaymentTypeValueForRow(row) {
            switch (row.payment_type) {
                case 'manual':
                    return $filter('translate')('general.manual');
                case 'oneoff':
                    return $filter('translate')('general.oneoff');
                case 'terms':
                    return '{{ row.payment_terms.length }} {{ \'general.terms\' | translate }}';
                case 'recurring':
                    return $filter('recurringInterval')(row);
            }
        }
    }
})(angular);
