(function (angular) {
    angular
        .module('one.admin')
        .directive('timeInput', timeInputDirective);

    timeInputDirective.$inject = ['$locale', 'moment'];

    function timeInputDirective($locale, moment) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                var parsedTimeFormat = $locale.DATETIME_FORMATS.parsedTime;
                var formattedTimeFormat = $locale.DATETIME_FORMATS.userInputTime;

                ngModel.$parsers = [parseValue];
                ngModel.$formatters = [formatValue];

                ngModel.$validators.time = validateTime;

                element.on('blur', updateViewValue);

                function parseValue(value) {
                    return value ? moment(value, formattedTimeFormat).format(parsedTimeFormat) : null;
                }

                function formatValue(value) {
                    return value ? moment(value, parsedTimeFormat).format(formattedTimeFormat) : null;
                }

                function validateTime(modelValue, viewValue) {
                    return ngModel.$isEmpty(viewValue) || moment(modelValue, parsedTimeFormat).isValid();
                }

                function updateViewValue() {
                    if (ngModel.$valid) {
                        ngModel.$setViewValue(formatValue(ngModel.$modelValue));
                        ngModel.$render();
                    }
                }
            }
        };
    }
})(angular);
