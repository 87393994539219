(function (angular) {
    angular
        .module('one.admin')
        .controller('SiteController', SiteController);

    SiteController.$inject = ['$scope', 'modal', 'session', 'languages', 'SiteService', 'TransactionalMailService', 'FormHelper'];

    function SiteController($scope, modal, session, languages, SiteService, TransactionalMailService, FormHelper) {
        $scope.site = session.get('data.current_site');

        $scope.form = FormHelper.getFormState({
            data: {
                title: $scope.site.title,
                language: $scope.site.language,
                config: $scope.site.config,
                password_reset_mail: $scope.site.password_reset_mail
            },
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, data);

                return SiteService.editSite(data);
            },
            success: function (data) {
                $scope.site = data.site;
                session.set('data.current_site', angular.copy(data.site));
            }
        });

        $scope.formatLanguageTitle = SiteService.formatLanguageTitle;
        $scope.languages = languages;
        $scope.formatTransactionalMailTitle = formatTransactionalMailTitle;
        $scope.getTransactionalMails = TransactionalMailService.transactionalMails;
        $scope.openAuditorModal = openAuditorModal;

        function formatTransactionalMailTitle(transactionalMail) {
            return '<i class="md-icon">mail_outline</i> ' + transactionalMail.title;
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.site.id, modelType: 'site' });
        }
    }
})(angular);
