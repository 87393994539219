(function (angular) {
    angular
        .module('one.admin')
        .directive('lineModuleForm', lineModuleFormDirective);

    lineModuleFormDirective.$inject = ['config'];

    function lineModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.line.form')
        };
    }
}(angular));
