(function (angular) {
    angular
        .module('one.admin')
        .controller('AddNewsFeedModuleModalController', AddNewsFeedModuleModalController);

    AddNewsFeedModuleModalController.$inject = ['$scope', '$uibModalInstance', 'session','ModuleService', 'params', 'newsModules'];

    function AddNewsFeedModuleModalController($scope, $uibModalInstance, session, ModuleService, params, newsModules) {
        $scope.module = {
            module_instance: {
                news_modules: [],
                layout: 'columns',
                enable_filters: false,
                enable_pagination: false,
                brand: 'default'
            }
        };

        $scope.newsModules = newsModules;

        angular.merge($scope, params);

        $scope.addNewsFeedModule = addNewsFeedModule;
        $scope.cancel = cancel;

        function addNewsFeedModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
