(function (angular) {
    angular
        .module('one.admin')
        .directive('accountsDataframe', accountsDataframeDirective);

    accountsDataframeDirective.$inject = ['config'];

    function accountsDataframeDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                accounts: '=',
                name: '=',
                externalData: '&',
                hideExport: '='
            },
            controller: ['$scope', 'AuthService', 'GroupService', 'dataframe', 'session', 'string', 'shortenFilter', function ($scope, AuthService, GroupService, dataframe, session, string, shortenFilter) {
                var dataframeName = ($scope.name || 'accounts');
                var sessionName = 'data.dataframes.' + ($scope.name || 'accounts');

                $scope.accounts = dataframe.init({
                    name: dataframeName,
                    externalData: function (params) {
                        return $scope.externalData()(params).then(function (result) {
                            for (var i = 0; i < result.data.length; i++) {
                                result.data[i].leaf_groups = GroupService.getLeafGroups(result.data[i].groups);
                            }

                            return result;
                        });
                    },
                    exportData: $scope.externalData(),
                    cols: [
                        {
                            name: 'title',
                            title: 'Name',
                            display: function (row) {
                                var output = '<div class="profile-picture-container">';
                                output += '<div class="profile-picture"><i account-icon account="row"></i></div>';
                                output += '<div class="profile-details">';
                                output += '<h4 class="text-limiter">{{ row.title }}</h4>';
                                output += '<small ng-repeat="group in row.leaf_groups"><span group-label group="::group" shorten="10" compact="true" hide-parents="true"></span> </small>';
                                output += '</div>';
                                output += '</div>';

                                return output;
                            },
                            weight: 3,
                            sortable: true
                        },
                        {
                            title: 'Organisation',
                            name: 'organisation',
                            display: function (row) {
                                if (row.organisation) {
                                    return '<a ui-sref="crm.accounts.account.index({ accountId: row.organisation.id })" ng-click="$event.stopPropagation()"><i account-icon account="row.organisation"></i> {{ row.organisation.title }}</a>';
                                }

                                return '';
                            },
                            weight: 2
                        },
                        {
                            title: 'Added',
                            name: 'created_at',
                            display: function (row) {
                                return '{{ row.created_at | formatDate }}';
                            },
                            sortable: true,
                            weight: 1
                        },
                        {
                            name: 'email',
                            title: 'E-mail',
                            weight: 2,
                            visible: false,
                            sortable: true
                        },
                        {
                            name: 'phone',
                            title: 'Phone',
                            weight: 2,
                            display: function (row) {
                                row.phonenumbers = [];

                                if (row.phone_private) {
                                    row.phonenumbers.push(string.escape(row.phone_private) + ' <span class="light">(private)</span>');
                                }

                                if (row.phone_work) {
                                    row.phonenumbers.push(string.escape(row.phone_work) + ' <span class="light">(work)</span>');
                                }

                                row.delimiter = ' <span class="text-muted">/</span> ';

                                return '<span list-limiter limit="1" list="row.phonenumbers" delimiter="row.delimiter"></span>';
                            },
                            visible: false
                        },
                        {
                            name: 'street',
                            title: 'Address',
                            weight: 2,
                            display: function (row) {
                                var address = '{{ row.street }}';

                                if (row.house_number) {
                                    address += ' {{ row.house_number }}';
                                }

                                if (row.house_number_addition) {
                                    address += ' {{ row.house_number_addition }}';
                                }

                                return address;
                            },
                            visible: false,
                            sortable: true
                        },
                        {
                            name: 'zip_code',
                            title: 'Zip code',
                            weight: 2,
                            visible: false,
                            sortable: true
                        },
                        {
                            name: 'city',
                            title: 'City',
                            weight: 2,
                            display: function (row) {
                                var address = row.city || '';
                                if (row.country) {
                                    address += '<span class="text-muted"> ({{ row.country }})</span>';
                                }
                                return address;
                            },
                            visible: false,
                            sortable: true
                        },
                        {
                            title: 'Contact of',
                            name: 'contact_user',
                            visible: false,
                            display: function (row) {
                                return '<span class="one-user" ng-if="row.contact_user"><i class="md-icon">account_circle</i> {{ row.contact_user.first_name }}</span>';
                            },
                            weight: 3
                        }
                    ],
                    rowSref: function (row) {
                        if (AuthService.userCanAccess('accounts.view')) {
                            return 'crm.accounts.account.index({accountId: ' + row.id + '})';
                        }
                        return false;
                    },
                    state: session.get(sessionName, {
                        filters: {
                            account_types: [],
                            groups: [], segments: [],
                            contact_users: [],
                            tags: session.get('data.dataframe.tags', [])
                        },
                        sort: { created_at: 'desc' }
                    }),
                    filterables: [
                        'id', 'title', 'first_name', 'infix', 'last_name', 'date_of_birth', 'email', 'secondary_email',
                        'phone_home', 'phone_private', 'phone_work', 'phone_emergency', 'street', 'city',
                        'zip_code', 'postal_street', 'iban', 'reference', 'created_at'
                    ],
                    filterableLabels: {
                        reference: 'mandate_reference'
                    },
                    batchLimits: {
                        export: 50000
                    }
                });

                session.sync(sessionName, $scope.accounts.state);

                $scope.colPresets = [
                    {
                        name: 'general',
                        cols: ['title', 'organisation', 'created_at']
                    },
                    {
                        name: 'contact_details',
                        cols: ['title', 'contact_person', 'phone', 'email']
                    },
                    {
                        name: 'address',
                        cols: ['title', 'street', 'zip_code', 'city']
                    }
                ];

                if (!$scope.accounts.state.activeColPreset) {
                    $scope.accounts.state.activeColPreset = $scope.colPresets[0].name;
                }
            }],
            templateUrl: config.path.template('accounts.accounts_dataframe')
        };
    }
}(angular));
