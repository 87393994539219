(function (angular) {
    angular
        .module('one.admin')
        .directive('videoModuleForm', videoModuleFormDirective);

    videoModuleFormDirective.$inject = ['config'];

    function videoModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.video.form')
        };
    }
}(angular));
