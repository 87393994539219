(function (angular) {
    angular
        .module('one.admin')
        .directive('matchPaymentForm', matchPaymentFormDirective);

    matchPaymentFormDirective.$inject = ['config'];

    function matchPaymentFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                bankStatementEntry: '=',
            },
            controller: ['$scope', '$rootScope', 'dataframe', 'session', 'translateFilter', 'AuthService', 'BankStatementService', 'ProductService', 'PurchaseService', function ($scope, $rootScope, dataframe, session, translateFilter, AuthService, BankStatementService, ProductService, PurchaseService) {
                $scope.purchaseSources = [];
                $scope.purchaseDestinations = [];

                function loadPurchaseSourceAndDestinations() {
                    PurchaseService.purchaseSources().then(function (purchaseSources) {
                        angular.forEach(purchaseSources, function (purchaseSource) {
                            purchaseSource.label = purchaseSource.title;
                        });

                        if (AuthService.userCanAccess('purchases.add_purchase_source')) {
                            purchaseSources.unshift({ label: translateFilter('general.new_dots'), new: true });
                        }

                        $scope.purchaseSources = purchaseSources;
                    });

                    PurchaseService.purchaseDestinations().then(function (purchaseDestinations) {
                        angular.forEach(purchaseDestinations, function (purchaseDestination) {
                            purchaseDestination.label = purchaseDestination.title;
                        });

                        if (AuthService.userCanAccess('purchases.add_purchase_destination')) {
                            purchaseDestinations.unshift({ label: translateFilter('general.new_dots'), new: true });
                        }

                        $scope.purchaseDestinations = purchaseDestinations;
                    });
                }

                loadPurchaseSourceAndDestinations();

                $scope.paymentMethods = [];
                $scope.products = [];

                $scope.form = {};

                $scope.form.main_operation = null;
                $scope.form.sub_operation = null;

                $scope.form.account = null;
                $scope.form.transaction = null;
                $scope.form.payment_method = null;
                $scope.form.purchase = null;
                $scope.form.product = null;
                $scope.form.purchase_source = null;
                $scope.form.purchase_destination = null;

                BankStatementService.unmatchedPaymentCount().then(function (data) {
                    $scope.unmatchedPaymentCount = data.count;
                });

                $scope.unmatchedPayments = dataframe.init({
                    externalData: BankStatementService.unmatchedPayments,
                    cols: [
                        {
                            title: '',
                            name: 'selected',
                            display: function (row) {
                                row.selected = function () {
                                    return $scope.bankStatementEntry && this.id == $scope.bankStatementEntry.id;
                                }

                                return '<i class="md-icon text-muted" ng-if="row.selected()">radio_button_checked</i><i class="md-icon text-muted" ng-if="!row.selected()">radio_button_unchecked</i>';
                            },
                            weight: 1,
                            sortable: false
                        },
                        {
                            title: 'IBAN',
                            name: 'iban',
                            weight: 4,
                            sortable: true
                        },
                        {
                            title: 'Amount',
                            name: 'amount',
                            display: function (row) {
                                return '{{ row.amount | money: \'&euro; \' }}';
                            },
                            weight: 2,
                            sortable: true
                        },
                        {
                            title: 'Name',
                            name: 'name',
                            weight: 4
                        },
                        {
                            title: 'Description',
                            name: 'description',
                            weight: 3
                        },
                        {
                            title: 'Booking date',
                            name: 'booking_date',
                            display: function (row) {
                                return '{{ row.booking_date | formatDate }}';
                            },
                            weight: 2,
                            sortable: true
                        }
                    ],
                    rowClick: function (row) {
                        $scope.bankStatementEntry = row;
                        $rootScope.scrollTop();
                    },
                    state: session.get('data.dataframes.unmatchedPayments', {
                        perPage: 5,
                        sort: { booking_date: 'desc' }
                    })
                });

                session.sync('data.dataframes.unmatchedPayments', $scope.unmatchedPayments.state);

                $scope.formatProductTitle = ProductService.formatProductTitle;
                $scope.processMatch = processMatch;
                $scope.ignore = ignore;

                $scope.$watch('bankStatementEntry.id', function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }

                    if (newValue) {
                        init();
                    }
                });

                function init() {
                    $scope.loading = true;

                    // Reset attributes that depend on the bank statement entry.
                    $scope.form.main_operation = null;
                    $scope.form.sub_operation = null;
                    $scope.form.account = null;
                    $scope.form.payment_method = null;
                    $scope.form.purchase = null;
                    $scope.form.transaction = null;
                    $scope.form.product = null;
                    $scope.form.purchase_source = null;
                    $scope.form.purchase_destination = null;
                    $scope.form.other = null;

                    $scope.form.suggested_account = null;
                    $scope.form.other_account = null;

                    BankStatementService.matchSuggestions($scope.bankStatementEntry.id).then(function (suggestions) {
                        $scope.suggestions = suggestions;

                        $scope.paymentMethods = suggestions.payment_methods;
                        $scope.products = suggestions.products;

                        $scope.form.payment_method = $scope.paymentMethods[0] || null;

                        $scope.$watch('form.transaction', function (transaction) {
                            if (transaction) {
                                $scope.form.main_operation = 'existing_transaction';
                                $scope.form.sub_operation = null;
                                $scope.form.account = null;
                                $scope.form.suggested_account = null;
                                $scope.form.other_account = null;
                                $scope.form.purchase = null;
                                $scope.form.other = null;
                            }
                        });

                        $scope.$watch('form.purchase', function (purchase) {
                            if (purchase) {
                                $scope.form.main_operation = 'new_transaction';
                                $scope.form.sub_operation = 'existing_purchase';
                                $scope.form.account = null;
                                $scope.form.suggested_account = null;
                                $scope.form.other_account = null;
                                $scope.form.transaction = null;
                                $scope.form.other = null;
                            }
                        });

                        $scope.$watch('form.suggested_account', function (account) {
                            if (account) {
                                $scope.form.main_operation = 'new_transaction';
                                $scope.form.sub_operation = 'new_purchase';
                                $scope.form.account = account;
                                $scope.form.other_account = null;
                                $scope.form.purchase = null;
                                $scope.form.transaction = null;
                            }
                        });

                        $scope.$watch('form.other_account', function (other) {
                            if (other) {
                                $scope.form.main_operation = 'new_transaction';
                                $scope.form.sub_operation = 'new_purchase';
                                $scope.form.account = null;
                                $scope.form.suggested_account = null;
                                $scope.form.purchase = null;
                                $scope.form.transaction = null;
                            }
                        });

                        $scope.loading = false;
                    });
                }

                function processMatch() {
                    $scope.loading = true;

                    BankStatementService.processMatch($scope.bankStatementEntry.id, $scope.form).then(function (data) {
                        return $scope.unmatchedPayments.getData().then(function () {
                            // A new purchase source or destination can be added.
                            loadPurchaseSourceAndDestinations();

                            $scope.bankStatementEntry = $scope.unmatchedPayments.rows[0] || null;

                            $scope.loading = false;

                            BankStatementService.unmatchedPaymentCount().then(function (data) {
                                $scope.unmatchedPaymentCount = data.count;
                            });
                        });
                    }, function (errors) {
                        $scope.errors = errors;
                        $scope.loading = false;
                    });
                }

                function ignore() {
                    $scope.form.main_operation = 'ignore';
                    $scope.form.sub_operation = null;

                    processMatch();
                }
            }],
            templateUrl: config.path.template('bank_statements.match_payment_form')
        };
    }
}(angular));
