(function (angular) {
    angular
        .module('one.admin')
        .controller('AddSddBatchController', AddSddBatchController);

    AddSddBatchController.$inject = ['$scope', '$rootScope', '$state', 'SddBatchService', 'paymentMethods'];

    function AddSddBatchController ($scope, $rootScope, $state, SddBatchService, paymentMethods) {
        angular.forEach(paymentMethods, function (paymentMethod) {
            paymentMethod.label = [paymentMethod.title, paymentMethod.properties.creditor_name, paymentMethod.properties.creditor_iban].join(' / ');
        });

        $scope.paymentMethods = paymentMethods;

        $scope.sddBatch = {
            payment_method: paymentMethods[0],
            collection_date: (new Date).toISOString().slice(0, 10)
        };

        $scope.add = add;

        $scope.$watch('sddBatch.payment_method', function (newValue, oldValue) {
            $scope.sddBatch.statement = newValue.properties.statement || '';
        });

        function add(data) {
            SddBatchService.addSddBatch(data);

            // TODO: Queue sdd batch creation and use "then" here instead of timeout.
            setTimeout(function () {
                if (!$rootScope.errors) {
                    $state.transitionTo('finance.sddBatches.index');
                }
            }, 1000);
        }
    }
})(angular);
