(function (angular) {
    angular
        .module('one.admin')
        .directive('giftRewardsDataframe', giftRewardsDataframeDirective);

    giftRewardsDataframeDirective.$inject = ['config'];

    function giftRewardsDataframeDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                giftRewards: '=',
                name: '=',
                externalData: '&',
                hideColumns: '=',
                hideExport: '=',
            },
            controller: ['$scope', 'AuthService', 'GiftService', 'dataframe', 'modal', 'session', function ($scope, AuthService, GiftService, dataframe, modal, session) {
                var dataframeName = ($scope.name || 'gift_rewards');
                var sessionName = 'data.dataframes.' + ($scope.name || 'gift_rewards');

                $scope.giftRewards = dataframe.init({
                    name: dataframeName,
                    externalData: function (params) {
                        return $scope.externalData()(params);
                    },
                    cols: [
                        {
                            name: 'title',
                            title: 'Amount',
                            display: function (row) {
                                return '<span class="h4 text-limiter"><i class="md-icon">card_giftcard</i> {{ row.amount | money: \'&euro; \' }}</span>';
                            },
                            weight: 1,
                            sortable: true
                        },
                        {
                            name: 'amount',
                            title: 'Title',
                            display: function (row) {
                                return '{{ row.title }}';
                            },
                            weight: 2,
                            sortable: true
                        },
                        {
                            name: 'times_sold',
                            title: 'Times sold',
                            display: function (row) {
                                var output = '{{ row.sales }}';

                                if (row.quantity !== null) {
                                    output = output + ' / {{ row.quantity }}';
                                }

                                return output;
                            },
                            weight: 1
                        }
                    ],
                    rowSref: function (row) {
                        if (AuthService.userCanAccess('gift_rewards.edit')) {
                            return 'crowdfunding.giftRewards.editGiftReward({giftRewardId: row.id})';
                        }

                        return false;
                    },
                    rowOptions: [
                        {
                            title: 'Edit',
                            icon: 'edit',
                            sref: function (row) {
                                return 'crowdfunding.giftRewards.editGiftReward({giftRewardId: row.id})';
                            },
                            visible: function (row) {
                                return AuthService.userCanAccess('gift_rewards.edit');
                            }
                        },
                        {
                            title: 'Delete',
                            icon: 'delete',
                            click: function (row) {
                                openDeleteGiftRewardModal(row.id);
                            },
                            visible: function (row) {
                                return row.deletable && AuthService.userCanAccess('gift_rewards.delete');
                            }
                        }
                    ],
                    state: session.get(sessionName, {
                        sort: {
                            amount: 'asc'
                        }
                    })
                });

                session.sync(sessionName, $scope.giftRewards.state);

                function openDeleteGiftRewardModal(id) {
                    modal.open('confirm', null, { title: 'Delete this gift reward?', message: 'This cannot be undone.' }).result.then(function (data) {
                        GiftService.deleteGiftReward(id).then(function (data) {
                            $scope.giftRewards.getData();
                        }, function (errors) {
                            notification.error(errors['all'][0]);
                        });
                    });
                }
            }],
            templateUrl: config.path.template('gift_rewards.gift_rewards_dataframe')
        };
    }
}(angular));
