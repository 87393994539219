(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPdfTemplateController', EditPdfTemplateController);

    EditPdfTemplateController.$inject = ['$scope', '$state', 'PdfTemplateService', 'modal', 'notification', 'pdfTemplate'];

    function EditPdfTemplateController($scope, $state, PdfTemplateService, modal, notification, pdfTemplate) {
        $scope.pdfTemplate = pdfTemplate;

        $scope.editPdfTemplate = editPdfTemplate;
        $scope.openAuditorModal = openAuditorModal;

        function editPdfTemplate(data) {
            PdfTemplateService.editPdfTemplate(data).then(function (data) {
                $state.transitionTo('content.pdfTemplates.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: pdfTemplate.id, modelType: 'pdf_template' });
        }
    }
})(angular);
