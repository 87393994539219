(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('mail', {
            parent: 'main',
            abstract: true,
            url: '/email',
            template: '<div ui-view></div>',
            params: {
                section: 'mail'
            }
        });

        $stateProvider.state('mail.campaigns', {
            abstract: true,
            url: '/campaigns',
            template: '<div ui-view></div>',
        });

        $stateProvider.state('mail.campaigns.index', {
            url: '',
            controller: 'CampaignsController',
            templateUrl: config.path.template('campaigns.index'),
            requirePermission: 'campaigns.overview'
        });

        $stateProvider.state('mail.campaigns.campaign', {
            url: '/:campaignId',
            controller: 'CampaignController',
            templateUrl: config.path.template('campaigns.campaign'),
            resolve: {
                campaign: ['CampaignService', '$stateParams', function (CampaignService, $stateParams) {
                    return CampaignService.campaign($stateParams.campaignId);
                }]
            },
            requirePermission: 'campaigns.view'
        });

        $stateProvider.state('mail.campaigns.editCampaign', {
            url: '/:campaignId/edit',
            controller: 'EditCampaignController',
            templateUrl: config.path.template('campaigns.edit'),
            resolve: {
                campaign: ['CampaignService', '$stateParams', function (CampaignService, $stateParams) {
                    return CampaignService.campaign($stateParams.campaignId);
                }],
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }]
            },
            requirePermission: 'campaigns.edit'
        });

        modalProvider.modal('addCampaign', {
            templateUrl: config.path.template('campaigns.add'),
            controller: 'AddCampaignModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }]
            },
            requirePermission: 'campaigns.add'
        });

        $stateProvider.state('mail.campaigns.mailings', {
            abstract: true,
            url: '/:campaignId/mailings',
            template: '<div ui-view></div>',
            resolve: {
                campaign: ['CampaignService', '$stateParams', function (CampaignService, $stateParams) {
                    return CampaignService.campaign($stateParams.campaignId);
                }]
            }
        });

        $stateProvider.state('mail.campaigns.mailings.editMailing', {
            url: '/:mailingId/edit',
            controller: 'EditMailingController',
            templateUrl: config.path.template('mailings.edit'),
            resolve: {
                campaigns: ['CampaignService', '$stateParams', function (CampaignService) {
                    return CampaignService.campaigns();
                }],
                mailing: ['MailingService', '$stateParams', function (MailingService, $stateParams) {
                    return MailingService.mailing($stateParams.mailingId);
                }]
            },
            requirePermission: 'mailings.edit'
        });

        $stateProvider.state('mail.campaigns.mailings.mailing', {
            url: '/:mailingId/overview',
            controller: 'MailingController',
            templateUrl: config.path.template('mailings.overview'),
            resolve: {
                mailing: ['MailingService', '$stateParams', function (MailingService, $stateParams) {
                    return MailingService.mailing($stateParams.mailingId);
                }]
            },
            params: {
                editing: null,
            },
            requirePermission: 'mailings.view',
        });

        $stateProvider.state('mail.campaigns.mailings.segment', {
            url: '/:mailingId/segment/:segmentId',
            controller: 'MailingSegmentController',
            templateUrl: config.path.template('mailings.segment'),
            resolve: {
                mailing: ['MailingService', '$stateParams', function (MailingService, $stateParams) {
                    return MailingService.mailing($stateParams.mailingId);
                }],
                segment: ['SegmentService', '$stateParams', function (SegmentService, $stateParams) {
                    return SegmentService.segment($stateParams.segmentId);
                }]
            },
            requirePermission: 'mailings.view'
        });

        $stateProvider.state('mail.campaigns.mailings.mailingContent', {
            url: '/:mailingId/content',
            controller: 'MailingContentController',
            templateUrl: config.path.template('mailings.content'),
            resolve: {
                mailing: ['MailingService', '$stateParams', function (MailingService, $stateParams) {
                    return MailingService.mailing($stateParams.mailingId);
                }],
                templates: ['MailService', function (MailService) {
                    return MailService.templates();
                }]
            },
            requirePermission: 'mailings.view'
        });

        $stateProvider.state('mail.campaigns.mailings.mailingPerformance', {
            url: '/:mailingId/performance',
            controller: 'MailingController',
            templateUrl: config.path.template('mailings.performance'),
            resolve: {
                mailing: ['MailingService', '$stateParams', function (MailingService, $stateParams) {
                    return MailingService.mailing($stateParams.mailingId);
                }]
            },
            requirePermission: 'mailings.view'
        });

        modalProvider.modal('addMailing', {
            templateUrl: config.path.template('mailings.add'),
            controller: 'AddMailingModalController',
            backdrop: 'static',
            keyboard: false,
            requirePermission: 'mailings.add'
        });

        modalProvider.modal('sendMailing', {
            templateUrl: config.path.template('mailings.send'),
            controller: 'SendMailingModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('scheduleMailing', {
            templateUrl: config.path.template('mailings.schedule'),
            controller: 'ScheduleMailingModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('sendTestMail', {
            templateUrl: config.path.template('mailings.send_test'),
            controller: 'SendTestMailModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('mailingRecipients', {
            templateUrl: config.path.template('mailings.recipients'),
            controller: 'MailingRecipientsModalController',
        });

        modalProvider.modal('mailingClicks', {
            templateUrl: config.path.template('mailings.clicks'),
            controller: 'MailingClicksModalController',
        });

        modalProvider.modal('mailingDeliveries', {
            templateUrl: config.path.template('mailings.deliveries'),
            controller: 'MailingDeliveriesModalController',
        });

        modalProvider.modal('mailingPending', {
            templateUrl: config.path.template('mailings.pending'),
            controller: 'MailingPendingModalController',
        });

        modalProvider.modal('mailingFailures', {
            templateUrl: config.path.template('mailings.failures'),
            controller: 'MailingFailuresModalController',
        });

        modalProvider.modal('mailingOpens', {
            templateUrl: config.path.template('mailings.opens'),
            controller: 'MailingOpensModalController',
        });

        modalProvider.modal('transactionalMailClicks', {
            templateUrl: config.path.template('transactional_mails.clicks'),
            controller: 'TransactionalMailClicksModalController',
        });

        modalProvider.modal('transactionalMailDeliveries', {
            templateUrl: config.path.template('transactional_mails.deliveries'),
            controller: 'TransactionalMailDeliveriesModalController',
        });

        modalProvider.modal('transactionalMailPending', {
            templateUrl: config.path.template('transactional_mails.pending'),
            controller: 'TransactionalMailPendingModalController',
        });

        modalProvider.modal('transactionalMailFailures', {
            templateUrl: config.path.template('transactional_mails.failures'),
            controller: 'TransactionalMailFailuresModalController',
        });

        modalProvider.modal('transactionalMailOpens', {
            templateUrl: config.path.template('transactional_mails.opens'),
            controller: 'TransactionalMailOpensModalController',
        });

        $stateProvider.state('mail.transactionalMails', {
            abstract: true,
            url: '/transactional-mails',
            template: '<div ui-view></div>',
        });

        $stateProvider.state('mail.transactionalMails.index', {
            url: '',
            controller: 'TransactionalMailsController',
            templateUrl: config.path.template('transactional_mails.index'),
            requirePermission: 'transactional_mails.overview'
        });

        $stateProvider.state('mail.transactionalMails.addTransactionalMail', {
            url: '/add',
            templateUrl: config.path.template('transactional_mails.add'),
            controller: 'AddTransactionalMailController',
            resolve: {
                pdfTemplates: ['PdfTemplateService', function (PdfTemplateService) {
                    return PdfTemplateService.pdfTemplates();
                }],
                senders: ['MailService', function (MailService) {
                    return MailService.senders();
                }],
                templates: ['MailService', function (MailService) {
                    return MailService.templates();
                }]
            },
            requirePermission: 'transactional_mails.add'
        });

        $stateProvider.state('mail.transactionalMails.editTransactionalMail', {
            url: '/:transactionalMailId/edit',
            controller: 'EditTransactionalMailController',
            templateUrl: config.path.template('transactional_mails.edit'),
            resolve: {
                pdfTemplates: ['PdfTemplateService', function (PdfTemplateService) {
                    return PdfTemplateService.pdfTemplates();
                }],
                transactionalMail: ['TransactionalMailService', '$stateParams', function (TransactionalMailService, $stateParams) {
                    return TransactionalMailService.transactionalMail($stateParams.transactionalMailId);
                }],
                senders: ['MailService', function (MailService) {
                    return MailService.senders();
                }],
                templates: ['MailService', function (MailService) {
                    return MailService.templates();
                }]
            },
            requirePermission: 'transactional_mails.edit'
        });

        $stateProvider.state('mail.transactionalMails.transactionalMail', {
            url: '/:transactionalMailId',
            controller: 'TransactionalMailController',
            templateUrl: config.path.template('transactional_mails.transactional_mail'),
            resolve: {
                transactionalMail: ['TransactionalMailService', '$stateParams', function (TransactionalMailService, $stateParams) {
                    return TransactionalMailService.transactionalMail($stateParams.transactionalMailId);
                }]
            },
            requirePermission: 'transactional_mails.view'
        });

        $stateProvider.state('mail.senders', {
            abstract: true,
            url: '/senders',
            template: '<div ui-view></div>',
        });

        $stateProvider.state('mail.senders.index', {
            url: '',
            controller: 'MailSendersController',
            templateUrl: config.path.template('mail_senders.index'),
            requirePermission: 'mail_senders.overview'
        });

        $stateProvider.state('mail.senders.editMailSender', {
            url: '/:mailSenderId/edit',
            controller: 'EditMailSenderController',
            templateUrl: config.path.template('mail_senders.edit'),
            resolve: {
                domains: ['MailService', '$stateParams', function (MailService, $stateParams) {
                    return MailService.domains();
                }],
                sender: ['MailService', '$stateParams', function (MailService, $stateParams) {
                    return MailService.sender($stateParams.mailSenderId);
                }],
                users: ['AuthService', function (AuthService) {
                    return AuthService.users();
                }]
            },
            requirePermission: 'mail_senders.edit'
        });

        modalProvider.modal('addMailSender', {
            templateUrl: config.path.template('mail_senders.add'),
            controller: 'AddMailSenderModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                domains: ['MailService', '$stateParams', function (MailService, $stateParams) {
                    return MailService.domains();
                }],
                users: ['AuthService', function (AuthService) {
                    return AuthService.users();
                }]
            }
        });

        $stateProvider.state('mail.templates', {
            abstract: true,
            url: '/templates',
            template: '<div ui-view></div>',
        });

        $stateProvider.state('mail.templates.index', {
            url: '',
            controller: 'MailTemplatesController',
            templateUrl: config.path.template('mail_templates.index'),
            requirePermission: 'mail_templates.overview'
        });

        modalProvider.modal('addMailTemplate', {
            templateUrl: config.path.template('mail_templates.add'),
            controller: 'AddMailTemplateModalController',
            backdrop: 'static',
            keyboard: false
        });

        $stateProvider.state('mail.templates.editMailTemplate', {
            url: '/:mailTemplateId/edit',
            controller: 'EditMailTemplateController',
            templateUrl: config.path.template('mail_templates.edit'),
            resolve: {
                template: ['MailService', '$stateParams', function (MailService, $stateParams) {
                    return MailService.template($stateParams.mailTemplateId);
                }]
            },
            requirePermission: 'mail_templates.edit'
        });

        modalProvider.modal('sentMail', {
            templateUrl: config.path.template('sent_mails.sent_mail'),
            controller: 'SentMailModalController',
        });

        modalProvider.modal('sendPersonalMail', {
            templateUrl: config.path.template('personal_mails.send'),
            controller: 'SendPersonalMailModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                attachments: ['AccountService', '$stateParams', function (AccountService, $stateParams) {
                    return AccountService.account.attachments($stateParams.accountId);
                }],
                senders: ['MailService', function (MailService) {
                    return MailService.senders();
                }],
                templates: ['MailService', function (MailService) {
                    return MailService.templates();
                }]
            },
            requirePermission: 'personal_mail.send'
        });
    }
})(angular);
