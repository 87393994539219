(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentForm', segmentFormDirective);

    segmentFormDirective.$inject = ['config'];

    function segmentFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                segment: '='
            },
            controller: ['$scope', function ($scope) {

            }],
            templateUrl: config.path.template('segments.form')
        };
    }
}(angular));
