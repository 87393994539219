(function (angular) {
    angular
        .module('one.admin')
        .controller('AddButtonModuleModalController', AddButtonModuleModalController);

    AddButtonModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddButtonModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                brand: 'default',
                size: 'md',
                alignment: 'center',
                new_window: false
            }
        };

        angular.merge($scope, params);

        $scope.addButtonModule = addButtonModule;

        $scope.cancel = cancel;

        function addButtonModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
