(function (angular) {
    angular
        .module('one.admin')
        .directive('faqModuleForm', faqModuleFormDirective);

    faqModuleFormDirective.$inject = ['config'];

    function faqModuleFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.faq.form'),
            controller: 'FaqModuleFormController'
        };
    }
}(angular));
