(function (angular) {
    angular
        .module('one.admin')
        .controller('EditActionCampaignOverviewModuleController', EditActionCampaignOverviewModuleController);

    EditActionCampaignOverviewModuleController.$inject = ['$scope', '$state', 'ModuleService', 'modal', 'module', 'actionProjects', 'overviewTypes', 'actionCampaignTypes', 'displayOptions', 'layoutOptions', 'sortOptions'];

    function EditActionCampaignOverviewModuleController($scope, $state, ModuleService, modal, module, actionProjects, overviewTypes, actionCampaignTypes, displayOptions, layoutOptions, sortOptions) {
        $scope.module = module;

        $scope.actionProjects = actionProjects;
        $scope.overviewTypes = overviewTypes;
        $scope.actionCampaignTypes = actionCampaignTypes
        $scope.displayOptions = displayOptions;
        $scope.layoutOptions = layoutOptions;
        $scope.sortOptions = sortOptions;

        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;

        function editModule(data) {
            ModuleService.editModule(data).then(function (data) {
                var state = ModuleService.getReturnState($scope.module);

                $state.transitionTo(state.name, state.params);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);
