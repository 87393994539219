(function (angular) {
    angular
        .module('one.admin')
        .directive('oneInput', oneInputDirective);

    oneInputDirective.$inject = ['$templateCache', '$timeout', 'config'];

    function oneInputDirective($templateCache, $timeout, config) {
        return {
            restrict: 'AE',
            scope: {
                model: '=',
                type: '@',
                value: '@',
                minValue: '@',
                maxValue: '@',
                minLength: '@',
                maxLength: '@',
                placeholder: '@',
                disabled: '=',
                dob: '@',
                change: '&',
                changeDelayed: '&',
                blur: '&',
                config: '&',
                faIcon: '=',
                mdIcon: '=',
            },
            link: function (scope, element, attrs, model) {
                scope.inputTemplate = function () {
                    var name = config.path.template('common.inputs.' + scope.type);

                    if ($templateCache.get(name)) {
                        return name;
                    }

                    return config.path.template('common.inputs.string');
                };

                var timer;


                scope.$watch('model', function (newValue, oldValue) {
                    if (attrs.change) {
                        scope.change();
                    }

                    if (attrs.changeDelayed) {
                        $timeout.cancel(timer);

                        timer = $timeout(function () {
                            scope.changeDelayed();
                        }, 300);
                    }
                });

                element.addClass('one-input');
            },
            templateUrl: config.path.template('common.inputs.input')
        };
    }
})(angular);
