(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$window'];

    function run($window) {
        $window.CodeMirror.defineMode("htmltwig", function(config, parserConfig) {
            return $window.CodeMirror.overlayMode($window.CodeMirror.getMode(config, parserConfig.backdrop || "text/html"), $window.CodeMirror.getMode(config, "twig"));
        });
    }
})(angular);
