(function (angular) {
    angular
        .module('one.admin')
        .directive('mailSubjectEditor', mailSubjectEditorDirective);

    mailSubjectEditorDirective.$inject = ['config'];

    function mailSubjectEditorDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                mail: '='
            },
            controller: ['$scope', 'MailService', function ($scope, MailService) {
            }],
            templateUrl: config.path.template('mails.subject.editor')
        };
    }
})(angular);
