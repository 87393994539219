(function (angular) {
    angular
        .module('one.admin')
        .filter('shorten', shortenFilter);

    shortenFilter.$inject = ['trimFilter'];

    function shortenFilter(trimFilter) {
        return function (value, length) {
            if (value == null) {
                return '';
            }

            if (!angular.isString(value)) {
                return value;
            }

            if (length) {
                shortened = value.slice(0, length);

                return trimFilter(shortened) + (value.length > length ? '...' : '');
            }

            return value;
        };
    }
})(angular);
