(function (angular) {
    angular
        .module('one.admin')
        .directive('actionProjectForm', actionProjectFormDirective);

    actionProjectFormDirective.$inject = ['config'];

    function actionProjectFormDirective(config) {
        controller.$inject = ['$scope', 'ActionCampaignService', 'FileService', 'GroupService', 'ProductService', 'PaymentMethodService', 'TransactionalMailService', 'session'];

        function controller($scope, ActionCampaignService, FileService, GroupService, ProductService, PaymentMethodService, TransactionalMailService, session) {
            $scope.typeIcons = ActionCampaignService.typeIcons;

            $scope.addGroup = addGroup;
            $scope.getGroups = GroupService.groups;
            $scope.getImages = getImages;
            $scope.getGiftProducts = ProductService.giftProducts;
            $scope.getIdealPaymentMethods = PaymentMethodService.idealPaymentMethods;
            $scope.getTransactionalMails = TransactionalMailService.transactionalMails;
            $scope.formatTransactionalMailTitle = TransactionalMailService.formatTransactionalMailTitle;
            $scope.formatGroupTitle = GroupService.formatGroupTitle;
            $scope.formatProductTitle = ProductService.formatProductTitle;
            $scope.formatFilename = FileService.formatFilename;

            function addGroup(group) {
                if (group && !groupSelected(group)) {
                    group.pivot = group.pivot || {};
                    group.pivot.share = group.pivot.share || true;

                    $scope.actionProject.groups.push(group);
                }

                $scope.selectedGroup = {};
            }

            function groupSelected(group) {
                var key = 'id';
                var list = $scope.actionProject.groups || [];

                for (var i = 0; i < list.length; i++) {
                    if (group[key] === list[i][key]) {
                        return true;
                    }
                }

                return false;
            }

            function getImages(params) {
                params.filters = {
                    file_types: ['image'],
                    sites: [session.get('data.current_site.id')]
                };

                return FileService.files(params);
            }

            function giftProducts(params) {
                params = params || {};
                params.filters = params.filters || {};
                params.filters.gift = true;

                 return http.get('products', params);
            }

        }

        return {
            restrict: 'EA',
            controller: controller,
            scope: {
                actionProject: '=',
                sites: '=',
                types: '=',
                fields: '='
            },
            templateUrl: config.path.template('action_projects.form')
        };
    }
}(angular));
