(function (angular) {
    angular
        .module('one.admin')
        .service('ModuleService', ModuleService);

    ModuleService.$inject = ['$stateParams', 'http', 'string', 'translateFilter'];

    function ModuleService($stateParams, http, string, translateFilter) {
        function module(id) {
            return http.get('modules/' + id);
        }

        function modules(params) {
            return http.get('modules', params);
        }

        function types(params) {
            return http.get('modules/types', params);
        }

        function addModule(data) {
            return http.post('modules/add', data);
        }

        function deleteModule(id) {
            return http.post('modules/' + id + '/delete');
        }

        function editModule(data) {
            return http.post('modules/' + data.id + '/edit', data);
        }

        function duplicateModule(data) {
            return http.post('modules/' + data.id + '/duplicate', data);
        }

        function getState(module) {
            return 'content.modules.' + string.snakeToCamel(module.type.name) + 'Module.index';
        }

        function getReturnState() {
            var pageId = $stateParams.pageId;

            return {
                name: pageId ? 'content.pages.pageContent' : 'content.modules.index',
                params: { pageId: pageId }
            };
        }

        function getIcon(name) {
            var map = {
                action_campaign_overview: 'group_work',
                list: 'format_list_numbered',
                image: 'insert_photo',
                line: 'linear_scale',
                card: 'subtitles',
                carousel: 'view_carousel',
                button: 'link',
                countdown: 'date_range',
                gallery: 'photo_library',
                share: 'share',
                content: 'format_align_left',
                html: 'code',
                form: 'assignment',
                video: 'ondemand_video',
                faq: 'question_answer',
                news: 'local_post_office',
                news_feed: 'rss_feed',
                slider: 'burst_mode'
            };

            return map[name] || 'view_module';
        }

        function getSiteVariables() {
            return ['site.url', 'uploadsUrl'];
        }

        function formatModuleTypeTitle(moduleType) {
            return '<i class="md-icon">' + getIcon(moduleType.name) + '</i> ' + translateFilter('module_types.' + moduleType.name);
        }


        return {
            module: module,
            modules: modules,
            types: types,
            addModule: addModule,
            deleteModule: deleteModule,
            duplicateModule: duplicateModule,
            editModule: editModule,
            getState: getState,
            getReturnState: getReturnState,
            getIcon: getIcon,
            getSiteVariables: getSiteVariables,
            formatModuleTypeTitle: formatModuleTypeTitle
        };
    }
})(angular);
