(function (angular) {
    angular
        .module('one.admin')
        .controller('EditGroupController', EditGroupController);

    EditGroupController.$inject = ['$scope', '$state', 'GroupService', 'group'];

    function EditGroupController($scope, $state, GroupService, group) {
        group.remove = false;

        $scope.originalGroup = angular.copy(group);

        $scope.group = group;

        $scope.validation = {
            cycle: false
        };

        $scope.editGroup = editGroup;
        $scope.formatGroupTitle = GroupService.formatGroupTitle;
        $scope.updateParents = updateParents;
        $scope.oldParent = oldParent;
        $scope.getGroups = GroupService.groups;
        $scope.addGroup = addGroup;
        $scope.isNewEntryGroup = isNewEntryGroup;

        function editGroup(data) {
            GroupService.editGroup(data).then(function (data) {
                $state.transitionTo('crm.groups.group', { groupId: group.id });
            });
        }

        function updateParents() {
            var parents = [];
            var parentGroup = $scope.group.parent_group;

            if (parentGroup) {
                parents.push({
                    id: parentGroup.id,
                    title: parentGroup.title
                });

                if (parentGroup.parents) {
                    parents = angular.copy(parentGroup.parents).concat(parents);
                }
            }

            $scope.group.parents = parents;

            $scope.validation.cycle = containsCycle();
        }

        function oldParent() {
            var currentParents = $scope.group.parents || [];
            var originalParents = $scope.originalGroup.parents || [];
            var difference = [];
            var different;

            for (var i = 0; i < originalParents.length; i++) {
                different = true;

                for (var j = 0; j < currentParents.length; j++) {
                    if (originalParents[i].id == currentParents[j].id) {
                        different = false;
                        break;
                    }
                }

                if (different) {
                    difference.push(originalParents[i]);
                }
            }

            var oldParent = difference.pop();

            oldParent.parents = difference;

            return oldParent;
        }

        function containsCycle() {
            var group = $scope.group;

            if (group.parents) {
                var parents = group.parents;

                for (var i = 0; i < parents.length; i++) {
                    if (parents[i].id == group.id) {
                        return true;
                    }
                }
            }

            return false;
        }

        function addGroup(group) {
            if (group && !groupSelected(group)) {
                group.pivot = group.pivot || {};
                group.pivot.share = group.pivot.share || true;

                $scope.group.entry_groups.push(group);
            }

            $scope.selectedGroup = {};
        }

        function groupSelected(group) {
            var key = 'id';
            var list = $scope.group.entry_groups || [];

            for (var i = 0; i < list.length; i++) {
                if (group[key] === list[i][key]) {
                    return true;
                }
            }

            return false;
        }

        function isNewEntryGroup(newGroup) {
            return $scope.originalGroup.entry_groups.filter(function (group) { return group.id === newGroup.id; }).length === 0;
        }
    }
})(angular);
