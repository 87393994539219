(function (angular) {
    angular
        .module('one.admin')
        .controller('FormStructureController', FormStructureController);

    FormStructureController.$inject = ['$scope', '$state', '$timeout', 'FormService', 'string', 'modal', 'notification', 'session', 'formatDateFilter', 'form'];

    function FormStructureController($scope, $state, $timeout, FormService, string, modal, notification, session, formatDateFilter, form) {
        init(form);

        $scope.loading = false;

        $scope.addPage = addPage;
        $scope.movePageUp = movePageUp;
        $scope.movePageDown = movePageDown;
        $scope.deletePage = deletePage;
        $scope.scrollToPage = scrollToPage;
        $scope.disableRow = disableRow;
        $scope.addFieldInNewRow = addFieldInNewRow;
        $scope.addField = addField;
        $scope.moveField = moveField;
        $scope.disableField = disableField;
        $scope.dragging = dragging;
        $scope.cleanUp = cleanUp;
        $scope.fieldIcon = FormService.fieldIcon;
        $scope.fieldTypeIcon = FormService.fieldTypeIcon;
        $scope.openDeleteFormModal = openDeleteFormModal;
        $scope.openAuditorModal = openAuditorModal;

        function addPage() {
            modal.open('addFormPage', null, { form: $scope.form }).result.then(function (data) {
                init(data.form);
            });
        }

        function movePageUp(index) {
            var page = $scope.form.pages[index];

            $scope.form.pages[index] = $scope.form.pages[index - 1];
            $scope.form.pages[index - 1] = page;

            editFormStructure();
        }

        function movePageDown(index) {
            var page = $scope.form.pages[index];

            $scope.form.pages[index] = $scope.form.pages[index + 1];
            $scope.form.pages[index + 1] = page;

            editFormStructure();
        }

        function deletePage(page) {
            FormService.deleteFormPage(page.id).then(function () {
                for (var i = 0; i < $scope.form.pages.length; i++) {
                    if ($scope.form.pages[i].id == page.id) {
                        $scope.form.pages.splice(i, 1);
                    }
                }
            });
        }

        function scrollToPage(index) {
            $timeout(function () {
                $scope.scrollTo('page-' + index, 750);
            }, 50);
        }

        function disableRow(page, $index) {
            if (page.rows.length == 1 && page.rows[0].length == 0) {
                // Empty page
                return true;
            }

            if ($scope.dnd.dragging) {
                var dragging = $scope.dnd.dragging;

                if (dragging.page == page) {
                    if (page.rows[dragging.rowIndex].length == 1) {
                        if ($index == dragging.rowIndex - 1 || $index == dragging.rowIndex) {
                            return true;
                        }
                    }
                }
            }

            return false;
        }

        function addFieldInNewRow(page, index, field) {
            page.rows.splice(index, 0, [field]);

            return true;
        }

        function addField(page) {
            modal.open('addFormField', 'lg', { form: $scope.form, page: page }).result.then(function (data) {
                if (page) {
                    page.rows.push([data.field]);
                } else {
                    $scope.form.hidden_fields.push(data.field);
                }

                cleanUp();
            });
        }

        function moveField(row, index) {
            row.splice(index, 1);

            cleanUp();

            editFormStructure();
        }

        function disableField(field, row, index) {
            modal.open('confirm', null, { title: 'Disable this field?', message: 'You will be able to recover it later.' }).result.then(function (data) {
                row.splice(index, 1);
                $scope.form.disabled_fields.push(field);

                cleanUp();

                FormService.disableFormField(field.id).then(function (data) {
                    init(data.form);
                });
            });
        }

        function cleanUp() {
            var page, row;

            for (var p = 0; p < $scope.form.pages.length; p++) {
                page = $scope.form.pages[p];

                for (var r = 0; r < page.rows.length; r++) {
                    row = page.rows[r];

                    if (row.length == 0) {
                        page.rows.splice(r, 1);
                        r = r - 1;
                    }
                }

                if (page.rows.length == 0) {
                    page.rows.push([]);
                }

                page.deletable = page.rows.length <= 1 && page.rows[0].length == 0;
            }
        }

        function dragging(page, row) {
            $scope.dnd.dragging = {
                page: page,
                rowIndex: page.rows.indexOf(row)
            };
        }

        function getVisiblePages(form) {
            var pages = [];
            var p = 1;

            angular.forEach(form.pages, function (page) {
                var rows = [];
                var r = 0;

                angular.forEach(form.visible_fields, function (field) {
                    if (field.form_page_id == page.id) {
                        if (field.row > r) {
                            r = r + 1;

                            rows.push([]);
                        }

                        rows[r - 1].push(field);
                    }
                });

                pages.push(angular.extend(page, {
                    rows: rows
                }));

                p = p + 1;
            });

            return pages;
        }

        function editFormStructure() {
            $scope.loading = true;

            FormService.editFormStructure($scope.form).then(function (data) {
                init(data.form);

                $scope.loading = false;
            });
        }

        function openDeleteFormModal() {
            FormService.openDeleteFormModal($scope.form.id, function () {
                $state.transitionTo('crm.forms.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: form.id, modelType: 'form' });
        }

        function init(form) {
            $scope.form = form;
            $scope.form.pages = getVisiblePages(form);

            cleanUp();

            $scope.newRow = [];

            $scope.dnd = {
                dragging: {}
            };
        }
    }
})(angular);
