(function (angular) {
    angular
        .module('one.admin')
        .controller('OrdersController', OrderController);

    OrderController.$inject = ['$scope', '$filter', 'AuthService', 'OrderService', 'PurchaseService', 'dataframe', 'modal', 'notification', 'session', 'string'];

    function OrderController($scope, $filter, AuthService, OrderService, PurchaseService, dataframe, modal, notification, session, string) {
        $scope.orders = dataframe.init({
            name: 'orders',
            externalData: OrderService.orders,
            exportData: PurchaseService.purchases,
            cols: [
                {
                    title: 'Order',
                    name: 'id',
                    display: function (row) {
                        return '<h4><i class="md-icon">shopping_basket</i> {{ row.remote_id }}</h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Status',
                    name: 'transactions',
                    display: function (row) {
                        return '<span order-status-icon statuses="row.statuses"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Purchases',
                    name: 'purchases',
                    display: function (row) {
                        row.purchase_titles = [];

                        angular.forEach(row.purchases, function (purchase) {
                            row.purchase_titles.push('<i class="md-icon">loyalty</i> ' + string.escape($filter('shorten')(purchase.product.title, 20)));
                        });

                        return '<span list-limiter list="row.purchase_titles" delimiter="\' / \'" limit="1" shorten="true"></span> <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 4
                },
                {
                    title: 'Contact',
                    name: 'account.title',
                    display: function (row) {
                        return '<a ui-sref="crm.accounts.account.index({accountId: row.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.account.active}"><i account-icon account="row.account"></i> {{ row.account.title }}</a>';
                    },
                    weight: 3
                },
                {
                    title: 'Payment method',
                    name: 'payment_method.title',
                    weight: 3
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    weight: 2,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    },
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('orders.view')) {
                    return 'sales.orders.order({orderId: ' + row.id + '})';
                }
                return false;
            },
            filterables: [
                'id', 'start_date', 'end_date', 'account_holder', 'iban', 'reference', 'created_at'
            ],
            filterableLabels: {
                id: 'order_id',
                reference: 'mandate_reference'
            },
            state: session.get('data.dataframes.orders', { sort: { created_at: 'desc' } }),
            batchLimits: {
                export: 30000,
                downloadPdf: 100,
                sendMail: 100
            }
        });

        session.sync('data.dataframes.orders', $scope.orders.state);

        $scope.openAddOrderModal = openAddOrderModal;
        $scope.openBatchDownloadPdfModal = openBatchDownloadPdfModal;
        $scope.openSendMailModal = openSendMailModal;

        function openAddOrderModal(size) {
            modal.open('addOrder', size).result.then(function (data) {
                $scope.orders.getData();
            });
        }

        function openBatchDownloadPdfModal(size) {
            modal.open('batchDownloadOrderPdf', size, { size: $scope.orders.total, state: $scope.orders.state });
        }

        function openSendMailModal(size) {
            modal.open('sendOrderMail', size, { ordersDataframe: $scope.orders });
        }
    }
})(angular);
