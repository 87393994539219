(function (angular) {
    angular
        .module('one.admin')
        .controller('AddImageModuleModalController', AddImageModuleModalController);

    AddImageModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddImageModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                width: null,
                height: null,
                image: null,
                new_window: true,
                mobile_custom_aspect_ratio: false,
                alt_text: '',
            }
        };

        angular.merge($scope, params);

        $scope.addImageModule = addImageModule;

        $scope.cancel = cancel;

        function addImageModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
