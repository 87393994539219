(function (angular) {
    angular
        .module('one.admin')
        .controller('GroupController', GroupController);

    GroupController.$inject = ['$scope', '$state', 'GroupService', 'modal', 'group'];

    function GroupController($scope, $state, GroupService, modal, group) {
        $scope.group = group;
        $scope.titleParts = GroupService.getTitleParts(group);

        $scope.getAccounts = getAccounts(GroupService, group);
        $scope.openAddChildGroupModal = openAddChildGroupModal;
        $scope.openAddGroupMembershipModal = openAddGroupMembershipModal;
        $scope.openDeleteGroupModal = openDeleteGroupModal;
        $scope.openAuditorModal = openAuditorModal;

        function openAddChildGroupModal(size) {
            modal.open('addGroup', size, { group: { parent_group: $scope.group } }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openAddGroupMembershipModal(size) {
            modal.open('addGroupMembership', size, { membership: { group: group } }).result.then(function (data) {
                $scope.accounts.getData();
            });
        }

        function openDeleteGroupModal() {
            GroupService.openDeleteGroupModal($scope.group.id, function () {
                if ($scope.group.parent_group) {
                    $state.transitionTo('crm.groups.group', { groupId: $scope.group.parent_group.id });
                } else {
                    $state.transitionTo('crm.groups.index');
                }
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: group.id, modelType: 'group' });
        }

        function openDeleteGroupMembershipModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this group membership?' }).result.then(function (data) {
                deleteGroupMembership(id);
            });
        }

        function deleteGroupMembership(id) {
            GroupService.deleteGroupMembership(id, group.id).then(function (data) {
                $scope.accounts.getData();
            });
        }

        function getAccounts(GroupService, group) {
            return function (params) {
                return GroupService.accounts(group.id, params);
            };
        }
    }
})(angular);
