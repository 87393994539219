(function (angular) {
    angular
        .module('one.admin')
        .directive('actionCampaignsDataframe', actionCampaignsDataframeDirective);

    actionCampaignsDataframeDirective.$inject = ['config'];

    function actionCampaignsDataframeDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                actionCampaigns: '=',
                actionCampaignTypes: '=',
                groups: '=',
                name: '=',
                externalData: '&',
                hideColumns: '=',
                hideExport: '=',
                hideTags: '='
            },
            controller: ['$scope', 'AuthService', 'dataframe', 'session', 'ActionCampaignService', function ($scope, AuthService, dataframe, session, ActionCampaignService) {
                var dataframeName = ($scope.name || 'action_campaigns');
                var sessionName = 'data.dataframes.' + dataframeName;

                $scope.actionCampaigns = dataframe.init({
                    name: dataframeName,
                    externalData: function (params) {
                        return $scope.externalData()(params);
                    },
                    cols: [
                        {
                            name: 'title',
                            title: 'Campaign',
                            display: function (row) {
                                var output = '<div class="profile-picture-container">';
                                output += '<div class="profile-picture"><img ng-src="{{ row.thumbnail.url }}" alt="" ng-if="row.thumbnail" /><i class="md-icon" ng-if="!row.thumbnail">group_work</i></div>';
                                output += '<div class="profile-details">';
                                output += '<h4 class="text-limiter" ng-class="{ archived: !row.active }">{{ row.prefilled_title }}</h4>';
                                output += '<small>';

                                if (row.parent_campaign) {
                                    output += '<i class="md-icon text-muted">subdirectory_arrow_right</i> <a ui-sref="crowdfunding.actionCampaigns.actionCampaign({ actionProjectId: row.action_project.id, actionCampaignId: row.parent_campaign.id })" ng-click="$event.stopPropagation()"><action-campaign-type-icon action-campaign="row.parent_campaign"></action-campaign-type-icon> {{ row.parent_campaign.prefilled_title }}</a>';
                                } else {
                                    output += '<action-campaign-type-icon action-campaign="row"></action-campaign-type-icon> {{ \'action_campaign_types.' + row.type + '\' | translate }}</span> <span class="text-muted" ng-if="row.has_child_campaigns">({{ row.child_campaign_count }})</span>';
                                }

                                output += '</small>';
                                output += '</div>';
                                output += '</div>';

                                return output;
                            },
                            weight: 4,
                            sortable: true
                        },
                        {
                            name: 'campaigner',
                            title: 'Contact',
                            display: function (row) {
                                return '<div class="text-limiter" ng-class="{ archived: row.active && !row.campaigner.active }"><a ui-sref="crm.accounts.account.index({ accountId: row.campaigner.id })" ng-click="$event.stopPropagation()" ng-class="{ archived: !row.campaigner.active }"><i account-icon account="row.campaigner"></i> {{ row.campaigner.title }}</a></div> <span ng-repeat="group in row.groups"><span class="label label-default">{{ group.pivot.title || group.title }}</span> </span>';
                            },
                            weight: 3
                        },
                        {
                            name: 'project',
                            title: 'Project',
                            display: function (row) {
                                return '<span class="text-limiter" ng-class="{ archived: row.active && !row.action_project.active }"><a ui-sref="crowdfunding.actionProjects.actionProject({actionProjectId: row.action_project.id})" ng-click="$event.stopPropagation()"><i class="md-icon">favorite_outline</i> {{ row.action_project.title }}</a> <span tag-indicator tags="row.action_data.tags"></span></span>';
                            },
                            visible: !$scope.hideColumns || $scope.hideColumns.indexOf('action_project') == -1,
                            weight: 3
                        },
                        {
                            name: 'gift_amount',
                            title: 'Funds raised',
                            display: function (row) {
                                return '{{ row.gift_amount | money: \'&euro; \' }}' + (row.target ? ' <small class="text-muted"> / {{ row.target | money: \'&euro;\' }}</span>' : '');
                            },
                            weight: 3,
                            visible: !$scope.hideColumns || $scope.hideColumns.indexOf('gift_amount') == -1,
                            sortable: true
                        },
                        {
                            name: 'created_at',
                            title: 'Created',
                            display: function (row) {
                                return '{{ row.created_at | formatDate }}';
                            },
                            weight: 2,
                            sortable: true
                        }
                    ],
                    rowClass: function (row) {
                        return { 'text-muted': !row.active };
                    },
                    rowSref: function (row) {
                        if (AuthService.userCanAccess('action_campaigns.view')) {
                            return 'crowdfunding.actionCampaigns.actionCampaign({actionProjectId: row.action_project.id, actionCampaignId: row.id})';
                        }

                        return false;
                    },
                    rowOptions: [
                        {
                            title: 'Edit',
                            icon: 'edit',
                            sref: function (row) {
                                return 'crowdfunding.actionCampaigns.editActionCampaign({actionProjectId: row.action_project.id, actionCampaignId: row.id})';
                            },
                            visible: function (row) {
                                return AuthService.userCanAccess('action_campaigns.edit');
                            }
                        },
                        {
                            title: 'Deactivate',
                            icon: 'visibility_off',
                            click: function (row) {
                                ActionCampaignService.openDeactivateActionCampaignModal(row.id, function () {
                                    $scope.actionCampaigns.getData();
                                });
                            },
                            visible: function (row) {
                                return !row.action_project.is_atleta_project && row.active && AuthService.userCanAccess('action_campaigns.edit');
                            }
                        },
                        {
                            title: 'Reactivate',
                            icon: 'visibility',
                            click: function (row) {
                                ActionCampaignService.openReactivateActionCampaignModal(row.id, function () {
                                    $scope.actionCampaigns.getData();
                                });
                            },
                            visible: function (row) {
                                return !row.action_project.is_atleta_project && !row.active && AuthService.userCanAccess('action_campaigns.edit');
                            }
                        },
                        {
                            title: 'Delete',
                            icon: 'delete',
                            click: function (row) {
                                ActionCampaignService.openDeleteActionCampaignModal(row.id, function () {
                                    $scope.actionCampaigns.getData();
                                });
                            },
                            visible: function (row) {
                                return !row.action_project.is_atleta_project && AuthService.userCanAccess('action_campaigns.delete');
                            }
                        }
                    ],
                    state: session.get(sessionName, {
                        sort: {
                            created_at: 'desc'
                        }
                    }),
                    batchLimits: {
                        export: 50000
                    }
                });

                session.sync(sessionName, $scope.actionCampaigns.state);

                $scope.groupFilters = dataframe.initFilterList($scope.groups || [], 'id', 'title');

                $scope.actionCampaignTypeIcons = ActionCampaignService.typeIcons;
                $scope.formatGroupFilter = formatGroupFilter;

                function formatGroupFilter() {
                    return '<span class="label label-default">{{ filter.object.pivot.title || filter.object.title }}</span>';
                }
            }],
            templateUrl: config.path.template('action_campaigns.action_campaigns_dataframe')
        };
    }
}(angular));
