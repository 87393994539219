(function (angular) {
    angular
        .module('one.admin')
        .service('AuthService', AuthService);

    AuthService.$inject = ['$rootScope', '$q', 'http', 'session'];

    function AuthService($rootScope, $q, http, session) {
        function users(params) {
            return http.get('auth/project/users', params);
        }

        function addUser(data) {
            return http.post('auth/project/users/add', data);
        }

        function projectUser(id) {
            return http.get('auth/project/users/' + id);
        }

        function deleteUser(userId, data) {
            return http.post('auth/project/users/' + userId + '/delete', data);
        }

        function editUser(userId, data) {
            return http.post('auth/project/users/' + userId + '/edit', data);
        }

        function authenticate(data) {
            return http.post('auth/login', data).then(startSession);
        }

        function startSession(data) {
            session.set('user', data.user);
            session.set('project', data.user.projects.length == 1 ? data.user.projects[0] : null);

            return data;
        }

        function user(params) {
            return http.get('auth/user', params, null, { skipBefore: true, ignoreLoadingBar: true });
        }

        user.editUser = function (data) {
            return http.post('auth/user/edit', data);
        };

        function activateUser(userId, data) {
            return http.post('auth/activate_user/' + userId, data);
        }

        function resetPassword(data) {
            return http.post('auth/reset_password', data);
        }

        function requestPasswordReset(data) {
            return http.post('auth/request_password_reset', data);
        }

        function twoFactorAuthenticationStatus(params) {
            return http.get('auth/two_factor_authentication_status', params);
        }

        function verifyTwoFactorAuthenticationToken(data) {
            return http.post('auth/verify_two_factor_authentication_token', data).then(function () {
                session.set('two_factor_authentication_status', 'verified');
            });
        }

        function project(params) {
            return http.get('auth/project', params, null, { skipBefore: true, ignoreLoadingBar: true });
        }

        function projectBilling(params) {
            return http.get('auth/project/billing', params);
        }

        function setProject(data) {
            return http.post('auth/set_project/' + data.id).then(function (data) {
                session.unset('data');

                session.set('project', data.project);
            });
        }

        function checkUser() {
            return user().then(function (user) {
                session.set('user', user);

                return user;
            }, function () {
                if ($rootScope.online) {
                    session.clear();
                }

                return null;
            });
        }

        function checkTwoFactorAuthentication() {
            return twoFactorAuthenticationStatus().then(function (result) {
                if (result.required) {
                    session.set('two_factor_authentication_status', 'required');
                    return true;
                } else {
                    session.set('two_factor_authentication_status', 'not_required');
                    return false;
                }
            }, function () {
                if ($rootScope.online) {
                    session.clear();
                }

                return null;
            });
        }

        function checkProject() {
            return project().then(function (project) {
                session.set('project', project);

                return project;
            }, function () {
                if ($rootScope.online) {
                    session.unset('project');
                }

                return null;
            });
        }

        function logout() {
            session.clear();

            return http.post('auth/logout');
        }

        function clientInfo() {
            return http.get('auth/client_info');
        }

        function userCanAccess(requiredPermissions) {
            requiredPermissions = requiredPermissions instanceof Array ? requiredPermissions : [requiredPermissions];

            var permissions = session.get('project').permissions;
            var hasPermission = false

            angular.forEach(requiredPermissions, function (requiredPermission) {
                var parts = requiredPermission.split('.');
                var section = parts[0];
                var action = parts[1];

                var validPermissions = [
                    requiredPermission,
                    section + ".*",
                    "*." + action,
                    "*.*"
                ];

                angular.forEach(validPermissions, function (permission) {
                    if (permissions.indexOf(permission) > -1) {
                        hasPermission = true;
                    }
                });
            });

            return hasPermission;
        }

        function confirmUpdates(data) {
            return http.post('auth/confirm_updates', data);
        }

        function formatUserName(user) {
            return '<i class="md-icon">account_circle</i> ' + user.full_name;
        }

        return {
            users: users,
            addUser: addUser,
            authenticate: authenticate,
            user: user,
            project: project,
            projectBilling: projectBilling,
            projectUser: projectUser,
            setProject: setProject,
            checkUser: checkUser,
            checkProject: checkProject,
            logout: logout,
            clientInfo: clientInfo,
            userCanAccess: userCanAccess,
            checkTwoFactorAuthentication: checkTwoFactorAuthentication,
            twoFactorAuthenticationStatus: twoFactorAuthenticationStatus,
            verifyTwoFactorAuthenticationToken: verifyTwoFactorAuthenticationToken,
            requestPasswordReset: requestPasswordReset,
            resetPassword: resetPassword,
            activateUser: activateUser,
            deleteUser: deleteUser,
            editUser: editUser,
            confirmUpdates: confirmUpdates,
            formatUserName: formatUserName
        };
    }
})(angular);
