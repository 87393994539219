(function (angular) {
    angular
        .module('one.admin')
        .directive('pageAccessLevelIcon', pageAccessLevelIconDirective);

    pageAccessLevelIconDirective.$inject = [];

    function pageAccessLevelIconDirective() {
        return {
            restrict: 'EA',
            scope: {
                accessLevel: '@'
            },
            controller: ['$scope', 'PageService', function ($scope, PageService) {
                if ($scope.accessLevel == PageService.constants.access_levels.public) {
                    $scope.iconName = 'public';
                } else if ($scope.accessLevel == PageService.constants.access_levels.login) {
                    $scope.iconName = 'lock_outline';
                } else if ($scope.accessLevel == PageService.constants.access_levels.logout) {
                    $scope.iconName = 'lock_open';
                } else if ($scope.accessLevel == PageService.constants.access_levels.hidden) {
                    $scope.iconName = 'visibility_off';
                } else {
                    $scope.iconName = '';
                }
            }],
            template: '<i class="md-icon">{{ iconName }}</i>'
        };
    }
}(angular));
