(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframePager', oneDataframePagerDirective);

    oneDataframePagerDirective.$inject = ['config'];

    function oneDataframePagerDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-pager');
            },
            templateUrl: config.path.template('common.dataframe.pager')
        };
    }
})(angular);
