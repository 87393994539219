(function (angular) {
    angular
        .module('one.admin')
        .directive('transactionalMailForm', transactionalMailFormDirective);

    transactionalMailFormDirective.$inject = ['config'];

    function transactionalMailFormDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                pdfTemplates: '=',
                transactionalMail: '=',
                senders: '=',
                templates: '='
            },
            controller: ['$scope', 'FileService', 'MailService', 'TransactionalMailService', function ($scope, FileService, MailService, TransactionalMailService) {
                $scope.bcc = $scope.transactionalMail.bcc.join(', ');
                $scope.variables = TransactionalMailService.getVariables();

                $scope.getFiles = FileService.files;

                $scope.setBcc = setBcc;
                $scope.addFile = addFile;
                $scope.addPdfTemplate = addPdfTemplate;
                $scope.formatMailSenderTitle = MailService.formatMailSenderTitle;
                $scope.formatMailTemplateTitle = MailService.formatMailTemplateTitle;

                function setBcc(bcc) {
                    // Split the string of multiple email addresses and create an array
                    var cleanedBcc = (bcc || '').replace(/\s+/g, ' ').replace(/,+/g, ' ');

                    $scope.transactionalMail.bcc = cleanedBcc.length ? cleanedBcc.split(/\s+/g) : [];
                }

                function addFile(file) {
                    if (file && !hasFile(file)) {
                        $scope.transactionalMail.file_attachments.push({ attachment: file });
                    }
                }

                function hasFile(file) {
                    var result = false;

                    angular.forEach($scope.transactionalMail.file_attachments, function (transactionalMailFile) {
                        if (file.id == transactionalMailFile.attachment.id) {
                            result = true;
                        }
                    });

                    return result;
                }

                function addPdfTemplate(pdfTemplate) {
                    if (pdfTemplate && !hasPdfTemplate(pdfTemplate)) {
                        $scope.transactionalMail.pdf_template_attachments.push({ attachment: pdfTemplate });
                    }
                }

                function hasPdfTemplate(pdfTemplate) {
                    var result = false;

                    angular.forEach($scope.transactionalMail.pdf_template_attachments, function (transactionalMailPdfTemplate) {
                        if (pdfTemplate.id == transactionalMailPdfTemplate.attachment.id) {
                            result = true;
                        }
                    });

                    return result;
                }
            }],
            templateUrl: config.path.template('transactional_mails.form')
        };
    }
})(angular);
