(function (angular) {
    angular
        .module('one.admin')
        .controller('ImportController', ImportController);

    ImportController.$inject = ['$scope', '$state', '$interval', 'string', 'dataframe', 'modal', 'session', 'ImportService', 'GroupService', 'importInstance'];

    function ImportController($scope, $state, $interval, string, dataframe, modal, session, ImportService, GroupService, importInstance) {
        $scope.formatGroupTitle = GroupService.formatGroupTitle;
        $scope.getGroups = GroupService.groups;

        $scope.import = importInstance;
        $scope.importData = {
            dedup_strategy: importInstance.dedup_strategy || 'ignore',
            group: null
        };

        var interval = $interval(function () {
            if ($scope.import.status == 'analysing' || $scope.import.status == 'importing') {
                ImportService.getImport($scope.import.id, null, true).then(function (data) {
                    $scope.import = data;

                    $scope.importRecords.getData();
                    $scope.importRecords.loading = false;
                    $scope.invalidImportRecords.getData();
                    $scope.invalidImportRecords.loading = false;
                });
            }
        }, 2000);

        $scope.$on('$destroy', function () {
            $interval.cancel(interval);
        });

        $scope.invalidImportRecords = dataframe.init({
            name: 'invalidImportRecords',
            externalData: function (params) {
                return ImportService.invalidImportRecords($scope.import.id, params, true);
            },
            cols: [
                {
                    title: 'Row',
                    name: 'position',
                    weight: 1
                },
                {
                    title: 'Attributes',
                    name: 'data',
                    display: function (row) {
                        row.attributes = [];

                        angular.forEach(row.data, function (value, key) {
                            if (value) {
                                row.attributes.push('<strong>{{ \'general.' + string.escape(key) + '\' | translate }}:</strong> ' + string.escape(value));
                            }
                        });

                        return '<span list-limiter list="row.attributes" delimiter="\' • \'" shorten="true"></span>';
                    },
                    weight: 5
                },
                {
                    title: 'Errors',
                    name: 'validation_errors',
                    display: function (row) {
                        row.errors = [];

                        angular.forEach(row.validation_errors, function (message) {
                            row.errors.push(message);
                        });

                        return '<span list-limiter list="row.errors" delimiter="\' <br /> \'" shorten="true"></span>';
                    },
                    weight: 4
                }
            ],
            state: session.get('dataframes.import.invalidImportRecords', { sort: { position: 'asc' } })
        });

        $scope.importRecords = dataframe.init({
            name: 'importRecords',
            externalData: function (params) {
                return ImportService.importRecords($scope.import.id, params, true);
            },
            cols: [
                {
                    title: 'Row',
                    name: 'position',
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Attributes',
                    name: 'data',
                    display: function (row) {
                        row.attributes = [];

                        angular.forEach(row.data, function (value, key) {
                            if (value) {
                                row.attributes.push('<strong>{{ \'general.' + string.escape(key) + '\' | translate }}:</strong> ' + string.escape(value));
                            }
                        });

                        return '<span list-limiter list="row.attributes" delimiter="\' • \'" shorten="true"></span>';
                    },
                    weight: 5
                },
                {
                    title: $scope.import.status == 'imported' ? $scope.import.type : 'Preview',
                    name: 'model',
                    display: function (row) {
                        if ($scope.import.type == 'account') {
                            if ($scope.import.status == 'imported') {
                                if (row.model) {
                                    return '<h4 class="text-limiter"><a ui-sref="crm.accounts.account.index({accountId: row.model.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.model.active}"><i account-icon account="row.model"></i> {{ row.model.title }}</a></h4>';
                                }

                                return '<span class="text-muted">Skipped</span>';
                            }

                            // Preview
                            return '<h4 class="text-limiter"><i account-icon account="row.preview"></i> {{ row.preview.title }}</h4>';
                        }

                        return '';
                    },
                    weight: 3
                },
                {
                    title: 'Duplicate',
                    name: 'duplicate',
                    display: function (row) {
                        if (row.duplicate) {
                            return '<i class="md-icon">warning</i>';
                        }

                        return '';
                    },
                    weight: 1
                }
            ],
            state: session.get('dataframes.import.importRecords', { sort: { position: 'asc' } })
        });

        $scope.finishImport = finishImport;
        $scope.openAuditorModal = openAuditorModal;
        $scope.openDeleteImportModal = openDeleteImportModal;

        function finishImport(data) {
            ImportService.finishImport($scope.import.id, data).then(function (data) {
                $state.reload($state.current);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.import.id, modelType: 'import' });
        }

        function openDeleteImportModal() {
            ImportService.openDeleteImportModal($scope.import.id, function () {
                $state.transitionTo('imports.index');
            });
        }
    }
})(angular);
