(function (angular) {
    angular
        .module('one.admin')
        .service('ActionCampaignOverviewModuleService', ActionCampaignOverviewModuleService);

    ActionCampaignOverviewModuleService.$inject = ['http'];

    function ActionCampaignOverviewModuleService(http) {
        function displayOptions() {
            return http.get('action_campaign_overview_modules/display_options');
        }

        function layoutOptions() {
            return http.get('action_campaign_overview_modules/layout_options');
        }

        function overviewTypes() {
            return http.get('action_campaign_overview_modules/overview_types');
        }

        return {
            displayOptions: displayOptions,
            layoutOptions: layoutOptions,
            overviewTypes: overviewTypes
        };
    }
})(angular);
