(function (angular) {
    angular
        .module('one.admin')
        .controller('AddDiscountCodeController', AddDiscountCodeController);

    AddDiscountCodeController.$inject = ['$scope', '$state', 'DiscountCodeService', 'notification', 'products'];

    function AddDiscountCodeController($scope, $state, DiscountCodeService, notification, products) {
        $scope.discountCode = {
            code: '',
            description: '',
            enabled: true,
            type: DiscountCodeService.types[0].value,
            value: 0,
            max_usage: null,
            blacklisting: false,
            products: []
        };

        $scope.products = products;

        $scope.addDiscountCode = addDiscountCode;

        function addDiscountCode(data) {
            DiscountCodeService.addDiscountCode(data).then(function (data) {
                $state.transitionTo('sales.discountCodes.discountCode', {discountCodeId: data.discountCode.id});
            });
        }
    }
})(angular);
