(function (angular) {
    angular
        .module('one.admin')
        .controller('MailingContentController', MailingContentController);

    MailingContentController.$inject = ['$scope', '$state', 'MailingService', 'MailService', 'campaign', 'mailing', 'templates'];

    function MailingContentController($scope, $state, MailingService, MailService, campaign, mailing, templates) {
        $scope.campaign = campaign;
        $scope.mailing = mailing;
        $scope.templates = templates;

        if (!$scope.mailing.template) {
            $scope.mailing.template = templates[0];
        }

        $scope.variables = MailingService.getVariables();

        $scope.submitting = false;

        $scope.editMailing = editMailing;
        $scope.formatMailSenderTitle = MailService.formatMailSenderTitle;
        $scope.formatMailTemplateTitle = MailService.formatMailTemplateTitle;

        function editMailing() {
            if ($scope.submitting) {
                return;
            }

            $scope.submitting = true;

            MailingService.editMailing({
                id: $scope.mailing.id,
                template: $scope.mailing.template,
                sections: $scope.mailing.sections
            }).then(function (data) {
                $scope.setChanged(false);
                $state.transitionTo('mail.campaigns.mailings.mailing', { campaignId: data.mailing.campaign.id, mailingId: mailing.id });
            }, function () {
                $scope.submitting = false;
            });
        }
    }
})(angular);
