(function (angular) {
    angular
        .module('one.admin')
        .directive('pageForm', pageFormDirective);

    pageFormDirective.$inject = ['config'];

    function pageFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                blocks: '=',
                flags: '=',
                languages: '=',
                moduleTypes: '=',
                page: '='
            },
            controller: ['$scope', 'config', 'modal', 'session', 'PageService', 'ModuleService', 'SegmentService', 'VisitableService', 'translateFilter', function PageFormController($scope, config, modal, session, PageService, ModuleService, SegmentService, VisitableService, translateFilter) {
                if (!$scope.page.site) {
                    $scope.page.site = session.get('data.current_site');
                }

                $scope.accessLevels = PageService.constants.access_levels;

                $scope.blocks = getSiteEntities('blocks');

                $scope.getSiteEntities = getSiteEntities;
                $scope.getSegments = SegmentService.segments;
                $scope.getVisitables = VisitableService.pageVisitables;
                $scope.formatPageTitle = PageService.formatPageTitle;
                $scope.formatLanguageTitle = PageService.formatLanguageTitle;
                $scope.formatSegmentTitle = SegmentService.formatSegmentTitle;
                $scope.formatVisitableTitle = VisitableService.formatVisitableTitle;

                function getSiteEntities(name) {
                    var result = [];

                    for (var i = 0; i < $scope[name].length; i++) {
                        if ($scope[name][i].site_id == $scope.page.site.id) {
                            result.push($scope[name][i]);
                        }
                    }

                    return result;
                }
            }],
            templateUrl: config.path.template('pages.form')
        };
    }
}(angular));
