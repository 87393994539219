(function (angular) {
    angular
        .module('one.admin')
        .controller('SalesDashboardController', SalesDashboardController);

    SalesDashboardController.$inject = ['$scope', '$timeout', '$locale', '$window', 'SalesDashboardService', 'chartBuilder', 'dataframe', 'moment'];

    function SalesDashboardController($scope, $timeout, $locale, $window, SalesDashboardService, chartBuilder, dataframe, moment) {
        var green = 'rgba(117, 182, 54, .7)';
        var gray = 'rgba(68, 68, 68, .7)';
        var red = 'rgba(245, 77, 71, .7)';

        var endDate = moment().endOf('month');
        var startDate = endDate.clone().subtract(5, 'months').startOf('day').startOf('month');

        var timeout;

        // Empty dataframe to make use of its filters.
        $scope.sales = dataframe.init({ data: [] });

        $scope.params = {
            start_date: startDate.format($locale.DATETIME_FORMATS.parsedDate),
            end_date: endDate.format($locale.DATETIME_FORMATS.parsedDate)
        };

        $scope.state = {
            loading: false,
            initializing: true
        };

        $scope.data = {};

        getTotals();

        $scope.$watch('sales.state.filters', function (newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
                $timeout.cancel(timeout);

                timeout = $timeout(applyParams, 500);
            }
        }, true);

        $scope.applyParams = applyParams;
        $scope.updateStartDate = updateStartDate;
        $scope.updateEndDate = updateEndDate;

        $scope.export = function () {
            SalesDashboardService.exportTotals(angular.merge({ returnUrl: true }, $scope.params, { filters: getFilters() })).then(function (url) {
                $window.open(url, '_blank');
            });
        };

        function getFilters() {
            return $scope.sales.state.filters;
        }

        function applyParams() {
            if (!$scope.params.start_date || !$scope.params.end_date) {
                return;
            }

            getTotals(function (totals) {
                $scope.params = angular.copy(totals.params);
            });
        }

        function updateStartDate(change) {
            var date = moment($scope.params['start_date']).startOf('day');

            if (date.clone().startOf('month').format('D') == date.format('D') || change > 0) {
                date = date.add(change, 'months');
            }

            date = date.startOf('month');

            $scope.params['start_date'] = date.format($locale.DATETIME_FORMATS.parsedDate);
        }

        function updateEndDate(change) {
            var date = moment($scope.params['end_date']).startOf('day');

            if (date.clone().endOf('month').format('D') == date.format('D') || change < 0) {
                date = date.add(change, 'months');
            }

            date = date.endOf('month');

            $scope.params['end_date'] = date.format($locale.DATETIME_FORMATS.parsedDate);
        }

        function getTotals(callback) {
            if ($scope.state.loading) {
                return;
            }

            $scope.state.loading = true;

            SalesDashboardService.totals(angular.merge({}, $scope.params, { filters: getFilters() })).then(function (totals) {
                $scope.totals = totals;

                if (callback) {
                    callback(totals);
                }

                buildChart();

                $scope.state.loading = false;
                $scope.state.initializing = false;
            });
        }

        function buildChart() {
            var start = moment($scope.params.start_date);
            var end = moment($scope.params.end_date);
            var diff = end.diff(start, 'months');

            var indexDateFormat = 'YYYY-MM-DD';
            var labelDateFormat = 'D MMM YYYY';
            $scope.timeScale = 'daily';

            if (diff >= 24) {
                indexDateFormat = 'YYYY-Q'
                labelDateFormat = 'YYYY ([Q]Q)';
                $scope.timeScale = 'quarterly';
            } else if (diff >= 6) {
                indexDateFormat = 'YYYY-MM'
                labelDateFormat = 'MMM YYYY';
                $scope.timeScale = 'monthly';
            } else if (diff >= 2) {
                // GGGG = ISO-8601 week-numbering year. This has the same value as YYYY, except that if
                // the ISO week number (WW) belongs to the previous or next year, that year is used instead
                indexDateFormat = 'GGGG-WW'
                labelDateFormat = 'GGGG ([W]W)';
                $scope.timeScale = 'weekly';
            }

            var defaultItem = { sales: 0, recurring_decay: 0, recurring_active: 0 };

            var data = chartBuilder.prefillData($scope.params.start_date, $scope.params.end_date, defaultItem, indexDateFormat, labelDateFormat);

            var includeRecurring = false;
            angular.forEach($scope.totals.daily, function (item, date) {
                var index = moment(date).format(indexDateFormat);

                data[index].sales += item.sales;
                data[index].recurring_decay += item.recurring_decay;
                data[index].recurring_active = item.recurring_active; // Do not cumulate, keep last item.

                if (!includeRecurring && (item.recurring_active > 0 || item.recurring_decay > 0)) {
                    includeRecurring = true;
                }
            });

            $scope.data = data;

            $scope.chart = chartBuilder.createChart({
                series: includeRecurring ? ['sales', 'recurring_decay', 'recurring_active'] : ['sales'],
                colors: includeRecurring ? [green, red, gray] : [green]
            }, data);
        }
    }
})(angular);
