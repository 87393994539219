(function (angular) {
    angular
        .module('one.admin')
        .component('formFieldEntryEditor', {
            controller: controller,
            bindings: {
                form: '<',
                formEntry: '<',
                fieldEntry: '=',
                state: '<'
            },
            templateUrl: ['config', function (config) {
                return config.path.template('forms.entries.editor.field');
            }]
        });

    controller.$inject = ['config', 'FormService'];

    function controller(config, FormService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.options = $ctrl.options || {};

            angular.forEach($ctrl.form.fields, function (field) {
                if (field.id == $ctrl.fieldEntry.field.id) {
                    $ctrl.field = field;
                }
            });
        };

        $ctrl.fieldIsVisible = function () {
            return $ctrl.state.visibilities.fields[$ctrl.fieldEntry.field.id];
        };

        $ctrl.choiceIsVisible = function (choice) {
            return $ctrl.state.visibilities.choices[choice.id];
        };

        $ctrl.choiceEnabled = function (choice) {
            if (!$ctrl.field.rules.max) {
                return true;
            }

            var choiceCount = 0;

            for (var i = 0; i < $ctrl.fieldEntry.choice_entries.length; i++) {
                choiceCount++;

                if ($ctrl.fieldEntry.choice_entries[i].choice.id == choice.id) {
                    return true;
                }
            }

            return choiceCount < $ctrl.field.rules.max;
        };

        $ctrl.compareChoices = function (a, b) {
            if (!a || !b) {
                return false;
            }

            if ('choice' in a && 'choice' in b) {
                return a.choice.id === b.choice.id;
            }

            return false;
        };

        $ctrl.isChecked = function (choice) {
            var result = false;

            angular.forEach($ctrl.fieldEntry.choice_entries, function (choiceEntry) {
                if ($ctrl.compareChoices(choiceEntry, { choice: choice })) {
                    result = true;
                }
            });

            return result;
        };

        $ctrl.checkedIndex = function (choice) {
            var result = -1;

            angular.forEach($ctrl.fieldEntry.choice_entries, function (choiceEntry, i) {
                if ($ctrl.compareChoices(choiceEntry, { choice: choice })) {
                    result = i;
                }
            });

            return result;
        };

        $ctrl.setChoiceEntry = function (choiceEntry, choice) {
            choiceEntry.choice = angular.copy(choice);
            choiceEntry.choice_id = choice.id;
            choiceEntry.value = angular.copy(choice.value);
        };
    }
})(angular);
