(function (angular) {
    angular
        .module('one.admin')
        .controller('EditOrderController', EditOrderController);

    EditOrderController.$inject = ['$scope', '$state', 'OrderService', 'order'];

    function EditOrderController($scope, $state, OrderService, order) {
        $scope.order = order;

        $scope.edit = edit;

        function edit(data) {
            OrderService.editOrder(data).then(function (data) {
                $state.transitionTo('sales.orders.order', {orderId: data.order.id});
            });
        }
    }
})(angular);
