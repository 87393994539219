(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeActiveButton', oneDataframeActiveButtonDirective);

    oneDataframeActiveButtonDirective.$inject = ['config'];

    function oneDataframeActiveButtonDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-active-button');
            },
            controller: ['$scope', function ($scope) {
                $scope.current = function () {
                    var active = $scope.dataframe.state.filters.active;

                    return active === null ? 'all' : (active[0] === true ? 'active' : 'inactive');
                };
            }],
            templateUrl: config.path.template('common.dataframe.active_button')
        };
    }
})(angular);
