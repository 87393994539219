(function (angular) {
    angular
        .module('one.admin')
        .controller('OrderController', OrderController);

    OrderController.$inject = ['$scope', '$filter', '$state', 'modal', 'notification', 'dataframe', 'order', 'translateFilter', 'cancelReasons', 'AuthService', 'OrderService'];

    function OrderController($scope, $filter, $state, modal, notification, dataframe, order, translateFilter, cancelReasons, AuthService, OrderService) {
        $scope.order = order;
        $scope.payment = order.payment;

        angular.forEach(cancelReasons, function (cancelReason) {
            cancelReason.label = cancelReason.title;
        });

        if (AuthService.userCanAccess('cancel_reasons.add')) {
            cancelReasons.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        $scope.cancelReasons = cancelReasons;

        $scope.accountForm = { show: false };
        $scope.cancelReasonForm = { show: false };
        $scope.referenceForm = { show: false };

        if ($scope.order.cancel_reason) {
            $scope.order.cancel_reason.label = $scope.order.cancel_reason.title;
        }

        $scope.orderHasCancelledPaymentTerms = false;
        $scope.orderHasCancelledPurchases = false;
        $scope.orderHasActiveRecurringPurchase = false;
        $scope.orderHasOpenPaymentTerm = $scope.order.statuses.indexOf('open') > -1;

        angular.forEach($scope.order.purchases, function (purchase) {
            angular.forEach(purchase.payment_terms, function (paymentTerm) {
                if (paymentTerm.cancelled) {
                    $scope.orderHasCancelledPaymentTerms = true;
                }
            });

            if (purchase.end_date !== null) {
                $scope.orderHasCancelledPurchases = true;
            } else {
                if (purchase.is_recurring) {
                    $scope.orderHasActiveRecurringPurchase = true;
                }
            }
        });

        $scope.updatedOrder = angular.copy(order);

        $scope.openCancelOrderModal = openCancelOrderModal;
        $scope.openConvertToInvoiceModal = openConvertToInvoiceModal;
        $scope.openDownloadPdfModal = openDownloadPdfModal;
        $scope.openSendMailModal = openSendMailModal;
        $scope.openAuditorModal = openAuditorModal;
        $scope.changeAccount = changeAccount;
        $scope.editOrder = editOrder;
        $scope.setCancelReason = setCancelReason;

        function openCancelOrderModal(size) {
            modal.open('cancelOrder', size, { order: $scope.order }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openConvertToInvoiceModal(size) {
            modal.open('convertToInvoice', size, { order: $scope.order }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openDownloadPdfModal(size) {
            modal.open('downloadOrderPdf', size, { order: $scope.order });
        }

        function openSendMailModal(size) {
            modal.open('sendOrderMail', size, { order: $scope.order }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: order.id, modelType: 'order' });
        }

        function changeAccount(account) {
            OrderService.changeAccount(order.id, account).then(function (data) {
                $state.reload($state.current);
            });
        }

        function editOrder(data) {
            OrderService.editOrder(order.id, data).then(function (data) {
                $state.reload($state.current);
            });
        }

        function setCancelReason(cancelReason) {
            OrderService.setCancelReason(order.id, cancelReason).then(function (data) {
                $state.reload($state.current);
            });
        }
    }
})(angular);
