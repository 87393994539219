(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeTagSelector', oneDataframeTagSelectorDirective);

    oneDataframeTagSelectorDirective.$inject = ['$filter', 'config'];

    function oneDataframeTagSelectorDirective($filter, config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-tag-selector');
            },
            controller: ['$scope', 'TagService', 'session', function ($scope, TagService, session) {
                $scope.tags = session.get('data.tags', []);

                TagService.tags().then(function (tags) {
                    session.set('data.tags', tags);
                    $scope.tags = tags;
                });

                var activeTagFilters = session.get('data.dataframe.tags', []);

                $scope.untagged = {
                    id: 'null', // String, so it gets sent as parameter in XHRs
                    title: $filter('translate')('general.untagged'),
                    color: 'rgba(0, 0, 0, .7)'
                };

                initFilter();

                $scope.toggleTag = toggleTag;
                $scope.isActive = isActive;

                function toggleTag(tag) {
                    initFilter();

                    var index = activeTagFilters.indexOf(tag.id);

                    if (index > -1) {
                        activeTagFilters.splice(index, 1);
                    } else {
                        activeTagFilters.push(tag.id);
                    }

                    $scope.dataframe.state.page = 1;
                }

                function isActive(tag) {
                    initFilter();

                    return activeTagFilters.indexOf(tag.id) > -1;
                }

                function initFilter() {
                    $scope.dataframe.state.filters.tags = activeTagFilters;
                }
            }],
            templateUrl: config.path.template('common.dataframe.tag_selector')
        };
    }
})(angular);
