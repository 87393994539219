(function(angular) {
    angular.module('one.admin.config').config(config);

    config.$inject = ['config'];

    function config(config) {
        config.faIcons = [
            // Regular
            { name: 'comment-dots', prefix: 'far' },
            { name: 'clone', prefix: 'far' },
            { name: 'grimace', prefix: 'far' },
            { name: 'pause-circle', prefix: 'far' },
            { name: 'check-square', prefix: 'far' },
            { name: 'grin-squint', prefix: 'far' },
            { name: 'file-excel', prefix: 'far' },
            { name: 'play-circle', prefix: 'far' },
            { name: 'list-alt', prefix: 'far' },
            { name: 'hourglass', prefix: 'far' },
            { name: 'arrow-alt-circle-left', prefix: 'far' },
            { name: 'grin-hearts', prefix: 'far' },
            { name: 'dot-circle', prefix: 'far' },
            { name: 'user', prefix: 'far' },
            { name: 'id-badge', prefix: 'far' },
            { name: 'smile-wink', prefix: 'far' },
            { name: 'circle', prefix: 'far' },
            { name: 'grin-tongue-wink', prefix: 'far' },
            { name: 'copyright', prefix: 'far' },
            { name: 'laugh-wink', prefix: 'far' },
            { name: 'hand-point-right', prefix: 'far' },
            { name: 'file-word', prefix: 'far' },
            { name: 'object-group', prefix: 'far' },
            { name: 'file-audio', prefix: 'far' },
            { name: 'grin-wink', prefix: 'far' },
            { name: 'map', prefix: 'far' },
            { name: 'comments', prefix: 'far' },
            { name: 'plus-square', prefix: 'far' },
            { name: 'file', prefix: 'far' },
            { name: 'meh-blank', prefix: 'far' },
            { name: 'kiss-wink-heart', prefix: 'far' },
            { name: 'frown-open', prefix: 'far' },
            { name: 'laugh', prefix: 'far' },
            { name: 'sad-tear', prefix: 'far' },
            { name: 'file-video', prefix: 'far' },
            { name: 'folder-open', prefix: 'far' },
            { name: 'keyboard', prefix: 'far' },
            { name: 'grin-alt', prefix: 'far' },
            { name: 'question-circle', prefix: 'far' },
            { name: 'clipboard', prefix: 'far' },
            { name: 'life-ring', prefix: 'far' },
            { name: 'meh', prefix: 'far' },
            { name: 'caret-square-left', prefix: 'far' },
            { name: 'calendar-plus', prefix: 'far' },
            { name: 'stop-circle', prefix: 'far' },
            { name: 'calendar-check', prefix: 'far' },
            { name: 'minus-square', prefix: 'far' },
            { name: 'thumbs-down', prefix: 'far' },
            { name: 'envelope', prefix: 'far' },
            { name: 'hand-point-left', prefix: 'far' },
            { name: 'hand-lizard', prefix: 'far' },
            { name: 'hand-scissors', prefix: 'far' },
            { name: 'hand-point-up', prefix: 'far' },
            { name: 'money-bill-alt', prefix: 'far' },
            { name: 'object-ungroup', prefix: 'far' },
            { name: 'dizzy', prefix: 'far' },
            { name: 'file-image', prefix: 'far' },
            { name: 'bell', prefix: 'far' },
            { name: 'flag', prefix: 'far' },
            { name: 'hand-peace', prefix: 'far' },
            { name: 'newspaper', prefix: 'far' },
            { name: 'snowflake', prefix: 'far' },
            { name: 'star-half', prefix: 'far' },
            { name: 'frown', prefix: 'far' },
            { name: 'grin-tongue-squint', prefix: 'far' },
            { name: 'envelope-open', prefix: 'far' },
            { name: 'share-square', prefix: 'far' },
            { name: 'hospital', prefix: 'far' },
            { name: 'file-archive', prefix: 'far' },
            { name: 'grin-beam', prefix: 'far' },
            { name: 'copy', prefix: 'far' },
            { name: 'kiss-beam', prefix: 'far' },
            { name: 'closed-captioning', prefix: 'far' },
            { name: 'arrow-alt-circle-right', prefix: 'far' },
            { name: 'image', prefix: 'far' },
            { name: 'hand-point-down', prefix: 'far' },
            { name: 'lightbulb', prefix: 'far' },
            { name: 'check-circle', prefix: 'far' },
            { name: 'save', prefix: 'far' },
            { name: 'smile', prefix: 'far' },
            { name: 'images', prefix: 'far' },
            { name: 'grin-tears', prefix: 'far' },
            { name: 'calendar-alt', prefix: 'far' },
            { name: 'laugh-beam', prefix: 'far' },
            { name: 'hand-paper', prefix: 'far' },
            { name: 'caret-square-down', prefix: 'far' },
            { name: 'file-alt', prefix: 'far' },
            { name: 'thumbs-up', prefix: 'far' },
            { name: 'square', prefix: 'far' },
            { name: 'eye-slash', prefix: 'far' },
            { name: 'paper-plane', prefix: 'far' },
            { name: 'arrow-alt-circle-up', prefix: 'far' },
            { name: 'grin-squint-tears', prefix: 'far' },
            { name: 'file-powerpoint', prefix: 'far' },
            { name: 'grin-tongue', prefix: 'far' },
            { name: 'grin-beam-sweat', prefix: 'far' },
            { name: 'hand-spock', prefix: 'far' },
            { name: 'gem', prefix: 'far' },
            { name: 'chart-bar', prefix: 'far' },
            { name: 'window-maximize', prefix: 'far' },
            { name: 'address-book', prefix: 'far' },
            { name: 'meh-rolling-eyes', prefix: 'far' },
            { name: 'star', prefix: 'far' },
            { name: 'sun', prefix: 'far' },
            { name: 'edit', prefix: 'far' },
            { name: 'address-card', prefix: 'far' },
            { name: 'caret-square-right', prefix: 'far' },
            { name: 'lemon', prefix: 'far' },
            { name: 'smile-beam', prefix: 'far' },
            { name: 'compass', prefix: 'far' },
            { name: 'credit-card', prefix: 'far' },
            { name: 'user-circle', prefix: 'far' },
            { name: 'trash-alt', prefix: 'far' },
            { name: 'window-close', prefix: 'far' },
            { name: 'tired', prefix: 'far' },
            { name: 'clock', prefix: 'far' },
            { name: 'window-minimize', prefix: 'far' },
            { name: 'sad-cry', prefix: 'far' },
            { name: 'eye', prefix: 'far' },
            { name: 'registered', prefix: 'far' },
            { name: 'arrow-alt-circle-down', prefix: 'far' },
            { name: 'grin', prefix: 'far' },
            { name: 'calendar-minus', prefix: 'far' },
            { name: 'id-card', prefix: 'far' },
            { name: 'bell-slash', prefix: 'far' },
            { name: 'handshake', prefix: 'far' },
            { name: 'comment', prefix: 'far' },
            { name: 'calendar', prefix: 'far' },
            { name: 'times-circle', prefix: 'far' },
            { name: 'calendar-times', prefix: 'far' },
            { name: 'file-code', prefix: 'far' },
            { name: 'futbol', prefix: 'far' },
            { name: 'comment-alt', prefix: 'far' },
            { name: 'laugh-squint', prefix: 'far' },
            { name: 'bookmark', prefix: 'far' },
            { name: 'caret-square-up', prefix: 'far' },
            { name: 'hand-pointer', prefix: 'far' },
            { name: 'kiss', prefix: 'far' },
            { name: 'hand-rock', prefix: 'far' },
            { name: 'grin-stars', prefix: 'far' },
            { name: 'hdd', prefix: 'far' },
            { name: 'sticky-note', prefix: 'far' },
            { name: 'window-restore', prefix: 'far' },
            { name: 'building', prefix: 'far' },
            { name: 'angry', prefix: 'far' },
            { name: 'folder', prefix: 'far' },
            { name: 'file-pdf', prefix: 'far' },
            { name: 'flushed', prefix: 'far' },
            { name: 'moon', prefix: 'far' },
            { name: 'surprise', prefix: 'far' },
            { name: 'heart', prefix: 'far' },

            // Solid
            { name: 'columns', prefix: 'fas' },
            { name: 'comment-dots', prefix: 'fas' },
            { name: 'passport', prefix: 'fas' },
            { name: 'socks', prefix: 'fas' },
            { name: 'underline', prefix: 'fas' },
            { name: 'tint-slash', prefix: 'fas' },
            { name: 'undo-alt', prefix: 'fas' },
            { name: 'cloud-meatball', prefix: 'fas' },
            { name: 'caret-down', prefix: 'fas' },
            { name: 'dice-three', prefix: 'fas' },
            { name: 'toggle-on', prefix: 'fas' },
            { name: 'clone', prefix: 'fas' },
            { name: 'search', prefix: 'fas' },
            { name: 'grimace', prefix: 'fas' },
            { name: 'angle-double-left', prefix: 'fas' },
            { name: 'crow', prefix: 'fas' },
            { name: 'pallet', prefix: 'fas' },
            { name: 'eraser', prefix: 'fas' },
            { name: 'list-ul', prefix: 'fas' },
            { name: 'chart-line', prefix: 'fas' },
            { name: 'receipt', prefix: 'fas' },
            { name: 'briefcase-medical', prefix: 'fas' },
            { name: 'pause-circle', prefix: 'fas' },
            { name: 'check-square', prefix: 'fas' },
            { name: 'hand-holding-usd', prefix: 'fas' },
            { name: 'cash-register', prefix: 'fas' },
            { name: 'arrow-down', prefix: 'fas' },
            { name: 'grin-squint', prefix: 'fas' },
            { name: 'campground', prefix: 'fas' },
            { name: 'american-sign-language-interpreting', prefix: 'fas' },
            { name: 'poo', prefix: 'fas' },
            { name: 'search-minus', prefix: 'fas' },
            { name: 'ghost', prefix: 'fas' },
            { name: 'business-time', prefix: 'fas' },
            { name: 'list', prefix: 'fas' },
            { name: 'broadcast-tower', prefix: 'fas' },
            { name: 'sign', prefix: 'fas' },
            { name: 'long-arrow-alt-right', prefix: 'fas' },
            { name: 'volume-off', prefix: 'fas' },
            { name: 'stream', prefix: 'fas' },
            { name: 'pen-alt', prefix: 'fas' },
            { name: 'neuter', prefix: 'fas' },
            { name: 'fist-raised', prefix: 'fas' },
            { name: 'file-excel', prefix: 'fas' },
            { name: 'prescription', prefix: 'fas' },
            { name: 'bed', prefix: 'fas' },
            { name: 'sort-numeric-up', prefix: 'fas' },
            { name: 'dumpster', prefix: 'fas' },
            { name: 'wind', prefix: 'fas' },
            { name: 'skiing-nordic', prefix: 'fas' },
            { name: 'subscript', prefix: 'fas' },
            { name: 'drum-steelpan', prefix: 'fas' },
            { name: 'user-cog', prefix: 'fas' },
            { name: 'cog', prefix: 'fas' },
            { name: 'play-circle', prefix: 'fas' },
            { name: 'list-alt', prefix: 'fas' },
            { name: 'mobile-alt', prefix: 'fas' },
            { name: 'prescription-bottle-alt', prefix: 'fas' },
            { name: 'poll-h', prefix: 'fas' },
            { name: 'luggage-cart', prefix: 'fas' },
            { name: 'clinic-medical', prefix: 'fas' },
            { name: 'tools', prefix: 'fas' },
            { name: 'hryvnia', prefix: 'fas' },
            { name: 'long-arrow-alt-left', prefix: 'fas' },
            { name: 'mitten', prefix: 'fas' },
            { name: 'pen-nib', prefix: 'fas' },
            { name: 'hourglass', prefix: 'fas' },
            { name: 'arrow-alt-circle-left', prefix: 'fas' },
            { name: 'grin-hearts', prefix: 'fas' },
            { name: 'boxes', prefix: 'fas' },
            { name: 'dot-circle', prefix: 'fas' },
            { name: 'chess-rook', prefix: 'fas' },
            { name: 'dice-one', prefix: 'fas' },
            { name: 'reply', prefix: 'fas' },
            { name: 'hamburger', prefix: 'fas' },
            { name: 'grip-lines', prefix: 'fas' },
            { name: 'camera-retro', prefix: 'fas' },
            { name: 'toolbox', prefix: 'fas' },
            { name: 'envelope-open-text', prefix: 'fas' },
            { name: 'quran', prefix: 'fas' },
            { name: 'thermometer-quarter', prefix: 'fas' },
            { name: 'beer', prefix: 'fas' },
            { name: 'ankh', prefix: 'fas' },
            { name: 'chess-knight', prefix: 'fas' },
            { name: 'id-card-alt', prefix: 'fas' },
            { name: 'comments-dollar', prefix: 'fas' },
            { name: 'spray-can', prefix: 'fas' },
            { name: 'user-secret', prefix: 'fas' },
            { name: 'utensil-spoon', prefix: 'fas' },
            { name: 'star-half-alt', prefix: 'fas' },
            { name: 'basketball-ball', prefix: 'fas' },
            { name: 'umbrella', prefix: 'fas' },
            { name: 'user', prefix: 'fas' },
            { name: 'blind', prefix: 'fas' },
            { name: 'volume-down', prefix: 'fas' },
            { name: 'cart-plus', prefix: 'fas' },
            { name: 'id-badge', prefix: 'fas' },
            { name: 'smile-wink', prefix: 'fas' },
            { name: 'network-wired', prefix: 'fas' },
            { name: 'cannabis', prefix: 'fas' },
            { name: 'tape', prefix: 'fas' },
            { name: 'hand-holding', prefix: 'fas' },
            { name: 'laptop-code', prefix: 'fas' },
            { name: 'circle', prefix: 'fas' },
            { name: 'temperature-high', prefix: 'fas' },
            { name: 'football-ball', prefix: 'fas' },
            { name: 'hippo', prefix: 'fas' },
            { name: 'grin-tongue-wink', prefix: 'fas' },
            { name: 'envelope-square', prefix: 'fas' },
            { name: 'home', prefix: 'fas' },
            { name: 'copyright', prefix: 'fas' },
            { name: 'battery-half', prefix: 'fas' },
            { name: 'music', prefix: 'fas' },
            { name: 'th-list', prefix: 'fas' },
            { name: 'car-crash', prefix: 'fas' },
            { name: 'quote-right', prefix: 'fas' },
            { name: 'low-vision', prefix: 'fas' },
            { name: 'map-marked-alt', prefix: 'fas' },
            { name: 'users-cog', prefix: 'fas' },
            { name: 'tasks', prefix: 'fas' },
            { name: 'igloo', prefix: 'fas' },
            { name: 'recycle', prefix: 'fas' },
            { name: 'eject', prefix: 'fas' },
            { name: 'laugh-wink', prefix: 'fas' },
            { name: 'hand-point-right', prefix: 'fas' },
            { name: 'archway', prefix: 'fas' },
            { name: 'chevron-down', prefix: 'fas' },
            { name: 'heading', prefix: 'fas' },
            { name: 'strikethrough', prefix: 'fas' },
            { name: 'file-word', prefix: 'fas' },
            { name: 'i-cursor', prefix: 'fas' },
            { name: 'hospital-alt', prefix: 'fas' },
            { name: 'money-check', prefix: 'fas' },
            { name: 'door-closed', prefix: 'fas' },
            { name: 'sort-alpha-up', prefix: 'fas' },
            { name: 'compact-disc', prefix: 'fas' },
            { name: 'hard-hat', prefix: 'fas' },
            { name: 'user-alt-slash', prefix: 'fas' },
            { name: 'gas-pump', prefix: 'fas' },
            { name: 'sign-in-alt', prefix: 'fas' },
            { name: 'expand-arrows-alt', prefix: 'fas' },
            { name: 'thermometer-three-quarters', prefix: 'fas' },
            { name: 'weight-hanging', prefix: 'fas' },
            { name: 'couch', prefix: 'fas' },
            { name: 'church', prefix: 'fas' },
            { name: 'crop', prefix: 'fas' },
            { name: 'restroom', prefix: 'fas' },
            { name: 'object-group', prefix: 'fas' },
            { name: 'pen-fancy', prefix: 'fas' },
            { name: 'cookie', prefix: 'fas' },
            { name: 'tag', prefix: 'fas' },
            { name: 'user-tag', prefix: 'fas' },
            { name: 'skiing', prefix: 'fas' },
            { name: 'file-export', prefix: 'fas' },
            { name: 'file-medical-alt', prefix: 'fas' },
            { name: 'briefcase', prefix: 'fas' },
            { name: 'dragon', prefix: 'fas' },
            { name: 'file-audio', prefix: 'fas' },
            { name: 'pen-square', prefix: 'fas' },
            { name: 'grin-wink', prefix: 'fas' },
            { name: 'arrow-circle-up', prefix: 'fas' },
            { name: 'splotch', prefix: 'fas' },
            { name: 'shower', prefix: 'fas' },
            { name: 'teeth-open', prefix: 'fas' },
            { name: 'phone-volume', prefix: 'fas' },
            { name: 'exchange-alt', prefix: 'fas' },
            { name: 'thermometer-half', prefix: 'fas' },
            { name: 'pound-sign', prefix: 'fas' },
            { name: 'headset', prefix: 'fas' },
            { name: 'carrot', prefix: 'fas' },
            { name: 'prescription-bottle', prefix: 'fas' },
            { name: 'map', prefix: 'fas' },
            { name: 'inbox', prefix: 'fas' },
            { name: 'comments', prefix: 'fas' },
            { name: 'align-justify', prefix: 'fas' },
            { name: 'plus-square', prefix: 'fas' },
            { name: 'microphone', prefix: 'fas' },
            { name: 'database', prefix: 'fas' },
            { name: 'calendar-day', prefix: 'fas' },
            { name: 'angle-down', prefix: 'fas' },
            { name: 'hands-helping', prefix: 'fas' },
            { name: 'rss-square', prefix: 'fas' },
            { name: 'dice-five', prefix: 'fas' },
            { name: 'swimmer', prefix: 'fas' },
            { name: 'truck-moving', prefix: 'fas' },
            { name: 'trash-restore', prefix: 'fas' },
            { name: 'satellite-dish', prefix: 'fas' },
            { name: 'text-width', prefix: 'fas' },
            { name: 'globe-asia', prefix: 'fas' },
            { name: 'user-astronaut', prefix: 'fas' },
            { name: 'file-invoice-dollar', prefix: 'fas' },
            { name: 'file', prefix: 'fas' },
            { name: 'meh-blank', prefix: 'fas' },
            { name: 'cloud-upload-alt', prefix: 'fas' },
            { name: 'signal', prefix: 'fas' },
            { name: 'book-dead', prefix: 'fas' },
            { name: 'brain', prefix: 'fas' },
            { name: 'male', prefix: 'fas' },
            { name: 'paint-brush', prefix: 'fas' },
            { name: 'helicopter', prefix: 'fas' },
            { name: 'ruler-horizontal', prefix: 'fas' },
            { name: 'paw', prefix: 'fas' },
            { name: 'chess', prefix: 'fas' },
            { name: 'wine-glass-alt', prefix: 'fas' },
            { name: 'golf-ball', prefix: 'fas' },
            { name: 'road', prefix: 'fas' },
            { name: 'text-height', prefix: 'fas' },
            { name: 'kiss-wink-heart', prefix: 'fas' },
            { name: 'frown-open', prefix: 'fas' },
            { name: 'box-open', prefix: 'fas' },
            { name: 'terminal', prefix: 'fas' },
            { name: 'square-root-alt', prefix: 'fas' },
            { name: 'city', prefix: 'fas' },
            { name: 'comment-dollar', prefix: 'fas' },
            { name: 'chevron-up', prefix: 'fas' },
            { name: 'lira-sign', prefix: 'fas' },
            { name: 'square-full', prefix: 'fas' },
            { name: 'ticket-alt', prefix: 'fas' },
            { name: 'laugh', prefix: 'fas' },
            { name: 'unlock-alt', prefix: 'fas' },
            { name: 'sad-tear', prefix: 'fas' },
            { name: 'file-video', prefix: 'fas' },
            { name: 'house-damage', prefix: 'fas' },
            { name: 'mortar-pestle', prefix: 'fas' },
            { name: 'folder-open', prefix: 'fas' },
            { name: 'plane-departure', prefix: 'fas' },
            { name: 'paint-roller', prefix: 'fas' },
            { name: 'keyboard', prefix: 'fas' },
            { name: 'less-than', prefix: 'fas' },
            { name: 'angle-up', prefix: 'fas' },
            { name: 'bath', prefix: 'fas' },
            { name: 'grin-alt', prefix: 'fas' },
            { name: 'exclamation-triangle', prefix: 'fas' },
            { name: 'question-circle', prefix: 'fas' },
            { name: 'map-signs', prefix: 'fas' },
            { name: 'poo-storm', prefix: 'fas' },
            { name: 'map-marker', prefix: 'fas' },
            { name: 'question', prefix: 'fas' },
            { name: 'lock', prefix: 'fas' },
            { name: 'x-ray', prefix: 'fas' },
            { name: 'fire', prefix: 'fas' },
            { name: 'ban', prefix: 'fas' },
            { name: 'portrait', prefix: 'fas' },
            { name: 'shopping-bag', prefix: 'fas' },
            { name: 'divide', prefix: 'fas' },
            { name: 'piggy-bank', prefix: 'fas' },
            { name: 'file-contract', prefix: 'fas' },
            { name: 'sd-card', prefix: 'fas' },
            { name: 'redo-alt', prefix: 'fas' },
            { name: 'pen', prefix: 'fas' },
            { name: 'drumstick-bite', prefix: 'fas' },
            { name: 'plane', prefix: 'fas' },
            { name: 'chevron-right', prefix: 'fas' },
            { name: 'republican', prefix: 'fas' },
            { name: 'clipboard', prefix: 'fas' },
            { name: 'mars', prefix: 'fas' },
            { name: 'venus-mars', prefix: 'fas' },
            { name: 'truck-pickup', prefix: 'fas' },
            { name: 'life-ring', prefix: 'fas' },
            { name: 'link', prefix: 'fas' },
            { name: 'compress-arrows-alt', prefix: 'fas' },
            { name: 'certificate', prefix: 'fas' },
            { name: 'toggle-off', prefix: 'fas' },
            { name: 'ellipsis-h', prefix: 'fas' },
            { name: 'hot-tub', prefix: 'fas' },
            { name: 'key', prefix: 'fas' },
            { name: 'birthday-cake', prefix: 'fas' },
            { name: 'map-marked', prefix: 'fas' },
            { name: 'gifts', prefix: 'fas' },
            { name: 'level-down-alt', prefix: 'fas' },
            { name: 'meh', prefix: 'fas' },
            { name: 'caret-square-left', prefix: 'fas' },
            { name: 'medkit', prefix: 'fas' },
            { name: 'door-open', prefix: 'fas' },
            { name: 'cloud-sun', prefix: 'fas' },
            { name: 'arrow-right', prefix: 'fas' },
            { name: 'store', prefix: 'fas' },
            { name: 'greater-than-equal', prefix: 'fas' },
            { name: 'hamsa', prefix: 'fas' },
            { name: 'calendar-plus', prefix: 'fas' },
            { name: 'brush', prefix: 'fas' },
            { name: 'at', prefix: 'fas' },
            { name: 'percentage', prefix: 'fas' },
            { name: 'stop-circle', prefix: 'fas' },
            { name: 'concierge-bell', prefix: 'fas' },
            { name: 'phone-slash', prefix: 'fas' },
            { name: 'cubes', prefix: 'fas' },
            { name: 'user-ninja', prefix: 'fas' },
            { name: 'ship', prefix: 'fas' },
            { name: 'qrcode', prefix: 'fas' },
            { name: 'notes-medical', prefix: 'fas' },
            { name: 'ambulance', prefix: 'fas' },
            { name: 'user-clock', prefix: 'fas' },
            { name: 'calendar-check', prefix: 'fas' },
            { name: 'skating', prefix: 'fas' },
            { name: 'stopwatch', prefix: 'fas' },
            { name: 'minus-square', prefix: 'fas' },
            { name: 'yen-sign', prefix: 'fas' },
            { name: 'calendar-week', prefix: 'fas' },
            { name: 'pizza-slice', prefix: 'fas' },
            { name: 'thumbs-down', prefix: 'fas' },
            { name: 'comment-medical', prefix: 'fas' },
            { name: 'compress', prefix: 'fas' },
            { name: 'envelope', prefix: 'fas' },
            { name: 'user-md', prefix: 'fas' },
            { name: 'utensils', prefix: 'fas' },
            { name: 'person-booth', prefix: 'fas' },
            { name: 'archive', prefix: 'fas' },
            { name: 'caret-up', prefix: 'fas' },
            { name: 'hand-point-left', prefix: 'fas' },
            { name: 'location-arrow', prefix: 'fas' },
            { name: 'magnet', prefix: 'fas' },
            { name: 'school', prefix: 'fas' },
            { name: 'diagnoses', prefix: 'fas' },
            { name: 'ruler-vertical', prefix: 'fas' },
            { name: 'desktop', prefix: 'fas' },
            { name: 'download', prefix: 'fas' },
            { name: 'hand-lizard', prefix: 'fas' },
            { name: 'baseball-ball', prefix: 'fas' },
            { name: 'expand', prefix: 'fas' },
            { name: 'people-carry', prefix: 'fas' },
            { name: 'khanda', prefix: 'fas' },
            { name: 'teeth', prefix: 'fas' },
            { name: 'fighter-jet', prefix: 'fas' },
            { name: 'hand-scissors', prefix: 'fas' },
            { name: 'vihara', prefix: 'fas' },
            { name: 'battery-three-quarters', prefix: 'fas' },
            { name: 'book-open', prefix: 'fas' },
            { name: 'hourglass-start', prefix: 'fas' },
            { name: 'comment-slash', prefix: 'fas' },
            { name: 'sign-out-alt', prefix: 'fas' },
            { name: 'glass-cheers', prefix: 'fas' },
            { name: 'file-import', prefix: 'fas' },
            { name: 'transgender-alt', prefix: 'fas' },
            { name: 'hand-point-up', prefix: 'fas' },
            { name: 'server', prefix: 'fas' },
            { name: 'bolt', prefix: 'fas' },
            { name: 'tv', prefix: 'fas' },
            { name: 'sms', prefix: 'fas' },
            { name: 'democrat', prefix: 'fas' },
            { name: 'user-plus', prefix: 'fas' },
            { name: 'tablet-alt', prefix: 'fas' },
            { name: 'money-bill-alt', prefix: 'fas' },
            { name: 'cocktail', prefix: 'fas' },
            { name: 'chevron-circle-right', prefix: 'fas' },
            { name: 'star-and-crescent', prefix: 'fas' },
            { name: 'angle-double-right', prefix: 'fas' },
            { name: 'shapes', prefix: 'fas' },
            { name: 'solar-panel', prefix: 'fas' },
            { name: 'mosque', prefix: 'fas' },
            { name: 'satellite', prefix: 'fas' },
            { name: 'indent', prefix: 'fas' },
            { name: 'sort-amount-down', prefix: 'fas' },
            { name: 'chevron-circle-left', prefix: 'fas' },
            { name: 'ad', prefix: 'fas' },
            { name: 'dna', prefix: 'fas' },
            { name: 'slash', prefix: 'fas' },
            { name: 'taxi', prefix: 'fas' },
            { name: 'book', prefix: 'fas' },
            { name: 'hotdog', prefix: 'fas' },
            { name: 'hotel', prefix: 'fas' },
            { name: 'object-ungroup', prefix: 'fas' },
            { name: 'skull', prefix: 'fas' },
            { name: 'thermometer-full', prefix: 'fas' },
            { name: 'paragraph', prefix: 'fas' },
            { name: 'plug', prefix: 'fas' },
            { name: 'thermometer-empty', prefix: 'fas' },
            { name: 'train', prefix: 'fas' },
            { name: 'h-square', prefix: 'fas' },
            { name: 'chess-board', prefix: 'fas' },
            { name: 'user-minus', prefix: 'fas' },
            { name: 'dizzy', prefix: 'fas' },
            { name: 'wallet', prefix: 'fas' },
            { name: 'file-image', prefix: 'fas' },
            { name: 'bell', prefix: 'fas' },
            { name: 'coffee', prefix: 'fas' },
            { name: 'code', prefix: 'fas' },
            { name: 'dolly', prefix: 'fas' },
            { name: 'user-lock', prefix: 'fas' },
            { name: 'volleyball-ball', prefix: 'fas' },
            { name: 'shield-alt', prefix: 'fas' },
            { name: 'long-arrow-alt-up', prefix: 'fas' },
            { name: 'cloud-moon-rain', prefix: 'fas' },
            { name: 'project-diagram', prefix: 'fas' },
            { name: 'thermometer', prefix: 'fas' },
            { name: 'air-freshener', prefix: 'fas' },
            { name: 'spider', prefix: 'fas' },
            { name: 'flag', prefix: 'fas' },
            { name: 'podcast', prefix: 'fas' },
            { name: 'user-times', prefix: 'fas' },
            { name: 'glass-martini-alt', prefix: 'fas' },
            { name: 'hand-peace', prefix: 'fas' },
            { name: 'level-up-alt', prefix: 'fas' },
            { name: 'truck-loading', prefix: 'fas' },
            { name: 'chess-king', prefix: 'fas' },
            { name: 'stop', prefix: 'fas' },
            { name: 'arrow-circle-left', prefix: 'fas' },
            { name: 'newspaper', prefix: 'fas' },
            { name: 'snowflake', prefix: 'fas' },
            { name: 'step-forward', prefix: 'fas' },
            { name: 'cube', prefix: 'fas' },
            { name: 'bomb', prefix: 'fas' },
            { name: 'arrows-alt-h', prefix: 'fas' },
            { name: 'file-medical', prefix: 'fas' },
            { name: 'shuttle-van', prefix: 'fas' },
            { name: 'star-half', prefix: 'fas' },
            { name: 'pencil-ruler', prefix: 'fas' },
            { name: 'ruler', prefix: 'fas' },
            { name: 'frown', prefix: 'fas' },
            { name: 'truck-monster', prefix: 'fas' },
            { name: 'grin-tongue-squint', prefix: 'fas' },
            { name: 'outdent', prefix: 'fas' },
            { name: 'clipboard-list', prefix: 'fas' },
            { name: 'fax', prefix: 'fas' },
            { name: 'biohazard', prefix: 'fas' },
            { name: 'caret-right', prefix: 'fas' },
            { name: 'user-graduate', prefix: 'fas' },
            { name: 'map-marker-alt', prefix: 'fas' },
            { name: 'smog', prefix: 'fas' },
            { name: 'bold', prefix: 'fas' },
            { name: 'tablets', prefix: 'fas' },
            { name: 'envelope-open', prefix: 'fas' },
            { name: 'trophy', prefix: 'fas' },
            { name: 'share-alt', prefix: 'fas' },
            { name: 'battery-full', prefix: 'fas' },
            { name: 'equals', prefix: 'fas' },
            { name: 'plus', prefix: 'fas' },
            { name: 'check', prefix: 'fas' },
            { name: 'seedling', prefix: 'fas' },
            { name: 'star-of-david', prefix: 'fas' },
            { name: 'share-square', prefix: 'fas' },
            { name: 'ruler-combined', prefix: 'fas' },
            { name: 'tenge', prefix: 'fas' },
            { name: 'trademark', prefix: 'fas' },
            { name: 'kaaba', prefix: 'fas' },
            { name: 'smoking', prefix: 'fas' },
            { name: 'hospital', prefix: 'fas' },
            { name: 'flask', prefix: 'fas' },
            { name: 'mars-stroke-v', prefix: 'fas' },
            { name: 'headphones', prefix: 'fas' },
            { name: 'horse-head', prefix: 'fas' },
            { name: 'rss', prefix: 'fas' },
            { name: 'horse', prefix: 'fas' },
            { name: 'landmark', prefix: 'fas' },
            { name: 'money-check-alt', prefix: 'fas' },
            { name: 'wifi', prefix: 'fas' },
            { name: 'radiation-alt', prefix: 'fas' },
            { name: 'file-archive', prefix: 'fas' },
            { name: 'chalkboard-teacher', prefix: 'fas' },
            { name: 'angle-right', prefix: 'fas' },
            { name: 'arrow-circle-right', prefix: 'fas' },
            { name: 'venus', prefix: 'fas' },
            { name: 'mug-hot', prefix: 'fas' },
            { name: 'info', prefix: 'fas' },
            { name: 'bullhorn', prefix: 'fas' },
            { name: 'bread-slice', prefix: 'fas' },
            { name: 'microphone-alt-slash', prefix: 'fas' },
            { name: 'dog', prefix: 'fas' },
            { name: 'user-nurse', prefix: 'fas' },
            { name: 'palette', prefix: 'fas' },
            { name: 'share-alt-square', prefix: 'fas' },
            { name: 'cart-arrow-down', prefix: 'fas' },
            { name: 'directions', prefix: 'fas' },
            { name: 'om', prefix: 'fas' },
            { name: 'hourglass-half', prefix: 'fas' },
            { name: 'holly-berry', prefix: 'fas' },
            { name: 'flag-usa', prefix: 'fas' },
            { name: 'info-circle', prefix: 'fas' },
            { name: 'smoking-ban', prefix: 'fas' },
            { name: 'folder-plus', prefix: 'fas' },
            { name: 'grip-horizontal', prefix: 'fas' },
            { name: 'space-shuttle', prefix: 'fas' },
            { name: 'grin-beam', prefix: 'fas' },
            { name: 'feather-alt', prefix: 'fas' },
            { name: 'rainbow', prefix: 'fas' },
            { name: 'cookie-bite', prefix: 'fas' },
            { name: 'copy', prefix: 'fas' },
            { name: 'funnel-dollar', prefix: 'fas' },
            { name: 'tram', prefix: 'fas' },
            { name: 'fish', prefix: 'fas' },
            { name: 'signature', prefix: 'fas' },
            { name: 'not-equal', prefix: 'fas' },
            { name: 'kiss-beam', prefix: 'fas' },
            { name: 'burn', prefix: 'fas' },
            { name: 'glasses', prefix: 'fas' },
            { name: 'coins', prefix: 'fas' },
            { name: 'closed-captioning', prefix: 'fas' },
            { name: 'monument', prefix: 'fas' },
            { name: 'hat-wizard', prefix: 'fas' },
            { name: 'arrow-alt-circle-right', prefix: 'fas' },
            { name: 'less-than-equal', prefix: 'fas' },
            { name: 'file-download', prefix: 'fas' },
            { name: 'tint', prefix: 'fas' },
            { name: 'fast-backward', prefix: 'fas' },
            { name: 'industry', prefix: 'fas' },
            { name: 'child', prefix: 'fas' },
            { name: 'fire-extinguisher', prefix: 'fas' },
            { name: 'marker', prefix: 'fas' },
            { name: 'align-right', prefix: 'fas' },
            { name: 'image', prefix: 'fas' },
            { name: 'hand-point-down', prefix: 'fas' },
            { name: 'vector-square', prefix: 'fas' },
            { name: 'lightbulb', prefix: 'fas' },
            { name: 'ellipsis-v', prefix: 'fas' },
            { name: 'check-circle', prefix: 'fas' },
            { name: 'save', prefix: 'fas' },
            { name: 'smile', prefix: 'fas' },
            { name: 'images', prefix: 'fas' },
            { name: 'tshirt', prefix: 'fas' },
            { name: 'pager', prefix: 'fas' },
            { name: 'grin-tears', prefix: 'fas' },
            { name: 'donate', prefix: 'fas' },
            { name: 'calendar-alt', prefix: 'fas' },
            { name: 'th-large', prefix: 'fas' },
            { name: 'laugh-beam', prefix: 'fas' },
            { name: 'code-branch', prefix: 'fas' },
            { name: 'vr-cardboard', prefix: 'fas' },
            { name: 'eye-dropper', prefix: 'fas' },
            { name: 'hand-paper', prefix: 'fas' },
            { name: 'caret-square-down', prefix: 'fas' },
            { name: 'dice-d6', prefix: 'fas' },
            { name: 'volume-mute', prefix: 'fas' },
            { name: 'search-plus', prefix: 'fas' },
            { name: 'paperclip', prefix: 'fas' },
            { name: 'file-alt', prefix: 'fas' },
            { name: 'street-view', prefix: 'fas' },
            { name: 'fast-forward', prefix: 'fas' },
            { name: 'syringe', prefix: 'fas' },
            { name: 'car-battery', prefix: 'fas' },
            { name: 'award', prefix: 'fas' },
            { name: 'baby-carriage', prefix: 'fas' },
            { name: 'box', prefix: 'fas' },
            { name: 'thumbs-up', prefix: 'fas' },
            { name: 'superscript', prefix: 'fas' },
            { name: 'glass-martini', prefix: 'fas' },
            { name: 'hiking', prefix: 'fas' },
            { name: 'mask', prefix: 'fas' },
            { name: 'peace', prefix: 'fas' },
            { name: 'percent', prefix: 'fas' },
            { name: 'square', prefix: 'fas' },
            { name: 'crown', prefix: 'fas' },
            { name: 'broom', prefix: 'fas' },
            { name: 'stethoscope', prefix: 'fas' },
            { name: 'eye-slash', prefix: 'fas' },
            { name: 'candy-cane', prefix: 'fas' },
            { name: 'play', prefix: 'fas' },
            { name: 'backward', prefix: 'fas' },
            { name: 'paper-plane', prefix: 'fas' },
            { name: 'cogs', prefix: 'fas' },
            { name: 'pencil-alt', prefix: 'fas' },
            { name: 'greater-than', prefix: 'fas' },
            { name: 'arrow-alt-circle-up', prefix: 'fas' },
            { name: 'dungeon', prefix: 'fas' },
            { name: 'user-edit', prefix: 'fas' },
            { name: 'table', prefix: 'fas' },
            { name: 'barcode', prefix: 'fas' },
            { name: 'thumbtack', prefix: 'fas' },
            { name: 'hourglass-end', prefix: 'fas' },
            { name: 'scroll', prefix: 'fas' },
            { name: 'flag-checkered', prefix: 'fas' },
            { name: 'chalkboard', prefix: 'fas' },
            { name: 'radiation', prefix: 'fas' },
            { name: 'th', prefix: 'fas' },
            { name: 'baby', prefix: 'fas' },
            { name: 'shekel-sign', prefix: 'fas' },
            { name: 'grin-squint-tears', prefix: 'fas' },
            { name: 'battery-empty', prefix: 'fas' },
            { name: 'star-of-life', prefix: 'fas' },
            { name: 'camera', prefix: 'fas' },
            { name: 'magic', prefix: 'fas' },
            { name: 'redo', prefix: 'fas' },
            { name: 'file-powerpoint', prefix: 'fas' },
            { name: 'folder-minus', prefix: 'fas' },
            { name: 'otter', prefix: 'fas' },
            { name: 'sync-alt', prefix: 'fas' },
            { name: 'sim-card', prefix: 'fas' },
            { name: 'truck', prefix: 'fas' },
            { name: 'grin-tongue', prefix: 'fas' },
            { name: 'apple-alt', prefix: 'fas' },
            { name: 'grin-beam-sweat', prefix: 'fas' },
            { name: 'glass-whiskey', prefix: 'fas' },
            { name: 'oil-can', prefix: 'fas' },
            { name: 'blender', prefix: 'fas' },
            { name: 'wine-glass', prefix: 'fas' },
            { name: 'hand-spock', prefix: 'fas' },
            { name: 'bible', prefix: 'fas' },
            { name: 'toilet-paper', prefix: 'fas' },
            { name: 'egg', prefix: 'fas' },
            { name: 'user-friends', prefix: 'fas' },
            { name: 'bullseye', prefix: 'fas' },
            { name: 'exclamation-circle', prefix: 'fas' },
            { name: 'bus', prefix: 'fas' },
            { name: 'book-reader', prefix: 'fas' },
            { name: 'dolly-flatbed', prefix: 'fas' },
            { name: 'mercury', prefix: 'fas' },
            { name: 'infinity', prefix: 'fas' },
            { name: 'cloud-download-alt', prefix: 'fas' },
            { name: 'gem', prefix: 'fas' },
            { name: 'audio-description', prefix: 'fas' },
            { name: 'skull-crossbones', prefix: 'fas' },
            { name: 'feather', prefix: 'fas' },
            { name: 'trash', prefix: 'fas' },
            { name: 'chart-bar', prefix: 'fas' },
            { name: 'fire-alt', prefix: 'fas' },
            { name: 'transgender', prefix: 'fas' },
            { name: 'dumpster-fire', prefix: 'fas' },
            { name: 'toilet', prefix: 'fas' },
            { name: 'step-backward', prefix: 'fas' },
            { name: 'leaf', prefix: 'fas' },
            { name: 'paste', prefix: 'fas' },
            { name: 'window-maximize', prefix: 'fas' },
            { name: 'gopuram', prefix: 'fas' },
            { name: 'address-book', prefix: 'fas' },
            { name: 'memory', prefix: 'fas' },
            { name: 'dollar-sign', prefix: 'fas' },
            { name: 'journal-whills', prefix: 'fas' },
            { name: 'meh-rolling-eyes', prefix: 'fas' },
            { name: 'graduation-cap', prefix: 'fas' },
            { name: 'kiwi-bird', prefix: 'fas' },
            { name: 'volume-up', prefix: 'fas' },
            { name: 'fill-drip', prefix: 'fas' },
            { name: 'sliders-h', prefix: 'fas' },
            { name: 'running', prefix: 'fas' },
            { name: 'hashtag', prefix: 'fas' },
            { name: 'guitar', prefix: 'fas' },
            { name: 'bong', prefix: 'fas' },
            { name: 'star', prefix: 'fas' },
            { name: 'arrows-alt-v', prefix: 'fas' },
            { name: 'euro-sign', prefix: 'fas' },
            { name: 'sun', prefix: 'fas' },
            { name: 'wrench', prefix: 'fas' },
            { name: 'arrow-circle-down', prefix: 'fas' },
            { name: 'circle-notch', prefix: 'fas' },
            { name: 'shoe-prints', prefix: 'fas' },
            { name: 'edit', prefix: 'fas' },
            { name: 'bacon', prefix: 'fas' },
            { name: 'unlink', prefix: 'fas' },
            { name: 'plane-arrival', prefix: 'fas' },
            { name: 'bone', prefix: 'fas' },
            { name: 'bus-alt', prefix: 'fas' },
            { name: 'crutch', prefix: 'fas' },
            { name: 'anchor', prefix: 'fas' },
            { name: 'jedi', prefix: 'fas' },
            { name: 'hammer', prefix: 'fas' },
            { name: 'search-location', prefix: 'fas' },
            { name: 'highlighter', prefix: 'fas' },
            { name: 'address-card', prefix: 'fas' },
            { name: 'caret-square-right', prefix: 'fas' },
            { name: 'wheelchair', prefix: 'fas' },
            { name: 'chart-pie', prefix: 'fas' },
            { name: 'lemon', prefix: 'fas' },
            { name: 'place-of-worship', prefix: 'fas' },
            { name: 'bug', prefix: 'fas' },
            { name: 'smile-beam', prefix: 'fas' },
            { name: 'praying-hands', prefix: 'fas' },
            { name: 'mars-stroke-h', prefix: 'fas' },
            { name: 'spinner', prefix: 'fas' },
            { name: 'unlock', prefix: 'fas' },
            { name: 'adjust', prefix: 'fas' },
            { name: 'hand-holding-heart', prefix: 'fas' },
            { name: 'compass', prefix: 'fas' },
            { name: 'meteor', prefix: 'fas' },
            { name: 'plus-circle', prefix: 'fas' },
            { name: 'search-dollar', prefix: 'fas' },
            { name: 'external-link-square-alt', prefix: 'fas' },
            { name: 'traffic-light', prefix: 'fas' },
            { name: 'bicycle', prefix: 'fas' },
            { name: 'menorah', prefix: 'fas' },
            { name: 'cloud-moon', prefix: 'fas' },
            { name: 'warehouse', prefix: 'fas' },
            { name: 'chevron-circle-down', prefix: 'fas' },
            { name: 'credit-card', prefix: 'fas' },
            { name: 'user-circle', prefix: 'fas' },
            { name: 'joint', prefix: 'fas' },
            { name: 'user-tie', prefix: 'fas' },
            { name: 'snowman', prefix: 'fas' },
            { name: 'chevron-circle-up', prefix: 'fas' },
            { name: 'tachometer-alt', prefix: 'fas' },
            { name: 'vial', prefix: 'fas' },
            { name: 'reply-all', prefix: 'fas' },
            { name: 'sort-alpha-down', prefix: 'fas' },
            { name: 'language', prefix: 'fas' },
            { name: 'cloud-rain', prefix: 'fas' },
            { name: 'mars-stroke', prefix: 'fas' },
            { name: 'venus-double', prefix: 'fas' },
            { name: 'trash-alt', prefix: 'fas' },
            { name: 'haykal', prefix: 'fas' },
            { name: 'window-close', prefix: 'fas' },
            { name: 'tired', prefix: 'fas' },
            { name: 'sitemap', prefix: 'fas' },
            { name: 'deaf', prefix: 'fas' },
            { name: 'crosshairs', prefix: 'fas' },
            { name: 'poop', prefix: 'fas' },
            { name: 'clock', prefix: 'fas' },
            { name: 'window-minimize', prefix: 'fas' },
            { name: 'retweet', prefix: 'fas' },
            { name: 'phone', prefix: 'fas' },
            { name: 'sad-cry', prefix: 'fas' },
            { name: 'crop-alt', prefix: 'fas' },
            { name: 'mail-bulk', prefix: 'fas' },
            { name: 'dumbbell', prefix: 'fas' },
            { name: 'sync', prefix: 'fas' },
            { name: 'wine-bottle', prefix: 'fas' },
            { name: 'tractor', prefix: 'fas' },
            { name: 'eye', prefix: 'fas' },
            { name: 'mobile', prefix: 'fas' },
            { name: 'universal-access', prefix: 'fas' },
            { name: 'user-injured', prefix: 'fas' },
            { name: 'angle-double-up', prefix: 'fas' },
            { name: 'book-medical', prefix: 'fas' },
            { name: 'dove', prefix: 'fas' },
            { name: 'car-side', prefix: 'fas' },
            { name: 'gift', prefix: 'fas' },
            { name: 'car', prefix: 'fas' },
            { name: 'registered', prefix: 'fas' },
            { name: 'file-invoice', prefix: 'fas' },
            { name: 'long-arrow-alt-down', prefix: 'fas' },
            { name: 'draw-polygon', prefix: 'fas' },
            { name: 'battery-quarter', prefix: 'fas' },
            { name: 'atlas', prefix: 'fas' },
            { name: 'table-tennis', prefix: 'fas' },
            { name: 'layer-group', prefix: 'fas' },
            { name: 'arrow-alt-circle-down', prefix: 'fas' },
            { name: 'share', prefix: 'fas' },
            { name: 'tags', prefix: 'fas' },
            { name: 'vials', prefix: 'fas' },
            { name: 'globe-europe', prefix: 'fas' },
            { name: 'arrow-up', prefix: 'fas' },
            { name: 'balance-scale', prefix: 'fas' },
            { name: 'grin', prefix: 'fas' },
            { name: 'microscope', prefix: 'fas' },
            { name: 'subway', prefix: 'fas' },
            { name: 'microphone-alt', prefix: 'fas' },
            { name: 'first-aid', prefix: 'fas' },
            { name: 'drum', prefix: 'fas' },
            { name: 'sort', prefix: 'fas' },
            { name: 'procedures', prefix: 'fas' },
            { name: 'calendar-minus', prefix: 'fas' },
            { name: 'ethernet', prefix: 'fas' },
            { name: 'undo', prefix: 'fas' },
            { name: 'video', prefix: 'fas' },
            { name: 'robot', prefix: 'fas' },
            { name: 'pepper-hot', prefix: 'fas' },
            { name: 'tree', prefix: 'fas' },
            { name: 'id-card', prefix: 'fas' },
            { name: 'pills', prefix: 'fas' },
            { name: 'puzzle-piece', prefix: 'fas' },
            { name: 'map-pin', prefix: 'fas' },
            { name: 'filter', prefix: 'fas' },
            { name: 'allergies', prefix: 'fas' },
            { name: 'suitcase-rolling', prefix: 'fas' },
            { name: 'water', prefix: 'fas' },
            { name: 'cloud-showers-heavy', prefix: 'fas' },
            { name: 'chess-pawn', prefix: 'fas' },
            { name: 'umbrella-beach', prefix: 'fas' },
            { name: 'check-double', prefix: 'fas' },
            { name: 'file-signature', prefix: 'fas' },
            { name: 'italic', prefix: 'fas' },
            { name: 'globe-americas', prefix: 'fas' },
            { name: 'bell-slash', prefix: 'fas' },
            { name: 'handshake', prefix: 'fas' },
            { name: 'money-bill', prefix: 'fas' },
            { name: 'medal', prefix: 'fas' },
            { name: 'mars-double', prefix: 'fas' },
            { name: 'won-sign', prefix: 'fas' },
            { name: 'comment', prefix: 'fas' },
            { name: 'torii-gate', prefix: 'fas' },
            { name: 'video-slash', prefix: 'fas' },
            { name: 'calendar', prefix: 'fas' },
            { name: 'globe', prefix: 'fas' },
            { name: 'arrow-left', prefix: 'fas' },
            { name: 'align-center', prefix: 'fas' },
            { name: 'hospital-symbol', prefix: 'fas' },
            { name: 'cross', prefix: 'fas' },
            { name: 'blog', prefix: 'fas' },
            { name: 'university', prefix: 'fas' },
            { name: 'route', prefix: 'fas' },
            { name: 'bowling-ball', prefix: 'fas' },
            { name: 'money-bill-wave-alt', prefix: 'fas' },
            { name: 'minus-circle', prefix: 'fas' },
            { name: 'grip-lines-vertical', prefix: 'fas' },
            { name: 'microchip', prefix: 'fas' },
            { name: 'frog', prefix: 'fas' },
            { name: 'laptop', prefix: 'fas' },
            { name: 'power-off', prefix: 'fas' },
            { name: 'theater-masks', prefix: 'fas' },
            { name: 'heartbeat', prefix: 'fas' },
            { name: 'chart-area', prefix: 'fas' },
            { name: 'sign-language', prefix: 'fas' },
            { name: 'quote-left', prefix: 'fas' },
            { name: 'file-prescription', prefix: 'fas' },
            { name: 'mountain', prefix: 'fas' },
            { name: 'microphone-slash', prefix: 'fas' },
            { name: 'times-circle', prefix: 'fas' },
            { name: 'caret-left', prefix: 'fas' },
            { name: 'dice-two', prefix: 'fas' },
            { name: 'tooth', prefix: 'fas' },
            { name: 'chess-queen', prefix: 'fas' },
            { name: 'calendar-times', prefix: 'fas' },
            { name: 'headphones-alt', prefix: 'fas' },
            { name: 'digital-tachograph', prefix: 'fas' },
            { name: 'file-code', prefix: 'fas' },
            { name: 'file-csv', prefix: 'fas' },
            { name: 'external-link-alt', prefix: 'fas' },
            { name: 'heart-broken', prefix: 'fas' },
            { name: 'angle-double-down', prefix: 'fas' },
            { name: 'dice-six', prefix: 'fas' },
            { name: 'cut', prefix: 'fas' },
            { name: 'user-alt', prefix: 'fas' },
            { name: 'cloud', prefix: 'fas' },
            { name: 'hockey-puck', prefix: 'fas' },
            { name: 'tty', prefix: 'fas' },
            { name: 'grip-vertical', prefix: 'fas' },
            { name: 'futbol', prefix: 'fas' },
            { name: 'snowboarding', prefix: 'fas' },
            { name: 'cheese', prefix: 'fas' },
            { name: 'comment-alt', prefix: 'fas' },
            { name: 'dice', prefix: 'fas' },
            { name: 'bars', prefix: 'fas' },
            { name: 'bezier-curve', prefix: 'fas' },
            { name: 'chair', prefix: 'fas' },
            { name: 'dice-d20', prefix: 'fas' },
            { name: 'suitcase', prefix: 'fas' },
            { name: 'laugh-squint', prefix: 'fas' },
            { name: 'sort-numeric-down', prefix: 'fas' },
            { name: 'motorcycle', prefix: 'fas' },
            { name: 'upload', prefix: 'fas' },
            { name: 'pause', prefix: 'fas' },
            { name: 'forward', prefix: 'fas' },
            { name: 'dice-four', prefix: 'fas' },
            { name: 'bookmark', prefix: 'fas' },
            { name: 'braille', prefix: 'fas' },
            { name: 'file-upload', prefix: 'fas' },
            { name: 'rocket', prefix: 'fas' },
            { name: 'icicles', prefix: 'fas' },
            { name: 'caret-square-up', prefix: 'fas' },
            { name: 'blender-phone', prefix: 'fas' },
            { name: 'asterisk', prefix: 'fas' },
            { name: 'user-check', prefix: 'fas' },
            { name: 'hand-pointer', prefix: 'fas' },
            { name: 'cloud-sun-rain', prefix: 'fas' },
            { name: 'rupee-sign', prefix: 'fas' },
            { name: 'sleigh', prefix: 'fas' },
            { name: 'tablet', prefix: 'fas' },
            { name: 'kiss', prefix: 'fas' },
            { name: 'list-ol', prefix: 'fas' },
            { name: 'random', prefix: 'fas' },
            { name: 'car-alt', prefix: 'fas' },
            { name: 'laptop-medical', prefix: 'fas' },
            { name: 'ruble-sign', prefix: 'fas' },
            { name: 'store-alt', prefix: 'fas' },
            { name: 'hand-rock', prefix: 'fas' },
            { name: 'lock-open', prefix: 'fas' },
            { name: 'atom', prefix: 'fas' },
            { name: 'cat', prefix: 'fas' },
            { name: 'yin-yang', prefix: 'fas' },
            { name: 'grin-stars', prefix: 'fas' },
            { name: 'ice-cream', prefix: 'fas' },
            { name: 'ring', prefix: 'fas' },
            { name: 'poll', prefix: 'fas' },
            { name: 'globe-africa', prefix: 'fas' },
            { name: 'parking', prefix: 'fas' },
            { name: 'snowplow', prefix: 'fas' },
            { name: 'backspace', prefix: 'fas' },
            { name: 'angle-left', prefix: 'fas' },
            { name: 'hdd', prefix: 'fas' },
            { name: 'sticky-note', prefix: 'fas' },
            { name: 'shopping-cart', prefix: 'fas' },
            { name: 'parachute-box', prefix: 'fas' },
            { name: 'spa', prefix: 'fas' },
            { name: 'sort-down', prefix: 'fas' },
            { name: 'calculator', prefix: 'fas' },
            { name: 'window-restore', prefix: 'fas' },
            { name: 'user-slash', prefix: 'fas' },
            { name: 'vote-yea', prefix: 'fas' },
            { name: 'shipping-fast', prefix: 'fas' },
            { name: 'screwdriver', prefix: 'fas' },
            { name: 'female', prefix: 'fas' },
            { name: 'money-bill-wave', prefix: 'fas' },
            { name: 'hand-middle-finger', prefix: 'fas' },
            { name: 'chess-bishop', prefix: 'fas' },
            { name: 'shopping-basket', prefix: 'fas' },
            { name: 'dharmachakra', prefix: 'fas' },
            { name: 'charging-station', prefix: 'fas' },
            { name: 'building', prefix: 'fas' },
            { name: 'binoculars', prefix: 'fas' },
            { name: 'angry', prefix: 'fas' },
            { name: 'genderless', prefix: 'fas' },
            { name: 'quidditch', prefix: 'fas' },
            { name: 'history', prefix: 'fas' },
            { name: 'gavel', prefix: 'fas' },
            { name: 'torah', prefix: 'fas' },
            { name: 'folder', prefix: 'fas' },
            { name: 'arrows-alt', prefix: 'fas' },
            { name: 'drafting-compass', prefix: 'fas' },
            { name: 'assistive-listening-systems', prefix: 'fas' },
            { name: 'users', prefix: 'fas' },
            { name: 'hands', prefix: 'fas' },
            { name: 'fingerprint', prefix: 'fas' },
            { name: 'fill', prefix: 'fas' },
            { name: 'hanukiah', prefix: 'fas' },
            { name: 'synagogue', prefix: 'fas' },
            { name: 'file-pdf', prefix: 'fas' },
            { name: 'flushed', prefix: 'fas' },
            { name: 'gamepad', prefix: 'fas' },
            { name: 'walking', prefix: 'fas' },
            { name: 'clipboard-check', prefix: 'fas' },
            { name: 'minus', prefix: 'fas' },
            { name: 'phone-square', prefix: 'fas' },
            { name: 'times', prefix: 'fas' },
            { name: 'chevron-left', prefix: 'fas' },
            { name: 'film', prefix: 'fas' },
            { name: 'print', prefix: 'fas' },
            { name: 'moon', prefix: 'fas' },
            { name: 'stroopwafel', prefix: 'fas' },
            { name: 'pray', prefix: 'fas' },
            { name: 'exclamation', prefix: 'fas' },
            { name: 'weight', prefix: 'fas' },
            { name: 'band-aid', prefix: 'fas' },
            { name: 'sort-amount-up', prefix: 'fas' },
            { name: 'capsules', prefix: 'fas' },
            { name: 'surprise', prefix: 'fas' },
            { name: 'trash-restore-alt', prefix: 'fas' },
            { name: 'user-shield', prefix: 'fas' },
            { name: 'mouse-pointer', prefix: 'fas' },
            { name: 'ribbon', prefix: 'fas' },
            { name: 'swatchbook', prefix: 'fas' },
            { name: 'swimming-pool', prefix: 'fas' },
            { name: 'stamp', prefix: 'fas' },
            { name: 'sort-up', prefix: 'fas' },
            { name: 'align-left', prefix: 'fas' },
            { name: 'temperature-low', prefix: 'fas' },
            { name: 'pastafarianism', prefix: 'fas' },
            { name: 'heart', prefix: 'fas' },
            { name: 'font', prefix: 'fas' },

            // Brands
            { name: 'viadeo-square', prefix: 'fab' },
            { name: 'supple', prefix: 'fab' },
            { name: 'weixin', prefix: 'fab' },
            { name: 'connectdevelop', prefix: 'fab' },
            { name: 'medium', prefix: 'fab' },
            { name: 'ioxhost', prefix: 'fab' },
            { name: 'snapchat-ghost', prefix: 'fab' },
            { name: 'fonticons', prefix: 'fab' },
            { name: 'figma', prefix: 'fab' },
            { name: 'reddit-alien', prefix: 'fab' },
            { name: 'angrycreative', prefix: 'fab' },
            { name: 'imdb', prefix: 'fab' },
            { name: 'steam', prefix: 'fab' },
            { name: 'd-and-d-beyond', prefix: 'fab' },
            { name: 'tumblr', prefix: 'fab' },
            { name: 'tripadvisor', prefix: 'fab' },
            { name: 'penny-arcade', prefix: 'fab' },
            { name: 'bandcamp', prefix: 'fab' },
            { name: 'red-river', prefix: 'fab' },
            { name: 'strava', prefix: 'fab' },
            { name: 'dropbox', prefix: 'fab' },
            { name: 'microsoft', prefix: 'fab' },
            { name: 'creative-commons-nd', prefix: 'fab' },
            { name: 'dyalog', prefix: 'fab' },
            { name: 'squarespace', prefix: 'fab' },
            { name: 'google-plus-square', prefix: 'fab' },
            { name: 'whatsapp-square', prefix: 'fab' },
            { name: 'accusoft', prefix: 'fab' },
            { name: 'lastfm', prefix: 'fab' },
            { name: 'phoenix-squadron', prefix: 'fab' },
            { name: 'yelp', prefix: 'fab' },
            { name: 'deviantart', prefix: 'fab' },
            { name: 'usps', prefix: 'fab' },
            { name: 'envira', prefix: 'fab' },
            { name: 'fulcrum', prefix: 'fab' },
            { name: 'linux', prefix: 'fab' },
            { name: 'foursquare', prefix: 'fab' },
            { name: 'drupal', prefix: 'fab' },
            { name: 'cc-stripe', prefix: 'fab' },
            { name: 'dev', prefix: 'fab' },
            { name: 'speakap', prefix: 'fab' },
            { name: 'android', prefix: 'fab' },
            { name: 'adobe', prefix: 'fab' },
            { name: 'git-square', prefix: 'fab' },
            { name: 'ethereum', prefix: 'fab' },
            { name: 'shirtsinbulk', prefix: 'fab' },
            { name: 'black-tie', prefix: 'fab' },
            { name: 'odnoklassniki', prefix: 'fab' },
            { name: 'fonticons-fi', prefix: 'fab' },
            { name: 'cc-amex', prefix: 'fab' },
            { name: 'js', prefix: 'fab' },
            { name: 'galactic-senate', prefix: 'fab' },
            { name: 'reacteurope', prefix: 'fab' },
            { name: 'openid', prefix: 'fab' },
            { name: 'gitlab', prefix: 'fab' },
            { name: 'sourcetree', prefix: 'fab' },
            { name: 'gg', prefix: 'fab' },
            { name: 'joget', prefix: 'fab' },
            { name: 'weibo', prefix: 'fab' },
            { name: 'aws', prefix: 'fab' },
            { name: 'sass', prefix: 'fab' },
            { name: 'cc-paypal', prefix: 'fab' },
            { name: 'superpowers', prefix: 'fab' },
            { name: 'sellcast', prefix: 'fab' },
            { name: 'java', prefix: 'fab' },
            { name: 'tencent-weibo', prefix: 'fab' },
            { name: 'trade-federation', prefix: 'fab' },
            { name: 'viber', prefix: 'fab' },
            { name: 'ello', prefix: 'fab' },
            { name: 'wix', prefix: 'fab' },
            { name: 'mizuni', prefix: 'fab' },
            { name: 'leanpub', prefix: 'fab' },
            { name: 'bitbucket', prefix: 'fab' },
            { name: 'pagelines', prefix: 'fab' },
            { name: 'creative-commons', prefix: 'fab' },
            { name: 'github', prefix: 'fab' },
            { name: 'viacoin', prefix: 'fab' },
            { name: 'freebsd', prefix: 'fab' },
            { name: 'rebel', prefix: 'fab' },
            { name: 'fedex', prefix: 'fab' },
            { name: 'usb', prefix: 'fab' },
            { name: 'empire', prefix: 'fab' },
            { name: 'simplybuilt', prefix: 'fab' },
            { name: 'google-drive', prefix: 'fab' },
            { name: 'etsy', prefix: 'fab' },
            { name: 'bimobject', prefix: 'fab' },
            { name: 'app-store-ios', prefix: 'fab' },
            { name: 'gripfire', prefix: 'fab' },
            { name: '500px', prefix: 'fab' },
            { name: 'deskpro', prefix: 'fab' },
            { name: 'critical-role', prefix: 'fab' },
            { name: 'vimeo-v', prefix: 'fab' },
            { name: 'stripe-s', prefix: 'fab' },
            { name: 'keybase', prefix: 'fab' },
            { name: 'yandex-international', prefix: 'fab' },
            { name: 'joomla', prefix: 'fab' },
            { name: 'google-play', prefix: 'fab' },
            { name: 'uniregistry', prefix: 'fab' },
            { name: 'd-and-d', prefix: 'fab' },
            { name: 'kaggle', prefix: 'fab' },
            { name: 'hubspot', prefix: 'fab' },
            { name: 'confluence', prefix: 'fab' },
            { name: 'youtube-square', prefix: 'fab' },
            { name: 'servicestack', prefix: 'fab' },
            { name: 'reddit-square', prefix: 'fab' },
            { name: 'npm', prefix: 'fab' },
            { name: 'creative-commons-nc', prefix: 'fab' },
            { name: 'galactic-republic', prefix: 'fab' },
            { name: 'raspberry-pi', prefix: 'fab' },
            { name: 'tumblr-square', prefix: 'fab' },
            { name: 'mastodon', prefix: 'fab' },
            { name: 'angular', prefix: 'fab' },
            { name: 'untappd', prefix: 'fab' },
            { name: 'apper', prefix: 'fab' },
            { name: 'behance-square', prefix: 'fab' },
            { name: 'vk', prefix: 'fab' },
            { name: 'vimeo', prefix: 'fab' },
            { name: 'discourse', prefix: 'fab' },
            { name: 'hotjar', prefix: 'fab' },
            { name: 'slack-hash', prefix: 'fab' },
            { name: 'skyatlas', prefix: 'fab' },
            { name: 'hacker-news', prefix: 'fab' },
            { name: 'instagram', prefix: 'fab' },
            { name: 'y-combinator', prefix: 'fab' },
            { name: 'mendeley', prefix: 'fab' },
            { name: 'fort-awesome-alt', prefix: 'fab' },
            { name: 'r-project', prefix: 'fab' },
            { name: 'edge', prefix: 'fab' },
            { name: 'fly', prefix: 'fab' },
            { name: 'safari', prefix: 'fab' },
            { name: 'pied-piper-hat', prefix: 'fab' },
            { name: 'audible', prefix: 'fab' },
            { name: 'yoast', prefix: 'fab' },
            { name: 'fort-awesome', prefix: 'fab' },
            { name: 'python', prefix: 'fab' },
            { name: 'cc-apple-pay', prefix: 'fab' },
            { name: 'docker', prefix: 'fab' },
            { name: 'git', prefix: 'fab' },
            { name: 'blogger', prefix: 'fab' },
            { name: 'creative-commons-nc-eu', prefix: 'fab' },
            { name: 'schlix', prefix: 'fab' },
            { name: 'mailchimp', prefix: 'fab' },
            { name: 'neos', prefix: 'fab' },
            { name: 'creative-commons-pd', prefix: 'fab' },
            { name: 'pinterest-p', prefix: 'fab' },
            { name: 'digg', prefix: 'fab' },
            { name: 'acquisitions-incorporated', prefix: 'fab' },
            { name: 'bitcoin', prefix: 'fab' },
            { name: 'whmcs', prefix: 'fab' },
            { name: 'delicious', prefix: 'fab' },
            { name: 'hackerrank', prefix: 'fab' },
            { name: 'vimeo-square', prefix: 'fab' },
            { name: 'telegram', prefix: 'fab' },
            { name: 'cc-discover', prefix: 'fab' },
            { name: 'centercode', prefix: 'fab' },
            { name: 'erlang', prefix: 'fab' },
            { name: 'medrt', prefix: 'fab' },
            { name: 'meetup', prefix: 'fab' },
            { name: 'wizards-of-the-coast', prefix: 'fab' },
            { name: 'markdown', prefix: 'fab' },
            { name: 'goodreads', prefix: 'fab' },
            { name: 'dribbble-square', prefix: 'fab' },
            { name: 'creative-commons-sampling-plus', prefix: 'fab' },
            { name: 'invision', prefix: 'fab' },
            { name: 'mix', prefix: 'fab' },
            { name: 'gratipay', prefix: 'fab' },
            { name: 'magento', prefix: 'fab' },
            { name: 'bluetooth', prefix: 'fab' },
            { name: 'houzz', prefix: 'fab' },
            { name: 'expeditedssl', prefix: 'fab' },
            { name: 'the-red-yeti', prefix: 'fab' },
            { name: 'canadian-maple-leaf', prefix: 'fab' },
            { name: 'sith', prefix: 'fab' },
            { name: 'algolia', prefix: 'fab' },
            { name: 'ussunnah', prefix: 'fab' },
            { name: 'sellsy', prefix: 'fab' },
            { name: 'jira', prefix: 'fab' },
            { name: 'spotify', prefix: 'fab' },
            { name: 'intercom', prefix: 'fab' },
            { name: 'wolf-pack-battalion', prefix: 'fab' },
            { name: 'flipboard', prefix: 'fab' },
            { name: 'google-wallet', prefix: 'fab' },
            { name: 'shopware', prefix: 'fab' },
            { name: 'phoenix-framework', prefix: 'fab' },
            { name: 'kickstarter-k', prefix: 'fab' },
            { name: 'cc-jcb', prefix: 'fab' },
            { name: 'researchgate', prefix: 'fab' },
            { name: 'lyft', prefix: 'fab' },
            { name: 'wpressr', prefix: 'fab' },
            { name: 'lastfm-square', prefix: 'fab' },
            { name: 'periscope', prefix: 'fab' },
            { name: 'modx', prefix: 'fab' },
            { name: 'kickstarter', prefix: 'fab' },
            { name: 'slideshare', prefix: 'fab' },
            { name: 'snapchat', prefix: 'fab' },
            { name: 'font-awesome', prefix: 'fab' },
            { name: 'readme', prefix: 'fab' },
            { name: 'creative-commons-nc-jp', prefix: 'fab' },
            { name: 'themeisle', prefix: 'fab' },
            { name: 'html5', prefix: 'fab' },
            { name: 'replyd', prefix: 'fab' },
            { name: 'cuttlefish', prefix: 'fab' },
            { name: 'rockrms', prefix: 'fab' },
            { name: 'ravelry', prefix: 'fab' },
            { name: 'quora', prefix: 'fab' },
            { name: 'font-awesome-alt', prefix: 'fab' },
            { name: 'monero', prefix: 'fab' },
            { name: 'hire-a-helper', prefix: 'fab' },
            { name: 'github-alt', prefix: 'fab' },
            { name: 'creative-commons-share', prefix: 'fab' },
            { name: 'yarn', prefix: 'fab' },
            { name: 'cc-visa', prefix: 'fab' },
            { name: 'dochub', prefix: 'fab' },
            { name: 'github-square', prefix: 'fab' },
            { name: 'laravel', prefix: 'fab' },
            { name: 'digital-ocean', prefix: 'fab' },
            { name: 'xing-square', prefix: 'fab' },
            { name: 'earlybirds', prefix: 'fab' },
            { name: 'centos', prefix: 'fab' },
            { name: 'bluetooth-b', prefix: 'fab' },
            { name: 'teamspeak', prefix: 'fab' },
            { name: 'wpexplorer', prefix: 'fab' },
            { name: 'first-order-alt', prefix: 'fab' },
            { name: 'ubuntu', prefix: 'fab' },
            { name: 'opencart', prefix: 'fab' },
            { name: 'pied-piper', prefix: 'fab' },
            { name: 'paypal', prefix: 'fab' },
            { name: 'suse', prefix: 'fab' },
            { name: 'wpbeginner', prefix: 'fab' },
            { name: 'blogger-b', prefix: 'fab' },
            { name: 'rocketchat', prefix: 'fab' },
            { name: 'draft2digital', prefix: 'fab' },
            { name: 'playstation', prefix: 'fab' },
            { name: 'facebook', prefix: 'fab' },
            { name: 'amazon-pay', prefix: 'fab' },
            { name: 'nintendo-switch', prefix: 'fab' },
            { name: 'amazon', prefix: 'fab' },
            { name: 'quinscape', prefix: 'fab' },
            { name: 'free-code-camp', prefix: 'fab' },
            { name: 'vine', prefix: 'fab' },
            { name: 'pinterest', prefix: 'fab' },
            { name: 'creative-commons-sa', prefix: 'fab' },
            { name: 'creative-commons-pd-alt', prefix: 'fab' },
            { name: 'stack-overflow', prefix: 'fab' },
            { name: 'page4', prefix: 'fab' },
            { name: 'osi', prefix: 'fab' },
            { name: 'yandex', prefix: 'fab' },
            { name: 'whatsapp', prefix: 'fab' },
            { name: 'google', prefix: 'fab' },
            { name: 'line', prefix: 'fab' },
            { name: 'affiliatetheme', prefix: 'fab' },
            { name: 'atlassian', prefix: 'fab' },
            { name: 'aviato', prefix: 'fab' },
            { name: 'bity', prefix: 'fab' },
            { name: 'php', prefix: 'fab' },
            { name: 'stumbleupon-circle', prefix: 'fab' },
            { name: 'stripe', prefix: 'fab' },
            { name: 'jedi-order', prefix: 'fab' },
            { name: 'think-peaks', prefix: 'fab' },
            { name: 'buromobelexperte', prefix: 'fab' },
            { name: 'phabricator', prefix: 'fab' },
            { name: 'apple-pay', prefix: 'fab' },
            { name: 'artstation', prefix: 'fab' },
            { name: 'font-awesome-flag', prefix: 'fab' },
            { name: 'scribd', prefix: 'fab' },
            { name: 'stack-exchange', prefix: 'fab' },
            { name: 'fedora', prefix: 'fab' },
            { name: 'jenkins', prefix: 'fab' },
            { name: 'studiovinari', prefix: 'fab' },
            { name: 'glide', prefix: 'fab' },
            { name: 'adn', prefix: 'fab' },
            { name: 'css3-alt', prefix: 'fab' },
            { name: 'xing', prefix: 'fab' },
            { name: 'medapps', prefix: 'fab' },
            { name: 'redhat', prefix: 'fab' },
            { name: 'fantasy-flight-games', prefix: 'fab' },
            { name: 'facebook-f', prefix: 'fab' },
            { name: 'hips', prefix: 'fab' },
            { name: 'twitch', prefix: 'fab' },
            { name: 'cc-diners-club', prefix: 'fab' },
            { name: 'windows', prefix: 'fab' },
            { name: 'rev', prefix: 'fab' },
            { name: 'btc', prefix: 'fab' },
            { name: 'creative-commons-zero', prefix: 'fab' },
            { name: 'elementor', prefix: 'fab' },
            { name: 'youtube', prefix: 'fab' },
            { name: 'accessible-icon', prefix: 'fab' },
            { name: 'gg-circle', prefix: 'fab' },
            { name: 'app-store', prefix: 'fab' },
            { name: 'codiepie', prefix: 'fab' },
            { name: 'stumbleupon', prefix: 'fab' },
            { name: 'nutritionix', prefix: 'fab' },
            { name: 'linkedin-in', prefix: 'fab' },
            { name: 'jsfiddle', prefix: 'fab' },
            { name: 'yahoo', prefix: 'fab' },
            { name: 'grav', prefix: 'fab' },
            { name: 'sticker-mule', prefix: 'fab' },
            { name: 'goodreads-g', prefix: 'fab' },
            { name: 'uber', prefix: 'fab' },
            { name: 'sketch', prefix: 'fab' },
            { name: 'creative-commons-remix', prefix: 'fab' },
            { name: 'itunes-note', prefix: 'fab' },
            { name: 'gulp', prefix: 'fab' },
            { name: 'keycdn', prefix: 'fab' },
            { name: 'diaspora', prefix: 'fab' },
            { name: 'reddit', prefix: 'fab' },
            { name: 'alipay', prefix: 'fab' },
            { name: 'cloudscale', prefix: 'fab' },
            { name: 'buysellads', prefix: 'fab' },
            { name: 'steam-symbol', prefix: 'fab' },
            { name: 'asymmetrik', prefix: 'fab' },
            { name: 'angellist', prefix: 'fab' },
            { name: 'wpforms', prefix: 'fab' },
            { name: 'linode', prefix: 'fab' },
            { name: 'js-square', prefix: 'fab' },
            { name: 'avianex', prefix: 'fab' },
            { name: 'codepen', prefix: 'fab' },
            { name: 'dribbble', prefix: 'fab' },
            { name: 'cc-amazon-pay', prefix: 'fab' },
            { name: 'xbox', prefix: 'fab' },
            { name: 'trello', prefix: 'fab' },
            { name: 'adversal', prefix: 'fab' },
            { name: 'dhl', prefix: 'fab' },
            { name: 'hornbill', prefix: 'fab' },
            { name: 'themeco', prefix: 'fab' },
            { name: 'google-plus', prefix: 'fab' },
            { name: 'contao', prefix: 'fab' },
            { name: 'linkedin', prefix: 'fab' },
            { name: 'mandalorian', prefix: 'fab' },
            { name: 'old-republic', prefix: 'fab' },
            { name: 'qq', prefix: 'fab' },
            { name: 'vaadin', prefix: 'fab' },
            { name: 'uikit', prefix: 'fab' },
            { name: 'react', prefix: 'fab' },
            { name: 'creative-commons-by', prefix: 'fab' },
            { name: 'optin-monster', prefix: 'fab' },
            { name: 'twitter', prefix: 'fab' },
            { name: 'less', prefix: 'fab' },
            { name: 'patreon', prefix: 'fab' },
            { name: 'discord', prefix: 'fab' },
            { name: 'hacker-news-square', prefix: 'fab' },
            { name: 'opera', prefix: 'fab' },
            { name: 'gitter', prefix: 'fab' },
            { name: 'css3', prefix: 'fab' },
            { name: 'renren', prefix: 'fab' },
            { name: 'ns8', prefix: 'fab' },
            { name: 'sistrix', prefix: 'fab' },
            { name: 'vuejs', prefix: 'fab' },
            { name: 'facebook-square', prefix: 'fab' },
            { name: 'mixcloud', prefix: 'fab' },
            { name: 'firstdraft', prefix: 'fab' },
            { name: 'slack', prefix: 'fab' },
            { name: 'zhihu', prefix: 'fab' },
            { name: 'soundcloud', prefix: 'fab' },
            { name: 'product-hunt', prefix: 'fab' },
            { name: 'wikipedia-w', prefix: 'fab' },
            { name: 'pied-piper-pp', prefix: 'fab' },
            { name: 'creative-commons-sampling', prefix: 'fab' },
            { name: 'hooli', prefix: 'fab' },
            { name: 'first-order', prefix: 'fab' },
            { name: 'glide-g', prefix: 'fab' },
            { name: 'ember', prefix: 'fab' },
            { name: 'gitkraken', prefix: 'fab' },
            { name: 'steam-square', prefix: 'fab' },
            { name: 'skype', prefix: 'fab' },
            { name: 'gofore', prefix: 'fab' },
            { name: 'ebay', prefix: 'fab' },
            { name: 'cpanel', prefix: 'fab' },
            { name: 'wordpress', prefix: 'fab' },
            { name: 'korvue', prefix: 'fab' },
            { name: 'cloudversify', prefix: 'fab' },
            { name: 'grunt', prefix: 'fab' },
            { name: 'viadeo', prefix: 'fab' },
            { name: 'wordpress-simple', prefix: 'fab' },
            { name: 'blackberry', prefix: 'fab' },
            { name: 'facebook-messenger', prefix: 'fab' },
            { name: 'pushed', prefix: 'fab' },
            { name: 'amilia', prefix: 'fab' },
            { name: 'pied-piper-alt', prefix: 'fab' },
            { name: 'cloudsmith', prefix: 'fab' },
            { name: 'chrome', prefix: 'fab' },
            { name: 'forumbee', prefix: 'fab' },
            { name: 'snapchat-square', prefix: 'fab' },
            { name: 'node-js', prefix: 'fab' },
            { name: 'maxcdn', prefix: 'fab' },
            { name: 'apple', prefix: 'fab' },
            { name: 'napster', prefix: 'fab' },
            { name: 'nimblr', prefix: 'fab' },
            { name: 'vnv', prefix: 'fab' },
            { name: 'telegram-plane', prefix: 'fab' },
            { name: 'odnoklassniki-square', prefix: 'fab' },
            { name: 'google-plus-g', prefix: 'fab' },
            { name: 'palfed', prefix: 'fab' },
            { name: 'weebly', prefix: 'fab' },
            { name: 'autoprefixer', prefix: 'fab' },
            { name: 'searchengin', prefix: 'fab' },
            { name: 'pinterest-square', prefix: 'fab' },
            { name: 'megaport', prefix: 'fab' },
            { name: 'typo3', prefix: 'fab' },
            { name: 'deploydog', prefix: 'fab' },
            { name: 'node', prefix: 'fab' },
            { name: 'cc-mastercard', prefix: 'fab' },
            { name: 'twitter-square', prefix: 'fab' },
            { name: 'dashcube', prefix: 'fab' },
            { name: 'get-pocket', prefix: 'fab' },
            { name: 'ups', prefix: 'fab' },
            { name: 'medium-m', prefix: 'fab' },
            { name: 'behance', prefix: 'fab' },
            { name: 'resolving', prefix: 'fab' },
            { name: 'internet-explorer', prefix: 'fab' },
            { name: 'staylinked', prefix: 'fab' },
            { name: 'firefox', prefix: 'fab' },
            { name: 'flickr', prefix: 'fab' },
            { name: 'itunes', prefix: 'fab' }
        ];
    }
})(angular);
