(function (angular) {
    angular
        .module('one.admin')
        .controller('EditTagController', EditTagController);

    EditTagController.$inject = ['$scope', '$state', 'TagService', 'tag', 'tags', 'colors'];

    function EditTagController($scope, $state, TagService, tag, tags, colors) {
        $scope.tag = tag;
        $scope.tags = tags;
        $scope.colors = colors;

        $scope.editTag = editTag;

        function editTag() {
            TagService.editTag($scope.tag).then(function () {
                $state.transitionTo('tags.index');
            });
        }
    }
})(angular);
