(function (angular) {
    angular
        .module('one.admin')
        .directive('pageNavBuilder', pageNavBuilderDirective);

    pageNavBuilderDirective.$inject = ['config'];

    function pageNavBuilderDirective(config) {
        controller.$inject = ['$scope', 'session', 'PageService'];

        function controller($scope, session, PageService) {
            var site = session.get('data.current_site');

            $scope.state = {
                selected: $scope.selected
            };

            angular.forEach($scope.links, function(link) {
                if (link.page) {
                    link.toggleCustomUrl = false;
                    return;
                }

                link.toggleCustomUrl = true;
                return;
            });

            $scope.depth = $scope.depth || 0;

            $scope.addLink = addLink;
            $scope.deleteLink = deleteLink;
            $scope.isComplete = isComplete;
            $scope.toggleEditor = toggleEditor;
            $scope.dropDisabled = dropDisabled;
            $scope.getPages = getPages;
            $scope.formatPageTitle = PageService.formatPageTitle;
            $scope.focusTitle = focusTitle;

            function addLink(link) {
                var newLink = {
                    editing: true,
                    visibility: 'all',
                    emphasize: false,
                    toggleCustomUrl: false,
                    new_window: false,
                    children: []
                };

                if (link) {
                    link.children.push(newLink);
                } else {
                    $scope.links.push(newLink);
                }

                closeEditors($scope.links);
            }

            function setActiveUrl(link) {
                if (link.toggleCustomUrl) {
                    link.page = null;
                    return;
                }

                link.custom_url = null;
                return;
            }

            function deleteLink(links, index) {
                links.splice(index, 1);
            }

            function isComplete(link) {
                return $scope.pageNav.header && (link.page && !link.toggleCustomUrl || link.custom_url && link.toggleCustomUrl || link.custom_title)
                || (link.page && !link.toggleCustomUrl)
                || (link.custom_url && link.toggleCustomUrl);
            }

            function closeEditors(links) {
                angular.forEach(links, function (link) {
                    if (isComplete(link) && link.editing) {
                        toggleEditor(link);
                    }

                    if (link.children) {
                        closeEditors(link.children);
                    }
                });
            }

            function toggleEditor(link) {
                setActiveUrl(link);

                link.editing = !link.editing;
            }

            function dropDisabled(depth) {
                var link = $scope.selected;

                if (link && depth) {
                    return link.children.length > 0;
                }

                return false;
            }

            function getPages(params) {
                params = params || {};
                params.filters = params.filters || {};
                params.filters.sites = [site.id];

                return PageService.pages(params);
            }

            function focusTitle(link) {
                link.focus = link.focus || 0;
                link.focus++;
            }
        }

        return {
            restrict: 'EA',
            scope: {
                pageNav: '=',
                links: '=',
                depth: '@',
                maxDepth: '=',
                selected: '=',
                parent: '='
            },
            controller: controller,
            templateUrl: config.path.template('page_navs.builder')
        };
    }
}(angular));
