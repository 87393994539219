(function (angular) {
    angular
        .module('one.admin')
        .directive('fileSelector', fileSelectorDirective);

    fileSelectorDirective.$inject = ['config'];

    function fileSelectorDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                model: '=',
                type: '@',
                site: '=',
                change: '&',
                nullable: '='
            },
            controller: ['$scope', 'FileService', function ($scope, FileService) {
                $scope.getFiles = getFiles;
                $scope.formatFilename = FileService.formatFilename;

                $scope.$watch('model', function () {
                    $scope.change();

                    if ($scope.model && !$scope.model.filename) {
                        $scope.model.client_filename = $scope.model.url.split('/').pop()
                    }
                });

                function getFiles(params) {
                    var fn;

                    if ($scope.type == 'image') {
                        fn = FileService.images;
                    } else {
                        fn = FileService.files;
                    }

                    params = params || {};
                    params.sort = { created_at: 'desc' };

                    return fn(params);
                }
            }],
            templateUrl: config.path.template('files.selector')
        };
    }
}(angular));
