(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$document'];

    function run($rootScope, $document) {
        $rootScope.scrollTop = function () {
            $document.scrollTopAnimated(0, 500);
        };

        $rootScope.scrollTo = function (id, speed) {
            speed = speed || 500;

            var element = angular.element(document.getElementById(id));

            $document.scrollTopAnimated(element.offset().top - 48 - 30, speed);
        };
    }
})(angular);
