(function (angular) {
    angular
        .module('one.admin')
        .controller('IpLocationsController', IpLocationsController);

    IpLocationsController.$inject = ['$scope', 'AuthService', 'IpLocationService', 'dataframe', 'modal', 'notification', 'session'];

    function IpLocationsController($scope, AuthService, IpLocationService, dataframe, modal, notification, session) {
        $scope.ipLocations = dataframe.init({
            externalData: IpLocationService.ipLocations,
            cols: [
            {
                    title: 'Organisation',
                    name: 'organisation',
                    display: function (row) {
                        return '{{ row.organisation.title }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Title',
                    name: 'title',
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'IP address',
                    name: 'ip_address',
                    weight: 1,
                    sortable: true
                },
                {
                    title: '# Users',
                    name: 'users_count',
                    weight: 1,
                    sortable: false
                }
            ],
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'ipLocations.editIpLocation({ipLocationId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('ip_locations.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        IpLocationService.openDeleteIpLocationModal(row.id, function () {
                            $scope.ipLocations.getData();
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('ip_locations.delete');
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('ip_locations.view')) {
                    return 'ipLocations.ipLocation({ipLocationId: ' + row.id + '})';
                }
                return false;
            },
            state: session.get('data.dataframes.ip_locations', { sort: { title: 'asc' } })
        });

        session.sync('data.dataframes.ip_locations', $scope.ipLocations.state);
    }
})(angular);
