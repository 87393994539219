(function (angular) {
    angular
        .module('one.admin')
        .controller('EditFormModuleController', EditFormModuleController);

    EditFormModuleController.$inject = ['$scope', '$state', 'notification', 'ModuleService', 'modal', 'module', 'translateFilter'];

    function EditFormModuleController($scope, $state, notification, ModuleService, modal, module, translateFilter) {
        $scope.module = module;

        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;

        function editModule(data) {
            ModuleService.editModule(data).then(function (data) {
                var state = ModuleService.getReturnState($scope.module);

                $state.transitionTo(state.name, state.params);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);
