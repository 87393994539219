(function (angular) {
    angular
        .module('one.admin')
        .controller('InteractionFormController', InteractionFormController);

    InteractionFormController.$inject = ['$scope', 'AccountService', 'TransactionalMailService', 'moment', 'session'];

    function InteractionFormController($scope, AccountService, TransactionalMailService, moment, session) {
        $scope.showAccountField = !$scope.interaction.account;
        $scope.user = session.get('user');

        $scope.uploadedAttachment = null;

        $scope.originalAssignedUser = $scope.interaction.id ? angular.copy($scope.interaction.assigned_user) : null;

        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        $scope.addAttachment = function (image) {
            $scope.interaction.attachments.push(image);
        };

        $scope.deleteAttachment = function (index) {
            $scope.interaction.attachments.splice(index, 1);
        };

        $scope.formatSubjectTitle = function (subject) {
            if (subject.template) {
                return subject.title + ' <i class="md-icon">short_text</i>';
            }

            return subject.title;
        };

        $scope.offsetContactDate = function (days) {
            $scope.interaction.contact_date = moment().add(days, 'days').format('YYYY-MM-DD');
        };

        // Set the description to the template as long as the input was not changed.
        var activeTemplate = '';
        $scope.updateSubject = function () {
            var template = '';

            if ($scope.interaction.subject && $scope.interaction.subject.template) {
                template = $scope.interaction.subject.template;
            }

            if (!$scope.interaction.description || $scope.interaction.description === activeTemplate || $scope.interaction.description.trim() === '') {
                activeTemplate = template;

                $scope.interaction.description = activeTemplate;
            }
        };

        $scope.formatTransactionalMailTitle = TransactionalMailService.formatTransactionalMailTitle;
    }
})(angular);
