(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', 'modal', 'session'];

    function run($rootScope, modal, session) {
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var user = session.get('user');

            if (user && user.unread_updates) {
                var updates = [];

                angular.forEach(user.unread_updates, function (update) {
                    if (!update.section || toState.name.indexOf(update.section) === 0) {
                        updates.push(update);
                    }
                });

                if (updates.length > 0) {
                    modal.open('updates', null, { updates: updates });
                }
            }
        });
    }
})(angular);
