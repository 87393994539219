(function (angular) {
    angular
        .module('one.admin')
        .service('AnnualPaymentService', AnnualPaymentService);

    AnnualPaymentService.$inject = ['http'];

    function AnnualPaymentService(http) {
        function saveAnnualPayment(account, data) {
            return http.post('annual_payments/' + account + '/save', data);
        }

        return {
            saveAnnualPayment: saveAnnualPayment
        };
    }
})(angular);
