(function (angular) {
    angular
        .module('one.admin')
        .controller('ProductCategoriesController', ProductCategoriesController);

    ProductCategoriesController.$inject = ['$scope', 'AuthService', 'ProductService', 'TagService', 'dataframe', 'modal', 'session'];

    function ProductCategoriesController($scope, AuthService, ProductService, TagService, dataframe, modal, session) {
        $scope.categories = dataframe.init({
            name: 'categories',
            externalData: ProductService.productCategories,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">style</i> {{ row.title }}</h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Products',
                    name: 'product_count',
                    display: function (row) {
                        return '{{ row.product_count | number }}';
                    },
                    weight: 1
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('product_categories.view')) {
                    return 'sales.products.productCategories.productCategory({ productCategoryId: ' + row.id + ' })';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'sales.products.productCategories.editProductCategory({ productCategoryId: ' + row.id + ' })';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('product_categories.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.categories.loading = true;

                        ProductService.duplicateProductCategory(row).then(function () {
                            $scope.categories.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('product_categories.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        ProductService.openDeleteProductCategoryModal(row.id, function () {
                            $scope.categories.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('product_categories.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.productCategories', {
                sort: { title: 'asc' }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.categories, 'product_category', session.get('data.tags'));
        }

        session.sync('data.dataframes.productCategories', $scope.categories.state);

        $scope.openAddProductCategoryModal = openAddProductCategoryModal;

        function openAddProductCategoryModal(size) {
            modal.open('addProductCategory', size).result.then(function (category) {
                $state.transitionTo('sales.products.productCategories.productCategory', { productCategoryId: category.id });
            });
        }
    }
})(angular);
