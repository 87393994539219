(function (angular) {
    angular
        .module('one.admin')
        .controller('AddUserController', AddUserController);

    AddUserController.$inject = ['$scope', '$state', 'session', 'AuthService', 'authRoles', 'ipLocations'];

    function AddUserController($scope, $state, session, AuthService, authRoles, ipLocations) {
        $scope.authRoles = authRoles;
        $scope.ipLocations = ipLocations;
        $scope.project = session.get('project');
        $scope.organisation = $scope.project.organisation;

        $scope.user = {
            email: '',
            auth_roles: [],
            ip_locations: []
        };

        $scope.addUser = addUser;

        function addUser(data) {
            AuthService.addUser(data).then(function (data) {
                $state.transitionTo('users.index');
            });
        }
    }
})(angular);
