(function (angular) {
    angular
        .module('one.admin')
        .service('InteractionService', InteractionService);

    InteractionService.$inject = ['http', 'modal', '$filter', 'AuthService'];

    function InteractionService(http, modal, $filter, AuthService) {
        function calendarUrl() {
            return http.get('calendar/url');
        }

        function subject(id) {
            return http.get('interactions/subjects/' + id);
        }

        function subjects(params) {
            return http.get('interactions/subjects', params);
        }

        function notificationMails(params) {
            return http.get('interactions/notification_mails', params);
        }

        function interaction(id) {
            return http.get('interactions/' + id);
        }

        function interactions(params) {
            return http.get('interactions', params);
        }

        function updateBadgeCount() {
            // TODO: use WebSocket?
            AuthService.checkUser();
        }

        function addInteraction(data) {
            return http.post('interactions/add', data);
        }

        function addInteractionSubject(data) {
            return http.post('interactions/subjects/add', data);
        }

        function deleteInteraction(id) {
            return http.post('interactions/' + id + '/delete');
        }

        function deleteInteractionSubject(id) {
            return http.post('interactions/subjects/' + id + '/delete');
        }

        function editInteraction(data) {
            return http.post('interactions/' + data.id + '/edit', data);
        }

        function editInteractionSubject(data) {
            return http.post('interactions/subjects/' + data.id + '/edit', data);
        }

        function setFinished(id, status) {
            return http.post('interactions/' + id + '/set_finished', { status: status }, null, { ignoreLoadingBar: true });
        }

        function openDeleteInteractionModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Are you sure?',
                message: 'Delete this interaction?'
            }).result.then(function (data) {
                return deleteInteraction(id).then(callback);
            });
        }

        function openDeleteInteractionSubjectModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Are you sure?',
                message: 'Delete this interaction subject?'
            }).result.then(function (data) {
                return deleteInteractionSubject(id).then(callback);
            });
        }

        function displayContactDate(interaction) {
            var date = moment(interaction.contact_date);
            var now = moment();

            var isPast = date.diff(now, 'days') < 0;
            var isToday = date.isSame(now, 'day');
            var isFuture = date.diff(now, 'days') > 0;

            var className = !interaction.finished ? (isPast ? 'text-danger' : isToday ? 'text-success' : '') : (isFuture ? 'archived' : '');

            return '<span class="' + className + '">' + $filter('formatDate')(interaction.contact_date) + '</span>';
        }

        return {
            calendarUrl: calendarUrl,
            subject: subject,
            subjects: subjects,
            notificationMails: notificationMails,
            interaction: interaction,
            interactions: interactions,
            addInteraction: addInteraction,
            addInteractionSubject: addInteractionSubject,
            editInteraction: editInteraction,
            editInteractionSubject: editInteractionSubject,
            deleteInteraction: deleteInteraction,
            openDeleteInteractionModal: openDeleteInteractionModal,
            openDeleteInteractionSubjectModal: openDeleteInteractionSubjectModal,
            setFinished: setFinished,
            updateBadgeCount: updateBadgeCount,
            displayContactDate: displayContactDate
        };
    }
})(angular);
