(function (angular) {
    angular
        .module('one.admin')
        .controller('SiteScriptsController', SiteScriptsController);

    SiteScriptsController.$inject = ['$scope', 'session', 'modal', 'SiteService', 'FormHelper'];

    function SiteScriptsController($scope, session, modal, SiteService, FormHelper) {
        $scope.site = angular.copy(session.get('data.current_site'));

        $scope.form = FormHelper.getFormState({
            data: {
                scripts_body: $scope.site.scripts_body,
                scripts_head: $scope.site.scripts_head
            },
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, data);

                return SiteService.editSite(data);
            },
            success: function (data) {
                $scope.site = data.site;
                session.set('data.current_site', angular.copy(data.site));
            }
        });

        $scope.openAuditorModal = openAuditorModal;

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.site.id, modelType: 'site' });
        }
    }
})(angular);
