(function (angular) {
    angular
        .module('one.admin')
        .controller('SiteStylesController', SiteStylesController);

    SiteStylesController.$inject = ['$scope', '$timeout', 'notification', 'session', 'modal', 'translateFilter', 'SiteService', 'FormHelper'];

    function SiteStylesController($scope, $timeout, notification, session, modal, translateFilter, SiteService, FormHelper) {
        var pingTimer;

        $scope.site = angular.copy(session.get('data.current_site'));
        $scope.site.logo = $scope.site.logo || null;
        $scope.site.inverse_logo = $scope.site.inverse_logo || null;

        $scope.variables = [];

        angular.forEach($scope.site.config_keys.brands, function (brand) {
            $scope.variables.push('brand.' + brand);
        });

        if ($scope.site.compiling) {
            pingSite();
        }

        $scope.$on('$destroy', function () {
            $timeout.cancel(pingTimer);
        });

        $scope.brandsForm = FormHelper.getFormState({
            data: $scope.site.config.brands,
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, {
                    config: {
                        brands: data
                    }
                });

                return compileStyles(data);
            },
            success: updateSessionOnSave,
            error: function () {
                $scope.site.compiling = false;

                session.set('data.current_site.compiling', false);
            }
        });

        $scope.logoForm = FormHelper.getFormState({
            data: {
                logo: $scope.site.logo,
                inverse_logo: $scope.site.inverse_logo
            },
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, data);

                return SiteService.editSite(data);
            },
            success: updateSessionOnSave
        });

        $scope.headerForm = FormHelper.getFormState({
            data: $scope.site.config.header_bar,
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, {
                    config: {
                        header_bar: data
                    }
                });

                return SiteService.updateConfig(data);
            },
            success: updateSessionOnSave
        });

        $scope.footerForm = FormHelper.getFormState({
            data: $scope.site.config.footer_bar,
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, {
                    config: {
                        footer_bar: data
                    }
                });

                return SiteService.updateConfig(data);
            },
            success: updateSessionOnSave
        });

        $scope.stylesForm = FormHelper.getFormState({
            data: $scope.site.queued_styles,
            submit: function (data) {
                data = angular.merge({ id: $scope.site.id }, {
                    queued_styles: data
                });

                return compileStyles(data);
            },
            success: updateSessionOnSave,
            error: function () {
                $scope.site.compiling = false;

                session.set('data.current_site.compiling', false);
            }
        });

        function compileStyles(data) {
            $scope.site.compiling = true;
            $scope.site.compile_error = null;

            session.set('data.current_site.compiling', true);
            session.set('data.current_site.compile_error', null);

            pingSite();

            return SiteService.editStyles(data);
        }

        function pingSite() {
            pingTimer = $timeout(function () {
                getSite().then(function (site) {
                    $scope.site = site;

                    if (site.compiling) {
                        pingSite();
                    } else if (!site.compile_error && !$scope.stylesForm.errors) {
                        notification.success(translateFilter('general.site_updated'));
                    }

                    session.set('data.current_site.compiling', site.compiling);
                });
            }, 3000);
        }

        function getSite() {
            return SiteService.site($scope.site.id, null, { ignoreLoadingBar: true });
        }

        function updateSessionOnSave(data) {
            $scope.site = data.site;
            session.set('data.current_site', angular.copy(data.site));
        }

        $scope.openAuditorModal = openAuditorModal;

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.site.id, modelType: 'site' });
        }
    }
})(angular);
