(function (angular) {
    angular
        .module('one.admin')
        .controller('BankStatementsController', BankStatementsController);

    BankStatementsController.$inject = ['$scope', '$interval', '$state', '$q', 'BankStatementService', 'dataframe', '$filter', 'session', 'unmatchedPaymentCount'];

    function BankStatementsController($scope, $interval, $state, $q, BankStatementService, dataframe, $filter, session, unmatchedPaymentCount) {
        var sessionName = 'data.dataframes.bankStatementEntries';

        $scope.config = {
            start_date: session.get(sessionName + '.filters.start_date'),
            end_date: session.get(sessionName + '.filters.end_date'),
        };

        $scope.state = {};

        $scope.unmatchedPaymentCount = unmatchedPaymentCount.count;

        var isProcessing = false;
        var interval = $interval(updateProcessingCount, 10000);

        updateProcessingCount();

        $scope.$on('$destroy', function () {
            $interval.cancel(interval);
        });

        getReversalActionStats();
        getReversalEntryStats();

        $scope.bankStatementEntries = dataframe.init({
            externalData: function (params) {
                params = angular.merge(params, { filters: $scope.config });

                return BankStatementService.bankStatementEntries(params);
            },
            cols: [
                {
                    title: 'Amount',
                    name: 'amount',
                    display: function (row) {
                        return '<span class="md-icon text-success" ng-if="row.amount >= 0">add</span><span class="md-icon text-danger" ng-if="row.amount < 0">remove</span> <span class="h4">{{ row.amount | absolute | money: \'&euro; \'}}</span class="h4">';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Match',
                    name: 'match_type',
                    display: function (row) {
                        var output = '{{ \'bank_statement_match_types.\' + row.match_type | translate }}';

                        if (row.transaction) {
                            output += ' <span class="md-icon text-muted">keyboard_arrow_right</span> <a ui-sref="finance.transactions.transaction({transactionId: row.transaction.id})" transaction-label transaction="row.transaction"></a>';
                        }

                        if (row.match_type == BankStatementService.matchTypes.IGNORED_PAYMENT) {
                            row.undoIgnore = function () {
                                BankStatementService.undoIgnore(row.id).then(function () {
                                    $state.transitionTo('finance.bankStatements.processUnmatchedPayments', { bankStatementEntryId: row.id });
                                });
                            };

                            output += ' <span class="md-icon text-muted">keyboard_arrow_right</span> <a ng-click="row.undoIgnore()">{{ \'general.process\' | translate }}</a>';
                        }

                        if (row.match_type == BankStatementService.matchTypes.UNMATCHED_PAYMENT) {
                            output += ' <span class="md-icon text-muted">keyboard_arrow_right</span> <a ui-sref="finance.bankStatements.processUnmatchedPayments({bankStatementEntryId: row.id})">{{ \'general.process\' | translate }}</a>';
                        }

                        if (row.match_type == BankStatementService.matchTypes.SDD_BATCH_PAYMENT) {
                            output += ' <span class="md-icon text-muted">keyboard_arrow_right</span> <a ui-sref="finance.sddBatches.sddBatch({sddBatchId: row.sdd_batch_payment.sdd_batch.id})">{{ row.sdd_batch_payment.sdd_batch.title }}</a> <span class="text-muted">({{ (\'sequence_types.\' + row.sdd_batch_payment.sequence_type) | translate }})</span>';
                        }

                        return output;
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'From',
                    name: 'iban',
                    display: function (row) {
                        var output = '';

                        if (row.iban) {
                            output += '<div><strong>{{ row.iban }}</strong></div>';
                        }

                        if (row.name) {
                            output += '<div>{{ row.name }}</div>';
                        }

                        if (row.description) {
                            output += '<div class="text-muted">{{ row.description }}</div>';
                        }

                        return output;
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Booking date',
                    name: 'booking_date',
                    display: function (row) {
                        return '{{ row.booking_date | formatDate }}';
                    },
                    weight: 1
                }
            ],
            filterables: [
                'iban', 'account_holder', 'reversal_code', 'booking_date'
            ],
            state: session.get(sessionName, {
                sort: { booking_date: 'desc' },
                perPage: 5
            }),
            batchLimits: {
                export: 50000
            }
        });

        session.sync(sessionName, $scope.bankStatementEntries.state);

        $scope.getData = getData;

        function updateProcessingCount() {
            BankStatementService.processingCount().then(function (data) {
                if (isProcessing && data.count == 0) {
                    $state.reload($state.current);
                }

                isProcessing = data.count > 0;

                $scope.processingCount = data.count;
            });
        }

        function getData() {
            $scope.state.loading = true;

            $q.all([
                $scope.bankStatementEntries.getData(),
                getReversalActionStats(),
                getReversalEntryStats()
            ]).then(function () {
                $scope.state.loading = false;
            });
        }

        function getReversalActionStats() {
            BankStatementService.reversalActionStats({ filters: $scope.config }).then(function (reversalActionStats) {
                $scope.reversalActionStats = reversalActionStats;

                $scope.totalReversalActionCount = 0;

                angular.forEach(reversalActionStats, function (row) {
                    $scope.totalReversalActionCount += row.count;
                });
            });
        }

        function getReversalEntryStats() {
            BankStatementService.reversalEntryStats({ filters: $scope.config }).then(function (reversalEntryStats) {
                $scope.reversalEntryStats = reversalEntryStats;

                $scope.totalReversalEntryCount = 0;

                angular.forEach(reversalEntryStats, function (row) {
                    $scope.totalReversalEntryCount += row.count;
                });
            });
        }
    }
})(angular);
