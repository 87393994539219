(function (angular) {
    angular
        .module('one.admin')
        .controller('InteractionsController', InteractionsController);

    InteractionsController.$inject = ['$scope', 'AuthService', 'InteractionService', 'dataframe', 'modal', 'moment', 'session'];

    function InteractionsController($scope, AuthService, InteractionService, dataframe, modal, moment, session) {
        $scope.myTodos = dataframe.init({
            name: 'myTodos',
            externalData: InteractionService.interactions,
            cols: [
                {
                    title: 'Finished',
                    name: 'finished',
                    display: function (row) {
                        row.setFinished = function (status) {
                            if (row.loading) {
                                return;
                            }

                            row.loading = true;
                            row.finished = status;

                            InteractionService.setFinished(row.id, status).then(function (data) {
                                InteractionService.updateBadgeCount();
                                row.loading = false;
                                $scope.myTodos.getData();
                                $scope.myTodos.loading = false;
                            });
                        };

                        var result = '';

                        result += '<span ng-if="!row.finished" class="interaction-toggle" ng-click="row.setFinished(true); $event.stopPropagation()"><i class="md-icon">check_box_outline_blank</i></span>';
                        result += '<span ng-if="row.finished" class="interaction-toggle finished" ng-click="row.setFinished(false); $event.stopPropagation()"><i class="md-icon">check_box</i></span>';

                        return result;
                    },
                    weight: 1
                },
                {
                    title: 'Contact',
                    name: 'account.title',
                    display: function (row) {
                        if (row.account) {
                            return '<i class="md-icon text-muted" ng-if="row.incoming">call_received</i> <a ui-sref="crm.accounts.account.index({accountId: row.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.account.active}"><i account-icon account="row.account"></i> {{ row.account.title }}</a> <i tag-indicator tags="row.account.tags"></i>';
                        }
                        return '';
                    },
                    weight: 3
                },
                {
                    name: 'description',
                    title: 'Summary',
                    display: function (row) {
                        return '<span class="text-limiter">{{ row.description }}</span>';
                    },
                    weight: 3
                },
                {
                    name: 'subject',
                    title: 'Subject',
                    display: function (row) {
                        return '<div class="text-limiter w80">{{ row.subject.title }}</div>';
                    },
                    weight: 2
                },
                {
                    name: 'assigned_user.full_name',
                    title: 'User',
                    display: function (row) {
                        if (row.assigned_user) {
                            return '<span class="one-user"><i class="md-icon">account_circle</i> {{ row.assigned_user.first_name }}</span>';
                        }

                        return '<span class="text-muted">{{ \'general.unassigned\' | translate }}</span>';
                    },
                    weight: 2
                },
                {
                    name: 'contact_date',
                    title: 'Date',
                    display: InteractionService.displayContactDate,
                    sortable: true,
                    weight: 2
                }
            ],
            rowClick: function (row) {
                openInteractionModal(row, 'lg');
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.interactions.editInteraction({interactionId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        InteractionService.openDeleteInteractionModal(row.id, function () {
                            $scope.myTodos.getData();
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.delete');
                    }
                }
            ],
            filterables: [
                'contact_date', 'created_at'
            ],
            state: session.get('data.dataframes.myTodos', {
                sort: { id: 'asc' },
                filters: {
                    finished: [false],
                    assigned_users: [session.get('user.id'), 'null']
                }
            })
        });

        $scope.interactions = dataframe.init({
            name: 'interactions',
            externalData: InteractionService.interactions,
            cols: [
                {
                    title: 'Finished',
                    name: 'finished',
                    display: function (row) {
                        row.setFinished = function (status) {
                            if (row.loading) {
                                return;
                            }

                            row.loading = true;
                            row.finished = status;

                            InteractionService.setFinished(row.id, status).then(function (data) {
                                InteractionService.updateBadgeCount();
                                row.loading = false;
                                $scope.interactions.getData();
                                $scope.interactions.loading = false;
                            });
                        };

                        var result = '';

                        result += '<span ng-if="!row.finished" class="interaction-toggle" ng-click="row.setFinished(true); $event.stopPropagation()"><i class="md-icon">check_box_outline_blank</i></span>';
                        result += '<span ng-if="row.finished" class="interaction-toggle finished" ng-click="row.setFinished(false); $event.stopPropagation()"><i class="md-icon">check_box</i></span>';

                        return result;
                    },
                    weight: 1
                },
                {
                    title: 'Contact',
                    name: 'account.title',
                    display: function (row) {
                        if (row.account) {
                            return '<i class="md-icon text-muted" ng-if="row.incoming">call_received</i> <a ui-sref="crm.accounts.account.index({accountId: row.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.account.active}"><i account-icon account="row.account"></i> {{ row.account.title }}</a> <i tag-indicator tags="row.account.tags"></i>';
                        }
                        return '';
                    },
                    weight: 3
                },
                {
                    name: 'description',
                    title: 'Summary',
                    display: function (row) {
                        return '<span class="text-limiter">{{ row.description }}</span>';
                    },
                    weight: 3
                },
                {
                    name: 'subject',
                    title: 'Subject',
                    display: function (row) {
                        return '<div class="text-limiter w80">{{ row.subject.title }}</div>';
                    },
                    weight: 2
                },
                {
                    name: 'assigned_user.full_name',
                    title: 'User',
                    display: function (row) {
                        if (row.assigned_user) {
                            return '<span class="one-user"><i class="md-icon">account_circle</i> {{ row.assigned_user.first_name }}</span>';
                        }

                        return '<span class="text-muted">{{ \'general.unassigned\' | translate }}</span>';
                    },
                    weight: 2
                },
                {
                    name: 'contact_date',
                    title: 'Date',
                    display: InteractionService.displayContactDate,
                    sortable: true,
                    weight: 2
                }
            ],
            rowClick: function (row) {
                openInteractionModal(row, 'lg');
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.interactions.editInteraction({interactionId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        InteractionService.openDeleteInteractionModal(row.id, function () {
                            $scope.interactions.getData();
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interactions.delete');
                    }
                }
            ],
            filterables: [
                'contact_date', 'created_at'
            ],
            state: session.get('data.dataframes.interactions', {
                sort: { id: 'desc' }
            }),
            batchLimits: {
                export: 50000
            }
        });

        session.sync('data.dataframes.interactions', $scope.interactions.state);

        $scope.openInteractionModal = openInteractionModal;
        $scope.openInteractionsCalendarModal = openInteractionsCalendarModal;
        $scope.openAddInteractionModal = openAddInteractionModal;
        $scope.assignedToMe = assignedToMe;
        $scope.toggleAssignedToMe = toggleAssignedToMe;

        function openInteractionModal(interaction, size) {
            modal.open('interaction', size, { interaction: interaction });
        }

        function openInteractionsCalendarModal() {
            modal.open('interactionsCalendar');
        }

        function openAddInteractionModal(size) {
            modal.open('addInteraction', size, { interaction: { account: null } }).result.then(function (data) {
                $scope.myTodos.getData();
                $scope.interactions.getData();
            });
        }

        function assignedToMe() {
            if (!$scope.interactions.state.filters.assigned_users || $scope.interactions.state.filters.assigned_users.length != 1 || $scope.interactions.state.filters.assigned_users[0] != session.get('user.id')) {
                return false;
            }

            if (!$scope.interactions.state.filters.finished || $scope.interactions.state.filters.finished.length != 1 || $scope.interactions.state.filters.finished[0] !== false) {
                return false;
            }

            return true;
        }

        function toggleAssignedToMe() {
            if (assignedToMe()) {
                $scope.interactions.state.filters.finished = null;
                $scope.interactions.state.filters.assigned_users = null;
            } else {
                $scope.interactions.state.filters.finished = [false];
                $scope.interactions.state.filters.assigned_users = [session.get('user.id')];
            }
        }
    }
})(angular);
