(function (angular) {
    angular
        .module('one.admin')
        .directive('compile', compileDirective);

    compileDirective.$inject = ['$compile'];

    function compileDirective($compile) {
        return function (scope, element, attrs) {
            scope.$watch(function (scope) {
                return scope.$eval(attrs.compile);
            }, function (value) {
                element.html(value);

                $compile(element.contents())(scope);
            });
        };
    }
})(angular);
