(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountFormEntriesController', AccountFormEntriesController);

    AccountFormEntriesController.$inject = ['$scope', 'AccountService', 'AuthService', 'FormEntryService', 'dataframe', 'modal', 'session', 'account'];

    function AccountFormEntriesController($scope, AccountService, AuthService, FormEntryService, dataframe, modal, session, account) {
        $scope.account = account;

        $scope.formEntries = dataframe.init({
            externalData: function (params) {
                return AccountService.account.formEntries($scope.account.id, params);
            },
            cols: [
                {
                    title: 'Form',
                    name: 'form.label',
                    display: function (row) {
                        return '<h4><i class="md-icon">assignment</i> ' + row.form.label + ' <span tag-indicator tags="row.form.tags" ></span></h4>';
                    },
                    weight: 4
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return '{{ row.created_at | formatDate }}';
                    }
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('form_entries.view')) {
                    return 'crm.forms.formEntry({formId: ' + row.form.id + ', formEntryId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.forms.editFormEntry({formId: ' + row.form.id + ', formEntryId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return row.form.editable && row.form.editable_entries && AuthService.userCanAccess('form_entries.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteFormEntryModal(row.id);
                    },
                    visible: function (row) {
                        return row.form.editable_entries && AuthService.userCanAccess('form_entries.delete');
                    }
                }
            ],
            state: {
                perPage: 5,
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                },
                sort: { created_at: 'desc' }
            }
        });

        $scope.openAddFormEntryModal = openAddFormEntryModal;

        function openAddFormEntryModal(size) {
            modal.open('selectFormEntry', size, { formEntry: { account: account } }).result.then(function (data) {
                $scope.formEntries.getData();
            });
        }

        function openDeleteFormEntryModal(id, size) {
            modal.open('confirm', size, {
                title: 'Are you sure?',
                message: 'Delete this form entry?'
            }).result.then(function (data) {
                FormEntryService.deleteFormEntry(id).then(function (data) {
                    $scope.formEntries.getData();
                });
            });
        }
    }
})(angular);
