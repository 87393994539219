(function (angular) {
    angular
        .module('one.admin')
        .directive('countdownModuleForm', countdownModuleFormDirective);

    countdownModuleFormDirective.$inject = ['config'];

    function countdownModuleFormDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            
        }

        return {
            restrict: 'EA',
            controller: controller,
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.countdown.form')
        };
    }
}(angular));
