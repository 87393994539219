(function (angular) {
    angular
        .module('one.admin')
        .directive('moduleIcon', moduleIconDirective);

    moduleIconDirective.$inject = [];

    function moduleIconDirective() {
        return {
            restrict: 'EA',
            scope: {
                name: '=',
            },
            replace: true,
            controller: ['$scope', 'ModuleService', function ($scope, ModuleService) {
                $scope.getIcon = function () {
                    return ModuleService.getIcon($scope.name);
                };
            }],
            template: '<span><i class="md-icon">{{ getIcon() }}</i></span>'
        };
    }
}(angular));
