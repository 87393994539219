(function (angular) {
    angular
        .module('one.admin')
        .controller('RequestExportModalController', RequestExportModalController);

    RequestExportModalController.$inject = ['$scope', '$uibModalInstance', 'params'];

    function RequestExportModalController($scope, $uibModalInstance, params) {
        angular.extend($scope, params);

        $scope.schedule = {
            number: 1,
            type: 'direct',
            timestamps: [{
                date: moment().add(1, 'days').format('YYYY-MM-DD'),
                time: '00:00:00'
            }]
        };

        $scope.confirm = function () {
            var params = {
                requestExport: true,
                export: $scope.export,
                filters: $scope.dataframe.state.filters,
                search: $scope.dataframe.state.search,
                sort: $scope.dataframe.state.sort
            };

            if ($scope.schedule.type == 'schedule') {
                params.schedule = [];

                angular.forEach($scope.schedule.timestamps, function (timestamp) {
                    params.schedule.push(timestamp.date + ' ' + (timestamp.time || '00:00:00'));
                });
            }

            $scope.dataframe.exportData(params).then(function (data) {
                $uibModalInstance.close(data);
            });
        };

        $scope.addTimestamp = function (offset) {
            var timestamp, lastTimestamp, date, newDate;

            for (var i = 0; i < $scope.schedule.number; i++) {
                timestamp = {};

                if (offset) {
                    lastTimestamp = $scope.schedule.timestamps[$scope.schedule.timestamps.length - 1];
                    date = moment(lastTimestamp.date);

                    timestamp.time = lastTimestamp.time;

                    if (offset == 'week') {
                        timestamp.date = date.add(7, 'days').format('YYYY-MM-DD');
                    } else if (offset == 'month') {
                        newDate = date.clone().add(1, 'month');

                        if (date.date() == date.clone().endOf('month').date()) {
                            newDate.endOf('month');
                        }

                        timestamp.date = newDate.format('YYYY-MM-DD');
                    }
                }

                $scope.schedule.timestamps.push(timestamp);
            }
        };

        $scope.deleteTimestamp = function (index) {
            $scope.schedule.timestamps.splice(index, 1);
        };

        $scope.offsetScheduledDate = function (timestamp, days) {
            timestamp.date = moment().add(days, 'days').format('YYYY-MM-DD');
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
