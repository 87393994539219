(function (angular) {
    angular
        .module('one.admin')
        .filter('escapeHtml', escapeHtmlFilter);

    function escapeHtmlFilter() {
        return function (value) {
            if (value) {
                return (value + '').
                    replace(/&/g, '&amp;').
                    replace(/</g, '&lt;').
                    replace(/>/g, '&gt;');
            }

            return '';
        };
    }
})(angular);
