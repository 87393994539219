(function (angular) {
    angular
        .module('one.admin')
        .controller('AddActionCampaignController', AddActionCampaignController);

    AddActionCampaignController.$inject = ['$scope', '$state', 'ActionCampaignService', 'types'];

    function AddActionCampaignController($scope, $state, ActionCampaignService, types) {
        $scope.types = types;

        var actionProject = $state.params.actionProject;
        var parentCampaign = $state.params.parentCampaign;

        $scope.actionCampaign = {
            action_project: actionProject || (parentCampaign && parentCampaign.action_project) || null,
            parent_campaign: parentCampaign,
            type: 'individual',
            image: null,
            type: null,
            share: null,
        };

        $scope.$watch('actionCampaign.action_project', function (actionProject) {
            if (actionProject) {
                $scope.actionCampaign.type = actionProject.types[0];
            }
        });

        $scope.addActionCampaign = addActionCampaign;

        function addActionCampaign(data) {
            ActionCampaignService.addActionCampaign(data).then(function (data) {
                $state.transitionTo('crowdfunding.actionCampaigns.actionCampaign', {
                    actionProjectId: data.action_campaign.action_project_id,
                    actionCampaignId: data.action_campaign.id,
                });
            });
        }
    }
})(angular);
