(function (angular) {
    angular
        .module('one.admin')
        .directive('groupLabel', groupLabelDirective);

    groupLabelDirective.$inject = ['config'];

    function groupLabelDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                group: '=',
                delete: '&',
                shorten: '=',
                hideParents: '=',
                hideTags: '=',
                noLink: '=',
                append: '=',
                compact: '='
            },
            transclude: true,
            controller: ['$scope', 'GroupService', function ($scope, GroupService) {
                $scope.groups = angular.isArray($scope.group) ? $scope.group : [$scope.group];
            }],
            link: function (scope, element, attributes) {
                scope.hasDeleteFn = !!attributes.delete;

                element.addClass('group-label');
            },
            templateUrl: config.path.template('groups.label')
        };
    }
}(angular));
