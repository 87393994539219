(function (angular) {
    angular
        .module('one.admin')
        .controller('BatchDownloadOrderPdfModalController', BatchDownloadOrderPdfModalController);

    BatchDownloadOrderPdfModalController.$inject = ['$scope', '$uibModalInstance', '$state', 'notification', 'OrderService', 'translateFilter', 'config', 'templates', 'params'];

    function BatchDownloadOrderPdfModalController($scope, $uibModalInstance, $state, notification, OrderService, translateFilter, config, templates, params) {
        angular.merge($scope, params);

        $scope.templates = templates;
        $scope.template = null;

        $scope.close = $uibModalInstance.close;
        $scope.download = download;

        function download() {
            var data = angular.merge($scope.state, {
                export: {
                    title: $scope.template.title
                }
            });

            OrderService.batchDownloadOrderPdf($scope.template.id, data).then(function (data) {
                $uibModalInstance.close();

                notification.success('Your export will be ready soon.');

                $state.transitionTo('exports.index');
            });
        }
    }
})(angular);
