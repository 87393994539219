(function (angular) {
    angular
        .module('one.admin')
        .directive('pageBlockForm', pageBlockFormDirective);

    pageBlockFormDirective.$inject = ['config'];

    function pageBlockFormDirective(config) {
        controller.$inject = ['$scope', 'PageService'];

        function controller($scope, PageService) {
            $scope.variables = PageService.getPageBlockVariables();

            $scope.formatLanguageTitle = PageService.formatLanguageTitle;
        }

        return {
            restrict: 'EA',
            scope: {
                pageBlock: '=',
                languages: '='
            },
            controller: controller,
            templateUrl: config.path.template('page_blocks.form')
        };
    }
}(angular));
