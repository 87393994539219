(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderFormEntryFilter', segmentBuilderFormEntryFilterDirective);

    segmentBuilderFormEntryFilterDirective.$inject = ['config'];

    function segmentBuilderFormEntryFilterDirective(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilderFormEntryFilterBlock',
            scope: {
                fields: '=',
                filter: '=',
                filterables: '=',
                filterBlock: '=',
                position: '=',
                segment: '='
            },
            controller: ['$scope', 'segmentBuilder', 'translateFilter', function ($scope, segmentBuilder, translateFilter) {
                $scope.isIntervalOperator = false;
                $scope.isOperatorWithoutValue = false;

                $scope.intervalDirections = segmentBuilder.intervalDirections;
                $scope.intervalTypes = segmentBuilder.intervalTypes;

                $scope.noChoicesFilter = {
                    type: 'has',
                    relation: 'choiceEntries',
                    boolean: 'and',
                    count: 0,
                    operator: '=',
                    filters: []
                };

                $scope.selectedField = $scope.filterables.getField($scope.filter);

                // Watch selected field
                $scope.$watch('selectedField', function (newField, previousField) {
                    if (newField != previousField) {
                        if (!newField.field) {
                            // Filter on field of a form entry
                            resetBasicFilter(newField, previousField);
                        } else {
                            // Filter on a form field
                            resetFieldEntryFilter(newField, previousField);
                        }
                    }
                });

                // Watch operator
                $scope.$watch(function () {
                    if ($scope.selectedField) {
                        if ($scope.selectedField.field) {
                            return $scope.filter.filters[1].operator;
                        } else {
                            return $scope.filter.operator;
                        }
                    }
                }, function (newOperator, previousOperator) {
                    if (typeof newOperator !== 'undefined') {
                        $scope.isOperatorWithoutValue = segmentBuilder.isOperatorWithoutValue(newOperator);
                        $scope.isIntervalOperator = segmentBuilder.isIntervalOperator(newOperator);

                        if ($scope.selectedField.field) {
                            filter = $scope.filter.filters[1]; // Filter on a form field
                        } else {
                            filter = $scope.filter; // Filter on field of a form entry
                        }

                        if (newOperator != previousOperator) {
                            resetOperator(filter, newOperator, previousOperator);
                        }
                    }
                });

                $scope.getOperators = getOperators;
                $scope.choiceFilter = choiceFilter;
                $scope.compareChoiceFilters = compareChoiceFilters;
                $scope.toggleNoChoicesFilter = toggleNoChoicesFilter;
                $scope.isEmptyChoiceFilter = isEmptyChoiceFilter;
                $scope.moveUpFilter = segmentBuilder.moveUpFilter;
                $scope.moveDownFilter = segmentBuilder.moveDownFilter;
                $scope.deleteFilter = segmentBuilder.deleteFilter;

                function resetOperator(filter, newOperator, previousOperator) {
                    var newOperatorIsInterval = segmentBuilder.isIntervalOperator(newOperator);
                    var previousOperatorIsInterval = segmentBuilder.isIntervalOperator(previousOperator);
                    var newOperatorIsWithoutValue = segmentBuilder.isOperatorWithoutValue(newOperator);

                    if (newOperatorIsWithoutValue || (!newOperatorIsInterval && (previousOperatorIsInterval || !previousOperator))) {
                        filter.value = null;
                    } else if (newOperatorIsInterval && !previousOperatorIsInterval) {
                        filter.value = {
                            amount: null,
                            type: null,
                            direction: null
                        };
                    }
                }

                function resetBasicFilter(newField, previousField) {
                    if (!previousField || previousField.field) {
                        // Previous filter was not a basic filter, so reset it
                        segmentBuilder.clearFilter($scope.filter);

                        // Initialize filter with comparison filter
                        angular.extend($scope.filter, segmentBuilder.comparisonFilter());
                    }

                    $scope.filter.field = newField.name;

                    if (!$scope.filter.operator || !comparisonOperatorIsAllowed($scope.filter.operator)) {
                        // If the current operator is not allowed, reset is to the first in the list of available operators
                        $scope.filter.operator = segmentBuilder.comparisonOperators[newField.type][0].value;
                    }

                    if (newField.options && newField.options.length > 0) {
                        // Selected field has options, preselect the first one
                        $scope.filter.value = newField.options[0].value;
                    } else if (previousField && (previousField.type != newField.type || (previousField.options && previousField.options.length > 0))) {
                        // Previous field had different data type or options, reset value
                        $scope.filter.value = null;
                    }
                }

                function resetFieldEntryFilter(newField, previousField) {
                    if (!previousField || !previousField.field) {
                        // Previous filter was not a field entry filter or new field has choices, so reset filter
                        segmentBuilder.clearFilter($scope.filter);

                        // Initialize filter with has filter
                        angular.extend($scope.filter, segmentBuilder.hasFilter({
                            relation: 'fieldEntries'
                        }));
                    }

                    $scope.filter.filters[0] = segmentBuilder.comparisonFilter({
                        field: 'form_field_id',
                        operator: '=',
                        value: newField.field.id
                    });

                    if (newField.field.choices && newField.field.choices.length > 0) {
                        if ($scope.filter.filters.length == 1 || $scope.filter.filters[1].type != 'has') {
                            $scope.filter.filters[1] = segmentBuilder.booleanFilter({
                                filters: [$scope.noChoicesFilter]
                            });
                        }

                        if (!$scope.filter.filters[1].operator || !booleanOperatorIsAllowed($scope.filter.filters[1].operator)) {
                            // Reset operator if previous filter was not a field entry filter with options
                            $scope.filter.filters[1].operator = segmentBuilder.booleanOperators[0].value;
                        }
                    } else {
                        if ($scope.filter.filters.length == 1 || $scope.filter.filters[1].type != 'comparison') {
                            $scope.filter.filters[1] = segmentBuilder.comparisonFilter({
                                field: 'value',
                                operator: '='
                            });
                        }

                        if (!comparisonOperatorIsAllowed($scope.filter.filters[1].operator)) {
                            // If the current operator is not allowed, reset is to the first in the list of available operators
                            $scope.filter.filters[1].operator = segmentBuilder.comparisonOperators[newField.type][0].value;
                        }

                        if (newField.options && newField.options.length > 0) {
                            // Selected field has options, preselect the first one
                            $scope.filter.filters[1].value = newField.options[0].value;
                        } else if (previousField && (previousField.type != newField.type || (previousField.options && previousField.options.length > 0))) {
                            // Previous field had different data type or options, reset value
                            $scope.filter.filters[1].value = null;
                        }
                    }
                }

                /**
                 * Checks if this operator occurs in the list of available operators.
                 * @param  {string} boolean
                 * @return {boolean}
                 */
                function booleanOperatorIsAllowed(boolean) {
                    var allowed = false;

                    angular.forEach(segmentBuilder.booleanOperators, function (booleanOperator) {
                        if (booleanOperator.value == boolean) {
                            allowed = true;
                            return;
                        }
                    });

                    return allowed;
                }

                /**
                 * Checks if this operator occurs in the list of available operators.
                 * @param  {string} operator
                 * @return {boolean}
                 */
                function comparisonOperatorIsAllowed(operator) {
                    var allowed = false;

                    angular.forEach(segmentBuilder.comparisonOperators[$scope.selectedField.type], function (comparisonOperator) {
                        if (comparisonOperator.value == operator) {
                            allowed = true;
                            return;
                        }
                    });

                    return allowed;
                }

                /**
                 * Get the available operators, depending on the type of the selected field.
                 * @return {Object|Array}
                 */
                function getOperators() {
                    if ($scope.selectedField.field && $scope.selectedField.field.choices && $scope.selectedField.field.choices.length > 0) {
                        return segmentBuilder.booleanOperators;
                    }

                    return segmentBuilder.comparisonOperators[$scope.selectedField.type];
                }

                /**
                 * Map a choice to a filter.
                 * @param  {Object} choice
                 * @return {Object}
                 */
                function choiceFilter(choice) {
                    return segmentBuilder.hasFilter({
                        relation: 'choiceEntries',
                        filters: [
                            segmentBuilder.comparisonFilter({
                                field: 'form_field_choice_id',
                                operator: '=',
                                value: choice.id
                            })
                        ]
                    });
                }

                /**
                 * Check if two choice filters are equal. Used by checklist model.
                 * @param  {Object} a
                 * @param  {Object} b
                 * @return {boolean}
                 */
                function compareChoiceFilters(a, b) {
                    return a.filters.length > 0 && b.filters.length > 0 && a.filters[0].value == b.filters[0].value;
                }

                function toggleNoChoicesFilter(filters) {
                    if (filters.length == 0) {
                        // If no choices are selected, make sure we only match fields that have no choices
                        filters.push($scope.noChoicesFilter);
                    } else {
                        // If at least one choice is selected, remove the 'no choices' filter
                        angular.forEach(filters, function (filter, index) {
                            if (isEmptyChoiceFilter(filter)) {
                                filters.splice(index, 1);
                            }
                        });
                    }
                }

                function isEmptyChoiceFilter(filter) {
                    return angular.equals(filter, $scope.noChoicesFilter);
                }
            }],
            templateUrl: config.path.template('segments.builder.form_entry_filter')
        };
    }
}(angular));
