(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', '$state', 'cfpLoadingBar', 'http', 'notification', 'session'];

    function run($rootScope, $state, cfpLoadingBar, http, notification, session) {
        $rootScope.online = true;
        $rootScope.maintenance = false;

        http.setBeforeHandler(function () {
            $rootScope.loading = true;
            $rootScope.errors = false;
        });

        http.setBeforeHandler(function (type, options) {
            if (!options.idempotent) {
                $rootScope.submitting = true;
            }
        }, 'post');

        http.setAfterHandler(function (response) {
            if (response.status === -1) {
                $rootScope.online = false;
            } else {
                $rootScope.online = true;
            }

            if (response.status == 503) {
                $rootScope.maintenance = true;
            } else {
                $rootScope.maintenance = false;
            }
        });

        http.setAfterHandler(function (response) {
            $rootScope.submitting = false;
        }, 'post');

        // Unauthorized
        http.setErrorHandler(function () {
            $rootScope.loading = false;

            session.clear();

            $state.transitionTo('login');
        }, 401);

        // Forbidden
        http.setErrorHandler(function (response) {
            $rootScope.loading = false;

            if (response.data.message) {
                notification.error(response.data.message);
            } else {
                notification.error('Unauthorised');
            }
        }, 403);

        // Unprocessable Entity
        http.setErrorHandler(function (response) {
            // Inject data into rootScope
            $rootScope.loading = false;

            if (response.data.errors) {
                $rootScope.errors = response.data.errors;
            } else if (response.data.message) {
                notification.error(response.data.message);
            }
        }, 422);

        // Too Many Requests
        http.setErrorHandler(function (response) {
            // Inject data into rootScope
            $rootScope.loading = false;

            $rootScope.errors = { limiter: ["Sorry, too many attempts. Try again later."] };
        }, 429);

        // Server error
        http.setErrorHandler(function (response) {
            $rootScope.loading = false;

            // Hide loading bar
            cfpLoadingBar.complete();

            notification.error('Server error');
        }, 500);

        // Succes
        http.setSuccessHandler(function (response) {
            $rootScope.loading = false;

            if (response.data.message && !response.data.id) {
                // Hack: checking for 'id' in case 'message' key exists on a real returned object,
                // assuming 'id' is never set when a message should be displayed.
                notification.success(response.data.message);
            }
        });
    }
})(angular);
