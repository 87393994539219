(function (angular) {
    angular
        .module('one.admin')
        .directive('orderStatusIcon', directive);

    directive.$inject = ['config'];

    function directive(config) {
        return {
            restrict: 'EA',
            scope: {
                statuses: '='
            },
            controller: ['$scope', function ($scope) {
            }],
            templateUrl: config.path.template('orders.status_icon')
        };
    }
}(angular));
