(function (angular) {
    angular
        .module('one.admin')
        .controller('FormsController', FormsController);

    FormsController.$inject = ['$scope', '$state', 'AuthService', 'FormService', 'TagService', 'dataframe', 'modal', 'session'];

    function FormsController($scope, $state, AuthService, FormService, TagService, dataframe, modal, session) {
        $scope.forms = dataframe.init({
            externalData: FormService.forms,
            cols: [
                {
                    title: 'Form',
                    name: 'label',
                    display: function (row) {
                        return '<h4><i class="md-icon">assignment</i> {{ ::row.label }}</h4>';
                    },
                    weight: 5,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Event',
                    name: 'event',
                    display: function (row) {
                        return '<a ui-sref="events.events.event({ eventId: row.event.id })" ng-click="$event.stopPropagation()" ng-if="row.event"><i class="md-icon">emoji_events</i> {{ row.event.title }}</a>';
                    },
                    weight: 4
                },
                {
                    title: 'Subform of',
                    name: 'subform',
                    display: function (row) {
                        row.parent_form_labels = [];

                        angular.forEach(row.parent_forms, function (parentForm) {
                            row.parent_form_labels.push('<i class="md-icon">assignment</i> ' + parentForm.label);
                        });

                        row.parent_form_labels_delimiter = ' <span class="text-muted">•</span> ';

                        return '<span list-limiter list="row.parent_form_labels" delimiter="row.parent_form_labels_delimiter" shorten="true" limit="1"></span>';
                    },
                    weight: 4,
                    visible: false
                },
                {
                    title: 'Published',
                    name: 'published',
                    display: function (row) {
                        row.site_labels = [];

                        angular.forEach(row.visitable_sites, function (site) {
                            row.site_labels.push('<i class="md-icon">desktop_mac</i> ' + site.title);
                        });

                        row.site_labels_delimiter = ' <span class="text-muted">•</span> ';

                        return '<span list-limiter list="row.site_labels" delimiter="row.site_labels_delimiter" shorten="true" limit="1"></span>';
                    },
                    weight: 4
                },
                {
                    title: 'Entries',
                    name: 'entry_count',
                    display: function (row) {
                        return '{{ row.entry_count | number }}';
                    },
                    weight: 2,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('forms.view')) {
                    if (!row.editable_settings) {
                        return 'crm.forms.formEntries({formId: ' + row.id + '})';
                    }

                    return 'crm.forms.formOverview({formId: ' + row.id + '})';
                }

                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.forms.editForm({formId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return row.editable_settings && AuthService.userCanAccess('forms.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.forms.loading = true;

                        FormService.duplicateForm(row).then(function () {
                            $scope.forms.getData()
                        });
                    },
                    visible: function (row) {
                        return row.editable_settings && row.duplicatable && AuthService.userCanAccess('forms.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        FormService.openDeleteFormModal(row.id, function () {
                            $scope.forms.getData();
                        });
                    },
                    visible: function (row) {
                        return row.editable_settings && AuthService.userCanAccess('forms.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.forms', {
                sort: { label: 'asc' },
                filters: {
                    root: true,
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.forms, 'form', session.get('data.tags'));
        }

        session.sync('data.dataframes.forms', $scope.forms.state);

        $scope.$watch('forms.state.filters.root', function (root) {
            if (root) {
                $scope.forms.cols[3].visible = false;
            } else {
                $scope.forms.cols[3].visible = true;
            }
        });

        $scope.openAddFormModal = openAddFormModal;

        function openAddFormModal() {
            modal.open('addForm').result.then(function (data) {
                $state.transitionTo('crm.forms.formOverview', { formId: data.form.id });
            });
        }
    }
})(angular);
