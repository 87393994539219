(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframe', oneDataframeDirective);

    oneDataframeDirective.$inject = ['config'];

    function oneDataframeDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe');
            },
            templateUrl: config.path.template('common.dataframe.dataframe')
        };
    }
})(angular);
