(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilder', segmentBuilderDirective);

    segmentBuilderDirective.$inject = ['config'];

    function segmentBuilderDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                segment: '='
            },
            controller: ['$scope', 'FormService', 'segmentBuilder', function SegmentBuilderController($scope, FormService, segmentBuilder) {
                initializeFilters();

                $scope.addAccountFilterBlock = addAccountFilterBlock;
                $scope.addAnnualPaymentFilterBlock = addAnnualPaymentFilterBlock;
                $scope.addGroupFilterBlock = addGroupFilterBlock;
                $scope.addSegmentFilterBlock = addSegmentFilterBlock;
                $scope.addActionCampaignFilterBlock = addActionCampaignFilterBlock;
                $scope.addFormEntryFilterBlock = addFormEntryFilterBlock;
                $scope.addSentMailFilterBlock = addSentMailFilterBlock;
                $scope.hasFilters = hasFilters;

                function initializeFilters() {
                    FormService.forms().then(function (forms) {
                        $scope.forms = forms;

                        $scope.formsMap = [];

                        angular.forEach(forms, function (form) {
                            $scope.formsMap[form.id] = form;
                        });
                    });

                    // We have to explicitly initialize the group/not_group filters for backwards compatibility
                    if (!$scope.segment.filters.group && $scope.segment.filters.not_group) {
                        addIncludeGroupFilterBlock();
                    }

                    if ($scope.segment.filters.group && !$scope.segment.filters.not_group) {
                        addExcludeGroupFilterBlock();
                    }

                    // Ditto for segments
                    if (!$scope.segment.filters.segment && $scope.segment.filters.not_segment) {
                        addIncludeSegmentFilterBlock();
                    }

                    if ($scope.segment.filters.segment && !$scope.segment.filters.not_segment) {
                        addExcludeSegmentFilterBlock();
                    }
                }

                function addAccountFilterBlock() {
                    $scope.segment.filters.account = segmentBuilder.booleanFilter({
                        filters: [segmentBuilder.comparisonFilter()]
                    });
                }

                function addAnnualPaymentFilterBlock() {
                    $scope.segment.filters.annual_payment = segmentBuilder.hasFilter({
                        relation: 'annualPayments',
                        boolean: 'and',
                        operator: '>',
                        count: 0,
                        filters: [
                            segmentBuilder.booleanFilter({
                                filters: [segmentBuilder.comparisonFilter()]
                            })
                        ]
                    })
                }

                function addGroupFilterBlock() {
                    addIncludeGroupFilterBlock();
                    addExcludeGroupFilterBlock();
                }

                function addIncludeGroupFilterBlock() {
                    $scope.segment.filters.group = segmentBuilder.booleanFilter({
                        operator: 'or'
                    });
                }

                function addExcludeGroupFilterBlock() {
                    $scope.segment.filters.not_group = segmentBuilder.booleanFilter({
                        operator: 'and'
                    });
                }

                function addSegmentFilterBlock() {
                    addIncludeSegmentFilterBlock();
                    addExcludeSegmentFilterBlock();
                }

                function addIncludeSegmentFilterBlock() {
                    $scope.segment.filters.segment = segmentBuilder.booleanFilter({
                        operator: 'or'
                    });
                }

                function addExcludeSegmentFilterBlock() {
                    $scope.segment.filters.not_segment = segmentBuilder.booleanFilter({
                        operator: 'and'
                    });
                }

                function addActionCampaignFilterBlock() {
                    $scope.segment.filters.action_campaign = segmentBuilder.hasFilter({
                        relation: 'actionCampaigns',
                        boolean: 'and',
                        filters: [
                            segmentBuilder.booleanFilter({
                                operator: 'and',
                                filters: [
                                    segmentBuilder.booleanFilter({
                                        operator: 'and'
                                    }),
                                    segmentBuilder.booleanFilter({
                                        operator: 'or'
                                    }),
                                ]
                            }),
                            segmentBuilder.comparisonFilter({
                                field: 'deactivated_at',
                                operator: 'empty'
                            })
                        ]
                    });
                }

                function addFormEntryFilterBlock(form) {
                    if (!$scope.segment.filters.form_entry) {
                        $scope.segment.filters.form_entry = segmentBuilder.booleanFilter();
                    }

                    var filterBlock = segmentBuilder.hasFilter({
                        relation: 'formEntries',
                        filters: [
                            segmentBuilder.comparisonFilter({
                                field: 'form_id',
                                operator: '=',
                                value: form.id
                            }),
                            segmentBuilder.booleanFilter()
                        ]
                    });

                    $scope.segment.filters.form_entry.filters.push(filterBlock);
                }

                function addSentMailFilterBlock() {
                    $scope.segment.filters.sent_mail = segmentBuilder.hasFilter({
                        relation: 'sentMails',
                        boolean: 'and',
                        operator: '>',
                        count: 0,
                        filters: [
                            segmentBuilder.booleanFilter()
                        ]
                    })
                }

                function hasFilters() {
                    var filters = $scope.segment.filters;

                    return filters.account
                        || filters.annual_payment
                        || filters.group
                        || filters.not_group
                        || filters.segment
                        || filters.not_segment
                        || filters.action_campaign
                        || filters.sent_mail
                        || (filters.form_entry && filters.form_entry.filters.length > 0);
                }
            }],
            templateUrl: config.path.template('segments.builder.builder')
        };
    }
}(angular));
