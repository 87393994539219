(function (angular) {
    angular
        .module('one.admin')
        .directive('eventForm', eventFormDirective);

    eventFormDirective.$inject = ['config'];

    function eventFormDirective(config) {
        controller.$inject = [
            '$scope', 'EventService', 'GroupService', 'ActionProjectService', 'SiteService', 'PaymentMethodService', 'ProductService'
        ];

        function controller($scope, EventService, GroupService, ActionProjectService, SiteService, PaymentMethodService, ProductService) {
            $scope.editing = {};

            if ($scope.event.tickets.length === 1 && !isComplete($scope.event.tickets[0])) {
                $scope.editing.ticket = $scope.event.tickets[0];
            }

            $scope.form = {
                enableCrowdfunding: !!$scope.event.action_project,
                selectActionProject: !!$scope.event.action_project,
                originalActionProject: $scope.event.action_project,
            };

            $scope.addTicket = addTicket;
            $scope.deleteTicket = deleteTicket;
            $scope.toggleTicketEditor = toggleTicketEditor;
            $scope.isComplete = isComplete;
            $scope.append = append;
            $scope.remove = remove;
            $scope.enableCrowdfunding = enableCrowdfunding;
            $scope.disableCrowdfunding = disableCrowdfunding;

            $scope.getActionProjects = ActionProjectService.actionProjects;
            $scope.getGroups = GroupService.groups;
            $scope.getPaymentMethods = PaymentMethodService.paymentMethods;
            $scope.getProducts = ProductService.products;

            $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;
            $scope.formatGroupTitle = GroupService.formatGroupTitle;
            $scope.formatSiteTitle = SiteService.formatSiteTitle;
            $scope.formatProductTitle = ProductService.formatProductTitle;
            $scope.formatTicketPrice = EventService.formatTicketPrice;

            function addTicket() {
                var ticket = {
                    active: true,
                    products: [],
                };

                $scope.editing.ticket = ticket;

                $scope.event.tickets.push(ticket);
            }

            function deleteTicket(index) {
                if ($scope.event.tickets[index] === $scope.editing.ticket) {
                    $scope.editing.ticket = null;
                }

                remove('tickets', index);
            }

            function toggleTicketEditor(ticket) {
                if (ticket === $scope.editing.ticket && isComplete(ticket)) {
                    $scope.editing.ticket = null;
                } else if (!$scope.editing.ticket || isComplete($scope.editing.ticket)) {
                    $scope.editing.ticket = ticket;
                }
            }

            function isComplete(ticket) {
                var complete = ticket.title;

                angular.forEach(ticket.products, function (product) {
                    complete = complete && product && (product.id || (product.amount !== null && typeof product.amount !== 'undefined'));
                });

                return complete;
            }

            function append(list, type) {
                var item = $scope.form[type];

                if (item && !inList(item, list)) {
                    list.push($scope.form[type]);
                }

                $scope.form[type] = null;
            }

            function inList(item, list) {
                var key = 'id';

                for (var i = 0; i < list.length; i++) {
                    if (item[key] === list[i][key]) {
                        return true;
                    }
                }

                return false;
            }

            function remove(type, index) {
                $scope.event[type].splice(index, 1);
            }

            function enableCrowdfunding() {
                $scope.event.action_project = $scope.form.originalActionProject || { title: '' };
            }

            function disableCrowdfunding() {
                $scope.event.action_project = null;
            }
        }

        return {
            restrict: 'EA',
            controller: controller,
            scope: {
                event: '=',
                sites: '=',
            },
            templateUrl: config.path.template('events.form')
        };
    }
}(angular));
