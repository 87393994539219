(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeSearchFilters', oneDataframeSearchFiltersDirective);

    oneDataframeSearchFiltersDirective.$inject = ['config'];

    function oneDataframeSearchFiltersDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-search-filters');
            },
            controller: ['$scope', function ($scope) {
                if ($scope.dataframe.filterables && $scope.dataframe.filterables.length > 0) {
                    $scope.isFiltered = isFiltered;

                    var filterableLabels = {};

                    angular.forEach($scope.dataframe.filterables, function (value) {
                        filterableLabels[value] = $scope.dataframe.filterableLabels[value] || value;
                    });

                    $scope.filterableLabels = filterableLabels;

                    $scope.removeFilter = removeFilter;

                    function removeFilter(index, attribute) {
                        $scope.dataframe.state.search[attribute].splice(index, 1);

                        if ($scope.dataframe.state.search[attribute].length == 0) {
                            delete $scope.dataframe.state.search[attribute];
                        }
                    }

                    function isFiltered() {
                        return angular.isObject($scope.dataframe.state.search) && Object.keys($scope.dataframe.state.search).length > 0;
                    }
                }
            }],
            templateUrl: config.path.template('common.dataframe.search_filters')
        };
    }
})(angular);
