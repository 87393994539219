(function (angular) {
    angular
        .module('one.admin')
        .factory('services', services);

    var inject = [
        'AccountService',
        'ActionCampaignService',
        'ActionProjectService',
        'AuthService',
        'FileService',
        'FormService',
        'GiftService',
        'GroupService',
        'MailService',
        'MailingService',
        'SegmentService',
        'SiteService',
        'TransactionalMailService'
    ];

    services.$inject = inject;

    function services() {
        var args = arguments;
        var services = {};

        angular.forEach(inject, function (name, i) {
            services[name] = args[i];
        });

        return services;
    }
})(angular);
