(function(angular) {
    angular
        .module('one.admin')
        .directive('imageModuleForm', imageModuleFormDirective);

    imageModuleFormDirective.$inject = ['config'];

    function imageModuleFormDirective(config) {
        controller.$inject = ['$scope', 'VisitableService'];

        function controller($scope, VisitableService) {
            $scope.site = $scope.module.type.site;
            $scope.toggleCustomUrl = !!$scope.module.module_instance.custom_url

            $scope.ratios = [
                [4, 5],
                [1, 1],
                [4, 3],
                [2, 1],
                [10, 3],
                [5, 1]
            ];

            $scope.getAspectRatio = getAspectRatio;
            $scope.setAspectRatio = setAspectRatio;
            $scope.unsetAspectRatio = unsetAspectRatio;
            $scope.formatAspectRatio = formatAspectRatio;
            $scope.getPaddingBottomFromRatio = getPaddingBottomFromRatio;
            $scope.formatVisitable = VisitableService.formatVisitableTitle;
            $scope.getVisitables = VisitableService.pageVisitables;

            function getAspectRatio() {
                if ($scope.module.module_instance.width) {
                    return $scope.module.module_instance.height / $scope.module.module_instance.width * 100;
                }

                return 0;
            }

            function setAspectRatio(ratio) {
                $scope.module.module_instance.width = ratio[0];
                $scope.module.module_instance.height = ratio[1];
            }

            function unsetAspectRatio() {
                $scope.module.module_instance.width = null;
                $scope.module.module_instance.height = null;
            }

            function formatAspectRatio(ratio) {
                return ratio[0] + ':' + ratio[1];
            }

            function getPaddingBottomFromRatio(ratio) {
                return (ratio[1] / ratio[0]) * 100;
            }

            $scope.$watch('toggleCustomUrl', function(newVal) {
                if (newVal) {
                    $scope.module.module_instance.visitable = null;
                    $scope.module.module_instance.visitable_id = null;
                    return;
                }

                $scope.module.module_instance.custom_url = null;
            });
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.image.form')
        };
    }
})(angular);
