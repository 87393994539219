(function (angular) {
    angular
        .module('one.admin')
        .controller('AddActionCampaignOverviewModuleModalController', AddActionCampaignOverviewModuleModalController);

    AddActionCampaignOverviewModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'actionProjects', 'overviewTypes', 'actionCampaignTypes', 'displayOptions', 'layoutOptions', 'sortOptions', 'params'];

    function AddActionCampaignOverviewModuleModalController($scope, $uibModalInstance, ModuleService, actionProjects, overviewTypes, actionCampaignTypes, displayOptions, layoutOptions, sortOptions, params) {
        $scope.module = {
            module_instance: {
                title: null,
                overview_type: overviewTypes[0],
                action_campaign_types: angular.copy(actionCampaignTypes),
                action_projects: [],
                groups: [],
                per_page: 6,
                sort_column: sortOptions[0],
                sort_direction: 'asc',
                display: angular.copy(displayOptions[overviewTypes[0]]),
                layout: layoutOptions[0],
                enable_filters: true,
            }
        };

        $scope.actionProjects = actionProjects;
        $scope.overviewTypes = overviewTypes;
        $scope.actionCampaignTypes = actionCampaignTypes
        $scope.displayOptions = displayOptions;
        $scope.layoutOptions = layoutOptions;
        $scope.sortOptions = sortOptions;

        angular.merge($scope, params);

        $scope.addActionCampaignOverviewModule = addActionCampaignOverviewModule;
        $scope.cancel = cancel;

        function addActionCampaignOverviewModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
