(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFormEntryController', AddFormEntryController);

    AddFormEntryController.$inject = ['$scope', '$state', '$stateParams', 'FormEntryService', 'AccountService', 'form'];

    function AddFormEntryController($scope, $state, $stateParams, FormEntryService, AccountService, form) {
        $scope.formEntry = {
            account: $stateParams.account,
            form: form
        };

        $scope.form = form;

        $scope.errors = false;

        $scope.addFormEntry = addFormEntry;
        $scope.choiceEnabled = choiceEnabled;
        $scope.compareChoices = compareChoices;
        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        function addFormEntry(data) {
            $scope.formEntrySaving = true;

            FormEntryService.saveFormEntry(data).then(function (data) {
                $state.transitionTo('crm.forms.formEntry', { formId: data.formEntry.form_id, formEntryId: data.formEntry.id });
            }, function (data) {
                $scope.errors = data.errors;
                $scope.formEntrySaving = false;
            });
        }

        function choiceEnabled(choice, fieldEntry) {
            if (!fieldEntry.field.rules.max) {
                return true;
            }

            var choiceCount = 0;

            for (var i = 0; i < fieldEntry.choice_entries.length; i++) {
                choiceCount++;

                if (fieldEntry.choice_entries[i].choice.id == choice.id) {
                    return true;
                }
            }

            return choiceCount < fieldEntry.field.rules.max;
        }

        function compareChoices(a, b) {
            if ('choice' in a && 'choice' in b) {
                return a.choice.id === b.choice.id;
            }

            return false;
        }
    }
})(angular);
