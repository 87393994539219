(function (angular) {
    angular
        .module('one.admin')
        .component('purchase', {
            controller: controller,
            bindings: {
                order: '=',
                purchase: '=',
                indent: '<'
            },
            templateUrl: ['config', function (config) {
                return config.path.template('orders.purchases.purchase');
            }]
        });

    controller.$inject = ['$state', 'modal', 'moment'];

    function controller($state, modal, moment) {
        var $ctrl = this;

        $ctrl.paymentTerms = function () {
            var paymentTerms = [];

            angular.forEach($ctrl.purchase.payment_terms, function (paymentTerm) {
                if (!paymentTerm.parent_purchase_payment_term_id) {
                    paymentTerms.push(paymentTerm);
                }
            });

            return paymentTerms;
        };

        $ctrl.isBefore = function (a, b) {
            return moment(a).isBefore(moment(b));
        };

        $ctrl.openCancelRecurringPurchaseModal = function (size) {
            modal.open('cancelRecurringPurchase', size, {purchase: $ctrl.purchase}).result.then(function (data) {
                $state.reload($state.current);
            });
        };
    }
})(angular);
