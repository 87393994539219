(function (angular) {
    angular
        .module('one.admin')
        .factory('string', string)
        .filter('simplifyUrl', simplifyUrl);

    string.$inject = ['$window'];

    function string($window) {
        this.pad = pad;
        this.escape = escape;
        this.stripHTML = stripHTML;
        this.snakeToCamel = snakeToCamel;
        this.snakeToUpperCamel = snakeToUpperCamel;
        this.snakeToCamel = snakeToCamel;
        this.snakeToTitle = snakeToTitle;
        this.lcfirst = lcfirst;
        this.ucfirst = ucfirst;
        this.param = param;
        this.queryParams = queryParams;

        function unformatCurrency(value, decimal) {
            // Recursively unformat arrays:
            // Fails silently (need decent errors):
            value = value || 0;

            // Return the value as-is if it's already a number:
            if (typeof value === "number") return value;

            // Default decimal point comes from settings, but could be set to eg. "," in opts:
            decimal = decimal || ',';

            // Build regex to strip out everything except digits, decimal point and minus sign:
            var regex = new RegExp("[^0-9-" + decimal + "]", ["g"]),
                unformatted = parseFloat(
                    ("" + value)
                        .replace(/\((.*)\)/, "-$1") // replace bracketed values with negatives
                        .replace(regex, '')         // strip out any cruft
                        .replace(decimal, '.')      // make sure decimal point is standard
                );

            // This will fail silently which may cause trouble, let's wait and see:
            return !isNaN(unformatted) ? unformatted : 0;
        }

        function escape(str) {
            return (str || '').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
        }

        function stripHTML(str) {
            return (str + '').replace(/<[^>]+>/gm, '');
        }

        function pad(x, n) {
            x = x + '';

            if (x.length >= n) {
                return x;
            }

            return pad('0' + x, n - 1);
        }

        function snakeToUpperCamel(str) {
            return str.split('_').map(ucfirst).join('');
        }

        function snakeToCamel(str) {
            return lcfirst(str.split('_').map(ucfirst).join(''));
        }

        function snakeToTitle(str) {
            return str.split('_').map(ucfirst).join(' ');
        }

        function lcfirst(str) {
            return str.charAt(0).toLowerCase() + str.slice(1);
        }

        function ucfirst(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        function lcfirst(str) {
            return str.charAt(0).toLowerCase() + str.slice(1);
        }

        function buildParam(value) {
            var result = [];

            if (angular.isObject(value)) {
                angular.forEach(value, function (v, k) {
                    angular.forEach(buildParam(v), function (keys) {
                        result.push([k].concat(keys));
                    });
                });
            } else {
                result = [value];
            }

            return result;
        }

        function param(obj) {
            var lists = angular.isObject(obj) ? buildParam(obj) : [];
            var result = {};
            var value;

            for (var i = 0; i < lists.length; i++) {
                value = lists[i].pop();

                result[lists[i][0] + (lists[i].length > 1 ? '[' + lists[i].splice(1).join('][') + ']' : '')] = value;
            }

            return result;
        }

        function queryParams() {
            var queryParams = {};
            var search = $window.location.search.replace('?', '');

            angular.forEach(search.split('&'), function (param) {
                param = param.split('=');
                queryParams[param[0]] = param[1];
            });

            return queryParams;
        }

        return this;
    }

    function simplifyUrl() {
        return function (input) {
            input = input || '';
            input = input
                .replace('http://www.', '')
                .replace('https://www.', '')
                .replace('http://', '')
                .replace('https://', '');

            return input;
        };
    }
})(angular);
