(function (angular) {
    angular
        .module('one.admin')
        .controller('EditInteractionController', EditInteractionController);

    EditInteractionController.$inject = ['$scope', '$state', 'modal', 'translateFilter', 'AuthService', 'InteractionService', 'interaction', 'subjects', 'users', 'notificationMails'];

    function EditInteractionController($scope, $state, modal, translateFilter, AuthService, InteractionService, interaction, subjects, users, notificationMails) {
        $scope.notificationMails = notificationMails;
        $scope.interaction = interaction;
        $scope.users = users;

        angular.forEach(subjects, function (subject) {
            subject.label = subject.title;
        });

        if (AuthService.userCanAccess('interactions.add_subject')) {
            subjects.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        $scope.subjects = subjects;

        if ($scope.interaction.subject) {
            $scope.interaction.subject.label = $scope.interaction.subject.title;
        }

        $scope.editInteraction = editInteraction;
        $scope.openAuditorModal = openAuditorModal;

        function editInteraction(data) {
            InteractionService.editInteraction(data).then(function (data) {
                InteractionService.updateBadgeCount();
                $state.transitionTo('crm.accounts.account.communication', { accountId: $scope.interaction.account.id });
            });
        };

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: $scope.interaction.id, modelType: 'interaction' });
        }
    }
})(angular);
