(function (angular) {
    angular
        .module('one.admin')
        .factory('templateCode', templateCode);

    templateCode.$inject = ['config'];

    function templateCode(config) {
        return {
            replaceTwig: function (string) {
                // Replace Twig outputs
                string = string.replace(/{{\s*([^{}]*?)\s*}}(?![^<]*>)/g, config.templateCode.output('$1'));

                // Replace Twig statements
                string = string.replace(/{\%\s*(.*?)\s*\%}/g, config.templateCode.statement('$1'));

                return string;
            },
            restoreTwig: function (string) {
                var $element = $('<div>' + string + '</div>');

                $element.find('.twig-output').each(function () {
                    var output = $(this).text();

                    string = string.replace($(this)[0].outerHTML, '{{ ' + output + ' }}');
                });

                $element.find('.twig-statement').each(function () {
                    var statement = $(this).text();

                    string = string.replace($(this)[0].outerHTML, '{% ' + statement + ' %}');
                });

                return string;
            }
        };
    }
})(angular);
