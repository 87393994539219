(function (angular) {
    angular
        .module('one.admin')
        .directive('actionCampaignForm', actionCampaignFormDirective);

    actionCampaignFormDirective.$inject = ['config'];

    function actionCampaignFormDirective(config) {
        controller.$inject = ['$scope', 'AccountService', 'ActionCampaignService', 'ActionProjectService'];

        function controller($scope, AccountService, ActionCampaignService, ActionProjectService) {
            var groupIds = [];

            $scope.$watch('actionCampaign.action_project', function (actionProject) {
                if (actionProject) {
                    groupIds = [];

                    angular.forEach(actionProject.groups, function (group) {
                        groupIds.push(group.id);
                    });
                }
            });

            $scope.updateActionCampaignGroups = updateActionCampaignGroups;
            $scope.display = display;
            $scope.getCampaigners = AccountService.contacts;
            $scope.formatAccountTitle = AccountService.formatAccountTitle;
            $scope.getParentCampaigns = getParentCampaigns;
            $scope.formatActionCampaignTitle = ActionCampaignService.formatActionCampaignTitle;
            $scope.getActionProjects = function () {
                return ActionProjectService.actionProjects({
                    filters: {
                        atleta: false,
                    },
                });
            };
            $scope.formatActionProjectTitle = ActionProjectService.formatActionProjectTitle;

            function updateActionCampaignGroups() {
                $scope.actionCampaign.groups = [];

                angular.forEach($scope.actionCampaign.campaigner.groups, function (group) {
                    if (groupIds.indexOf(group.id) > -1) {
                        $scope.actionCampaign.groups.push(group);
                    }
                });
            }

            function display(field) {
                var actionProject = $scope.actionCampaign.action_project;
                var type = $scope.actionCampaign.type;
                var allowedFields = actionProject.fields[type];

                return allowedFields.indexOf(field) > -1;
            }

            function getParentCampaigns(params) {
                params.filters = angular.merge({}, params.filters, {
                    action_campaign_types: ['open', 'team'],
                    action_projects: [$scope.actionCampaign.action_project.id]
                });

                return ActionCampaignService.actionCampaigns(params);
            }

        }

        return {
            restrict: 'EA',
            scope: {
                actionCampaign: '=',
                types: '=',
            },
            controller: controller,
            templateUrl: config.path.template('action_campaigns.form')
        };
    }
}(angular));
