(function (angular) {
    angular
        .module('one.admin')
        .directive('interactionForm', interactionFormDirective);

    interactionFormDirective.$inject = ['config'];

    function interactionFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                interaction: '=',
                subjects: '=',
                users: '=',
                notificationMails: '='
            },
            controller: 'InteractionFormController',
            templateUrl: config.path.template('interactions.form')
        };
    }
}(angular));
