(function (angular) {
    angular
        .module('one.admin')
        .directive('actionCampaignOverviewModuleForm', actionCampaignOverviewModuleFormDirective);

    actionCampaignOverviewModuleFormDirective.$inject = ['config'];

    function actionCampaignOverviewModuleFormDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.$watch('module.module_instance.overview_type', function (newOverviewType, oldOverviewType) {
                if (newOverviewType == oldOverviewType) {
                    return;
                }

                $scope.module.module_instance.display = angular.copy($scope.displayOptions[newOverviewType]);
            });

            $scope.overviewTypeIcons = {
                action_projects: 'folder',
                action_campaigns: 'group_work',
            };

            $scope.layoutIcons = {
                full: 'view_module',
                list: 'list'
            }

            $scope.toggleActionProjectGroups = toggleActionProjectGroups;
            $scope.actionProjectSelected = actionProjectSelected;
            $scope.groupSelected = groupSelected;

            function toggleActionProjectGroups(actionProject) {
                if (!actionProjectSelected(actionProject)) {
                    // Method is called by the ng-change directive before the model of selected action projects
                    // is actually changed, so if actionProjectSelected(actionProject) is true, then that means
                    // it has actually been deselected.
                    angular.forEach(actionProject.groups, function (group) {
                        if (!groupSelected(group)) {
                            $scope.module.module_instance.groups.push(group);
                        }
                    });
                }
            }

            function actionProjectSelected(actionProject) {
                var selected = false;

                angular.forEach($scope.module.module_instance.action_projects, function (selectedActionProject) {
                    if (actionProject.id == selectedActionProject.id) {
                        selected = true;
                    }
                });

                return selected;
            }

            function groupSelected(group) {
                var selected = false;

                angular.forEach($scope.module.module_instance.groups, function (selectedGroup) {
                    if (group.id == selectedGroup.id) {
                        selected = true;
                    }
                });

                return selected;
            }

        }

        return {
            restrict: 'EA',
            scope: {
                module: '=',
                actionProjects: '=',
                overviewTypes: '=',
                actionCampaignTypes: '=',
                displayOptions: '=',
                layoutOptions: '=',
                sortOptions: '='
            },
            controller: controller,
            templateUrl: config.path.template('modules.action_campaign_overview.form')
        };
    }
}(angular));
