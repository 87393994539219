(function (angular) {
    angular
        .module('one.admin')
        .controller('FormOverviewController', FormOverviewController);

    FormOverviewController.$inject = ['$scope', '$state', 'FormService', 'TransactionalMailService', 'string', 'modal', 'form', 'visitablePages'];

    function FormOverviewController($scope, $state, FormService, TransactionalMailService, string, modal, form, visitablePages) {
        if (!form.editable_settings) {
            $state.transitionTo('crm.forms.formEntries', {
                formId: form.id
            });
        }

        $scope.form = form;
        $scope.visitablePages = visitablePages;
        $scope.string = string;

        $scope.editor = initialEditorState();

        $scope.scopeIcon = {
            account: 'person',
            order: 'shopping_basket',
            action_campaign: 'group_work'
        };

        var sections = {
            title: {
                finished: function () {
                    return $scope.form.title !== null;
                },
                fields: ['title']
            },
            creates: {
                finished: function () {
                    return $scope.form.creates !== null || $scope.form.updates !== null;
                },
                fields: ['creates', 'updates']
            },
            end_user_settings: {
                finished: function () {
                    return $scope.form.allow_multiple !== null;
                },
                fields: ['allow_multiple', 'editable']
            },
            fields: {
                finished: function () {
                    return $scope.form.visible_fields.length + $scope.form.hidden_fields.length > 0;
                }
            },
            mail: {
                fields: ['confirmation_mail']
            },
            language: {
                fields: ['success_message', 'submit_text', 'note']
            }
        };

        $scope.mailVariables = TransactionalMailService.getVariables();

        $scope.save = save;
        $scope.setCreateAccount = setCreateAccount;
        $scope.setUpdateAccount = setUpdateAccount;
        $scope.toggleEditor = toggleEditor;
        $scope.fieldIcon = FormService.fieldIcon;
        $scope.getTransactionalMails = TransactionalMailService.transactionalMails;
        $scope.formatTransactionalMailTitle = TransactionalMailService.formatTransactionalMailTitle;
        $scope.formatTypeName = formatTypeName;
        $scope.openDeleteFormModal = openDeleteFormModal;
        $scope.openAuditorModal = openAuditorModal;
        $scope.openPublishFormModal = FormService.openPublishFormModal;
        $scope.isEmpty = isEmpty;

        function setCreateAccount() {
            $scope.editor.form.creates['account'] = {};
            delete $scope.editor.form.updates['account'];
        }

        function setUpdateAccount() {
            $scope.editor.form.updates['account'] = {};
            delete $scope.editor.form.creates['account'];
        }

        function save(section) {
            var fields = sections[section].fields;

            var formData = {
                id: $scope.form.id
            };

            angular.forEach(fields, function (field) {
                formData[field] = $scope.editor.form[field];
            });

            FormService.editForm(formData).then(function (data) {
                $scope.form = data.form;
                $scope.editor = initialEditorState();

                toggleNextEmptySection(section);
            }, function (data) {
                $scope.editor.errors = data.errors;
                $scope.editor.loading = false;
            });
        }

        function toggleNextEmptySection(section) {
            var next = nextEmptySection(section);

            if (next) {
                toggleEditor(next);
            }
        }

        function nextEmptySection(section) {
            var sectionNames = Object.keys(sections);
            var index = sectionNames.indexOf(section);
            var next;

            for (var i = index + 1; i < sectionNames.length; i++) {
                next = sectionNames[i];

                if (next && sections[next].finished && !sections[next].finished()) {
                    return next;
                }
            }

            return null;
        }

        function toggleEditor(section) {
            $scope.editor = initialEditorState();
            $scope.errors = false;

            $scope.editor.editing = section;
        }

        function initialEditorState() {
            var form = angular.copy($scope.form);

            if (form.allow_multiple === null) {
                form.allow_multiple = true;
                form.editable = false;
            }

            if (form.creates === null) {
                form.creates = { account: {} };
                form.updates = {};
            }

            if (angular.isArray(form.creates)) {
                form.creates = {};
            }

            if (angular.isArray(form.updates)) {
                form.updates = {};
            }

            return {
                editing: null,
                form: form,
                creates_account: !form.creates || !!form.creates.account,
                errors: false,
                loading: false,
                selectConfirmationMail: form.confirmation_mail,
            };
        }

        function formatTypeName(name) {
            return name.replace(/\./g, '_');
        }

        function openDeleteFormModal() {
            FormService.openDeleteFormModal($scope.form.id, function () {
                $state.transitionTo('crm.forms.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: form.id, modelType: 'form' });
        }

        function isEmpty(value) {
            return !value || (angular.isObject(value) && Object.keys(value).length == 0);
        }
    }
})(angular);
