(function (angular) {
    angular
        .module('one.admin')
        .controller('DuplicatesController', DuplicatesController);

    DuplicatesController.$inject = ['$scope', 'session', 'AuthService', 'DuplicateService', 'GroupService', 'dataframe', 'modal', 'translateFilter'];

    function DuplicatesController($scope, session, AuthService, DuplicateService, GroupService, dataframe, modal, translateFilter) {
        $scope.duplicates = dataframe.init({
            externalData: function (params) {
                return DuplicateService.duplicates(params).then(function (result) {
                    for (var i = 0; i < result.data.length; i++) {
                        result.data[i].leaf_groups = GroupService.getLeafGroups(result.data[i].groups);
                    }

                    return result;
                });
            },
            exportData: DuplicateService.duplicates,
            cols: [
                {
                    name: 'title',
                    title: 'Name',
                    display: function (row) {
                        return '<span class="h4"><i account-icon account="row"></i> {{ row.title }}</span> <span ng-repeat="group in row.leaf_groups"><span group-label group="::group" shorten="10"></span> </span>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    name: 'duplicates_count',
                    title: 'Potential duplicates',
                    display: function (row) {
                        return '{{ row.duplicates_count | number }}';
                    },
                    sortable: true,
                    weight: 1
                },
                {
                    title: 'Added',
                    name: 'created_at',
                    display: function (row) {
                        return '{{ row.created_at | formatDate }}';
                    },
                    sortable: true,
                    weight: 1
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('accounts.view')) {
                    return 'crm.duplicates.account({accountId: ' + row.id + '})';
                }

                return false;
            },
            state: session.get('data.dataframes.duplicates', {
                sort: { created_at: 'desc' }
            })
        });

        session.sync('data.dataframes.duplicates', $scope.duplicates.state);
    }
})(angular);
