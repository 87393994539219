(function (angular) {
    angular
        .module('one.admin')
        .controller('EditFormFieldController', EditFormFieldController);

    EditFormFieldController.$inject = ['$scope', '$filter', '$state', 'FormService', 'form', 'field'];

    function EditFormFieldController($scope, $filter, $state, FormService, form, field) {
        $scope.form = form;
        $scope.field = field;

        $scope.state = {
            fieldType: null,
            activeChoice: null
        };

        if ($scope.field.enabled) {
            if ($scope.field.choices.length == 0 && $scope.field.rules.choices.min === null && $scope.field.rules.choices.max === null && ($scope.field.allow_open || !$scope.field.page)) {
                // No choices
                if ($scope.field.page && $scope.field.allow_open) {
                    $scope.state.fieldType = 'open';
                } else if (!$scope.field.page) {
                    $scope.state.fieldType = 'hidden';
                }
            } else {
                // Choices
                if ($scope.field.allow_multiple_choice && (($scope.field.rules.choices.min == 1 && $scope.field.rules.choices.max == 1) || !$scope.field.allow_selection)) {
                    $scope.state.fieldType = 'multiple_choice';
                } else if ($scope.field.allow_selection) {
                    $scope.state.fieldType = 'selection';
                }
            }
        } else {
            $scope.state.fieldType = 'disabled';
        }

        $scope.isNumberField = ['integer', 'money', 'date'].indexOf($scope.field.type.data_type) >= 0;
        $scope.isMoneyField = $scope.field.type.data_type == 'money';
        $scope.isDateField = $scope.field.type.data_type == 'date';

        $scope.deletedChoices = [];

        $scope.fieldTypeIcons = {
            open: 'short_text',
            multiple_choice: 'radio_button_checked',
            selection: 'check_box',
            hidden: 'visibility_off',
            disabled: 'remove_circle_outline'
        };

        var originalPage = $scope.field.page || $scope.form.pages[0];

        $scope.formStateFields = [];

        angular.forEach($scope.form.visible_fields, function (field) {
            if (field.id != $scope.field.id) {
                $scope.formStateFields.push(field);
            }
        });

        $scope.fieldTypeIcon = FormService.fieldTypeIcon;

        $scope.hasRules = FormService.rulesTemplatePath($scope.field);

        $scope.setOpenField = function () {
            $scope.state.fieldType = 'open';

            if (!$scope.field.page) {
                $scope.field.page = originalPage;
            }

            $scope.field.enabled = true;
            $scope.field.rules.choices.min = null;
            $scope.field.rules.choices.max = null;

            if ($scope.field.choices.length > 0) {
                $scope.field.default_value = angular.copy($scope.field.choices[0].value);

                while ($scope.field.choices.length > 0) {
                    $scope.deleteChoice($scope.field.choices[0]);
                }
            }
        };

        $scope.setMultipleChoiceField = function () {
            $scope.state.fieldType = 'multiple_choice';

            if (!$scope.field.page) {
                $scope.field.page = originalPage;
            }

            $scope.field.enabled = true;
            $scope.field.rules.choices.min = 1;
            $scope.field.rules.choices.max = 1;

            $scope.reinitializeChoices();
        };

        $scope.setSelectionField = function () {
            $scope.state.fieldType = 'selection';

            if (!$scope.field.page) {
                $scope.field.page = originalPage;
            }

            $scope.field.enabled = true;
            $scope.field.rules.choices.min = 0;
            $scope.field.rules.choices.max = null;

            $scope.reinitializeChoices();
        };

        $scope.setHiddenField = function () {
            $scope.setOpenField();

            if ($scope.field.page) {
                originalPage = $scope.field.page;
                $scope.field.page = null;
            }

            $scope.state.fieldType = 'hidden';

            $scope.field.enabled = true;
            $scope.field.rules.choices.min = null;
            $scope.field.rules.choices.max = null;
        };

        $scope.setDisabledField = function () {
            if ($scope.field.page) {
                originalPage = $scope.field.page;
                $scope.field.page = null;
            }

            $scope.state.fieldType = 'disabled';

            $scope.field.enabled = false;
        };

        $scope.reinitializeChoices = function () {
            if ($scope.field.choices.length == 0) {
                if ($scope.deletedChoices.length > 0) {
                    while ($scope.deletedChoices.length > 0) {
                        $scope.restoreChoice(0);
                    }
                } else {
                    $scope.addChoice();
                }
            }
        };

        $scope.addChoice = function () {
            var value = null;

            if ($scope.field.choices.length == 0) {
                value = $scope.field.default_value;

                $scope.field.default_value = null;
            }

            $scope.field.choices.push({
                value: value,
                rules: {},
                other: false,
                default: false,
                enabled: true,
            });

            $scope.state.activeChoice = $filter('filter')($scope.field.choices, { enabled: true }).length - 1;
        };

        $scope.moveUpChoice = function (choice) {
            var index = $scope.field.choices.indexOf(choice);

            $scope.field.choices[index] = $scope.field.choices[index - 1];
            $scope.field.choices[index - 1] = choice;

            if ($scope.state.activeChoice == index) {
                $scope.state.activeChoice = index - 1;
            }
        };

        $scope.moveDownChoice = function (choice) {
            var index = $scope.field.choices.indexOf(choice);

            $scope.field.choices[index] = $scope.field.choices[index + 1];
            $scope.field.choices[index + 1] = choice;

            if ($scope.state.activeChoice == index) {
                $scope.state.activeChoice = index + 1;
            }
        };

        $scope.deleteChoice = function (choice) {
            var index = $scope.field.choices.indexOf(choice);

            if (choice.id) {
                $scope.deletedChoices.push(choice);
            }

            $scope.field.choices.splice(index, 1);

            $scope.state.activeChoice = null;
        };

        $scope.restoreChoice = function (index) {
            var choice = $scope.deletedChoices[index];

            $scope.field.choices.push(choice);
            $scope.deletedChoices.splice(index, 1);

            $scope.field.default_value = null;
        };

        $scope.clearState = function (object) {
            object.state = null;
            object.state_title = null;
        };

        $scope.formatFormPageTitle = FormService.formatFormPageTitle;

        $scope.editField = function () {
            FormService.editFormField($scope.field).then(function (data) {
                var state = data.field.visible_field ? 'formStructure' : (data.field.hidden_field ? 'hiddenFormStructure' : 'disabledFormStructure');

                $state.transitionTo('crm.forms.' + state, { formId: $scope.form.id });
            });
        };
    }
})(angular);
