(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('sales', {
            parent: 'main',
            abstract: true,
            url: '/sales',
            template: '<div ui-view></div>',
            params: {
                section: 'sales'
            }
        });

        $stateProvider.state('sales.dashboard', {
            parent: 'sales',
            abstract: true,
            url: '/dashboard',
            template: '<div ui-view></div>',
            params: {
                section: 'dashboard'
            }
        });

        $stateProvider.state('sales.dashboard.index', {
            url: '',
            controller: 'SalesDashboardController',
            templateUrl: config.path.template('dashboards.sales.dashboard'),
            requirePermission: 'sales_dashboard.view'
        });

        $stateProvider.state('sales.discountCodes', {
            parent: 'sales',
            abstract: true,
            url: '/discount-codes',
            template: '<div ui-view></div>',
            params: {
                section: 'discountCodes'
            }
        });

        $stateProvider.state('sales.discountCodes.index', {
            url: '',
            controller: 'DiscountCodesController',
            templateUrl: config.path.template('discount_codes.index'),
            requirePermission: 'discount_codes.overview'
        });

        $stateProvider.state('sales.discountCodes.addDiscountCode', {
            url: '/add',
            controller: 'AddDiscountCodeController',
            templateUrl: config.path.template('discount_codes.add'),
            resolve: {
                products: ['ProductService', function (ProductService) {
                    return ProductService.products();
                }]
            },
            requirePermission: 'discount_codes.add'
        });

        $stateProvider.state('sales.discountCodes.editDiscountCode', {
            url: '/:discountCodeId/edit',
            controller: 'EditDiscountCodeController',
            templateUrl: config.path.template('discount_codes.edit'),
            resolve: {
                discountCode: ['DiscountCodeService', '$stateParams', function (DiscountCodeService, $stateParams) {
                    return DiscountCodeService.discountCode($stateParams.discountCodeId);
                }],
                products: ['ProductService', function (ProductService) {
                    return ProductService.products();
                }]
            },
            requirePermission: 'discount_codes.edit'
        });

        $stateProvider.state('sales.discountCodes.discountCode', {
            url: '/:discountCodeId',
            controller: 'DiscountCodeController',
            templateUrl: config.path.template('discount_codes.discount_code'),
            resolve: {
                discountCode: ['DiscountCodeService', '$stateParams', function (DiscountCodeService, $stateParams) {
                    return DiscountCodeService.discountCode($stateParams.discountCodeId);
                }]
            },
            requirePermission: 'discount_codes.view'
        });

        $stateProvider.state('sales.products', {
            parent: 'sales',
            abstract: true,
            url: '/products',
            template: '<div ui-view></div>',
            params: {
                section: 'products'
            }
        });

        $stateProvider.state('sales.products.productCategories', {
            parent: 'sales.products',
            abstract: true,
            url: '/categories',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('sales.products.productCategories.index', {
            url: '',
            controller: 'ProductCategoriesController',
            templateUrl: config.path.template('product_categories.index'),
            resolve: {},
            requirePermission: 'products.overview'
        });

        $stateProvider.state('sales.products.productCategories.editProductCategory', {
            url: '/:productCategoryId/edit',
            controller: 'EditProductCategoryController',
            templateUrl: config.path.template('product_categories.edit'),
            resolve: {
                category: ['ProductService', '$stateParams', function (ProductService, $stateParams) {
                    return ProductService.productCategory($stateParams.productCategoryId);
                }]
            },
            requirePermission: 'product_categories.edit'
        });

        $stateProvider.state('sales.products.productCategories.productCategory', {
            url: '/:productCategoryId',
            controller: 'ProductCategoryController',
            templateUrl: config.path.template('product_categories.product_category.products'),
            resolve: {
                category: ['ProductService', '$stateParams', function (ProductService, $stateParams) {
                    return ProductService.productCategory($stateParams.productCategoryId);
                }]
            },
            requirePermission: 'product_categories.view'
        });

        $stateProvider.state('sales.products.productCategories.productCategoryPurchases', {
            url: '/:productCategoryId/purchases',
            controller: 'ProductCategoryPurchasesController',
            templateUrl: config.path.template('product_categories.product_category.purchases'),
            resolve: {
                category: ['ProductService', '$stateParams', function (ProductService, $stateParams) {
                    return ProductService.productCategory($stateParams.productCategoryId);
                }]
            },
            requirePermission: 'product_categories.view'
        });

        $stateProvider.state('sales.products.index', {
            url: '',
            controller: 'ProductsController',
            templateUrl: config.path.template('products.index'),
            resolve: {},
            requirePermission: 'products.overview'
        });

        $stateProvider.state('sales.products.addProduct', {
            url: '/add',
            controller: 'AddProductController',
            templateUrl: config.path.template('products.add'),
            params: {
                productCategory: null
            },
            resolve: {
                paymentMethods: ['PaymentMethodService', '$stateParams', function (PaymentMethodService) {
                    return PaymentMethodService.idealPaymentMethods();
                }],
                productCategories: ['ProductService', '$stateParams', function (ProductService) {
                    return ProductService.productCategories();
                }]
            },
            requirePermission: 'products.add'
        });

        $stateProvider.state('sales.products.editProduct', {
            url: '/:productId/edit',
            controller: 'EditProductController',
            templateUrl: config.path.template('products.edit'),
            resolve: {
                product: ['ProductService', '$stateParams', function (ProductService, $stateParams) {
                    return ProductService.product($stateParams.productId);
                }],
                paymentMethods: ['PaymentMethodService', '$stateParams', function (PaymentMethodService) {
                    return PaymentMethodService.idealPaymentMethods();
                }],
                productCategories: ['ProductService', '$stateParams', function (ProductService) {
                    return ProductService.productCategories();
                }]
            },
            requirePermission: 'products.edit'
        });

        $stateProvider.state('sales.products.product', {
            url: '/:productId',
            controller: 'ProductController',
            templateUrl: config.path.template('products.product'),
            resolve: {
                product: ['ProductService', '$stateParams', function (ProductService, $stateParams) {
                    return ProductService.product($stateParams.productId);
                }]
            },
            requirePermission: 'products.view'
        });

        $stateProvider.state('sales.orders', {
            parent: 'sales',
            abstract: true,
            url: '/orders',
            template: '<div ui-view></div>',
            params: {
                section: 'orders'
            }
        });

        $stateProvider.state('sales.orders.index', {
            url: '',
            controller: 'OrdersController',
            templateUrl: config.path.template('orders.index'),
            requirePermission: 'orders.overview'
        });

        $stateProvider.state('sales.orders.addOrder', {
            url: '/add',
            controller: 'AddOrderController',
            params: {
                account: null,
            },
            resolve: {
                paymentMethods: ['PaymentMethodService', function (PaymentMethodService) {
                    return PaymentMethodService.paymentMethods();
                }],
                products: ['ProductService', function (ProductService) {
                    return ProductService.products();
                }],
                purchaseSources: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseSources();
                }],
                purchaseDestinations: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseDestinations();
                }]
            },
            templateUrl: config.path.template('orders.add'),
            requirePermission: 'orders.add'
        });

        $stateProvider.state('sales.orders.editPurchase', {
            url: '/:orderId/purchases/:purchaseId/edit',
            controller: 'EditPurchaseController',
            templateUrl: config.path.template('orders.purchases.edit'),
            resolve: {
                purchase: ['PurchaseService', '$stateParams', function (PurchaseService, $stateParams) {
                    return PurchaseService.purchase($stateParams.purchaseId);
                }],
                purchaseSources: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseSources();
                }],
                purchaseDestinations: ['PurchaseService', '$stateParams', function (PurchaseService) {
                    return PurchaseService.purchaseDestinations();
                }]
            },
            requirePermission: 'orders.edit'
        });

        $stateProvider.state('sales.orders.order', {
            url: '/:orderId',
            controller: 'OrderController',
            templateUrl: config.path.template('orders.order'),
            resolve: {
                order: ['OrderService', '$stateParams', function (OrderService, $stateParams) {
                    return OrderService.order($stateParams.orderId);
                }],
                cancelReasons: ['OrderService', function (OrderService) {
                    return OrderService.cancelReasons();
                }],
                invoicePaymentMethods: ['PaymentMethodService', function (PaymentMethodService) {
                    return PaymentMethodService.invoicePaymentMethods();
                }]
            },
            requirePermission: 'orders.view'
        });

        modalProvider.modal('order', {
            templateUrl: config.path.template('orders.modal'),
            controller: 'OrderModalController',
        });

        modalProvider.modal('cancelOrder', {
            templateUrl: config.path.template('orders.cancel_modal'),
            controller: 'CancelOrderModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                cancelReasons: ['OrderService', function (OrderService) {
                    return OrderService.cancelReasons();
                }]
            }
        });

        modalProvider.modal('convertToInvoice', {
            templateUrl: config.path.template('orders.convert_to_invoice_modal'),
            controller: 'ConvertToInvoiceModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                invoicePaymentMethods: ['PaymentMethodService', function (PaymentMethodService) {
                    return PaymentMethodService.invoicePaymentMethods();
                }]
            }
        });

        modalProvider.modal('downloadOrderPdf', {
            templateUrl: config.path.template('orders.download_pdf_modal'),
            controller: 'DownloadOrderPdfModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                templates: ['PdfTemplateService', function (PdfTemplateService) {
                    return PdfTemplateService.pdfTemplates();
                }]
            }
        });

        modalProvider.modal('batchDownloadOrderPdf', {
            templateUrl: config.path.template('orders.batch_download_pdf_modal'),
            controller: 'BatchDownloadOrderPdfModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                templates: ['PdfTemplateService', function (PdfTemplateService) {
                    return PdfTemplateService.pdfTemplates();
                }]
            }
        });

        modalProvider.modal('sendOrderMail', {
            templateUrl: config.path.template('orders.send_mail_modal'),
            controller: 'SendOrderMailModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                mails: ['TransactionalMailService', function (TransactionalMailService) {
                    return TransactionalMailService.transactionalMails();
                }]
            }
        });

        modalProvider.modal('cancelRecurringPurchase', {
            templateUrl: config.path.template('orders.purchases.cancel_recurring_modal'),
            controller: 'CancelRecurringPurchaseModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('cancelPurchasePaymentTerm', {
            templateUrl: config.path.template('orders.purchases.payment_terms.cancel_modal'),
            controller: 'CancelPurchasePaymentTermModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('refundPurchasePaymentTerm', {
            templateUrl: config.path.template('orders.purchases.payment_terms.refund_modal'),
            controller: 'RefundPurchasePaymentTermModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addProductCategory', {
            templateUrl: config.path.template('product_categories.add'),
            controller: 'AddProductCategoryModalController',
        });
    }
})(angular);
