(function (angular) {
    angular
        .module('one.admin')
        .controller('ReverseSddTransactionModalController', ReverseSddTransactionModalController);

    ReverseSddTransactionModalController.$inject = ['$scope', '$uibModalInstance', 'TransactionService', 'notification', 'translateFilter', 'params'];

    function ReverseSddTransactionModalController($scope, $uibModalInstance, TransactionService, notification, translateFilter, params) {
        angular.merge($scope, params);

        $scope.retry = false;

        $scope.close = $uibModalInstance.close;
        $scope.reverseSddTransaction = reverseSddTransaction;

        function reverseSddTransaction() {
            TransactionService.transaction.applyAction($scope.transaction.id, TransactionService.actions.reverse, {retry: $scope.retry}).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
