(function (angular) {
    angular
        .module('one.admin')
        .controller('EditIpLocationController', EditIpLocationController);

    EditIpLocationController.$inject = ['$scope', '$state', 'IpLocationService', 'ipLocation'];

    function EditIpLocationController($scope, $state, IpLocationService, ipLocation) {
        $scope.ipLocation = ipLocation;

        $scope.edit = edit;

        function edit(data) {
            IpLocationService.editIpLocation(data).then(function (data) {
                $state.transitionTo('ipLocations.index');
            });
        }
    }
})(angular);
