(function (angular) {
    angular
        .module('one.admin')
        .controller('AddCardModuleModalController', AddCardModuleModalController);

    AddCardModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddCardModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                content: '',
                new_window: true,
                image: null,
                image_alt_text: '',
                brand: 'white',
            }
        };

        $scope.loading = false;

        angular.merge($scope, params);

        $scope.addCardModule = addCardModule;

        $scope.cancel = cancel;

        function addCardModule(data) {
            $scope.loading = true;

            ModuleService.addModule(data).then(function (data) {
                $scope.loading = false;
                $uibModalInstance.close(data);
            }, function (data) {
                $scope.errors = data.errors;
                $scope.loading = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
