(function (angular) {
    angular
        .module('one.admin')
        .controller('FaqModuleFormController', FaqModuleFormController);

    FaqModuleFormController.$inject = ['$scope'];

    function FaqModuleFormController($scope) {
        $scope.site = $scope.module.type.site;

        $scope.moveUpSubsection = moveUpSubsection;
        $scope.moveDownSubsection = moveDownSubsection;
        $scope.addSubsection = addSubsection;
        $scope.deleteSubsection = deleteSubsection;
        $scope.toggleSubsection = toggleSubsection;

        function toggleSubsection(subsection) {
            subsection.show = !subsection.show;
        }

        function addSubsection() {
            var newSubsection = {
                answer: '',
                question: '',
                show: true,
                position: this.module.module_instance.subsections.length
            }

            this.module.module_instance.subsections.push(newSubsection);
        }

        function deleteSubsection(i) {
            this.module.module_instance.subsections.splice(i, 1);
        }

        function moveUpSubsection(i) {
            if (i === 0) return;
            var subsections = this.module.module_instance.subsections;
            var subsection = subsections[i];

            this.module.module_instance.subsections[i] = subsections[i - 1];
            this.module.module_instance.subsections[i - 1] = subsection;
        }

        function moveDownSubsection(i) {
            var subsections = this.module.module_instance.subsections;
            var subsection = subsections[i];
            
            if (i === subsections.length - 1) return;

            this.module.module_instance.subsections[i] = subsections[i + 1];
            this.module.module_instance.subsections[i + 1] = subsection;
        }
    }
})(angular);
