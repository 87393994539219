(function (angular) {
    angular
        .module('one.admin')
        .controller('EditFormEntryController', EditFormEntryController);

    EditFormEntryController.$inject = ['$scope', '$state', 'notification', 'FormEntryService', 'AccountService', 'form', 'formEntry'];

    function EditFormEntryController($scope, $state, notification, FormEntryService, AccountService, form, formEntry) {
        $scope.form = form;
        $scope.formEntry = formEntry;

        $scope.errors = false;

        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;
        $scope.editFormEntry = editFormEntry;

        function editFormEntry(data) {
            $scope.formEntrySaving = true;

            FormEntryService.saveFormEntry(data).then(function (data) {
                $state.transitionTo('crm.forms.formEntry', { formId: data.formEntry.form_id, formEntryId: data.formEntry.id });
            }, function (data) {
                $scope.errors = data.errors;

                $scope.formEntrySaving = false;
            });
        }
    }
})(angular);
