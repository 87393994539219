(function (angular) {
    angular
        .module('one.admin')
        .controller('PageSettingsController', PageSettingsController);

    PageSettingsController.$inject = ['$scope', '$state', 'modal', 'PageService', 'blocks', 'flags', 'languages', 'page'];

    function PageSettingsController($scope, $state, modal, PageService, blocks, flags, languages, page) {
        $scope.blocks = blocks;
        $scope.flags = flags;
        $scope.languages = languages;
        $scope.page = angular.copy(page);
        $scope.accessLevels = PageService.constants.access_levels;

        $scope.editPage = editPage;
        $scope.openAuditorModal = openAuditorModal;
        $scope.openDeletePageModal = openDeletePageModal;

        function editPage(data) {
            PageService.editPage(data).then(function (data) {
                $state.reload($state.current);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: page.id, modelType: 'page' });
        }

        function openDeletePageModal() {
            PageService.openDeletePageModal($scope.page.id, function () {
                $state.transitionTo('content.pages.index');
            });
        }
    }
})(angular);
