(function (angular) {
    angular
        .module('one.admin')
        .controller('AddProductController', AddProductController);

    AddProductController.$inject = ['$scope', '$state', '$stateParams', 'ProductService', 'paymentMethods', 'productCategories'];

    function AddProductController($scope, $state, $stateParams, ProductService, paymentMethods, productCategories) {
        $scope.paymentMethods = paymentMethods;
        $scope.productCategories = productCategories;

        $scope.product = {
            category: $stateParams.productCategory,
            vat_percentage: ProductService.vatPercentages[0].value,
            vat_inclusive: true,
            payment_type: ProductService.paymentTypes[0].value,
            allow_custom_amount: false,
            payment_terms: [{ timing: 'direct' }],
            amount: 0,
            gross_amount: 0,
            vat_amount: 0,
            images: [],
            payment_methods: [],
        };

        $scope.addProduct = addProduct;

        function addProduct(data) {
            ProductService.addProduct(data).then(function (data) {
                $state.transitionTo('sales.products.product', { productId: data.product.id });
            });
        }
    }
})(angular);
