(function (angular) {
    angular
        .module('one.admin')
        .controller('MergeActionCampaignsModalController', MergeActionCampaignsModalController);

    MergeActionCampaignsModalController.$inject = ['$scope', '$uibModalInstance', 'ActionCampaignService', 'params'];

    function MergeActionCampaignsModalController($scope, $uibModalInstance, ActionCampaignService, params) {
        $scope.merge = {
            master: null,
            duplicate: null
        };

        angular.merge($scope, params);

        $scope.original = $scope.merge.master;

        $scope.close = $uibModalInstance.close;
        $scope.getActionCampaigns = getActionCampaigns;
        $scope.mergeActionCampaigns = mergeActionCampaigns;
        $scope.formatActionCampaignTitle = ActionCampaignService.formatActionCampaignTitle;

        function getActionCampaigns(params) {
            params = params || {};
            params.sort = { created_at: 'desc' };

            return ActionCampaignService.actionCampaigns(params);
        }

        function mergeActionCampaigns() {
            ActionCampaignService.mergeActionCampaigns($scope.merge).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
