(function (angular) {
    angular
        .module('one.admin')
        .controller('RefundPurchasePaymentTermModalController', RefundPurchasePaymentTermModalController);

    RefundPurchasePaymentTermModalController.$inject = ['$scope', '$uibModalInstance', 'PurchaseService', 'notification', 'params'];

    function RefundPurchasePaymentTermModalController($scope, $uibModalInstance, PurchaseService, notification, params) {
        $scope.paymentTerm = params.paymentTerm;

        $scope.close = $uibModalInstance.close;
        $scope.refundPaymentTerm = refundPaymentTerm;

        function refundPaymentTerm() {
            PurchaseService.refundPaymentTerm($scope.paymentTerm.id).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
