(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeRow', oneDataframeRowDirective);

    oneDataframeRowDirective.$inject = ['config'];

    function oneDataframeRowDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '=',
                row: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-row');
            },
            templateUrl: config.path.template('common.dataframe.row')
        };
    }
})(angular);
