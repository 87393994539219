(function (angular) {
    angular
        .module('one.admin')
        .directive('inlineEditor', inlineEditorDirective);

    inlineEditorDirective.$inject = ['config'];

    function inlineEditorDirective(config) {
        return {
            restrict: 'EA',
            transclude: true,
            scope: {
                save: '&',
                cancel: '&',
                errors: '=',
                loading: '='
            },
            templateUrl: config.path.template('common.inline_editor.editor')
        };
    }
}(angular));
