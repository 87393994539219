(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$templateCache'];

    function run($templateCache) {
        $templateCache.put('one.ui.select',
            '<div class="input-group one-new-select" ng-class="{disabled: disabled}" uib-dropdown is-open="state.open">' +
            '<div class="form-control" tabindex="0" ng-focus="openDropdown(); $event.stopPropagation()" ng-if="!state.open">' +
            '<span compile="getOptionLabel(state.selected.option)" ng-if="hasValue()"></span>' +
            '<span class="text-muted" ng-if="!hasValue() && !nullable">{{ state.search || placeholder }}</span>' +
            '<span ng-if="!hasValue() && nullable && !placeholder">{{ \'general.none\' | translate }}</span>' +
            '</div>' +
            '<div class="input-group-addon" ng-if="state.open">' +
            '<i class="md-icon" ng-if="!state.loading" style="width: 18px;">search</i>' +
            '<span class="loader" ng-if="state.loading" style="width: 18px;"></span>' +
            '</div>' +
            '<input type="text" class="form-control search" ng-if="state.open" input-focus-when="state.focus" ng-model="state.search" placeholder="{{ getOptionLabel(state.selected.option, true) || \'general.search\' | translate }}" ng-keydown="handleKeydown($event)" ng-click="$event.stopPropagation()" ng-disabled="disabled" />' +
            '<a class="input-group-addon" ng-click="clearModel()" ng-if="nullable && hasValue()"><i class="md-icon">close</i></a>' +
            '<a class="input-group-addon last" ng-click="openDropdown(); $event.stopPropagation()"><i class="md-icon">expand_more</i></a>' +
            '<ul class="dropdown-menu" ng-class="{loading: state.loading && state.limit < state.total}" uib-dropdown-menu ng-if="items.length > 0">' +
            '<li ng-click="setModel(item)" ng-mouseenter="state.selected.index = $index" ng-class="{hover: state.selected.index == $index, active: modelsAreEqual(state.selected.option, item), sep: ($index + 1) % perPage == 0 && externalOptions() }" ng-repeat="item in items">' +
            '<div class="text-limiter" compile="getOptionLabel(item)"></div>' +
            '</li>' +
            '<li ng-click="showMore(); $event.stopPropagation()" ng-mouseenter="state.selected.index = state.limit" class="show-more" ng-class="{hover: state.selected.index == state.limit}" ng-if="state.total > state.limit && !state.loading">' +
            '<i class="md-icon text-muted">add</i> {{ \'general.show_n_more\' | translate: { n: remaining() } }} <small class="text-muted pull-right">{{ \'general.n_results\' | translate: { n: state.total } }}</small>' +
            '</li>' +
            '</ul>' +
            '</div>'
        );

        $templateCache.put('one.ui.options-dropdown',
            '<div class="dropdown options-dropdown" uib-dropdown>' +
            '<a class="dropdown-toggle" uib-dropdown-toggle>' +
            '<i class="md-icon">more_vert</i><i class="md-icon">keyboard_arrow_down</i>' +
            '</a>' +
            '<ul ng-transclude class="dropdown-menu" ng-class="{\'dropdown-menu-right\': dropdownMenuRight}"></ul>' +
            '</div>'
        );

        $templateCache.put('one.ui.options-dropdown.item',
            '<li ng-transclude></li>'
        );

        $templateCache.put("uib/template/datepicker/popup.html",
            "<ul class=\"dropdown-menu\" ng-if=\"isOpen\" style=\"display: block\" ng-style=\"{top: position.top+'px', left: position.left+'px'}\" ng-keydown=\"keydown($event)\" ng-click=\"$event.stopPropagation()\">\n" +
            " <li ng-transclude></li>\n" +
            " <li ng-if=\"showButtonBar\" style=\"padding:10px 9px 2px\">\n" +
            "     <span class=\"btn-group pull-left\">\n" +
            "         <button type=\"button\" class=\"btn btn-sm btn-default\" ng-click=\"select('today')\" ng-disabled=\"isDisabled('today')\">{{ getText('current') }}</button>\n" +
            "         <button type=\"button\" class=\"btn btn-sm btn-danger\" ng-click=\"select(null)\">{{ getText('clear') }}</button>\n" +
            "     </span>\n" +
            "     <button type=\"button\" class=\"btn btn-sm btn-primary pull-right\" ng-click=\"close()\">{{ getText('close') }}</button>\n" +
            " </li>\n" +
            "</ul>\n"
        );

        $templateCache.put("uib/template/datepicker/day.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><a class=\"btn btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_before</i></a></a></th>\n" +
            "      <th colspan=\"{{::5 + showWeeks}}\"><a id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" class=\"btn btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></a></th>\n" +
            "      <th><a class=\"btn btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_next</i></a></a></th>\n" +
            "    </tr>\n" +
            "    <tr>\n" +
            "      <th ng-if=\"showWeeks\" class=\"text-center\"></th>\n" +
            "      <th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{::label.full}}\">{{::label.abbr}}</small></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr ng-repeat=\"row in rows track by $index\">\n" +
            "      <td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td>\n" +
            "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\">\n" +
            "        <a style=\"min-width:100%;\" class=\"btn btn-sm\" ng-class=\"{active: isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'text-muted': dt.secondary}\">{{::dt.label}}</span></a>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n"
        );

        $templateCache.put("uib/template/datepicker/month.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><a class=\"btn btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_before</i></a></a></th>\n" +
            "      <th><a id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" class=\"btn btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></a></th>\n" +
            "      <th><a class=\"btn btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_next</i></a></a></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr ng-repeat=\"row in rows track by $index\">\n" +
            "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\">\n" +
            "        <a style=\"min-width:100%;\" class=\"btn\" ng-class=\"{active: isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span>{{::dt.label}}</span></a>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n"
        );

        $templateCache.put("uib/template/datepicker/year.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><a class=\"btn btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_before</i></a></th>\n" +
            "      <th colspan=\"3\"><a id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" class=\"btn btn-sm\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></a></th>\n" +
            "      <th><a class=\"btn btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"md-icon\">navigate_next</i></a></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr ng-repeat=\"row in rows track by $index\">\n" +
            "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\">\n" +
            "        <a style=\"min-width:100%;\" class=\"btn\" ng-class=\"{active: isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span>{{::dt.label}}</span></a>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n"
        );
    }
})(angular);
