(function(angular) {
    angular.module('one.admin').service('PageService', PageService);

    PageService.$inject = ['http', 'modal', 'translateFilter'];

    function PageService(http, modal, translateFilter) {
        var constants = {
            access_levels: {
                public: 'public',
                login: 'login',
                logout: 'logout',
                hidden: 'hidden'
            }
        };

        function flags(params) {
            return http.get('pages/flags', params);
        }

        function languages(params) {
            return http.get('pages/languages', params);
        }

        function page(id) {
            return http.get('pages/' + id);
        }

        function pages(params) {
            return http.get('pages', params);
        }

        function block(id) {
            return http.get('pages/blocks/' + id);
        }

        function blocks(params) {
            return http.get('pages/blocks', params);
        }

        function nav(id) {
            return http.get('pages/navs/' + id);
        }

        function navs(params) {
            return http.get('pages/navs', params);
        }

        function rowOptions(params) {
            return http.get('pages/row_options', params);
        }

        function addPage(data) {
            return http.post('pages/add', data);
        }

        function deletePage(id) {
            return http.post('pages/' + id + '/delete');
        }

        function editPage(data) {
            return http.post('pages/' + data.id + '/edit', data);
        }

        function duplicatePage(data) {
            return http.post('pages/' + data.id + '/duplicate', data);
        }

        function addBlock(data) {
            return http.post('pages/blocks/add', data);
        }

        function duplicateBlock(data) {
            return http.post('pages/blocks/' + data.id + '/duplicate', data);
        }

        function deleteBlock(id) {
            return http.post('pages/blocks/' + id + '/delete');
        }

        function editBlock(data) {
            return http.post('pages/blocks/' + data.id + '/edit', data);
        }

        function getPageBlockVariables() {
            return ['site.url', 'uploadsUrl', 'user_nav | raw'];
        }

        function addNav(data) {
            return http.post('pages/navs/add', data);
        }

        function duplicateNav(data) {
            return http.post('pages/navs/' + data.id + '/duplicate', data);
        }

        function deleteNav(id) {
            return http.post('pages/navs/' + id + '/delete');
        }

        function editNav(data) {
            return http.post('pages/navs/' + data.id + '/edit', data);
        }

        function formatPageTitle(page) {
            return '<i class="md-icon">description</i> ' + page.title;
        }

        function formatLanguageTitle(language) {
            return translateFilter('languages.' + language.name);
        }

        function openDeletePageModal(id, callback) {
            modal
                .open('confirm', null, {
                    title: 'Are you sure?',
                    message: 'Delete this page?'
                })
                .result.then(function(data) {
                    return deletePage(id).then(callback);
                });
        }

        function openDeletePageNavModal(id, callback) {
            modal
                .open('confirm', null, {
                    title: 'Are you sure?',
                    message: 'Delete this navigation?'
                })
                .result.then(function(data) {
                    return deleteNav(id).then(callback);
                });
        }

        return {
            constants: constants,
            flags: flags,
            languages: languages,
            page: page,
            pages: pages,
            block: block,
            blocks: blocks,
            nav: nav,
            navs: navs,
            rowOptions: rowOptions,
            addPage: addPage,
            editPage: editPage,
            deletePage: deletePage,
            duplicatePage: duplicatePage,
            addBlock: addBlock,
            editBlock: editBlock,
            deleteBlock: deleteBlock,
            duplicateBlock: duplicateBlock,
            getPageBlockVariables: getPageBlockVariables,
            addNav: addNav,
            duplicateNav: duplicateNav,
            deleteNav: deleteNav,
            editNav: editNav,
            formatPageTitle: formatPageTitle,
            formatLanguageTitle: formatLanguageTitle,
            openDeletePageModal: openDeletePageModal,
            openDeletePageNavModal: openDeletePageNavModal
        };
    }
})(angular);
