(function (angular) {
    angular
        .module('one.admin')
        .directive('modelTagger', modelTaggerDirective);

    modelTaggerDirective.$inject = [];

    function modelTaggerDirective() {
        return {
            restrict: 'AE',
            scope: {
                model: '=',
                modelType: '@'
            },
            controller: ['$scope', '$state', 'session', 'TagService', 'modal', function ($scope, $state, session, TagService, modal) {
                $scope.maxTags = TagService.maxTags;

                $scope.tags = session.get('data.tags', []);

                TagService.tags().then(function (tags) {
                    session.set('data.tags', tags);
                    $scope.tags = tags;
                });

                var indicators = {};

                $scope.isActive = function (tag) {
                    if (!(tag.id in indicators)) {
                        indicators[tag.id] = TagService.containsTag($scope.model.tags, tag)
                    }

                    return indicators[tag.id];
                };

                $scope.toggleTag = function (tag) {
                    indicators[tag.id] = !indicators[tag.id];

                    $scope.tagging = true;

                    TagService.toggleTagOptimistically(tag, $scope.modelType, $scope.model).then(function () {
                        $state.reload($state.current);
                    });
                };

                $scope.openAddTagModal = function () {
                    modal.open('addTag', 'md', {
                        models: [
                            { id: $scope.model.id, type: $scope.modelType }
                        ]
                    }).result.then(function (data) {
                        $state.reload($state.current);
                    });
                };
            }],
            templateUrl: 'one.common.tags.model_tagger'
        };
    }
})(angular);
