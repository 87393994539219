(function (angular) {
    angular
        .module('one.admin')
        .component('formEntryEditor', {
            bindings: {
                'form': '<',
                'formEntry': '=',
                'account': '='
            },
            controller: controller,
            templateUrl: ['config', function (config) {
                return config.path.template('forms.entries.editor.main');
            }]
        });

    controller.$inject = ['$scope', '$timeout', 'FormEntryService'];

    function controller($scope, $timeout, FormEntryService) {
        var $ctrl = this;
        var updateTimer;

        $ctrl.fieldEntriesForPage = function (page) {
            var fieldEntries = [];

            angular.forEach($ctrl.formEntry.field_entries, function (fieldEntry) {
                if ($ctrl.formEntry.form.fields[fieldEntry.field.id].form_page_id == page.id) {
                    fieldEntries.push(fieldEntry);
                }
            });

            return fieldEntries;
        };

        $ctrl.field = function (fieldEntry) {
            return $ctrl.formEntry.form.fields[fieldEntry.field.id];
        };

        $ctrl.fieldIsVisible = function (fieldEntry) {
            return $ctrl.state && $ctrl.state.visibilities && $ctrl.state.visibilities.fields[fieldEntry.field.id];
        };

        $ctrl.updateState = function () {
            var timeout = 0;

            $timeout.cancel(updateTimer);

            updateTimer = $timeout(function () {
                FormEntryService.updateFormEntryState($ctrl.formEntry).then(function (state) {
                    $ctrl.state = state;
                    $ctrl.state.update = $ctrl.updateState;

                    // Clean up choice entries with disabled choices
                    angular.forEach($ctrl.formEntry.field_entries, function (fieldEntry) {
                        angular.forEach(fieldEntry.choice_entries, function (choiceEntry, i) {
                            if (!state.visibilities.choices[choiceEntry.choice.id]) {
                                fieldEntry.choice_entries.splice(i, 1);
                            }
                        });
                    });
                });
            }, timeout);
        };

        $ctrl.showOverview = function () {
            return ($ctrl.state.purchases.length > 0 || $ctrl.state.discount_codes.length > 0) && $ctrl.state.page == $ctrl.formEntry.form.pages.length - 1;// && $ctrl.options.submittable;
        };

        $ctrl.initFormEntry = function () {
            FormEntryService.initFormEntry($ctrl.formEntry).then(function (formEntry) {
                angular.extend($ctrl.formEntry, formEntry);

                // Need to use scope in order to watch account ID, which is changed from outside this component
                $scope.$watch(function () {
                    return $ctrl.formEntry.account ? $ctrl.formEntry.account.id : null;
                }, $ctrl.updateState);
            });
        };

        $ctrl.formatModelTitle = FormEntryService.formatModelTitle;

        $ctrl.$onInit = function () {
            $ctrl.state = false;

            if ($ctrl.account && $ctrl.form.require_account && !$ctrl.formEntry.account) {
                $ctrl.formEntry.account = $ctrl.account;
            }

            $ctrl.initFormEntry();
        };
    }
})(angular);
