(function (angular) {
    angular
        .module('one.admin')
        .controller('ExportPaymentReferencesModalController', ExportPaymentReferencesModalController);

    ExportPaymentReferencesModalController.$inject = ['$scope', '$uibModalInstance', 'session', 'PaymentReferenceService', 'modal', 'params'];

    function ExportPaymentReferencesModalController($scope, $uibModalInstance, session, PaymentReferenceService, modal, params) {
        angular.extend($scope, params);

        $scope.getAccounts = function (params) {
            return PaymentReferenceService.accounts($scope.paymentReferenceConfig.id, params);
        };

        $scope.export = function () {
            $scope.accounts.requestExport({
                title: $scope.paymentReferenceConfig.title
            });

            $scope.close();
        };

        $scope.close = $uibModalInstance.close;

        session.sync('data.dataframes.export_payment_references', {
            perPage: 5
        });
    }
})(angular);
