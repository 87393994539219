(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$rootScope', 'session'];

    function run($rootScope, session) {
        $rootScope.session = session;
    }
})(angular);
