(function (angular) {
    angular
        .module('one.admin')
        .controller('EditMailSenderController', EditMailSenderController);

    EditMailSenderController.$inject = ['$scope', '$state', 'MailService', 'modal', 'domains', 'sender', 'users'];

    function EditMailSenderController($scope, $state, MailService, modal, domains, sender, users) {
        $scope.domains = domains;
        $scope.sender = sender;

        angular.forEach(domains, function (domain) {
            if ($scope.sender.domain.name == domain.name) {
                $scope.sender.domain = domain;
            }
        });

        $scope.users = users;

        $scope.editMailSender = editMailSender;
        $scope.openAuditorModal = openAuditorModal;

        function editMailSender(data) {
            MailService.editMailSender(data).then(function (data) {
                $state.transitionTo('mail.senders.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: sender.id, modelType: 'mail_sender' });
        }
    }
})(angular);
