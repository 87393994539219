(function (angular) {
    angular
        .module('one.admin')
        .controller('AccountAboutController', AccountAboutController);

    AccountAboutController.$inject = ['$scope', '$rootScope', '$timeout', '$interval', '$state', '$filter', 'AccountService', 'AuthService', 'InteractionService', 'GroupService', 'RelationService', 'MailService', 'SiteService', 'VariableService', 'config', 'modal', 'moment', 'string', 'account', 'genders', 'activity', 'sites', 'languages'];

    function AccountAboutController($scope, $rootScope, $timeout, $interval, $state, $filter, AccountService, AuthService, InteractionService, GroupService, RelationService, MailService, SiteService, VariableService, config, modal, moment, string, account, genders, activity, sites, languages) {
        $scope.account = account;
        $scope.account.formatted_name = AccountService.formatName(account);
        $scope.languages = languages;

        $scope.hasContactDetails = ($scope.account.account_type_name == 'organisation' && $scope.account.full_name) || $scope.account.email || $scope.account.secondary_email || $scope.account.phone_private || $scope.account.phone_home || $scope.account.phone_work || $scope.account.phone_emergency;

        emptyValuesFilter = function (el) { return el instanceof Array ? el.length : el; };

        $scope.addressLines = [
            [$scope.account.street, $scope.account.house_number, $scope.account.house_number_addition].filter(emptyValuesFilter),
            [$scope.account.zip_code, $scope.account.city].filter(emptyValuesFilter),
            [$scope.account.country].filter(emptyValuesFilter)
        ].filter(emptyValuesFilter);

        $scope.postalAddressLines = [
            [$scope.account.postal_street, $scope.account.postal_house_number, $scope.account.postal_house_number_addition].filter(emptyValuesFilter),
            [$scope.account.postal_zip_code, $scope.account.postal_city].filter(emptyValuesFilter),
            [$scope.account.postal_country].filter(emptyValuesFilter)
        ].filter(emptyValuesFilter);

        $scope.genders = genders;
        $scope.sites = sites;

        // Used to keep track of UI states
        $scope.formStates = {};

        // Keys of fields used by different forms
        $scope.accountFields = {
            contact: ['initials', 'first_name', 'infix', 'last_name', 'gender', 'date_of_birth', 'language'],
            team: ['title'],
            organisation: ['title'],
            contact_details: [
                'email', 'secondary_email', 'phone_home', 'phone_work', 'phone_private', 'phone_emergency',
                'initials', 'first_name', 'infix', 'last_name', 'gender', 'date_unsubscribed_from_mails'
            ],
            address: ['street', 'house_number', 'house_number_addition', 'zip_code', 'city', 'country'],
            postal_address: ['postal_street', 'postal_house_number', 'postal_house_number_addition', 'postal_zip_code', 'postal_city', 'postal_country'],
            note: ['note']
        };

        $scope.accountForms = {};

        $scope.variableSearch = { value: null };

        // Initialize forms
        resetForms();
        resetRelation();

        $scope.activity = preprocessActivity(activity);

        var activityTimer = $interval(getActivity, 10000);

        $scope.$on('$destroy', function () {
            $interval.cancel(activityTimer);
        });

        getRelations();
        getVariables();

        watchAddress();
        watchAddress('postal_');

        $scope.getActivityPermission = getActivityPermission;
        $scope.fileHref = fileHref;
        $scope.openInteractionActivity = openInteractionActivity;
        $scope.openSentMailActivity = openSentMailActivity;
        $scope.setMailSubscription = setMailSubscription;
        $scope.openDeleteRelationModal = openDeleteRelationModal;
        $scope.openDeleteVariableModal = openDeleteVariableModal;
        $scope.editAccount = editAccount;
        $scope.addRelation = addRelation;
        $scope.makeRelationPrimaryOrganisation = makeRelationPrimaryOrganisation;
        $scope.addVariable = addVariable;
        $scope.editVariable = editVariable;
        $scope.showAddVariableForm = showAddVariableForm;
        $scope.showEditVariableForm = showEditVariableForm;
        $scope.hideEditVariableForm = hideEditVariableForm;
        $scope.variablesWhereKey = variablesWhereKey;
        $scope.showForm = showForm;
        $scope.hideForm = hideForm;
        $scope.resetForms = resetForms;
        $scope.formatGender = AccountService.formatGender;
        $scope.impersonateUrl = impersonateUrl;
        $scope.formatLanguageTitle = SiteService.formatLanguageTitle;

        function impersonateUrl(site) {
            return config.path.api('accounts/' + $scope.account.id + '/impersonate/' + site.id);
        }

        function getActivity() {
            AccountService.account.activity($scope.account.id, null, { ignoreLoadingBar: true }).then(function (activity) {
                $scope.activity = preprocessActivity(activity);
            });
        }

        function preprocessActivity(activity) {
            var feed = [];
            var category = {};

            angular.forEach(activity, function (item) {
                var timestamp = $filter('amTimeAgo')(item.entity.created_at);

                if (category.timestamp != timestamp) {
                    if (category.timestamp) {
                        feed.push(category);
                    }

                    category = {
                        timestamp: timestamp,
                        created_at: item.entity.created_at,
                        items: []
                    };
                }

                category.items.push(item);
            });

            if (category.timestamp) {
                feed.push(category);
            }

            return feed;
        }

        function getActivityPermission(item) {
            var permissions = {
                action_campaign: 'action_campaigns.view',
                interaction: 'interactions.view',
                form_entry: 'form_entries.view',
                sent_mail: 'accounts.view',
                file: 'files.download',
                order: 'orders.view',
                relation: 'accounts.view'
            };

            return permissions[item.type];
        }

        function fileHref(file) {
            return config.path.api('files/' + file.id + '/download/' + file.client_filename);
        }

        function openInteractionActivity(interaction) {
            InteractionService.interaction(interaction.id).then(function (interaction) {
                openInteractionModal(interaction, 'lg');
            });
        }

        function openSentMailActivity(sentMail) {
            MailService.sentMail(sentMail.id).then(function (entry) {
                openSentMailModal(entry, 'lg');
            });
        }

        function setMailSubscription() {
            if ($scope.accountForms.contact_details.unsubscribe_from_mails) {
                $scope.accountForms.contact_details.date_unsubscribed_from_mails = moment().format('YYYY-MM-DD');
            } else {
                $scope.accountForms.contact_details.date_unsubscribed_from_mails = null;
            }
        }

        function openInteractionModal(interaction, size) {
            interaction.account = account;

            modal.open('interaction', size, { interaction: interaction });
        }

        function openSentMailModal(sentMail, size) {
            sentMail.account = account;
            modal.open('sentMail', size, { sentMail: sentMail });
        }

        function openDeleteRelationModal(relation, size) {
            modal.open('confirm', size, {
                title: 'Are you sure?',
                message: 'Delete this relation?'
            }).result.then(function (data) {
                deleteRelation(relation.id);
            });
        }

        function openDeleteVariableModal(variable, size) {
            modal.open('confirm', size, {
                title: 'Are you sure?',
                message: 'Delete this variable?'
            }).result.then(function (data) {
                deleteVariable(variable.id);
            });
        }

        function deleteRelation(id) {
            RelationService.deleteRelation(id).then(function (data) {
                getAccount();
                getRelations();
            });
        }

        function makeRelationPrimaryOrganisation(relation) {
            RelationService.makeRelationPrimaryOrganisation(relation.id).then(function (data) {
                getAccount();
            });
        }

        function deleteVariable(id) {
            VariableService.deleteVariable(id).then(function (data) {
                getVariables();
            });
        }

        function getAccount() {
            AccountService.account(account.id).then(function (account) {
                $scope.account = account;
            });
        }

        function getRelations() {
            AccountService.account.relations($scope.account.id).then(function (relations) {
                angular.forEach(relations, function (relation) {
                    relation.related_account.root_groups = GroupService.getRootGroups(relation.related_account.groups);
                });

                $scope.relations = relations;
            });
        }

        function addRelation() {
            RelationService.addRelation($scope.relation).then(function () {
                resetRelation();
                getActivity();
                getRelations();

                $scope.formStates.showAddRelationForm = false;
            });
        }

        function resetRelation() {
            $scope.relation = {
                account: $scope.account
            };
        }

        function getVariables() {
            AccountService.account.variables(account.id).then(function (variables) {
                $scope.variables = variables;
            });

            VariableService.keys('account').then(function (keys) {
                $scope.variableKeys = keys;
            });
        }

        function variablesWhereKey(variables, key) {
            if (!variables) {
                return [];
            }

            var result = [];

            for (var i = 0; i < variables.length; i++) {
                if (variables[i].key.name === key.name) {
                    result.push(variables[i]);
                }
            }

            return result;
        }

        function addVariable() {
            VariableService.addVariable($scope.variable).then(function () {
                getVariables();

                $scope.formStates.showAddVariableForm = false;
            });
        }

        function showAddVariableForm(key) {
            resetForms();

            $scope.variable = {
                key: key,
                value: null,
                model_id: $scope.account.id
            };

            $scope.formStates = {};
            $scope.formStates.showAddVariableForm = key.name;
        }

        function showEditVariableForm(variable) {
            resetForms();

            $scope.variable = angular.copy(variable);

            $scope.formStates = {};
            $scope.formStates.showEditVariableForm = variable.id;
        }

        function hideEditVariableForm(variable) {
            $scope.formStates = {};
        }

        function editVariable(variable) {
            VariableService.editVariable(variable).then(function () {
                getVariables();

                $scope.formStates.showEditVariableForm = false;
            });
        }

        function editAccount(form) {
            var data = {
                id: $scope.account.id
            };

            copyKeys($scope.accountFields[form], $scope.accountForms[form], data);

            AccountService.editAccount(data).then(function () {
                $state.reload();
            });
        }

        function showForm(name, value) {
            resetForms();

            value = value || true;

            $scope.formStates = {};
            $scope.formStates['show' + string.ucfirst(name) + 'Form'] = value;
        }

        function hideForm(name) {
            resetForms();

            $scope.formStates = {};
        }

        function resetForms() {
            $rootScope.errors = null;

            for (var form in $scope.accountFields) {
                resetForm(form);
            }

            $scope.accountForms.contact_details.unsubscribe_from_mails = $scope.accountForms.contact_details.date_unsubscribed_from_mails !== null;
        }

        function resetForm(form) {
            $scope.accountForms[form] = {};

            copyKeys($scope.accountFields[form], $scope.account, $scope.accountForms[form]);
        }

        function copyKeys(keys, fromObj, toObj) {
            angular.forEach(keys, function (key) {
                toObj[key] = fromObj[key];
            });
        }

        function watchAddress(prefix) {
            prefix = prefix || '';

            var timeout;

            $scope.$watchGroup(['accountForms.' + prefix + 'address.' + prefix + 'zip_code', 'accountForms.' + prefix + 'address.' + prefix + 'house_number'], function (newValues, oldValues) {
                if (angular.equals(newValues, oldValues)) {
                    return;
                }

                $timeout.cancel(timeout);

                timeout = $timeout(function () {
                    AccountService.getGeoLocation({ zip_code: newValues[0], house_number: newValues[1] }).then(function (data) {
                        $scope.accountForms[prefix + 'address'][prefix + 'street'] = data.street;
                        $scope.accountForms[prefix + 'address'][prefix + 'city'] = data.city;
                    });
                }, 250);
            });
        }
    }
})(angular);
