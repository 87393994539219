(function (angular) {
    angular
        .module('one.admin')
        .directive('formValueEditor', formValueEditor);

    formValueEditor.$inject = ['config'];

    function formValueEditor(config) {
        return {
            restrict: 'EA',
            scope: {
                value: '=',
                field: '=',
                nullable: '=',
                disabled: '='
            },
            controller: ['$scope', 'AccountService', 'ActionCampaignService', 'ActionProjectService', 'DiscountCodeService', 'FormService', 'GroupService', 'ProductService', 'PurchaseService', 'SiteService', function ($scope, AccountService, ActionCampaignService, ActionProjectService, DiscountCodeService, FormService, GroupService, ProductService, PurchaseService, SiteService) {
                $scope.model = {
                    value: $scope.value
                };

                $scope.$watch('model.value', function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        $scope.value = newValue;
                    }
                });

                $scope.$watch('value', function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        $scope.model.value = newValue;
                    }
                });

                $scope.viewPath = function () {
                    var type = $scope.field.type ? $scope.field.type.name : $scope.field.form_field_type_name;

                    return config.path.template('forms.values.editors.' + type.replace(/\-/g, '_'));
                };

                $scope.getOptions = function (scope) {
                    return function (params) {
                        params = angular.merge({}, params, { scope: scope });

                        return FormService.fieldOptions($scope.field.id || $scope.field.type.name, params);
                    }
                };

                $scope.AccountService = AccountService;
                $scope.ActionCampaignService = ActionCampaignService;
                $scope.ActionProjectService = ActionProjectService;
                $scope.DiscountCodeService = DiscountCodeService;
                $scope.FormService = FormService;
                $scope.GroupService = GroupService;
                $scope.ProductService = ProductService;
                $scope.SiteService = SiteService;

                var controllers = {
                    'sub_group': function () {
                        $scope.getGroups = function (params) {
                            params = params || {};
                            params.filters = angular.merge({}, $scope.field.rules.filters.groups, params.filters);

                            return GroupService.groups(params);
                        }
                    },
                    'purchase': function () {
                        $scope.purchaseSources = [];
                        $scope.purchaseDestinations = [];

                        $scope.showingSourceAndDestination = function () {
                            return $scope.purchaseSources.length > 0 || $scope.purchaseDestinations.length > 0;
                        };

                        PurchaseService.purchaseSources().then(function (purchaseSources) {
                            $scope.purchaseSources = purchaseSources;
                        });

                        PurchaseService.purchaseDestinations().then(function (purchaseDestinations) {
                            $scope.purchaseDestinations = purchaseDestinations;
                        });
                    },
                };

                var controller = controllers[$scope.field.type.name];

                if (controller) {
                    controller();
                }
            }],
            template: function () {
                return '<span ng-include="viewPath()"></span>';
            }
        };
    }
}(angular));
