(function (angular) {
    angular
        .module('one.admin')
        .controller('EditDiscountCodeController', EditDiscountCodeController);

    EditDiscountCodeController.$inject = ['$scope', '$state', 'DiscountCodeService', 'notification', 'discountCode', 'products'];

    function EditDiscountCodeController($scope, $state, DiscountCodeService, notification, discountCode, products) {
        $scope.discountCode = discountCode;
        $scope.products = products;

        $scope.editDiscountCode = editDiscountCode;

        function editDiscountCode(data) {
            DiscountCodeService.editDiscountCode(data).then(function (data) {
                $state.transitionTo('sales.discountCodes.discountCode', {discountCodeId: discountCode.id});
            });
        }
    }
})(angular);
