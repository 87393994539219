(function (angular) {
    angular
        .module('one.admin')
        .service('DiscountCodeService', DiscountCodeService);

    DiscountCodeService.$inject = ['$filter', 'http', 'modal', 'translateFilter'];

    function DiscountCodeService($filter, http, modal, translateFilter) {
        var types = [
            { label: translateFilter('discount_code_types.absolute'), value: 'absolute' },
            { label: translateFilter('discount_code_types.percentage'), value: 'percentage' }
        ];

        function discountCode(id) {
            return http.get('discount_codes/' + id);
        }

        discountCode.apply = function (id, params) {
            return http.get('discount_codes/' + id + '/apply', params);
        };

        discountCode.purchases = function (id, params) {
            return http.get('discount_codes/' + id + '/purchases', params);
        };

        function discountCodes(params) {
            return http.get('discount_codes', params);
        }

        function addDiscountCode(data) {
            return http.post('discount_codes/add', data);
        }

        function deleteDiscountCode(id) {
            return http.post('discount_codes/' + id + '/delete');
        }

        function editDiscountCode(data) {
            return http.post('discount_codes/' + data.id + '/edit', data);
        }

        function duplicateDiscountCode(data) {
            return http.post('discount_codes/' + data.id + '/duplicate', data);
        }

        function openDeleteDiscountCodeModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this discount code?'
            }).result.then(function (data) {
                return deleteDiscountCode(id).then(callback);
            });
        }

        function formatDiscountCodeTitle(discountCode) {
            return discountCode.code + ' <span class="text-muted">(' + (discountCode.type == 'absolute' ? $filter('money')(discountCode.value, '&euro; ') : (discountCode.value + '%')) + ')</span>';
        }

        return {
            types: types,
            discountCode: discountCode,
            discountCodes: discountCodes,
            addDiscountCode: addDiscountCode,
            deleteDiscountCode: deleteDiscountCode,
            duplicateDiscountCode: duplicateDiscountCode,
            editDiscountCode: editDiscountCode,
            openDeleteDiscountCodeModal: openDeleteDiscountCodeModal,
            formatDiscountCodeTitle: formatDiscountCodeTitle
        };
    }
})(angular);
