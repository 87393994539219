(function (angular) {
    angular
        .module('one.admin')
        .component('formEntryOverview', {
            controller: controller,
            bindings: {
                formEntry: '<',
                state: '<'
            },
            templateUrl: ['config', function (config) {
                return config.path.template('forms.entries.editor.overview');
            }]
        });

    controller.$inject = ['config', 'AccountService', 'FormService'];

    function controller(config, AccountService, FormService) {

    }
})(angular);
