(function (angular) {
    angular
        .module('one.admin')
        .directive('findOrCreateAccountForm', findOrCreateAccountFormDirective);

    findOrCreateAccountFormDirective.$inject = ['config'];

    function findOrCreateAccountFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                account: '=',
                focus: '='
            },
            controller: ['$scope', '$timeout', 'AccountService', 'DuplicateService', function ($scope, $timeout, AccountService, DuplicateService) {
                var dedupTimer;

                $scope.formState = {};
                $scope.types = [];

                AccountService.types().then(function (types) {
                    $scope.types = types;
                });

                $scope.$watch('account', function (account) {
                    $timeout.cancel(dedupTimer);

                    dedupTimer = $timeout(function () {
                        if (account && !account.id) {
                            DuplicateService.findDuplicatesForAccount(account).then(function (result) {
                                $scope.duplicates = result;
                            });
                        }
                    }, 250);
                }, true);

                $scope.getAccounts = AccountService.accounts;
                $scope.formatAccountTitle = AccountService.formatAccountTitle;
                $scope.setType = setType;
                $scope.reset = reset;

                function setType(type) {
                    reset();

                    $scope.account = {
                        type: type
                    };

                    $scope.formState.type = type;
                }

                function reset() {
                    $scope.account = null;
                    $scope.formState.type = null;
                }

            }],
            templateUrl: config.path.template('accounts.account.find_or_create_account_form')
        };
    }
}(angular));
