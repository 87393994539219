(function (angular) {
    angular
        .module('one.admin')
        .controller('ScheduledExportsController', ScheduledExportsController);

    ScheduledExportsController.$inject = ['$scope', '$filter', '$interval', '$timeout', '$state', '$window', 'session', 'AuthService', 'ExportService', 'config', 'dataframe', 'modal', 'notification'];

    function ScheduledExportsController($scope, $filter, $interval, $timeout, $state, $window, session, AuthService, ExportService, config, dataframe, modal, notification) {
        $scope.exports = dataframe.init({
            externalData: function (params) {
                params = params || {};
                params.filters = params.filters || {};
                params.filters.scheduled = [true];

                return ExportService.exports(params);
            },
            cols: [
                {
                    title: 'Export',
                    name: 'title',
                    display: function (row) {
                        return '<h4>{{ row.title }} <small class="text-muted" ng-if="(\'export_scopes.\' + row.type | translate) != row.title">({{ \'export_scopes.\' + row.type | translate }})</small></h4> <small>' + ExportService.formatFilters(row) + '</small>';
                    },
                    sortable: true,
                    weight: 3
                },
                {
                    title: 'Requested',
                    name: 'created_at',
                    display: function (row) {
                        return '<small>{{ row.created_at | formatDate: \'medium\' }}</small>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Scheduled',
                    name: 'scheduled_at',
                    display: function (row) {
                        return '<small>{{ row.scheduled_at | formatDate: \'medium\' }}</small>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Requested by',
                    name: 'user.full_name',
                    display: function (row) {
                        return '<small class="one-user" ng-if="row.user"><i class="md-icon">account_circle</i> {{ row.user.first_name }}</small>'
                    },
                    weight: 1
                }
            ],
            rowOptions: [
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteExportModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('exports.delete') && row.deletable;
                    }
                }
            ],
            state: session.get('data.dataframes.scheduled_exports', {
                sort: { scheduled_at: 'asc' }
            })
        });

        session.sync('data.dataframes.scheduled_exports', $scope.exports.state);

        $scope.openDeleteExportModal = openDeleteExportModal;

        function openDeleteExportModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this export?' }).result.then(function (data) {
                deleteExport(id);
            });
        }

        function deleteExport(id) {
            ExportService.deleteExport(id).then(function (data) {
                $scope.exports.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }
    }
})(angular);
