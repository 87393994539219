(function (angular) {
    angular.module('one.admin').controller('AddSliderModuleModalController', AddSliderModuleModalController);

    AddSliderModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddSliderModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                pager: true,
                crop_mobile: true,
                timeout: 5,
                width: 16,
                height: 9,
                slides: [
                    {
                        content: null,
                        alt_text: '',
                        position: 1
                    }
                ]
            }
        };

        angular.merge($scope, params);

        $scope.addSliderModule = addSliderModule;
        $scope.cancel = cancel;

        function addSliderModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
