(function (angular) {
    angular
        .module('one.admin')
        .directive('oneCodeMirrorInput', codeMirrorInputDirective);

    codeMirrorInputDirective.$inject = ['config'];

    function codeMirrorInputDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.options = angular.merge(config.codeMirrorOptions, {
                mode: $scope.mode,
                onLoad: function (editor) {
                    $scope.insert = function (string, $event) {
                        var doc = editor.getDoc();
                        var cursor = doc.getCursor('start')

                        var variable = '{{ ' + string + ' }}';

                        doc.replaceSelection(variable);

                        cursor.ch += variable.length;

                        editor.setCursor(cursor);
                        editor.focus();
                    };
                }
            });
        }

        return {
            restrict: 'AE',
            scope: {
                model: '=',
                variables: '=',
                mode: '@'
            },
            controller: controller,
            templateUrl: config.path.template('common.inputs.code_mirror')
        };
    }
})(angular);
