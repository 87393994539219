(function (angular) {
    angular
        .module('one.admin')
        .directive('listLimiter', listLimiterDirective);

    listLimiterDirective.$inject = ['$compile'];

    /**
     * Display a short list and a button to expand it if the length exceeds a limit.
     */
    function listLimiterDirective($compile) {
        return {
            restrict: 'EA',
            scope: {
                list: '&',
                limit: '=',
                delimiter: '='
            },
            controller: ['$scope', function ($scope) {
                var list = $scope.list();
                var defaultLimit = 3;

                $scope.items = list.slice(0, $scope.limit || defaultLimit);
                $scope.remaining = list.length - ($scope.limit || defaultLimit);
                $scope.hasToggle = $scope.remaining > 0;

                $scope.toggle = toggle;

                function toggle($event) {
                    $event.stopPropagation();

                    if ($scope.remaining > 0) {
                        $scope.items = list;
                    } else {
                        $scope.items = list.slice(0, $scope.limit || defaultLimit);
                    }

                    $scope.remaining = list.length - $scope.items.length;

                    $scope.render();
                }
            }],
            link: function (scope, element) {
                render();

                scope.render = render;

                function render() {
                    element.empty();

                    var output = '';

                    angular.forEach(scope.items, function (item, i) {
                        output += '<span>' + item + '</span>';

                        if (i + 1 < scope.items.length && scope.delimiter) {
                            output += '<span>' + scope.delimiter + '</span>';
                        }
                    });

                    output += ' <span class="text-muted" ng-if="hasToggle && remaining > 0">... </span>';
                    output += '<a ng-click="toggle($event)" ng-if="hasToggle" class="text-nowrap">';
                    output += '<span ng-if="remaining > 0">+ {{ remaining }}</span>';
                    output += '<span ng-if="remaining == 0">({{ \'general.less\' | translate }})</span>';
                    output += '</a>';

                    element.append($compile(output)(scope));
                }
            }
        };
    }
}(angular));
