(function (angular) {
    angular
        .module('one.admin')
        .controller('EditCampaignController', EditCampaignController);

    EditCampaignController.$inject = ['$scope', '$state', 'CampaignService', 'notification', 'campaign', 'sites'];

    function EditCampaignController($scope, $state, CampaignService, notification, campaign, sites) {
        $scope.campaign = campaign;
        $scope.sites = sites;

        $scope.editCampaign = editCampaign;

        function editCampaign(data) {
            CampaignService.editCampaign(data).then(function (data) {
                $state.transitionTo('mail.campaigns.campaign', {campaignId: campaign.id});
            });
        }
    }
})(angular);
