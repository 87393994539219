(function (angular) {
    angular
        .module('one.admin')
        .controller('EditFormController', EditFormController);

    EditFormController.$inject = ['$scope', '$state', 'FormService', 'form'];

    function EditFormController($scope, $state, FormService, form) {
        $scope.form = form;

        $scope.editForm = editForm;

        function editForm() {
            FormService.editForm({
                id: $scope.form.id,
                label: $scope.form.label
            }).then(function () {
                $state.transitionTo('crm.forms.formOverview', { formId: form.id });
            });
        }
    }
})(angular);
