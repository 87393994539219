(function (angular) {
    angular
        .module('one.admin')
        .controller('UserController', UserController);

    UserController.$inject = ['$scope', 'dataframe', 'notification', 'session', 'AuthService', 'user'];

    function UserController($scope, dataframe, notification, session, AuthService, user) {
        $scope.user = user;
        $scope.project = session.get('project');
        $scope.organisation = $scope.project.organisation;
    }
})(angular);
