(function (angular) {
    angular
        .module('one.admin')
        .controller('PaymentReferenceConfigsController', PaymentReferenceConfigsController);

    PaymentReferenceConfigsController.$inject = ['$scope', 'AuthService', 'PaymentReferenceService', 'config', 'dataframe', 'modal', 'notification', '$filter', 'session'];

    function PaymentReferenceConfigsController($scope, AuthService, PaymentReferenceService, config, dataframe, modal, notification, $filter, session) {
        $scope.paymentReferenceConfigs = dataframe.init({
            externalData: PaymentReferenceService.paymentReferenceConfigs,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4>{{ row.title }}</h4>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Product',
                    name: 'product',
                    display: function (row) {
                        return '{{ row.product.title }}';
                    },
                    weight: 1
                },
                {
                    title: 'Source',
                    name: 'purchase_source',
                    display: function (row) {
                        return '{{ row.purchase_source ? row.purchase_source.title : \'\' }}';
                    },
                    weight: 1
                },
                {
                    title: 'Destination',
                    name: 'purchase_destination',
                    display: function (row) {
                        return '{{ row.purchase_destination ? row.purchase_destination.title : \'\' }}';
                    },
                    weight: 1
                },
                {
                    title: 'Creation date',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    },
                    sortable: true
                }
            ],
            rowClick: function (row) {
                if (AuthService.userCanAccess('payment_reference_configs.export')) {
                    openExportPaymentReferencesModal(row, 'xl');
                }
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'finance.paymentReferences.editPaymentReferenceConfig({paymentReferenceConfigId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('payment_reference_configs.edit');
                    }
                },
                {
                    title: 'Export',
                    icon: 'cloud_download',
                    click: function (row) {
                        openExportPaymentReferencesModal(row, 'xl');
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('payment_reference_configs.export');
                    }
                }
            ],
            state: session.get('data.dataframes.paymentReferenceConfigs', {
                sort: { created_at: 'desc' }
            })
        });

        session.sync('data.dataframes.paymentReferenceConfigs', $scope.paymentReferenceConfigs.state);

        function openExportPaymentReferencesModal(paymentReferenceConfig, size) {
            modal.open('exportPaymentReferences', size, { paymentReferenceConfig: paymentReferenceConfig });
        }
    }
})(angular);
