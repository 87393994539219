(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderFilter', segmentBuilderFilterDirective);

    segmentBuilderFilterDirective.$inject = ['config'];

    function segmentBuilderFilterDirective(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilderFilterBlock',
            scope: {
                filterables: '=',
                filter: '=',
                filterBlock: '=',
                position: '=',
                segment: '='
            },
            controller: ['$scope', 'segmentBuilder', function ($scope, segmentBuilder) {
                $scope.isIntervalOperator = false;
                $scope.isOperatorWithoutValue = false;

                $scope.intervalDirections = segmentBuilder.intervalDirections;
                $scope.intervalTypes = segmentBuilder.intervalTypes;

                $scope.selectedField = $scope.filterables.getField($scope.filter);

                $scope.$watch('selectedField', function (newField, previousField) {
                    if (newField != previousField) {
                        // Reset the filter when a new field is selected
                        resetFilter(newField, previousField);
                    }
                });

                $scope.$watch('filter.operator', function (newOperator, previousOperator) {
                    $scope.isOperatorWithoutValue = segmentBuilder.isOperatorWithoutValue($scope.filter.operator);
                    $scope.isIntervalOperator = segmentBuilder.isIntervalOperator($scope.filter.operator);

                    if (newOperator != previousOperator) {
                        resetOperator(newOperator, previousOperator);
                    }
                });

                $scope.operators = segmentBuilder.comparisonOperators;
                $scope.moveUpFilter = segmentBuilder.moveUpFilter;
                $scope.moveDownFilter = segmentBuilder.moveDownFilter;
                $scope.deleteFilter = segmentBuilder.deleteFilter;

                function resetOperator(newOperator, previousOperator) {
                    var newOperatorIsInterval = segmentBuilder.isIntervalOperator(newOperator);
                    var previousOperatorIsInterval = segmentBuilder.isIntervalOperator(previousOperator);
                    var newOperatorIsWithoutValue = segmentBuilder.isOperatorWithoutValue(newOperator);

                    if (newOperatorIsWithoutValue || (!newOperatorIsInterval && (previousOperatorIsInterval || !previousOperator))) {
                        $scope.filter.value = null;
                    } else if (newOperatorIsInterval && !previousOperatorIsInterval) {
                        $scope.filter.value = {
                            amount: null,
                            type: null,
                            direction: null
                        };
                    }
                }

                /**
                 * Resets the value of selectedField based on the field that is selected.
                 * Also resets the operator and value if necessary.
                 * @return {undefined}
                 */
                function resetFilter(newField, previousField) {
                    $scope.filter.field = newField.name;

                    if (!$scope.filter.operator || !operatorIsAllowed($scope.filter.operator)) {
                        // If the current operator is not allowed, reset is to the first in the list of available operators
                        $scope.filter.operator = $scope.operators[newField.type][0].value;
                    }

                    if ((newField.options && newField.options.length > 0) || newField.externalOptions) {
                        // Selected field has options, reset value
                        $scope.filter.value = null;
                    } else if (previousField && previousField.type != newField.type) {
                        // Previous field had different data type or options, reset value
                        $scope.filter.value = null;
                    }
                }

                /**
                 * Checks if this operator occurs in the list of available operators.
                 * @param  {string} operator
                 * @return {boolean}
                 */
                function operatorIsAllowed(operator) {
                    var allowed = false;

                    angular.forEach($scope.operators[$scope.selectedField.type], function (comparisonOperator) {
                        if (comparisonOperator.value == operator) {
                            allowed = true;
                            return;
                        }
                    });

                    return allowed;
                }
            }],
            templateUrl: config.path.template('segments.builder.filter')
        };
    }
}(angular));
