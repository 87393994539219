(function(angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeFilter', oneDataframeFilterDirective);

    oneDataframeFilterDirective.$inject = ['config'];

    function oneDataframeFilterDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '=',
                filters: '=',
                externalFilters: '=',
                filterFormatFn: '=',
                name: '=',
                label: '='
            },
            link: function(scope, element) {
                element.addClass('one-dataframe-filter');
            },
            controller: ['$scope', '$filter', '$timeout', function($scope, $filter, $timeout) {
                var timeout;

                $scope.search = {};
                $scope.filterList = [];
                $scope.activeFilterList = [];

                if (!$scope.dataframe.state.filters[$scope.name]) {
                    $scope.dataframe.state.filters[$scope.name] = [];
                }

                if ($scope.dataframe.state.filters[$scope.name].length > 0) {
                    if ($scope.externalFilters) {
                        var params = {
                            filters: {}
                        };

                        params.filters[$scope.name] = $scope.dataframe.state.filters[$scope.name];

                        $scope.externalFilters(params).then(function(filters) {
                            if (filters.length == $scope.dataframe.state.filters[$scope.name].length) {
                                $scope.activeFilterList = filters;
                            } else {
                                $scope.dataframe.state.filters[$scope.name] = [];
                            }
                        });
                    } else {
                        // Wait until the list of filters has been fetched by the parent component
                        var unregister = $scope.$watch('filters', function(filters) {
                            if (filters) {
                                angular.forEach(filters, function(filter) {
                                    if ($scope.dataframe.state.filters[$scope.name].indexOf(filter.value) > -1) {
                                        $scope.activeFilterList.push(filter);
                                    }
                                });

                                // Only initialize once
                                unregister();
                            }
                        }, true);
                    }
                }

                $scope.getFilters = getFilters;
                $scope.toggleFilter = toggleFilter;
                $scope.isActiveFilter = isActiveFilter;
                $scope.emptySearch = emptySearch;

                function getFilters() {
                    if ($scope.externalFilters) {
                        if ($scope.search.label) {
                            $scope.search.loading = true;

                            $timeout.cancel(timeout);

                            timeout = $timeout(function() {
                                $scope.externalFilters({
                                    search: $scope.search.label
                                }).then(function(filters) {
                                    $scope.filterList = filters;

                                    $scope.search.loading = false;
                                });
                            }, 250);
                        }
                    } else {
                        $scope.filterList = $filter('filter')($scope.filters, $scope.search.label);
                    }
                }

                function toggleFilter(filter, $event) {
                    $event.stopPropagation();

                    if (!$scope.dataframe.state.filters[$scope.name]) {
                        $scope.dataframe.state.filters[$scope.name] = [];
                    }

                    var filters = $scope.dataframe.state.filters[$scope.name];

                    // Find filter in active filters
                    var index = filters.indexOf(filter.value);

                    if (index == -1) {
                        // Add filter
                        filters.push(filter.value);

                        $scope.activeFilterList.push(filter);
                    } else {
                        // Remove filter
                        filters.splice(index, 1);

                        var activeIndex = activeFilterIndex(filter.value);

                        if (activeIndex > -1) {
                            $scope.activeFilterList.splice(activeIndex, 1);
                        }
                    }
                }

                function isActiveFilter(value) {
                    return activeFilterIndex(value) > -1;
                }

                function activeFilterIndex(value) {
                    var index = -1;

                    angular.forEach($scope.activeFilterList, function(activeFilter, i) {
                        if (activeFilter.value == value) {
                            index = i;
                        }
                    });

                    return index;
                }

                function emptySearch() {
                    $scope.search.label = '';

                    if ($scope.externalFilters) {
                        $scope.filterList = [];
                    } else {
                        getFilters();
                    }

                    if (!$scope.dataframe.state.filters[$scope.name]) {
                        $scope.activeFilterList = [];
                    }
                }
            }],
            templateUrl: config.path.template('common.dataframe.filter')
        };
    }
})(angular);
