(function (angular) {
    angular
        .module('one.admin')
        .controller('ConfirmLeavePageModalController', ConfirmLeavePageModalController);

    ConfirmLeavePageModalController.$inject = ['$scope', '$uibModalInstance', 'params'];

    function ConfirmLeavePageModalController($scope, $uibModalInstance, params) {
        angular.merge($scope, params);

        $scope.cancel = cancel;
        $scope.save = save;
        $scope.discard = discard;

        function cancel() {
            $uibModalInstance.close();
        }

        function save() {
            $uibModalInstance.close({
                save: true
            });
        }

        function discard() {
            $uibModalInstance.close({
                discard: true
            });
        }
    }
})(angular);
