(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderSegmentFilterBlock', segmentBuilderSegmentFilterBlock);

    segmentBuilderSegmentFilterBlock.$inject = ['config'];

    function segmentBuilderSegmentFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                segment: '='
            },
            controller: ['$scope', 'SegmentService', 'segmentBuilder', function ($scope, SegmentService, segmentBuilder) {
                $scope.filterables = null;

                $scope.status = { loading: false };

                $scope.selected = {};

                $scope.segments = $scope.segment.filtered_models.segment || {};

                $scope.compareValues = compareValues;
                $scope.includeSegment = includeSegment;
                $scope.excludeSegment = excludeSegment;
                $scope.getSegments = getSegments;
                $scope.formatSegmentTitle = SegmentService.formatSegmentTitle;
                $scope.deleteFilterBlock = deleteFilterBlock;

                function compareValues(item, value) {
                    return item.filters[0].value == value;
                }

                function includeSegment(segment) {
                    if (segment) {
                        $scope.segments[segment.id] = segment;

                        $scope.segment.filters.segment.filters.push(segmentBuilder.hasFilter({
                            relation: 'segments',
                            boolean: 'or',
                            filters: [
                                segmentBuilder.comparisonFilter({
                                    field: 'id',
                                    operator: '=',
                                    value: segment.id
                                })
                            ]
                        }));
                    }

                    $scope.selected.includeSegment = null;
                }

                function excludeSegment(segment) {
                    if (segment) {
                        $scope.segments[segment.id] = segment;

                        $scope.segment.filters.not_segment.filters.push(segmentBuilder.hasFilter({
                            relation: 'segments',
                            boolean: 'or',
                            operator: '=',
                            count: 0,
                            filters: [
                                segmentBuilder.comparisonFilter({
                                    field: 'id',
                                    operator: '=',
                                    value: segment.id
                                })
                            ]
                        }));
                    }

                    $scope.selected.excludeSegment = null;
                }

                function getSegments(params) {
                    return segmentBuilder.filterables.segment(params).then(function (filterables) {
                        return filterables.segments;
                    });
                }

                /**
                 * Resets the filter block and filterables.
                 */
                function deleteFilterBlock() {
                    $scope.segment.filters.segment = null;
                    $scope.segment.filters.not_segment = null;
                    $scope.filterables = null;
                }
            }],
            templateUrl: config.path.template('segments.builder.segment_filter_block')
        };
    }
}(angular));
