(function (angular) {
    angular
        .module('one.admin')
        .controller('DiffSegmentModalController', DiffSegmentModalController);

    DiffSegmentModalController.$inject = ['$scope', '$uibModalInstance', '$timeout', 'SegmentService', 'params'];

    function DiffSegmentModalController($scope, $uibModalInstance, $timeout, SegmentService, params) {
        angular.extend($scope, params);

        SegmentService.diffSegment($scope.segment).then(function (diff) {
            $scope.diff = diff;
        }, function () {
            // Wait a bit before closing modal to prevent flickering
            $timeout(cancel, 500);
        });

        $scope.editSegment = editSegment;
        $scope.cancel = cancel;

        function editSegment() {
            SegmentService.editSegment($scope.segment).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
