(function (angular) {
    angular
        .module('one.admin')
        .controller('ImportsController', ImportsController);

    ImportsController.$inject = ['$scope', '$filter', '$interval', '$timeout', '$state', '$window', 'session', 'AuthService', 'ImportService', 'config', 'dataframe', 'modal', 'notification'];

    function ImportsController($scope, $filter, $interval, $timeout, $state, $window, session, AuthService, ImportService, config, dataframe, modal, notification) {
        $scope.imports = dataframe.init({
            externalData: ImportService.imports,
            cols: [
                {
                    title: 'File',
                    name: 'client_filename',
                    display: function (row) {
                        return '<h4>{{ row.file.client_filename }}</h4>';
                    },
                    weight: 2
                },
                {
                    title: 'Type',
                    name: 'type',
                    display: function (row) {
                        return '{{ \'general.\' + row.type + \'s\' | translate }}';
                    },
                    weight: 1
                },
                {
                    title: 'Status',
                    name: 'status',
                    display: function (row) {
                        var className = row.status == 'imported' ? 'text-success' : (['failed', 'refused'].indexOf(row.status) > -1 ? 'text-danger' : 'text-muted');

                        return '<span class="' + className + '">{{ \'import_statuses.\' + row.status | translate }}</span>';
                    },
                    weight: 1
                },
                {
                    title: 'Records',
                    name: 'record_count',
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Uploaded at',
                    name: 'created_at',
                    weight: 2,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'short');
                    },
                    sortable: true
                },
                {
                    title: 'Uploaded by',
                    name: 'user.full_name',
                    weight: 2
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('imports.view')) {
                    return 'imports.import({importId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        ImportService.openDeleteImportModal(row.id, function () {
                            $scope.imports.getData();
                        });
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('imports.delete') && row.deletable;
                    }
                }
            ],
            state: session.get('data.dataframes.imports', {
                sort: { created_at: 'desc' }
            })
        });

        session.sync('data.dataframes.imports', $scope.imports.state);
    }
})(angular);
