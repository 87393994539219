(function (angular) {
    angular
        .module('one.admin')
        .directive('requirePermission', requirePermissionDirective);

    requirePermissionDirective.$inject = ['AuthService'];

    function requirePermissionDirective(AuthService) {
        return {
            restrict: 'AE',
            scope: {
                requirePermission: '@',
            },
            link: function (scope, element, attrs, model) {
                if (!AuthService.userCanAccess(scope.requirePermission)) {
                    element.hide();
                }
            }
        };
    }
})(angular);
