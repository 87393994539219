(function (angular) {
    angular
        .module('one.admin')
        .controller('PageNavsController', PageNavsController);

    PageNavsController.$inject = ['$scope', 'session', 'pageNavs', 'languages'];

    function PageNavsController($scope, session, pageNavs, languages) {
        var currentSite = session.get('data.current_site');

        $scope.site = currentSite;

        $scope.pageNavs = pageNavs.filter(function (pageNav) {
            return pageNav.site_id == currentSite.id;
        });

        var language;

        for (var i = 0; i < languages.length; i++) {
            language = languages[i];
            if (language.name == currentSite.language_name) {
                languages.splice(i, 1);
                languages.unshift(language);
                break;
            }
        }

        $scope.languages = languages;
    }
})(angular);
