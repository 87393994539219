(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFormModalController', AddFormModalController);

    AddFormModalController.$inject = ['$scope', '$uibModalInstance', 'FormService', 'params'];

    function AddFormModalController($scope, $uibModalInstance, FormService, params) {
        $scope.form = {};

        angular.extend($scope, params);

        $scope.add = add;
        $scope.cancel = cancel;

        function add() {
            FormService.addForm($scope.form).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
