(function (angular) {
    angular
        .module('one.admin')
        .controller('TransactionalMailsController', TransactionalMailsController);

    TransactionalMailsController.$inject = ['$scope', 'AuthService', 'TagService', 'TransactionalMailService', 'config', 'dataframe', 'session'];

    function TransactionalMailsController($scope, AuthService, TagService, TransactionalMailService, config, dataframe, session) {
        $scope.transactionalMails = dataframe.init({
            externalData: TransactionalMailService.transactionalMails,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">mail_outline</i> {{ row.title }}</h4>';
                    },
                    weight: 4,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Forms',
                    weight: 1,
                    display: function (row) {
                        return '{{ row.forms.length | number }}';
                    },
                    weight: 1
                },
                {
                    title: 'Delivery rate',
                    name: 'delivery_count',
                    display: function (row) {
                        var rate = Math.round(row.delivery_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        return '<span uib-tooltip="{{ \'general.delivered\' | translate }}: ' + row.delivery_count + ', {{ \'general.bounced\' | translate }}: ' + row.failed_count + '">' +
                            rate + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 1.5
                },
                {
                    title: 'Open rate',
                    name: 'open_count',
                    display: function (row) {
                        var rate = Math.round(row.open_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        return '<span uib-tooltip="{{ \'general.opens\' | translate }}: ' + row.open_count + '">' +
                            rate + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 1.5
                },
                {
                    title: 'Open rate',
                    name: 'open_count',
                    display: function (row) {
                        var rate = Math.round(row.open_rate * 1000) / 10;

                        if (rate < 100) {
                            rate = rate.toFixed(1);
                        }

                        return '<span uib-tooltip="{{ \'general.opens\' | translate }}: ' + row.open_count + '">' +
                            rate + '% ' +
                            '<i class="md-icon text-muted">info_outline</i>' +
                            '</span>';
                    },
                    weight: 1.5
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('transactional_mails.view')) {
                    return 'mail.transactionalMails.transactionalMail({transactionalMailId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'mail.transactionalMails.editTransactionalMail({transactionalMailId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('transactional_mails.edit');
                    }
                },
                {
                    title: 'Preview',
                    icon: 'pageview',
                    href: function (row) {
                        return config.path.api('transactional_mails/' + row.id + '/preview');
                    }
                }, {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.transactionalMails.loading = true;

                        TransactionalMailService.duplicateTransactionalMail(row).then(function () {
                            $scope.transactionalMails.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('transactional_mails.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        TransactionalMailService.openDeleteTransactionalMailModal(row.id, function () {
                            $scope.transactionalMails.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('transactional_mails.delete') && row.deletable;
                    }
                }
            ],
            state: session.get('data.dataframes.transactionalMails', {
                sort: { title: 'asc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.transactionalMails, 'transactional_mail', session.get('data.tags'));
        }

        session.sync('data.dataframes.transactionalMails', $scope.transactionalMails.state);
    }
})(angular);
