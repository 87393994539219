(function (angular) {
    angular
        .module('one.admin')
        .controller('SelectFormEntryModalController', SelectFormEntryModalController);

    SelectFormEntryModalController.$inject = ['$scope', '$state', '$uibModalInstance', 'AccountService', 'FormService', 'forms', 'params'];

    function SelectFormEntryModalController($scope, $state, $uibModalInstance, AccountService, FormService, forms, params) {
        $scope.forms = forms;
        $scope.formEntry = {};
        $scope.search = {};

        angular.extend($scope, params);

        $scope.showAccountField = !$scope.formEntry.account;

        $scope.select = select;
        $scope.cancel = cancel;
        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        function select(formType) {
            $state.go('crm.forms.addFormEntry', {formId: formType.id, account: $scope.formEntry.account});
            cancel();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
