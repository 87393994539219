(function (angular) {
    angular
        .module('one.admin')
        .directive('transactionStatusIcon', directive);

    directive.$inject = ['config'];

    function directive(config) {
        return {
            restrict: 'EA',
            scope: {
                status: '='
            },
            templateUrl: config.path.template('transactions.status_icon')
        };
    }
}(angular));
