(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeFilters', oneDataframeFiltersDirective);

    oneDataframeFiltersDirective.$inject = ['config',];

    function oneDataframeFiltersDirective(config) {
        controller.$inject = ['$scope', '$filter', 'dataframe', 'session', 'AccountService', 'AuthService', 'BankStatementService', 'InteractionService', 'DuplicateService', 'GroupService', 'PaymentMethodService', 'ProductService', 'PurchaseService', 'SegmentService', 'TransactionService'];

        function controller($scope, $filter, dataframe, session, AccountService, AuthService, BankStatementService, InteractionService, DuplicateService, GroupService, PaymentMethodService, ProductService, PurchaseService, SegmentService, TransactionService) {
            $scope.show = {};

            angular.forEach($scope.filters, function (filter) {
                $scope.show[filter] = true;
            })

            if ($scope.show.account_types && AuthService.userCanAccess('accounts.list')) {
                $scope.accountTypes = session.get('data.dataframe.accountTypes', []);

                AccountService.types().then(function (accountTypes) {
                    $scope.accountTypes = accountTypes;

                    session.set('data.dataframe.accountTypes', accountTypes);
                });
            }

            if ($scope.show.payment_methods && AuthService.userCanAccess('payment_methods.list')) {
                PaymentMethodService.paymentMethods().then(function (paymentMethods) {
                    $scope.paymentMethodFilters = dataframe.initFilterList(paymentMethods || [], 'id', 'title');
                });
            }

            if ($scope.show.product_categories && AuthService.userCanAccess('products.list')) {
                ProductService.productCategories().then(function (productCategories) {
                    $scope.productCategoryFilters = dataframe.initFilterList(productCategories || [], 'id', 'title');
                });
            }

            if ($scope.show.transaction_statuses) {
                $scope.transactionStatusFilters = [];

                angular.forEach(TransactionService.statuses, function (status) {
                    $scope.transactionStatusFilters.push({
                        value: status,
                        label: $filter('translate')('transaction_statuses.' + status)
                    });
                });
            }

            if ($scope.show.match_types) {
                $scope.bankStatementEntryMatchTypeFilters = [];

                angular.forEach(BankStatementService.matchTypes, function (matchType) {
                    $scope.bankStatementEntryMatchTypeFilters.push({
                        value: matchType,
                        label: $filter('translate')('bank_statement_match_types.' + matchType)
                    });
                });
            }

            if ($scope.show.duplicate_types) {
                $scope.duplicateMatchTypeFilters = [];

                angular.forEach(DuplicateService.matchTypes, function (matchType) {
                    $scope.duplicateMatchTypeFilters.push({
                        value: matchType,
                        label: $filter('translate')('duplicate_match_types.' + matchType)
                    });
                });
            }

            if (($scope.show.users || $scope.show.contact_users || $scope.show.assigned_users) && AuthService.userCanAccess('users.list')) {
                AuthService.users({ sort: { first_name: 'asc', infix: 'asc', last_name: 'asc' } }).then(function (users) {
                    $scope.userFilters = dataframe.initFilterList(users || [], 'id', 'full_name');
                });
            };

            $scope.getGroups = function (params) {
                return GroupService.groups(params).then(function (groups) {
                    return dataframe.initFilterList(groups || [], 'id', 'full_title');
                });
            };

            $scope.getProducts = function (params) {
                return ProductService.products(params).then(function (products) {
                    return dataframe.initFilterList(products || [], 'id', 'title');
                });
            };

            if ($scope.show.purchase_sources && AuthService.userCanAccess('orders.list')) {
                PurchaseService.purchaseSources().then(function (purchaseSources) {
                    $scope.purchaseSourceFilters = dataframe.initFilterList(purchaseSources || [], 'id', 'title');
                });
            }

            if ($scope.show.purchase_destinations && AuthService.userCanAccess('orders.list')) {
                PurchaseService.purchaseDestinations().then(function (purchaseDestinations) {
                    $scope.purchaseDestinationFilters = dataframe.initFilterList(purchaseDestinations || [], 'id', 'title');
                });
            }

            if ($scope.show.payment_types && AuthService.userCanAccess('orders.list')) {
                $scope.paymentTypeFilters = ProductService.paymentTypes;
            }

            $scope.getSegments = function (params) {
                return SegmentService.segments(params).then(function (segments) {
                    return dataframe.initFilterList(segments || [], 'id', 'title');
                });
            };

            $scope.getInteractionSubjects = function (params) {
                return InteractionService.subjects(params).then(function (subjects) {
                    return dataframe.initFilterList(subjects || [], 'id', 'title');
                });
            };

            $scope.formatGroupFilter = formatGroupFilter;
            $scope.formatSegmentFilter = formatSegmentFilter;
            $scope.formatProductFilter = formatProductFilter;
            $scope.formatProductCategoryFilter = formatProductCategoryFilter;
            $scope.formatUserFilter = formatUserFilter;

            function formatGroupFilter() {
                return '<span group-label group="filter.object" no-link="true"></span>';
            }

            function formatSegmentFilter(filter) {
                return SegmentService.formatSegmentTitle(filter.object);
            }

            function formatProductFilter(filter) {
                return ProductService.formatProductTitle(filter.object);
            }

            function formatProductCategoryFilter(filter) {
                return ProductService.formatProductCategoryTitle(filter.object);
            }

            function formatUserFilter() {
                return '<i class="md-icon">account_circle</i> {{ filter.object.full_name }}';
            }
        }

        return {
            restrict: 'AE',
            scope: {
                dataframe: '=',
                filters: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-filters');
            },
            controller: controller,
            templateUrl: config.path.template('common.dataframe.filters')
        };
    }
})(angular);
