(function (angular) {
    angular
        .module('one.admin')
        .controller('EditUserController', EditUserController);

    EditUserController.$inject = ['$scope', '$state', '$stateParams', 'AuthService', 'notification', 'user', 'authRoles', 'ipLocations'];

    function EditUserController($scope, $state, $stateParams, AuthService, notification, user, authRoles, ipLocations) {
        $scope.authRoles = authRoles;
        $scope.ipLocations = ipLocations;
        $scope.user = user;

        $scope.editUser = editUser;

        function editUser(data) {
            AuthService.editUser($stateParams.userId, data).then(function (data) {
                $state.transitionTo('users.user', { userId: $stateParams.userId });
            });
        }
    }
})(angular);
