(function (angular) {
    angular
        .module('one.admin')
        .directive('pdfTemplateForm', pdfTemplateFormDirective);

    pdfTemplateFormDirective.$inject = ['config'];

    function pdfTemplateFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                pdfTemplate: '='
            },
            controller: 'PdfTemplateFormController',
            templateUrl: config.path.template('pdf_templates.form')
        };
    }
}(angular));
