(function (angular) {
    angular
        .module('one.admin')
        .controller('InteractionsCalendarModalController', InteractionsCalendarModalController);

    InteractionsCalendarModalController.$inject = ['$scope', '$uibModalInstance', 'calendarUrl', 'params'];

    function InteractionsCalendarModalController($scope, $uibModalInstance, calendarUrl, params) {
        angular.extend($scope, params);

        $scope.calendarUrl = calendarUrl;

        $scope.onTextClick = function ($event) {
            $event.target.select();
        };

        $scope.close = $uibModalInstance.close;
    }
})(angular);
