(function (angular) {
    angular
        .module('one.admin')
        .service('OrderService', OrderService);

    OrderService.$inject = ['http'];

    function OrderService(http) {
        function cancelReasons() {
            return http.get('orders/cancel_reasons');
        }

        function order(id) {
            return http.get('orders/' + id);
        }

        function orders(params) {
            return http.get('orders', params);
        }

        function addOrder(data) {
            return http.post('orders/add', data);
        }

        function cancelOrder(id, data) {
            return http.post('orders/' + id + '/cancel', data);
        }

        function changeAccount(orderId, data) {
            return http.post('orders/' + orderId + '/change_account', data);
        }

        function editOrder(orderId, data) {
            return http.post('orders/' + orderId + '/edit', data);
        }

        function convertToInvoice(orderId, data) {
            return http.post('orders/' + orderId + '/convert_to_invoice', data);
        }

        function sendMail(mailId, params) {
            return http.post('orders/send_mail/' + mailId, params);
        }

        function setCancelReason(orderId, data) {
            return http.post('orders/' + orderId + '/set_cancel_reason', data);
        }

        function batchDownloadOrderPdf(pdfTemplateId, data) {
            return http.get('orders/batch_download_order_pdf/' + pdfTemplateId, data);
        }

        function formatOrderTitle(order) {
            return '<i class="md-icon">shopping_basket</i> {{ order.remote_id }}';
        }

        return {
            cancelReasons: cancelReasons,
            changeAccount: changeAccount,
            order: order,
            orders: orders,
            addOrder: addOrder,
            cancelOrder: cancelOrder,
            editOrder: editOrder,
            convertToInvoice: convertToInvoice,
            sendMail: sendMail,
            setCancelReason: setCancelReason,
            batchDownloadOrderPdf: batchDownloadOrderPdf,
            formatOrderTitle: formatOrderTitle
        };
    }
})(angular);
