(function (angular) {
    angular
        .module('one.admin')
        .controller('ProcessUnmatchedPaymentsController', ProcessUnmatchedPaymentsController);

    ProcessUnmatchedPaymentsController.$inject = ['$scope', '$stateParams', 'BankStatementService'];

    function ProcessUnmatchedPaymentsController($scope, $stateParams, BankStatementService) {
        $scope.bankStatementEntry = null;

        if ($stateParams.bankStatementEntryId) {
            BankStatementService.bankStatementEntry($stateParams.bankStatementEntryId).then(function (bankStatementEntry) {
                $scope.bankStatementEntry = bankStatementEntry;
            });
        }
    }
})(angular);
