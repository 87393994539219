(function (angular) {
    angular
        .module('one.admin')
        .directive('segmentBuilderFormEntryFilterBlock', segmentBuilderFormEntryFilterBlock);

    segmentBuilderFormEntryFilterBlock.$inject = ['config'];

    function segmentBuilderFormEntryFilterBlock(config) {
        return {
            restrict: 'EA',
            require: '^segmentBuilder',
            scope: {
                form: '=',
                position: '=',
                segment: '=',
                filterBlock: '='
            },
            controller: ['$scope', 'segmentBuilder', function ($scope, segmentBuilder) {
                $scope.filterables = null;

                getFilterables();

                $scope.initFilterBlock = initFilterBlock;
                $scope.deleteFilterBlock = deleteFilterBlock;
                $scope.addFilter = addFilter;

                /**
                 * Loads the filterables and initializes the filter block with a 'has form entry' filter.
                 * The first subfilter makes sure that only form entries of the given form are targeted,
                 * the second subfilter is a boolean filter that may contain additional criteria.
                 * @return {undefined}
                 */
                function initFilterBlock() {
                    getFilterables();

                    $scope.filterBlock = segmentBuilder.hasFilter({
                        boolean: 'or',
                        relation: 'formEntries',
                        filters: [
                            segmentBuilder.comparisonFilter({
                                field: 'form_id',
                                operator: '=',
                                value: $scope.form.id
                            }),
                            segmentBuilder.booleanFilter()
                        ]
                    });
                }

                /**
                 * Resets the filter block and filterables.
                 * @return {undefined}
                 */
                function deleteFilterBlock() {
                    $scope.segment.filters.form_entry.filters.splice($scope.position, 1);
                    $scope.filterables = null;

                    if ($scope.segment.filters.form_entry.filters.length == 0) {
                        $scope.segment.filters.form_entry = null;
                    }
                }

                /**
                 * Adds an empty filter to the filter block.
                 * @return {undefined}
                 */
                function addFilter() {
                    $scope.filterBlock.filters[1].filters.push({});
                }

                /**
                 * Fetches the fields that can be filtered on.
                 * @return {undefined}
                 */
                function getFilterables() {
                    segmentBuilder.filterables.formEntry($scope.form.id).then(function (filterables) {
                        $scope.filterables = filterables;
                    });
                }
            }],
            templateUrl: config.path.template('segments.builder.form_entry_filter_block')
        };
    }
}(angular));
