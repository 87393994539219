(function (angular) {
    angular
        .module('one.admin.http', [
            'one.admin.dependencies'
        ])
        .provider('http', http);

    http.$inject = ['$httpProvider'];

    function http($httpProvider) {
        var path = '/';

        var afterHandlers = { common: false, get: false, post: false };
        var beforeHandlers = { common: false, get: false, post: false };
        var errorHandlers = { common: {}, get: {}, post: {} };
        var successHandlers = { common: false, get: false, post: false };

        this.$get = ['$http', '$httpParamSerializer', '$q', 'string', $get];
        this.errorHandlers = errorHandlers;
        this.getPath = getPath;
        this.setPath = setPath;
        this.setGlobalHeader = setGlobalHeader;
        this.setAfterHandler = setAfterHandler;
        this.setBeforeHandler = setBeforeHandler;
        this.setErrorHandler = setErrorHandler;
        this.setSuccessHandler = setSuccessHandler;

        function $get($http, $httpParamSerializer, $q, string) {
            this.get = get;
            this.post = post;

            function get(url, params, headers, options) {
                options = options || {};
                params = angular.copy(params) || {};

                if (!options.skipBefore) {
                    before('get', options, params);
                }

                if (params && params.returnUrl) {
                    // Return URL in a promise so it can be opened in a new window
                    return $q.when(getPath(url) + '?' + $httpParamSerializer(string.param(params)));
                }

                if (!options.skipTimestamp) {
                    params._t = + new Date;
                }

                return $q(function (resolve, reject) {
                    $http.get(getPath(url), {
                        headers: headers,
                        params: paramString(params),
                        ignoreLoadingBar: options.ignoreLoadingBar
                    }).then(success('get', resolve), error('get', reject));
                });
            }

            function post(url, data, headers, options) {
                options = angular.copy(options) || {};
                options.params = options.params || {};

                before('post', options, options.params);

                return $q(function (resolve, reject) {
                    $http.post(getPath(url), data, {
                        headers: headers,
                        ignoreLoadingBar: options.ignoreLoadingBar
                    }).then(success('post', resolve), error('post', reject));
                });
            }

            function after(response, type) {
                type = type || 'common';

                if (afterHandlers[type]) {
                    afterHandlers[type](response, type);
                }

                if (afterHandlers.common) {
                    afterHandlers.common(response, type);
                }
            }

            function before(type, options, params) {
                type = type || 'common';

                if (beforeHandlers[type]) {
                    beforeHandlers[type](type, options, params);
                }

                if (beforeHandlers.common) {
                    beforeHandlers.common(type, options, params);
                }
            }

            function success(type, resolve) {
                return function handle(response) {
                    after(response, type);

                    resolve(response.data);

                    if (successHandlers[type]) {
                        successHandlers[type](response);
                    }

                    if (successHandlers.common) {
                        successHandlers.common(response);
                    }
                };
            }

            function error(type, reject) {
                return function handle(response) {
                    after(response, type);

                    reject(response.data);

                    if (errorHandlers[type][response.status]) {
                        errorHandlers[type][response.status](response);
                    }

                    if (errorHandlers.common[response.status]) {
                        errorHandlers.common[response.status](response);
                    }
                };
            }

            return this;
        }

        function getPath(url) {
            return path + (url || '');
        }

        function setPath(newPath) {
            path = newPath;
        }

        function setGlobalHeader(name, value, type) {
            type = type || 'common';

            if (value) { // Set
                $httpProvider.defaults.headers[type][name] = value;
            } else { // Unset
                delete $httpProvider.defaults.headers[type][name];
            }
        }

        function setAfterHandler(handler, type) {
            type = type || 'common';

            afterHandlers[type] = handler;
        }

        function setBeforeHandler(handler, type) {
            type = type || 'common';

            beforeHandlers[type] = handler;
        }

        function setErrorHandler(handler, statusCode, type) {
            type = type || 'common';

            errorHandlers[type][statusCode] = handler;
        }

        function setSuccessHandler(handler, type) {
            type = type || 'common';

            successHandlers[type] = handler;
        }

        function buildParamString(value) {
            var result = [];

            if (angular.isObject(value)) {
                angular.forEach(value, function (v, k) {
                    angular.forEach(buildParamString(v), function (keys) {
                        result.push([k].concat(keys));
                    });
                });
            } else {
                result = [value];
            }

            return result;
        }

        function paramString(obj) {
            var lists = angular.isObject(obj) ? buildParamString(obj) : [];
            var result = {};
            var value;

            for (var i = 0; i < lists.length; i++) {
                value = lists[i].pop();

                result[lists[i][0] + (lists[i].length > 1 ? '[' + lists[i].splice(1).join('][') + ']' : '')] = value;
            }

            return result;
        }

        return this;
    }
})(angular);
