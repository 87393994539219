(function (angular) {
    angular
        .module('one.admin')
        .controller('SddBatchesController', SddBatchesController);

    SddBatchesController.$inject = ['$scope', 'AuthService', 'SddBatchService', 'config', 'dataframe', '$filter', 'session'];

    function SddBatchesController($scope, AuthService, SddBatchService, config, dataframe, $filter, session) {
        $scope.sddBatches = dataframe.init({
            externalData: SddBatchService.sddBatches,
            cols: [
                {
                    title: 'ID',
                    name: 'id',
                    display: function (row) {
                        return '<span class="label label-default">{{ row.remote_id }}</span>';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4>{{ row.title }}</h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Status',
                    name: 'status',
                    display: function (row) {
                        return '{{ \'sdd_batch_statuses.\' + row.status | translate }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Transactions',
                    name: 'transaction_count',
                    display: function (row) {
                        return '{{ row.transaction_count | number }} <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    title: 'Total',
                    name: 'amount',
                    display: function (row) {
                        return '{{ row.amount | money:\'&euro; \' }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Collection date',
                    name: 'collection_date',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.collection_date);
                    },
                    sortable: true
                },
                {
                    title: 'Creation date',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    },
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('sdd_batches.view')) {
                    return 'finance.sddBatches.sddBatch({sddBatchId: row.id})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Download XML file',
                    icon: 'file_download',
                    click: function (row) {
                        downloadBatch(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('sdd_batches.download') && row.downloadable;
                    }
                }
            ],
            state: session.get('data.dataframes.sddBatches', {
                sort: { created_at: 'desc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        session.sync('data.dataframes.sddBatches', $scope.sddBatches.state);

        $scope.downloadBatch = downloadBatch;

        function downloadBatch(id) {
            window.location = window.location.origin + window.location.pathname + config.path.api('sdd_batches/' + id + '/download');
        }
    }
})(angular);
