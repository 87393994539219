(function (angular) {
    angular
        .module('one.admin')
        .filter('trustedHtml', trustedHtmlFilter);

    trustedHtmlFilter.$inject = ['$sce'];

    function trustedHtmlFilter($sce) {
        return function (value) {
            if (!angular.isString(value)) {
                return value;
            }

            return $sce.trustAsHtml(value);
        };
    }
})(angular);
