(function (angular) {
    angular
        .module('one.admin')
        .service('ExportService', ExportService);

    ExportService.$inject = ['$filter', 'http', 'ActionCampaignService', 'Rollbar'];

    function ExportService($filter, http, ActionCampaignService, Rollbar) {
        function requestedExport(id) {
            return http.get('exports/' + id);
        }

        function exports(params) {
            return http.get('exports', params);
        }

        function deleteExport(id) {
            return http.post('exports/' + id + '/delete');
        }

        function formatFilters(row) {
            var output = [];

            if (row.params.search) {
                if (row.params.search instanceof Object) {
                    angular.forEach(row.params.search, function (searchTerms, attribute) {
                        var search = '<i class="md-icon text-muted">search</i> <strong>' + $filter('translate')('general.' + attribute) + '</strong>: ';

                        angular.forEach(searchTerms, function (searchTerm, index) {
                            if (index > 0) {
                                search += ' <span class="text-muted">/</span> ';
                            }

                            search += searchTerm;
                        });

                        output.push(search);
                    });
                } else {
                    output.push('<i class="md-icon text-muted">search</i> ' + row.params.search);
                }
            }

            angular.forEach(row.filter_objects, function (values, scope) {
                var values = values instanceof Array ? values : [values];
                var filter = [];

                angular.forEach(values, function (value, index) {
                    filter.push('<span class="text-nowrap">' + formatFilterObject(scope, value, index) + '</span>');
                });

                output.push(filter.join(' <span class="text-muted">/</span> '));
            });

            return output.join(' <span class="text-muted"> <i class="md-icon">add</i> </span> ');
        }

        function formatFilterObject(scope, object, index) {
            if (scope == 'active') {
                if (object) {
                    return '<i class="md-icon">visibility</i> {{ \'general.active\' | translate }}';
                }

                return '<i class="md-icon">visibility_off</i> {{ \'general.inactive\' | translate }}';
            }

            if (scope == 'archived') {
                if (object) {
                    return '<i class="md-icon">visibility_off</i> {{ \'general.archived\' | translate }}';
                }

                return '<i class="md-icon">visibility</i> {{ \'general.unarchived\' | translate }}';
            }

            if (scope == 'action_campaign_types') {
                return ActionCampaignService.formatActionCampaignType(object);
            }

            if (scope == 'accounts' || scope == 'from_accounts' || scope == 'campaigners' || scope == 'with_campaigners') {
                return '<a ui-sref="crm.accounts.account.index({ accountId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><span account-icon type="' + object.account_type_name + '"></span> ' + object.title + '</a>';
            }

            if (scope == 'account_types') {
                return '<span account-icon type="' + object.name + '"></span> ' + $filter('translate')('account_types.' + object.name);
            }

            if (scope == 'action_campaigns' || scope == 'to_action_campaigns' || scope == 'parent_campaigns') {
                return '<a ui-sref="crowdfunding.actionCampaigns.actionCampaign({ actionProjectId: ' + object.action_project_id + ', actionCampaignId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">group_work</i> ' + object.prefilled_title + '</a>';
            }

            if (scope == 'action_projects' || scope == 'to_action_projects') {
                return '<a ui-sref="crowdfunding.actionProjects.actionProject({ actionProjectId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">favorite_outline</i> ' + object.title + '</a>';
            }

            if (scope == 'finished') {
                if (object) {
                    return '<i class="md-icon">check_box</i> {{ \'general.finished\' | translate }}';
                }

                return '<i class="md-icon">check_box_outline_blank</i> {{ \'general.to_do\' | translate }}';
            }

            if (scope == 'interaction_subjects') {
                return object.title;
            }

            if (scope == 'forms') {
                return '<a ui-sref="crm.forms.formEntries({ formId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">assignment</i> ' + object.label + '</a>';
            }

            if (scope == 'groups' || scope == 'to_groups') {
                return '<span group-label group="row.filter_objects.' + scope + '[' + index + ']" compact="true"></span>';
            }

            if (scope == 'segments') {
                return '<a ui-sref="crm.segments.segment({ segmentId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">filter_list</i> ' + object.title + '</a>';
            }

            if (scope == 'payment_methods') {
                return '<i class="md-icon">payment</i> ' + object.title;
            }

            if (scope == 'products') {
                return '<a ui-sref="sales.products.product({ productId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">loyalty</i> ' + object.title + '</a>';
            }

            if (scope == 'product_categories') {
                return '<a ui-sref="sales.products.productCategories.productCategory({ productCategoryId: ' + object.id + ' })" ng-click="$event.stopPropagation()"><i class="md-icon">style</i> ' + object.title + '</a>';
            }

            if (scope == 'purchase_sources') {
                return '<i class="md-icon">label</i> ' + object.title;
            }

            if (scope == 'purchase_destinations') {
                return '<i class="md-icon">folder_open</i> ' + object.title;
            }

            if (scope == 'discount_codes') {
                return '<a ui-sref="sales.discountCodes.discountCode({ discountCodeId: ' + object.id + ' })" ng-click="$event.stopPropagation()">' + object.code + '</a>';
            }

            if (scope == 'transaction_statuses') {
                return '<span class="label label-transaction-status label-transaction-' + object + '">' + $filter('translate')('transaction_statuses.' + object) + '</span>';
            }

            if (scope == 'payment_reference_configs') {
                return object.title;
            }

            if (scope == 'users' || scope == 'contact_users' || scope == 'assigned_users') {
                return '<span class="one-user"><i class="md-icon">account_circle</i> ' + object.full_name + '</span>';
            }

            if (scope == 'tags') {
                return '<span style="width: 16px; text-align: center; display: inline-block"><i tag-indicator tags="[row.filter_objects.tags[' + index + ']]"></i></span> ' + object.title;
            }

            if (scope == 'start_date') {
                return '<i class="md-icon">event</i> {{ \'general.from\' | translate }} ' + $filter('formatDate')(object);
            }

            if (scope == 'end_date') {
                return '<i class="md-icon">event</i> {{ \'general.till\' | translate }} ' + $filter('formatDate')(object);
            }

            if (scope == 'match_types') {
                return '<i class="md-icon">euro_symbol</i> ' + $filter('translate')('bank_statement_match_types.' + object);
            }

            if (scope == 'payment_types') {
                return $filter('translate')('general.' + object);
            }

            Rollbar.error("ExportService.formatFilterObject could not format filter of type '" + scope + "'");

            return '';
        }

        return {
            requestedExport: requestedExport, // Use this name because "export" is a reserved keyword.
            exports: exports,
            deleteExport: deleteExport,
            formatFilters: formatFilters,
        };
    }
})(angular);
