(function (angular) {
    angular
        .module('one.admin')
        .controller('DiscountCodeController', DiscountCodeController);

    DiscountCodeController.$inject = ['$scope', '$filter', '$state', 'modal', 'session', 'dataframe', 'notification', 'DiscountCodeService', 'discountCode'];

    function DiscountCodeController($scope, $filter, $state, modal, session, dataframe, notification, DiscountCodeService, discountCode) {
        $scope.discountCode = discountCode;

        $scope.purchases = dataframe.init({
            name: 'purchases',
            externalData: function (params) {
                return DiscountCodeService.discountCode.purchases($scope.discountCode.id, params);
            },
            cols: [
                {
                    title: 'Order',
                    name: 'id',
                    display: function (row) {
                        return '<strong><i class="md-icon">shopping_basket</i> {{ row.order.remote_id }}</strong>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Status',
                    name: 'status',
                    display: function (row) {
                        return '<span order-status-icon statuses="row.statuses"></span>';
                    },
                    weight: 1
                },
                {
                    name: 'product.title',
                    title: 'Product',
                    weight: 3,
                    display: function (row) {
                        return '<span class="text-muted">' + row.quantity + '&times;</span> <a ui-sref="sales.products.product({productId:\'' + row.product.id + '\'})" ng-click="$event.stopPropagation()"><i class="md-icon">loyalty</i> {{ row.product.title }}</a>';
                    }
                },
                {
                    title: 'Contact',
                    name: 'account.title',
                    display: function (row) {
                        return '<a ui-sref="crm.accounts.account.index({accountId: row.order.account.id})" ng-click="$event.stopPropagation()" ng-class="{archived: !row.order.account.active}"><i account-icon account="row.order.account"></i> {{ row.order.account.title }}</a>';
                    },
                    weight: 3
                },
                {
                    title: 'Payment method',
                    name: 'order.payment_method.title',
                    weight: 3
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    weight: 2,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    },
                    sortable: true
                }
            ],
            rowSref: function (row) {
                return 'sales.orders.order({orderId: ' + row.order_id + '})';
            },
            state: session.get('dataframes.discountCode.purchases', { sort: { created_at: 'desc' } }),
            filterables: ['start_date', 'end_date']
        });

        $scope.openDeleteDiscountCodeModal = openDeleteDiscountCodeModal;
        $scope.openAuditorModal = openAuditorModal;

        function openDeleteDiscountCodeModal() {
            DiscountCodeService.openDeleteDiscountCodeModal($scope.discountCode.id, function () {
                $state.transitionTo('sales.discountCodes.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: discountCode.id, modelType: 'discount_code' });
        }
    }
})(angular);
