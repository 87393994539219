(function (angular) {
    angular
        .module('one.admin')
        .service('PaymentReferenceService', PaymentReferenceService);

    PaymentReferenceService.$inject = ['http'];

    function PaymentReferenceService(http) {
        function accounts(id, params) {
            return http.get('payment_reference_configs/' + id + '/accounts', params);
        }

        function paymentReferenceConfig(id) {
            return http.get('payment_reference_configs/' + id);
        }

        function paymentReferenceConfigs(params) {
            return http.get('payment_reference_configs', params);
        }

        function addPaymentReferenceConfig(data) {
            return http.post('payment_reference_configs/add', data);
        }

        function editPaymentReferenceConfig(data) {
            return http.post('payment_reference_configs/' + data.id + '/edit', data);
        }

        return {
            accounts: accounts,
            paymentReferenceConfig: paymentReferenceConfig,
            paymentReferenceConfigs: paymentReferenceConfigs,
            addPaymentReferenceConfig: addPaymentReferenceConfig,
            editPaymentReferenceConfig: editPaymentReferenceConfig
        };
    }
})(angular);
