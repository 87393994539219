(function (angular) {
    angular
        .module('one.admin')
        .controller('TransactionController', TransactionController);

    TransactionController.$inject = ['$scope', '$filter', '$state', 'AuthService', 'ProductService', 'TransactionService', 'dataframe', 'modal', 'notification', 'string', 'transaction'];

    function TransactionController($scope, $filter, $state, AuthService, ProductService, TransactionService, dataframe, modal, notification, string, transaction) {
        $scope.transaction = transaction;
        $scope.payment = transaction.order.payment;

        $scope.paymentTerms = dataframe.init({
            data: transaction.purchase_payment_terms,
            cols: [
                {
                    title: 'Product / Term',
                    name: 'title',
                    weight: 2,
                    display: function (row) {
                        var output = '';

                        if (row.cancelled || row.refunded) {
                            output += '<span class="text-muted">';
                        }

                        output += row.purchase.quantity + ' &times; ';
                        output += '<a ui-sref="sales.products.product({productId:\'' + row.purchase.product_id + '\'})">';
                        output += '<i class="md-icon">loyalty</i> ' + string.escape(row.purchase.product.title);
                        output += '</a>';

                        if (row.purchase.payment_type == 'terms') {
                            output += ' / ';
                            output += string.escape(row.title);
                        }

                        if (row.cancelled || row.refunded) {
                            output += '</span>';
                        }

                        if (row.refunded) {
                            output += '<i class="md-icon text-muted" uib-tooltip="{{ \'general.refunded\' | translate }}" tooltip-append-to-body="true">replay</i></span> ';
                        }

                        if (row.cancelled) {
                            output += '<i class="md-icon text-muted" uib-tooltip="{{ \'general.cancelled\' | translate }}" tooltip-append-to-body="true">close</i></span> ';
                        }

                        if (row.purchase.gift) {
                            output += '<div ng-if="showGift">{{ \'general.gifted_to\' | translate }} <a ui-sref="crm.accounts.account.index({accountId: row.purchase.gift.account.id})" ng-click="$event.stopPropagation()"><i account-icon account="row.purchase.gift.account"></i> {{ row.purchase.gift.account.title }}</a></div>';
                        }

                        return output;
                    }
                },
                {
                    title: 'Category',
                    name: 'category',
                    weight: 1,
                    display: function (row) {
                        if (row.purchase.product.category) {
                            return '<a ui-sref="sales.products.productCategories.productCategory({ productCategoryId: row.purchase.product.category.id })">' + ProductService.formatProductCategoryTitle(row.purchase.product.category) + '</a>'
                        }

                        return null;
                    }
                },
                {
                    title: 'Amount',
                    name: 'amount',
                    weight: 1,
                    display: function (row) {
                        var output = '';

                        if (row.cancelled || row.refunded) {
                            output += '<span class="text-muted text-line-through">';
                        }

                        output += '<span' + (row.refunded ? ' class="text-muted text-line-through"' : '') + '>' + $filter('money')(row.gross_amount, '&euro; ', 2) + '</span>';

                        if (row.cancelled || row.refunded) {
                            output += '</span>';
                        }

                        return output;
                    }
                }
            ],
            showHeader: false,
            showPagination: false,
            state: {
                perPage: 100
            }
        });

        transaction.recursive_parent_transactions.reverse();

        var relatedTransactions = transaction.recursive_parent_transactions.concat([transaction]).concat(transaction.recursive_child_transactions);

        $scope.relatedTransactions = dataframe.init({
            data: relatedTransactions,
            cols: [
                {
                    title: 'Transaction',
                    name: 'id',
                    display: function (row) {
                        return '<span transaction-label transaction="row"></span>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Collect date',
                    name: 'collect_date',
                    weight: 1,
                    display: function (row) {
                        var output = '';

                        if (row.id == $scope.transaction.id) {
                            output += '<div class="text-strong">';
                        }

                        output += $filter('formatDate')(row.collect_date);

                        if (row.id == $scope.transaction.id) {
                            output += '</div>';
                        }

                        return output;
                    },
                    sortable: true
                },
                {
                    title: 'Note',
                    name: 'rote',
                    weight: 1,
                    display: function (row) {
                        var output = '';

                        if (row.id == $scope.transaction.id) {
                            output += '<span class="text-strong">';
                        }

                        output += row.note || '';

                        if (row.id == $scope.transaction.id) {
                            output += '</span>';
                        }

                        return output;
                    },
                    sortable: true
                },
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('transactions.view') && row.id != $scope.transaction.id) {
                    return 'finance.transactions.transaction({transactionId: row.id})';
                }
            },
            showHeader: false,
            showPagination: false,
            state: {
                perPage: 100
            }
        });

        $scope.statuses = dataframe.init({
            data: transaction.statuses,
            cols: [
                {
                    title: 'Status',
                    name: 'status',
                    weight: 2,
                    display: function (row) {
                        return '<i transaction-status-icon status="row.status"></i> <strong>{{ \'transaction_statuses.' + row.status + '\' | translate }}</strong>';
                    }
                },
                {
                    title: 'Date',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at);
                    }
                },
                {
                    title: 'Time',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'time');
                    }
                }
            ],
            showHeader: false,
            showPagination: false,
            state: {
                perPage: 100
            }
        });

        $scope.actionIcons = {};
        $scope.actionIcons[TransactionService.actions.authorise] = 'play_arrow';
        $scope.actionIcons[TransactionService.actions.finalize] = 'check';
        $scope.actionIcons[TransactionService.actions.cancel] = 'close';
        $scope.actionIcons[TransactionService.actions.reverse] = 'error_outline';
        $scope.actionIcons[TransactionService.actions.pause] = 'pause';
        $scope.actionIcons[TransactionService.actions.refund] = 'replay';

        $scope.applyAction = applyAction;
        $scope.verifyAction = verifyAction;
        $scope.openAuditorModal = openAuditorModal;

        function openReverseSddTransactionModal(size) {
            modal.open('reverseSddTransaction', size, {
                sddTransaction: $scope.transaction.transactionable,
                transaction: $scope.transaction
            }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openFinalizeSddTransactionModal(size) {
            modal.open('finalizeSddTransaction', size, {
                sddTransaction: $scope.transaction.transactionable,
                transaction: $scope.transaction
            }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function openRefundTransactionModal(size) {
            modal.open('refundTransaction', size, {
                transaction: $scope.transaction
            }).result.then(function (data) {
                $state.reload($state.current);
            });
        }

        function verifyAction(action) {
            if (action == TransactionService.actions.finalize) {
                openFinalizeSddTransactionModal();
            } else if (action == TransactionService.actions.reverse) {
                openReverseSddTransactionModal();
            } else if (action == TransactionService.actions.refund) {
                openRefundTransactionModal();
            } else {
                applyAction(action);
            }
        }

        function applyAction(action) {
            TransactionService.transaction.applyAction($scope.transaction.id, action).then(function (data) {
                notification.success('Status updated');
                $state.reload($state.current);
            }, function (response) {
                notification.error(Object.values(response.errors)[0][0]);
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: transaction.id, modelType: 'transaction' });
        }
    }
})(angular);
