(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$window', 'Rollbar', 'session'];

    function run($window, Rollbar, session) {
        var href = $window.location.href;

        Rollbar.configure({
            transform: function (payload) {
                var project = session.get('project');
                var user = session.get('user');

                payload.project = project ? project.title : '';
                payload.user = user ? user.id : '';
            }
        });

        if (href.indexOf('127.0.0.1') > - 1 || href.indexOf('localhost') > -1) {
            Rollbar.disable();
        }
    }
})(angular);
