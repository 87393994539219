(function (angular) {
    angular
        .module('one.admin')
        .controller('AddGroupModalController', AddGroupModalController);

    AddGroupModalController.$inject = ['$scope', '$state', '$uibModalInstance', 'GroupService', 'translateFilter', 'params'];

    function AddGroupModalController($scope, $state, $uibModalInstance, GroupService, translateFilter, params) {
        angular.extend($scope, params);

        if (!$scope.group) {
            $scope.group = {};
        }

        $scope.add = add;
        $scope.cancel = cancel;
        $scope.getGroups = GroupService.groups;
        $scope.formatGroupTitle = GroupService.formatGroupTitle;

        function add(data) {
            GroupService.addGroup(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
