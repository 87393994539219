(function (angular) {
    angular
        .module('one.admin')
        .controller('CancelOrderModalController', CancelOrderModalController);

    CancelOrderModalController.$inject = ['$scope', '$uibModalInstance', 'OrderService', 'notification', 'moment', 'translateFilter', 'cancelReasons', 'params'];

    function CancelOrderModalController($scope, $uibModalInstance, OrderService, notification, moment, translateFilter, cancelReasons, params) {
        angular.merge($scope, params);

        angular.forEach(cancelReasons, function (cancelReason) {
            cancelReason.label = cancelReason.title;
        });

        cancelReasons.unshift({ label: translateFilter('general.new_dots'), new: true });

        $scope.cancelReasons = cancelReasons;

        $scope.cancellation = {};

        $scope.close = $uibModalInstance.close;
        $scope.cancelOrder = cancelOrder;

        function cancelOrder() {
            OrderService.cancelOrder($scope.order.id, $scope.cancellation).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
