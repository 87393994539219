(function (angular) {
    angular
        .module('one.admin')
        .service('IpLocationService', IpLocationService);

    IpLocationService.$inject = ['http', 'modal', 'translateFilter'];

    function IpLocationService(http, modal, translateFilter) {
        function ipLocation(id) {
            return http.get('ip_locations/' + id);
        }

        ipLocation.users = function (id, params) {
            return http.get('ip_locations/' + id + '/users', params);
        };

        function ipLocations(params) {
            return http.get('ip_locations', params);
        }

        function addIpLocation(data) {
            return http.post('ip_locations/add', data);
        }

        function deleteIpLocation(id) {
            return http.post('ip_locations/' + id + '/delete');
        }

        function editIpLocation(data) {
            return http.post('ip_locations/' + data.id + '/edit', data);
        }

        function openDeleteIpLocationModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Are you sure?',
                message: 'This will also remove the location from associated users and might cause users to be able to login from any location.'
            }).result.then(function (data) {
                return deleteIpLocation(id).then(callback);
            });
        }

        return {
            ipLocation: ipLocation,
            ipLocations: ipLocations,
            addIpLocation: addIpLocation,
            deleteIpLocation: deleteIpLocation,
            editIpLocation: editIpLocation,
            openDeleteIpLocationModal: openDeleteIpLocationModal
        };
    }
})(angular);
