(function (angular) {
    angular
        .module('one.admin')
        .directive('mailTemplateForm', mailTemplateFormDirective);

    mailTemplateFormDirective.$inject = ['config'];

    function mailTemplateFormDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                template: '=',
            },
            controller: ['$scope', '$timeout', 'MailService', 'string', function ($scope, $timeout, MailService, string) {
                var getPlaceholdersTimer;

                $scope.placeholderMap = {};
                $scope.files = {};

                for (var i = 0; i < $scope.template.placeholders.length; i++) {
                    var placeholder = $scope.template.placeholders[i];

                    $scope.placeholderMap[placeholder.name] = placeholder;

                    if (placeholder.config.url) {
                        $scope.files[placeholder.name] = {
                            url: placeholder.config.url
                        };
                    } else {
                        $scope.files[placeholder.name] = null;
                    }
                }

                $scope.placeholderIcons = MailService.getPlaceholderIcons();
                $scope.placeholderTypes = MailService.getPlaceholderTypes();
                $scope.variables = MailService.getMailTemplateVariables();

                $scope.forms = {};

                if ($scope.template.placeholders.length > 0) {
                    $scope.forms.showPlaceholderForm = $scope.template.placeholders[0].name;
                }

                $scope.tab = {
                    active: 'preview'
                };

                $scope.loading = true;

                getPlaceholders();
                getPreview();
                getCssEditorVariables();

                $scope.getPlaceholders = getPlaceholders;
                $scope.getPreview = getPreview;
                $scope.setPlaceholderImage = setPlaceholderImage;
                $scope.isColorVariable = isColorVariable;
                $scope.isFontFamilyVariable = isFontFamilyVariable;
                $scope.isFontSizeVariable = isFontSizeVariable;
                $scope.formatFontFamily = formatFontFamily;
                $scope.formatFontSize = formatFontSize;

                function getPlaceholders() {
                    $timeout.cancel(getPlaceholdersTimer);

                    getPlaceholdersTimer = $timeout(function () {
                        var placeholderNames = MailService.matchPlaceholders($scope.template.content);
                        var placeholders = [];

                        angular.forEach(placeholderNames, function (name) {
                            if (!$scope.placeholderMap[name]) {
                                $scope.placeholderMap[name] = {
                                    name: name,
                                    type: $scope.placeholderTypes[0],
                                    title: string.snakeToTitle(name),
                                    config: {}
                                };
                            }

                            if (angular.isArray($scope.placeholderMap[name].config)) {
                                $scope.placeholderMap[name].config = {};
                            }

                            placeholders.push($scope.placeholderMap[name]);
                        });

                        $scope.template.placeholders = placeholders;
                    }, 500);
                }

                function getPreview() {
                    $scope.loading = true;

                    MailService.livePreviewMailTemplate($scope.template, { ignoreLoadingBar: true }).then(function (preview) {
                        $scope.preview = preview;
                        $scope.loading = false;
                    });
                }

                function getCssEditorVariables() {
                    $scope.css_variables = [];

                    angular.forEach($scope.template.css_variables, function (variable) {
                        $scope.css_variables.push(variable.name);
                    });

                    $scope.fontSizes = ['9px', '10px', '11px', '12px', '14px', '16px', '18px', '21px', '24px', '27px', '30px', '36px', '42px', '48px'];

                    $scope.fontFamilies = [
                        "Helvetica, Arial, sans-serif",
                        "Trebuchet MS, Helvetica, sans-serif",
                        "Verdana, Geneva, sans-serif",
                        "Georgia, Times New Roman, serif",
                        "Courier New, Courier, monospace"
                    ];
                }

                function setPlaceholderImage(placeholder) {
                    var image = $scope.files[placeholder.name] || {};

                    placeholder.config = angular.merge(placeholder.config, {
                        url: image.url
                    });
                }

                function isColorVariable(variable) {
                    return variable.name.indexOf('color') > -1 || variable.name.indexOf('background') > -1;
                }

                function isFontFamilyVariable(variable) {
                    return variable.name.indexOf('font_family') > -1;
                }

                function isFontSizeVariable(variable) {
                    return ['h1_size', 'h2_size', 'h3_size'].indexOf(variable.name) > -1 || variable.name.indexOf('font_size') > -1;
                }

                function formatFontFamily(fontFamily) {
                    return '<span style="font-family: ' + fontFamily + '">' + fontFamily.replace(/'/g, '') + '</span>';
                }

                function formatFontSize(fontSize) {
                    return fontSize.replace('px', '');
                }
            }],
            templateUrl: config.path.template('mail_templates.form')
        };
    }
})(angular);
