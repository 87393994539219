(function (angular) {
    angular
        .module('one.admin')
        .directive('giftRewardForm', giftRewardFormDirective);

    giftRewardFormDirective.$inject = ['config'];

    function giftRewardFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                giftReward: '='
            },
            controller: ['$scope', 'GroupService', 'ProductService', 'VisitableService', function PageFormController($scope, GroupService, ProductService, VisitableService) {
                $scope.getGroups = GroupService.groups;
                $scope.getProducts = ProductService.products;
                $scope.getVisitables = VisitableService.pageVisitables;
                $scope.formatGroupTitle = GroupService.formatGroupTitle;
                $scope.formatProductTitle = ProductService.formatProductTitle;
                $scope.formatVisitableTitle = VisitableService.formatVisitableTitle;
            }],
            templateUrl: config.path.template('gift_rewards.form')
        };
    }
}(angular));
