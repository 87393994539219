(function (angular) {
    angular
        .module('one.admin')
        .filter('money', moneyFilter);

    moneyFilter.$inject = ['$filter'];

    /**
     * Format an integer money amount (takes cents as input).
     */
    function moneyFilter($filter) {
        var currency = $filter('currency');

        return function (amount, symbol, fractionSize) {
            symbol = symbol || '&euro; ';
            amount = amount / 100;

            return currency(amount, symbol, fractionSize);
        };
    }
})(angular);
