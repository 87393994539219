(function (angular) {
    angular
        .module('one.admin')
        .controller('EditActionCampaignController', EditActionCampaignController);

    EditActionCampaignController.$inject = ['$scope', '$state', 'ActionCampaignService', 'types', 'actionCampaign'];

    function EditActionCampaignController($scope, $state, ActionCampaignService, types, actionCampaign) {
        $scope.types = types;
        $scope.actionCampaign = actionCampaign;

        $scope.edit = edit;

        function edit(data) {
            ActionCampaignService.editActionCampaign(data).then(function (data) {
                $state.transitionTo('crowdfunding.actionCampaigns.actionCampaign', {
                    actionProjectId: data.action_campaign.action_project_id,
                    actionCampaignId: actionCampaign.id
                });
            });
        }
    }
})(angular);
