(function (angular) {
    angular
        .module('one.admin')
        .controller('MergeAccountsModalController', MergeAccountsModalController);

    MergeAccountsModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'notification', 'params'];

    function MergeAccountsModalController($scope, $uibModalInstance, AccountService, notification, params) {
        $scope.merge = {
            master: null,
            duplicate: null
        };

        angular.merge($scope, params);

        $scope.original = $scope.merge.master;

        $scope.close = $uibModalInstance.close;
        $scope.getAccounts = function (params) {
            params = params || {};
            params.filters = params.filters || {};
            params.filters.mergeable = true;

            return AccountService.accounts(params);
        };
        $scope.mergeAccounts = mergeAccounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        function mergeAccounts() {
            AccountService.mergeAccounts($scope.merge).then(function (data) {
                $uibModalInstance.close(data);
            });
        }
    }
})(angular);
