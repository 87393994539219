(function (angular) {
    angular
        .module('one.admin')
        .service('FormService', FormService);

    FormService.$inject = ['http', 'config', 'modal', 'translateFilter', '$state', '$templateCache', 'escapeHtmlFilter'];

    function FormService(http, config, modal, translateFilter, $state, $templateCache, escapeHtmlFilter) {
        function field(formFieldId, params) {
            return http.get('forms/fields/' + formFieldId, params);
        }

        function fields(formId, params) {
            return http.get('forms/' + formId + '/fields', params);
        }

        function fieldTypes(params) {
            return http.get('forms/fields/types', params);
        }

        function fieldOptions(id, params) {
            if (parseInt(id) === id) {
                return http.get('forms/fields/' + id + '/options', params);
            }

            return http.get('forms/fields/types/' + id + '/options', params);
        }

        function form(id, params) {
            return http.get('forms/' + id, params);
        }

        form.visitablePages = function (id, params) {
            return http.get('forms/' + id + '/visitable_pages', params);
        };

        function forms(params) {
            return http.get('forms', params);
        }

        function page(id, params) {
            return http.get('forms/pages/' + id, params);
        }

        function states(params) {
            return http.get('forms/states', params);
        }

        function addForm(data) {
            return http.post('forms/add', data);
        }

        function deleteForm(id) {
            return http.post('forms/' + id + '/delete');
        }

        function editForm(data) {
            return http.post('forms/' + data.id + '/edit', data);
        }

        function publishForm(id, data) {
            return http.post('forms/' + id + '/publish', data);
        }

        function addFormPage(formId, data) {
            return http.post('forms/' + formId + '/pages/add', data);
        }

        function deleteFormPage(id) {
            return http.post('forms/pages/' + id + '/delete');
        }

        function editFormPage(data) {
            return http.post('forms/pages/' + data.id + '/edit', data);
        }

        function duplicateForm(data) {
            return http.post('forms/' + data.id + '/duplicate', data);
        }

        function addFormField(data) {
            return http.post('forms/fields/add', data);
        }

        function deleteFormField(id) {
            return http.post('forms/fields/' + id + '/delete');
        }

        function disableFormField(id) {
            return http.post('forms/fields/' + id + '/disable');
        }

        function editFormField(data) {
            return http.post('forms/fields/' + data.id + '/edit', data);
        }

        function editFormStructure(data) {
            return http.post('forms/' + data.id + '/edit_structure', data);
        }

        function formatFieldTypeTitle(fieldType) {
            return translateFilter('form_field_types.' + fieldType.name);
        }

        function fieldIcon(field) {
            if ((field.choices && field.choices.length > 0) || field.rules.choices.min !== null || field.rules.choices.max !== null) {
                if (field.rules.choices.min == 1 && field.rules.choices.max == 1) {
                    return 'radio_button_checked';
                }

                return 'check_box';
            }

            return 'short_text';
        }

        function fieldTypeIcon(fieldType) {
            var iconMap = {
                'generic_string': 'short_text',
                'generic_text': 'notes',
                'generic_integer': 'looks_one',
                'generic_money': 'euro_symbol',
                'generic_date': 'calendar_today',
                'generic_email': 'alternate_email',
                'account_type': 'person',
                'account.initials': 'person',
                'account.first_name': 'person',
                'account.infix': 'person',
                'account.last_name': 'person',
                'account.gender': 'face',
                'account.title': 'business',
                'account.email': 'alternate_email',
                'account.secondary_email': 'alternate_email',
                'account.email_username': 'alternate_email',
                'account.street': 'location_on',
                'account.house_number': 'location_on',
                'account.house_number_addition': 'location_on',
                'account.zip_code': 'location_on',
                'account.city': 'location_on',
                'account.country': 'location_on',
                'account.postal_street': 'location_on',
                'account.postal_house_number': 'location_on',
                'account.postal_house_number_addition': 'location_on',
                'account.postal_zip_code': 'location_on',
                'account.postal_city': 'location_on',
                'account.postal_country': 'location_on',
                'account.phone_private': 'phone',
                'account.phone_home': 'phone',
                'account.phone_work': 'phone',
                'account.phone_emergency': 'phone',
                'account.subscribe_to_mails': 'email',
                'account.unsubscribe_from_mails': 'email',
                'account.image': 'photo',
                'account.password': 'security',
                'action_campaign.image': 'photo',
                'action_campaign.description': 'notes',
                'action_campaign.target': 'euro_symbol',
                'action_campaign.end_date': 'calendar_today',
                'action_campaign.cause_iban': 'credit_card',
                'action_campaign.active': 'visibility',
                'action_campaign.child.image': 'photo',
                'action_campaign.child.description': 'notes',
                'action_campaign.child.target': 'euro_symbol',
                'action_campaign.child.active': 'visibility',
                'action_campaign.child.share': 'euro_symbol',
                'discount_code': 'label_outline',
                'group': 'label',
                'sub_group': 'label',
                'purchase': 'loyalty',
                'bank_account': 'credit_card',
                'gift.destination': 'card_giftcard',
                'gift.message': 'card_giftcard',
                'gift.privacy': 'card_giftcard',
                'payment_method': 'account_balance',
                'ideal_bank': 'credit_card',
                'idin_bank': 'credit_card',
                'action_campaign': 'group_work',
                'action_campaign.type': 'group_work',
                'action_project': 'folder',
                'form_entry': 'assignment',
                'file': 'attachment'
            };

            return iconMap[fieldType.name] || iconMap['generic_' + fieldType.data_type] || 'short_text';
        }

        function rulesTemplatePath(field) {
            var type = field.type ? field.type.name : field.form_field_type_name;
            var path = config.path.template('forms.rules.' + type.replace(/\-/g, '_'));

            if ($templateCache.get(path)) {
                return path;
            }

            return null;
        }

        function openDeleteFormModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this form <strong>and its form entries</strong>?'
            }).result.then(function (data) {
                return deleteForm(id).then(callback);
            });
        }

        function formatFormTitle(form) {
            return '<i class="md-icon">assignment</i> ' + escapeHtmlFilter(form.label);
        }

        function formatFormPageTitle(page) {
            return page.position + '. ' + (escapeHtmlFilter(page.title) || '<em>' + translateFilter('general.untitled') + '</em>');
        }

        function openPublishFormModal(form) {
            modal.open('publishForm', null, { form: form }).result.then(function (data) {
                $state.transitionTo('content.pages.pageContent', { pageId: data.page.id });
            });
        }

        return {
            field: field,
            fields: fields,
            fieldTypes: fieldTypes,
            fieldOptions: fieldOptions,
            form: form,
            forms: forms,
            page: page,
            states: states,
            rulesTemplatePath: rulesTemplatePath,
            addForm: addForm,
            addFormField: addFormField,
            addFormPage: addFormPage,
            deleteForm: deleteForm,
            deleteFormField: deleteFormField,
            disableFormField: disableFormField,
            deleteFormPage: deleteFormPage,
            editFormPage: editFormPage,
            editForm: editForm,
            publishForm: publishForm,
            duplicateForm: duplicateForm,
            editFormStructure: editFormStructure,
            editFormField: editFormField,
            formatFieldTypeTitle: formatFieldTypeTitle,
            fieldIcon: fieldIcon,
            fieldTypeIcon: fieldTypeIcon,
            openDeleteFormModal: openDeleteFormModal,
            formatFormTitle: formatFormTitle,
            formatFormPageTitle: formatFormPageTitle,
            openPublishFormModal: openPublishFormModal
        };
    }
})(angular);
