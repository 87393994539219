(function (angular) {
    angular
        .module('one.admin')
        .service('PurchaseService', PurchaseService);

    PurchaseService.$inject = ['http'];

    function PurchaseService(http) {
        function purchaseSources(params) {
            return http.get('purchases/purchase_sources', params);
        }

        function purchaseDestinations(params) {
            return http.get('purchases/purchase_destinations', params);
        }

        function purchase(id) {
            return http.get('purchases/' + id);
        }

        purchase.alternativeProducts = function (id, params) {
            return http.get('purchases/' + id + '/alternative_products', params);
        }

        function purchases(params) {
            return http.get('purchases', params);
        }

        function addPurchase(data) {
            return http.post('purchases/add', data);
        }

        function cancelRecurringPurchase(id, data) {
            return http.post('purchases/' + id + '/cancel_recurring', data);
        }

        function cancelPurchasePaymentTerm(id, data) {
            return http.post('purchases/payment_terms/' + id + '/cancel', data);
        }

        function refundPurchasePaymentTerm(id, data) {
            return http.post('purchases/payment_terms/' + id + '/refund', data);
        }

        function deletePurchase(id) {
            return http.post('purchases/' + id + '/delete');
        }

        function editPurchase(data) {
            return http.post('purchases/' + data.id + '/edit', data);
        }

        return {
            purchaseSources: purchaseSources,
            purchaseDestinations: purchaseDestinations,
            purchase: purchase,
            purchases: purchases,
            addPurchase: addPurchase,
            cancelRecurringPurchase: cancelRecurringPurchase,
            cancelPurchasePaymentTerm: cancelPurchasePaymentTerm,
            refundPurchasePaymentTerm: refundPurchasePaymentTerm,
            deletePurchase: deletePurchase,
            editPurchase: editPurchase
        };
    }
})(angular);
