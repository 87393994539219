(function (angular) {
    angular
        .module('one.admin')
        .controller('EditProductController', EditProductController);

    EditProductController.$inject = ['$scope', '$state', 'ProductService', 'product', 'paymentMethods', 'productCategories'];

    function EditProductController ($scope, $state, ProductService, product, paymentMethods, productCategories) {
        $scope.paymentMethods = paymentMethods;
        $scope.productCategories = productCategories;
        $scope.product = product;

        $scope.editProduct = editProduct;

        function editProduct(data) {
            ProductService.editProduct(data).then(function (data) {
                $state.transitionTo('sales.products.product', {productId: product.id});
            });
        }
    }
})(angular);
