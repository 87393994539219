(function (angular) {
    angular
        .module('one.admin')
        .controller('AddMailSenderModalController', AddMailSenderModalController);

    AddMailSenderModalController.$inject = ['$scope', '$uibModalInstance', 'MailService', 'domains', 'users', 'params'];

    function AddMailSenderModalController($scope, $uibModalInstance, MailService, domains, users, params) {
        $scope.domains = domains;
        $scope.users = users;

        $scope.sender = {
            domain: domains[0]
        };

        angular.extend($scope, params);

        $scope.add = add;
        $scope.cancel = cancel;

        function add(data) {
            MailService.addMailSender(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
