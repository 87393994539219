(function (angular) {
    angular
        .module('one.admin')
        .directive('carouselModuleForm', carouselModuleFormDirective);

    carouselModuleFormDirective.$inject = ['config'];

    function carouselModuleFormDirective(config) {
        controller.$inject = ['$scope'];

        function controller($scope) {
            $scope.deleteItem = deleteItem;
            $scope.addItem = addItem;
            $scope.data = $scope.module.module_instance.items;
            $scope.site = $scope.module.type.site;

            function deleteItem(index) {
                $scope.module.module_instance.items.splice(index, 1);
            }

            /**
             * Set the default value when adding new carousel items, based on the last uploaded carousel item.
             */
            $scope.setDefaultNewWindow = function(val) {
                $scope.defaultOpenInNewWindow = val;
            }

            /**
             * An item in the carousel will open a new window by default, unless a carousel with existing items
             * already disabled this.
             */
            $scope.defaultOpenInNewWindow = true;
            if ($scope.module.module_instance.items.length > 0) {
                $scope.defaultOpenInNewWindow = $scope.module.module_instance.items.slice(-1)[0].new_window;
            }

            function addItem(image) {
                $scope.module.module_instance.items.push({
                    url: "",
                    image: image,
                    alt_text: '',
                    new_window: $scope.defaultOpenInNewWindow,
                    position: $scope.module.module_instance.items.length
                });
            }
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.carousel.form'),
            controller: controller
        };
    }
})(angular);
