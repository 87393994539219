(function (angular) {
    angular
        .module('one.admin')
        .service('session', session);

    session.$inject = ['$rootScope'];

    function session($rootScope) {
        var storage = JSON.parse(sessionStorage.getItem('data')) || {};

        this.get = get;
        this.has = has;
        this.set = set;
        this.unset = unset;
        this.sync = sync;
        this.clear = clear;

        /**
         * Get an item from the session. Supports keys with dot-notation.
         */
        function get(key, value) {
            var data = storedData();

            if (typeof key == 'undefined') {
                return data;
            }

            var keys = key.split('.');

            angular.forEach(keys, function (key) {
                if (angular.isObject(data) || angular.isArray(data)) {
                    data = data[key];
                } else {
                    data = undefined;
                }
            });

            if (typeof data == 'undefined' && typeof value !== 'undefined') {
                set(key, value);

                return get(key);
            }

            return data;
        }

        /**
         * Check if an item exists in the session.
         */
        function has(key) {
            return typeof get(key) !== 'undefined';
        }

        /**
         * Set a value and store it in the session.
         */
        function set(key, value) {
            var data = storedData();
            var keys = key.split('.');

            setNested(keys, value, data);

            storeData(data);
        }

        /**
         * Set a value by key, using dot notation.
         */
        function setNested(keys, value, data) {
            var key = keys[0];

            if (keys.length > 1) {
                if (!angular.isObject(data[key], key)) {
                    data[key] = {};
                }

                setNested(keys.slice(1), value, data[key]);
            } else {
                data[key] = value;
            }
        }

        /**
         * Remove an item from the session.
         */
        function unset(key) {
            var data = storedData();
            delete data[key];

            storeData(data);
        }

        /**
         * Store an item in the session, and keep watching it. If the value changes, update the
         * session.
         */
        function sync(key, value) {
            if (!has(key)) {
                set(key, value);
            }

            var watchFn = angular.isFunction(value) ? value : function () {
                return value;
            };

            $rootScope.$watch(watchFn, function (newValue) {
                set(key, newValue);
            }, true);
        }

        /**
         * Retrieve the stored data.
         */
        function storedData() {
            return storage;
        }

        /**
         * Store data.
         */
        function storeData(data) {
            storage = data;

            sessionStorage.setItem('data', JSON.stringify(data));
        }

        /**
         * Empty the storage.
         */
        function clear() {
            storage = {};
            sessionStorage.clear();
        }

        return this;
    }
})(angular);
