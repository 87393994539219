(function (angular) {
    angular
        .module('one.admin')
        .directive('formValueViewer', formValueViewer);

    formValueViewer.$inject = ['config'];

    function formValueViewer(config) {
        return {
            restrict: 'EA',
            scope: {
                value: '=',
                close: '=',
                type: '@',
                noLink: '='
            },
            controller: ['$scope', 'services', function ($scope, services) {
                $scope.viewPath = config.path.template('forms.values.viewers.' + $scope.type.replace(/\-/g, '_'));

                angular.extend($scope, services);
            }],
            template: function () {
                return '<span ng-include="viewPath"></span>';
            }
        };
    }
}(angular));
