(function (angular) {
    angular
        .module('one.admin')
        .factory('chartBuilder', chartBuilder);

    chartBuilder.$inject = ['translateFilter'];

    function chartBuilder(translateFilter) {
        Chart.defaults.global.defaultFontFamily = 'Proxima Nova W01, Helvetica, Arial, sans-serif';
        Chart.defaults.global.elements.line.tension = 0;

        function createChart(config, data) {
            var chart = angular.merge({
                data: [],
                labels: [],
                series: [],
                options: {
                    layout: {
                        padding: 5
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontSize: 9,
                                fontColor: '#aaa'
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            display: true,
                        }]
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                    }
                }
            }, config);

            chart.series = chart.series.map(function (name) {
                chart.data.push([]);

                return translateFilter('general.' + name);
            });

            chart.colors = chart.colors.map(function (color) {
                return {
                    backgroundColor: 'transparent',
                    borderColor: color,
                    pointBackgroundColor: color,
                    pointHoverBackgroundColor: color,
                };
            });

            angular.forEach(data, function (stats) {
                chart.labels.push(stats.label);
            });

            angular.forEach(data, function (stats) {
                for (var i = 0; i < chart.series.length; i++) {
                    chart.data[i].push(stats[config.series[i]]);
                }
            });

            return chart;
        }

        function prefillData(startDate, endDate, defaultValue, indexDateFormat, labelDateFormat) {
            startDate = moment(startDate);
            endDate = moment(endDate);
            indexDateFormat = indexDateFormat || 'YYYY-MM-DD';
            labelDateFormat = labelDateFormat || 'D MMM';

            var chart = {};

            while (!startDate.isAfter(endDate)) {
                var index = startDate.format(indexDateFormat);

                chart[index] = angular.merge({
                    label: moment(startDate).format(labelDateFormat)
                }, angular.copy(defaultValue));

                startDate.add(1, 'day');
            }

            return chart;
        }

        return {
            createChart: createChart,
            prefillData: prefillData
        };
    }
})(angular);
