(function (angular) {
    angular
        .module('one.admin')
        .directive('accountIcon', accountIconDirective);

    accountIconDirective.$inject = [];

    function accountIconDirective() {
        return {
            restrict: 'EA',
            scope: {
                account: '=',
                type: '@',
            },
            replace: true,
            controller: ['$scope', 'AccountService', function ($scope, AccountService) {
                $scope.getIcon = function () {
                    var name = ($scope.account ? ($scope.account.account_type_name || ($scope.account.type ? $scope.account.type.name : null)) : null) || $scope.type;

                    return AccountService.accountIcon(name);
                };
            }],
            template: '<i class="md-icon">{{ getIcon() }}</i>'
        };
    }
}(angular));
