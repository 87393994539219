(function (angular) {
    angular
        .module('one.admin')
        .service('VisitableService', VisitableService);

    VisitableService.$inject = ['http'];

    function VisitableService(http) {
        function visitable(id, params) {
            return http.get('visitables/' + id, params);
        }

        function visitables(params) {
            return http.get('visitables', params);
        }

        function pageVisitables(params) {
            return http.get('visitables/page', params);
        }

        function formatVisitableTitle(visitable) {
            return '<i class="md-icon">desktop_mac</i> ' + visitable.site.title + ' / ' + visitable.visitable_instance.title;
        }

        return {
            visitable: visitable,
            visitables: visitables,
            pageVisitables: pageVisitables,
            formatVisitableTitle: formatVisitableTitle
        };
    }
})(angular);
