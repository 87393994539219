(function (angular) {
    angular
        .module('one.admin')
        .controller('InteractionSubjectsController', InteractionSubjectsController);

    InteractionSubjectsController.$inject = ['$scope', 'AuthService', 'InteractionService', 'TagService', 'dataframe', 'modal', 'session'];

    function InteractionSubjectsController($scope, AuthService, InteractionService, TagService, dataframe, modal, session) {
        $scope.subjects = dataframe.init({
            name: 'subjects',
            externalData: InteractionService.subjects,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">subject</i> {{ row.title }}</h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Interactions',
                    name: 'interaction_count',
                    display: function (row) {
                        return '{{ row.interaction_count | number }}';
                    },
                    weight: 1
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('interaction_subjects.edit')) {
                    return 'crm.interactions.interactionSubjects.editInteractionSubject({ interactionSubjectId: ' + row.id + ' })';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'crm.interactions.interactionSubjects.editInteractionSubject({ interactionSubjectId: ' + row.id + ' })';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('interaction_subjects.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        InteractionService.openDeleteInteractionSubjectModal(row.id, function () {
                            $scope.subjects.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('interaction_subjects.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.interactionSubjects', {
                sort: { title: 'asc' },
                perPage: 10
            })
        });

        session.sync('data.dataframes.interactionSubjects', $scope.subjects.state);

        $scope.openAddInteractionSubjectModal = openAddInteractionSubjectModal;

        function openAddInteractionSubjectModal(size) {
            modal.open('addInteractionSubject', size).result.then(function (subject) {
                $scope.subjects.getData();
            });
        }
    }
})(angular);
