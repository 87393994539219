(function (angular) {
    angular
        .module('one.admin')
        .controller('NewsFeedModuleIndexController', NewsFeedModuleIndexController);

    NewsFeedModuleIndexController.$inject = ['$scope', '$state', 'dataframe', 'modal', 'module', 'newsModules', 'ModuleService'];

    function NewsFeedModuleIndexController($scope, $state, dataframe, modal, module, newsModules, ModuleService) {
        $scope.module = module;
        $scope.newsModules = newsModules;

        $scope.editModule = editModule;
        $scope.openAuditorModal = openAuditorModal;

        function editModule(module) {
            ModuleService.editModule(module).then(function () {
                $state.transitionTo('content.modules.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);

