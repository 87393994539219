(function (angular) {
    angular
        .module('one.admin')
        .controller('ForgotPasswordController', ForgotPasswordController);

    ForgotPasswordController.$inject = ['$scope', '$rootScope', '$state', 'AuthService', 'session'];

    function ForgotPasswordController($scope, $rootScope, $state, AuthService, session) {
        $scope.requestPasswordReset = requestPasswordReset;

        $scope.resetMailSent = false;

        function requestPasswordReset(email) {
            AuthService.requestPasswordReset({email: email}).then(function (response) {
                $rootScope.errors = false;
                
                $scope.resetMailSent = true;
            });
        }
    }
})(angular);
