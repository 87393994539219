(function (angular) {
    angular
        .module('one.admin')
        .controller('SaveAnnualPaymentModalController', SaveAnnualPaymentModalController);

    SaveAnnualPaymentModalController.$inject = ['$scope', '$uibModalInstance', 'AnnualPaymentService', 'params'];

    function SaveAnnualPaymentModalController($scope, $uibModalInstance, AnnualPaymentService, params) {
        $scope.annualPayment = {
            year: moment().year(),
            expected: 0
        };     

        angular.merge($scope, params);

        $scope.save = save;
        $scope.cancel = cancel;

        function save(data) {
            AnnualPaymentService.saveAnnualPayment($scope.account.id, $scope.annualPayment).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
