(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeRowOptions', oneDataframeRowOptionsDirective);

    oneDataframeRowOptionsDirective.$inject = ['config'];

    function oneDataframeRowOptionsDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '=',
                row: '='
            },
            controller: ['$scope', function ($scope) {
                $scope.getOutput = function (item) {
                    return angular.isFunction(item) ? item($scope.row) : item;
                };
            }],
            link: function (scope, element) {
                element.addClass('one-dataframe-row-options');
            },
            templateUrl: config.path.template('common.dataframe.row_options')
        };
    }
})(angular);
