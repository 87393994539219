(function (angular) {
    angular
        .module('one.admin')
        .controller('AddFaqModuleModalController', AddFaqModuleModalController);

    AddFaqModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddFaqModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                subsections: []
            }
        };

        angular.merge($scope, params);

        $scope.addFaqModule = addFaqModule;
        $scope.cancel = cancel;

        function addFaqModule(data) {
            ModuleService.addModule(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
