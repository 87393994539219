(function (angular) {
    angular
        .module('one.admin')
        .directive('mailRecipientsEditor', mailRecipientsEditorDirective);

    mailRecipientsEditorDirective.$inject = ['config'];

    function mailRecipientsEditorDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                mailing: '='
            },
            controller: ['$scope', '$state', 'AccountService', 'GroupService', 'MailingService', 'SegmentService', function ($scope, $state, AccountService, GroupService, MailingService, SegmentService) {
                $scope.segment = $scope.mailing.segment;

                $scope.atletaEvents = getAtletaEvents();

                $scope.isPermissionGroup = isPermissionGroup;
                $scope.createSegment = createSegment;
                $scope.addRecipient = addRecipient;
                $scope.getAccounts = AccountService.accounts;
                $scope.getGroups = GroupService.groups;
                $scope.getSegments = SegmentService.segments;
                $scope.formatAccountTitle = AccountService.formatAccountTitle;
                $scope.formatGroupTitle = GroupService.formatGroupTitle;
                $scope.formatSegmentTitle = SegmentService.formatSegmentTitle;

                $scope.formState = {
                    recipientsType: $scope.mailing.segment ? 'segment' : 'groups',
                };

                $scope.$watch(function () { return $scope.mailing.groups.length; }, function () {
                    $scope.atletaEvents = getAtletaEvents();
                });

                $scope.optinGroup = null;
                $scope.permissions = [];

                MailingService.permissions().then(function (response) {
                    $scope.optinGroup = response.group;
                    $scope.permissions = response.permissions;
                });

                function getAtletaEvents() {
                    var events = $scope.mailing.groups.filter((function (group) {
                        return group.atleta_event;
                    })).map(function (group) {
                        return group.atleta_event;
                    });

                    if ($scope.mailing.segment) {
                        events = events.concat($scope.mailing.segment.atleta_events);
                    }

                    var titles = [];

                    events.forEach(function (event) {
                        if (!titles.includes(event.title)) {
                            titles.push(event.title);
                        }
                    });

                    titles.sort();

                    return titles;
                }

                function isPermissionGroup(group) {
                    return $scope.optinGroup && group.parent_group_id === $scope.optinGroup.id;
                }

                function createSegment() {
                    MailingService.createSegment($scope.mailing.id).then(function (data) {
                        $state.transitionTo('mail.campaigns.mailings.segment', {campaignId: $scope.mailing.campaign.id, mailingId: $scope.mailing.id, segmentId: data.mailing.segment.id});
                    });
                }

                function addRecipient(recipient, list) {
                    if (recipient && !recipientInList(recipient, list)) {
                        list.push(recipient);
                    }

                    $scope.to = {};
                }

                function recipientInList(recipient, list) {
                    var key = 'id';

                    list = list || [];

                    for (var i = 0; i < list.length; i++) {
                        if (recipient[key] === list[i][key]) {
                            return true;
                        }
                    }

                    return false;
                }
            }],
            templateUrl: config.path.template('mails.recipients.editor')
        };
    }
})(angular);
