(function (angular) {
    angular
        .module('one.admin')
        .controller('EditGiftRewardController', EditGiftRewardController);

    EditGiftRewardController.$inject = ['$scope', '$state', 'GiftService', 'giftReward'];

    function EditGiftRewardController($scope, $state, GiftService, giftReward) {
        $scope.giftReward = giftReward;

        $scope.edit = edit;

        function edit(data) {
            GiftService.editGiftReward(data).then(function (data) {
                var giftReward = data.gift_reward;

                if (giftReward.action_project_id) {
                    $state.transitionTo('crowdfunding.actionProjects.giftRewards', {
                        actionProjectId: giftReward.action_project_id
                    });
                } else if (giftReward.action_campaign_id) {
                    $state.transitionTo('crowdfunding.actionCampaigns.giftRewards', {
                        actionCampaignId: giftReward.action_campaign_id
                    });
                }
            });
        }
    }
})(angular);
