(function (angular) {
    angular
        .module('one.admin')
        .controller('DisabledFormStructureController', DisabledFormStructureController);

    DisabledFormStructureController.$inject = ['$scope', 'FormService', 'modal', 'form'];

    function DisabledFormStructureController($scope, FormService, modal, form) {
        $scope.form = form;

        $scope.fieldIcon = FormService.fieldIcon;
        $scope.fieldTypeIcon = FormService.fieldTypeIcon;
        $scope.deleteField = deleteField;

        function deleteField(field, index) {
            modal.open('confirm', null, { title: 'Delete this field?', message: 'This field <strong>and all of its entries</strong> will be permanently deleted.' }).result.then(function (data) {
                $scope.form.disabled_fields.splice(index, 1);

                FormService.deleteFormField(field.id);
            });
        }
    }
})(angular);
