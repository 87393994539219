(function (angular) {
    angular
        .module('one.admin')
        .service('MailService', MailService);

    MailService.$inject = ['http', 'config', 'TransactionalMailService', 'MailingService'];

    function MailService(http, config, TransactionalMailService, MailingService) {
        function domains(params) {
            return http.get('mail/domains', params);
        }

        function sender(id, params) {
            return http.get('mail/senders/' + id, params);
        }

        function senders(params) {
            return http.get('mail/senders', params);
        }

        function sentMail(id) {
            return http.get('mail/sent_mails/' + id);
        }

        function sentMails(params) {
            return http.get('mail/sent_mails', params);
        }

        function resendSentMail(id) {
            return http.post('mail/sent_mails/' + id + '/resend');
        }

        function sentMailStatuses(params) {
            return http.get('mail/sent_mail_statuses', params);
        }

        function template(id, params) {
            return http.get('mail/templates/' + id, params);
        }

        function templates(params) {
            return http.get('mail/templates', params);
        }

        function addMailSender(data) {
            return http.post('mail/senders/add', data);
        }

        function addMailTemplate(data) {
            return http.post('mail/templates/add', data);
        }

        function deleteMailSender(id) {
            return http.post('mail/senders/' + id + '/delete');
        }

        function deleteMailTemplate(id) {
            return http.post('mail/templates/' + id + '/delete');
        }

        function editMailSender(data) {
            return http.post('mail/senders/' + data.id + '/edit', data);
        }

        function duplicateMailSender(data) {
            return http.post('mail/senders/' + data.id + '/duplicate', data);
        }

        function editMailTemplate(data) {
            return http.post('mail/templates/' + data.id + '/edit', data);
        }

        function duplicateMailTemplate(data) {
            return http.post('mail/templates/' + data.id + '/duplicate', data);
        }

        function getMailTemplateVariables() {
            var variables = TransactionalMailService.getVariables().concat(MailingService.getVariables());

            return variables.filter(function (value, index) {
                return variables.indexOf(value) == index;
            });
        }

        function livePreviewMail(data, options) {
            return http.post('mail/live_preview', data, null, options);
        }

        function livePreviewMailTemplate(data, options) {
            return http.post('mail/templates/live_preview', data, null, options);
        }

        function matchPlaceholders(string) {
            var regExp = /{{ *placeholders\.([a-zA-Z0-9_]+?) *}}/g;

            var match;
            var placeholders = [];

            while (match = regExp.exec(string)) {
                var placeholder = match[1];

                if (placeholders.indexOf(placeholder) === -1) {
                    placeholders.push(placeholder);
                }
            }

            return placeholders;
        }

        function replacePlaceholders(string, replace) {
            var regExp = /{{ *placeholders\.([a-zA-Z0-9_]+?) *}}/g;

            var match;
            var matches = [];

            while (match = regExp.exec(string)) {
                matches.push({
                    needle: match[0],
                    placeholderName: match[1]
                });
            }

            angular.forEach(matches, function (match) {
                string = string.replace(match.needle, replace(match.placeholderName));
            });

            return string;
        }

        function getPlaceholderIcons() {
            return {
                text: 'text_format',
                plain: 'short_text',
                image: 'image',
                html: 'code'
            };
        }

        function getPlaceholderTypes() {
            return [
                'text',
                'plain',
                'image',
                'html'
            ];
        }

        function getEditorTheme(template) {
            var theme = {};

            angular.forEach(template.cssVariables, function (variable) {
                theme[variable.name] = variable.value;
            });

            theme.brand = template.brands;

            return theme;
        }

        function createSection(placeholder) {
            return {
                name: placeholder ? placeholder.name : 'body',
                config: angular.copy(placeholder.config),
                placeholder: placeholder
            };
        }

        function formatMailSenderTitle(mailSender) {
            var output = '<i class="md-icon">contact_mail</i> ' + mailSender.title;

            if (mailSender.primary) {
                output += ' <i class="md-icon text-muted">check</i>';
            }

            return output;
        }

        function formatMailTemplateTitle(mailTemplate) {
            var output = '<i class="md-icon">palette</i> ' + mailTemplate.title + ' ';

            if (mailTemplate.default) {
                output += '<span class="label label-success"><i class="md-icon">check_circle_outline</i> {{ \'general.optimized\' | translate }}</span>';
            } else {
                output += '<span class="label label-default"><i class="md-icon">code</i> {{ \'general.custom\' | translate }}</span>';
            }

            if (mailTemplate.primary) {
                output += ' <i class="md-icon text-muted">check</i> ';
            }

            if (mailTemplate.tags) {
                output += '<span class="tag-indicators">';

                angular.forEach(mailTemplate.tags, function (tag) {
                    output += ' <i class="tag-indicator" style="background: ' + tag.color + '"></i>';
                });

                output += '</span>';
            }

            return output;
        }

        return {
            domains: domains,
            sender: sender,
            senders: senders,
            sentMail: sentMail,
            sentMails: sentMails,
            resendSentMail: resendSentMail,
            sentMailStatuses: sentMailStatuses,
            template: template,
            templates: templates,
            addMailSender: addMailSender,
            addMailTemplate: addMailTemplate,
            deleteMailSender: deleteMailSender,
            deleteMailTemplate: deleteMailTemplate,
            duplicateMailSender: duplicateMailSender,
            duplicateMailTemplate: duplicateMailTemplate,
            editMailSender: editMailSender,
            editMailTemplate: editMailTemplate,
            getMailTemplateVariables: getMailTemplateVariables,
            livePreviewMail: livePreviewMail,
            livePreviewMailTemplate: livePreviewMailTemplate,
            matchPlaceholders: matchPlaceholders,
            replacePlaceholders: replacePlaceholders,
            getPlaceholderIcons: getPlaceholderIcons,
            getPlaceholderTypes: getPlaceholderTypes,
            getEditorTheme: getEditorTheme,
            createSection: createSection,
            formatMailSenderTitle: formatMailSenderTitle,
            formatMailTemplateTitle: formatMailTemplateTitle
        };
    }
})(angular);
