(function (angular) {
    angular
        .module('one.admin')
        .controller('ActivateUserController', ActivateUserController);

    ActivateUserController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'AuthService', 'session'];

    function ActivateUserController($scope, $rootScope, $state, $stateParams, AuthService, session) {
        $scope.activateUser = activateUser;

        $scope.user = {
            token: $stateParams.token,
            two_factor_enabled: true
        };

        function activateUser(user) {
            AuthService.activateUser($stateParams.userId, user).then(function (response) {
                $rootScope.errors = false;
                $state.transitionTo('login');
            });
        }
    }
})(angular);
