(function (angular) {
    angular
        .module('one.admin')
        .controller('AddInteractionModalController', AddInteractionModalController);

    AddInteractionModalController.$inject = ['$scope', '$uibModalInstance', 'translateFilter', 'AuthService', 'AccountService', 'session', 'InteractionService', 'subjects', 'users', 'notificationMails', 'params'];

    function AddInteractionModalController($scope, $uibModalInstance, translateFilter, AuthService, AccountService, session, InteractionService, subjects, users, notificationMails, params) {
        $scope.notificationMails = notificationMails;
        $scope.users = users;

        $scope.interaction = {
            contact_date: (new Date()).toISOString().substring(0, 10),
            user: session.get('user'),
            assigned_user: session.get('user'),
            finished: true,
            internal: true,
            notification_mail: null,
            attachments: []
        };

        angular.extend($scope.interaction, params.interaction);

        angular.forEach(subjects, function (subject) {
            subject.label = subject.title;
        });

        if (AuthService.userCanAccess('interactions.add_subject')) {
            subjects.unshift({ label: translateFilter('general.new_dots'), new: true });
        }

        $scope.subjects = subjects;

        if ($scope.interaction.subject) {
            $scope.interaction.subject.label = $scope.interaction.subject.title;
        }

        $scope.addInteraction = addInteraction;
        $scope.cancel = cancel;

        function addInteraction(data) {
            InteractionService.addInteraction(data).then(function (data) {
                InteractionService.updateBadgeCount();
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
