(function (angular) {
    angular
        .module('one.admin')
        .controller('AddBankAccountModalController', AddBankAccountModalController);

    AddBankAccountModalController.$inject = ['$scope', '$uibModalInstance', 'AccountService', 'BankAccountService', 'params'];

    function AddBankAccountModalController($scope, $uibModalInstance, AccountService, BankAccountService, params) {
        angular.extend($scope, params);

        $scope.hideAccountField = !!$scope.bankAccount.account;

        if ($scope.hideAccountField) {
            $scope.bankAccount.account_holder = $scope.bankAccount.account.title;
        }

        $scope.add = add;
        $scope.cancel = cancel;
        $scope.getAccounts = AccountService.accounts;
        $scope.formatAccountTitle = AccountService.formatAccountTitle;

        function add(data) {
            BankAccountService.addBankAccount(data).then(function (data) {
                $uibModalInstance.close(data);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
