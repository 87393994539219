(function (angular) {
    angular
        .module('one.admin')
        .service('TransactionalMailService', TransactionalMailService);

    TransactionalMailService.$inject = ['http', 'modal'];

    function TransactionalMailService(http, modal) {
        function transactionalMail(id, params) {
            return http.get('transactional_mails/' + id, params, null, { ignoreLoadingBar: params && params.recache });
        }

        function transactionalMails(params) {
            return http.get('transactional_mails', params);
        }

        function history(id) {
            return http.get('transactional_mails/' + id + '/history');
        }

        function clicks(id, params) {
            return http.get('transactional_mails/' + id + '/clicks', params);
        }

        function deliveries(id, params) {
            return http.get('transactional_mails/' + id + '/deliveries', params);
        }

        function pending(id, params) {
            return http.get('transactional_mails/' + id + '/pending', params);
        }

        function failures(id, params) {
            return http.get('transactional_mails/' + id + '/failures', params);
        }

        function opens(id, params) {
            return http.get('transactional_mails/' + id + '/opens', params);
        }

        function addTransactionalMail(data) {
            return http.post('transactional_mails/add', data);
        }

        function deleteTransactionalMail(id) {
            return http.post('transactional_mails/' + id + '/delete');
        }

        function editTransactionalMail(data) {
            return http.post('transactional_mails/' + data.id + '/edit', data);
        }

        function duplicateTransactionalMail(data) {
            return http.post('transactional_mails/' + data.id + '/duplicate', data);
        }

        function getVariables() {
            return [
                'account.first_name',
                'account.full_name',
                'sender.signature | raw'
            ];
        }

        function openDeleteTransactionalMailModal(id, callback) {
            modal.open('confirm', null, {
                title: 'Do you want to delete this transactional mail?'
            }).result.then(function (data) {
                return deleteTransactionalMail(id).then(callback);
            });
        }

        function formatTransactionalMailTitle(transactionalMail) {
            return '<i class="md-icon">mail_outline</i> ' + transactionalMail.title;
        }

        return {
            transactionalMail: transactionalMail,
            transactionalMails: transactionalMails,
            history: history,
            clicks: clicks,
            deliveries: deliveries,
            pending: pending,
            failures: failures,
            opens: opens,
            addTransactionalMail: addTransactionalMail,
            deleteTransactionalMail: deleteTransactionalMail,
            duplicateTransactionalMail: duplicateTransactionalMail,
            editTransactionalMail: editTransactionalMail,
            getVariables: getVariables,
            openDeleteTransactionalMailModal: openDeleteTransactionalMailModal,
            formatTransactionalMailTitle: formatTransactionalMailTitle
        };
    }
})(angular);
