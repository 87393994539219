(function (angular) {
    angular
        .module('one.admin')
        .controller('AddCarouselModuleModalController', AddCarouselModuleModalController);

    AddCarouselModuleModalController.$inject = ['$scope', '$uibModalInstance', 'ModuleService', 'params'];

    function AddCarouselModuleModalController($scope, $uibModalInstance, ModuleService, params) {
        $scope.module = {
            module_instance: {
                items_per_slide: 4,
                timeout: 5,
                items: []
            }
        };

        $scope.loading = false;

        angular.merge($scope, params);

        $scope.addCarouselModule = addCarouselModule;

        $scope.cancel = cancel;

        function addCarouselModule(data) {
            $scope.loading = true;

            ModuleService.addModule(data).then(function (data) {
                $scope.loading = false;
                $uibModalInstance.close(data);
            }, function (data) {
                $scope.errors = data.errors;
                $scope.loading = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})(angular);
