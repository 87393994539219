(function (angular) {
    angular
        .module('one.admin')
        .directive('oneSelect', oneSelectDirective);

    oneSelectDirective.$inject = ['$filter', '$timeout', 'config', 'string'];

    function oneSelectDirective($filter, $timeout, config, string) {
        return {
            restrict: 'E',
            scope: {
                options: '&',
                externalOptions: '&',
                model: '=',
                selected: '=',
                change: '&',
                optionLabel: '@',
                optionLabelFn: '=',
                optionValue: '@',
                optionComparator: '@',
                placeholder: '@',
                config: '=',
                nullable: '=',
                disabled: '=',
                onClear: '&',
            },
            link: function (scope, element) {
                scope.handleKeydown = function ($event) {
                    var lengthCorrection = scope.state.total > scope.state.limit ? 1 : 0;
                    var length = scope.items.length + lengthCorrection;

                    if ($event.keyCode == 38) { // up arrow
                        if (scope.state.selected.index === null) {
                            scope.state.selected.index = length - 1;
                        } else {
                            scope.state.selected.index = (scope.state.selected.index - 1 + length) % length;
                        }

                        scope.scrollToSelected();

                        $event.preventDefault();
                    } else if ($event.keyCode == 40) { // down arrow
                        if (scope.state.selected.index === null) {
                            scope.state.selected.index = 0;
                        } else {
                            scope.state.selected.index = (scope.state.selected.index + 1) % length;
                        }

                        scope.scrollToSelected();

                        $event.preventDefault();
                    } else if ($event.keyCode == 13) { // return
                        if (scope.state.selected.index == scope.state.limit) {
                            scope.showMore();
                        } else if (scope.state.selected.index !== null) {
                            if (scope.items.length > scope.state.selected.index) {
                                scope.setModel(scope.items[scope.state.selected.index]);
                            }

                            scope.state.open = false;
                        }

                        scope.scrollToSelected();

                        $event.preventDefault();
                    }
                };

                scope.scrollToSelected = function () {
                    // Set scoll distance
                    if (scope.state.selected.index !== null) {
                        var list = $(element).find('.dropdown-menu');
                        var options = $(element).find('li');
                        var optionHeight = 30;

                        if (options.length > 0) {
                            optionHeight = $(options[0]).outerHeight();
                        }

                        if (list.length > 0) {
                            list = list[0];

                            var distance = scope.state.selected.index * optionHeight + Math.floor((scope.state.selected.index + 1) / scope.perPage);
                            var listHeight = $(list).outerHeight();
                            var scrollStart = $(list).scrollTop();
                            var scrollEnd = $(list).scrollTop() + listHeight;

                            if (distance < scrollStart) {
                                list.scrollTop = distance;
                            } else if (distance + optionHeight > scrollEnd) {
                                list.scrollTop += distance + optionHeight - scrollEnd + 2;
                            }
                        }
                    }
                }
            },
            controller: ['$scope', function ($scope) {
                var defaultConfig = angular.extend({}, $scope.config, {
                    limit: 20
                });

                $scope.state = {
                    loading: false,
                    open: false,
                    search: null,
                    selected: {
                        index: 0,
                        option: $scope.selected
                    },
                    total: 0,
                    limit: defaultConfig.limit,
                    focus: 1
                };

                $scope.perPage = defaultConfig.limit;

                $scope.items = [];

                if (!$scope.externalOptions()) {
                    $scope.$watch('options', getItems, true);
                }

                var timeout;
                var delay = $scope.externalOptions() ? 200 : 0;

                $scope.$watch('state.search', function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }

                    $timeout.cancel(timeout);

                    if (typeof newValue == 'string' && (newValue.length == 0 || newValue.length >= config.minSearchLength)) {
                        timeout = $timeout(function () {
                            $scope.state.limit = defaultConfig.limit;

                            getItems();

                            $scope.state.selected.index = 0;
                            $scope.scrollToSelected();
                        }, delay);
                    }
                });

                $scope.$watch('model', function (newValue, oldValue) {
                    if ($scope.optionValue) {
                        var items = $scope.externalOptions() ? $scope.items : $scope.options();

                        var optionChanged = false;

                        angular.forEach(items, function (item) {
                            if (modelsAreEqual($scope.model, getOptionValue(item))) {
                                $scope.state.selected.option = item;
                                optionChanged = true;
                            }
                        });

                        if (!optionChanged && !angular.equals(newValue, oldValue)) {
                            $scope.state.selected.option = null;
                        }
                    } else {
                        $scope.state.selected.option = newValue;
                    }

                    if ($scope.change && !angular.equals(newValue, oldValue)) {
                        $scope.change();
                    }
                });

                $scope.$watch('config.focusWhen', function (newValue) {
                    if (newValue) {
                        openDropdown();
                    }
                });

                $scope.getOptionLabel = getOptionLabel;
                $scope.getOptionValue = getOptionValue;
                $scope.setModel = setModel;
                $scope.clearModel = clearModel;
                $scope.modelsAreEqual = modelsAreEqual;
                $scope.openDropdown = openDropdown;
                $scope.closeDropdown = closeDropdown;
                $scope.clear = clear;
                $scope.showMore = showMore;
                $scope.remaining = remaining;
                $scope.hasValue = hasValue;

                function getOptionLabel(option, textOnly) {
                    var label;

                    if (!option) {
                        return;
                    }

                    if (angular.isFunction($scope.optionLabelFn) && (!textOnly || !$scope.optionLabel)) {
                        label = $scope.optionLabelFn(option);

                        if (textOnly) {
                            label = string.stripHTML(label);
                        }

                        return label;
                    }

                    if ($scope.optionLabel) {
                        var parts = $scope.optionLabel.split('.');

                        label = option[parts.shift()];

                        while (parts.length > 0) {
                            label = label[parts.shift()];
                        }

                        return textOnly ? label : string.escape(label);
                    }

                    return option;
                }

                function getOptionValue(option) {
                    if (!option) {
                        return null;
                    }

                    if ($scope.optionValue) {
                        var parts = $scope.optionValue.split('.');
                        var value = option[parts.shift()];

                        while (parts.length > 0) {
                            value = value[parts.shift()];
                        }

                        return value;
                    }

                    return option;
                }

                function setModel(option) {
                    $scope.state.selected.option = option;
                    $scope.model = getOptionValue(option);
                }

                function clearModel() {
                    setModel(null);

                    if ($scope.onClear) {
                        $scope.onClear();
                    }
                }

                function modelsAreEqual(model1, model2) {
                    if (model1 === 0 && model2 === 0) {
                        return true;
                    }

                    if (isEmpty(model1) || isEmpty(model2)) {
                        return false;
                    }

                    if ($scope.optionComparator) {
                        return model1[$scope.optionComparator] === model2[$scope.optionComparator];
                    }

                    return angular.equals(model1, model2);
                }

                function getItems() {
                    var params = {};

                    if ($scope.externalOptions()) {
                        params = {
                            search: $scope.state.search,
                            perPage: $scope.state.limit
                        };

                        $scope.state.loading = true;

                        $scope.externalOptions()(params).then(function (result) {
                            $scope.state.loading = false;

                            if (params.perPage && result.data) {
                                $scope.items = result.data;
                                $scope.state.total = result.total;
                            } else {
                                $scope.items = result;
                            }
                        });
                    } else if ($scope.state.search) {
                        if ($scope.optionLabel) {
                            // Set nested value using dot-notation
                            var keys = $scope.optionLabel.split('.');
                            var subparams = params;

                            for (var i = 0; i < keys.length - 1; i++) {
                                if (!subparams[keys[i]]) {
                                    subparams[keys[i]] = {};
                                }

                                subparams = subparams[keys[i]];
                            }

                            subparams[keys[keys.length - 1]] = $scope.state.search;
                        } else {
                            params = $scope.state.search;
                        }

                        $scope.items = $filter('filter')($scope.options(), params);
                    } else {
                        $scope.items = $scope.options();
                    }
                }

                function openDropdown() {
                    if ($scope.disabled) {
                        return;
                    }

                    $scope.state.limit = defaultConfig.limit;
                    $scope.state.open = true;
                    $scope.state.focus = $scope.state.focus + 1;

                    getItems();
                }

                function closeDropdown() {
                    $scope.state.open = false;
                }

                function clear() {
                    $scope.state.selected.index = 0;
                    $scope.state.search = null;
                }

                function showMore() {
                    var oldLimit = $scope.state.limit;

                    $scope.state.limit = $scope.state.limit + defaultConfig.limit;
                    $scope.state.focus = $scope.state.focus + 1;
                    $scope.state.selected.index = oldLimit;

                    getItems();
                }

                function remaining() {
                    return Math.min($scope.state.total - $scope.state.limit, defaultConfig.limit);
                }

                function hasValue() {
                    var value = $scope.state.selected.option;

                    if ($scope.optionComparator && angular.isObject(value)) {
                        value =  value[$scope.optionComparator];
                    }

                    return !isEmpty(value);
                }

                function isEmpty(value) {
                    return value === null || typeof value == 'undefined';
                }
            }],
            templateUrl: 'one.ui.select'
        };
    }
})(angular);
