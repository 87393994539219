(function (angular) {
    angular
        .module('one.admin')
        .service('DashboardService', DashboardService);

    DashboardService.$inject = ['http'];

    function DashboardService(http) {
        function recentAccounts(params) {
            return http.get('dashboard/recent_accounts', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function recentInteractions(params) {
            return http.get('dashboard/recent_interactions', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function recentFormEntries(params) {
            return http.get('dashboard/recent_form_entries', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function recentOrders(params) {
            return http.get('dashboard/recent_orders', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function recentSentMails(params) {
            return http.get('dashboard/recent_sent_mails', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function recentTransactions(params) {
            return http.get('dashboard/recent_transactions', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function accountStats(params) {
            return http.get('dashboard/account_stats', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function sentMailStats(params) {
            return http.get('dashboard/sent_mail_stats', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function orderStats(params) {
            return http.get('dashboard/order_stats', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        function transactionStats(params) {
            return http.get('dashboard/transaction_stats', params, null, { skipBefore: true, ignoreLoadingBar: true, skipTimestamp: true });
        }

        return {
            recentAccounts: recentAccounts,
            recentInteractions: recentInteractions,
            recentFormEntries: recentFormEntries,
            recentOrders: recentOrders,
            recentSentMails: recentSentMails,
            recentTransactions: recentTransactions,
            accountStats: accountStats,
            sentMailStats: sentMailStats,
            orderStats: orderStats,
            transactionStats: transactionStats
        };
    }
})(angular);
