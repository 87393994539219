(function (angular) {
    angular
        .module('one.admin')
        .run(run);

    run.$inject = ['$interval', '$rootScope', '$state', '$window', 'AuthService', 'config', 'session', 'string'];

    function run($interval, $rootScope, $state, $window, AuthService, config, session, string) {
        var loginState = 'login';
        var logoutState = 'logout';
        var gatewayState = 'gateway';
        var activateUserState = 'activateUser';
        var forgotPasswordState = 'forgotPassword';
        var resetPasswordState = 'resetPassword';
        var twoFactorAuthenticationState = 'twoFactorAuthentication';
        var notFoundState = 'notFound';

        session.unset('user');
        session.unset('project');

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name == notFoundState) {
                return;
            }

            var publicState = toState.name == loginState
                || toState.name == forgotPasswordState
                || toState.name == activateUserState
                || toState.name == resetPasswordState;

            if ($rootScope.maintenance) {
                event.preventDefault();
            } else if (!publicState) {
                if (!session.get('user')) {
                    event.preventDefault();

                    AuthService.checkUser().then(function (user) {
                        if (user) {
                            $state.transitionTo(toState, toParams);
                        } else {
                            $state.transitionTo(loginState);
                        }
                    });
                } else if (toState.name != twoFactorAuthenticationState && toState.name != logoutState) {
                    if ((!session.get('two_factor_authentication_status') || session.get('two_factor_authentication_status') == 'required')) {
                        event.preventDefault();

                        AuthService.checkTwoFactorAuthentication().then(function (twoFactorAuthenticationRequired) {
                            if (twoFactorAuthenticationRequired) {
                                $state.transitionTo(twoFactorAuthenticationState);
                            } else {
                                $state.transitionTo(toState, toParams);
                            }
                        });
                    } else if (toState.name != gatewayState) {
                        if (!session.get('project')) {
                            event.preventDefault();

                            AuthService.checkProject().then(function (project) {
                                if (project) {
                                    $state.transitionTo(toState, toParams);
                                } else {
                                    $state.transitionTo(gatewayState);
                                }
                            });
                        } else if (returnUrl = string.queryParams().return) {
                            returnUrl = decodeURIComponent(returnUrl);

                            var expected = $window.location.protocol + '//' + $window.location.host + '/';

                            // Only redirect to the same domain.
                            if (returnUrl.substr(0, expected.length) == expected) {
                                event.preventDefault();

                                $window.location = returnUrl;
                            }
                        }
                    }
                }
            }
        });

        $interval(function () {
            function reload() {
                if ($rootScope.online && !$rootScope.maintenance) {
                    $window.location.reload();
                }
            }

            if ($state.current.name != loginState && $state.current.name != activateUserState && $state.current.name != forgotPasswordState && $state.current.name != resetPasswordState) {
                AuthService.user().then(function (user) {
                    if (user.id != session.get('user.id')) {
                        // Changed to a different user in another tab
                        reload();
                    }

                    // Update user
                    session.set('user', user);

                    AuthService.project().then(function (project) {
                        if (project.name != session.get('project.name')) {
                            // Changed project in another tab
                            reload();
                        }

                        // Update project
                        session.set('project', project);
                    }, function () {
                        // No project set
                        if (!$state.current.name != gatewayState) {
                            reload();
                        }
                    });
                }, function () {
                    // No longer logged in
                    reload();
                });
            }
        }, config.auth.checkInterval);
    }
})(angular);
