(function (angular) {
    angular
        .module('one.admin')
        .controller('EditActionProjectController', EditActionProjectController);

    EditActionProjectController.$inject = ['$scope', '$state', 'ActionProjectService', 'actionProject', 'types', 'fields'];

    function EditActionProjectController($scope, $state, ActionProjectService, actionProject, types, fields) {
        $scope.actionProject = actionProject;
        $scope.types = types;
        $scope.fields = fields;

        $scope.edit = edit;

        function edit(data) {
            ActionProjectService.editActionProject(data).then(function (data) {
                $state.transitionTo('crowdfunding.actionProjects.actionProject', { actionProjectId: actionProject.id });
            });
        }
    }
})(angular);
