(function (angular) {
    angular
        .module('one.admin')
        .controller('GlobalDashboardController', GlobalDashboardController);

    GlobalDashboardController.$inject = ['$scope', '$locale', '$filter', '$timeout', 'session', 'chartBuilder', 'DashboardService', 'GroupService', 'TransactionService'];

    function GlobalDashboardController($scope, $locale, $filter, $timeout, session, chartBuilder, DashboardService, GroupService, TransactionService) {
        var timer;
        var timeout = 10000;
        var days = 30;
        var green = 'rgba(117, 182, 54, .7)';
        var gray = 'rgba(68, 68, 68, .7)';
        var red = 'rgba(245, 77, 71, .7)';

        $scope.bootstrapped = false;
        $scope.loading = 0;
        $scope.session = session;

        $scope.$watch('loading', function (count) {
            if (count <= 0) {
                $scope.bootstrapped = true;

                timer = $timeout(fetchData, timeout);
            }
        });

        $scope.$on('$destroy', function () {
            $timeout.cancel(timer);
        });

        var defaultOptions = {
            scales: {
                xAxes: [{
                    display: false
                }]
            }
        };

        // Initialize dashboard.
        fetchData();

        function fetchData() {
            $scope.loading = 8; // Equal to the number of http requests.

            DashboardService.recentAccounts().then(function (data) {
                angular.forEach(data.accounts, function (account) {
                    var leafGroups = GroupService.getLeafGroups(account.groups);

                    account.group_labels = [];

                    angular.forEach(leafGroups, function (group) {
                        account.group_labels.push('<span group-label group="{title: \'' + group.title.replace(/\\/g, '\\\\').replace(/['"]/g, "\\'") + '\', id: ' + group.id + '}" shorten="10"></span>');
                    });
                });

                $scope.recentAccounts = data;
                $scope.loading--;
            });

            DashboardService.recentOrders().then(function (data) {
                angular.forEach(data.orders, function (order) {
                    // Order purchases by amount in descending order.
                    order.purchases = $filter('orderBy')(order.purchases, function (purchase) {
                        return purchase.amount;
                    }, true);

                    order.purchase_titles = [];

                    angular.forEach(order.purchases, function (purchase) {
                        order.purchase_titles.push($filter('shorten')(purchase.product.title, 20));
                    });
                });

                $scope.recentOrders = data;
                $scope.loading--;
            });

            DashboardService.recentSentMails().then(function (data) {
                $scope.recentSentMails = data;
                $scope.loading--;
            });

            DashboardService.recentTransactions().then(function (data) {
                $scope.recentTransactions = data;
                $scope.loading--;
            });

            DashboardService.accountStats({ days: days }).then(function (result) {
                var data = chartBuilder.prefillData(result.start_date, result.end_date, { accounts: 0 })

                angular.forEach(result.data, function (stats) {
                    var index = moment(stats.iso_date).format('YYYY-MM-DD');

                    data[index].accounts += stats.count;
                });

                $scope.accountsChart = chartBuilder.createChart({
                    series: ['accounts'],
                    colors: [green],
                    options: defaultOptions
                }, data);

                $scope.loading--;
            });

            DashboardService.sentMailStats({ days: days }).then(function (result) {
                var data = chartBuilder.prefillData(result.start_date, result.end_date, { bounces: 0, deliveries: 0 })

                angular.forEach(result.data, function (stats) {
                    var index = moment(stats.iso_date).format($locale.DATETIME_FORMATS.parsedDate);

                    if (stats.status == 'delivered') {
                        data[index].deliveries += stats.count;
                    } else if (stats.status == 'failed') {
                        data[index].bounces += stats.count;
                    }
                });

                $scope.sentMailsChart = chartBuilder.createChart({
                    series: ['deliveries', 'bounces'],
                    colors: [green, red],
                    options: defaultOptions
                }, data);

                $scope.loading--;
            });

            DashboardService.orderStats({ days: days }).then(function (result) {
                var data = chartBuilder.prefillData(result.start_date, result.end_date, { orders: 0 })

                angular.forEach(result.data, function (stats) {
                    var index = moment(stats.iso_date).format($locale.DATETIME_FORMATS.parsedDate);

                    data[index].orders += stats.count;
                });

                $scope.ordersChart = chartBuilder.createChart({
                    series: ['orders'],
                    colors: [green],
                    options: defaultOptions
                }, data);

                $scope.loading--;
            });

            DashboardService.transactionStats({ days: days }).then(function (result) {
                var data = chartBuilder.prefillData(result.start_date, result.end_date, { failed: 0, open: 0, paid: 0 })

                angular.forEach(result.data, function (stats) {
                    var index = moment(stats.iso_date).format($locale.DATETIME_FORMATS.parsedDate);

                    if (TransactionService.failedStatuses.indexOf(stats.status) > -1) {
                        data[index].failed += stats.count;
                    } else if (TransactionService.openStatuses.indexOf(stats.status) > -1) {
                        data[index].open += stats.count;
                    } else if (TransactionService.paidStatuses.indexOf(stats.status) > -1) {
                        data[index].paid += stats.count;
                    }
                });

                $scope.transactionsChart = chartBuilder.createChart({
                    series: ['paid', 'open', 'failed'],
                    colors: [green, gray, red],
                    options: defaultOptions
                }, data);

                $scope.loading--;
            });
        }
    }
})(angular);
