(function (angular) {
    angular
        .module('one.admin')
        .controller('SegmentsController', SegmentsController);

    SegmentsController.$inject = ['$scope', 'AuthService', 'SegmentService', 'TagService', 'dataframe', 'modal', 'session'];

    function SegmentsController($scope, AuthService, SegmentService, TagService, dataframe, modal, session) {
        $scope.stats = null;
        function refreshStats() {
            SegmentService.stats().then(function (stats) {
                $scope.stats = stats;
            });
        }
        refreshStats();

        $scope.segments = dataframe.init({
            externalData: function (params) {
                params = params || {};
                params.appends = ['deletable', 'has_dependent_mailings', 'has_dependent_pages', 'has_dependent_segments'];

                return SegmentService.segments(params);
            },
            cols: [
                {
                    name: 'title',
                    title: 'Title',
                    display: function (row) {
                        return '<h4><i class="md-icon">filter_list</i> {{ row.title }}</h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    name: 'used_in',
                    title: 'Used in',
                    display: function (row) {
                        return '<small class="segment-icons" ng-if="row.has_dependent_mailings || row.has_dependent_pages || row.has_dependent_segments">' +
                            '<i class="md-icon" ng-if="row.has_dependent_mailings">mail</i>' +
                            '<i class="md-icon" ng-if="row.has_dependent_pages">desktop_mac</i>' +
                            '<i class="md-icon" ng-if="row.has_dependent_segments">filter_list</i>' +
                            '</small>';
                    },
                    weight: 1
                },
                {
                    name: 'account_count',
                    title: 'Contacts',
                    display: function (row) {
                        return '{{ row.account_count | number }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    name: 'created_at',
                    title: 'Created',
                    display: function (row) {
                        return '{{ row.created_at | formatDate }}';
                    },
                    weight: 1,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('segments.view')) {
                    return 'crm.segments.segment({segmentId: ' + row.id + '})';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Configure',
                    icon: 'build',
                    sref: function (row) {
                        return 'crm.segments.editSegment({segmentId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('segments.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.segments.loading = true;

                        SegmentService.duplicateSegment(row).then(function () {
                            refreshStats();
                            $scope.segments.getData();
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('segments.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        SegmentService.openDeleteSegmentModal(row.id, function () {
                            refreshStats();
                            $scope.segments.getData();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('segments.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.segments', {
                sort: { title: 'asc' },
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.segments, 'segment', session.get('data.tags'));
        }

        session.sync('data.dataframes.segments', $scope.segments.state);

        $scope.openAddSegmentModal = openAddSegmentModal;

        function openAddSegmentModal(size) {
            modal.open('addSegment', size).result.then(function () {
                refreshStats();
                $scope.segments.getData();
            });
        }
    }
})(angular);
