(function (angular) {
    angular
        .module('one.admin')
        .controller('EventsController', EventsController);

    EventsController.$inject = ['$scope', 'AuthService', 'EventService', 'TagService', 'dataframe', 'modal', 'notification', 'session'];

    function EventsController($scope, AuthService, EventService, TagService, dataframe, modal, notification, session) {
        $scope.events = dataframe.init({
            externalData: EventService.events,
            cols: [
                {
                    title: 'Title',
                    name: 'title',
                    display: function (row) {
                        return '<h4><i class="md-icon">emoji_events</i> {{ row.title }} <span tag-indicator tags="row.tags"></span></h4>';
                    },
                    weight: 2,
                    sortable: true
                },
                {
                    title: 'Tickets',
                    name: 'tickets',
                    display: function (row) {
                        return '{{ row.tickets.length }}';
                    },
                    weight: 1,
                    sortable: true
                },
                {
                    title: 'Crowdfunding',
                    name: 'crowdfunding',
                    display: function (row) {
                        return '<span class="text-limiter" ng-class="{ archived: row.active && !row.action_project.active }" ng-if="row.action_project"><a ui-sref="crowdfunding.actionProjects.actionProject({actionProjectId: row.action_project.id})" ng-click="$event.stopPropagation()"><i class="md-icon">favorite_outline</i> {{ row.action_project.title }}</a> <span tag-indicator tags="row.action_data.tags"></span></span>';
                    },
                    weight: 2,
                    sortable: true
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('events.view')) {
                    return 'events.events.event({  eventId: ' + row.id + ' })';
                }
                return false;
            },
            rowOptions: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    sref: function (row) {
                        return 'events.events.editEvent({  eventId: ' + row.id + ' })';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('events.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.events.loading = true;

                        EventService.duplicateEvent(row).then(function () {
                            $scope.events.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('events.add');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        openDeleteEventModal(row.id);
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('events.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.events', { sort: { title: 'asc' } })
        });

        session.sync('data.dataframes.events', $scope.events.state);

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.events, 'event', session.get('data.tags'));
        }

        $scope.openDeleteEventModal = openDeleteEventModal;

        function openDeleteEventModal(id) {
            modal.open('confirm', null, { title: 'Are you sure?', message: 'Delete this event?' }).result.then(function (data) {
                deleteEvent(id);
            });
        }

        function deleteEvent(id) {
            EventService.deleteEvent(id).then(function (data) {
                $scope.events.getData();
            }, function (errors) {
                notification.error(errors['all'][0]);
            });
        }
    }
})(angular);
