(function (angular) {
    angular
        .module('one.admin.config')
        .config(config);

    config.$inject = ['msdElasticConfig'];

    function config(msdElasticConfig) {
        msdElasticConfig.append = '\n';
    }
})(angular);
