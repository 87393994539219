(function (angular) {
    angular
        .module('one.admin')
        .directive('userForm', userFormDirective);

    userFormDirective.$inject = ['config'];

    function userFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                authRoles: '=',
                ipLocations: '=',
                user: '='
            },
            controller: 'UserFormController',
            templateUrl: config.path.template('users.form')
        };
    }
}(angular));
