(function (angular) {
    angular
        .module('one.admin')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$scope', '$state', '$window', 'AuthService', 'session'];

    function LoginController($scope, $state, $window, AuthService, session) {
        $scope.authenticate = authenticate;

        function authenticate(data) {
            AuthService.authenticate(data).then(success);

            function success(data) {
                if (data.user.projects.length == 1) {
                    AuthService.setProject(data.user.projects[0]).then(function (data) {
                        $state.transitionTo('welcome');
                    });
                } else {
                    $state.transitionTo('welcome');
                }
            }
        }
    }
})(angular);
