(function (angular) {
    angular
        .module('one.admin')
        .controller('SelectFileModalController', SelectFileModalController);

    SelectFileModalController.$inject = ['$scope', '$uibModalInstance', 'params'];

    function SelectFileModalController($scope, $uibModalInstance, params) {
        $scope.file = null;

        angular.extend($scope, params);

        $scope.close = function () {
            $uibModalInstance.close($scope.file);
        };

        $scope.cancel = function () {
            $uibModalInstance.close();
        };
    }
})(angular);
