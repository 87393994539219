(function (angular) {
    angular
        .module('one.admin')
        .controller('EditPostController', EditPostController);

    EditPostController.$inject = ['$scope', '$state', 'PostService', 'post'];

    function EditPostController($scope, $state, PostService, post) {
        $scope.post = post;

        $scope.edit = edit;

        function edit(data) {
            PostService.editPost(data).then(function (data) {
                var post = data.post;

                if (post.owner_type == 'action_campaign') {
                    $state.transitionTo('crowdfunding.actionCampaigns.posts', {
                        actionProjectId: post.owner.action_project_id,
                        actionCampaignId: post.owner.id
                    });
                } else if (post.owner_type == 'news_module') {
                    $state.transitionTo('content.modules.newsModule.index', {
                        moduleId: post.owner.module.id
                    });
                }
            });
        }
    }
})(angular);
