(function (angular) {
    angular
        .module('one.admin')
        .controller('AddBankStatementBatchController', AddBankStatementBatchController);

    AddBankStatementBatchController.$inject = ['$scope', '$state', 'http', 'BankStatementService', 'Upload'];

    function AddBankStatementBatchController($scope, $state, http, BankStatementService, Upload) {
        $scope.uploads = [];

        $scope.upload = function (files) {
            if (files && files.length > 0) {
                var progress = function (evt) {
                    $scope.uploads[this.id].progress = parseInt(100.0 * evt.loaded / evt.total, 10);
                };

                var success = function (data, status, headers, config) {
                    $scope.uploads[this.id].file = data;
                };

                var error = function (data, status, headers, config) {
                    $scope.uploads[this.id].errors = data.errors;
                };

                for (var i = 0; i < files.length; i++) {
                    var file = files[i];

                    var id = $scope.uploads.length;

                    $scope.uploads[id] = { binary: file, progress: 0 };

                    Upload.upload({
                        url: http.getPath('bank_statements/add_batch'),
                        file: file
                    })
                        .progress(progress.bind({ id: id }))
                        .success(success.bind({ id: id }))
                        .error(error.bind({ id: id }));
                }
            }
        };
    }
})(angular);
