(function (angular) {
    angular
        .module('one.admin')
        .directive('formStateEditor', formStateEditor);

    formStateEditor.$inject = ['config'];

    function formStateEditor(config) {
        return {
            restrict: 'EA',
            scope: {
                state: '=',
                fields: '='
            },
            controller: ['$scope', 'FormService', function ($scope, FormService) {
                $scope.fieldConditions = [];
                $scope.fieldMap = {};

                angular.forEach($scope.fields, function (field) {
                    var valueCondition = {};

                    if (field.choices.length > 0) {
                        valueCondition = {
                            type: 'has',
                            relation: 'choice_entries',
                            boolean: 'or',
                            filters: []
                        };
                    } else {
                        valueCondition = {
                            type: 'comparison',
                            field: 'value',
                            operator: 'not_empty'
                        };
                    }

                    $scope.fieldConditions.push({
                        type: 'has',
                        filters: [
                            valueCondition,
                            {
                                type: 'comparison',
                                field: 'field.id',
                                value: field.id,
                                operator: '='
                            },
                            {
                                type: 'comparison',
                                field: 'enabled',
                                value: true,
                                operator: '='
                            }
                        ],
                        relation: 'field_entries'
                    });

                    $scope.fieldMap[field.id] = field;
                });

                setActiveField();

                $scope.show = {};

                $scope.setActiveField = setActiveField;
                $scope.choiceFilter = choiceFilter;
                $scope.formatStateTitle = formatStateTitle;

                function fieldTitle(field) {
                    return field.title + ' (' + FormService.formatFormPageTitle(field.page) + ')';
                }

                function setActiveField() {
                    if ($scope.state) {
                        var fieldId = $scope.state.filters[1].value;

                        $scope.activeField = $scope.fieldMap[fieldId];
                    } else {
                        $scope.activeField = null;
                    }
                }

                function choiceFilter(choice) {
                    return {
                        type: 'comparison',
                        field: 'choice.id',
                        operator: '=',
                        value: choice.id
                    };
                }

                function formatStateTitle(state) {
                    var field = $scope.fieldMap[state.filters[1].value];

                    state.title = field ? fieldTitle(field) : '';

                    return state.title;
                }
            }],
            templateUrl: config.path.template('forms.states.editor')
        };
    }
}(angular));
