(function (angular) {
    angular
        .module('one.admin')
        .directive('discountCodeForm', discountCodeFormDirective);

    discountCodeFormDirective.$inject = ['config'];

    function discountCodeFormDirective(config) {
        return {
            restrict: 'EA',
            scope: {
                discountCode: '=',
                products: '=',
            },
            controller: 'DiscountCodeFormController',
            templateUrl: config.path.template('discount_codes.form')
        };
    }
}(angular));
