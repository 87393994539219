(function (angular) {
    angular
        .module('one.admin')
        .controller('EditMailTemplateController', EditMailTemplateController);

    EditMailTemplateController.$inject = ['$scope', '$state', 'MailService', 'modal', 'template'];

    function EditMailTemplateController($scope, $state, MailService, modal, template) {
        $scope.template = template;

        $scope.editMailTemplate = editMailTemplate;
        $scope.openAuditorModal = openAuditorModal;

        function editMailTemplate(data) {
            MailService.editMailTemplate(data).then(function (data) {
                $state.transitionTo('mail.templates.index');
            });
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: template.id, modelType: 'mail_template' });
        }
    }
})(angular);
