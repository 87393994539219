(function (angular) {
    angular
        .module('one.admin')
        .directive('cardModuleForm', cardModuleFormDirective);

    cardModuleFormDirective.$inject = ['config'];

    function cardModuleFormDirective(config) {
        controller.$inject = ['$scope', 'VisitableService'];

        function controller($scope, VisitableService) {
            $scope.toggleCustomUrl = !!$scope.module.module_instance.custom_url

            $scope.formatVisitable = VisitableService.formatVisitableTitle;
            $scope.getVisitables = VisitableService.pageVisitables;

            $scope.$watch('toggleCustomUrl', function(newVal) {
                if (newVal) {
                    $scope.module.module_instance.visitable = null;
                    $scope.module.module_instance.visitable_id = null;
                    return;
                }

                $scope.module.module_instance.custom_url = null;
            });
        }

        return {
            restrict: 'EA',
            scope: {
                module: '='
            },
            templateUrl: config.path.template('modules.card.form'),
            controller: controller
        };
    }
})(angular);
