(function (angular) {
    angular
        .module('one.admin')
        .controller('NewsModuleIndexController', NewsModuleIndexController);

    NewsModuleIndexController.$inject = ['$scope', 'modal', '$state', 'dataframe', 'modal', 'module', 'NewsModuleService', 'PostService'];

    function NewsModuleIndexController($scope, modal, $state, dataframe, modal, module, NewsModuleService, PostService) {
        $scope.module = module;

        $scope.getPosts = getPosts;
        $scope.openAuditorModal = openAuditorModal;

        function getPosts(params) {
            return NewsModuleService.posts($scope.module.module_instance.id, params)
        }

        function openAuditorModal() {
            modal.open('auditor', 'lg', { modelId: module.id, modelType: 'module' });
        }
    }
})(angular);

