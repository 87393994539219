(function (angular) {
    angular
        .module('one.admin')
        .controller('ProcessSddBatchModalController', ProcessSddBatchModalController);

    ProcessSddBatchModalController.$inject = ['$scope', '$locale', '$uibModalInstance', 'params'];

    function ProcessSddBatchModalController($scope, $locale, $uibModalInstance, params) {
        angular.merge($scope, params);

        $scope.paymentDate = moment().format($locale.DATETIME_FORMATS.parsedDate);

        $scope.close = $uibModalInstance.close;
        $scope.processSddBatch = processSddBatch;

        function processSddBatch() {
            $uibModalInstance.close({payment_date: $scope.paymentDate});
        }
    }
})(angular);
