(function (angular) {
    angular
        .module('one.admin')
        .controller('MailingPendingModalController', MailingPendingModalController);

    MailingPendingModalController.$inject = ['$scope', '$rootScope', '$filter', '$uibModalInstance', 'dataframe', 'session', 'MailingService', 'params'];

    function MailingPendingModalController($scope, $rootScope, $filter, $uibModalInstance, dataframe, session, MailingService, params) {
        angular.extend($scope, params);

        $scope.pending = dataframe.init({
            externalData: function (params) {
                return MailingService.pending($scope.mailing.id, params);
            },
            cols: [
                {
                    name: 'title',
                    title: 'Name',
                    display: function (row) {
                        var icon = 'person';

                        if (row.account.account_type_name == 'organisation') {
                            icon = 'business';
                        };

                        return '<i class="md-icon">' + icon + '</i> <strong>{{ row.account.title }}</strong>';
                    },
                    weight: 1
                },
                {
                    name: 'email',
                    title: 'E-mail',
                    display: function (row) {
                        return row.recipient
                    },
                    weight: 1
                },
                {
                    title: 'Timestamp',
                    name: 'created_at',
                    weight: 1,
                    display: function (row) {
                        return $filter('formatDate')(row.created_at, 'short');
                    },
                    sortable: true
                }
            ],
            perPage: 5,
            rowSref: function (row) {
                return 'crm.accounts.account.index({accountId: ' + row.account.id + '})';
            },
            state: session.get('data.dataframes.sent_mail_per_status', {
                sort: {
                    created_at: 'desc'
                }
            })
        });

        $scope.close = $uibModalInstance.close;

        $rootScope.$on('$stateChangeStart', function () {
            $uibModalInstance.close();
        });
    }
})(angular);
