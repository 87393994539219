(function (angular) {
    angular
        .module('one.admin')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'AuthService', 'session'];

    function ResetPasswordController($scope, $rootScope, $state, $stateParams, AuthService, session) {
        $scope.resetPassword = resetPassword;

        $scope.data = {
            token: $stateParams.token
        };

        function resetPassword(data) {
            AuthService.resetPassword(data).then(function (response) {
                $rootScope.errors = false;
                $state.transitionTo('login');
            });
        }
    }
})(angular);
