(function (angular) {
    angular
        .module('one.admin')
        .directive('notifications', notificationsDirective);

    notificationsDirective.$inject = [];

    function notificationsDirective() {
        return {
            restrict: 'AE',
            controller: ['$scope', '$window', 'notification', function ($scope, $window, notification) {
                $scope.notifications = notification.all();

                $scope.reloadPage = function () {
                    $window.location.reload();
                };
            }],
            templateUrl: 'one.common.notifications.notifications'
        };
    }
})(angular);
