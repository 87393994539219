(function (angular) {
    angular
        .module('one.admin')
        .directive('oneDataframeHeader', oneDataframeHeaderDirective);

    oneDataframeHeaderDirective.$inject = ['config'];

    function oneDataframeHeaderDirective(config) {
        return {
            restrict: 'AE',
            scope: {
                dataframe: '='
            },
            link: function (scope, element) {
                element.addClass('one-dataframe-header');
            },
            controller: ['$scope', function ($scope) {
                $scope.row = {};

                for (var i = 0; i < $scope.dataframe.cols.length; i++) {
                    $scope.row[$scope.dataframe.cols[i].name] = $scope.dataframe.cols[i].title;
                }
            }],
            templateUrl: config.path.template('common.dataframe.header')
        };
    }
})(angular);
