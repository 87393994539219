(function (angular) {
    angular
        .module('one.admin')
        .controller('ActionProjectsController', ActionProjectsController);

    ActionProjectsController.$inject = ['$scope', 'AuthService', 'ActionProjectService', 'ActionCampaignService', 'TagService', 'dataframe', 'modal', 'session', 'groups'];

    function ActionProjectsController($scope, AuthService, ActionProjectService, ActionCampaignService, TagService, dataframe, modal, session, groups) {
        $scope.groups = groups;

        $scope.actionProjects = dataframe.init({
            externalData: ActionProjectService.actionProjects,
            cols: [
                {
                    name: 'title',
                    title: 'Title',
                    display: function (row) {
                        return '<h4 class="text-limiter"><i class="md-icon">favorite_outline</i> {{ row.title }}</h4>';
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    display: function (row) {
                        return ' <span tag-indicator tags="row.tags"></span>';
                    },
                    weight: 1
                },
                {
                    name: 'gift_amount',
                    title: 'Funds raised',
                    display: function (row) {
                        return '{{ row.gift_amount | money: \'&euro; \' }}' + (row.target ? ' <span class="text-muted">/ {{ row.target | money: \'&euro;\' }}' : '');
                    },
                    weight: 3,
                    sortable: true
                },
                {
                    name: 'action_campaign_count',
                    title: 'Campaigns',
                    display: function (row) {
                        return '{{ row.action_campaign_count | number }}';
                    },
                    weight: 2
                },
                {
                    name: 'campaigner_count',
                    title: 'Campaigners',
                    display: function (row) {
                        return '{{ row.campaigner_count | number }}';
                    },
                    weight: 2
                },
                {
                    name: 'gift_count',
                    title: 'Gifts',
                    display: function (row) {
                        return '{{ row.gift_count | number }}';
                    },
                    weight: 2
                }
            ],
            rowSref: function (row) {
                if (AuthService.userCanAccess('action_projects.view')) {
                    return 'crowdfunding.actionProjects.actionProject({actionProjectId: ' + row.id + '})';
                }

                return false;
            },
            rowOptions: [
                {
                    title: 'Configure',
                    icon: 'build',
                    sref: function (row) {
                        return 'crowdfunding.actionProjects.editActionProject({actionProjectId: ' + row.id + '})';
                    },
                    visible: function (row) {
                        return AuthService.userCanAccess('action_projects.edit');
                    }
                },
                {
                    title: 'Duplicate',
                    icon: 'flip_to_front',
                    click: function (row) {
                        $scope.actionProjects.loading = true;

                        ActionProjectService.duplicateActionProject(row).then(function () {
                            $scope.actionProjects.getData()
                        });
                    },
                    visible: function (row) {
                        return row.duplicatable && AuthService.userCanAccess('action_projects.add');
                    }
                },
                {
                    title: 'Deactivate',
                    icon: 'visibility_off',
                    click: function (row) {
                        ActionProjectService.openDeactivateActionProjectModal(row.id, function () {
                            $scope.actionProjects.getData();
                            getStats();
                        });
                    },
                    visible: function (row) {
                        return row.active && AuthService.userCanAccess('action_projects.edit');
                    }
                },
                {
                    title: 'Reactivate',
                    icon: 'visibility',
                    click: function (row) {
                        ActionProjectService.openReactivateActionProjectModal(row.id, function () {
                            $scope.actionProjects.getData();
                            getStats();
                        });
                    },
                    visible: function (row) {
                        return !row.active && AuthService.userCanAccess('action_projects.edit');
                    }
                },
                {
                    title: 'Delete',
                    icon: 'delete',
                    click: function (row) {
                        ActionProjectService.openDeleteActionProjectModal(row.id, function () {
                            $scope.actionProjects.getData();
                            getStats();
                        });
                    },
                    visible: function (row) {
                        return row.deletable && AuthService.userCanAccess('action_projects.delete');
                    }
                }
            ],
            state: session.get('data.dataframes.action_projects', {
                filters: {
                    tags: session.get('data.dataframe.tags', [])
                },
                sort: {
                    end_date: 'desc',
                    active: 'desc',
                    title: 'asc'
                }
            })
        });

        if (AuthService.userCanAccess('tags.toggle')) {
            TagService.insertDataframeTags($scope.actionProjects, 'action_project', session.get('data.tags'));
        }

        session.sync('data.dataframes.action_projects', $scope.actionProjects.state);

        $scope.stats = session.get('data.action_project_stats');

        ActionProjectService.stats().then(function (stats) {
            session.set('data.action_project_stats', stats);
            $scope.stats = stats;
        });

        $scope.actionCampaignTypes = [];

        ActionCampaignService.types().then(function (actionCampaignTypes) {
            $scope.actionCampaignTypes = actionCampaignTypes;
        });

        $scope.getActionCampaigns = ActionCampaignService.actionCampaigns;
        $scope.getGifts = ActionProjectService.gifts;
        $scope.openAddActionProjectModal = openAddActionProjectModal;

        function openAddActionProjectModal(size) {
            modal.open('addActionProject', size).result.then(function (data) {
                $scope.actionProjects.getData();
            });
        }

        function getStats() {
            ActionProjectService.stats().then(function (stats) {
                $scope.stats = stats;
            });
        }
    }
})(angular);
