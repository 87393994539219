(function (angular) {
    angular
        .module('one.admin')
        .controller('AuditorModalController', AuditorModalController);

    AuditorModalController.$inject = ['$scope', '$uibModalInstance', 'params'];

    function AuditorModalController($scope, $uibModalInstance, params) {
        angular.extend($scope, params);

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})(angular);
